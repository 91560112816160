import {
    Paragraph,
    TableCell,
    TableRow,
    TextRun,
    BorderStyle
} from "docx";
import lang from '../../Language/language.js';
export const likvardigaComments = (comments, analysisType, tableSpace) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //Calculations
    let equalent = comments.filter(com => com.analysis === "likvärdiga")
    let hierarchy = comments.filter(com => com.analysis === "hierarkisk")
    // END of Calcultaions

    //RENDER -----------------------------------

    //Comments for this year
    const dataG = (group) => {
        let groupRows = ""
        group.length < 1 ? 
        groupRows = [new TableRow({
            children: [
                new TableCell({
                    children:
                        [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: "",
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                    columnSpan: 3,
                }),
            ]
        })] : groupRows = group.map(elem =>
            new TableRow({
                children: [
                    new TableCell({
                        children:
                            [
                                new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: `${!elem.notable ? "" : elem.notable}`,
                                            font: "Arial"
                                        }),
                                    ]
                                }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },

                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.reason ? "" : elem.reason}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.todo ? "" : elem.todo}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },
                    }),
                ],
            }),
        )
        const groupRowHeader = () => {
            groupRows.unshift(new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Noterbart" : lang[language].notable,
                                        font: "Arial",
                                        size: 15,
                                        bold: true,
                                        italics: true,
                                        color: "#333333",
                                    }),
                                ]
                            }),
                        ],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                        },
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Orsak" : lang[language].reason,
                                        font: "Arial",
                                        size: 15,
                                        bold: true,
                                        italics: true,
                                        color: "#333333",
                                    }),
                                ]
                            }),
                        ],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                        },
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Åtgärd" : lang[language].action,
                                        font: "Arial",
                                        size: 15,
                                        bold: true,
                                        italics: true,
                                        color: "#333333",
                                    }),
                                ]
                            }),
                        ],
                        columnSpan: 1,
                        borders: {
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                        },
                    }),
                ],
            }))
            //Add the analysistype title
            groupRows.unshift(new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: analysisType === "equalent" ? (language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks) : (language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie),
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ],
                            }),
                        ],
                        columnSpan: 3,
                        borders: {
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                        },
                    }),
                ]
            })
            )

        }
        groupRowHeader(group);
        return groupRows
    }

    //RENDER -----------------------------------
    //let allGroups = getAllGroups();
    if (analysisType === "equalent") return dataG(equalent)
    if (analysisType === "hierarchical") return dataG(hierarchy)
}