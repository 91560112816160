import React from "react";
import blueFlag from '../../../../assets/images/blue-flag.png';
import yellowFlag from '../../../../assets/images/yellow-flag.png';
import { autoAnalysis } from '../../../AnalysLikvardiga/autoAnalysis';
import percentile from "percentile";
import lang from '../../../Language/language.js';

export class ResultatLikvardiga extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Groups if not automatically comparing (result shown in kr)
            g1: "",
            g2: "",
            g3: "",
            g4: "",
            g5: "",
            g6: "",
            g7: "",
            g8: "",
            g9: "",
            g10: "",
            g11: "",
            g12: "",
            g13: "",
            g14: "",
            g15: "",
            //For equalent comparisons (result if not showing kr)
            allComparisons: [],
            nrOfComparisions: [],
            group1: [],
            group2: [],
            group3: [],
            group4: [],
            group5: [],
            group6: [],
            group7: [],
            group8: [],
            group9: [],
            group10: [],
            group11: [],
            group12: [],
            group13: [],
            group14: [],
            group15: [],
        }
    }
    /* RESULTAT AV LIKVÄRDIGA ARBETEN
     - Loopa persdata för varje likvärdiga grupp och sammanställ:
     - - Vilka lika grupper som ingår i likvärdiga gruppen
     - - Hur många % kvinnor för varje lika grupp i likvärdiga gruppen
     - - Hur många anställda totalt för varje lika grupp i likvärdiga gruppen
     - - Meddellönen för varje lika grupp i likvärdiga gruppen (räkna även ut medianen när ändå håller på)
 
     ---------
 
 
 */
    calculate = (group) => {
        const onlyGroups = group.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)

        const arr = [];
        for (let i = 0; i < uniqueGroups.length; i++) {
            let filteredMale = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i] && person.gender === "man"))
            let filteredWomen = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i] && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //Calculate spridning for group
            //Get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let allEmployees = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i]))
            let getOnlySalaries = []
            for (let i = 0; i < allEmployees.length; i++) { getOnlySalaries.push(allEmployees[i].totalSalary) }
            let lowestSalary = Math.min(...getOnlySalaries)
            let highestSalary = Math.max(...getOnlySalaries)
            let spridningskvot = Math.round((highestSalary / lowestSalary) * 10) / 10
            //If 20 or more persons in the group
            if (allEmployees.length > 19) {
                let salariesInOrder = getOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                let more10 = percentile(10, salariesInOrder)
                let more90 = percentile(90, salariesInOrder)
                spridningskvot = Math.round((more90 / more10) * 10) / 10
            }
            let groupData = {
                "equalGroup": uniqueGroups[i],
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "equalentGroup": 1,
                "spridningskvot": spridningskvot
            }
            arr.push(groupData)
        };
        return arr
    }

    compareEqualentGroups = () => {
        let allGroups = [...this.state.group1, ...this.state.group2, ...this.state.group3, ...this.state.group4, ...this.state.group5, ...this.state.group6]
        if (this.props.nrOfEqualentGroups > 6 && this.props.nrOfEqualentGroups < 10) { allGroups = [...this.state.group1, ...this.state.group2, ...this.state.group3, ...this.state.group4, ...this.state.group5, ...this.state.group6, ...this.state.group7, ...this.state.group8, ...this.state.group9] }
        if (this.props.nrOfEqualentGroups > 9 && this.props.nrOfEqualentGroups < 13) { allGroups = [...this.state.group1, ...this.state.group2, ...this.state.group3, ...this.state.group4, ...this.state.group5, ...this.state.group6, ...this.state.group7, ...this.state.group8, ...this.state.group9, ...this.state.group10, ...this.state.group11, ...this.state.group12] }
        if (this.props.nrOfEqualentGroups > 12) { allGroups = [...this.state.group1, ...this.state.group2, ...this.state.group3, ...this.state.group4, ...this.state.group5, ...this.state.group6, ...this.state.group7, ...this.state.group8, ...this.state.group9, ...this.state.group10, ...this.state.group11, ...this.state.group12, ...this.state.group13, ...this.state.group14, ...this.state.group15] }

        //List to push results
        let comparisons = 0
        let allComparisons = []
        //Check all against eachother 
        for (let i = 0; i < allGroups.length; i++) {
            for (let j = i + 1; j < allGroups.length; j++) {

                //console.log(allGroups[i], allGroups[j], )
                //Here compare them to eachother
                const group1 = allGroups[i].group
                const group2 = allGroups[j].group
                //Get all employees in those groups
                const groupOne = this.props.persData.filter(person => person.groupNameArbVardering === group1);
                const groupTwo = this.props.persData.filter(person => person.groupNameArbVardering === group2);

                //calculate average values
                //group 1: 
                const oneNrOfEmp = groupOne.length
                const oneWomen = Math.round((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
                const oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + person.totalSalary, 0) / oneNrOfEmp)
                const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
                const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
                const oneEqualentGroup = groupOne[0].equalentGroup
                const oneCityArray = groupOne.map(person => person.city)
                const oneAverageCity = oneCityArray.sort((a, b) =>
                    oneCityArray.filter(v => v === a).length
                    - oneCityArray.filter(v => v === b).length
                ).pop();
                //Spridning
                let oneGetOnlySalaries = []
                for (let i = 0; i < groupOne.length; i++) { oneGetOnlySalaries.push(groupOne[i].totalSalary) }
                let oneLowestSalary = Math.min(...oneGetOnlySalaries)
                let oneHighestSalary = Math.max(...oneGetOnlySalaries)
                let oneSpridningskvot = Math.round((oneHighestSalary / oneLowestSalary) * 10) / 10
                //If 20 or more persons in the group
                if (groupOne.length > 19) {
                    let salariesInOrder = oneGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                    let more10 = percentile(10, salariesInOrder)
                    let more90 = percentile(90, salariesInOrder)
                    oneSpridningskvot = Math.round((more90 / more10) * 10) / 10
                }

                //group 2:
                const twoNrOfEmp = groupTwo.length
                const twoWomen = Math.round((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
                const twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + person.totalSalary, 0) / twoNrOfEmp)
                const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
                const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
                const twoEqualentGroup = groupTwo[0].equalentGroup
                const twoCityArray = groupTwo.map(person => person.city)
                const twoAverageCity = twoCityArray.sort((a, b) =>
                    twoCityArray.filter(v => v === a).length
                    - twoCityArray.filter(v => v === b).length
                ).pop();
                //Spridning
                let twoGetOnlySalaries = []
                for (let i = 0; i < groupTwo.length; i++) { twoGetOnlySalaries.push(groupTwo[i].totalSalary) }
                let twoLowestSalary = Math.min(...twoGetOnlySalaries)
                let twoHighestSalary = Math.max(...twoGetOnlySalaries)
                let twoSpridningskvot = Math.round((twoHighestSalary / twoLowestSalary) * 10) / 10
                //If 20 or more persons in the group
                if (groupTwo.length > 19) {
                    let salariesInOrder = twoGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                    let more10 = percentile(10, salariesInOrder)
                    let more90 = percentile(90, salariesInOrder)
                    twoSpridningskvot = Math.round((more90 / more10) * 10) / 10
                }

                //Diffs
                const diffWomen = (oneWomen - twoWomen)

                //prepare data for robot
                let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
                let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
                let firsAvgSalary = oneAvgSalary //Total average salary for group 1
                let secondAvgSalary = twoAvgSalary //Total average salary for group 2
                let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
                let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
                let firstGroup = group1 //Employees in group 1
                let secondGroup = group2 //Employees in group 2
                let firstExp = oneAvgExp //Average Experience in group 1
                let secondExp = twoAvgExp //Average Experience in group 2
                let firstAge = oneAvgAge //Average Experience in group 1
                let secondAge = twoAvgAge //Average Experience in group 2
                let firstEqualent = oneEqualentGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
                let secondEqualent = twoEqualentGroup
                let firstCity = oneAverageCity
                let secondCity = twoAverageCity
                let firstSpridning = oneSpridningskvot
                let secondSpridning = twoSpridningskvot

                //Only compare if one group is women dominated and the second is not
                if ((oneWomen > 59 && twoWomen < 60) || (twoWomen > 59 && oneWomen < 60)) {
                    //Count nr of comparisions
                    comparisons = comparisons + 1
                    this.setState({ nrOfComparisions: comparisons })

                    //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
                    if (twoWomen > 59 && oneWomen < 60) {
                        lessWomen = twoWomen
                        moreWomen = oneWomen
                        firsAvgSalary = twoAvgSalary
                        secondAvgSalary = oneAvgSalary
                        nrOfEmp = twoNrOfEmp
                        nrOfEmpTwo = oneNrOfEmp
                        firstGroup = group2
                        secondGroup = group1
                        firstExp = twoAvgExp
                        secondExp = oneAvgExp
                        firstAge = twoAvgAge
                        secondAge = oneAvgAge
                        firstEqualent = twoEqualentGroup
                        secondEqualent = oneEqualentGroup
                        firstCity = twoAverageCity
                        secondCity = oneAverageCity
                        firstSpridning = twoSpridningskvot
                        secondSpridning = oneSpridningskvot
                    }

                    //Only compare if there is a need for it. If no need to compare the groups, because male group is in higher group or has lower salary, then dont compare group
                    if (firstEqualent < secondEqualent || firsAvgSalary > secondAvgSalary) {
                        //Do nothing
                    } else {

                        //Run auto analysis (from separate file)
                        //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "Yes");
                        const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");
                        //console.log(group1, group2, autoAnalys)
                        //create object for analyticList
                        let type = (firstEqualent - secondEqualent) === 0 ? "equalent" : "hierarchical"
                        let group = {
                            "Group1": firstGroup,
                            "avgWomen1": lessWomen,
                            "emp1": nrOfEmp,
                            "avgSal1": firsAvgSalary,
                            "demands1": firstEqualent,
                            "Group2": secondGroup,
                            "avgWomen2": moreWomen,
                            "emp2": nrOfEmpTwo,
                            "avgSal2": secondAvgSalary,
                            "demands2": secondEqualent,
                            "Analys": autoAnalys[0], //<-- Måste skapa så skickar för robot här
                            //"Analys": autoAnalys, // <-- For testing
                            "AllResult": autoAnalys[4], //Used to get all result, example citydiff, agediff etc
                            "TotResult": autoAnalys[4] !== undefined ? autoAnalys[4].total : undefined, //The overall result for all
                            "reason": "",
                            "type": type,
                            "oneSpridningskvot": firstSpridning,
                            "twoSpridningskvot": secondSpridning
                        }
                        if (group.TotResult !== undefined && group.Analys !== "ignore") {
                            allComparisons.push(group)
                        }
                    } //End of if statement if need to run function or not
                } //End of if first group is women dominated if statement
            } //End of first for loop
        } //End of second for loop
        //Divide all women dominated groups togheter with the male dominated groups that needs to be looked at
        let nestedGroups = allComparisons.reduce(function (r, a) {
            r[a.Group1] = r[a.Group1] || [];
            r[a.Group1].push(a);
            return r;
        }, Object.create(null));
        let nestedGroupsArray = Object.keys(nestedGroups).map((key) => nestedGroups[key]); //Makes nestedgroups an array
        this.setState({ allComparisons: nestedGroupsArray })
    }

    divideDataByGroup = () => {
        return new Promise((resolve, reject) => {
            let persData = this.props.persData
            // console.log("personaldata")
            // console.log(this.props.persData)
            //Divide persData by equalent group 
            const group1 = persData.filter(person => person.equalentGroup === 1);
            const group2 = persData.filter(person => person.equalentGroup === 2);
            const group3 = persData.filter(person => person.equalentGroup === 3);
            const group4 = persData.filter(person => person.equalentGroup === 4);
            const group5 = persData.filter(person => person.equalentGroup === 5);
            const group6 = persData.filter(person => person.equalentGroup === 6);
            let group7 = ""
            let group8 = ""
            let group9 = ""
            if (this.props.nrOfEqualentGroups > 6) {
                group7 = persData.filter(person => person.equalentGroup === 7);
                group8 = persData.filter(person => person.equalentGroup === 8);
                group9 = persData.filter(person => person.equalentGroup === 9);
            }
            let group10 = ""
            let group11 = ""
            let group12 = ""
            if (this.props.nrOfEqualentGroups > 9) {
                group10 = persData.filter(person => person.equalentGroup === 10);
                group11 = persData.filter(person => person.equalentGroup === 11);
                group12 = persData.filter(person => person.equalentGroup === 12);
            }
            let group13 = ""
            let group14 = ""
            let group15 = ""
            if (this.props.nrOfEqualentGroups > 12) {
                group13 = persData.filter(person => person.equalentGroup === 13);
                group14 = persData.filter(person => person.equalentGroup === 14);
                group15 = persData.filter(person => person.equalentGroup === 15);
            }
            const g1 = this.calculate(group1)
            const g2 = this.calculate(group2)
            const g3 = this.calculate(group3)
            const g4 = this.calculate(group4)
            const g5 = this.calculate(group5)
            const g6 = this.calculate(group6)
            let g7 = ""
            let g8 = ""
            let g9 = ""
            if (this.props.nrOfEqualentGroups > 6) {
                g7 = this.calculate(group7)
                g8 = this.calculate(group8)
                g9 = this.calculate(group9)
            }
            let g10 = ""
            let g11 = ""
            let g12 = ""
            if (this.props.nrOfEqualentGroups > 9) {
                g10 = this.calculate(group10)
                g11 = this.calculate(group11)
                g12 = this.calculate(group12)
            }
            let g13 = ""
            let g14 = ""
            let g15 = ""
            if (this.props.nrOfEqualentGroups > 12) {
                g13 = this.calculate(group13)
                g14 = this.calculate(group14)
                g15 = this.calculate(group15)
            }

            this.setState({
                g1: g1,
                g2: g2,
                g3: g3,
                g4: g4,
                g5: g5,
                g6: g6,
            })
            if (this.props.nrOfEqualentGroups > 6) {
                this.setState({
                    g7: g7,
                    g8: g8,
                    g9: g9,
                    g10: g10,
                    g11: g11,
                    g12: g12
                })
            }
            if (this.props.nrOfEqualentGroups > 12) {
                this.setState({
                    g13: g13,
                    g14: g14,
                    g15: g15
                })
            }
            resolve();
        })
    }

    divideDataByGroupNoSalaries = async () => {
        return new Promise((resolve, reject) => {
            const allEmployees = this.props.persData

            //*** data for GROUP 1: ***
            const group1 = allEmployees.filter(person => person.equalentGroup === 1); //Get all works with demands 1
            //get unique groups
            const onlyGroups = group1.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group1Data = [];

            uniqueGroups.forEach(elem => {
                let filteredMale = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //get averageExperience and avg age
                let filteredGroup = group1.filter(person => (person.groupNameArbVardering === elem))
                let avgExp = Math.round(filteredGroup.reduce((total, person) => total + person.experience, 0) / (filteredGroup.length));
                let avgAge = Math.round(filteredGroup.reduce((total, person) => total + person.age, 0) / (filteredGroup.length));

                //let avgExp = 
                //maybe also median salary...
                let group1data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "avgExp": avgExp,
                    "avgAge": avgAge,
                    "demands": 1,
                }
                group1Data.push(group1data)
            });

            //*** data for GROUP 2: ***
            const group2 = allEmployees.filter(person => person.equalentGroup === 2); //Get all works with demands 2
            //get unique groups
            const onlyGroups2 = group2.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups2 = onlyGroups2.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group2Data = [];

            uniqueGroups2.forEach(elem => {
                let filteredMale = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //maybe also median salary...
                let group2data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "demands": 2,
                }
                group2Data.push(group2data)
            });

            //*** data for GROUP 3: ***
            const group3 = allEmployees.filter(person => person.equalentGroup === 3); //Get all works with demands 3
            //get unique groups
            const onlyGroups3 = group3.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups3 = onlyGroups3.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group3Data = [];

            uniqueGroups3.forEach(elem => {
                let filteredMale = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //maybe also median salary...
                let group3data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "demands": 3,
                }
                group3Data.push(group3data)
            });

            //*** data for GROUP 4: ***
            const group4 = allEmployees.filter(person => person.equalentGroup === 4); //Get all works with demands 1
            //get unique groups
            const onlyGroups4 = group4.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups4 = onlyGroups4.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group4Data = [];

            uniqueGroups4.forEach(elem => {
                let filteredMale = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //maybe also median salary...
                let group4data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "demands": 4,
                }
                group4Data.push(group4data)
            });

            //*** data for GROUP 5: ***
            const group5 = allEmployees.filter(person => person.equalentGroup === 5); //Get all works with demands 1
            //get unique groups
            const onlyGroups5 = group5.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups5 = onlyGroups5.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group5Data = [];

            uniqueGroups5.forEach(elem => {
                let filteredMale = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //maybe also median salary...
                let group5data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "demands": 5,
                }
                group5Data.push(group5data)
            });

            //*** data for GROUP 6: ***
            const group6 = allEmployees.filter(person => person.equalentGroup === 6); //Get all works with demands 1
            //get unique groups
            const onlyGroups6 = group6.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
            const uniqueGroups6 = onlyGroups6.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
            //For each unique group, check:
            //Nr of male and Women, Nr of employees, salary
            const group6Data = [];

            uniqueGroups6.forEach(elem => {
                let filteredMale = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                let filteredWomen = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                let nrOfMen = filteredMale.length
                let nrOfWomen = filteredWomen.length
                let nrAll = nrOfMen + nrOfWomen
                let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                let avgSalary = (totSalary / nrAll).toFixed(0)
                //maybe also median salary...
                let group6data = {
                    "group": elem,
                    "womenPercent": womenPercent,
                    "Men": nrOfMen,
                    "Women": nrOfWomen,
                    "totEmployees": nrAll,
                    "avgSalary": avgSalary,
                    "demands": 6,
                }
                group6Data.push(group6data)
            });

            if (this.props.nrOfEqualentGroups > 6) {
                //*** data for GROUP 7: ***
                const group7 = allEmployees.filter(person => person.equalentGroup === 7); //Get all works with demands 1
                //get unique groups
                const onlyGroups7 = group7.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups7 = onlyGroups7.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group7Data = [];

                uniqueGroups7.forEach(elem => {
                    let filteredMale = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group7data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 7,
                    }
                    group7Data.push(group7data)
                    this.setState({ group7: group7Data })
                });
                //*** data for GROUP 8: ***
                const group8 = allEmployees.filter(person => person.equalentGroup === 8); //Get all works with demands 1
                //get unique groups
                const onlyGroups8 = group8.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups8 = onlyGroups8.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group8Data = [];

                uniqueGroups8.forEach(elem => {
                    let filteredMale = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group8data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 8,
                    }
                    group8Data.push(group8data)
                    this.setState({ group8: group8Data })
                });
                //*** data for GROUP 9: ***
                const group9 = allEmployees.filter(person => person.equalentGroup === 9); //Get all works with demands 1
                //get unique groups
                const onlyGroups9 = group9.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups9 = onlyGroups9.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group9Data = [];

                uniqueGroups9.forEach(elem => {
                    let filteredMale = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group9data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 9,
                    }
                    group9Data.push(group9data)
                    this.setState({ group9: group9Data })
                });
            }

            if (this.props.nrOfEqualentGroups > 9) {
                //*** data for GROUP 10: ***
                const group10 = allEmployees.filter(person => person.equalentGroup === 10); //Get all works with demands 1
                //get unique groups
                const onlyGroups10 = group10.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups10 = onlyGroups10.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group10Data = [];

                uniqueGroups10.forEach(elem => {
                    let filteredMale = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group10data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 10,
                    }
                    group10Data.push(group10data)
                    this.setState({ group10: group10Data })
                });
                //*** data for GROUP 11: ***
                const group11 = allEmployees.filter(person => person.equalentGroup === 11); //Get all works with demands 1
                //get unique groups
                const onlyGroups11 = group11.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups11 = onlyGroups11.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group11Data = [];

                uniqueGroups11.forEach(elem => {
                    let filteredMale = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group11data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 11,
                    }
                    group11Data.push(group11data)
                    this.setState({ group11: group11Data })
                });
                //*** data for GROUP 12: ***
                const group12 = allEmployees.filter(person => person.equalentGroup === 12); //Get all works with demands 1
                //get unique groups
                const onlyGroups12 = group12.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups12 = onlyGroups12.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group12Data = [];

                uniqueGroups12.forEach(elem => {
                    let filteredMale = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group12data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 12,
                    }
                    group12Data.push(group12data)
                    this.setState({ group12: group12Data })
                });
            }

            if (this.props.nrOfEqualentGroups > 12) {
                //*** data for GROUP 13: ***
                const group13 = allEmployees.filter(person => person.equalentGroup === 13); //Get all works with demands 1
                //get unique groups
                const onlyGroups13 = group13.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups13 = onlyGroups13.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group13Data = [];

                uniqueGroups13.forEach(elem => {
                    let filteredMale = group13.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group13.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group13data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 13,
                    }
                    group13Data.push(group13data)
                    this.setState({ group13: group13Data })
                });
                //*** data for GROUP 14: ***
                const group14 = allEmployees.filter(person => person.equalentGroup === 14); //Get all works with demands 1
                //get unique groups
                const onlyGroups14 = group14.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups14 = onlyGroups14.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group14Data = [];

                uniqueGroups14.forEach(elem => {
                    let filteredMale = group14.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group14.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group14data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 14,
                    }
                    group14Data.push(group14data)
                    this.setState({ group14: group14Data })
                });
                //*** data for GROUP 15: ***
                const group15 = allEmployees.filter(person => person.equalentGroup === 15); //Get all works with demands 1
                //get unique groups
                const onlyGroups15 = group15.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups15 = onlyGroups15.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group15Data = [];

                uniqueGroups15.forEach(elem => {
                    let filteredMale = group15.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group15.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //maybe also median salary...
                    let group15data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 15,
                    }
                    group15Data.push(group15data)
                    this.setState({ group15: group15Data })
                });
            }
            //Set state for groups
            this.setState({
                group1: group1Data,
                group2: group2Data,
                group3: group3Data,
                group4: group4Data,
                group5: group5Data,
                group6: group6Data,
            })
            resolve();
        });
    }

    componentDidMount() {
        //  console.log("ResultatLikvardiga started")
        const run = async () => {
            if (this.props.hideSalaries == false) {
                await this.divideDataByGroup(); //Divide data by group if salaries should be shown
                //this.compareEqualentGroups(); //Comparing and grouping equalent groups that should be compared 
            } else {
                await this.divideDataByGroup(); //Divide data by group if salaries should be shown
                await this.divideDataByGroupNoSalaries(); //Divide data by group if salaries should not be shown
                this.compareEqualentGroups(); //Comparing and grouping equalent groups that should be compared 
            }
        }
        run();
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let nestedGroups = ""
        if (this.props.hideSalaries) {
            if (this.state.allComparisons.length > 0) {
                let demandsNr = "6"
                if (this.props.nrOfEqualentGroups > 6 && this.props.nrOfEqualentGroups < 10) { demandsNr = "9" }
                if (this.props.nrOfEqualentGroups > 9 && this.props.nrOfEqualentGroups < 13) { demandsNr = "12" }
                if (this.props.nrOfEqualentGroups > 12) { demandsNr = "15" }
                nestedGroups = this.state.allComparisons.map((item1) => (
                    <div style={{ background: "white", marginBottom: 20, boxShadow: "none", padding: 12, borderRadius: 5 }}>
                        <div className="robotBef" style={{ paddingTop: 0, paddingBottom: 0, borderBottom: "none" }}>
                            <span className="robotListInfo roboSmallTitles" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}>{language === "sv" ? "Grupp" : lang[language].group}</span>
                            <span className="robotListInfo roboSmallTitles" style={{ width: "10%" }}>{language === "sv" ? "Kvinnor" : lang[language].women}</span>
                            <span className="robotListInfo roboSmallTitles">{language === "sv" ? "Ans." : lang[language].employees}</span>
                            <span className="robotListInfo roboSmallTitles">{language === "sv" ? "Spridning" : lang[language].spread}</span>
                            <span className="robotListInfo roboSmallTitles"> {language === "sv" ? "Krav" : lang[language].requirements}</span>
                            {this.props.hideSalaries ? "" : <span className="robotListInfo roboSmallTitles" style={{ width: "15%" }}>{language === "sv" ? "M. lön" : lang[language].avgSal}</span>}
                            <span className="robotListInfo roboSmallTitles" style={{ width: "10%" }}>{this.props.hideSalaries ? (language === "sv" ? "Löneskillnad" : lang[language].salDiffShort) : ""}</span>
                        </div>
                        {/*<div><b>{item1[0].Group1}</b></div>*/}
                        <div className="robotBef" style={{ borderBottom: "1px solid #e2e2e2", fontWeight: "bold" }}>
                            <span className="robotListInfo" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}> {item1[0].Group1}</span>
                            <span className="robotListInfo" style={{ width: "10%" }}> {item1[0].avgWomen1}%</span>
                            <span className="robotListInfo"> {item1[0].emp1}</span>
                            <span className="robotListInfo"> {item1[0].oneSpridningskvot}</span>
                            <span className="robotListInfo"> {item1[0].demands1}/{demandsNr}</span>
                            {this.props.hideSalaries ? "" : <span className="robotListInfo" style={{ width: "15%" }}> {item1[0].avgSal1}</span>}
                            <span className="robotListInfo" style={{ width: "10%" }}> </span>
                        </div>
                        {item1.map((item2) => (
                            <div className="robotBef robotBefHover" style={{ borderBottom: "none", fontSize: 15 }}>
                                <span className="robotListInfo" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}> {item2.Group2}</span>
                                <span className="robotListInfo" style={{ width: "10%" }}> {item2.avgWomen2}%</span>
                                <span className="robotListInfo"> {item2.emp2}</span>
                                <span className="robotListInfo"> {item2.twoSpridningskvot}</span>
                                <span className="robotListInfo"> {item2.demands2}/{demandsNr}</span>
                                {this.props.hideSalaries ? "" : <span className="robotListInfo" style={{ width: "15%" }}> {item2.avgSal2}</span>}
                                {/*}  <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#c26e17" }}> +{((item2.avgSal2/item2.avgSal1)*100)-100}%</span>*/}
                                {/*  <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#ce8f1c", fontSize: 15 }}> +{Math.round((((item2.avgSal2 / item2.avgSal1) * 100) - 100) * 10) / 10}%</span>*/}
                                <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#ce8f1c", fontSize: 15 }}> {(((item2.avgSal2 - item2.avgSal1) / item2.avgSal2) * 100).toFixed(1)}%</span>


                            </div>
                        ))}
                    </div>
                ))
            }
        }

        const dataG = (group) => {
            return (
                group.map(elem =>
                    <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none" }} key={elem.equalGroup}>
                        <div className="fillertitleEq" style={{ width: 321 }}>{elem.equalGroup}</div>
                        <div className="titleEq flag">{elem.womenPercent > 59 ? <img alt="" style={{ width: 14, paddingBottom: 1 }} src={yellowFlag} /> : elem.womenPercent < 41 ? <img alt="" style={{ width: 14, paddingBottom: 1 }} src={blueFlag} /> : ""}</div>
                        <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"}>
                            <div className="titleEq noBold w80">{elem.womenPercent}%</div>
                            <div className="titleEq noBold w80">{elem.totEmployees}</div>
                            <div className="titleEq noBold w80">{elem.spridningskvot}</div>
                            {!this.props.hideSalaries ? <div className="titleEq noBold w80">{elem.avgSalary}</div> : <></>}
                        </div>
                    </div>
                )
            )
        }

        let dataG1 = ""
        this.state.g1 === undefined || this.state.g1.length == 0 ? dataG1 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG1 = dataG(this.state.g1)

        let dataG2 = ""
        this.state.g2 === undefined || this.state.g2.length == 0 ? dataG2 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG2 = dataG(this.state.g2)

        let dataG3 = ""
        this.state.g3 === undefined || this.state.g3.length == 0 ? dataG3 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG3 = dataG(this.state.g3)

        let dataG4 = ""
        this.state.g4 === undefined || this.state.g4.length == 0 ? dataG4 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG4 = dataG(this.state.g4)

        let dataG5 = ""
        this.state.g5 === undefined || this.state.g5.length == 0 ? dataG5 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG5 = dataG(this.state.g5)

        let dataG6 = ""
        this.state.g6 === undefined || this.state.g6.length == 0 ? dataG6 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
            dataG6 = dataG(this.state.g6)

        let dataG7 = ""
        let dataG8 = ""
        let dataG9 = ""
        if (this.props.nrOfEqualentGroups > 6) {
            this.state.g7 === undefined || this.state.g7.length == 0 ? dataG7 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG7 = dataG(this.state.g7)
            this.state.g8 === undefined || this.state.g8.length == 0 ? dataG8 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG8 = dataG(this.state.g8)
            this.state.g9 === undefined || this.state.g9.length == 0 ? dataG9 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG9 = dataG(this.state.g9)
        }
        let dataG10 = ""
        let dataG11 = ""
        let dataG12 = ""
        if (this.props.nrOfEqualentGroups > 9) {
            this.state.g10 === undefined || this.state.g10.length == 0 ? dataG10 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG10 = dataG(this.state.g10)
            this.state.g11 === undefined || this.state.g11.length == 0 ? dataG11 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG11 = dataG(this.state.g11)
            this.state.g12 === undefined || this.state.g12.length == 0 ? dataG12 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG12 = dataG(this.state.g12)
        }
        let dataG13 = ""
        let dataG14 = ""
        let dataG15 = ""
        if (this.props.nrOfEqualentGroups > 12) {
            this.state.g13 === undefined || this.state.g13.length == 0 ? dataG13 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG13 = dataG(this.state.g13)
            this.state.g14 === undefined || this.state.g14.length == 0 ? dataG14 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG14 = dataG(this.state.g14)
            this.state.g15 === undefined || this.state.g15.length == 0 ? dataG15 = <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none", height: 25 }}></div> :
                dataG15 = dataG(this.state.g15)
        }
        // Comments function
        let equalent = this.props.comments.filter(com => com.analysis === "likvärdiga")
        let hierarchy = this.props.comments.filter(com => com.analysis === "hierarkisk")
        let commentsEqualent = equalent.length > 0 ? equalent.map(elem =>
            <tr class="trStyleLine">
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT">{elem.notable}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT">{elem.reason}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT">{elem.todo}</td>
            </tr>
        ) : <tr class="trStyleLine">
            <td style={{ paddingTop: 18, fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT"></td>
        </tr>
        let commentsHierarchy = hierarchy.length > 0 ? hierarchy.map(elem =>
            <tr class="trStyleLine">
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT">{elem.notable}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT">{elem.reason}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT">{elem.todo}</td>
            </tr>
        ) : <tr class="trStyleLine">
            <td style={{ paddingTop: 18, fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT"></td>
        </tr>

        return (
            <div className="aPage">
                <h3 className="h2PaddingTop">{language === "sv" ? "5.2 Likvärdiga arbeten & hierarkiska skillnader" : lang[language].tocEquivalent}</h3>
                {this.props.hideSalaries ?
                    <>
                        <p className="P-document">{language === "sv" ? "Nedan visas arbeten med likvärdiga krav. Varje grupp innehåller arbeten med likvärdiga krav baserat på utförd arbetsvärdering. Högre grupp innebär högre krav. Dessa grupper har även varit underlag för analys av hierarkiska skillnader. Gul flagg innebär att arbetet är kvinnodominerat och blå flagg innebär att arbetet är mansdominerat. Om ingen flagga anses det vara jämställt. Gränsen för överrepresentation är 60%. Spridningen baseras på percentil 10 och 90 i de fall det är 20 eller fler anställda i gruppen, om mindre grupper baseras det på högsta och lägsta lön." : lang[language].eqvOne}
                        </p><p className="P-document">{language === "sv" ? "Nedanför grupperna redovisas vilka kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet. Därefter redovisas analys. I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].eqvTwo}</p>
                    </> :
                    <><p className="P-document">{language === "sv" ? "Nedan visas arbeten med likvärdiga krav. Varje grupp innehåller arbeten med likvärdiga krav baserat på utförd arbetsvärdering. Högre grupp innebär högre krav. Dessa grupper har även varit underlag för analys av hierarkiska skillnader. Gul flagg innebär att arbetet är kvinnodominerat och blå flagg innebär att arbetet är mansdominerat. Om ingen flagga anses det vara jämställt. Gränsen för överrepresentation är 60%. Spridningen baseras på percentil 10 och 90 i de fall det är 20 eller fler anställda i gruppen, om mindre grupper baseras det på högsta och lägsta lön." : lang[language].eqvOne}
                    </p><p className="P-document">{language === "sv" ? "Analys redovisas nedanför grupperna. I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].eqvThree}</p>
                    </>}

                {/*Loop all groups */}
                {this.props.nrOfEqualentGroups === 6 ?
                    <div className={!this.props.hideSalaries ? "wrapperLikvardigaDoko" : "wrapperLikvardigaDokoSmaller"}>
                        <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}1</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1 - 1.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>} </div></div>
                            {dataG1}
                        </div>
                        <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}2</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.5 - 2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                            {dataG2}
                        </div>
                        <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}3</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2 - 2.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                            {dataG3}
                        </div>
                        <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}4</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.5 - 3)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                            {dataG4}
                        </div>
                        <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}5</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3 - 3.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                            {dataG5}
                        </div> <div className="dontCut">
                            <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}6</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.5 - 4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                            {dataG6}
                        </div>
                    </div>
                    : this.props.nrOfEqualentGroups === 9 ?
                        <div className={!this.props.hideSalaries ? "wrapperLikvardigaDoko" : "wrapperLikvardigaDokoSmaller"}>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}1</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1 - 1.33)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG1}</div>
                            <div className="dontCut"> <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}2</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.33 - 1.66)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG2}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}3</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.66 - 1.99)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG3}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}4</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.99 - 2.32)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG4}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}5</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.32 - 2.65)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG5}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}6</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.65 - 2.98)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG6}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}7</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.98 - 3.31)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG7}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}8</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.31 - 3.64)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG8}</div>
                            <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}9</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.64 - 4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                {dataG9}</div>
                        </div>
                        : this.props.nrOfEqualentGroups === 12 ?
                            <div className={!this.props.hideSalaries ? "wrapperLikvardigaDoko" : "wrapperLikvardigaDokoSmaller"}>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}1</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1 - 1.25)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG1}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}2</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.25 - 1.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG2}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}3</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.5 - 1.75)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG3}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}4</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.75 - 2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG4}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}5</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2 - 2.25)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG5}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}6</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.25 - 2.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG6}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}7</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.5 - 2.75)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG7}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}8</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.75 - 3)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG8}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}9</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3 - 3.25)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG9}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}10</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.25 - 3.5)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG10}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}11</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.5 - 3.75)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG11}</div>
                                <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}12</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.75 - 4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                    {dataG12}</div>
                            </div>
                            : this.props.nrOfEqualentGroups === 15 ?
                                <div className={!this.props.hideSalaries ? "wrapperLikvardigaDoko" : "wrapperLikvardigaDokoSmaller"}>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}1</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1 - 1.2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG1}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}2</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.2 - 1.4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG2}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}3</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.4 - 1.6)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG3}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}4</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.6 - 1.8)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG4}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}5</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(1.8 - 2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG5}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}6</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2 - 2.2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG6}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}7</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.2 - 2.4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG7}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}8</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.4 - 2.6)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG8}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}9</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.6 - 2.8)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG9}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}10</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(2.8 - 3)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG10}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}11</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3 - 3.2)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG11}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}12</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.2 - 3.4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG12}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}13</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.4 - 3.6)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG13}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}14</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.6 - 3.8)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG14}</div>
                                    <div className="dontCut"><div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 40, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}15</h4><span style={{ display: 'inline-block', marginLeft: 6, fontSize: 12 }}>(3.8 - 4)</span></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>}</div></div>
                                        {dataG15}</div>
                                </div>
                                : <></>
                }
                {/* Show if salaries is hidden */}
                {this.props.hideSalaries ? <><p style={{ pageBreakBefore: "always" }}><span className='noprint'></span> </p>
                    <p style={{ paddingLeft: 18, paddingRight: 20, paddingTop: 100, paddingBottom: 20 }}>{language === "sv" ? "Nedan redovisas de kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet." : lang[language].eqvFour}</p>
                    {nestedGroups === "" ? <div style={{ padding: 10, border: "1px solid", width: "70%", margin: "auto", textAlign: "center" }}>{language === "sv" ? "Inga kvinnodominerande arbeten har haft lägre lön än icke kvinnodominerade arbeten med lika eller högre krav." : lang[language].eqvFive}</div> : nestedGroups}
                </> : <></>}
                {/* List comments */}
                <div className="dontCut">
                    <h3 style={{ fontSize: "1.1em", paddingTop: 40 }}>{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</h3>
                    <table className="commentsTableDoco" style={{ fontSize: 13, marginLeft: 0 }}>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="3" className="showB color bNoB">{language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                        </tr>
                        {commentsEqualent}
                    </table>
                </div>
                <div className="dontCut">
                    <table className="commentsTableDoco" style={{ fontSize: 13, marginTop: 30, marginLeft: 0 }}>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="3" className="showB color bNoB">{language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                        </tr>
                        {commentsHierarchy}
                    </table>
                </div>
            </div>
        )
    }
}