import React from 'react';
import { ArbetsvarderaExt } from './ArbetsvarderaExt.js';
import { ArbetsvarderaNormal } from './ArbetsvarderaNormal.js';
import { ArbetsvarderaNormalEditable } from './ArbetsvarderaNormalEditable.js';
import { fetchAuth } from '../../fetch-auth';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import videoImg from '../../assets/images/video.png';

let selectedValues = []; //Fix because of bug in MultiSearchSelect

export class Arbetsvardera extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluation: "loadNothing"
        }

    }
    componentDidMount() {
        const runIt = async () => {
            let response = await fetchAuth(`/api/loadArbetsvarderingSetting`, 'POST');
            let data = await response.json();
            if (data[0].whatEvaluation == undefined || data[0].whatEvaluation == null) {
                this.setState({ evaluation: "" })
            } else {
                this.setState({ evaluation: data[0].whatEvaluation })
            }
        }
        runIt();
    }

    render() {
        return (
            this.state.evaluation === "loadNothing" ?
                <>
                    <TopNav />
                    <Menu />
                    <div className="container">

                        <div className="explainer-container">
                            <h1>Arbetsvärdera</h1>
                            <div className="explainer-box" style={{ marginBottom: 0 }}>
                                <div className="explainer-text">
                                    Arbetsvärdering innebär en värdering av arbetets krav. Denna används för analys av likvärdiga arbeten i steg 3.2.
                                    Totalt finner ni 8 områden där ni för varje arbete ska sätta en poäng på 1-4.
                                    Klicka på info symbolen vid respektive område för att se vad bedömningen baseras på och vad ni bör ha i beaktande.

                                    Notera att ni även kan vikta områden som är extra relevanta för er organisation. Klicka på videon för mer information.
                                </div>
                                <div className="video-explainer">
                                    <div className="video-wrapper">
                                        <img src={videoImg} alt="instruktionsvideo" className="video" />
                                    </div>
                                </div>
                            </div>
                            <div className="explainer-box" style={{ paddingTop: 0 }}>
                                <div className="explainer-text" style={{ width: "fit-content", width: "unset" }}>
                                    <p style={{ border: "1px solid grey", borderRadius: 5, padding: 5 }}><b>1</b> = låga krav | <b>2</b> = medelhöga krav |  <b>3</b> = höga krav |  <b>4</b> = mycket höga krav</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : this.state.evaluation === "extLevel" || this.state.evaluation === "extPoint" ? <ArbetsvarderaExt /> : this.state.evaluation === "edited" ? <ArbetsvarderaNormalEditable/> : <ArbetsvarderaNormal/>
        );
    }
}