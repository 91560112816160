export const fetchAuth = async (fetchUrl, sendMethod, bodyData) => {
    if (bodyData === undefined || null) {
        bodyData = ''
    }
    let token = localStorage.getItem('localT')
    let year = localStorage.getItem('year')

    let response = await fetch(fetchUrl, {
        method: sendMethod,
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'Year': year,
        },
        body: bodyData
    });
    //If we get 401 (forbidden) from the fetchUrl, run fetch for refresh token
    if (response.status === 401) {

        let responseT = await fetch(`/api/r/token`, {
            method: 'POST',
            // credentials: 'include',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'Year': year,
            },
        });
        //If refresh was checked but not ok, clear localStorage and redirect to login
        if (responseT.status === 403) {
            //If problem with refresh, delete local storage if exists and then redirect to login
            if (localStorage.getItem("localT") !== null) {
                const keepwarningPrompt = localStorage.getItem('warningPrompt');
                const keepHideSalary = localStorage.getItem('docoHideSalary');
                const keepLanguage = localStorage.getItem('language')
                const keepClickedYear = localStorage.getItem('clickedYear')
                // Save if below is true
                let keepYear = null
                let keepCurrentYearSelected = null
                let keepDateForYearSelected = null
                // *** Fix for popUp and year selection (if should be saved or not) ***
                //If there has been more than 90 days since a previous year was selected, then select current year instead.
                let dateForClickedYear = localStorage.getItem("dateForYearSelected")
                if (dateForClickedYear !== null) {
                    const savedDate = new Date(dateForClickedYear);
                    const currentDate = new Date();
                    const timeDifference = currentDate.getTime() - savedDate.getTime();
                    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
                    if (daysDifference < 90)  {
                        keepYear = localStorage.getItem('year')
                        keepCurrentYearSelected = localStorage.getItem('currentYearSelected')
                        keepDateForYearSelected = localStorage.getItem('dateForYearSelected')
                    }
                }
                localStorage.clear();
                localStorage.setItem('warningPrompt', keepwarningPrompt);
                localStorage.setItem('docoHideSalary', keepHideSalary);
                if (keepLanguage !== null) { localStorage.setItem('language', keepLanguage); }
                if (keepClickedYear !== null) { localStorage.setItem('clickedYear', keepClickedYear); }
                if (keepYear !== null) { localStorage.setItem('year', keepYear); }
                if (keepCurrentYearSelected !== null) { localStorage.setItem('currentYearSelected', keepCurrentYearSelected); }
                if (keepDateForYearSelected !== null) { localStorage.setItem('dateForYearSelected', keepDateForYearSelected); }
            }
            return window.location.href = '/login';
            //return responseT.status
        }
        //If refresh was ok, set local Storage and return the fetch again
        if (responseT.status === 200) {
            let data = await responseT.json();
            // console.log(data)
            localStorage.setItem('localT', data.localToken)
            return await fetch(fetchUrl, {
                method: sendMethod,
                // credentials: 'include',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'Year': year,
                },
                body: bodyData
            });
        }
    }

    //If no errors, then let return the first fetch
    return response;
};


//------------------------------------------------------------------------------------------


//For uploading data we use this function instead
export const fetchAuthUpload = async (fetchUrl, sendMethod, bodyData) => {
    if (bodyData === undefined || null) {
        bodyData = ''
    }
    //console.log('I am fetchAuth!!!')
    let token = localStorage.getItem('localT')

    let response = await fetch(fetchUrl, {
        method: sendMethod,
        credentials: 'same-origin',
        headers: {
            //'Content-Type': 'application/json', 
            'Authorization': token
        },
        body: bodyData,
        encType: 'multipart/form-data' //<---- This is what differs this function and the one above
    });
    //If we get 401 (forbidden) from the fetchUrl, run fetch for refresh token
    if (response.status === 401) {

        let responseT = await fetch(`/api/r/token`, {
            method: 'POST',
            //credentials: 'include',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });
        //If refresh was checked but not ok, clear localStorage and redirect to login
        if (responseT.status === 403) {
            //If problem with refresh, delete local storage if exists and then redirect to login
            if (localStorage.getItem("localT") !== null) {
                localStorage.clear();
            }
            return window.location.href = '/login';
            //return responseT.status
        }
        //If refresh was ok, set local Storage and return the fetch again
        if (responseT.status === 200) {
            let data = await responseT.json();
            //  console.log(data)
            localStorage.setItem('localT', data.localToken)
            return await fetch(fetchUrl, {
                method: sendMethod,
                //credentials: 'include',
                credentials: 'same-origin',
                headers: {
                    //'Content-Type': 'application/json', 
                    'Authorization': token
                },
                body: bodyData,
                encType: 'multipart/form-data'
            });
        }
    }

    //If no errors, then let return the first fetch
    return response;
};