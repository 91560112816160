import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    ImageRun,
    ShadingType
} from "docx";
import lang from '../../Language/language.js';
const percentile = require("percentile");

export const LikvardigaTabellNoSalaries = (persData, whatGroup, tableSpace, nrOfEqualentGroups) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //Calculate likvardiga
    const calculate = (group) => {
        const onlyGroups = group.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)

        const arr = [];
        for (let i = 0; i < uniqueGroups.length; i++) {
            let filteredMale = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i] && person.gender === "man"))
            let filteredWomen = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i] && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //Get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let allEmployees = group.filter(person => (person.groupNameArbVardering === uniqueGroups[i]))
            let getOnlySalaries = []
            for (let i = 0; i < allEmployees.length; i++) { getOnlySalaries.push(allEmployees[i].totalSalary) }
            let lowestSalary = Math.min(...getOnlySalaries)
            let highestSalary = Math.max(...getOnlySalaries)
            let spridningskvot = Math.round((highestSalary / lowestSalary) * 10) / 10
            //If 20 or more persons in the group
            if (allEmployees.length > 19) {
                let salariesInOrder = getOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                let more10 = percentile(10, salariesInOrder)
                let more90 = percentile(90, salariesInOrder)
                spridningskvot = Math.round((more90 / more10) * 10) / 10
            }
            //maybe also median salary...
            let groupData = {
                "equalGroup": uniqueGroups[i],
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "equalentGroup": 1,
                "spridningskvot": spridningskvot
            }
            arr.push(groupData)
        };
        return arr
    }
    //Divide persData by equalent group 
    const group1 = persData.filter(person => person.equalentGroup === 1);
    const group2 = persData.filter(person => person.equalentGroup === 2);
    const group3 = persData.filter(person => person.equalentGroup === 3);
    const group4 = persData.filter(person => person.equalentGroup === 4);
    const group5 = persData.filter(person => person.equalentGroup === 5);
    const group6 = persData.filter(person => person.equalentGroup === 6);
    const group7 = persData.filter(person => person.equalentGroup === 7);
    const group8 = persData.filter(person => person.equalentGroup === 8);
    const group9 = persData.filter(person => person.equalentGroup === 9);
    const group10 = persData.filter(person => person.equalentGroup === 10);
    const group11 = persData.filter(person => person.equalentGroup === 11);
    const group12 = persData.filter(person => person.equalentGroup === 12);
    const group13 = persData.filter(person => person.equalentGroup === 13);
    const group14 = persData.filter(person => person.equalentGroup === 14);
    const group15 = persData.filter(person => person.equalentGroup === 15);
    const g1 = calculate(group1)
    const g2 = calculate(group2)
    const g3 = calculate(group3)
    const g4 = calculate(group4)
    const g5 = calculate(group5)
    const g6 = calculate(group6)
    const g7 = calculate(group7)
    const g8 = calculate(group8)
    const g9 = calculate(group9)
    const g10 = calculate(group10)
    const g11 = calculate(group11)
    const g12 = calculate(group12)
    const g13 = calculate(group13)
    const g14 = calculate(group14)
    const g15 = calculate(group15)

    //RENDER -----------------------------------
    const dataG = (group) => {
        let groupRows = group.map(elem =>
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: `${elem.equalGroup}`,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    elem.womenPercent > 59 || elem.womenPercent < 41 ?
                                        new TextRun({
                                            text: `${elem.womenPercent > 59 ? "K" : elem.womenPercent < 41 ? "M" : "   "}`,
                                            color: `${elem.womenPercent > 59 ? "ffffff" : elem.womenPercent < 41 ? "ffffff" : "ffffff"}`,
                                            bold: true,
                                            font: "Arial",
                                            shading: {
                                                type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                                                fill: `${elem.womenPercent > 59 ? "cac054" : elem.womenPercent < 41 ? "5497ca" : "000000"}`,
                                            }
                                        }) :
                                        new TextRun({
                                            text: "   ",
                                            color: `ffffff`,
                                            bold: true,
                                            font: "Arial",
                                        })
                                    ,
                                    new TextRun({
                                        text: `  ${elem.womenPercent} %`,
                                        font: "Arial",
                                    }),
                                ]
                            }),
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: ` ${elem.totEmployees}`,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: ` ${elem.spridningskvot}`,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                    }),
                ],
            }),
        )
        const groupRowHeader = (group) => {
            let header = ""
            if (nrOfEqualentGroups == 6) {
                if (group === g1) header = (language === "sv" ? "Grupp " : lang[language].group) + "1 (1 - 1,5)"
                if (group === g2) header = (language === "sv" ? "Grupp " : lang[language].group) + "2 (1,5 - 2)"
                if (group === g3) header = (language === "sv" ? "Grupp " : lang[language].group) + "3 (2 - 2,5)"
                if (group === g4) header = (language === "sv" ? "Grupp " : lang[language].group) + "4 (2,5 - 3)"
                if (group === g5) header = (language === "sv" ? "Grupp " : lang[language].group) + "5 (3 - 3,5)"
                if (group === g6) header = (language === "sv" ? "Grupp " : lang[language].group) + "6 (3,5 - 4)"
            }
            if (nrOfEqualentGroups == 9) {
                if (group === g1) header = (language === "sv" ? "Grupp " : lang[language].group) + "1 (1 - 1,33)"
                if (group === g2) header = (language === "sv" ? "Grupp " : lang[language].group) + "2 (1,33 - 1,66)"
                if (group === g3) header = (language === "sv" ? "Grupp " : lang[language].group) + "3 (1,66 - 1,99)"
                if (group === g4) header = (language === "sv" ? "Grupp " : lang[language].group) + "4 (1,99 - 2,32)"
                if (group === g5) header = (language === "sv" ? "Grupp " : lang[language].group) + "5 (2,32 - 2,65)"
                if (group === g6) header = (language === "sv" ? "Grupp " : lang[language].group) + "6 (2,65 - 2,98)"
                if (group === g7) header = (language === "sv" ? "Grupp " : lang[language].group) + "7 (2,98 - 3,31)"
                if (group === g8) header = (language === "sv" ? "Grupp " : lang[language].group) + "8 (3,31 - 3,64)"
                if (group === g9) header = (language === "sv" ? "Grupp " : lang[language].group) + "9 (3,64 - 4)"
            }
            if (nrOfEqualentGroups == 12) {
                if (group === g1) header = (language === "sv" ? "Grupp " : lang[language].group) + "1 (1 - 1,25)"
                if (group === g2) header = (language === "sv" ? "Grupp " : lang[language].group) + "2 (1,25 - 1,5)"
                if (group === g3) header = (language === "sv" ? "Grupp " : lang[language].group) + "3 (1,5 - 1,75)"
                if (group === g4) header = (language === "sv" ? "Grupp " : lang[language].group) + "4 (1,75 - 2)"
                if (group === g5) header = (language === "sv" ? "Grupp " : lang[language].group) + "5 (2 - 2,25)"
                if (group === g6) header = (language === "sv" ? "Grupp " : lang[language].group) + "6 (2,25 - 2,5)"
                if (group === g7) header = (language === "sv" ? "Grupp " : lang[language].group) + "7 (2,5 - 2,75)"
                if (group === g8) header = (language === "sv" ? "Grupp " : lang[language].group) + "8 (2,75 - 3)"
                if (group === g9) header = (language === "sv" ? "Grupp " : lang[language].group) + "9 (3 - 3,25)"
                if (group === g10) header = (language === "sv" ? "Grupp " : lang[language].group) + "10 (3,25 - 3,5)"
                if (group === g11) header = (language === "sv" ? "Grupp " : lang[language].group) + "11 (3,5 - 3,75)"
                if (group === g12) header = (language === "sv" ? "Grupp " : lang[language].group) + "12 (3,75 - 4)"
            }
            if (nrOfEqualentGroups == 15) {
                if (group === g1) header = (language === "sv" ? "Grupp " : lang[language].group) + "1 (1 - 1,2)"
                if (group === g2) header = (language === "sv" ? "Grupp " : lang[language].group) + "2 (1,2 - 1,4)"
                if (group === g3) header = (language === "sv" ? "Grupp " : lang[language].group) + "3 (1,4 - 1,6)"
                if (group === g4) header = (language === "sv" ? "Grupp " : lang[language].group) + "4 (1,6 - 1,8)"
                if (group === g5) header = (language === "sv" ? "Grupp " : lang[language].group) + "5 (1,8 - 2)"
                if (group === g6) header = (language === "sv" ? "Grupp " : lang[language].group) + "6 (2 - 2,2)"
                if (group === g7) header = (language === "sv" ? "Grupp " : lang[language].group) + "7 (2,2 - 2,4)"
                if (group === g8) header = (language === "sv" ? "Grupp " : lang[language].group) + "8 (2,4 - 2,6)"
                if (group === g9) header = (language === "sv" ? "Grupp " : lang[language].group) + "9 (2,6 - 2,8)"
                if (group === g10) header = (language === "sv" ? "Grupp " : lang[language].group) + "10 (2,8 - 3)"
                if (group === g11) header = (language === "sv" ? "Grupp " : lang[language].group) + "11 (3 - 3,2)"
                if (group === g12) header = (language === "sv" ? "Grupp " : lang[language].group) + "12 (3,2 - 3,4)"
                if (group === g13) header = (language === "sv" ? "Grupp " : lang[language].group) + "13 (3,4 - 3,6)"
                if (group === g14) header = (language === "sv" ? "Grupp " : lang[language].group) + "14 (3,6 - 3,8)"
                if (group === g15) header = (language === "sv" ? "Grupp " : lang[language].group) + "15 (3,8 - 4)"
            }
            groupRows.unshift(new TableRow({ //Push to start of table array
                children: [
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: header,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Kvinnor" : lang[language].women,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Anställda" : lang[language].intEmp,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Spridning" : lang[language].spread,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                ],
            }))
        }
        groupRowHeader(group);
        return groupRows
    }
    //let allGroups = getAllGroups();
    if (whatGroup === "g1") return dataG(g1)
    if (whatGroup === "g2") return dataG(g2)
    if (whatGroup === "g3") return dataG(g3)
    if (whatGroup === "g4") return dataG(g4)
    if (whatGroup === "g5") return dataG(g5)
    if (whatGroup === "g6") return dataG(g6)
    if (whatGroup === "g7") return dataG(g7)
    if (whatGroup === "g8") return dataG(g8)
    if (whatGroup === "g9") return dataG(g9)
    if (whatGroup === "g10") return dataG(g10)
    if (whatGroup === "g11") return dataG(g11)
    if (whatGroup === "g12") return dataG(g12)
    if (whatGroup === "g13") return dataG(g13)
    if (whatGroup === "g14") return dataG(g14)
    if (whatGroup === "g15") return dataG(g15)
}