import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    ImageRun,
    ShadingType
} from "docx";
import lang from '../../Language/language.js';
export const likvardigaTabellExt = (persData, tableSpace, whatEvaluation) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //1. Group persData by extEqualentGroups
    const calculate = (group) => {
        const groupedData = Object.values(persData.reduce((result, item) => {
            let g = "" // CHANGE THIS IF WE ARE USING ANOTHER EQUALENT GROUP
            if(whatEvaluation === "extLevel" || whatEvaluation === "extLevel") {g = item.extEqualentGroup}
            if(whatEvaluation === "edited") {g = item.editedEqualentGroup}
            const positionTitle = item.positionTitle;
            const salary = item.totalSalary;
            const gender = item.gender;
            const groupNameArbVardering = item.groupNameArbVardering

            if (!result[g]) {
                result[g] = [];
            }

            const group = result[g].find(group => group.groupNameArbVardering === groupNameArbVardering);
            if (group) {
                group.groups.push(item);
                group.averageSalary += salary;
                group.numberOfPersons++;
                group.percentageOfWomen += (gender === 'woman' ? 1 : 0);
                group.lowestSalary = Math.min(group.lowestSalary, salary);
                group.highestSalary = Math.max(group.highestSalary, salary);
            } else {
                result[g].push({
                    g,
                    positionTitle,
                    groupNameArbVardering, //added this
                    groups: [item],
                    averageSalary: salary,
                    numberOfPersons: 1,
                    percentageOfWomen: (gender === 'woman' ? 1 : 0),
                    lowestSalary: salary,
                    highestSalary: salary
                });
            }
            return result;
        }, {}));

        groupedData.forEach(gGroup => {
            gGroup.forEach(positionGroup => {
                positionGroup.averageSalary /= positionGroup.numberOfPersons;
                positionGroup.percentageOfWomen = Math.round(((positionGroup.percentageOfWomen / positionGroup.numberOfPersons) * 100))
                positionGroup.spreadRatio = Math.round((positionGroup.highestSalary / positionGroup.lowestSalary) * 10) / 10
            });
        });
        //Clone each first object in the groups, used as buggfix for maping them below
        const groupedData2 = groupedData.map((arr) => {
            const clonedObj = { ...arr[0] };
            arr.unshift(clonedObj);
            return arr;
        });
        //2. groupedData2 holds our array of groups, now create a table for each group
        let createdTables = groupedData2.map((group, index) => (

            new Table({
                columns: 4,
                width: 0, // AUTO
                columnWidths: [4450, 1250, 1250, 1250, 1250], // total page width is 9638 DXA for A4 portrait
                layout: TableLayoutType.FIXED,
                rows: group.map((item, index) => (
                    index === 0 ? // groupedData2 cloned the first array item, so we loop this differently for the group header.
                        new TableRow({
                            children: [
                                new TableCell({
                                    shading: {
                                        fill: "f3f3f3",
                                    },
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: (language === "sv" ? "Grupp " : lang[language].group) + item.g, 
                                                    color: "000000",
                                                    bold: true,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),
                                    ],
                                }),
                                new TableCell({
                                    shading: {
                                        fill: "f3f3f3",
                                    },
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Kvinnor" : lang[language].women,
                                                    color: "000000",
                                                    bold: true,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),
                                    ],
                                }),
                                new TableCell({
                                    shading: {
                                        fill: "f3f3f3",
                                    },
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Anställda" : lang[language].intEmp,
                                                    color: "000000",
                                                    bold: true,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),
                                    ],
                                }),
                                new TableCell({
                                    shading: {
                                        fill: "f3f3f3",
                                    },
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Spridning" : lang[language].spread,
                                                    color: "000000",
                                                    bold: true,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),
                                    ],
                                }),
                                new TableCell({
                                    shading: {
                                        fill: "f3f3f3",
                                    },
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Medellön" : lang[language].avgSal,
                                                    color: "000000",
                                                    bold: true,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),
                                    ],
                                })
                            ]
                        })

                        : // If not first item: 

                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${item.groupNameArbVardering}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                item.percentageOfWomen > 59 || item.percentageOfWomen < 41 ?
                                                    new TextRun({
                                                        text: `${item.percentageOfWomen > 59 ? "K" : item.percentageOfWomen < 41 ? "M" : "   "}`,
                                                        color: `${item.percentageOfWomen > 59 ? "ffffff" : item.percentageOfWomen < 41 ? "ffffff" : "ffffff"}`,
                                                        bold: true,
                                                        font: "Arial",
                                                        shading: {
                                                            type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                                                            fill: `${item.percentageOfWomen > 59 ? "cac054" : item.percentageOfWomen < 41 ? "5497ca" : "000000"}`,
                                                        }
                                                    }) :
                                                    new TextRun({
                                                        text: "   ",
                                                        color: `ffffff`,
                                                        bold: true,
                                                        font: "Arial",
                                                    })
                                                ,
                                                new TextRun({
                                                    text: `  ${item.percentageOfWomen} %`,
                                                    font: "Arial",
                                                }),
                                            ]
                                        }),
                                    ]
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: ` ${item.numberOfPersons}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: ` ${item.spreadRatio}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${parseInt(item.averageSalary).toLocaleString()}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                }),
                            ],
                        })
                ))
            })
        ))
        return createdTables
    }
    return calculate();
}