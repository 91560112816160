import React from 'react';
import './Arbetsvardera.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import { PopUp } from './Popup/PopUp.js';
import infoImg from '../../assets/images/info.png';
import exitImg from '../../assets/images/exit.png';
import { fetchAuth } from '../../fetch-auth';
import MultiSearchSelect from "react-search-multi-select";
import { Prompt } from 'react-router'
import { EditValuation } from './EditValuation/editValuation.js';
import editImg from '../../assets/images/edit.png';
import TextareaAutosize from 'react-textarea-autosize';
import resetImg from '../../assets/images/resetIcon.png';
import sortFilter from '../../assets/images/SortFilter.png';
import imgArrWhite from '../../assets/images/rightArrWhite.png';
import imgArrBlack from '../../assets/images/rightArrBlack.png';
import lang from '../Language/language.js';
import settings from '../../assets/images/Settings.png';
import Switch from "react-switch";
import openTab from '../../assets/images/openTab.png';
import arrowPrint from '../../assets/images/arrowPrint.png';
import filterBtn from '../../assets/images/filterBtn.png';
import { NavLink } from 'react-router-dom';
import eyeOn from '../../assets/images/eyeOn.png';
import eyeOff from '../../assets/images/eyeOff.png';
import exportImg from '../../assets/images/export.png';
import { exportEvaluationWord } from './exportEvaluationWord.js'

let selectedValues = []; //Fix because of bug in MultiSearchSelect

export class ArbetsvarderaNormalEditable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemId: "",
            fullValue: [],
            vUtb: 20,
            vProb: 15,
            vSoc: 10,
            vPer: 15,
            vVerk: 15,
            vMans: 10,
            vFys: 5,
            vPsy: 10,
            vTotal: 0,
            saveButtonStatus: "Sparat",
            //For import 
            copyOpen: true,
            importWhat: "",
            importFrom: "",
            concernComps: [],
            values: [],
            showPopUpWarning: "hide",
            equalentGroups: 9, //Should be 6 by default,
            showChangesPrompt: "Yes",
            allWorkNames: [],
            //For edit
            whatEvaluation: "original",
            factors: [],
            factorAndResults: [],
            equalGroups: [],
            weightTotal: 0,
            groupPoints: [],
            percentChange: "No",
            maxGroups: 6,
            //For popUp
            popUpQuestionImport: false,
            activeQuestion: "",
            descPop: false,
            descId: "",
            instructions: "",
            focus: "",
            level1: "",
            level2: "",
            level3: "",
            level4: "",
            level5: "",
            level6: "",
            level7: "",
            nrOfLevels: 4,
            //For filter
            sorting: "alphabet", // alphabet or points
            sortingOpen: false,
             //For settings popUp
             settingValPop: false,
             hideArrows: false,
             popInfoArrows: false,
             //For filter
            filterOpen: false,
            nrFilterOut: 0, 
            filterSearch: "",
            //For edit desc
            whatLevelDesc: null,
            editQuestions: false,
            saveBtn: false,
            showAddBtn: false,
            isHoveringQuestion: false,
            backupLevels: [
                {
                    question: 'Erfarenhet & Utbildning',
                    level1: "Inga eller låga krav på utbildning och relevant arbetslivserfarenhet.",
                    level2: "Kräver eftergymnasial utbildning eller relevant yrkeserfarenhet.",
                    level3: "Kräver hög kunskap. Förvärvad genom hög utbildning eller medelhög utbildning med längre relevant arbetslivserfarenhet.",
                    level4: "Kräver mycket specialiserad eller bred kunskap. Förvärvard genom hög utbildning och lång relevant arbetslivserfarenhet.",
                },
                {
                    question: 'Problemlösning',
                    level1: "Enkla och ofta rutinmässiga uppgifter där problemlösning förekommer i mindre utsträckning. För lösningar finns oftast kända tillvägagångssätt.",
                    level2: "Kräver viss analys och kreativitet. Komplicerade problem förekommer men ska diskuteras med överordnad innan beslut.",
                    level3: "Problemlösning vanligt förekommande. Självständig analys, nytänkande, utvärdering av alternativ inför beslut. Lösningar är sällan givna eller definierade.",
                    level4: "Problemlösning är stor del i arbetet. Varierade, komplexa problem. Kräver kreativa lösningar, ofta nya angreppssätt, kvalificerad kunskapsinhämtning och stor självständighet i beslut.",
                },
                {
                    question: 'Sociala färdigheter',
                    level1: "Inga krav på särskilda sociala färdigheter.",
                    level2: "Färdigheter i kommunikation och samarbete både internt och externt. Kontinuerliga dialoger. Kan innehålla intressemotsättningar.",
                    level3: "Kommunikation är en betydande del i arbetet. Ofta krävande situationer och intressemotsättningar. Kunna vägleda och argumentera.",
                    level4: "Sociala färdigheter avgörande för arbetet. Svåra och komplicerade kontakter med personer/grupper. Ofta stora intressemotsättningar eller svåra situationer.",
                },
                {
                    question: 'Personal',
                    level1: "Har inget personalansvar eller ytterst få medarbetare där arbetsledning är en mycket liten del av arbetet.",
                    level2: "Ansvarar för en mindre grupp anställda. Arbetsledning är en viss del av arbetet.",
                    level3: "Ansvarar för ett betydande antal anställda. Arbetsledning är en stor del av arbetet.",
                    level4: "Övergripande chefsansvar för stora personalgrupper eller avdelningar. Ofta arbetsledning av andra chefer.",
                },
                {
                    question: 'Verksamhet',
                    level1: "Riktlinjer skapat av någon annan finns att följa. Inget eller litet ansvar för planering, utveckling och resultat. Kan delta i planering för egna gruppen.",
                    level2: "Visst ansvar för planering, utveckling eller resultat. Ej nödvändigtvis arbetsledande funktion. Självständigt ansvar för arbetssätt, tidsplanering eller budget.",
                    level3: "Övergripande och strategiskt ansvar för planering, utveckling och resultat. Kan styra och förändra processer inom egna verksamhetsområdet.",
                    level4: "Övergripande affärsmässigt helhetsansvar för en/flera grupper eller verksamhetsområden. Utformar och påverkar varor/tjänster samt normer och riktlinjer som styr."
                },
                {
                    question: 'Människor',
                    level1: "Ansvaret är begränsat till normal hänsyn och omtanke för andras välbefinnande.",
                    level2: "Ett visst ansvar för andras hälsa, utveckling eller säkerhet. Innebär ofta att påverka andra genom att inom ramen följa regler och anvisningar. Hantering av känslig information kan förekomma.",
                    level3: "Betydande ansvar/påverkan på andras hälsa, utveckling eller säkerhet. Självständiga beslut som påverkar andra människor är vanligt.",
                    level4: "Mycket stora krav att självständigt bedöma och fatta avgörande beslut som påverkar andras hälsa, utveckling eller säkerhet. Medför ofta stor påverkan på individer/grupper.",
             },
                {
                    question: 'Fysiska',
                    level1: "Inga särskilda krav på fysisk ansträngning. Risk för skada är mycket liten.",
                    level2: "I perioder kan det krävas fysisk ansträning. Risk för skada är liten.",
                    level3: "Fysisk ansträngning sker dagligen. Långt repetetivt arbete eller tyngre moment under kortare perioder. Viss risk för skada.",
                    level4: "Fysisk ansträngning är en stor del av arbetet. Obekväma arbetställningar förekommer. Stor risk för skada.",
                },
                {
                    question: 'Psykiska',
                    level1: "Låga krav på psykisk ansträngning. Psykiskt påfrestande situationer inträffar sällan.",
                    level2: "Psykiskt påfrestande situationer inträffar ibland. Hög arbetsbelastning och tidspress förekommer i perioder. ",
                    level3: "Psykisk och känslomässig ansträngning förekommer ofta.",
                    level4: "Mycket höga krav på psykisk och känslomässig ansträngning över tid. Höga krav på tillgänglighet, koncentration, tidspress och påfrestande relationer.",
                },
            ]
        }
        this.handleChange = this.handleChange.bind(this);
        // this.percentChange = this.percentChange.bind(this);
        this.popUpNull = this.popUpNull.bind(this);
    }
    componentDidMount() {
        //this.setState({ vTotal: Number(this.state.vUtb) + Number(this.state.vProb) + Number(this.state.vSoc) + Number(this.state.vPer) + Number(this.state.vVerk) + Number(this.state.vMans) + Number(this.state.vFys) + Number(this.state.vPsy) });
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        let hideArrows = localStorage.getItem('hideArrows');
        hideArrows === "true" ? this.setState({ hideArrows: true }) : this.setState({ hideArrows: false })
       const setLanguageForFactors = async () => {
        return new Promise(resolve => {
          let language = localStorage.getItem('language') ?? 'sv';
          if (language !== "sv") {
            this.setState({ backupLevels: lang[language].backUpLevels }, resolve);
          } else {
            resolve(); // Resolve immediately if language is "sv"
          }
        });
      }
      setLanguageForFactors().then(() => { this.getData(); });
    }

    getData() {
        const getIt = async () => {
            let response = await fetchAuth(`/api/loadArbetsvarderingEdited`, 'POST');
            let data = await response.json();
            let factors = []
            let results = []
            let equalGroups = []
            let maxGroups = 6
            let whatLevelDesc = data[3][0][0].whatLevelDesc
            if (whatLevelDesc === "") { whatLevelDesc = null }
            if (data[0] !== undefined && data[0] !== null) { factors = data[0][0] }
            if (data[1] !== undefined && data[1] !== null) { results = data[1][0] }
            if (data[2] !== undefined && data[2] !== null) { equalGroups = data[2][0] }
            if (data[3][0][0].editedNrOfEqualGroups !== undefined && data[3][0][0].editedNrOfEqualGroups !== null) { maxGroups = data[3][0][0].editedNrOfEqualGroups }

            // For import valuations
            let companies = data[4]

            // 1. Calculate equalent groups and points for the groups

            // 1.1 First, set what type of divide for equalentGroup we want to use
            let divideGroupsBy = "groupLimit"
            if ("getSetting" === "settingGet") { divideGroupsBy = "pointLimit" } //<-- OBS!!! Change based on setting

            // 1.2 Calculate maxPoint to use with dividing into groups. Calculated by getting the sum of maxLevel * Weight for each question 
            let maxPoints = 0
            for (const obj of factors) {
                const result = obj.levels * obj.weight;
                maxPoints += result;
            }
            let groupPoints = [] // Floor levels for each equalentGroup. if we have 600 points and 6 groups this will say [100,200,300,400,500,600]


            // 1.3.1 If we use groupLimit, 6,9 or 12, divide based on this 
            if (divideGroupsBy !== "pointLimit") {
                //const interval = maxPoints / maxGroups;

                /* for (let i = 1; i <= maxGroups; i++) {
                     const value = interval * i;
                     groupPoints.push(parseFloat(Math.round(value)));
                 }*/
                const interval = (maxPoints - 100) / maxGroups;

                for (let i = 0; i < maxGroups; i++) {
                    const value = 100 + interval * i;
                    groupPoints.push(Math.round(value));
                }
            }

            // 2. Merge lists of our recieved objects
            let list = [];
            equalGroups.forEach((work) => {
               // let matchingResults = results.filter((res) => res.work.trim() === work.groupNameArbVardering.trim()); //Problem if null
                /*let matchingResults = results.filter((res) => {
                    const resWork = res.work ? res.work.trim() : null;
                    const groupNameArbVardering = work.groupNameArbVardering ? work.groupNameArbVardering.trim() : null;
                  
                    return resWork === groupNameArbVardering;
                  });*/
                   let matchingResults = results.filter((res) => res.work === work.groupNameArbVardering);
                let resultObjects = matchingResults.map((result) => {
                    let factorResult = factors.find((factor) => factor.id === result.questionId);
                    return factorResult
                        ? { answer: result.answer, levels: factorResult.levels, questionId: result.questionId, weight: factorResult.weight }
                        : null;
                });

                list.push({
                    work: work.groupNameArbVardering,
                    equalentGroup: work.editedEqualentGroup,
                    results: resultObjects,
                });
            });

            // 3. Sort the array for factors so they are showing up the same (first sort by category, then by name)
            const factorsSorted = factors.sort((a, b) => {
                if (a.category === b.category) {
                    // If categories are equal, sort by question
                    return a.question.localeCompare(b.question);
                }
                // Sort by category
                const categoryOrder = {
                    Knowledge: 0,
                    Responsibility: 1,
                    Effort: 2
                };
                return categoryOrder[a.category] - categoryOrder[b.category];
            });

            // 4. Now calculate equalentPoint and divide into group

            // 4.1 Get point weighted value
            list.forEach(item => {
                let sum = 0;

                item.results.forEach(result => {
                    sum += result.answer * (result.weight); //For "ordinarie" arbetsvardering result.weight is also divided by 100. but now we skip this
                });
                item.equalentPoint = sum;
                // 4.2 Based on weighted value divide into equalentGroup
                let equalentGroup = null;
                for (let i = 0; i < groupPoints.length; i++) { //<-- OBS kolla så högsta gruppen delas in korrekt <-- FEL! Högsta gruppen delas in om den är lika med högsta poängen, blir inte rättvist. Måste ha en till nivå
                    const start = groupPoints[i];
                    const end = i < groupPoints.length - 1 ? groupPoints[i + 1] : Infinity;
                    if (sum >= start && sum < end) {
                        equalentGroup = i + 1;
                        break;
                    }
                }
                item.equalentGroup = equalentGroup;
            });

            // 5. Add total weight for percentages
            let totalWeightArray = []
            for (let i = 0; i < factors.length; i++) {
                totalWeightArray.push(Number(factors[i].weight))
            }
            const weightTotal = totalWeightArray.reduce((accumulator, currentNumber) => accumulator + currentNumber, 0);

            //Lastly, sort equal groups by name
            list.sort((a, b) => a.work != null ? a.work.localeCompare(b.work != null ? b.work : "") : "".localeCompare(b.work != null ? b.work : ""))
            let sortNullOnTop = [
                ...list.filter(x => x.equalentGroup === null),
                ...list.filter(x => x.equalentGroup !== null)
            ]
            list = sortNullOnTop

            //Also create a list with just the names that will be used for import
            const allWorkNames = list.map((obj) => obj.work);

            this.setState({ factorAndResults: list, factors: factorsSorted, weightTotal: weightTotal, groupPoints: groupPoints, maxGroups: maxGroups, concernComps: companies, allWorkNames: allWorkNames, whatLevelDesc: whatLevelDesc }) //groupPoints is the floor level points for each equalentGroup
        }

        getIt();
    }

    toggleActive = (id) => {
        this.setState({
            activeItemId: id
        });
    }

    togglePopUp = (id) => {
        this.setState({
            showPopUp: id
        });
    }
    descPop = (event) => {
        let id = event.currentTarget.id
        let cleanId = id.replace(/desc/g, "")
        let arrayCopy = this.state.factors.slice()
        let activeItemArray = arrayCopy.filter(item => item.id == cleanId)
        let activeItem = activeItemArray[0]
        let nrOfLevels = activeItem.levels
        if (nrOfLevels === undefined || nrOfLevels === null || nrOfLevels === "") { nrOfLevels = 4 }
        //If all levels are empty and whatLevelDesc === null || "oldStandard" , show add btn:
        let showAddBtn = false
        if ((activeItem.level1 === "" || activeItem.level1 === null) && (activeItem.level2 === "" || activeItem.level2 === null) && (activeItem.level3 === "" || activeItem.level3 === null) && (activeItem.level4 === "" || activeItem.level4 === null) && (activeItem.level5 === "" || activeItem.level5 === null) && (activeItem.level6 === "" || activeItem.level6 === null) && (activeItem.level7 === "" || activeItem.level7 === null)) {
            showAddBtn = true;
        }

        //Here "first" or "standard" we should here check for original levels
        /*  if((this.state.whatLevelDesc === "first" || this.state.whatLevelDesc === "standard") && (activeItem.level1 === "" || activeItem.level1 === null) && (activeItem.level2 === "" || activeItem.level2 === null) && (activeItem.level3 === "" || activeItem.level3 === null) && (activeItem.level4 === "" || activeItem.level4 === null) && (activeItem.level5 === "" || activeItem.level5 === null) && (activeItem.level6 === "" || activeItem.level6 === null) && (activeItem.level7 === "" || activeItem.level7 === null)) {
              let question = activeItem.question
              let backupLevels = this.state.backupLevels
              for (let a of backupLevels) {
                  if (a.question === question) {
                      this.setState({
                          level1: a.level1,
                          level2: a.level2,
                          level3: a.level3,
                          level4: a.level4,
                          level5: a.level5,
                          level6: a.level6,
                          level7: a.level7,
                          activeQuestion: activeItem.question, 
                          showAddBtn: showAddBtn, 
                          descPop: true, 
                          descId: cleanId,
                          instructions: activeItem.instructions, 
                          focus: activeItem.focus, 
                          nrOfLevels: nrOfLevels,
                      })
                      return;
                  }
              }
          }*/
        this.setState({ activeQuestion: activeItem.question, showAddBtn: showAddBtn, descPop: true, descId: cleanId, instructions: activeItem.instructions, focus: activeItem.focus, nrOfLevels: nrOfLevels, level1: activeItem.level1, level2: activeItem.level2, level3: activeItem.level3, level4: activeItem.level4, level5: activeItem.level5, level6: activeItem.level6, level7: activeItem.level7 })
    }
    editQuestionStatus = () => { this.state.editQuestions === false ? this.setState({ editQuestions: true }) : this.setState({ editQuestions: false }) }
    hideDescPop = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.saveBtn) {
            let userConfirmation = window.confirm(language === "sv" ? "Är du säker på att du vill avbryta? Dina ändringar kommer inte att sparas." : lang[language].sureCancel)
            if (userConfirmation) { this.setState({ descPop: false, saveBtn: false, editQuestions: false }) }
        } else {
            this.setState({ descPop: false, saveBtn: false, editQuestions: false })
        }
    }
    instructions = (event) => {
        let whatText = event.target.id
        let textInfo = event.target.value
        this.setState({ [whatText]: textInfo, saveBtn: true })
    }

    addBtnClicked = () => {
        let question = this.state.activeQuestion
        let backupLevels = this.state.backupLevels
        for (let a of backupLevels) {
            if (a.question === question) {
                this.setState({
                    level1: a.level1,
                    level2: a.level2,
                    level3: a.level3,
                    level4: a.level4,
                    level5: a.level5,
                    level6: a.level6,
                    level7: a.level7
                })
                break;
            }
        }
        this.setState({ showAddBtn: false, saveBtn: true })
    }

    saveIt = () => { //Saves descriptions
        let language = localStorage.getItem('language') ?? 'sv';
        const save = async () => {
            let data = {
                id: this.state.descId,
                instructions: this.state.instructions,
                focus: this.state.focus,
                level1: this.state.level1,
                level2: this.state.level2,
                level3: this.state.level3,
                level4: this.state.level4,
                level5: this.state.level5,
                level6: this.state.level6,
                level7: this.state.level7,
                whatLevelDesc: this.state.whatLevelDesc
            }
            let response = await fetchAuth(`/api/saveArbVarderingEditedDesc`, 'POST', JSON.stringify({ data: data }))
            let gotData = await response.json();

            if (gotData !== "Saved") { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
            if (gotData === "Saved") {
                //Also save temporary to state
                let factorCopy = this.state.factors.slice()
                for (let item of factorCopy) {
                    if (item.id == this.state.descId) {
                        item.instructions = this.state.instructions
                        item.focus = this.state.focus
                        item.level1 = this.state.level1
                        item.level2 = this.state.level2
                        item.level3 = this.state.level3
                        item.level4 = this.state.level4
                        item.level5 = this.state.level5
                        item.level6 = this.state.level6
                        item.level7 = this.state.level7
                        break;
                    }
                }
                this.setState({ saveBtn: false, editQuestions: false })
            }
        }
        save();
    }
    handleMouseEnter = () => { this.setState({ isHoveringQuestion: true }); };
    handleMouseLeave = () => { this.setState({ isHoveringQuestion: false }); };

    handleChange = (event) => {
        const whatWork = event.currentTarget.getAttribute('whatWork');
        const questionId = event.currentTarget.getAttribute('questionId');
        const weight = event.currentTarget.getAttribute('weight');
        let value = event.currentTarget.value
        let maxValue = event.currentTarget.max

        if (Number(value) > maxValue || Number(value) < 1) {
            value = ""
        }
        if (value !== "") { value = Number(value) }

        // 1. Add number to object for saving into state
        let factorAndResults = this.state.factorAndResults.slice();

        for (let i = 0; i < factorAndResults.length; i++) {
            if (factorAndResults[i].work === whatWork) {
                let foundAnswer = false;

                for (let a = 0; a < factorAndResults[i].results.length; a++) {
                    if (factorAndResults[i].results[a].questionId == questionId) {
                        factorAndResults[i].results[a].answer = value
                        foundAnswer = true;
                        if (factorAndResults[i].results[a].status != "New") {
                            factorAndResults[i].results[a].status = "Updated"
                        }
                        break;
                    }
                }
                if (!foundAnswer) {
                    const newAnswer = {
                        answer: value,
                        levels: Number(maxValue),
                        questionId: Number(questionId),
                        weight: Number(weight),
                        status: "New", //So we know that this is a new answer
                    };
                    factorAndResults[i].results.push(newAnswer);
                }
                break;
            }
        }

        // 2. Calculate equalentGroup
        // 4.1 Get point weighted value

        let groupPoints = this.state.groupPoints

        for (let i = 0; i < factorAndResults.length; i++) {
            if (factorAndResults[i].work === whatWork) {
                let sum = 0;
                let item = factorAndResults[i]
                item.results.forEach(result => {
                    sum += result.answer * (result.weight); //For "ordinarie" arbetsvardering result.weight is also divided by 100. but now we skip this
                });
                item.equalentPoint = sum;
                //4.2 Divide into equalentGroup based on weighted value
                let equalentGroup = null;
                for (let i = 0; i < groupPoints.length; i++) { 
                    const start = groupPoints[i];
                    // const end = i < groupPoints.length - 1 ? groupPoints[i + 1] - 1 : Infinity; //infinity is used for the last end.
                    // const end = groupPoints[i + 1] - 1; console.log(groupPoints[i +1] -1)// Subtract 1 from the end value console.log("End: " + groupPoints[i + 1])
                    const end = i < groupPoints.length - 1 ? groupPoints[i + 1] : Infinity;
                    if (sum >= start && sum < end) {
                        equalentGroup = i + 1;
                        break;
                    }
                }
                item.equalentGroup = equalentGroup;
                break;
            }
        }
        this.setState({ factorAndResults: factorAndResults, saveButtonStatus: "Spara" })

    }

    handleFocus(e) {
        e.currentTarget.select();
    }

    percentChange = (event) => {
        let id = event.target.id
        let cleanId = id.replace(/weight/g, "")
        let newWeight = event.target.value
        if (Number(newWeight) > 99 || Number(newWeight) < 1) {
            newWeight = ""
        }
        // 1. Update factor object in state
        let arrayCopy = this.state.factors.slice()
        let updatedArray = arrayCopy.map(el => {
            if (el.id == cleanId)
                return Object.assign({}, el, { weight: newWeight })
            return el
        });

        this.setState({ factors: updatedArray }, () => {
            // 2. Calculate and update percentage check calculation to make sure it does not exceed 100% (green/red box)
            let totalWeightArray = []
            for (let i = 0; i < this.state.factors.length; i++) {
                totalWeightArray.push(Number(this.state.factors[i].weight))
            }
            const weightTotal = totalWeightArray.reduce((accumulator, currentNumber) => accumulator + currentNumber, 0);

            // 3. Recalculate equalentGroups for alla works.

            let list = this.state.factorAndResults.slice()
            let groupPoints = this.state.groupPoints

            list.forEach(item => {
                let sum = 0;

                item.results.forEach(result => {
                    if (result.questionId == cleanId) {
                        result.weight = newWeight
                    }
                    sum += result.answer * (result.weight); //For "ordinarie" arbetsvardering result.weight is also divided by 100. but now we skip this
                });
                item.equalentPoint = sum;
                // 4.2 Based on weighted value divide into equalentGroup
                let equalentGroup = null;
                for (let i = 0; i < groupPoints.length; i++) { //<-- OBS kolla så högsta gruppen delas in korrekt <-- FEL! Högsta gruppen delas in om den är lika med högsta poängen, blir inte rättvist. Måste ha en till nivå
                    const start = groupPoints[i];
                    const end = i < groupPoints.length - 1 ? groupPoints[i + 1] : Infinity;
                    if (sum >= start && sum < end) {
                        equalentGroup = i + 1;
                        break;
                    }
                }
                item.equalentGroup = equalentGroup;
            });


            this.setState({ weightTotal: weightTotal, factorAndResults: list, percentChange: "Yes", saveButtonStatus: "Spara" })
        });
    }

    popUpNull() { //This is sent to the PopUp child component
        this.setState({
            showPopUp: null
        });
    }
    popUpQuestionImport = () => this.state.popUpQuestion ? this.setState({ popUpQuestion: false }) : this.setState({ popUpQuestion: true })

    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.weightTotal == 100) {
            const saveData = async () => {
                const factors = this.state.factors // Use to save weight
                const factorAndResults = this.state.factorAndResults //Use to save results

                let response = await fetchAuth(`/api/saveArbetsvarderingEdited`, 'POST', JSON.stringify({ data: [factors, factorAndResults, this.state.percentChange, this.state.maxGroups] }));
                let data = await response.json();
                data === "Saved" ? this.setState({ saveButtonStatus: "Sparat" }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
            };
            saveData();
        } else {
            alert(language === "sv" ? 'Kan inte spara. Den totala viktningen måste vara 100%. Vänligen justera viktningen och spara sedan igen.' : lang[language].weightNotH)
        }
    }

    toggleCopy = () => {
        if (this.state.copyOpen) {
            this.setState({ copyOpen: false })
        } else this.setState({ copyOpen: true })
    }
    whatToImport = (event) => {
        this.setState({ importWhat: event.target.value });
    }
    importFrom = (event) => {
        this.setState({ importFrom: event.target.value });
    }
    handleChangeForMultiSelect = (arr) => { //<-- For react-search-multi-select
        if (arr.length > 0) {
            selectedValues = arr
        }
    }


    import = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const importIt = async () => {
            const importFrom = this.state.importFrom
            const importWhat = this.state.importWhat
            let data = ""
            let factorAndResults = this.state.factorAndResults.slice()
            if (importFrom === "" || importWhat === "") { //all, empty, selected
                alert(language === "sv" ?  "Du måste välja vad och ifrån vilket bolag du vill importera." : lang[language].selectWhatComp)
                return;
                //Import all
            } else if (importWhat === "all") {
                data = { from: importFrom, what: "all", positions: this.state.allWorkNames }
                //Import empty ones
            } else if (importWhat === "empty") {
                let emptyPositions = [];
                for (let item of factorAndResults) { if (item.equalentGroup === null) { emptyPositions.push(item.work) } }
                if (emptyPositions.length === 0) {
                    alert(language === "sv" ? "Inga befattningar saknar gruppindelning." : lang[language].noPosMissGroup)
                    return;
                }
                data = { from: importFrom, what: "empty", positions: emptyPositions }
                //import selected ones
            } else if (importWhat === "selected") {
                if (selectedValues.length > 0) {
                    data = { from: importFrom, what: "selected", positions: selectedValues }
                } else if (selectedValues.length === 0) {
                    alert(language === "sv" ? "Du har inte valt några befattningar." : lang[language].noPosSelected)
                    return;
                }
            }
            //Copy from the server
            const response = await fetchAuth(`/api/importArbetsvarderingEdited`, 'POST', JSON.stringify({ data: data }));
            //If error
            if (response.status !== 200) {
                if (response.status === 400) { alert(language === "sv" ? "Kan ej importera. Vald organisation använder inte samma faktorplan." : lang[language].cantImportFactor) } 
                else if (response.status === 422) { alert(language === "sv" ? "Inget importerat. Det fanns inga värderingar att importera för arbetena." : lang[language].nothingImported) }
                else { alert(language === "sv" ? "Något gick fel, försök igen." : lang[language].somethingWrong) }
                this.setState({ showPopUpWarning: "hide" })
                return;
            }
            if (response.status === 200) {
                let resData = await response.json();
                const updatedWorks = resData[0]
                if (updatedWorks.length > 0) {
                    //Update all equal groups
                    let factorAndResults = this.state.factorAndResults.slice()
                    //Replace factors with same work
                    for (let i = 0; i < factorAndResults.length; i++) {
                        let obj1 = factorAndResults[i];
                        for (let j = 0; j < updatedWorks.length; j++) {
                            let obj2 = updatedWorks[j];
                            if (obj1.work === obj2.work) {
                                factorAndResults[i] = obj2;
                                break;
                            }
                        }
                    }
                    this.setState({ factorAndResults: factorAndResults, showPopUpWarning: "finished" })
                    return;
                } else {
                    alert(language === "sv" ? "Inga motsvarande grupperingar kunde hittas. Säkerställ att du valt rätt bolag att importera ifrån." : lang[language].noPosFound) 
                    return;
                }
            }
        }
        importIt();
    }

    toggleWarning = () => (this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? this.setState({ showPopUpWarning: "hide" }) : this.setState({ showPopUpWarning: "start" })
    sorting = (event) => {
        let sortBy = event.target.id
        if(sortBy === this.state.sorting) {return}
        else {
            this.setState({sorting: sortBy, sortingOpen: false})
            // Sort here
            let allWorks = this.state.factorAndResults.slice()

            if(sortBy === "points") {
            allWorks.sort((a, b) => (a.equalentPoint === null ? -1 : b.equalentPoint === null ? 1 : a.equalentPoint - b.equalentPoint));
            this.setState({factorAndResults: allWorks})
            return;
            }
            if(sortBy === "alphabet") {
              //  allWorks.sort((a, b) => a.work.localeCompare(b.work))
                allWorks.sort((a, b) => (a.work ?? '').localeCompare(b.work ?? ''));
                this.setState({factorAndResults: allWorks})
                return
            }
        }
    }
    sortingOpen = () => {this.state.sortingOpen ? this.setState({sortingOpen: false, settingValPop: false}) : this.setState({exportPopUp: false, sortingOpen: true, settingValPop: false})}
    settingValPop = () => { this.state.settingValPop ? this.setState({ exportPopUp: false, settingValPop: false, sortingOpen: false, popInfoArrows: false }) : this.setState({ settingValPop: true, sortingOpen: false, popInfoArrows: false }) }
    popInfoArrows = () => { this.state.popInfoArrows ? this.setState({ popInfoArrows: false }) : this.setState({ popInfoArrows: true }) }
    switchArrows = () => {
        this.state.hideArrows ?
            this.setState({ hideArrows: false }, () => { localStorage.setItem('hideArrows', 'false') }) :
            this.setState({ hideArrows: true }, () => { localStorage.setItem('hideArrows', 'true') })
    }
    filterOpen = () => { this.state.filterOpen ? this.setState({ filterOpen: false }) : this.setState({ exportPopUp: false, filterOpen: true, sortingOpen: false, settingValPop: false }) }
    handleFilter = (event) => {
        let workA = event.currentTarget.dataset.filterwork
        let theList = this.state.factorAndResults
        const updatedArray = theList.map(obj => {
            // Check if the groupName property matches the given string
            if (obj.work === workA && !obj.filterOut) {
                // Return a new object with the filterOut property added
                return { ...obj, filterOut: true };
            }
            if (obj.work === workA && obj.filterOut) {
                obj.filterOut = false
            }
            // Return the original object if no match
            return obj;
        });
        const countFilterOutTrue = updatedArray.filter(obj => obj.filterOut === true).length;
        this.setState({ factorAndResults: updatedArray, nrFilterOut: countFilterOutTrue })
    }
    filterAll = () => {
        let theList = this.state.factorAndResults;
        const updatedArray = theList.map(obj => {
            return { ...obj, filterOut: true };
        });
        this.setState({ factorAndResults: updatedArray, nrFilterOut: updatedArray.length, filterSearch: "" });
    }
    removeFilter = () => {
        let theList = this.state.factorAndResults;
        const updatedArray = theList.map(obj => {
            return { ...obj, filterOut: false };
        });
        this.setState({ factorAndResults: updatedArray, nrFilterOut: 0, filterSearch: "" });
    }
    filterSearch = (event) => {
       let searchString = event.target.value
        this.setState({filterSearch: searchString})
    }
    clearSearch = () => {  this.setState({filterSearch: ""})}

    exportPopUp = () => { this.state.exportPopUp ? this.setState({ exportPopUp: false }) : this.setState({ exportPopUp: true }) }

    exportThis = async (event) => {
        let exportWhat = event.target.id
        if (exportWhat === "exportFactors") { // Word
            let response = await fetchAuth(`/api/loadDocFields`, 'POST');
            let data = await response.json();
            let originalFactors = data[17][0]
            let whatLevelDesc = data[18]
            let editedFactors = data[16][0]
            let whatEvaluation = ""
            let valuationName = ""
            const vikt = data[1][0]; //<-- change to data[1] only if error
            if (data[8][0].length != 0) {
                if (data[8][0].whatEvaluation != undefined) {
                    whatEvaluation = data[8][0].whatEvaluation
                }
                if (data[8][0].valuationName != undefined) {
                    valuationName = data[8][0].valuationName
                }
            }
            exportEvaluationWord(vikt, whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc) // whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc
        }
        else if (exportWhat === "exportEvaluation") { // Excel
            let language = localStorage.getItem('language') ?? 'sv';
            let name = language === "sv" ? "Arbetsvardering" : lang[language].jobEvaluation

            let valuationData = {
                valuations: this.state.factorAndResults,
                factors: this.state.factors
            }
            
            const response = await fetchAuth(`/api/exportEquivalentNormalEditable`, 'POST', JSON.stringify({ data: valuationData, language: language }));
            const blob = await response.blob();
            // Create a download link for the Blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.xlsx'); // File name
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link after downloading
            link.parentNode.removeChild(link);
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        {/* PopUp */ }
        let popUp = <></>
        let bulletCreatorInstructions = this.state.instructions.split('-').filter(item => item.trim() !== '').map((item, index) => (
            <li className="popUp-list" key={index}>
                {item.trim()}
            </li>
        ));
        let bulletCreatorFocus = this.state.focus.split('-').filter(item => item.trim() !== '').map((item, index) => (
            <li className="popUp-list" key={index}>
                {item.trim()}
            </li>
        ));

        if (this.state.descPop && !this.state.editQuestions && this.state.showAddBtn) {
            popUp = <div className="popUp active-popUp" style={{ fontSize: 15, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.hideDescPop} />
                </div>
                <div style={{ position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden", minHeight: 239 }}>
                    <div className="textblock-popup-left" style={{ height: "auto", background: "#f8f8f8", borderRight: "1px solid #dddddd", width: 285, marginLeft: -20, paddingLeft: 60 }}>
                        <h4 style={{ marginLeft: -17, fontSize: 15.6, marginBottom: 16 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                        <ul>
                            {bulletCreatorInstructions}
                        </ul>
                    </div>
                    <div className="textblock-popup-right" style={{ height: "auto", paddingLeft: 25, width: 405 }}>
                        <h4 style={{ fontSize: 15.6, marginBottom: 16 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                        <ul className="ulRight" style={{ marginLeft: "15px !important", paddingLeft: 15 }}>
                            {bulletCreatorFocus}
                        </ul>
                    </div>
                </div>
            </div>
        }

        if (this.state.descPop && !this.state.editQuestions && !this.state.showAddBtn) {
            popUp = <div className="popUp active-popUp" style={{ fontSize: 13.6, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.hideDescPop} />
                </div>
                <div style={{ position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden" }}>
                    <div className="textblock-popup-left" style={{ float: "left", height: "auto", width: 355, paddingLeft: 30, background: "#f8f8f8", borderRight: "1px solid #dddddd", paddingBottom: 30, minHeight: 239 }}>
                        <div>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginLeft: -13, marginTop: 0 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                            <ul>
                                {bulletCreatorInstructions}
                            </ul>
                        </div>
                        <div>
                            <h4 style={{ marginTop: 24, fontSize: 15.6, marginBottom: 16, marginLeft: -13 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                            <ul className="ulRight">
                                {bulletCreatorFocus}
                            </ul>
                        </div>
                    </div>
                    <div className="textblock-popup-right" style={{ float: "left", width: 345, height: "auto", paddingBottom: 30 }}>

                        <div style={{ width: 365 }}>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginTop: 0 }}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</h4>
                            <div className="popUp-list testar"><span className="aNumber">1</span><span className="besideNumber">{this.state.level1}</span></div>
                            <div className="popUp-list testar"><span className="aNumber">2</span><span className="besideNumber">{this.state.level2}</span></div>
                            {this.state.nrOfLevels > 2 && <div className="popUp-list testar"><span className="aNumber">3</span><span className="besideNumber">{this.state.level3}</span></div>}
                            {this.state.nrOfLevels > 3 && <div className="popUp-list testar"><span className="aNumber">4</span><span className="besideNumber">{this.state.level4}</span></div>}
                            {this.state.nrOfLevels > 4 && <div className="popUp-list testar"><span className="aNumber">5</span><span className="besideNumber">{this.state.level5}</span></div>}
                            {this.state.nrOfLevels > 5 && <div className="popUp-list testar"><span className="aNumber">6</span><span className="besideNumber">{this.state.level6}</span></div>}
                            {this.state.nrOfLevels > 6 && <div className="popUp-list testar"><span className="aNumber">7</span><span className="besideNumber">{this.state.level7}</span></div>}
                        </div>
                    </div>
                </div>
            </div>
        }

        if (this.state.descPop && this.state.editQuestions) {
            popUp = <div className="popUp active-popUp" style={{ fontSize: 13.6, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.hideDescPop} />
                </div>
                <div style={{ marginBottom: -60, position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden" }}>
                    <div className="textblock-popup-left" style={{ float: "left", height: "auto", width: 355, paddingLeft: 30, background: "#f8f8f8", borderRight: "1px solid #dddddd", paddingBottom: 30 }}>
                        <div>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginLeft: -13, marginTop: 0 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                            <TextareaAutosize value={this.state.instructions} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="instructions" />
                        </div>
                        <div>
                            <h4 style={{ marginTop: 24, fontSize: 15.6, marginBottom: 16, marginLeft: -13 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                            <TextareaAutosize value={this.state.focus} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="focus" />

                        </div>
                    </div>
                    <div className="textblock-popup-right" style={{ float: "left", width: 345, height: "auto", paddingBottom: 80 }}>

                        <div style={{ width: 385 }}>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginTop: 0 }}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</h4>
                            {this.state.showAddBtn ?
                                <div>
                                    <span>{language === "sv" ? "Här kan ni lägga till mer detaljerade nivåbeskrivningar." : lang[language].infoDetailedLevelDesc}</span>
                                    <div className="edit-levels-small-btn" onClick={this.addBtnClicked}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</div><span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className="infoBoxEditQuestionBox">?</span>
                                    <div className={this.state.isHoveringQuestion ? "infoBoxEditLevels" : "hide"}>{language === "sv" ? "Detaljerade nivåbeskrivningar kan hjälpa till att konkretisera arbetsvärderingen och göra det enklare att förstå vad varje nivå innebär." : lang[language].infoDeailLevels}<p style={{ marginBottom: 0 }}>{language === "sv" ? `Om ni klickar på lägg till kommer färdiga nivåbeskrivningar att hämtas, dessa kan även justeras vid behov.`: lang[language].infoDetailLevelsTwo}</p></div>
                                </div>
                                :
                                <>
                                    <div className="popUp-list testar"><span className="aNumber">1</span><TextareaAutosize value={this.state.level1} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level1" /></div>
                                    <div className="popUp-list testar"><span className="aNumber">2</span><TextareaAutosize value={this.state.level2} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level2" /></div>
                                    {this.state.nrOfLevels > 2 && <div className="popUp-list testar"><span className="aNumber">3</span><TextareaAutosize value={this.state.level3} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level3" /></div>}
                                    {this.state.nrOfLevels > 3 && <div className="popUp-list testar"><span className="aNumber">4</span><TextareaAutosize value={this.state.level4} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level4" /></div>}
                                    {this.state.nrOfLevels > 4 && <div className="popUp-list testar"><span className="aNumber">5</span><TextareaAutosize value={this.state.level5} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level5" /></div>}
                                    {this.state.nrOfLevels > 5 && <div className="popUp-list testar"><span className="aNumber">6</span><TextareaAutosize value={this.state.level6} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level6" /></div>}
                                    {this.state.nrOfLevels > 6 && <div className="popUp-list testar"><span className="aNumber">7</span><TextareaAutosize value={this.state.level7} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level7" /></div>}
                                </>
                            }
                        </div>
                    </div>

                </div>
                <div style={{ bottom: 0, position: "sticky", right: 4, float: "right" }}>
                    <div className={this.state.saveBtn ? "edit-levels-save" : "edit-levels-save-grey"} onClick={this.state.saveBtn && this.saveIt}>{language === "sv" ? "Spara" : lang[language].save}</div>
                </div>
            </div>
        }
        {/* End of PopUp */ }

        let arbetsvaderingList = "";
        const factorAndResults = this.state.factorAndResults
        if (factorAndResults.length > 0) {
            // arbetsvaderingList = factorAndResults.map((item, index) => (
            arbetsvaderingList = factorAndResults.map((item, index) => (
                /*<li key={index}> */
                <li style={{ display: item.filterOut ? "none" : "list-item" }} id={item.work} key={index} className={this.state.activeItemId === `${item.work}` ? "valuation-li li-active" : "valuation-li"} onClick={name => this.toggleActive(name.currentTarget.id)}>

                    <div className="position" >{item.work}</div>
                    <div className="answers-container">
                        <div className="titlesInside">
                            {this.state.factors.map(factor => {
                                const result = item.results.find(result => result.questionId === factor.id);
                                const inputValue = result ? result.answer : '';
                                const maxLevel = result ? result.levels : factor.levels; //Here we need to add max level even if there is no answer.
                                const weight = result ? result.weight : factor.weight;
                                return (
                                    <input type="number" min={1} max={maxLevel} value={inputValue} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id={item.work + factor.id} whatWork={item.work} questionId={factor.id} weight={weight} onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                                );
                            })}
                            <span className={item.equalentGroup === null || "" ? "equalentHide" : "equalentSpan"}>{item.equalentGroup === null || "" ? "" : item.equalentGroup}</span>
                        </div>
                    </div>
                </li>
            ))
        }
        //List companies for MultiSearchSelect
        let companySelection = ["Inga bolag hittades"]
        if(this.state.concernComps !== null && this.state.concernComps !== undefined ) {
        if (this.state.concernComps.length > 0) {
            //Here add list of companies to choose from <-- FOR SELECTED LIST
            companySelection = this.state.concernComps.map(company => {
                return <option value={company.dotterBolag}>{company.dotterBolag}</option>
            });
        }
    }

        let popUpQuestion = <></>
        if (this.state.popUpQuestion === true) {
            popUpQuestion = <div id="utbildning" className="popUp active-popUp" style={{ maxWidth: 650, height: "auto" }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.popUpQuestionImport} />
                <div style={{ padding: 20, textAlign: "left", fontSize: 14.6 }}>
                {language === "sv" ? "Du kan importera och återanvända arbetsvärderingar från andra bolag i er koncern. Ändringarna du gör i ett bolag påverkar inte de andra." : lang[language].importOther}
                    <p></p><b>{language === "sv" ? "Såhär gör du:" : lang[language].howTo}</b>
                    {language === "sv" ? <ul>
                        <li><b>1)</b> Klicka på knappen "Hämta värderingar"</li>
                        <li><b>2)</b> Välj vilket bolag du vill importera ifrån.</li>
                        <li><b>3)</b> Välj vad du vill importera.</li>
                        <li><b>4)</b> Klicka på importera.</li>
                    </ul> : lang[language].howToSteps}
                </div>
            </div>
        }
        let popUpQuestionBackground = <></>
        if (this.state.popUpQuestion === true) {
            popUpQuestionBackground = <div className="popUpFader"></div>
        }

        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container containerFixForValuation">
                    <Information step="arbetsvarderaEdited" />
                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>
                    {popUp}
                    <div className={this.state.descPop === false ? "hide" : "popUpFader"}></div>
                    {/* QuestionMark popUp */}
                    {popUpQuestion}
                    {popUpQuestionBackground}
                    { /* Warning popUp */}
                    <div className={(this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? "popUp active-popUp" : "popUp"} style={{ padding: 40, width: 400, height: "auto" }}>
                        {this.state.showPopUpWarning === "start" ?
                            <>
                                {language === "sv" ? "Är du säker på att du vill importera?" : lang[language].jobEPop}
                                <div>
                                    <div className="noButton" onClick={this.toggleWarning}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                    <div className="yesButton" onClick={this.import}>{language === "sv" ? "Fortsätt" : lang[language].continue}</div>
                                </div>
                            </>
                            :
                            <>
                               {language === "sv" ? "Data importerad!" : lang[language].jobEImported}
                                <div>
                                    <div className="yesButton" onClick={this.toggleWarning}>Ok</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={this.state.showPopUpWarning !== "hide" ? "popUpFader" : "hide"}></div>

                    <div className="evaluate-container" style={{marginTop: 0, marginBottom: 100}}>
                        {/* Import */}
                        <div style={{ paddingTop: 20 }} className={this.state.copyOpen ? "copyClosed" : "copyClosed openCopyCont"}>

                            <div className={this.state.copyOpen ? "copyButton" : "copyButton openCopyCont"} >
                                <span style={{ paddingTop: 20, paddingBottom: 20, marginLeft: -4, paddingLeft: 10, fontSize: 14.6 }} onClick={this.toggleCopy}>{language === "sv" ? "Hämta värderingar" : lang[language].importEvaluation}</span>
                                <select onChange={this.importFrom} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany">
                                    <option value=""> {language === "sv" ? "Importera ifrån.." : lang[language].importFrom}</option>
                                    {companySelection}
                                </select>
                                <select onChange={this.whatToImport} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseWhat" >
                                    <option value=""> {language === "sv" ? "Vad vill du importera?" : lang[language].importWhat}</option>
                                    <option value="all"> {language === "sv" ? "Allt" : lang[language].everything} </option>
                                    <option value="empty"> {language === "sv" ? "Endast tomma" : lang[language].onlyEmpty}</option>
                                    <option value="selected">  {language === "sv" ? "Enskilda grupper" : lang[language].specificGroups} </option>
                                </select>
                                <div className={this.state.importWhat === "selected" ? "showMultiSearch" : "hide"}>
                                    <MultiSearchSelect
                                        searchPlaceholder="Välj grupper"
                                        searchable={true}
                                        showTags={false}
                                        multiSelect={true}
                                        width="180px"
                                        onSelect={this.handleChangeForMultiSelect}
                                        options={this.state.allWorkNames}
                                        className={this.state.copyOpen ? "hide" : "multiSearch"}
                                        primaryColor="white"
                                        secondaryColor="white"
                                        textSecondaryColor="#333333"
                                    />
                                </div>
                                <span onClick={this.toggleWarning} className={this.state.copyOpen ? "hiddenButton" : "showButton"}>{language === "sv" ? "Importera" : lang[language].groupImport}</span>
                                <span onClick={this.toggleCopy} className={this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 30 }}>x</span>
                            </div>
                            <span className="questionM" onClick={() => this.popUpQuestionImport()}>?</span>


                        </div>

                         {/* Filter Button */}
                         <div style={{ marginRight: 70 }} className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}>
                            <div className={this.state.nrFilterOut !== 0 ? "notificationFilter" : "hide"}>{this.state.nrFilterOut}</div>
                            <img onClick={this.filterOpen} src={filterBtn} width={9} style={{ marginRight: 5, opacity: 0.45 }} /><span style={{ paddingTop: 9, paddingBottom: 9, color: "#333333" }} onClick={this.filterOpen}>{language === "sv" ? "Filtrera" : lang[language].filter}</span>
                            <div style={{ width: 270, marginLeft: -222, paddingBottom: 0, cursor: "auto" }} className={this.state.filterOpen ? "filterValuationBtnBox" : "hide"}>
                                {/* <div>OBS: Alla arbeten måste arbetsvärderas</div> */}
                                <div style={{ borderBottom: "1px solid #cccccc", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9 }}><span style={{ padding: 5, paddingLeft: 0, float: "left", paddingTop: 0 }}><span className="notificationDetails" style={{fontSize: 11, background: this.state.nrFilterOut !== 0 ? "#c95454" : "#56cbae", width: this.state.nrFilterOut > 9 ? 25 : 20, borderRadius: this.state.nrFilterOut > 9 ? 14 : "50%"}}>{this.state.nrFilterOut}</span> {language === "sv" ? "arbeten döljs." : lang[language].hiddenWorks}</span>
                                    {/*} <span className="filterShowAllBtn">Rensa filter</span>*/}
                                    <span onClick={this.removeFilter} style={{ cursor: "pointer", borderLeft: "1px solid #e8e8e8", float: "right", marginLeft: 9, paddingLeft: 9, color: "#40a18b", textDecoration: "underline", marginTop: 1, marginRight: 5 }}>{language === "sv" ? "Visa alla" : lang[language].showAll}</span>
                                    <span onClick={this.filterAll} style={{ cursor: "pointer", float: "right", marginLeft: 5, paddingLeft: 5, color: "#40a18b", textDecoration: "underline", marginTop: 1 }}>{language === "sv" ? "Dölj alla" : lang[language].hideAll}</span>
                                <div>
                                   <input className="searchBoxFilterFocus" type="text" placeholder={language === "sv" ? "Sök" : lang[language].search} value={this.state.filterSearch} onChange={this.filterSearch} style={{fontSize: 13, border: "1px solid #c8c8c8", width: 251, height: 24, cursor: "auto", marginBottom: 9, paddingLeft: 9, textAlign: "left"}} />
                               <img onClick={this.clearSearch} style={{display: this.state.filterSearch.trim().length > 0 ? "inline" : "none", width: 14, position: "absolute", marginLeft: -24,marginTop: 9.5,opacity: 0.5,cursor: "pointer"}} src={exitImg} />
                                </div>
                                </div>


                                <ul className="filterScrollUl">
                                    {this.state.factorAndResults.map((work) =>
                                        <li data-filterwork={work.work} onClick={this.handleFilter} style={{ display: work.work !== null && work.work.toLowerCase().includes(this.state.filterSearch.toLowerCase().trim()) ? "list-item" : "none", cursor: "pointer", paddingTop: 10, paddingBottom: 9, borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9 }}><img src={work.filterOut ? eyeOff : eyeOn} style={{ opacity: work.filterOut ? 0.5 : 1, width: 20, verticalAlign: "top", marginTop: -1.5 }} /><span style={{ opacity: work.filterOut ? 0.5 : 1, display: "inline-block", marginLeft: 9, verticalAlign: "top", width: 220, overflowWrap: "break-word" }}>{work.work}</span></li>
                                        //  <li data-filterwork="Drifttekniker" onClick={this.handleFilter} style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOn} style={{width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Drifttekniker</span></li>
                                        //  <li style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOn} style={{width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Fabriksarbetare</span></li>
                                        //  <li style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOff} style={{opacity: 0.5, width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{opacity: 0.5, display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Inköpare</span></li>
                                    )}
                                </ul>

                            </div>
                        </div>

                         {/* Sort Button */}
                         <div style={{ marginRight: language === "sv" ? 160 : 150 }} className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}><img onClick={this.sortingOpen} src={sortFilter} width={9.5} style={{ marginRight: 5, opacity: 0.7 }} /><span style={{paddingTop: 9, paddingBottom: 9, color: "#333333"}} onClick={this.sortingOpen}>{language === "sv" ? "Sortera" : lang[language].sort}</span>
                        <div className={this.state.sortingOpen ? "filterValuationBtnBox" : "hide"}>
                            <div id="alphabet" onClick={this.sorting} style={{paddingBottom: 10}} className={this.state.sorting === "alphabet" ? "" : "greyFilterItem"}><img src={this.state.sorting === "alphabet" ? imgArrBlack : imgArrWhite} width={16} style={{marginRight: 5,verticalAlign: "top"}} />{language === "sv" ? "Bokstavsordning" : lang[language].sortAlpha}</div>
                            <div id="points" onClick={this.sorting} className={this.state.sorting === "points" ? "" : "greyFilterItem"}><img src={this.state.sorting === "points" ? imgArrBlack : imgArrWhite} width={16} style={{marginRight: 5,verticalAlign: "top"}} />{language === "sv" ? "Krav / Totalpoäng" : lang[language].sortLevel}</div>
                        </div>
                        </div>
                         {/* Settings button */}
                         <div className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}><img onClick={this.settingValPop} src={settings} width={16} style={{ opacity: 0.4, filter: "invert(100%)", verticalAlign: "top" }} />
                            <div style={{ marginLeft: -199, width: 205, cursor: "default" }} className={this.state.settingValPop ? "filterValuationBtnBox" : "hide"}>
                                <div id="alphabet" style={{ padding: 5, paddingLeft: 0, paddingRight: 0 }} className={this.state.sorting === "alphabet" ? "" : "greyFilterItem"}>
                                    <Switch width={41} height={21} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.switchArrows} checked={this.state.hideArrows} />
                                    <div style={{ display: "inline-block", float: "right" }}>
                                        {language === "sv" ? "Dölj pilar i poängfält" : lang[language].hideArrows}
                                        <div className={this.state.popInfoArrows ? "popUpBoxHideArrow" : "hide"}>
                                            <img onClick={this.popInfoArrows} className="popUpHideArrowExit" src={exitImg} width={17} />
                                            <img src={arrowPrint} width={90} style={{ display: "inline-block", verticalAlign: "top" }} />
                                            <div style={{ display: "inline-block", verticalAlign: "top", marginLeft: 20, width: 305 }}><span style={{ width: 285, display: "block" }}>{language === "sv" ? "Om ni upplever att ni av misstag råkar klicka på pilarna i poängfälten kan ni välja att dölja dessa. Ni kommer då endast att kunna ändra poäng med tangentbordet." : lang[language].hideArrowsInfo}</span></div>
                                        </div>
                                        <span onClick={this.popInfoArrows} className="checkBoxQuestion" style={{ textAlign: "center", marginLeft: 8, marginTop: 2, width: 14, height: 14, fontSize: 12 }}>?</span>
                                    </div>
                                </div>
                                  {/* Export button */}
                                  <div onClick={this.exportPopUp} className="moreSettingsItem"><img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: language === "sv" ? 27 : 21, marginLeft: 17 }} />
                                    {language === "sv" ? "Exportera" : lang[language].export}
                                </div>
                                <div className={this.state.exportPopUp ? "whatExportPop" : "hide"}>
                                    <div id="exportFactors" onClick={this.exportThis} className="whatExportExcel">
                                        <img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 15 }} />
                                         {language === "sv" ? "Faktorer" : lang[language].factors}
                                    </div>
                                    <div id="exportEvaluation" onClick={this.exportThis} className="whatExportWord">
                                        <img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 15 }} />
                                        {language === "sv" ? "Värdering" : lang[language].evaluation}
                                    </div>
                                </div>
                                <NavLink
                                    exact
                                    to={{
                                        pathname: '/settings',
                                        state: { from: { component: 'Arbetsvardera' } },
                                    }}
                                >
                                    <div className="moreSettingsItem"><img src={openTab} width={16} style={{ opacity: 0.5, verticalAlign: "top", marginRight: language === "sv" ? 27 : 21, marginLeft: 17 }} />
                                        {language === "sv" ? "Fler inställningar" : lang[language].moreSettings}
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        {/* Viktning */}
                        <div className="titles-container" style={{
                            background: "white", borderTopRightRadius: "4px",
                            borderTopLeftRadius: "4px"
                        }}>
                            <span className="title-head-position"><span style={{ fontWeight: 600 }}> {language === "sv" ? "Viktning:" : lang[language].weight}</span> <span className={this.state.weightTotal == 100 ? "green-weight" : "red-weight"}>{this.state.weightTotal}%</span> </span>
                            <div className="titles">
                                <div className="titlesInside">
                                    {this.state.factors.map(item => {
                                        return <span className="title-head"><input id={"weight" + item.id} value={item.weight} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    })}
                                </div>
                            </div>
                        </div>
                        {/* END OF Viktning  */}

                        {/* Questions */}
                        <div className={this.state.showPopUp != null || "" ? "titles-container titles-container-if-popup" : "titles-container"}>
                            <span className="title-head-position" style={{ verticalAlign: "bottom", marginBottom: 8 }}>{language === "sv" ? "Grupp" : lang[language].group}</span>
                            <div className="titles">
                                <div className="titlesInside">
                                    {this.state.factors.map(item => {
                                        return <span className="title-head" onClick={this.descPop} id={"desc" + item.id}>
                                            <img src={infoImg} className="info-icon" alt="" />{item.question}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                        {/* END OF Position Titles */}

                        {/* List items */}
                        <ul className="valuation-ul" style={{ marginBottom: 0, letterSpacing: -0.2 }}>
                            {arbetsvaderingList}
                        </ul>
                    </div>
                </div>

            </>
        );
    }
}