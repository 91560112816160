// This uses separate linear regressions and then take the average value. 
// This makes sure that it is always equal weight for all variables, if the user is not changing it.
// This create an easy to understand function for the user wich is what we want

// Matristransponering
function transpose(matrix) {
    return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
}

// Matrismultiplikation
function multiply(matrixA, matrixB) {
    const result = Array(matrixA.length).fill(0).map(() => Array(matrixB[0].length).fill(0));

    for (let i = 0; i < matrixA.length; i++) {
        for (let j = 0; j < matrixB[0].length; j++) {
            for (let k = 0; k < matrixA[0].length; k++) {
                result[i][j] += matrixA[i][k] * matrixB[k][j];
            }
        }
    }
    return result;
}

// Invertera matris
function inverse(matrix) {
    const size = matrix.length;
    const augmentedMatrix = matrix.map((row, rowIndex) => [...row, ...Array(size).fill(0).map((_, colIndex) => rowIndex === colIndex ? 1 : 0)]);

    for (let i = 0; i < size; i++) {
        let maxElementIndex = i;
        for (let j = i + 1; j < size; j++) {
            if (Math.abs(augmentedMatrix[j][i]) > Math.abs(augmentedMatrix[maxElementIndex][i])) {
                maxElementIndex = j;
            }
        }

        if (augmentedMatrix[maxElementIndex][i] === 0) {
            throw new Error("Matrix is singular and cannot be inverted");
        }

        [augmentedMatrix[i], augmentedMatrix[maxElementIndex]] = [augmentedMatrix[maxElementIndex], augmentedMatrix[i]];

        for (let j = i + 1; j < size * 2; j++) {
            augmentedMatrix[i][j] /= augmentedMatrix[i][i];
        }

        for (let j = 0; j < size; j++) {
            if (j !== i) {
                const factor = augmentedMatrix[j][i];
                for (let k = i; k < size * 2; k++) {
                    augmentedMatrix[j][k] -= factor * augmentedMatrix[i][k];
                }
            }
        }
    }

    return augmentedMatrix.map(row => row.slice(size));
}

// Enkla linjära regressioner för ålder och år i organisationen
function simpleLinearRegression(X, y) {
    const XT = transpose(X);
    const XT_X = multiply(XT, X);
    const XT_X_inv = inverse(XT_X);
    const XT_y = multiply(XT, y);
    const beta = multiply(XT_X_inv, XT_y);

    return beta;
}

// Förbered datan för enkel regression (anpassad för att bara ha en variabel)
const prepareDataSingle = (employees, feature) => {
    const X = employees.map(emp => [1, emp[feature]]); // 1 för intercept, 'feature' är 'age' eller 'expNoJitter'
    const y = employees.map(emp => [emp.y]); // y är aktuell lön
    return { X, y };
};

// Förutsäg lön baserat på koefficienter och en given variabel
const predictSimpleSalary = (coefficients, featureValue) => {
    const intercept = coefficients[0][0]; // Startsalary
    const beta = coefficients[1][0]; // increment or raise per year
    const slopeDown = coefficients[2] // undefined if not negative
    const startAge = coefficients[3]
    //console.log("slopedown: " + slopeDown)
    if(slopeDown === undefined) { // If positive slope
    return intercept + beta * featureValue; // Prediktion baserat på intercept och koefficient för variabeln
    } else { // If negative slope
        return intercept + (beta * (featureValue - startAge))  // The formula is: Expected salary = startSalary for lowest X (x can be for example age, this is intercept) + (increment per year x (age - lowest x) )
    }
};

const generateRegressionLine = (coefficients, XValues) => {
    const intercept = coefficients[0][0];
    const beta = coefficients[1][0];

    // Skapa en array med punkter som motsvarar regressionslinjen
    const regressionLine = XValues.map(X => ({
        X: X,  // Oberoende variabel (t.ex. ålder eller erfarenhet)
        y: intercept + beta * X  // Predikterad lön (beroende variabel)
    }));

    // Returnera regressionslinjen och själva ekvationen
    return {
        equation: `y = ${intercept.toFixed(2)} + ${beta.toFixed(2)} * X`,
        line: regressionLine
    };
};

// Handle negative slope for regressionline
const calculateSalaryRange = (employees) => {
    const salaries = employees.map(emp => emp.y).sort((a, b) => a - b);

    if (salaries.length > 9) {
        const p10 = salaries[Math.floor(salaries.length * 0.1)];
        const p90 = salaries[Math.floor(salaries.length * 0.9)];
        return { min: p10, max: p90 };
    }

    const minSalary = Math.min(...salaries);
    const maxSalary = Math.max(...salaries);

    return { min: minSalary, max: maxSalary };
};

// Generalized function to adjust regression for negative slope
const adjustRegressionForNegativeSlope = (employees, feature) => {
    const { min, max } = calculateSalaryRange(employees);

    // Get the feature values (age or experience)
    const featureValues = employees.map(emp => emp[feature]);
    const minFeatureValue = Math.min(...featureValues);
    const maxFeatureValue = Math.max(...featureValues);
    const featureRange = maxFeatureValue - minFeatureValue;

    // Calculate the increment for each unit of the feature
    const increment = featureRange > 0 ? (max - min) / featureRange : 0;

    // Create a linear regression line
    const adjustedRegressionLine = [];
    for (let value = minFeatureValue; value <= maxFeatureValue; value++) {
        const salary = min + increment * (value - minFeatureValue); // Smooth increment
        adjustedRegressionLine.push({
            X: value,
            y: salary
        });
    }

    // Calculate new coefficients
    const newIntercept = min; // Starting salary
    const newSlope = increment; // The increment, the yearly raise
    const startAge = adjustedRegressionLine[0].X

   /* console.log("newIntercept"); console.log(newIntercept)
    console.log("newSlope"); console.log(increment)*/

    return {
        equation: `y = ${newIntercept.toFixed(2)} + (${newSlope.toFixed(2)} * (X - ${minFeatureValue}))`,
        line: adjustedRegressionLine,
        newCoefficients: [[newIntercept], [newSlope], "slopeDown", startAge], // Return the new coefficients
        increment
    };
};


// Huvudfunktion för analysen
const RegressionAnalysis = (employees, diffWomenPercent, weightAge, weightExp) => {
    // Vikter
    //let weightAge = 0.5;  // Vikt för ålder
   // let weightExp = 0.5;  // Vikt för erfarenhet


    // Initial thresholds
    let limit = 0.05; // 5%
    let limitMinus = -0.05; // -5%

    // Max value for use with flipped slope
    const maxIncreasePerYear = 2000; // "2000 kr" <-- OBS! Need to be changed to percent later on to match euro and better follow different roles

    // Set priority list length, if more people selected than this, raise the percentage bar
    let priorityLength = 5

    // Special message to be generated 
    let message = ""

    // Kontrollera vikterna
    if (weightAge + weightExp !== 1) {
        throw new Error("Vikterna måste summera till 1.");
    }

    // Make sure there is more than 2 employees
    if (employees.length < 3) {
       // console.log("Not enough employees for analysis. Need at least 3.");
        message = "Need 3 employees"
        return [
            [],
            [],
            [],
            [],
            message  // Message
        ];
    }
    // Make sure there is at least two distinct values
    const hasAtLeastTwoDistinctValues = (feature) => { //checks if there are at least two different values for a given feature
        const uniqueValues = new Set(employees.map(emp => emp[feature]));
        return uniqueValues.size >= 2;
    };
    const ageHasDistinctValues = hasAtLeastTwoDistinctValues('age'); // Check for age
    const expHasDistinctValues = hasAtLeastTwoDistinctValues('expNoJitter'); // Check for years in org

    const calculateMedianSalary = (employees) => {
        const salaries = employees.map(emp => emp.y).sort((a, b) => a - b);
        const mid = Math.floor(salaries.length / 2);
        return salaries.length % 2 !== 0 ? salaries[mid] : (salaries[mid - 1] + salaries[mid]) / 2;
    };
    let median = null

    // Räkna ut regressionskoefficienterna för ålder
    let ageCoefficients = null
    let ageRegression = null
    let ageSlope = null
    let incrementNegative = null
    if (ageHasDistinctValues) {
        const { X: ageX, y: ageY } = prepareDataSingle(employees, 'age');
        ageCoefficients = simpleLinearRegression(ageX, ageY);
        ageSlope = ageCoefficients[1][0]; // This is the slope, if negative, it means that the older you are, the lower your salary is
        // If the slope is negative, handle it
       /*("ageCoefficients")
        console.log(ageCoefficients)*/

        if (ageSlope <= 0) {
            const { line: adjustedLine, newCoefficients, increment } = adjustRegressionForNegativeSlope(employees, "age");
            ageRegression = {
                line: adjustedLine,
                equation: `y = ${newCoefficients[0][0].toFixed(2)} + (${newCoefficients[1][0].toFixed(2)} * (X - ${Math.min(...employees.map(emp => emp.age))}))`,
            };
            ageCoefficients = newCoefficients; // Update the coefficients
            incrementNegative = Math.round(increment)
        } if (ageSlope > 0) {
            // Skapa regressionslinje
            const ageValues = employees.map(emp => emp.age);  // Samla alla åldervärden
            ageRegression = generateRegressionLine(ageCoefficients, ageValues);
        }
      //  console.log("new ageCoefficients")
       // console.log(ageCoefficients)
    } else {
        median = calculateMedianSalary(employees)
      //  console.log("Not enough distinct values for age. Skipping regression and using median.");
    }

    // Räkna ut regressionskoefficienterna för erfarenhet 
    let expCoefficients = null
    let expRegression = null;
    let expSlope = null
    if (expHasDistinctValues) {
        const { X: expX, y: expY } = prepareDataSingle(employees, 'expNoJitter');
        expCoefficients = simpleLinearRegression(expX, expY);
        expSlope = expCoefficients[1][0]; // This is the slope, if negative, it means that the more experience you have, the lower your salary is
        // If the slope is negative, handle it
        if (expSlope <= 0) {
          //  console.log("Negativ slope for exp")
            const { line: adjustedLine, newCoefficients, increment } = adjustRegressionForNegativeSlope(employees, "expNoJitter");
            expRegression = {
                line: adjustedLine,
                equation: `y = ${newCoefficients[0][0].toFixed(2)} + (${newCoefficients[1][0].toFixed(2)} * (X - ${Math.min(...employees.map(emp => emp.expNoJitter))}))`,
            };
            expCoefficients = newCoefficients; // Update the coefficients
            incrementNegative = Math.round(increment)
        } if (expSlope > 0) {
            // Skapa regressionslinje
            const expValues = employees.map(emp => emp.expNoJitter);  // Samla alla erfarenhetsvärden
            expRegression = generateRegressionLine(expCoefficients, expValues);
        }
    } else {
        median = calculateMedianSalary(employees)
      //  console.log("Not enough distinct values for years in org. Skipping regression and using median.");
    }

    // Message if slope is negative
    if (ageSlope < 0) {
        message = `Medarbetare i denna grupp tjänar mindre ju äldre de är och detta bör undersökas närmare. För att utföra analysen har ett antagande gjorts om en fiktiv ökning per år baserat på högsta och lägsta lön för den åldersrelaterade löneökningen. Förslagen bör därför tolkas med stor försiktighet, det verkar finnas andra faktorer som har större påverkan på löneskillnaderna.`
    }
    if (expSlope < 0) {
        message = `Medarbetare i denna grupp tjänar mindre ju längre de har arbetat i organisationen och detta bör undersökas närmare. För att utföra analysen har ett antagande gjorts om en fiktiv ökning per år baserat på högsta och lägsta lön avseende år i organisationen. Förslagen bör därför tolkas med stor försiktighet, det verkar finnas andra faktorer som har större påverkan på löneskillnaderna.`
    }
    if (ageSlope < 0 && expSlope < 0) {
        message = `Medarbetare i denna grupp tjänar mindre ju äldre de är och ju längre de har arbetat i organisationen. Detta bör undersökas närmare. För att utföra analysen har ett antagande gjorts om en fiktiv ökning per år baserat på skillnaden mellan högsta och lägsta lön. Förslagen bör därför tolkas med stor försiktighet, det verkar finnas andra faktorer som har större påverkan på löneskillnaderna.`
    }

    // Arrays to hold suggestions
    const suggestionsUnderpaid = [];
    const suggestionsOverpaid = [];

    // Iterera över varje medarbetare och beräkna differenserna
    employees.forEach(emp => {
        // Förväntad lön enligt ålder och erfarenhet, om inga koefficienter finns används medianvärde
        const expectedSalaryAge = ageCoefficients !== null ? predictSimpleSalary(ageCoefficients, emp.age) : median; 
        const expectedSalaryExp = expCoefficients !== null ? predictSimpleSalary(expCoefficients, emp.expNoJitter) : median;

      //  console.log("expectedSalaryAge BUG: ")
     //   console.log(expectedSalaryAge)

        // Beräkna differenser: Positivt om de tjänar mindre än förväntat (de borde tjäna mer)
        const differenceAge = expectedSalaryAge !== null ? expectedSalaryAge - emp.y : null
        const differenceExp = expectedSalaryExp !== null ? expectedSalaryExp - emp.y : null

        // Beräkna total differens med vikter. Om inga koefficienter för någon del så använd bara en
        //  let totalDifference = (weightAge * differenceAge) + (weightExp * differenceExp)
        /*  if (expectedSalaryAge !== null & expectedSalaryExp !== null) { totalDifference = (weightAge * differenceAge) + (weightExp * differenceExp);}
          if (expectedSalaryAge !== null & expectedSalaryExp === null) { totalDifference = expectedSalaryAge } 
          if (expectedSalaryAge === null & expectedSalaryExp !== null) { totalDifference = expectedSalaryExp }*/


        //  let totalDifference = differenceAge + differenceExp <-- istället för att plussa, ta viktat medelvärde
        let totalDifference = (weightAge * differenceAge) + (weightExp * differenceExp); // Viktat medelvärde

        emp.adjustment = Math.round(totalDifference); // Add the adjustment property
        emp.expectedSalary = emp.y + totalDifference;

        // Kontrollera om skillnaden mellan expectedSalary och aktuell lön är > 5% eller < -5%
        const salaryDifference = emp.expectedSalary - emp.y;
        const percentageDifference = (salaryDifference / emp.y);

        const percentageDifferenceExp = (differenceExp / emp.y);
        const percentageDifferenceAge = (differenceAge / emp.y);

        if (percentageDifference < limitMinus && salaryDifference < 0) {
            //  emp.ai = `${Math.abs(emp.adjustment)} kr över`; // Add new 'ai' property. Math abs turns minus into plus, so the adjustment is minus but we want it to show like plus
            emp.ai = "on"
            emp.ageExpected = differenceAge < 0 ? `<b>${Math.abs(Math.round(differenceAge))}</b> kr över förväntat` : `<b>${Math.abs(Math.round(differenceAge))}</b> kr under förväntat`
            emp.orgExpected = differenceExp < 0 ? `<b>${Math.abs(Math.round(differenceExp))}</b> kr över förväntat` : `<b>${Math.abs(Math.round(differenceExp))}</b> kr under förväntat`
            emp.totExpected = `- ${Math.round(Math.abs(emp.adjustment))}`
            emp.expectedSal = Math.abs(Math.round((expectedSalaryAge + expectedSalaryExp) / 2));
            suggestionsOverpaid.push(emp); // Lägg till i suggestionsOverpaid 
        } else if (percentageDifference > limit && salaryDifference > 0) {
            //  emp.ai = `${Math.abs(emp.adjustment)} kr under`; // Add new 'ai' property. Math abs turns minus into plus, so the adjustment is minus but we want it to show like plus
            emp.ai = "on"
            emp.ageExpected = differenceAge < 0 ? `<b>${Math.abs(Math.round(differenceAge))}</b> kr över förväntat` : `<b>${Math.abs(Math.round(differenceAge))}</b> kr under förväntat`
            emp.orgExpected = differenceExp < 0 ? `<b>${Math.abs(Math.round(differenceExp))}</b> kr över förväntat` : `<b>${Math.abs(Math.round(differenceExp))}</b> kr under förväntat`
            emp.totExpected = `+ ${Math.abs(Math.round(emp.adjustment))}`
            emp.expectedSal = Math.abs(Math.round((expectedSalaryAge + expectedSalaryExp) / 2));
            suggestionsUnderpaid.push(emp); // Lägg till i suggestionsUnderpaid
        }

        // Skriv ut resultatet för varje medarbetare
     /*   console.log(`Medarbetare: ${emp.fullName}`);
        console.log(`Aktuell lön: ${emp.y}`);
        console.log(`Förväntad lön (ålder): ${expectedSalaryAge}, Differens (ålder): ${differenceAge}`);
        console.log(`Förväntad lön (erfarenhet): ${expectedSalaryExp}, Differens (erfarenhet): ${differenceExp}`);

        console.log("total förväntad lön: " + (expectedSalaryAge + expectedSalaryExp) / 2)
        console.log(`Total differens (summa av båda): ${totalDifference}`);
        console.log("salaryDifference: " + salaryDifference);
        console.log("percentageDifference: " + percentageDifference);*/
    });

    // Dynamisk tröskeljustering
    while (suggestionsUnderpaid.concat(suggestionsOverpaid).length > priorityLength) {
        // Justera trösklar om fler än 10
        limit += 0.02; // Öka med 2%
        limitMinus -= 0.02; // Minska med 2%

        // Rensa listorna och filtrera igen med den nya gränsen
        suggestionsUnderpaid.length = 0;
        suggestionsOverpaid.length = 0;

        employees.forEach(emp => {
            // Kontrollera med nya gränser
            const salaryDifference = emp.expectedSalary - emp.y;
            const percentageDifference = (salaryDifference / emp.y);

            if (percentageDifference < limitMinus && salaryDifference < 0) {
                //  emp.ai = `${Math.abs(emp.adjustment)} kr över`;
                suggestionsOverpaid.push(emp);
            } else if (percentageDifference > limit && salaryDifference > 0) {
                //   emp.ai = `${Math.abs(emp.adjustment)} kr under`;
                suggestionsUnderpaid.push(emp);
            }
        });
    }

     // Set emp.ai to "off" if not in the updated lists
     employees.forEach(emp => {
        if (!suggestionsOverpaid.includes(emp) && !suggestionsUnderpaid.includes(emp)) {
            emp.ai = undefined;
        }
    });


    return [
        suggestionsUnderpaid,
        suggestionsOverpaid,
        ageRegression,
        expRegression,
        message
    ];
};

// Exportera RegressionAnalysis
export { RegressionAnalysis };


// Förbättringar:
// För många markeras. Kika munkedals kommun Lärare grundskola tidigare år, för många som markeras. sätta någon gräns eller avgöra om någon faktor inte ska påverka så mkt, tror dessa beror på år i org tex. Så om fler än ett visst antal procent markeras kan den faktorns vikt sänkas tex.
// Ta hänsyn till lönediffen och fokusera på kvinnor eller män för att få ner den?


// KANSKE MEN EJ PRIO:
// Regressionslinje finns nu men visas som punkter, det går bra för min testning men om ska visa den live (vilket jag troligtvis inte ska göra) så behöver den vara en linje.


// OBS, originalet verkar hantera regressionslinjen ganska bra, så behåll som det är nu men annars kan nedan göras.
// Hantera extramvärden så att regressionslinjen inte blir för skev. Gör såhär 
// Prova något av nedan eller en kombination av olika:
/* 
- Remove outliers: Remove data points that deviate too much from the trend
- Transform data: Apply transformations like logarithms to reduce the impact of extreme values.
- Cap extreme values: Cap values that are too high or low at a reasonable percentile.
- Robust regression: Use models that are less sensitive to outliers.

Känn av slopen. Om rak eller lutar nedåt, ange ingen påverkan
*/