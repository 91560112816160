import {
    Paragraph,
    TableCell,
    TableRow,
    TextRun
} from "docx";
import lang from '../../Language/language.js';

export const analysTabell = (comments, oldComments, analysisType, tableSpace) => {
    //Calculations
    let equal = comments.filter(com => com.analysis === "lika" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let equalent = comments.filter(com => com.analysis === "likvärdiga" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let hierarchy = comments.filter(com => com.analysis === "hierarkisk" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let general = comments.filter(com => com.analysis === "general" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let oldEqual = oldComments.filter(com => com.analysis === "lika" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")) //If no todo or deadline then no need to show it in the documentation
    let oldEqualent = oldComments.filter(com => com.analysis === "likvärdiga" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let oldHierarchy = oldComments.filter(com => com.analysis === "hierarkisk" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    let oldGeneral = oldComments.filter(com => com.analysis === "general" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
    // END of Calcultaions

    //RENDER -----------------------------------
    let language = localStorage.getItem('language') ?? 'sv';
    //Comments for this year
    const dataG = (group) => {
        let groupRows = group.map(elem =>
            new TableRow({
                children: [
                    new TableCell({
                        children: analysisType === "equal" ? [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: `${!elem.equalGroup ? "" : elem.equalGroup}`,
                                        font: "Arial",
                                        color: "808080"
                                    }),
                                ]
                            }),
                           /* new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: ""
                                    }),
                                ]
                            }),*/
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: `${!elem.notable ? "" : elem.notable}`,
                                        font: "Arial"
                                    }),
                                ]
                            }),]
                            :
                            [
                                new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: `${!elem.notable ? "" : elem.notable}`,
                                            font: "Arial"
                                        }),
                                    ]
                                }),]

                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.reason ? "" : elem.reason}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.todo ? "" : elem.todo}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.deadline ? "" : elem.deadline}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.cost ? "" : elem.cost}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                    }),
                ],
            }),
        )
        const groupRowHeader = () => {
            groupRows.unshift(new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Noterbart" : lang[language].notable,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Orsak" : lang[language].reason,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Åtgärd" : lang[language].action,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: "Deadline",
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Kostnad" : lang[language].cost,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                ],
            }))
        }
        groupRowHeader(group);
        return groupRows
    }

    //Comments for last year
    //RENDER -----------------------------------
    const oldDataG = (group) => {
        let groupRows = group.map(elem =>
            new TableRow({
                children: [
                    new TableCell({
                        children: analysisType === "oldEqual" ? [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: `${!elem.equalGroup ? "" : elem.equalGroup}`,
                                        font: "Arial",
                                        color: "808080"
                                    }),
                                ]
                            }),
                           /* new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: ""
                                    }),
                                ]
                            }),*/
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: `${!elem.notable ? "" : elem.notable}`,
                                        font: "Arial"
                                    }),
                                ]
                            }),]
                            : [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.notable ? "" : elem.notable}`,
                                    font: "Arial"
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.reason ? "" : elem.reason}`,
                                    font: "Arial"
                                }),
                            ]
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.todo ? "" : elem.todo}`,
                                    font: "Arial"
                                }),
                            ]
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.deadline ? "" : elem.deadline}`,
                                    font: "Arial"
                                }),
                            ]
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.cost ? "" : elem.cost}`,
                                    font: "Arial"
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.finished ? (language === "sv" ? "Nej" : lang[language].no) : elem.finished}`,
                                    font: "Arial"
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${!elem.comment ? "" : elem.comment}`,
                                    font: "Arial"
                                }),
                            ]
                        })],
                    }),
                ],
            }),
        )
        const groupRowHeader = () => {
            groupRows.unshift(new TableRow({
                children: [
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Noterbart" : lang[language].notable,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Orsak" : lang[language].reason,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Åtgärd" : lang[language].action,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: "Deadline",
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Kostnad" : lang[language].cost,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: (language === "sv" ? "Utfört" : lang[language].completed)+"?",
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                    new TableCell({
                        shading: {
                            fill: "f3f3f3",
                        },
                        children: [
                            new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Kommentar" : lang[language].comment,
                                        color: "000000",
                                        bold: true,
                                        font: "Arial"
                                    }),
                                ]
                            }),
                        ],
                    }),
                ],
            }))
        }
        groupRowHeader(group);
        return groupRows
    }

    //Return tables with comments for handlingsplan
    if (analysisType === "equal") return dataG(equal)
    if (analysisType === "equalent") return dataG(equalent)
    if (analysisType === "hierarchical") return dataG(hierarchy)
    if (analysisType === "general") return dataG(general)
    if (analysisType === "oldEqual") return oldDataG(oldEqual)
    if (analysisType === "oldEqualent") return oldDataG(oldEqualent)
    if (analysisType === "oldHierarchical") return oldDataG(oldHierarchy)
    if (analysisType === "oldGeneral") return oldDataG(oldGeneral)
}