import React from 'react'
import ReactDOM from 'react-dom'
import lang from '../Language/language.js';
//This is the autoanalysis function
export const autoAnalysis = (
    lessWomen,
    moreWomen,
    firsAvgSalary,
    secondAvgSalary,
    nrOfEmp,
    nrOfEmpTwo,
    firstGroup,
    secondGroup,
    firstExp,
    secondExp,
    firstAge,
    secondAge,
    firstEqualent,
    secondEqualent,
    firstCity,
    secondCity,
    autoRobot
) => {
    //Note: "first.."" is always the women dominated one if there is one. 
    let language = localStorage.getItem('language') ?? 'sv';
    //NEW ONE -------

    //*** Settings: ***
    let ai = "On" //Turn on/off AI

    //A. How many percent that is reasonable for salary raise each year. 2-3 percent is normal and considered "märket"
    // Note: This is used for age and experience
    let expPercent = 0.03
    const agePercent = 0.03 //Used by experience and age
    let startAge = 1
    let stopAge = 100
    if (ai === "On") {
        if (firsAvgSalary <= 30000) expPercent = 0.045 //Used by experience only
        startAge = 3 //Start counting on age differences after certain age. If 3 age differences will be started to calculate after 3 years and forward.
        stopAge = 16 //Stop counting age difference after certain age. So it wont be unrealistic. This is the maximum years that can be counted. Set a really high number if want to not use it
    }
    //B. How many percent of a reason should be considered "a reason" and used in comment
    let reasonLimit = 0.4 //Used for city, age and exp. If more than this percentage then result will be 2

    //C. Salary differences in cities. Can be higher, but we set this pretty low because it can differ much in differen jobs. Higher differences should be checked manually
    let sthlmAddToSalary = 0.08
    let gbgAndMalmoAddToSalary = 0.04
    let generalCityAddToSalary = 0
    let maxCityAmount = 4000 //If we want to use a max amount for how much city can be the reason. Set a really high number like 100.000 if not want to use it
    //D. Work demands. If a group has more demands it should not be enought to just have equal amount, the higher demand group should have more. Below is settings for this
    let demands = 0
    let demandDifference = (firstEqualent - secondEqualent)
    const activeGroupAddon = "Yes" //Set this to "No" if we dont want the groupAddon function
    const groupAddonPercent = "Yes" //Set this to "No" if we want to use fixed amounts
    if (activeGroupAddon === "Yes" && groupAddonPercent !== "Yes") {
        if (demandDifference == 1) { demands = 2000 }
        if (demandDifference == 2) { demands = 4000 }
        if (demandDifference == 3) { demands = 8000 }
        if (demandDifference == 4) { demands = 16000 }
        if (demandDifference == 5) { demands = 24000 }
        if (demandDifference == -1) { demands = -2000 }
        if (demandDifference == -2) { demands = -4000 }
        if (demandDifference == -3) { demands = -8000 }
        if (demandDifference == -4) { demands = -16000 }
        if (demandDifference == -5) { demands = -24000 }
    } else if (activeGroupAddon === "Yes" && groupAddonPercent === "Yes") {
        if (demandDifference == 1) { demands = firsAvgSalary * 0.35 }
        if (demandDifference == 2) { demands = firsAvgSalary * 1.5 }
        if (demandDifference == 3) { demands = firsAvgSalary * 2 }
        if (demandDifference == 4) { demands = firsAvgSalary * 3 }
        if (demandDifference == 5) { demands = firsAvgSalary * 4 }
        if (demandDifference == -1) { demands = firsAvgSalary * -0.4 }
        if (demandDifference == -2) { demands = firsAvgSalary * -1.5 }
        if (demandDifference == -3) { demands = firsAvgSalary * -2 }
        if (demandDifference == -4) { demands = firsAvgSalary * -3 }
        if (demandDifference == -5) { demands = firsAvgSalary * -4 }
    }
    /* Info for cities 
    Due to SCB, https://kommunsiffror.scb.se/?id1=1480&id2=0180, there is: sthlmSalary = 33500, gbgSalary = 28117, almoSalary = 23800. But these take sickness pay into account and is therefore not reliable. Malmö and Gbg is often very alike.
    Sthlm is often a bit higher, Then Gbg and Malmö and then the rest of Sweden
    Ref, example for engineers: https://www.ingenjoren.se/2020/10/21/sa-mycket-skiljer-sig-ingenjorers-loner/
    */

    //For saving comment we need to know it it is an equalent och hierarchical analysis
    let analysisType = "hierarkisk"
    if (demandDifference === 0) { analysisType = "likvärdiga" }

    //If one is women dominated and one is not
    if (lessWomen > 59 && moreWomen < 60) {
        //If autorobot, ignore
        if (autoRobot === "Yes") {
            //console.log("hello robot!")
            //If men has more demands
            if (demandDifference < 0) {
                return [(language === "sv" ? `${secondGroup} har högre lön på grund av högre krav i arbetet.` : `${secondGroup} ${lang[language].aiAnalysisInfo}`), "ignore", "ignore", analysisType]
            }
        }
        //If women makes more money
        if (firsAvgSalary > secondAvgSalary) {
            //If autorobot, ignore
            if (autoRobot === "Yes") {
                return ["ignore", "ignore"]
            }
            //If same demands
            if (demandDifference === 0) {
                return [(language === "sv" ? `Behöver ej analyseras. ${firstGroup} är kvinnodominerat och har högre lön.` : `${lang[language].AINotNeeded} ${firstGroup} ${lang[language].AIwomenHigherSal}`), "ignore", "ignore", analysisType]
            }
            //If men has more demands
            if (demandDifference < 0) {
                return [(language === "sv" ? `Behöver ej analyseras. ${firstGroup} är kvinnodominerat och har högre lön trots lägre krav i arbetet.` : `${lang[language].AINotNeeded} ${firstGroup} ${lang[language].AIwomenHigherSalLowerReq}`), "ignore", "ignore", analysisType]
            }
            //If women has more demands
            if (demandDifference > 0) {
                return [(language === "sv" ? `Behöver ej analyseras. ${firstGroup} är kvinnodominerat, har högre krav men också högre lön.` : `${lang[language].AINotNeeded} ${firstGroup} ${lang[language].AIwomehHigherSalHigherReq}`), "ignore", "ignore", analysisType]
            }
            /* if (demandDifference >= 2 && (firsAvgSalary - secondAvgSalary < 2000)) {
                 return [`${firstGroup} är kvinnodominerat, har högre krav och högre lön. Då kraven dock är betydligt högre borde lönen dock skillnaden sannolikt vara något större.`, "ignore"]
             } */
        }

        //If women make less money
        if (firsAvgSalary < secondAvgSalary) {

            //If there is 3 in one group and one are man
            /* if (lessWomen < 90 && nrOfEmp === 3) {
                 return [`Löneskillnader finns, dock består ${firstGroup} bara av 3 anställda där 1 är man. Detta ger en hög procentuell andel kvinnor men det låga antalet personer kan göra det svårt att motivera kön som orsak.`, `${firstGroup} är kvinnodominerat och har lägre lön än ${secondGroup} som inte är kvinnodominerat.`, `Då ${firstGroup} endast består av 3 anställda varav en är man ger detta en hög procentuell andel kvinnor men det låga antalet personer kan göra det svårt att motivera kön som orsak. `, analysisType]
             }*/

            // *** Calculate differences ***
            // Differences (will be minus if women has more experience or similar)
            //Note: Demands and equalentgroup difference is calculated in settings above
            let salaryDiff = secondAvgSalary - firsAvgSalary //Salary difference 
            let expDiff = secondExp - firstExp
            let ageDiff = secondAge - firstAge
            let firstCityAmount = firstCity.toLowerCase().includes("stockholm") ? sthlmAddToSalary : (firstCity.toLowerCase().includes("göteborg") || firstCity.toLowerCase().includes("gothenburg") || firstCity.toLowerCase().includes("malmö") || firstCity.toLowerCase().includes("malmo")) ? gbgAndMalmoAddToSalary : generalCityAddToSalary
            let secondCityAmount = secondCity.toLowerCase().includes("stockholm") ? sthlmAddToSalary : (secondCity.toLowerCase().includes("göteborg") || secondCity.toLowerCase().includes("gothenburg") || secondCity.toLowerCase().includes("malmö") || secondCity.toLowerCase().includes("malmo")) ? gbgAndMalmoAddToSalary : generalCityAddToSalary
            let cityDiff = (firsAvgSalary * secondCityAmount) - (firsAvgSalary * firstCityAmount)
            // *** End of calculate differences ***

            //1. Experience. 
            let expFairDifference = (firsAvgSalary * expPercent) * expDiff //This is the reasonable difference in salary that can explain the difference. Will be minus if women has more experience
            let expRemainingDiff = salaryDiff - expFairDifference //Will be minus if it can be the explained difference
            let expRemainDiffInclDemand = expRemainingDiff + demands
            /* console.log(
                 "expFairDifference: " + expFairDifference, 
                 "expRemainingDiff uträkning: salaryDiff" + salaryDiff + " - expFairDifference" + expFairDifference,
                 " = " + expRemainingDiff, 
                 "expRemainDiffInclDemand: " + expRemainDiffInclDemand,  
                 "BERÄKNING: " + expRemainingDiff + " + demands " + demands
                 )*/
            //BACKUP: let expResult = expRemainingDiff <= 0 ? 3 : expRemainingDiff < salaryDiff && expRemainingDiff > 0 && (expFairDifference / salaryDiff) > reasonLimit ? 2 : expRemainingDiff > salaryDiff ? 0 : expRemainingDiff === salaryDiff || (expFairDifference / salaryDiff) < reasonLimit && expRemainingDiff <= salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            let expResult = expRemainingDiff <= 0 ? 3 : expRemainingDiff < salaryDiff && expRemainingDiff > 0 && (expFairDifference / salaryDiff) > reasonLimit ? 2 : expRemainingDiff > salaryDiff && (expFairDifference / salaryDiff) < (reasonLimit - reasonLimit * 2) ? -1 : expRemainingDiff > salaryDiff ? 0 : expRemainingDiff === salaryDiff || (expFairDifference / salaryDiff) < reasonLimit && expRemainingDiff <= salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            //The fix: expRemainingDiff > salaryDiff && (expFairDifference / salaryDiff) < reasonLimit 

            //2. Age.
            let ageDiffAdjusted = ageDiff
            // console.log("ageDiffAdjusted:" + ageDiffAdjusted)
            /* New ageDiff fix */
            if (ageDiff > 0) { ageDiffAdjusted = ageDiff - startAge }
            if (ageDiff > stopAge) { ageDiffAdjusted = stopAge }
            if (ageDiff < 0) { ageDiffAdjusted = ageDiff - (startAge - (startAge * 2)) }
            if (ageDiff < stopAge - (stopAge * 2)) { ageDiffAdjusted = stopAge - (stopAge * 2) }
            if (ageDiff > 0 && ageDiff <= startAge) { ageDiffAdjusted = 0 }
            if (ageDiff < 0 && ageDiff >= startAge - (startAge * 2)) { ageDiffAdjusted = 0 }
            /* End of newAge fix */

            // ageDiff <= (startAge - (startAge * 2)) ? 
            let ageFairDifference = (firsAvgSalary * agePercent) * ageDiffAdjusted
            let ageRemainingDiff = salaryDiff - ageFairDifference
            let ageRemainDiffInclDemand = ageRemainingDiff + demands
            //let ageResult = ageRemainingDiff <= 0 ? 3 : ageRemainingDiff < salaryDiff && ageRemainingDiff > 0 ? 2 : ageRemainingDiff > salaryDiff ? 0 : ageRemainingDiff === salaryDiff ? 1 : "Error" //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            let ageResult = ageRemainingDiff <= 0 ? 3 : ageRemainingDiff < salaryDiff && ageRemainingDiff > 0 && (ageFairDifference / salaryDiff) > reasonLimit ? 2 : ageRemainingDiff > salaryDiff && (ageFairDifference / salaryDiff) < (reasonLimit - reasonLimit * 2) ? -1 : ageRemainingDiff > salaryDiff ? 0 : ageRemainingDiff === salaryDiff || (ageFairDifference / salaryDiff) < reasonLimit && ageRemainingDiff <= salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            // console.log("age reason in percent: " + ageFairDifference / salaryDiff)

            //3. City
            let cityFairDifference = cityDiff > maxCityAmount ? maxCityAmount : cityDiff
            let cityRemainingDiff = salaryDiff - cityFairDifference
            let cityRemainDiffInclDemand = cityRemainingDiff + demands
            //let cityResult = cityRemainingDiff <= 0 ? 3 : cityRemainingDiff < salaryDiff && cityRemainingDiff > 0 ? 2 : cityRemainingDiff > salaryDiff ? 0 : cityRemainingDiff === salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            let cityResult = cityRemainingDiff <= 0 ? 3 : cityRemainingDiff < salaryDiff && cityRemainingDiff > 0 && (cityFairDifference / salaryDiff) > reasonLimit ? 2 : cityRemainingDiff > salaryDiff && (cityFairDifference / salaryDiff) < (reasonLimit - reasonLimit * 2) ? -1 : cityRemainingDiff > salaryDiff ? 0 : cityRemainingDiff === salaryDiff || (cityFairDifference / salaryDiff) < reasonLimit && cityRemainingDiff <= salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference


            //4. Total
            let totalFairDifference = expFairDifference + ageFairDifference + cityFairDifference
            let totalRemainingDiff = salaryDiff - totalFairDifference
            let totalRemainDiffInclDemand = totalRemainingDiff + demands
            /*onsole.log(
                "expFairDifference " + expFairDifference,
                "ageFairDifference" + ageFairDifference,
                "cityFairDifference" + cityFairDifference,
                "totalFairDifference " + totalFairDifference,
                "totalRemainingDiff" + totalRemainingDiff,
                "totalRemainDiffInclDemand " + totalRemainDiffInclDemand,
                "demands " + demands
                )*/
            let totalResult = totalRemainDiffInclDemand <= 0 ? 3 : totalRemainingDiff < salaryDiff && totalRemainDiffInclDemand > 0 ? 2 : totalRemainingDiff > salaryDiff ? 0 : totalRemainingDiff === salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            // let totalResult = totalRemainDiffInclDemand <= 0 ? 3 : totalRemainingDiff < salaryDiff && totalRemainDiffInclDemand > 0 && (totalFairDifference / salaryDiff) > reasonLimit ? 2 : totalRemainingDiff > salaryDiff ? 0 : totalRemainingDiff === salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference
            //  let cityResult = cityRemainingDiff <= 0 ? 3 : cityRemainingDiff < salaryDiff && cityRemainingDiff > 0 && (cityFairDifference / salaryDiff) > reasonLimit ? 2 : cityRemainingDiff > salaryDiff && (cityFairDifference / salaryDiff) < (reasonLimit - reasonLimit * 2) ? -1 : cityRemainingDiff > salaryDiff ? 0 : cityRemainingDiff === salaryDiff || (cityFairDifference / salaryDiff) < reasonLimit && cityRemainingDiff <= salaryDiff ? 1 : 1 //0 = Women has higher experience, 1 = Same experience for men and women, 2 = Men has higher experience but not enough to explain difference, 3 = Men has higher exp and it is enough to explain the difference

            //*** Return status and result ***

            //If ageResult, expResult, cityResult, demands (bigger than 0), totalResult
            //0 = women more, 1 = equal, 2 = men more but not enough, 3 = men more and enough

            let resultReturn = {
                exp: expResult,
                city: cityResult,
                age: ageResult,
                demands: demands,
                total: totalResult
            }

            //*** Generated text for Notable ***/
            let notableWomenLess = language === "sv" ? `${secondGroup} har samma krav men högre lön än ${firstGroup} som är kvinnodominerat.` : `${secondGroup} ${lang[language].AIsameReqButHigherSal} ${firstGroup} ${lang[language].AIisWomenDominated}`
            let notableWomenLessWomenMoreDemands = language === "sv" ? `${secondGroup} har mer i lön än ${firstGroup} som är kvinnodominerat och har högre krav i sitt arbete. ` : `${secondGroup} ${lang[language].AImoreSalaryThan} ${firstGroup} ${lang[language].AIwomenDominatedHigherReq} `
            let notableMenMoreDemandsAndSalary = ""
            //*** Generated text for Reason ***/
            //Reason only shows if totalResult is 2 or more. Else show "noR"
            let noR = language === "sv" ? "Kunde ej hitta saklig orsak till löneskillnaden." : lang[language].AINoReason
            let introR = language === "sv" ? "Löneskillnad förklaras av att de anställda " : lang[language].AIExplainedBy
            let introPartlyR = language === "sv" ? "Löneskillnad förklaras delvis av att de anställda " : lang[language].AIPartlyExplainedBy

            let expSelfR = language === "sv" ? "har arbetat längre i organisationen." : lang[language].AILongerInOrg
            let citySelfR = language === "sv" ? "arbetar i en större stad där lönerna generellt är högre." : lang[language].AIBiggerCity
            let ageSelfR = language === "sv" ? "är äldre och har med sig mer erfarenhet sedan tidigare." : lang[language].AIOlder
            let demandSelfR = language === "sv" ? `Löneskillnaden förklaras av att ${secondGroup} har högre krav i sitt arbete.` : `${lang[language].AIExplained} ${secondGroup} ${lang[language].AIhigherReq}`
            let demandSelfPartlyR = language === "sv" ? `Löneskillnaden förklaras delvis av att ${secondGroup} har högre krav i sitt arbete.` : `${lang[language].AIExplainedPartly} ${secondGroup} ${lang[language].AIhigherReq}`

            let expTogheterR = language === "sv" ? "har arbetat längre i organisationen, samt " : lang[language].AILongerOrgAnd
            let cityTogheterR = language === "sv" ? "arbetar i en större stad där lönerna generellt är högre, samt " : lang[language].AICityAnd
            let demandTogheterR = language === "sv" ? `Löneskillnaden förklaras av att ${secondGroup} har högre krav i sitt arbete, samt att de anställda ` : `${lang[language].AIExplained} ${secondGroup} ${lang[language].AIHigherReqAnd} `
            let demandTogheterPartlyR = language === "sv" ? `Löneskillnaden förklaras delvis av att ${secondGroup} har högre krav i sitt arbete, samt att de anställda ` : `${lang[language].AIExplainedPartly} ${secondGroup} ${lang[language].AIHigherReqAnd} `
            //*** Generated text ***
            //Human readable text (intro, outro, city etc)
            let intro = language === "sv" ? "Högre lön kan förklaras av att " : lang[language].AICanBeExplained
            let city = secondCity.toLowerCase().includes("stockholm") ? "Stockholm" : (secondCity.toLowerCase().includes("göteborg") || secondCity.toLowerCase().includes("gothenburg")) ? "Göteborg" : (secondCity.toLowerCase().includes("malmö") || secondCity.toLowerCase().includes("malmo")) ? "Malmö" : "";
            let cityWomen = firstCity.toLowerCase().includes("stockholm") ? "Stockholm" : (firstCity.toLowerCase().includes("göteborg") || firstCity.toLowerCase().includes("gothenburg")) ? "Göteborg" : (firstCity.toLowerCase().includes("malmö") || firstCity.toLowerCase().includes("malmo")) ? "Malmö" : "";
            let introNotEnough = language === "sv" ? "Högre lön kan delvis förklaras av att " : lang[language].AICanBeExplainedPartly
            let introEqual = language === "sv" ? "Högre lön kan inte per automatik förklaras. " : lang[language].AINotAutoExplained
            let introEqualFollowUp = language === "sv" ? "Vissa orsaker hittades dock, såsom att " : lang[language].AIHowever
            let salaryToBig = language === "sv" ? "Det är dock inte tillräckligt för att automatiskt förklara löneskillnaden. Manuell översyn rekommenderas." : lang[language].AISalToBig
            let nothingFound = language === "sv" ? "Hittade inte tillräckligt med skillnader för att förklara orsaken till löneskillnaden. Se över manuellt." : lang[language].AInothingFound
            // --- If can be explained (result 3) ---
            //Ev bara lägga till "och" här. Tex: "De anställda har arbetat längre i organisationen och de anställda arbetar i en stärre stad... och de anställda är äldre vilket indikerar på..
            //Text for demands
            let menMoreDemands = language === "sv" ? `${secondGroup} har högre krav i sitt arbete. ` : `${secondGroup} ${lang[language].AIHasHigherReqs}`
            let menMoreDemandsTogheter = language === "sv" ? `${secondGroup} har högre krav i sitt arbete, samt att ` : `${secondGroup} ${lang[language].AIHasHigherReqsAnd}`
            let womenMoreDemandsIntro = language === "sv" ? `${firstGroup} har högre krav i sitt arbete och borde därmed ha högre lön, men löneskillnaden kan sannolikt förklaras av att ` : `${firstGroup} ${lang[language].AIHasHigherReqsBut}`
            //Text for experience
            let expSelfExplain = language === "sv" ? `de anställda har arbetat längre i organisationen` : lang[language].AIEmployeesWorkedLonger
            let expToghetherExplain = language === "sv" ? `de anställda har arbetat längre i organisationen. ` : `${lang[language].AIEmployeesWorkedLonger}. `
            let expOpposite = language === "sv" ? `Gruppen ${firstGroup} har mer erfarenhet men löneskillnaderna kan förklaras med att ` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AiExpOpposite}`
            //Text for city
            let citySelfExplain = language === "sv" ? `de anställda arbetar i en större stad (${city}) där lönerna och utgifterna är högre` : `${lang[language].AIbiggerCityOne}${city}${lang[language].AIbiggerCityTwo}`
            let cityToghetherExplain = language === "sv" ? `. De arbetar dessutom i en större stad (${city}) där lönerna och utgifterna är högre. ` : `${lang[language].AIbiggerCityOneVerTwo}${city}${lang[language].AIbiggerCityTwo}`
            let cityOpposite = ""
            //Text for age
            let ageSelfExplain = language === "sv" ? `de anställda är äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : lang[language].AIolderVerTwo
            let ageToghetherExplain = language === "sv" ? ` tillsammans med att de är äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare <age>` : lang[language].AIolderVerThree
            let ageOpposite = ""
            let ageOppositeTogheter = ""


            // --- If reasons but not enough (result 2) ---
            //Text for demands
            let menMoreDemandsNotEnough = language === "sv" ? `${secondGroup} har högre krav i sitt arbete. ` : `${secondGroup} ${lang[language].AIHasHigherReqs}`
            let menMoreDemandsTogheterNotEnough = language === "sv" ? `${secondGroup} har högre krav i sitt arbete, samt att ` : `${secondGroup} ${lang[language].AIHasHigherReqsAnd}`
            let womenMoreDemandsIntroNotEnough = language === "sv" ? `${firstGroup} har högre krav i sitt arbete och borde ha högre lön än ${secondGroup}. Orsaken kan till viss del förklaras med att ` : `${firstGroup} ${lang[language].AIhighReqShouldBigger} ${secondGroup}. ${lang[language].AIreasonPart}`
            let womenMoreDemandsNoReasons = language === "sv" ? `${firstGroup} har högre krav i arbetet och borde vara den grupp som har högre lön. Se över manuellt.` : `${firstGroup} ${lang[language].AIhaveHigherReq}`
            let womenMoreDemandSalary = language === "sv" ? `${firstGroup} har högre krav i sitt arbete och borde ha högre lön än ${secondGroup}. Det finns vissa skillnader, men löneskillnaden är väl stor. Se över manuellt` : `${firstGroup} ${lang[language].AIhighReqShouldBigger} ${secondGroup}. ${lang[language].AItoLarge}`
            let womenMoreDemansOutroNotEnough = language === "sv" ? " Kraven i arbetet väger dock tungt och sannolikt finns andra faktorer som påverkar. Se över manuellt. " : lang[language].AInotExplained
            let demandSpecialBridge = language === "sv" ? "Notera även att " : lang[language].AIalsoNote
            let demandSpecialAgeOppositeNotEnough = language === "sv" ? `gruppen ${firstGroup} är äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : `${lang[language].AItheGroupSmall} ${firstGroup} ${lang[language].AIisOlderGeneral}`
            let demandSpecialAgeOppositeNotEnoughTogheter = language === "sv" ? `Medelåldern är även högre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : lang[language].AIageAlsoHigher
            let demandSpecialCityOppositeNotEnough = language === "sv" ? `majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AImajority} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} `
            let demandSpecialCityOppositeNotEnoughTogheter = language === "sv" ? `De arbetar dessutom i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AIBCityHigherAlso}${cityWomen}${lang[language].AIBCityHigher} `
            let demandSpecialExpOppositeNotEnough = language === "sv" ? `gruppen ${firstGroup} har mer erfarenhet vilket gör det ännu svårare att hitta saklig orsak till löneskillnaden. ` : `${lang[language].AItheGroupSmall} ${firstGroup} ${lang[language].AIEvenMore} `
            let demandSpecialExpOppositeNotEnoughTogheter = language === "sv" ? `gruppen ${firstGroup} har mer erfarenhet vilket gör det svårare att hitta saklig orsak till löneskillnaden. ` : `${lang[language].AItheGroupSmall} ${firstGroup} ${lang[language].AIEmore} `
            let demandNotEnough = language === "sv" ? `Huvudorsak till löneskillnaden är sannolikt att ${secondGroup} har högre krav i sitt arbete. Löneskillnaden är dock såpass stor att manuell översyn rekommenderas.` : `${lang[language].AIMainReason} ${secondGroup} ${lang[language].AImainReasonInfo}`

            //Text for experience
            let expSelfExplainNotEnough = language === "sv" ? `de anställda har arbetat längre i organisationen. ` : `${lang[language].AIEmployeesWorkedLonger}. `
            let expToghetherExplainNotEnough = language === "sv" ? `de anställda har arbetat längre i organisationen. ` : `${lang[language].AIEmployeesWorkedLonger}. `
            let expOppositeNotEnough = language === "sv" ? `Gruppen ${firstGroup} har dock mer erfarenhet vilket gör det svårare att hitta saklig orsak till löneskillnaden. ` : `${lang[language].AItheGroup} ${firstGroup}${lang[language].AIEmoreTwo} `
            let expOppositeNotEnoughTogheter = language === "sv" ? `Gruppen ${firstGroup} har dock mer erfarenhet vilket gör det svårare att hitta saklig orsak till löneskillnaden. ` : `${lang[language].AItheGroup} ${firstGroup}${lang[language].AIEmoreTwo} `

            //Text for city
            let citySelfExplainNotEnough = language === "sv" ? `de anställda arbetar i en större stad (${city}) där lönerna och utgifterna är högre. ` : `${lang[language].AIbiggerCityOne}${city}${lang[language].AIbiggerCityTwo}. `
            let cityToghetherExplainNotEnough = language === "sv" ? `De arbetar dessutom i en större stad (${city}) där lönerna och utgifterna är högre. ` : `${lang[language].AIbiggerCityOneVerThree}${city}${lang[language].AIbiggerCityTwo}. `
            let cityOppositeNotEnough = language === "sv" ? `Notera dock att majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AinoteMaj} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} `
            let cityOppositeNotEnoughTogheter = language === "sv" ? `De arbetar dessutom i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AIBCityHigherAlso}${cityWomen}${lang[language].AIBCityHigher} `

            //Text for age
            let ageSelfExplainNotEnough = language === "sv" ? `de anställda är äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : lang[language].AIolderVerTwo
            let ageToghetherExplainNotEnough = language === "sv" ? `Utöver detta är de äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : lang[language].AIolderFour
            let ageOppositeNotEnough = language === "sv" ? `Gruppen ${firstGroup} är dock äldre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AIolderFive}`
            let ageOppositeNotEnoughTogheter = language === "sv" ? `Medelåldern är även högre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : lang[language].AIageAlsoHigher


            // --- If no reasons (result 1 and 0) Note: 0 is women have more exp etc ---
            let expOppositeOnly = language === "sv" ? `Gruppen ${firstGroup} har mer erfarenhet och lägre lön. ` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AImoreExpLowSal} `
            let expOppositeOnlyTogheter = language === "sv" ? `Gruppen ${firstGroup} har mer erfarenhet och lägre lön. ` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AImoreExpLowSal} `
            let cityOppositeOnly = language === "sv" ? `Gruppen ${firstGroup} har mer erfarenhet och lägre lön. ` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AImoreExpLowSal} `
            let cityOppositeOnlyTogheter = language === "sv" ? `Dessutom arbetar majoriteten i gruppen i en större stad vilket borde innebära lite högre löner.` : lang[language].AIalsobiggerCity
            let ageOppositeOnly = language === "sv" ? `Gruppen ${firstGroup} har högre medelålder vilket indikerar på att de har mer total erfarenhet. <age>` : `${lang[language].AItheGroup} ${firstGroup} ${lang[language].AIbiggerAgeInfo}`
            let ageOppositeOnlyTogheter = language === "sv" ? `Även medelåldern är högre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : lang[language].AIbiggerAgeInfoTwo
            let womenMoreDemandsOpposite = language === "sv" ? `${firstGroup} har dock högre krav men lägre lön.` : `${firstGroup}${lang[language].AIHigReqLowSalHowever}`
            let womenMoreDemandsOppositeStart = language === "sv" ? `${firstGroup} har dock högre krav men lägre lön.` : `${firstGroup}${lang[language].AIHigReqLowSalHowever}`

            //For testing, just comment this out when going live
            //let testResult = "expRes: " + expResult + "cityResult: " + cityResult + "ageResult: " + ageResult + "totResult: " + totalResult
            let testResult = ""

            //*** Demand fix ***

            // --- if women has 2 or more and totalResult is 2 or higher ---
            if (totalResult >= 2 && demandDifference >= 2) {
                if (salaryDiff < 2000 && expDiff >= 8) {
                    resultReturn.result = 3
                    let reason2 = introR + expSelfR


                    return [(language === "sv" ? `${firstGroup} har betydligt högre krav i arbetet och borde vara den grupp som har högre lön. ${secondGroup} har dock arbetat betydligt längre i organisationen vilket kan utgöra orsaken till löneskillnaden.` : `${firstGroup} ${lang[language].AihighShouldBiggerBut} ${secondGroup} ${lang[language].ihighShouldBiggerButTwo}`), notableWomenLessWomenMoreDemands, reason2, analysisType, resultReturn]
                }
                if (salaryDiff < 5000 && (expDiff >= 5 || ageDiff >= 10)) {
                    resultReturn.result = 0
                    return [(language === "sv" ? `${firstGroup} har betydligt högre krav i arbetet och borde vara den grupp som har högre lön. Det finns skillnader men då kraven skiljer väsentligt bör orsakerna ses över manuellt.` : `${firstGroup} ${lang[language].AihighShouldBiggerButThree}`), notableWomenLessWomenMoreDemands, noR, analysisType, resultReturn]
                }
                else {
                    resultReturn.result = -1
                    return [(language === "sv" ? `${firstGroup} har betydligt högre krav i arbetet och borde vara den grupp som har högre lön. Se över manuellt.` : `${firstGroup} ${lang[language].AihighShouldBiggerButFour}`), notableWomenLessWomenMoreDemands, noR, analysisType, resultReturn]
                }
            }
            // --- if women has 2 or more and totalResult is 1 or lower ---
            if (totalResult <= 1 && demandDifference >= 2) {
                resultReturn.result = -1
                return [(language === "sv" ? `${firstGroup} har betydligt högre krav i arbetet och borde vara den grupp som har högre lön.` : `${firstGroup} ${lang[language].AihighShouldBiggerBut}`), notableWomenLessWomenMoreDemands, noR, analysisType, resultReturn]
            }

            // --- if men has 2 or more and totalResult is 2 or higher ---
            if (totalResult >= 2 && demandDifference <= -2) {
                resultReturn.result = 3
                notableWomenLess = ""
                return [(language === "sv" ? `${secondGroup} har betydligt högre krav i arbetet varav det är rimligt att lönen är högre` : `${secondGroup} ${lang[language].AihighShouldBiggerButFive}`), notableWomenLess, demandSelfR, analysisType, resultReturn]
            }


            //*** End of demand fix ***/

            //If there is 3 in one group and one are man
            if (lessWomen < 90 && nrOfEmp === 3) {
                resultReturn.result = 3
                return [(language === "sv" ? `Löneskillnader finns, dock består ${firstGroup} bara av 3 anställda där 1 är man. Detta ger en hög procentuell andel kvinnor men det låga antalet personer kan göra det svårt att motivera kön som orsak.` : `${lang[language].AIdetailsOne} ${firstGroup} ${lang[language].AIdetailsTwo}`), (language === "sv" ? `${firstGroup} är kvinnodominerat och har lägre lön än ${secondGroup} som inte är kvinnodominerat.` : `${firstGroup} ${lang[language].AIdetailsThree} ${secondGroup}${lang[language].AIdetailsFour}`), (language === "sv" ? `Då ${firstGroup} endast består av 3 anställda varav en är man ger detta en hög procentuell andel kvinnor men det låga antalet personer kan göra det svårt att motivera kön som orsak. ` : `${lang[language].Aibecause} ${firstGroup} ${lang[language].AIdetailsOneTwo} `), analysisType, resultReturn]
            }

            //If can be explained (res 3)
            if (totalResult === 3) {

                //If autorobot and reason is higher group, dont count it in (do it for manually robot)
                if (autoRobot === "Yes" && demandDifference < 0) {
                    return ["ignore", "ignore"]
                }
                //if (autoRobot === "Yes") {return []}

                //This is specific if totalResult is 3. 

                /* -- Specific is from here -- */

                //If experience is 3 and no one is below 0 and demands are 0, then add this as the only reason (only do this for experience, the rest will be taken care of further down)
                if (expResult === 3 && (cityResult > 0 && ageResult > 0 && demandDifference === 0)) {
                    let string = intro + expSelfExplain + "." + testResult
                    let reason2 = introR + expSelfR
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                /* -- Specific to here -- */

                if (expResult === 3 && (cityResult <= 1 || ageResult <= 1)) {
                    //let oppositeReason = (cityResult === 0 && ageResult > 0) ? cityOpposite : (ageResult === 0 && cityResult > 0) ? ageOpposite : (ageResult === 0 && cityResult === 0) ? cityOpposite + ageOppositeTogheter : ""
                    let string = intro + expSelfExplain + "." + testResult
                    let reason2 = introR + expSelfR
                    if (demandDifference < 0) {
                        string = intro + menMoreDemandsTogheter + expSelfExplain + testResult
                        reason2 = demandTogheterR + expSelfR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        string = womenMoreDemandsIntro + expSelfExplain + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                if (expResult >= 2) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? cityToghetherExplain : (ageResult >= 2 && cityResult < 2) ? ageToghetherExplain : (ageResult >= 2 && cityResult >= 2) ? ageToghetherExplain + cityToghetherExplain : "."
                    let string = intro + expSelfExplain + nextReason + testResult
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    if (nextReasonR === "" || nextReasonR === ".") { expTogheterR = expSelfR }
                    let reason2 = introR + expTogheterR + nextReasonR

                    if (demandDifference < 0) {
                        string = intro + menMoreDemandsTogheter + expSelfExplain + nextReason + testResult
                        reason2 = demandTogheterR + expTogheterR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        string = womenMoreDemandsIntro + expSelfExplain + nextReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                if (expResult === 1) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? citySelfExplain + "." : (ageResult >= 2 && cityResult < 2) ? ageSelfExplain : (ageResult >= 2 && cityResult >= 2) ? citySelfExplain + ageToghetherExplain : ""
                    let string = intro + nextReason + testResult
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    let reason2 = introR + nextReasonR

                    if (demandDifference < 0) {
                        if (nextReason === "" || demandDifference <= -2) { menMoreDemandsTogheter = menMoreDemands }
                        string = intro + menMoreDemandsTogheter + nextReason + testResult
                        if (nextReasonR === "" || nextReasonR === ".") { demandTogheterR = demandSelfR }
                        reason2 = demandTogheterR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        string = womenMoreDemandsIntro + nextReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                if (expResult <= 0) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? citySelfExplain + "." : (ageResult >= 2 && cityResult < 2) ? ageSelfExplain : (ageResult >= 2 && cityResult >= 2) ? citySelfExplain + ageToghetherExplain : ""
                    let string = expOpposite + nextReason + testResult
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    let reason2 = introR + nextReasonR
                    if (demandDifference < 0) {
                        if (nextReason === "" || demandDifference <= -2) { menMoreDemandsTogheter = menMoreDemands }
                        string = intro + menMoreDemandsTogheter + nextReason + testResult
                        if (nextReasonR === "" || nextReasonR === ".") { demandTogheterR = demandSelfR }
                        reason2 = demandTogheterR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        string = womenMoreDemandsIntro + nextReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                //If none of above then the reason is only due to demand difference
                else if (demandDifference < 0) {
                    let string = intro + menMoreDemands + testResult
                    let reason2 = demandSelfR
                    notableWomenLess = ""
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
            }

            //If res 2:
            if (totalResult === 2) {

                if (expResult === 3 && (cityResult <= 1 || ageResult <= 1)) {
                    //precis som för nextReason nyttjar vi oppositeReason fast åt andra hållet
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    let string = introNotEnough + expSelfExplainNotEnough + oppositeReason + testResult
                    let reason2 = introPartlyR + expSelfR
                    if (demandDifference < 0) {
                        string = introNotEnough + menMoreDemandsTogheterNotEnough + expSelfExplainNotEnough + oppositeReason + testResult
                        reason2 = demandTogheterPartlyR + expSelfR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? demandSpecialCityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? demandSpecialAgeOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? demandSpecialCityOppositeNotEnough + demandSpecialAgeOppositeNotEnoughTogheter : ""
                        let bridge = oppositeReason === "" ? "" : demandSpecialBridge
                        string = womenMoreDemandsIntroNotEnough + expSelfExplainNotEnough + womenMoreDemansOutroNotEnough + bridge + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    //If women dominated group is not enough big difference and no demandDifferences, then return a total result of 3 instead
                    if (cityResult > -1 && ageResult > -1 && demandDifference === 0) {
                        let string = intro + expSelfExplain + "." + testResult
                        resultReturn.result = 3
                        return [string, notableWomenLess, reason2, analysisType, resultReturn]
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }

                if (expResult >= 2) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? cityToghetherExplainNotEnough : (ageResult >= 2 && cityResult < 2) ? ageToghetherExplainNotEnough : (ageResult >= 2 && cityResult >= 2) ? cityToghetherExplainNotEnough + ageToghetherExplainNotEnough : ""
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    if (demandDifference === 0 && cityResult >= 0 && cityResult <= 1 && ageResult >= 0 && ageResult <= 1) {
                        nextReason = salaryToBig
                    }
                    let string = introNotEnough + expToghetherExplainNotEnough + nextReason + oppositeReason + testResult
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    if (nextReasonR === "" || nextReasonR === ".") { expTogheterR = expSelfR }
                    let reason2 = introPartlyR + expTogheterR + nextReasonR

                    if (demandDifference < 0) {
                        string = introNotEnough + menMoreDemandsTogheterNotEnough + expSelfExplainNotEnough + nextReason + oppositeReason + testResult
                        reason2 = demandTogheterPartlyR + expTogheterR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? demandSpecialCityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? demandSpecialAgeOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? demandSpecialCityOppositeNotEnough + demandSpecialAgeOppositeNotEnoughTogheter : ""
                        let bridge = oppositeReason === "" ? "" : demandSpecialBridge
                        string = womenMoreDemandsIntroNotEnough + expSelfExplainNotEnough + nextReason + womenMoreDemansOutroNotEnough + bridge + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                        if (salaryDiff > 8000 && nextReason !== "" && expDiff <= 5) {
                            string = womenMoreDemandSalary
                            reason2 = noR
                        }
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                if (expResult === 1) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? citySelfExplainNotEnough : (ageResult >= 2 && cityResult < 2) ? ageSelfExplainNotEnough : (ageResult >= 2 && cityResult >= 2) ? citySelfExplainNotEnough + ageToghetherExplainNotEnough : ""
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    //If oppositeReason is not enough and nextReason is something, then the totalResult of 2 has to bee due to to high salary
                    let toLargeSalary = demandDifference === 0 && oppositeReason === "" && nextReason !== "" ? salaryToBig : ""
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    let reason2 = introPartlyR + nextReasonR
                    //If nextreason and oppositeReason is empty
                    if (demandDifference === 0 && oppositeReason === "" && nextReason === "") {
                        introNotEnough = ""
                        nextReason = nothingFound
                        reason2 = noR
                    }

                    let string = introNotEnough + nextReason + oppositeReason + toLargeSalary + testResult

                    if (demandDifference < 0) {
                        menMoreDemandsTogheterNotEnough = nextReason === "" ? menMoreDemandsNotEnough : menMoreDemandsTogheterNotEnough
                        string = introNotEnough + menMoreDemandsTogheterNotEnough + nextReason + oppositeReason + testResult
                        if (nextReasonR === "" || nextReasonR === ".") { demandTogheterPartlyR = demandSelfPartlyR }
                        reason2 = demandTogheterPartlyR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? demandSpecialCityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? demandSpecialAgeOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? demandSpecialCityOppositeNotEnough + demandSpecialAgeOppositeNotEnoughTogheter : ""
                        let bridge = oppositeReason === "" ? "" : demandSpecialBridge
                        string = womenMoreDemandsIntroNotEnough + nextReason + womenMoreDemansOutroNotEnough + bridge + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                        if (nextReason === "" || nextReasonR === ".") {
                            string = womenMoreDemandsNoReasons
                            reason2 = noR
                        }
                        if (salaryDiff > 8000 && nextReason !== "" && expDiff <= 5) {
                            string = womenMoreDemandSalary
                            reason2 = noR
                        }
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                if (expResult <= 0) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? citySelfExplainNotEnough : (ageResult >= 2 && cityResult < 2) ? ageSelfExplainNotEnough : (ageResult >= 2 && cityResult >= 2) ? citySelfExplainNotEnough + ageToghetherExplainNotEnough : ""
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    //If oppositeReason is not enough and nextReason is something, then the totalResult of 2 has to bee due to to high salary
                    let toLargeSalary = demandDifference === 0 && oppositeReason === "" && nextReason !== "" ? salaryToBig : ""
                    let nextReasonR = (cityResult >= 2 && ageResult < 2) ? citySelfR : (ageResult >= 2 && cityResult < 2) ? ageSelfR : (ageResult >= 2 && cityResult >= 2) ? cityTogheterR + ageSelfR : ""
                    let reason2 = introPartlyR + nextReasonR
                    //If nextreason and oppositeReason is empty
                    if (demandDifference === 0 && oppositeReason === "" && nextReason === "") {
                        introNotEnough = ""
                        nextReason = nothingFound
                        reason2 = noR
                    }
                    let string = introNotEnough + nextReason + oppositeReason + toLargeSalary + testResult

                    if (demandDifference < 0) {
                        menMoreDemandsTogheterNotEnough = nextReason === "" ? menMoreDemandsNotEnough : menMoreDemandsTogheterNotEnough
                        string = introNotEnough + menMoreDemandsTogheterNotEnough + nextReason + oppositeReason + testResult
                        if (nextReasonR === "") { demandTogheterPartlyR = demandSelfPartlyR }
                        reason2 = demandTogheterPartlyR + nextReasonR
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? demandSpecialCityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? demandSpecialAgeOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? demandSpecialCityOppositeNotEnough + demandSpecialAgeOppositeNotEnoughTogheter : ""
                        let bridge = oppositeReason === "" ? "" : demandSpecialBridge
                        string = womenMoreDemandsIntroNotEnough + nextReason + womenMoreDemansOutroNotEnough + bridge + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                        if (nextReason === "") {
                            string = womenMoreDemandsNoReasons
                            reason2 = noR
                        }
                        if (salaryDiff > 8000 && nextReason !== "" && expDiff <= 5) {
                            string = womenMoreDemandSalary
                            reason2 = noR
                        }
                    }
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
                //If none of above the reason has to do with demand groups
                else if (demandDifference < 0) {
                    let string = introNotEnough + menMoreDemandsNotEnough + testResult
                    let reason2 = demandSelfPartlyR
                    notableWomenLess = ""
                    return [string, notableWomenLess, reason2, analysisType, resultReturn]
                }
            }

            //If res 1 or 0 or -1:
            if (totalResult <= 1) {
                //console.log("expResult: " + expResult + "cityResult" + cityResult + "ageResult" + ageResult)
                //exp: -1, city: 1, age: -1

                if (expResult === 3 && (cityResult <= 1 || ageResult <= 1)) {
                    //precis som för nextReason nyttjar vi oppositeReason fast åt andra hållet
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    //If oppositeReason is not enough and nextReason is something, then the totalResult of 2 has to bee due to to high salary
                    let toLargeSalary = demandDifference === 0 && oppositeReason === "" ? salaryToBig : ""
                    let string = introEqual + introEqualFollowUp + expSelfExplainNotEnough + oppositeReason + toLargeSalary + testResult
                    if (demandDifference < 0) {
                        string = introEqual + introEqualFollowUp + menMoreDemandsTogheterNotEnough + expSelfExplainNotEnough + oppositeReason + testResult
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnoughTogheter : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnoughTogheter : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnoughTogheter + ageOppositeNotEnoughTogheter : ""
                        string = introEqual + introEqualFollowUp + expSelfExplainNotEnough + womenMoreDemandsOpposite + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    // Såhär ser texten ut: "!Orsak kan inte förklaras.! !Vissa orsaker hittades dock, såsom att! !arbetat längre.! !Kvinnogruppen har dock högre krav men lägre lön.! !Majoriteten av kvinnogrupp arbetar dessutom i större stad och har högre ålder.!"
                    return [string, notableWomenLess, noR, analysisType, resultReturn]
                }

                if (expResult >= 2) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? cityToghetherExplainNotEnough : (ageResult >= 2 && cityResult < 2) ? ageToghetherExplainNotEnough : (ageResult >= 2 && cityResult >= 2) ? cityToghetherExplainNotEnough + ageToghetherExplainNotEnough : ""
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    //If oppositeReason is not enough and nextReason is something, then the totalResult of 2 has to bee due to to high salary
                    let toLargeSalary = demandDifference === 0 && oppositeReason === "" && nextReason !== "" ? salaryToBig : ""
                    let string = introEqual + introEqualFollowUp + expToghetherExplainNotEnough + nextReason + oppositeReason + toLargeSalary + testResult
                    if (demandDifference < 0) {
                        string = introEqual + introEqualFollowUp + menMoreDemandsTogheterNotEnough + expToghetherExplainNotEnough + nextReason + salaryToBig + oppositeReason + testResult
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnoughTogheter : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnoughTogheter : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnoughTogheter + ageOppositeNotEnoughTogheter : ""
                        string = introEqual + introEqualFollowUp + expToghetherExplainNotEnough + nextReason + womenMoreDemandsOpposite + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    return [string, notableWomenLess, noR, analysisType, resultReturn]
                }
                if (expResult <= 1) {
                    let nextReason = (cityResult >= 2 && ageResult < 2) ? citySelfExplainNotEnough : (ageResult >= 2 && cityResult < 2) ? ageSelfExplainNotEnough : (ageResult >= 2 && cityResult >= 2) ? citySelfExplainNotEnough + ageToghetherExplainNotEnough : ""
                    let oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnough : (ageResult === -1 && cityResult > 0) ? ageOppositeNotEnough : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnough + ageOppositeNotEnoughTogheter : ""
                    //If oppositeReason is not enough and nextReason is something, then the totalResult of 2 has to bee due to to high salary
                    let toLargeSalary = demandDifference === 0 && oppositeReason === "" && nextReason !== "" ? salaryToBig : ""
                    //If nextreason and oppositeReason is empty
                    if (demandDifference === 0 && oppositeReason === "" && nextReason === "") {
                        introNotEnough = ""
                        introEqualFollowUp = ""
                        nextReason = nothingFound
                    }

                    if (demandDifference > 0 && oppositeReason !== "") { //LOOK INTO
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? 
                        (language === "sv" ? `Notera även att majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} `)
                        : (ageResult === -1 && cityResult > 0) ? 
                        (language === "sv" ? `Notera även att gruppen ${firstGroup} är äldre, vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIisOlderGeneral}`)
                        : (ageResult === -1 && cityResult === -1) ? 
                        (language === "sv" ? `Notera även att majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. Medelåldern är även högre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} ${lang[language].AIageAlsoHigher}`)
                        : ""
                    }

                    if (demandDifference < 0 && nextReason === "" && oppositeReason !== "") { //LOOK INTO
                        oppositeReason = (cityResult === -1 && ageResult > -1) ? 
                        (language === "sv" ? `Notera även att majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. ` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} `)
                        : (ageResult === -1 && cityResult > 0) ? 
                        (language === "sv" ? `Notera även att gruppen ${firstGroup} är äldre, vilket indikerar på att de bär med sig mer erfarenhet sen tidigare.<age>` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIisOlderGeneral}`)
                        : (ageResult === -1 && cityResult === -1) ? 
                        (language === "sv" ? `Notera även att majoriteten i gruppen ${firstGroup} arbetar i en större stad (${cityWomen}) där lönerna ofta är högre. Medelåldern är även högre vilket indikerar på att de bär med sig mer erfarenhet sen tidigare. <age>` : `${lang[language].AImultiOne} ${firstGroup} ${lang[language].AIBCityOne}${cityWomen}${lang[language].AIBCityHigher} ${lang[language].AIageAlsoHigher}`)
                        : ""
                        notableWomenLess = ""
                    }

                    if (nextReason === "" && demandDifference === 0) {
                        introEqualFollowUp = ""
                    }

                    let string = introEqual + introEqualFollowUp + nextReason + oppositeReason + toLargeSalary + testResult

                    if (demandDifference === 0 && nextReason === "" && (oppositeReason !== "" || expResult < 0)) {
                        let womenMore = ""
                        if (cityResult < 0 && ageResult < 0 && expResult < 0) { womenMore = (language === "sv" ? "Den kvinnodominerade gruppen har längre erfarenhet, är äldre och arbetar i en större stad där lönen oftast är högre.<age>" : lang[language].AIwomenHigherAll) }
                        if (cityResult >= 0 && ageResult < 0 && expResult < 0) { womenMore = (language === "sv" ? "Den kvinnodominerade gruppen har arbetat längre i organisationen och är äldre.<age>" : lang[language].AIwomenHigherTwo) }
                        if (cityResult < 0 && ageResult >= 0 && expResult < 0) { womenMore = (language === "sv" ? "Den kvinnodominerade gruppen har mer erfarenhet och arbetar i en större stad där lönen oftast är högre." : lang[language].AIwomenHigherThree) }
                        if (cityResult < 0 && ageResult < 0 && expResult >= 0) { womenMore = (language === "sv" ? "Den kvinnodominerade gruppen är äldre och arbetar i en större stad där lönen oftast är högre.<age>" : lang[language].AIwomenHigherFour) }

                        if (cityResult < 0 && ageResult >= 0 && expResult >= 0) { womenMore = (language === "sv" ? "Notera att den kvinnodominerade gruppen arbetar i en större stad där lönen oftast är högre." : lang[language].AIwomenHigherFive) }
                        if (cityResult >= 0 && ageResult >= 0 && expResult < 0) { womenMore = (language === "sv" ? "Notera att den kvinnodominerade gruppen har längre erfarenhet." : lang[language].AIwomenHigherSix) }
                        if (cityResult >= 0 && ageResult < 0 && expResult >= 0) { womenMore = (language === "sv" ? "Notera att den kvinnodominerade gruppen är äldre. <age>" : lang[language].AIwomenHigherSeven) }
                        string = introEqual + womenMore
                    }
                    if (demandDifference === 0 && nextReason === "" && oppositeReason === "" && expResult >= 0) {
                        string = introEqual
                    }

                    if (demandDifference < 0) {
                        menMoreDemandsTogheterNotEnough = nextReason === "" ? menMoreDemandsNotEnough : menMoreDemandsTogheterNotEnough
                        string = (nextReason === "" && salaryDiff > 10000) ? string = demandNotEnough + oppositeReason :
                            introEqual + introEqualFollowUp + menMoreDemandsTogheterNotEnough + nextReason + salaryToBig + oppositeReason + testResult
                        notableWomenLess = ""
                    }
                    if (demandDifference > 0) {
                        oppositeReason = (cityResult === 0 && ageResult > 0) ? cityOppositeNotEnoughTogheter : (ageResult === 0 && cityResult > 0) ? ageOppositeNotEnoughTogheter : (ageResult === 0 && cityResult === 0) ? cityOppositeNotEnoughTogheter + ageOppositeNotEnoughTogheter : ""
                        string = (nextReason !== "") ? introEqual + introEqualFollowUp + nextReason + womenMoreDemandsOpposite + oppositeReason + testResult :
                            `Högre lön kan inte per automatik förklaras. ${firstGroup} har högre krav i sitt arbete och borde ha högre lön än ${secondGroup}. Se över manuellt.` + oppositeReason + testResult
                        notableWomenLess = notableWomenLessWomenMoreDemands
                    }
                    //If no reasons found, answer with other text
                    /* if (cityResult <= 1 && ageResult <= 1) {
                         oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeOnly : (ageResult === -1 && cityResult > -1) ? ageOppositeOnly : (ageResult === -1 && cityResult === -1) ? cityOppositeOnly + ageOppositeOnlyTogheter : ""
                         string = introEqual + oppositeReason + testResult
                         if (demandDifference < 0) {
                             string = introEqual + introEqualFollowUp + menMoreDemandsNotEnough + oppositeReason + testResult
                         }
                         if (demandDifference > 0) {
                             oppositeReason = (cityResult === -1 && ageResult > -1) ? cityOppositeNotEnoughTogheter : (ageResult === -1 && cityResult > -1) ? ageOppositeNotEnoughTogheter : (ageResult === -1 && cityResult === -1) ? cityOppositeNotEnoughTogheter + ageOppositeNotEnoughTogheter : ""
                             string = introEqual + womenMoreDemandsOppositeStart + oppositeReason + testResult
                         }
                     }*/
                    return [string, notableWomenLess, noR, analysisType, resultReturn]
                }
            }
        }

    } else {
        // if both are women dominated
        if (lessWomen > 59 && moreWomen > 59) {
            return [(language === "sv" ? "Båda grupperna är dominerade av kvinnor och behöver ej jämföras." : lang[language].AInoNeedOne), "ignore", "ignore", analysisType]
        }
        // if both are men dominated
        if (lessWomen < 41 && moreWomen < 41) {
            return [(language === "sv" ? "Båda grupperna är dominerade av män och behöver ej jämföras." : lang[language].AInoNeedTwo), "ignore", "ignore", analysisType]
        }
        // if both are equal
        if (lessWomen < 60 && lessWomen > 40 && moreWomen < 60 && moreWomen > 40) {
            return [(language === "sv" ? "Båda grupperna är jämställda och behöver ej jämföras." : lang[language].AInoNeedThree), "ignore", "ignore", analysisType]
        }
        // If one is male dominated and if one is equal
        if ((lessWomen < 41 && moreWomen > 40 && moreWomen < 60) || (moreWomen < 41 && lessWomen > 40 && lessWomen < 60)) {
            return [(language === "sv" ? "Ingen kvinnodominerad grupp vald." : lang[language].AInoNeedFour), "ignore", "ignore", analysisType]
        }
    }
    //If nothing in this file fits:
    return [(language === "sv" ? "Ett fel inträffade." : lang[language].importError), "ignore", "ignore"]
}