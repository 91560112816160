import React from 'react';
import './Arbetsvardera.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import { PopUp } from './Popup/PopUp.js';
import infoImg from '../../assets/images/info.png';
import exitImg from '../../assets/images/exit.png';
import { fetchAuth } from '../../fetch-auth';
import MultiSearchSelect from "react-search-multi-select";
import { Prompt } from 'react-router'
import arrowDown from '../../assets/images/arrow-down-black.png';
import lang from '../Language/language.js';

let selectedValues = []; //Fix because of bug in MultiSearchSelect

export class ArbetsvarderaExt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whatExtValuation: "extLevel",
            pointRanges: [{
                nr: 1,
                from: 100,
                to: 199,
            }, {
                nr: 2,
                from: 200,
                to: 299,
            }],
            groupedData: [],
            openGroups: [],
            valuationName: "",
            showChangesPrompt: "Yes",
            saveButtonStatus: "Sparat"
        }
    }
    componentDidMount() {
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        //this.getData(); <-- Use for normal work evaluation
        this.getDataExt(); //Use for ext work evaluation
    }

    getDataExt() {
        const runIt = async () => {
            let response = await fetchAuth(`/api/loadArbetsvarderingExisting`, 'POST');
            let data = await response.json();
            let positionsData = data[0][0]
            let evaluationData = data[1]
            let infoData = data[2][0][0]
            //1. Group all equal groups with the positions as children
            const result = positionsData.reduce((acc, obj) => {
                const { groupNameArbVardering, positionTitle } = obj;
                if (!acc[groupNameArbVardering]) {
                    acc[groupNameArbVardering] = { groupNameArbVardering, positionTitle: [positionTitle] };
                } else {
                    acc[groupNameArbVardering].positionTitle.push(positionTitle);
                }
                return acc;
            }, {});
            const groupedData = Object.values(result);
            //2. Now loop the groups and check if there is only one position and if it then is the same group, if so, make it empty
            for (let i = 0; i < groupedData.length; i++) {
                if (groupedData[i].positionTitle.length === 1 && groupedData[i].positionTitle[0] === groupedData[i].groupNameArbVardering) {
                    groupedData[i].positionTitle = []
                }
            }
            //3. Add the equalent group, which comes from another array, it copies evaluation from evaluationData to groupedData if groupName and groupNameArbVardering match
            const groupedDataUpdated = groupedData.map(obj => {
               // const matchObj = evaluationData.find(arr => arr[0].groupName === obj.groupNameArbVardering);
               const matchObj = evaluationData.find(arr => arr[0] && arr[0].groupName && arr[0].groupName === obj.groupNameArbVardering);
                 return matchObj ? { ...obj, extEqualentGroup: matchObj[0].extEqualentGroup } : obj;
            });
            //4. Sort by alphabetic order and make sure empty groups are at the top
          groupedDataUpdated.sort((a, b) => {
            // Sort null eqGroup values first
            if (a.extEqualentGroup === null && b.extEqualentGroup !== null) {
              return -1; // a comes first
            } else if (a.extEqualentGroup !== null && b.extEqualentGroup === null) {
              return 1; // b comes first
            }
            // Sort by group in alphabetical order
            if (a.groupNameArbVardering < b.groupNameArbVardering) {
              return -1; // a comes first
            } else if (a.groupNameArbVardering > b.groupNameArbVardering) {
              return 1; // b comes first
            }
            return 0; // elements are equal in sorting criteria
          });
            this.setState({
                groupedData: groupedDataUpdated,
                valuationName: infoData.valuationName,
                whatExtValuation: infoData.whatEvaluation
            })
        }
        runIt();
    }

    openGroups = (event) => {
        const id = event.target.getAttribute('theId');
        const openGroups = this.state.openGroups.slice()
        if (openGroups.includes(id)) {
            //remove it
            const index = openGroups.indexOf(id);
            if (index !== -1) {
                openGroups.splice(index, 1);
            }
        } else { openGroups.push(id) } //add it
        this.setState({ openGroups: openGroups })
    }

    addLevel = (event) => {
        const id = event.target.id
        const level = event.target.value
        const groupedData = this.state.groupedData.slice()
        //extEqualentGroup
        for (let i = 0; i < groupedData.length; i++) {
            if (groupedData[i].groupNameArbVardering === id) {
                groupedData[i].extEqualentGroup = level
                groupedData[i].edited = "edited"
            }
        }
        this.setState({ groupedData: groupedData, saveButtonStatus: "Spara" })
    }

    valuationName = (event) => {
        const name = event.target.value
        this.setState({ valuationName: name, saveButtonStatus: "Spara" })
    }

    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.whatExtValuation === "extLevel") {
            const saveData = async () => {
                let groupedData = this.state.groupedData.slice()
                let relevantGroupedData = groupedData.filter(item => item.edited === "edited")
                const saveData = {
                    valuationName: this.state.valuationName,
                    whatEvaluation: "extLevel",
                    levelResults: relevantGroupedData,
                }
                let response = await fetchAuth(`/api/saveArbetsvarderingExtLevel`, 'POST', JSON.stringify({ data: saveData }));
                let data = await response.json();
                data === "Saved" ? this.setState({ saveButtonStatus: "Sparat" }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
            };
            saveData();
        } else {
            //SaveData for points
        }
    }


    //* For ext valuation
    whatExtValuationLevel = () => { this.setState({ whatExtValuation: "extLevel", saveButtonStatus: "Spara" }) }
    whatExtValuationPoint = () => { this.setState({ whatExtValuation: "extPoint", saveButtonStatus: "Spara" }) }
    CreatePointRanges = () => {
        let oneRange = this.state.pointRanges.slice()
        let numbers = []
        if (oneRange.length > 0) {
            for (let i = 0; i < oneRange.length; i++) {
                numbers.push(oneRange[i].nr)
            }
            let highestNr = Math.max(...numbers);
            let newNumber = highestNr + 1
            oneRange.push({
                nr: newNumber,
                from: 0,
                to: 0,
            })
            this.setState({ pointRanges: oneRange })
        } else {
            this.setState({
                pointRanges: [
                    {
                        nr: 1,
                        from: 0,
                        to: 0,
                    }
                ]
            })
        }
    }

    DeletePointRanges = () => {
        let oneRange = this.state.pointRanges.slice()
        oneRange.sort((a, b) => a.nr - b.nr);
        oneRange.pop()
        this.setState({ pointRanges: oneRange })
    }

    editPointRange = (event) => {
        let changeWhat = event.target.getAttribute('changeWhat');
        let nr = event.target.getAttribute('nr');
        let theValue = event.target.value
        let oneRange = this.state.pointRanges.slice()
        //Update the pointRange item
        for (let i = 0; i < oneRange.length; i++) {
            if (oneRange[i].nr == nr) {
                oneRange[i][changeWhat] = theValue
            }
        }
        this.setState({ pointRanges: oneRange })
    }

    render() {

        //PointRanges
        let pointRanges = []
        if (this.state.pointRanges.length > 0) {
            pointRanges = this.state.pointRanges
        }

        //Groups and titles 
        let groupedData = []
        if (this.state.groupedData.length > 0) {
            groupedData = this.state.groupedData
        }
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container">

                    <Information step="arbetsvarderaExt" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{this.state.saveButtonStatus}</button>


                    {/* Container for external work evaluation */}
                    <div className="evaluate-container">

                        <div className="extValSettingContainer">
                            <div className="extValInline">
                                <div className="extValTitle">{language === "sv" ? "Ange värderingssystemets namn" : lang[language].enterName}</div>
                                <input className="extValNameField" id="LikaGrupp" placeholder="System" type="text" onChange={this.valuationName} value={this.state.valuationName} />
                            </div>

                           {/* <div className="extValInline">
                              <div className="extValTitle">Välj om ni vill ange nivå eller poäng</div>
                                <div className="extValContainer">
                                    <div className={this.state.whatExtValuation === "extLevel" ? "extValSelection extValLeft extValActive" : "extValSelection extValLeft"} onClick={this.whatExtValuationLevel}>Nivå</div><div className={this.state.whatExtValuation === "extPoint" ? "extValSelection extValRight extValActive" : "extValSelection extValRight"} onClick={this.whatExtValuationPoint}>Poäng</div>
        </div>
        </div> */}
                        </div>

                        <div className={this.state.whatExtValuation === "extLevel" ? "levelContainer" : "hide"}>
                            <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>{language === "sv" ? "Ange nivå" : lang[language].addLevel}</h3>
                            {/*} <div className="extValOpenBtn">Öppna alla grupper</div> // Open/close */}
                            <ul>
                                {/* Load groups and positions */}
                                {groupedData.map((work) =>
                                    <li className="list" style={{ maxWidth: 350, display: "block", fontSize: "13.6px", overflow: "hidden" }}>
                                        <div style={{ display: "flex" }}>
                                            <div className="inline positionList" style={{ maxWidth: "none", width: "75%", marginTop: "12.5px" }}>
                                                <img theId={work.groupNameArbVardering} onClick={this.openGroups} src={arrowDown} className={work.positionTitle.length > 0 ? "extValArrow" : "hide"} />{work.groupNameArbVardering}
                                            </div>
                                            <div className="inline" style={{ width: "25%" }}>
                                                <input onChange={this.addLevel} className="equalInput extValNoArrows" id={work.groupNameArbVardering} placeholder="Ange nivå" type="number" value={work.extEqualentGroup} />
                                            </div>
                                        </div>
                                        {/* Children */}
                                        <ul className={this.state.openGroups.includes(work.groupNameArbVardering) ? "extValUl" : "hide"}>
                                            {work.positionTitle.map((position) =>
                                                <li className="extValLiChild">{position}</li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        </div>

                        {/*<div className={this.state.whatExtValuation === "extPoint" ? "" : "hide"}>
                            <div className="leftAndRightContainer">
                                <div className="leftList" style={{ flex: "0.75 1" }}>
                                    <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>Ange poäng</h3>
                                    <ul>

                                        <li className="list">
                                            <div className="inline positionList" style={{ maxWidth: "62%", width: "62%" }}><img src={arrowDown} className="extValArrow" />Montör</div>
                                            <div className="inline">
                                                <input style={{ width: 100 }} className="equalInput extValNoArrows" placeholder="Ange poäng" type="number" id="Montör" />
                                            </div>
                                            <div className="inline positionList">
                                                <div className="extValNumber extValNumerTwo">1</div>
                                            </div>
                                        </li>
                                        <li className="list">
                                            <div className="inline positionList" style={{ maxWidth: "62%", width: "62%" }}>Redovisningsekonom</div>
                                            <div className="inline">
                                                <input style={{ width: 100 }} className="equalInput extValNoArrows" placeholder="Ange poäng" type="number" />
                                            </div>
                                            <div className="inline positionList">
                                                <div className="extValNumber extValNumerTwo extValNoNumber">-</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rightContainer">
                                    <div>
                                        <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>Ange poängnivåer</h3>
                                        <div className="extValCreateBtn" onClick={this.CreatePointRanges}>Skapa</div>
                                        <ul>
                                            {pointRanges.map((item, index) => (
                                                <li className="list" style={{ maxWidth: 200, height: 41, overflow: "hidden" }} id={item.nr}>
                                                    <div className="inline positionList" style={{ maxWidth: "none", width: "150%" }}>
                                                        <div className="extValNumber">{item.nr}</div>
                                                    </div>
                                                    <div className="inline">
                                                        <input style={{ width: "90%" }} className="equalInput extValNoArrows" nr={item.nr} changeWhat={"from"} placeholder="Från" type="number" onChange={this.editPointRange} value={item.from} />
                                                    </div>
                                                    <div className="inline">-</div>
                                                    <div className="inline">
                                                        <input style={{ width: "90%" }} className="equalInput extValNoArrows" nr={item.nr} changeWhat={"to"} placeholder="Till" type="number" onChange={this.editPointRange} value={item.to} />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className={this.state.pointRanges.length > 0 ? "extValRemoveBtn" : "hide"} onClick={this.DeletePointRanges}>Ta bort</div>
                                    </div>
                                </div>
                            </div>

                                            </div>*/}




                    </div>

                </div>
            </>
        );
    }
}