import React from "react";
import lang from '../../../Language/language.js';

export class Innehall extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <div className="aPage">
                <h2 style={{ textAlign: "center", paddingTop: 40, paddingBottom: 40 }}>{language === "sv" ? "Innehållsförteckning" : lang[language].tableOfCont}</h2>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "1. Inledning" : lang[language].tocIntro}</h4>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "2. Metod" : lang[language].tocMethod}</h4>
                <span style={{ fontSize: 14, paddingLeft: 20, display: "block", paddingBottom: 8, paddingTop: 2 }}>{language === "sv" ? "2.1 Verktyg" : lang[language].tocSys}</span>
                <span style={{ fontSize: 14, paddingLeft: 20, display: "block", paddingBottom: 8 }}>{language === "sv" ? "2.2 Viktning & bedömning" : lang[language].tocWeight}</span>
                <span style={{ fontSize: 14, paddingLeft: 20, display: "block", paddingBottom: 5 }}>{language === "sv" ? "2.3 Arbetsgång" : lang[language].tocProcedure}</span>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "3. Samverkan" : lang[language].tocCollab}</h4>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "4. Kartläggning & analys av lönebestämmelser & praxis" : lang[language].tocAnalyze}</h4>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "5. Resultat & analys" : lang[language].tocResults}</h4>
                <span style={{ fontSize: 14, paddingLeft: 20, display: "block", paddingBottom: 8, paddingTop: 2 }}>{language === "sv" ? "5.1 Lika arbete" : lang[language].tocEqualWork}</span>
                <span style={{ fontSize: 14, paddingLeft: 20, display: "block", paddingBottom: 5 }}>{language === "sv" ? "5.2 Likvärdiga arbeten & hierarkiska skillnader " : lang[language].tocEquivalent}</span>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "6. Handlingsplan" : lang[language].tocAction}</h4>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "7. Utvärdering av föregående års åtgärder" : lang[language].tocEvaluation}</h4>
                <h4 style={{ marginBlockEnd: 10 }}>{(this.props.avslutning !== undefined && this.props.avslutning !== "") ? "8. " + this.props.avslutning : ""}</h4>
            </div>
        )
    }
}