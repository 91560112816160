import React from "react";
import lang from '../../../Language/language.js';

export class Metod extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let factorDescriptions = [
            {
                question: "utbildning",
                category: "Knowledge",
                instructions: "- Utbildning\n- Arbetslivserfarenhet",
                focus: "Sammanfattande bedömning över hur stora krav tjänsten ställer på utbildning och arbetslivserfarenhet.",
                level1: "Inga eller låga krav på utbildning och relevant arbetslivserfarenhet.",
                level2: "Kräver eftergymnasial utbildning eller relevant yrkeserfarenhet.",
                level3: "Kräver hög kunskap. Förvärvad genom hög utbildning eller medelhög utbildning med längre relevant arbetslivserfarenhet.",
                level4: "Kräver mycket specialiserad eller bred kunskap. Förvärvard genom hög utbildning och lång relevant arbetslivserfarenhet.",
            },
            {
                question: "problem",
                category: "Knowledge",
                instructions: "- Hantera uppkommande problem\n- Analysering\n- Kreativitet",
                focus: "- Hur ofta problem förekommer och måste lösas\n- Självständigheten i arbetet. Finns det hjälp att tillgå?\n- Komplexiteten i uppgifterna\n- Om måste sätta sig in i nya områden till följd av snabb utveckling",
                level1: "Enkla och ofta rutinmässiga uppgifter där problemlösning förekommer i mindre utsträckning. För lösningar finns oftast kända tillvägagångssätt.",
                level2: "Kräver viss analys och kreativitet. Komplicerade problem förekommer men ska diskuteras med överordnad innan beslut.",
                level3: "Problemlösning vanligt förekommande. Självständig analys, nytänkande, utvärdering av alternativ inför beslut. Lösningar är sällan givna eller definierade.",
                level4: "Problemlösning är stor del i arbetet. Varierade, komplexa problem. Kräver kreativa lösningar, ofta nya angreppssätt, kvalificerad kunskapsinhämtning och stor självständighet i beslut.",
            },
            {
                question: "sociala",
                category: "Knowledge",
                instructions: "- Kommunikation\n- Samarbete\n- Service",
                focus: "- Hur stor andel i arbetet som kräver sociala färdigheter\n- Om situationerna ofta är krävande\n- Om det ofta förekommer intressemotsättningar",
                level1: "Inga krav på särskilda sociala färdigheter.",
                level2: "Färdigheter i kommunikation och samarbete både internt och externt. Kontinuerliga dialoger. Kan innehålla intressemotsättningar.",
                level3: "Kommunikation är en betydande del i arbetet. Ofta krävande situationer och intressemotsättningar. Kunna vägleda och argumentera.",
                level4: "Sociala färdigheter avgörande för arbetet. Svåra och komplicerade kontakter med personer/grupper. Ofta stora intressemotsättningar eller svåra situationer.",
            },
            {
                question: "personal",
                category: "Responsibility",
                instructions: "- Personalansvar\n- Arbetsledning",
                focus: "- Antal anställda\n- Om en större del av arbetet upptas av arbetsledning\n- Självständigheten i ansvaret",
                level1: "Har inget personalansvar eller ytterst få medarbetare där arbetsledning är en mycket liten del av arbetet.",
                level2: "Ansvarar för en mindre grupp anställda. Arbetsledning är en viss del av arbetet.",
                level3: "Ansvarar för ett betydande antal anställda. Arbetsledning är en stor del av arbetet.",
                level4: "Övergripande chefsansvar för stora personalgrupper eller avdelningar. Ofta arbetsledning av andra chefer.",
            },
            {
                question: "verksamhet",
                category: "Responsibility",
                instructions: "- Planering\n- Utveckling\n- Resultat",
                focus: "- Om arbetet är av långsiktig betydelse för organisationen\n- Hur starkt resultatet påverkar organisationen\n- Självständigheten i ansvaret",
                level1: "Riktlinjer skapat av någon annan finns att följa. Inget eller litet ansvar för planering, utveckling och resultat. Kan delta i planering för egna gruppen.",
                level2: "Visst ansvar för planering, utveckling eller resultat. Ej nödvändigtvis arbetsledande funktion. Självständigt ansvar för arbetssätt, tidsplanering eller budget.",
                level3: "Övergripande och strategiskt ansvar för planering, utveckling och resultat. Kan styra och förändra processer inom egna verksamhetsområdet.",
                level4: "Övergripande affärsmässigt helhetsansvar för en/flera grupper eller verksamhetsområden. Utformar och påverkar varor/tjänster samt normer och riktlinjer som styr."
            },
            {
                question: "manniskor",
                category: "Responsibility",
                instructions: "- Kunder\n- Patienter\n- Ansvar för andras hälsa eller säkerhet\n- Påverkan på människor genom beslut",
                focus: "- Självständigheten i ansvaret\n- Hur mycket ansvaret för människor dominerar i arbetet\n- Hur starkt arbetet påverkar andra människor\n- Om känslig/konfidentiell information om människor tillhandahålls",
                level1: "Ansvaret är begränsat till normal hänsyn och omtanke för andras välbefinnande.",
                level2: "Ett visst ansvar för andras hälsa, utveckling eller säkerhet. Innebär ofta att påverka andra genom att inom ramen följa regler och anvisningar. Hantering av känslig information kan förekomma.",
                level3: "Betydande ansvar/påverkan på andras hälsa, utveckling eller säkerhet. Självständiga beslut som påverkar andra människor är vanligt.",
                level4: "Mycket stora krav att självständigt bedöma och fatta avgörande beslut som påverkar andras hälsa, utveckling eller säkerhet. Medför ofta stor påverkan på individer/grupper.",
           },
            {
                question: "fysiska",
                category: "Effort",
                instructions: "- Tungt arbete\n- Repetitiva arbetsmoment\n- Obekväma arbetsställningar\n- Risk för skada",
                focus: "- Hur påfrestande det fysiska arbetet är\n- Kombinationseffekter, till exempel om tungt arbete sker i kombination med obekväm arbetsställning\n- Långt repetetivt arbete kan vara lika fysiskt ansträngande som tunga moment under kortare perioder\n- Tillgång till hjälpmedel\n- Arbetstakt, hur snabbt det fysiska arbetet måste utföras",
                level1: "Inga särskilda krav på fysisk ansträngning. Risk för skada är mycket liten.",
                level2: "I perioder kan det krävas fysisk ansträning. Risk för skada är liten.",
                level3: "Fysisk ansträngning sker dagligen. Långt repetetivt arbete eller tyngre moment under kortare perioder. Viss risk för skada.",
                level4: "Fysisk ansträngning är en stor del av arbetet. Obekväma arbetställningar förekommer. Stor risk för skada.",
            },
            {
                question: "psykiska",
                category: "Effort",
                instructions: "- Känslomässig ansträngning \n- Enformighet \n- Tillgänglighet \n- Stress",
                focus: "- Hur påfrestande den psykiska ansträngningen är \n- Sker ofta möte med arga, sjuka eller svårhanterliga människor? \n- Krav på tillgänglighet, tex utanför ordinarie arbetstid eller att andra uppgifter ofta blir lidande \n- Hårt styrt och enformigt arbete som kräver noggran koncentration kan vara mycket påfrestande \n- Hur stor andel arbetsbelastning och stress",
                level1: "Låga krav på psykisk ansträngning. Psykiskt påfrestande situationer inträffar sällan.",
                level2: "Psykiskt påfrestande situationer inträffar ibland. Hög arbetsbelastning och tidspress förekommer i perioder. ",
                level3: "Psykisk och känslomässig ansträngning förekommer ofta.",
                level4: "Mycket höga krav på psykisk och känslomässig ansträngning över tid. Höga krav på tillgänglighet, koncentration, tidspress och påfrestande relationer.",
            }
        ]

        let editedFactors = this.props.editedFactors
        let originalFactors = this.props.originalFactors
        let whatLevelDesc = this.props.whatLevelDesc
        let whatEvaluation = this.props.whatEvaluation
        let vikt = this.props.vikt
        let valuationName = this.props.valuationName

        // First add categories to originalFactors
        if(originalFactors.length > 0) {
            for (let i = 0; i < originalFactors.length; i++) {
                if(originalFactors[i].question === "utbildning") {originalFactors[i].category = "Knowledge"}
                if(originalFactors[i].question === "problem") {originalFactors[i].category = "Knowledge"}
                if(originalFactors[i].question === "sociala") {originalFactors[i].category = "Knowledge"}
                if(originalFactors[i].question === "personal") {originalFactors[i].category = "Responsibility"}
                if(originalFactors[i].question === "verksamhet") {originalFactors[i].category = "Responsibility"}
                if(originalFactors[i].question === "manniskor") {originalFactors[i].category = "Responsibility"}
                if(originalFactors[i].question === "fysiska") {originalFactors[i].category = "Effort"}
                if(originalFactors[i].question === "psykiska") {originalFactors[i].category = "Effort"}
            }
        }

        if(this.props.whatEvaluation === "extLevel" || this.props.whatEvaluation === "extPoint" ) {
            return   <div className="aPage" style={{marginBottom: -90}}>
            <h2 className="h2PaddingTop">{language === "sv" ? "2. Metod" : lang[language].tocMethod}</h2>
            <h3 className="h2PaddingTop">{language === "sv" ? "2.1 Verktyg" : lang[language].tocSys}</h3>
            <p className="P-document">{language === "sv" ? " I arbetet med lönekartläggningen har verktyget Lönelys använts. Verktyget sammanställer resultatet i diagram och tabeller som möjliggör analys. Detta visar löneförhållanden inom grupper av anställda som utför lika arbete, mellan likvärdiga arbeten samt hierarkiska skillnader." : lang[language].metOne}
            </p>
            <h3 className="h2PaddingTop">{language === "sv" ? "2.2 Viktning & bedömning" : lang[language].tocWeight}</h3>
            <p className="P-document">{language === "sv" ? `För att kunna jämföra likvärdiga arbeten har en arbetsvärdering gjorts. Syftet är att göra en så korrekt och rättvis bedömning av arbetenas krav som möjligt. För arbetsvärderingen har systemet ${this.props.valuationName} använts.` : lang[language].metTwo + this.props.valuationName + "."}
            </p>
        </div>
        } else {
        let showLevels = ""
        let factors = ""
        if (whatLevelDesc === null) { showLevels = "noLevels"; factors = factorDescriptions }
        if (whatLevelDesc === "oldStandard") {
            if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                //Check if there is 8 factors saved in originalFactors. If not, show no levels.
                // If there is 8 factors, check if each one has a level description for level1. If not, show no levels
                if (originalFactors.length === 8) {
                    for (let item of originalFactors) {
                        if (item.level1 === null || item.level1 === "") {
                            showLevels = "noLevels"
                            break;
                        } else {
                            showLevels = "originalLevels" //<-- maybe change what this should be
                        }
                    }
                } else { showLevels = "noLevels" }
                //Now merge descriptions. focus and levels for the factor to be rendered
                factors = factorDescriptions.map(factorDesc => {
                    const matchingFactor = originalFactors.find(factor => factor.question === factorDesc.question);
                    if (matchingFactor) {
                        return {
                            ...factorDesc,
                            instructions: matchingFactor.instructions,
                            focus: matchingFactor.focus,
                            category: matchingFactor.category,
                            level1: matchingFactor.level1,
                            level2: matchingFactor.level2,
                            level3: matchingFactor.level3,
                            level4: matchingFactor.level4,
                        };
                    }
                    return factorDesc;
                });
            }
            if (whatEvaluation === "edited") {
                //Here we always have all factors saved. Check if each one has a level description for level1. If not, show no levels
                for (let item of editedFactors) {
                    if (item.level1 === null || item.level1 === "") {
                        showLevels = "noLevels"
                        break;
                    } else {
                        showLevels = "editedLevels" //<-- maybe change what this should be
                    }
                }
                factors = editedFactors
            }
        }
        //If whatLevelDesc = "first", we know that this means that it has the original levels by default
        if (whatLevelDesc === "first") { showLevels = "originalLevels"; factors = factorDescriptions }
        //If whatLevelDesc = "standard", the levels might have changed or been removed. 
        if (whatLevelDesc === "standard") { 
            if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                //Check if there is 0 saved factors for original, if so we just use defaul values
                if (originalFactors.length === 0) { showLevels = "originalLevels"; factors = factorDescriptions }
                //Check if there is 8 factors saved in originalFactors. If there is, check each one if they have null or "" for level1. If so then show no levels.
                if (originalFactors.length === 8) {
                    for (let item of originalFactors) {
                        if (item.level1 === null || item.level1 === "") {
                            showLevels = "noLevels"
                            break;
                        } else {
                            showLevels = "originalLevels" //<-- maybe change what this should be
                        }
                    }
                    //Now merge descriptions. focus and levels for the factor to be rendered
                    factors = originalFactors
                }
                //If there is not 8 factors, loop and check if the once who are there have null or "". If so then show no levels. If not, then use those level descriptions and then use default for the rest.
                if (originalFactors.length !== 8 && originalFactors.length !== 0) {
                    for (let item of originalFactors) {
                        if (item.level1 === null || item.level1 === "") {
                            showLevels = "noLevels"
                            break;
                        } else {
                            showLevels = "originalSome"
                        }
                    }
                    //Now merge descriptions. focus and levels for the factor to be rendered
                    factors = factorDescriptions.map(factorDesc => {
                        const matchingFactor = originalFactors.find(factor => factor.question === factorDesc.question);
                        if (matchingFactor) {
                            return {
                                ...factorDesc,
                                instructions: matchingFactor.instructions,
                                focus: matchingFactor.focus,
                                category: matchingFactor.category,
                                level1: matchingFactor.level1,
                                level2: matchingFactor.level2,
                                level3: matchingFactor.level3,
                                level4: matchingFactor.level4,
                            };
                        }
                        return factorDesc;
                    });
                }
            }
            if (whatEvaluation === "edited") {
                //Here we always have all factors saved. Check if each one has a level description for level1. If not, show no levels
                for (let item of editedFactors) {
                    if (item.level1 === null || item.level1 === "") {
                        showLevels = "noLevels"
                        break;
                    } else {
                        showLevels = "editedLevels" //<-- maybe change what this should be
                    }
                }
                factors = editedFactors
            }
        }
        // Above is setting descriptions, factors and levels. Now categorise by category 
        let knowledge = []
        let responsibility = []
        let effort = []
        for (let i = 0; i < factors.length; i++) {
            if (factors[i].category === "Knowledge") { knowledge.push(factors[i]) }
            if (factors[i].category === "Responsibility") { responsibility.push(factors[i]) }
            if (factors[i].category === "Effort") { effort.push(factors[i]) }
        }

        //Into text
        let introText = <>
        <div className="aPage">
             <h2 className="h2PaddingTop">{language === "sv" ? "2. Metod" : lang[language].tocMethod}</h2>
                        <h3 className="h2PaddingTop">{language === "sv" ? "2.1 Verktyg" : lang[language].tocSys}</h3>
                        <p className="P-document">{language === "sv" ? <>I arbetet med lönekartläggningen har verktyget Lönelys använts.
                            Detta hanterar kartläggningen av arbetskrav samt ger möjligheten att vikta dessa.
                            Verktyget sammanställer resultatet i diagram och tabeller som möjliggör analys. Detta visar
                            löneförhållanden inom grupper av anställda som utför lika arbete, mellan likvärdiga arbeten samt hierarkiska skillnader.</> : lang[language].metOne}
                          </p>

                        <h3 className="h2PaddingTop">{language === "sv" ? "2.2 Viktning & bedömning" : lang[language].tocWeight}</h3>
                        <p className="P-document">{language === "sv" ? <>För att kunna jämföra likvärdiga arbeten har en arbetsvärdering gjorts.
                            Syftet är att göra en så korrekt och rättvis bedömning av arbetenas krav som möjligt.
                            Diskrimineringslagen anger kunskap och färdigheter, ansvar, ansträngning och arbetsförhållanden
                            som exempel på kriterier i bedömningen av ett arbete.</> : lang[language].metThree} </p>

                        <p className="P-document">{language === "sv" ? "Vi har valt att använda nedanstående viktning för dessa kriterier." : lang[language].metFour}</p>

                        <table className="tableLeftText" style={{ width: 280 }}>
                            <tr>
                                <th style={{ textAlign: "left", width: 140, paddingTop: 15, paddingBottom: 15 }}>{language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp}</th>
                                <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                                    {this.props.whatEvaluation === "edited" ? editedFactors.reduce((sum, item) => item.category === "Knowledge" ? sum + item.weight : sum, 0) : this.props.vikt.education + this.props.vikt.problemsolving + this.props.vikt.socialSkills} %
                                    </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: "left", paddingTop: 15, paddingBottom: 15 }}>{language === "sv" ? "Ansvar" : lang[language].setResp}</th>
                                <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                                    {this.props.whatEvaluation === "edited" ? editedFactors.reduce((sum, item) => item.category === "Responsibility" ? sum + item.weight : sum, 0) : this.props.vikt.personnel + this.props.vikt.operation + this.props.vikt.people} %
                                    </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: "left", paddingTop: 15, paddingBottom: 15 }}>{language === "sv" ? "Ansträngning & Arbetsförhållande" : lang[language].setEffort}</th>
                                <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                                    {this.props.whatEvaluation === "edited" ? editedFactors.reduce((sum, item) => item.category === "Effort" ? sum + item.weight : sum, 0) : this.props.vikt.physical + this.props.vikt.psychological} %
                                    </td>
                            </tr>
                        </table>

                        <p className="P-document" style={{ paddingTop: 40 }}>{language === "sv" ? <>Viktningen ovan är den totala viktningen inom varje område. Varje område är i sin tur uppdelad i
                            faktorer som också är viktade var för sig, vilket utför den totala. Detta för att gå djupare och kunna göra en så bred och korrekt
                            bedömning av arbetets krav som möjligt. </> : lang[language].metFive} {this.props.whatEvaluation === "edited" ? language === "sv" ? "Nedan beskrivs de faktorer och den viktning som har används för arbetsvärderingen." : lang[language].metSix : language === "sv" ? "Nedanstående kriterier och viktning användes och har bedömts med en skala på 1 - 4, där 1 = låga krav, 2 = medelhöga krav, 3 = höga krav och 4 = mycket höga krav. Arbetsvärderingen har enbart fokuserat på arbetets krav." : lang[language].metSeven}
                        </p>
                        </div>
                         <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p>{/* <-- Page Break */}
                         </>

        let rows = []
        {/* If should not show levels */ }
        if (showLevels === "noLevels") {
            let createTables = (category) => {
                let whatCategory = category[0].category === "Knowledge" ? language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp : category[0].category === "Responsibility" ? language === "sv" ? "Ansvar" : lang[language].setResp : language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort
                rows.push(
                    <>
                        <div className="aPage">
                            <table className="tableLeftText" style={{ width: "100%", maxWidth: "none" }}>
                                <tbody>
                                    <tr>
                                        <span style={{ width: 200, display: "block", fontWeight: "bold", fontSize: 16, paddingLeft: 10, paddingTop: 10 }}>{whatCategory}</span>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: "left", verticalAlign: "top", paddingTop: 15, }}>{language === "sv" ? "Område" : lang[language].factor}</th>
                                        <th style={{ textAlign: "left", verticalAlign: "top", paddingTop: 15, }}>{language === "sv" ? "Viktning" : lang[language].weightning}</th>
                                        <th style={{ textAlign: "left", verticalAlign: "top", paddingTop: 15, }}>{language === "sv" ? "Bedömning" : lang[language].assessment}</th>
                                    </tr>
                                    {category.map(item => {
                                        let itemQuestion = item.question
                                        let weight = ""
                                        if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                                            if (item.question === "utbildning") { itemQuestion = "Utbildning & Erfarenhet"; weight = vikt.education }
                                            if (item.question === "problem") { itemQuestion = "Problemlösning"; weight = vikt.problemsolving }
                                            if (item.question === "sociala") { itemQuestion = "Sociala färdigheter"; weight = vikt.socialSkills }
                                            if (item.question === "personal") { itemQuestion = "Personal"; weight = vikt.personnel }
                                            if (item.question === "verksamhet") { itemQuestion = "Verksamhet"; weight = vikt.operation }
                                            if (item.question === "manniskor") { itemQuestion = "Människor"; weight = vikt.people }
                                            if (item.question === "fysiska") { itemQuestion = "Fysiska"; weight = vikt.physical }
                                            if (item.question === "psykiska") { itemQuestion = "Psykiska"; weight = vikt.psychological }
                                        }
                                        if (whatEvaluation === "edited") { weight = item.weight }
                                        return <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                            <td style={{ textAlign: "left", verticalAlign: "top", paddingBottom: 15 }}>{itemQuestion}</td>
                                            <td style={{ textAlign: "left", verticalAlign: "top", paddingBottom: 15 }}>{weight} %</td>
                                            <td style={{ textAlign: "left", verticalAlign: "top", paddingBottom: 15, minWidth: 331 }}>
                                                <span style={{ textDecoration: "underline" }}>{language === "sv" ? "Bedömning av" : lang[language].assessmentOf}:</span>
                                                <div style={{ whiteSpace: 'pre-line', fontSize: 14, fontFamily: 'inherit' }}>{item.instructions}</div>
                                                <span style={{ textDecoration: "underline", paddingTop: 15, display: "block" }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].assessmentOfCons}:</span>
                                                <div style={{ whiteSpace: 'pre-line', fontSize: 14, fontFamily: 'inherit' }}>{item.focus}</div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {category[0].category !== "Effort" && <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p>}{/* <-- Page Break */}
                    </>
                )
            }
            rows.push(introText)
            createTables(knowledge)
            createTables(responsibility)
            createTables(effort)
        }
        {/* If should show levels */ }
        if (showLevels !== "noLevels" && showLevels !== "") {
            let createTables = (category) => {
                let whatCategory = category[0].category === "Knowledge" ? language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp : category[0].category === "Responsibility" ?  language === "sv" ? "Ansvar" : lang[language].setResp : language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort
                rows.push(
                    <>
                        <div className="aPage">
                            
                            <table className="tableLeftText" style={{ width: "100%", maxWidth: "none" }}>
                                <tbody>
                                    <tr>
                                        <span style={{ width: 200, display: "block", fontWeight: "bold", fontSize: 16, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>{whatCategory}</span>
                                    </tr>
                                    {category.map(item => {
                                        let itemQuestion = item.question
                                        let weight = ""
                                        if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                                            if (item.question === "utbildning") { itemQuestion = "Utbildning & Erfarenhet"; weight = vikt.education }
                                            if (item.question === "problem") { itemQuestion = "Problemlösning"; weight = vikt.problemsolving }
                                            if (item.question === "sociala") { itemQuestion = "Sociala färdigheter"; weight = vikt.socialSkills }
                                            if (item.question === "personal") { itemQuestion = "Personal"; weight = vikt.personnel }
                                            if (item.question === "verksamhet") { itemQuestion = "Verksamhet"; weight = vikt.operation }
                                            if (item.question === "manniskor") { itemQuestion = "Människor"; weight = vikt.people }
                                            if (item.question === "fysiska") { itemQuestion = "Fysiska"; weight = vikt.physical }
                                            if (item.question === "psykiska") { itemQuestion = "Psykiska"; weight = vikt.psychological }
                                        }
                                        if (whatEvaluation === "edited") { weight = item.weight }
                                        return <>
                                        <tr style={{background: "#f3f3f3"}}>
                                            <th style={{ width: 300, textAlign: "left", verticalAlign: "top", paddingTop: 10, paddingBottom: 8, borderRight: "1px solid #e6e6e6", borderBottom: "1px solid #e6e6e6", borderTop: "1px solid #e6e6e6"}}>{itemQuestion}<span style={{fontWeight: "normal"}}>{" | " + (language === "sv" ? "Viktning:" : lang[language].weight)  + " " + weight + " %"}</span></th>
                                            <th style={{ width: 359, textAlign: "left", verticalAlign: "top", paddingTop: 10, borderBottom: "1px solid #e6e6e6", borderTop: "1px solid #e6e6e6"}}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</th>
                                        </tr>
                                        <tr>
                                        <td style={{ fontSize: 13, textAlign: "left", verticalAlign: "top", paddingBottom: 15, borderRight: "1px solid #e6e6e6" }}>
                                        <span style={{ textDecoration: "underline" }}>{language === "sv" ? "Bedömning av" : lang[language].assessmentOf}:</span>
                                                <div style={{ whiteSpace: 'pre-line', fontFamily: 'inherit' }}>{item.instructions}</div>
                                                <span style={{ textDecoration: "underline", paddingTop: 15, display: "block" }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].assessmentOfCons}:</span>
                                                <div style={{ whiteSpace: 'pre-line', fontFamily: 'inherit' }}>{item.focus}</div>
                                            </td>
                                            {item.levels === undefined ?
                                            <td style={{textAlign: "left", verticalAlign: "top"}}>
                                                <div> <span style={{display: "inline-block", verticalAlign: "top" }}>1. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level1}</span></div>
                                                <div style={{marginTop: 10}}><span style={{display: "inline-block", verticalAlign: "top" }}>2. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level2}</span></div>
                                                <div style={{marginTop: 10}}><span style={{display: "inline-block", verticalAlign: "top" }}>3. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level3}</span></div>
                                                <div style={{marginTop: 10}}><span style={{display: "inline-block", verticalAlign: "top" }}>4. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level4}</span></div>
                                            </td> : 
                                                <td style={{fontSize: 13, textAlign: "left", verticalAlign: "top"}}>
                                                 <div>{item.level1 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>1. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level1}</span></> : "1. "}</div>
                                                 <div style={{marginTop: 10}}>{item.level2 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>2. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level2}</span></> : "2. "}</div>
                                                {item.levels > 2 && <div style={{marginTop: 10}}>{item.level3 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>3. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level3}</span></> : "3. "}</div>}
                                                {item.levels > 3 && <div style={{marginTop: 10}}>{item.level4 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>4. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level4}</span></> : "4. "}</div>}
                                                {item.levels > 4 && <div style={{marginTop: 10}}>{item.level5 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>5. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level5}</span></> : "5. "}</div>}
                                                {item.levels > 5 && <div style={{marginTop: 10}}>{item.level6 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>6. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level6}</span></> : "6. "}</div>}
                                                {item.levels > 6 && <div style={{marginTop: 10}}>{item.level7 !== null ? <><span style={{display: "inline-block", verticalAlign: "top" }}>7. </span><span style={{display: "inline-block",width: "95%",verticalAlign: "top",marginLeft: 5}}>{item.level7}</span></> : "7. "}</div>}
                                            </td>}
                                        </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {whatCategory !== (language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort) && <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p>}
                    </>
                )

            }
            rows.push(introText)
            createTables(knowledge)
            createTables(responsibility)
            createTables(effort)
        }
        return rows
    }

    }
}