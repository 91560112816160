import React from "react";
import lang from '../../../Language/language.js';

export class Praxis extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <div className="aPage">
                <h2 className="h2PaddingTop">{language === "sv" ? "4. Kartläggning & analys av lönebestämmelser & praxis" : lang[language].tocAnalyze}</h2>
                <p className="P-document">
                    {this.props.praxisText}
                </p>
            </div>
        )
    }
}