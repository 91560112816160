import React from "react";
import lang from '../../../Language/language.js';

export class Analys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            equal: [],
            equalent: [],
            hierarchy: [],
            general: [],
            oldEqual: [],
            oldEqualent: [],
            oldHierarchy: [],
            oldGeneral: [],
            allComments: [],
            allOldComments: []
        }
    }
    componentDidMount() {
        let comments = this.props.comments
        let oldComments = this.props.oldComments
        let allComments = comments.filter(com => (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let equal = comments.filter(com => (com.analysis === "lika" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let equalent = comments.filter(com => (com.analysis === "likvärdiga" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let hierarchy = comments.filter(com => (com.analysis === "hierarkisk" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let general = comments.filter(com => (com.analysis === "general" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let allOldComments = oldComments.filter(com => (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldEqual = oldComments.filter(com => (com.analysis === "lika" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldEqualent = oldComments.filter(com => (com.analysis === "likvärdiga" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldHierarchy = oldComments.filter(com => (com.analysis === "hierarkisk" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldGeneral = oldComments.filter(com => (com.analysis === "general" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))

        this.setState({
            equal: equal,
            equalent: equalent,
            hierarchy: hierarchy,
            general: general,
            oldEqual: oldEqual,
            oldEqualent: oldEqualent,
            oldHierarchy: oldHierarchy,
            oldGeneral: oldGeneral,
            allComments: allComments,
            allOldComments: allOldComments
        })
    }

    componentDidUpdate(prevprops) {
        if(prevprops.comments !== this.props.comments || prevprops.oldComments !== this.props.oldComments) {
        let comments = this.props.comments
        let oldComments = this.props.oldComments
        let allComments = comments.filter(com => (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let equal = comments.filter(com => (com.analysis === "lika" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let equalent = comments.filter(com => (com.analysis === "likvärdiga" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let hierarchy = comments.filter(com => (com.analysis === "hierarkisk" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let general = comments.filter(com => (com.analysis === "general" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let allOldComments = oldComments.filter(com => (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldEqual = oldComments.filter(com => (com.analysis === "lika" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldEqualent = oldComments.filter(com => (com.analysis === "likvärdiga" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldHierarchy = oldComments.filter(com => (com.analysis === "hierarkisk" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))
        let oldGeneral = oldComments.filter(com => (com.analysis === "general" && com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== ""))

        this.setState({
            equal: equal,
            equalent: equalent,
            hierarchy: hierarchy,
            general: general,
            oldEqual: oldEqual,
            oldEqualent: oldEqualent,
            oldHierarchy: oldHierarchy,
            oldGeneral: oldGeneral,
            allComments: allComments,
            allOldComments: allOldComments
        })
        }
    }
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        const data = (group) => {
            return (
                group.map(elem =>
                    <tr class="trStyleLine">
                        <td class="comPad">{elem.notable}</td>
                        <td class="comPad">{elem.reason}</td>
                        <td class="comPad">{elem.todo}</td>
                        <td class="comPad">{elem.deadline}</td>
                        <td class="comPad">{elem.cost}</td>
                        {/*<td class="comPad">{elem.responsible}</td>*/}
                    </tr>
                )
            )
        }
        const dataEqual = (group) => {
            return (
                group.map(elem =>
                    <tr class="trStyleLine">
                        <td class="comPad">
                            <span style={{ display: "block", color: "grey", fontStyle: "italic", marginBottom: 4 }}>{elem.equalGroup}</span>
                            {elem.notable}</td>
                        <td class="comPad">{elem.reason}</td>
                        <td class="comPad">{elem.todo}</td>
                        <td class="comPad">{elem.deadline}</td>
                        <td class="comPad">{elem.cost}</td>
                        {/*<td class="comPad">{elem.responsible}</td>*/}
                    </tr>
                )
            )
        }
        const oldData = (group) => {
            return (
                group.map(elem =>
                    <tr class="trStyleLine">
                        <td class="comPad">{elem.notable}</td>
                        <td class="comPad">{elem.reason}</td>
                        <td class="comPad">{elem.todo}</td>
                        <td class="comPad">{elem.deadline}</td>
                        <td class="comPad">{elem.cost}</td>
                        <td class="comPad">{elem.finished === null || elem.finished === undefined ? "Nej" : elem.finished}</td>
                        <td class="comPad">{elem.comment}</td>
                        {/*<td class="comPad">{elem.responsible}</td>*/}
                    </tr>
                )
            )
        }
        const oldDataEqual = (group) => {
            return (
                group.map(elem =>
                    <tr class="trStyleLine">
                        <td class="comPad">
                            <span style={{ display: "block", color: "grey", fontStyle: "italic", marginBottom: 4 }}>{elem.equalGroup}</span>
                            {elem.notable}</td>
                        <td class="comPad">{elem.reason}</td>
                        <td class="comPad">{elem.todo}</td>
                        <td class="comPad">{elem.deadline}</td>
                        <td class="comPad">{elem.cost}</td>
                        <td class="comPad">{elem.finished === null || elem.finished === undefined ? "Nej" : elem.finished}</td>
                        <td class="comPad">{elem.comment}</td>
                        {/*<td class="comPad">{elem.responsible}</td>*/}
                    </tr>
                )
            )
        }

        let equal = ""
        this.state.equal === undefined || this.state.equal.length == 0 ? equal = "" : equal = dataEqual(this.state.equal)

        let equalent = ""
        this.state.equalent === undefined || this.state.equalent.length == 0 ? equalent = "" : equalent = data(this.state.equalent)

        let hierarchy = ""
        this.state.hierarchy === undefined || this.state.hierarchy.length == 0 ? hierarchy = "" : hierarchy = data(this.state.hierarchy)

        let general = ""
        this.state.general === undefined || this.state.general.length == 0 ? general = "" : general = data(this.state.general)

        let oldEqual = ""
        this.state.oldEqual === undefined || this.state.oldEqual.length == 0 ? oldEqual = "" : oldEqual = oldDataEqual(this.state.oldEqual)

        let oldEqualent = ""
        this.state.oldEqualent === undefined || this.state.oldEqualent.length == 0 ? oldEqualent = "" : oldEqualent = oldData(this.state.oldEqualent)

        let oldHierarchy = ""
        this.state.oldHierarchy === undefined || this.state.oldHierarchy.length == 0 ? oldHierarchy = "" : oldHierarchy = oldData(this.state.oldHierarchy)

        let oldGeneral = ""
        this.state.oldGeneral === undefined || this.state.oldGeneral.length == 0 ? oldGeneral = "" : oldGeneral = oldData(this.state.oldGeneral)

        return (
            <>
                <div className="aPage">
                <h2 className="h2PaddingTop">{language === "sv" ? "6. Handlingsplan" : lang[language].tocAction}</h2>
                    {this.state.allComments.length > 0 ? <p className="P-document">{language === "sv" ? "Nedan handlingsplan redovisar de åtgärder som behöver genomföras för att åtgärda löneskillnader som har direkt eller indirekt samband med kön baserat på resultaten och analyserna i denna lönekartläggning. Åtgärderna redovisas tillsammans med deadline och kostnadsberäkning." : lang[language].actOne}</p>
                        : <p className="P-document">{language === "sv" ? "En kartläggning och analys har genomförts. I kartläggningen kunde inga osakliga löneskillnader eller villkor hittas som haft direkt eller indirekt samband med kön. Detta gäller både på individuell samt gruppnivå. Inga åtgärder har därför bedömts nödvändiga varav ingen handlingsplan tagits fram för detta år." : lang[language].actTwo}</p>
                    }
                    {/* Loop equal actions */}
                    {this.state.equal.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Lika arbeten" : lang[language].equalWorks}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                {/*<th className="comPad">Ansvarig</th>*/}
                            </tr>
                            {equal}
                        </table>
                    </> : <></>}

                    {/* Loop equalent actions*/}
                    {this.state.equalent.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                            </tr>
                            {equalent}
                        </table></> : <></>}
                    {/* Loop  hierarchy actions */}
                    {this.state.hierarchy.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                            </tr>
                            {hierarchy}
                        </table></> : <></>}
                         {/* Loop general actions */}
                    {this.state.general.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Övriga åtgärder" : lang[language].generalActions}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                            </tr>
                            {general}
                        </table></> : <></>}
                </div>

                <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break. Below is utvardering*/}

                <div className="aPage">
                    <h2 className="h2PaddingTop">{language === "sv" ? "7. Utvärdering av föregående års åtgärder" : lang[language].tocEvaluation}</h2>
                    {this.state.allOldComments.length > 0 ? <p className="P-document">{language === "sv" ? <>Nedan redovisas föregående års åtgärder tillsammans med
                        en utvärdering. Syftet är att säkerställa att diskrimineringslagen följs, samt att
                        säkerställa att åtgärderna utförs.</> : lang[language].actThree}</p> :
                        <p className="P-document">{language === "sv" ? "Inga planerade åtgärder från föregående år." : lang[language].noActionsPastYear}</p>
                    }
                    {/* Loop old equal actions*/}
                    {this.state.oldEqual.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Lika arbeten" : lang[language].equalWorks}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="comPad">{language === "sv" ? "Utfört" : lang[language].completed}?</th>
                                <th className="comPad">{language === "sv" ? "Kommentar" : lang[language].comment}</th>
                            </tr>
                            {oldEqual}
                        </table></> : <></>}
                    {/* Loop old equalent actions*/}
                    {this.state.oldEqualent.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="comPad">{language === "sv" ? "Utfört" : lang[language].completed}?</th>
                                <th className="comPad">{language === "sv" ? "Kommentar" : lang[language].comment}</th>
                            </tr>
                            {oldEqualent}
                        </table></> : <></>}
                    {/* Loop old hierachy actions*/}
                    {this.state.oldHierarchy.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="comPad">{language === "sv" ? "Utfört" : lang[language].completed}?</th>
                                <th className="comPad">{language === "sv" ? "Kommentar" : lang[language].comment}</th>
                            </tr>
                            {oldHierarchy}
                        </table></> : <></>}
                         {/* Loop old general actions */}
                    {this.state.oldGeneral.length > 0 ? <><h3 className="h2PaddingTop">{language === "sv" ? "Övriga åtgärder" : lang[language].generalActions}</h3>
                        <table className="commentsTable" style={{ fontSize: 13, marginLeft: 2 }}>
                            <tr className="trStyleLine">
                                <th className="comPad">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="comPad">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="comPad">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="comPad">Deadline</th>
                                <th className="comPad">{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="comPad">{language === "sv" ? "Utfört" : lang[language].completed}?</th>
                                <th className="comPad">{language === "sv" ? "Kommentar" : lang[language].comment}</th>
                            </tr>
                            {oldGeneral}
                        </table></> : <></>}
                </div>
            </>
        )
    }
}