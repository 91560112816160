import React from "react";
import ReactToPrint from "react-to-print";
import logoDefault from '../../../assets/images/logo-default.png';
import { Inledning } from './Inledning/Inledning.js';
import { Innehall } from './Innehall/Innehall.js';
import { Metod } from './Metod/Metod.js';
import { Arbetsgang } from './Arbetsgang/Arbetsgang.js';
import { Samverkan } from './Samverkan/Samverkan.js';
import { Praxis } from './Praxis/Praxis.js';
import { ResultatLika } from './ResultatLika/ResultatLika.js';
import { ResultatLikvardiga } from './ResultatLikvardiga/ResultatLikvardiga.js';
import { ResultatLikvardigaExt } from './ResultatLikvardiga/ResultatLikvardigaExt.js';
import { Analys } from './Analys/Analys.js';
//import { Utvardering } from './Utvardering/Utvardering.js';
import { Avslutning } from './Avslutning/Avslutning.js';
import lang from '../../Language/language.js';


export class ComponentToPrint extends React.Component {
  render() {
    let language = localStorage.getItem('language') ?? 'sv';
    let ResLika
    let ResLikvardiga
    let comments
    //makes sure that props has been set
    if (this.props.persData === undefined || this.props.persData.length === 0) {
      ResLika =  null
      ResLikvardiga = null
    } else {
      ResLika =  < ResultatLika persData={this.props.persData} chartImages={this.props.chartImages} hideSalaries={this.props.hideSalaries} comments={this.props.comments} chartPercent={this.props.chartPercent} chartImagesAge={this.props.chartImagesAge} chartAge={this.props.chartAge} chartExp={this.props.chartExp} showAgeChart={this.props.showAgeChart} addOnDetails={this.props.addOnDetails} addOnChart={this.props.addOnChart} chartImagesExtraExp={this.props.chartImagesExtraExp} chartImagesExtraAge={this.props.chartImagesExtraAge} />
      ResLikvardiga = this.props.whatEvaluation !== "extLevel" && this.props.whatEvaluation !== "extPoint" && this.props.whatEvaluation !== "edited" ? < ResultatLikvardiga persData={this.props.persData} nrOfEqualentGroups={this.props.nrOfEqualentGroups} hideSalaries={this.props.hideSalaries} comments={this.props.comments}/> : < ResultatLikvardigaExt persData={this.props.persData} nrOfEqualentGroups={this.props.nrOfEqualentGroups} hideSalaries={this.props.hideSalaries} comments={this.props.comments} whatEvaluation={this.props.whatEvaluation}/> 
    }

      let comms = this.props.comments === undefined ? [] : this.props.comments
      let oldcomms = this.props.comments === undefined ? [] : this.props.oldComments
      comments =  < Analys comments={comms} oldComments={oldcomms}/>

    return (
      <div style={{ width: 760, margin: "auto" }}>
        {/*** Logo */}
        <img
          style={{ maxWidth: this.props.logoWidth, marginTop: 30, float: "left" }}
          src={this.props.logo === "" && this.props.temporaryLogo === "" ? logoDefault : this.props.temporaryLogo !== "" ? URL.createObjectURL(this.props.temporaryLogo) : this.props.logo}
        />

        <span style={{ textAlign: "center", fontWeight: 900, fontSize: 44, textDecoration: "underline", display: "block", paddingTop: 400, fontFamily: 'Inter'}}>{language === "sv" ? "Lönekartläggning" : lang[language].payE}</span><p>
          <span style={{ textAlign: "center", fontSize: 38, marginTop: 5, marginTop: -5, display: "block", fontFamily: "Inter" }}>{this.props.theYear}</span>
        </p>

        {/* Innehall */}
        <span style={{display: "block",color: "#ababab",fontWeight: 600,fontFamily: 'Inter',marginTop: 535,textAlign: "center"}}>{this.props.legalComp} </span>
        <p style={{ pageBreakBefore: "always"}}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Innehall avslutning={this.props.fritextTitel} />

        {/* Inledning */}
        <p style={{ pageBreakBefore: "always", display: "block" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Inledning medverkandeText={this.props.medverkandeText} totStats={this.props.totStats} hideSalaries={this.props.hideSalaries}/>

        {/* Metod */}
         <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Metod company="Vi" vikt={this.props.vikt} valuationName={this.props.valuationName} whatEvaluation={this.props.whatEvaluation} editedFactors={this.props.editedFactors} originalFactors={this.props.originalFactors} whatLevelDesc={this.props.whatLevelDesc}
        /> 

        {/* Arbetsgang */}
        {(this.props.whatEvaluation !== "extLevel" && this.props.whatEvaluation !== "extPoint") && <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p>} {/* <-- Page Break */}
        < Arbetsgang />

        {/* Samverkan */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Samverkan samverkan={this.props.samverkanText} />

        {/* Lonebestammelser & Praxis */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Praxis praxisText={this.props.praxisText} />

        {/* Resultat Lika */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        {ResLika}

        {/* Resultat Likvardiga */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        {ResLikvardiga}

        {/* Analys & Handlingsplan */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        {comments}

        {/* Utvardering */}
        {/*
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> 
        < Utvardering />*/}

        {/* Avslutning */}
        <p style={{ pageBreakBefore: "always" }}><span className='noprint'></span></p> {/* <-- Page Break */}
        < Avslutning fritextTitel={this.props.fritextTitel} fritext={this.props.fritext} />
      </div>
    );
  }
}