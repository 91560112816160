import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    ImageRun,
    VerticalAlign
} from "docx";
import lang from '../../Language/language.js';
const percentile = require("percentile");
export const likaTabell = (persData, tableSpace, chartImages, comments, chartImagesAge) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //Divide persData by equal Groups
    let dividebyGroup = persData.reduce((result, value) => {
        result[value.groupNameArbVardering] = result[value.groupNameArbVardering] || [];
        result[value.groupNameArbVardering].push({ name: value.fullName, position: value.positionTitle, gender: value.gender, salary: value.totalSalary });
        return result;
    }, {});
    let groups = Object.entries(dividebyGroup)
    groups.sort((a, b) => a[0][0].localeCompare(b[0][0])) //Sort groups in alphabetic order
    let arr = [];
    for (let i = 0; i < groups.length; i++) {
        let egroup = groups[i][0]
        let totEmp = groups[i][1].length
        //let positions = groups[i][1].map(pos => pos.position).filter((value, index, self) => self.indexOf(value) === index) //another way of doing it, but probably slower
        let positions = [...new Set(groups[i][1].map(item => item.position))] //a faster way
        let women = [...new Set(groups[i][1].filter(item => item.gender === 'woman'))]
        let nrWomen = women.length
        let men = [...new Set(groups[i][1].filter(item => item.gender === 'man'))]
        let nrMen = men.length
        let avgSalary = (groups[i][1].reduce((a, { salary }) => a + salary, 0)) / totEmp
        let avgSalMen = (men.reduce((a, { salary }) => a + salary, 0)) / nrMen
        let avgSalWom = (women.reduce((a, { salary }) => a + salary, 0)) / nrWomen
        let diffWom = avgSalWom - avgSalMen
        //make sure 0 is displayed instead of "NaN"
        avgSalWom = avgSalWom ? avgSalWom : 0;
        avgSalMen = avgSalMen ? avgSalMen : 0;
        diffWom = diffWom ? diffWom : 0;

        let diffWomenPercent = ""
        if (avgSalWom && avgSalMen) { 
        //    diffWomenPercent = (Math.round((100 - ((avgSalMen / avgSalWom) * 100)) * 10) / 10) + "%" 
        diffWomenPercent = ((diffWom / avgSalMen) * 100).toFixed(1) + "%"
        }
        if (nrWomen < 1 || nrMen < 1) {diffWomenPercent = ""}
        //push comments
        let thisGroupComments = []
        for (let i = 0; i < comments.length; i++) {
            if (comments[i].equalGroup === egroup && comments[i].analysis === "lika") {
                thisGroupComments.push({
                    cost: comments[i].cost,
                    deadline: comments[i].deadline,
                    notable: comments[i].notable,
                    reason: comments[i].reason,
                    todo: comments[i].todo
                });
            }
        }
        //Spridning
        //Get lowest and highest salary, also calculate spridningskvot (highest/lowest)
        let getOnlySalariesMen = []
        for (let i = 0; i < men.length; i++) { getOnlySalariesMen.push(men[i].salary) }
        let lowestSalaryMen = Math.min(...getOnlySalariesMen)
        let highestSalaryMen = Math.max(...getOnlySalariesMen)
        let spridningskvotMen = Math.round((highestSalaryMen / lowestSalaryMen) * 10) / 10

        let getOnlySalariesWomen = []
        for (let i = 0; i < women.length; i++) { getOnlySalariesWomen.push(women[i].salary) }
        let lowestSalaryWomen = Math.min(...getOnlySalariesWomen)
        let highestSalaryWomen = Math.max(...getOnlySalariesWomen)
        let spridningskvotWomen = Math.round((highestSalaryWomen / lowestSalaryWomen) * 10) / 10
        //let spridningDiff = Math.round((spridningskvotMen - spridningskvotWomen) * 10) / 10

        //Calculate 10 and 90 percentiles if needed (if more than 20 employees)
        //let moreThan20 = ""
        let moreMen10 = "-"
        let moreMen90 = "-"
        //let moreMenKvot = "-"
        let moreWomen10 = "-"
        let moreWomen90 = "-"
        //let moreWomenKvot = "-"

        if (nrMen > 19 || nrWomen > 19) {
            //If men bigger but not women
            if (nrMen > 19 && nrWomen < 20) {
                let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                moreMen10 = percentile(10, menSalariesInOrder)
                moreMen90 = percentile(90, menSalariesInOrder)
                spridningskvotMen = Math.round((moreMen90 / moreMen10) * 10) / 10
                // moreThan20 = "men"
            }
            //If women bigger but not men
            if (nrWomen > 19 && nrMen < 20) {
                let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                moreWomen10 = percentile(10, womenSalariesInOrder)
                moreWomen90 = percentile(90, womenSalariesInOrder)
                spridningskvotWomen = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                //moreThan20 = "women"
            }
            //If both bigger
            if (nrWomen > 19 && nrMen > 19) {
                let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                moreMen10 = percentile(10, menSalariesInOrder)
                moreMen90 = percentile(90, menSalariesInOrder)
                spridningskvotMen = Math.round((moreMen90 / moreMen10) * 10) / 10
                let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                moreWomen10 = percentile(10, womenSalariesInOrder)
                moreWomen90 = percentile(90, womenSalariesInOrder)
                spridningskvotWomen = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                //moreThan20 = "both"
            }
        }
        //Make sure spridningskvot is not "NaN"
        spridningskvotMen = spridningskvotMen ? spridningskvotMen : "-";
        spridningskvotWomen = spridningskvotWomen ? spridningskvotWomen : "-";

        let group = {
            equalGroup: egroup,
            positions: positions,
            totEmp: totEmp,
            women: nrWomen,
            men: nrMen,
            avgSalary, avgSalary,
            avgSalWom: avgSalWom,
            avgSalMen: avgSalMen,
            diffWom: diffWom,
            diffWomenPercent: diffWomenPercent,
            chartImg: chartImages[i],
            comments: thisGroupComments,
            spridningskvotMen: spridningskvotMen,
            spridningskvotWomen: spridningskvotWomen,
            chartImgAge: chartImagesAge[i]
        }
        arr.push(group)
    }
    let data = arr


    //RENDER FULL TABLES

    //data === undefined || data.length == 0 ? likaData = "" :
    let likaData = []
    for (let i = 0; i < data.length; i++) {
        let rowsToTable = []
        let rowsToTableComments = []

        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph("")],
                    columnSpan: 5,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                })
            ],
        })
        )

        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: data[i].equalGroup,
                                color: "000000",
                                bold: true,
                                size: 23,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 5,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                })
            ],
        })
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${data[i].positions.map(pos => pos)}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 5,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
            ],
        })
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Anställda i grupp" : lang[language].equalNrOfWorkers,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Män" : lang[language].men,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Kvinnor" : lang[language].women,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Totalt" : lang[language].total,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph("")],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
            ],
        })
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].men).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].women).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].totEmp).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph("")],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                })
            ],
        })
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Lön (inkl tillägg)" : lang[language].salInclBenefit,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Män" : lang[language].men,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Kvinnor" : lang[language].women,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Totalt" : lang[language].total,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Lönediff. kvinnor" : lang[language].womenDiff,
                                color: "000000",
                                bold: true,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
            ],
        })
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Medellön" : lang[language].averageSal,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].avgSalMen).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].avgSalWom).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].avgSalary).toLocaleString()}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${parseInt(data[i].diffWom).toLocaleString()} `,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: data[i].diffWomenPercent === "" || data[i].diffWomenPercent === "0%" ? "" : `(${data[i].diffWomenPercent})`,
                                font: "Arial",
                                color: "#565656",
                                size: 16,
                                italics: true
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
            ],
        }),
        )
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Lönespridning" : lang[language].salarySpread,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${data[i].spridningskvotMen}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${data[i].spridningskvotWomen}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: ``,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 2,
                }),
            ],
        }),
        )
        //Shows comments 
        //  createCommentRows(el.comments),
        //createCommentRowsTest(el.comments),
        // createCommentRowsTest(el.comments),

        /*el.comments[5] !== undefined ? 
        createCommentRowsTest(el.comments) : null,*/
        // el.comments[4] !== undefined ? createCommentRowsTest(el.comments) : null,
        // data[i].comments.map(comment => createCommentRows(comment)),

        //Shows charts if setting is on:
        chartImages === "none" && chartImagesAge === "none" ?
        rowsToTable.push(new Paragraph({
            spacing: {
                before: 0,
                after: 0,
            }
        })) : 
        chartImages !== "none" && chartImagesAge === "none" ?
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new ImageRun({
                                data: Uint8Array.from(atob(data[i].chartImg), c =>
                                    c.charCodeAt(0)
                                ),
                                transformation: {
                                    width: 360,
                                    height: 220
                                }
                            })
                        ]
                    }),],
                    columnSpan: 5,
                }),

            ],
        })) : 
        chartImages === "none" && chartImagesAge !== "none" ?
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new ImageRun({
                                data: Uint8Array.from(atob(data[i].chartImgAge), c =>
                                    c.charCodeAt(0)
                                ),
                                transformation: {
                                    width: 360,
                                    height: 220
                                }
                            })
                        ]
                    }),],
                    columnSpan: 5,
                }),

            ],
        })) : 
        rowsToTable.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new ImageRun({
                                data: Uint8Array.from(atob(data[i].chartImg), c =>
                                    c.charCodeAt(0)
                                ),
                                transformation: {
                                    width: 290,
                                    height: 185
                                }
                            }),
                            new ImageRun({
                                data: Uint8Array.from(atob(data[i].chartImgAge), c =>
                                    c.charCodeAt(0)
                                ),
                                transformation: {
                                    width: 290,
                                    height: 185
                                }
                            })
                        ]
                    }),],
                    columnSpan: 5,
                }),

            ],
        }),
        new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 100,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: "                                         " + (language === "sv" ? "År i organisationen" : lang[language].yearInOrg),
                                font: "Arial",
                                bold: true,
                                color: "#afafaf",
                                size: 14
                            }),
                            new TextRun({
                                text: "                                                                                           " + (language === "sv" ? "Ålder" : lang[language].age),
                                font: "Arial",
                                bold: true,
                                color: "#afafaf",
                                size: 14
                            }),
                        ]
                    }),],
                    columnSpan: 5,
                    borders: {
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),

            ],
        })
        
        )

        //)

        //Push analysis main title to comment table
        if (data[i].comments[0] !== undefined) rowsToTableComments.push(new TableRow({
            children: [
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Analys" : lang[language].aiAnalysisTitle,
                                font: "Arial",
                                bold: true,
                            }),
                        ]
                    }),],
                    columnSpan: 3,
                    borders: {
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                    },
                }),
            ],
        })
        )
        //Push comment titles to comment table
        if (data[i].comments[0] !== undefined) rowsToTableComments.push(new TableRow({
            children: [
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Noterbart" : lang[language].notable,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                    },
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Orsak" : lang[language].reason,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                    },
                }),
                new TableCell({
                    shading: {
                        fill: "f3f3f3",
                    },
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Åtgärd" : lang[language].action,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "f3f3f3" },
                    },
                }),

            ],
        })
        )
        //push comments to comment table
        for (let a = 0; a < data[i].comments.length; a++) {
            rowsToTableComments.push(new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: 0,
                                after: 0,
                            },
                            children: [
                                new TextRun({
                                    text: `${data[i].comments !== undefined ? data[i].comments[a].notable : ""}`,
                                    //text: `${el.comments[0] !== undefined ? el.comments[0].notable : ""}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: 0,
                                after: 0,
                            },
                            children: [
                                new TextRun({
                                    text: `${data[i].comments !== undefined ? data[i].comments[a].reason : ""}`,
                                    //text: `${el.comments[0] !== undefined ? el.comments[0].notable : ""}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: 0,
                                after: 0,
                            },
                            children: [
                                new TextRun({
                                    text: `${data[i].comments !== undefined && data[i].comments[a].todo !== null ? data[i].comments[a].todo : ""}`,
                                    //text: `${el.comments[0] !== undefined ? el.comments[0].notable : ""}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        },
                    }),

                ],
            })
            )
        }
        // Push statistics table
        let tableToPush = new Table({
            // rows: 5,
            columns: 5,
            width: 0, // AUTO
            columnWidths: [2100, 1500, 1500, 1500, 2400], // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: rowsToTable
        })
        likaData.push(tableToPush)
        //Then push comments table under it
        let commentTableToPush = new Table({
            // rows: 5,
            columns: 5,
            width: 0, // AUTO
            columnWidths: [3000, 3000, 3000], // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: rowsToTableComments
        })
        likaData.push(commentTableToPush)
    }

    return likaData
}