import React from 'react';
import { LikvardigaExt } from './LikvardigaExt';
import { LikvardigaNormal } from './LikvardigaNormal';
import { fetchAuth } from '../../fetch-auth';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import videoImg from '../../assets/images/video.png';

export class Likvardiga extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluation: "showNothing"
        }
    }

    componentDidMount() {
        const runIt = async () => {
            let response = await fetchAuth(`/api/loadArbetsvarderingSetting`, 'POST');
            let data = await response.json();
            if (data[0].whatEvaluation == undefined || data[0].whatEvaluation == null) {
                this.setState({ evaluation: "" })
            } else {
                this.setState({ evaluation: data[0].whatEvaluation })
            }
        }
        runIt();
    }

    render() {
        return (
            this.state.evaluation === "showNothing" ?
                <>
                    <TopNav />
                    <Menu />
                    <div className="container">
                        <div className="explainer-container">
                            <h1>Analysera likvärdiga arbeten & hierarkiska skillnader</h1>
                            <div className="explainer-box">
                                <div className="explainer-text">
                                    Analys av likvärdiga arbeten innebär analys av arbeten som har likvärdiga krav. Nedan finner ni flera grupper. Ju högre grupp desto högre krav i arbetet. Vilken grupp arbetet har hamnat i baseras på den arbetsvärdering ni gjorde i steg 2.2. Analysera nu arbetena inom varje grupp. Finns skillnader mellan kvinnodominerade arbeten och övriga? Anteckna ner skillnader. <p></p>Analys av hierarkiska skillnader innebär att ni analyserar löneskillnader i arbeten mellan grupperna, finns det kvinnodominerade arbeten som har högre krav men lägre lön? Se video för mer information och tips om hur ni gör er analys.
                                </div>
                                <div className="video-explainer">
                                    <div className="video-wrapper">
                                        <img src={videoImg} alt="instruktionsvideo" className="video" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="autoAnalysisContainer">
                            <div className="topAnalysis hideBoxShadowInitial">
                                <span className="groupAnalysis selectGroup">Välj grupp</span>
                                <span style={{ fontSize: 16, display: "inline-block", fontWeight: 600 }}> &amp;</span>
                                <span className="groupAnalysis selectGroup">Välj grupp</span>
                                <div className="compareButton">Jämför</div>
                                <span className="minusAnalysis">-</span>
                                <span className="questionMark">?</span>
                            </div>
                        </div>
                    </div>
                </>
                : this.state.evaluation === "extLevel" || this.state.evaluation === "extPoint" || this.state.evaluation === "edited" ? <LikvardigaExt evaluation={this.state.evaluation} /> : <LikvardigaNormal />
        );
        {/* <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message="Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?"
        />*/}


    }
}