import React from 'react';
import './CostumerAccounts.css';
import { TopNav } from '../TopNav.js';
import { fetchAuth } from '../../fetch-auth';
import Switch from "react-switch";
import { NavLink } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import exitImg from '../../assets/images/exit.png';
import edit from '../../assets/images/edit.png';
import { AdminPopUp } from './AdminPopUp.js';

export class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section: "bolag",
            theUser: "",
            companies: [],
            chosenCompanies: [],
            usingDaughter: false,
            clickedCompany: '',
            activeCompany: '',
            acBounce: 'acStill',
            users: [],
            removeUser: "",
            removeCompany: "",
            mainCompanies: [],
            showingConsult: "",
            //Select what to add
            addNew: "Bolag",
            //For new company
            motherCompanies: [], //SelectionBox for DotterBolag
            addCompType: "Dotterbolag",
            addCompName: "",
            addCompLegalName: "",
            addCompMother: "",
            //Edit company
            editCompanyType: "",
            editCompany: "",
            companyEditType: "",
            //For new user
            addUserMail: "",
            addUserPass: "",
            addUserComp: "",
            addUserRole: "Koncern",
            addUserCompParentId: "",
            //For consult
            consultCompany: "",
            consultantComps: [],
            consultUser: "",
            consultUsers: [],
            consultForComp: "",
            consultSetting: "",
            consultRemoveSetting: "",
            //For PopUps
            showPopUpFile: null,
            popUpEdit: false,
            popUp: false,
            popUpUser: false,
            popUpConsult: false,
            popUpPass: false,
            //Edit user
            changeUserPass: '',
            newPass: '',
            adminSecret: '',
            //more settings for adding new company
            moreSettings: false,
            ageSetting: "off",
            filterSetting: "off",
            levelSetting: null,
            ageDocumentationDefault: "expChart",
            extraSetting: "off",
            salaryBenchSetting: "off",
            equalentGroupsSetting: "6",
            nrEqualentGroupDefault: "6",
            showExtValuation: "on",
            //Change name
            popUpChangeName: false,
            changeNameComp: "",
            changeNameCompId: "",
            changeNameTo: "",
            changeLegalNameTo: ""
        }
    }

    selectCompany = (event) => {
        const company = event.target.id
        const selectIt = async () => {
            //First check if the clicked one is the same as now.
            const parsedData = JSON.parse(company) //Get the clicked company and id
            const clickedCompany = parsedData.company
            const clickedType = parsedData.type
            const clickedId = parsedData.companyId

            if (clickedType === "Koncern") {
                //Get the selected company daughter companies and set these to state
                let daughterCompanies = this.state.companies.filter(c => c.companyId_FK === clickedId)
                this.setState({ chosenCompanies: daughterCompanies })
                //Set state to use daughter companies, for render these instead of main ones
                this.setState({ clickedCompany: clickedCompany })
                this.setState({ usingDaughter: true, showingConsult: "bolag" })
                return;
            }

            if (clickedType === "Konsult") { //<--- OBS! För konsultvy så behöver det vara consultantId som är aktuell, inte consultId, men för admin är detta korrekt
                //Get the selected company daughter companies and set these to state
                let daughterCompanies = this.state.companies.filter(c => c.consultId_FK === clickedId)
                this.setState({ chosenCompanies: daughterCompanies })
                //Set state to use daughter companies, for render these instead of main ones
                this.setState({ clickedCompany: clickedCompany })
                this.setState({ usingDaughter: true, showingConsult: "kunder" })
                return;
            }

            if (clickedCompany === this.state.activeCompany) {
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            //If not the same, change it
            let response = await fetchAuth(`/api/r/selectAdmin`, 'POST', JSON.stringify({ data: company }));
            if (response.status === 200) {
                let data = await response.json();
                localStorage.setItem('localT', data.localToken)
                this.setState({ activeCompany: data.activeCompany })
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            if (response.status !== 200) {
                alert('Ett fel inträffade, prova igen.')
                return;
            }
        }
        selectIt();
    }

    back = () => {
        this.setState({ chosenCompanies: this.state.mainCompanies })
        this.setState({ usingDaughter: false, showingConsult: false })
    }

    companyDelete = (event) => {
        this.setState({ popUp: false });
        const dataset = this.state.removeCompany
        const parsedData = JSON.parse(dataset) //Get the clicked company and id
        const { company, companyId, type } = parsedData
        if (company === this.state.activeCompany) {
            alert('Du kan inte ta bort det företag du för närvarande representerar. Välj först ett annat företag och försök därefter igen.')
            return;
        } else {
            const removeCompany = async () => {
                let response = await fetchAuth(`/api/removeCompany`, 'POST', JSON.stringify({ data: dataset }));
                if (response.status !== 200) {
                    alert("Något gick fel. Försök igen.")
                    return;
                } else if (response.status === 200) {
                    alert(`Bolaget "${company}" är borttaget.`)
                    const companies = this.state.companies
                    const chosenCompanies = this.state.chosenCompanies
                    const mainCompanies = this.state.mainCompanies

                    let companiesUpd = companies.filter(c => c.dotterBolag != company)
                    let mainUpd = mainCompanies.filter(c => c.company != company)
                    let chosenUpd = ""
                    if (chosenCompanies[0].company != undefined) {
                        chosenUpd = chosenCompanies.filter(c => c.company != company)
                    } else if (chosenCompanies[0].dotterBolag != undefined) {
                        chosenUpd = chosenCompanies.filter(c => c.dotterBolag != company)
                    }
                    this.setState({
                        companies: companiesUpd,
                        chosenCompanies: chosenUpd,
                        mainCompanies: mainUpd
                    })
                }
            }
            removeCompany();
            this.setState({ removeCompany: '' })
        }
    }

    togglePopUp = (event) => {
        if (this.state.popUp) {
            this.setState({ popUp: false, removeCompany: '' })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUp: true, removeCompany: dataset })
        }
    }

    togglePopUpEdit = (event) => {
        if (this.state.popUpEdit) {
            this.setState({ popUpEdit: false, editCompanyType: '', companyEditType: '', editCompany: '' })
        } else {
            const dataset = event.target.dataset.txt
            const parsedData = JSON.parse(dataset) //Get the clicked company and id
            this.setState({ popUpEdit: true, editCompanyType: dataset, companyEditType: parsedData.type, editCompany: parsedData.company })
        }
    }

    togglePopUpChangeName = (event) => {
        if (this.state.popUpChangeName) {
            this.setState({ popUpChangeName: false, changeNameComp: "", changeNameCompId: "", changeNameTo: "", changeLegalNameTo: "" })
        } else {
            const dataset = event.target.dataset.txt
            const parsedData = JSON.parse(dataset) //Get the clicked company and id
            console.log(parsedData)
            this.setState({ popUpChangeName: true, changeNameComp: parsedData.company, changeNameCompId: parsedData.companyId })
        }
    }
    changeCompName = async () => {
        let changeTo = this.state.changeNameTo
        let changeFrom = this.state.changeNameComp
        let compId = this.state.changeNameCompId
        let legalName = this.state.changeLegalNameTo
        if(changeTo === "" || changeTo === null  || changeTo === undefined || changeFrom === "" || changeFrom === null  || changeFrom === undefined || compId === "" || compId === null  || compId === undefined) {
            alert("Något gick fel. Saknar nytt namn eller befintligt namn. Försök igen.")
            return;
        } 
        let nameData = {
            changeTo: changeTo,
            changeFrom: changeFrom,
            compId: compId,
            changeLegalNameTo: legalName
        }
        let response = await fetchAuth(`/api/changeNameCompany`, 'POST', JSON.stringify({ data: nameData }));
        if (response.status !== 200) {
            alert("Något gick fel. Försök igen.")
        } else if (response.status === 200) {
            alert("Namnet är utbytt. Ladda om sidan för att se det nya namnet.")
            this.setState({ popUpChangeName: false, changeNameComp: "", changeNameCompId: "", changeNameTo: "", changeLegalNameTo: "" })
        }
    }
    editCompName = (event) => { this.setState({ changeNameTo: event.target.value }) }
    editLegalCompName  = (event) => { this.setState({ changeLegalNameTo: event.target.value }) }

    togglePopUpUser = (event) => {
        if (this.state.popUpUser) {
            this.setState({ popUpUser: false, removeUser: '' })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUpUser: true, removeUser: dataset })
        }
    }

    togglePopUpPass = (event) => {
        if (this.state.popUpPass) {
            this.setState({ popUpPass: false, changeUserPass: '', newPass: '', adminSecret: '' })
        } else {
            const dataset = event.target.dataset.txt
            const parsedData = JSON.parse(dataset) //Get the clicked company and id
            const { email } = parsedData
            this.setState({ popUpPass: true, changeUserPass: email })
        }
    }
    newPass = event => this.setState({ newPass: event.target.value })
    adminSecret = event => this.setState({ adminSecret: event.target.value })

    userDelete = () => {
        this.setState({ popUpUser: false });
        const dataset = this.state.removeUser
        const parsedData = JSON.parse(dataset) //Get the clicked company and id
        const { role, companyId, email, user } = parsedData
        if (role === "Admin") {
            alert("Admin användare kan inte raderas.")
            return;
        }
        let localT = localStorage.getItem('localT');
        let { name } = jwt_decode(localT);
        if (name == user) {
            alert("Du kan inte radera din egen användare.")
            return;
        } else {
            const removeIt = async () => {
                let response = await fetchAuth(`/api/userDelete`, 'POST', JSON.stringify({ data: dataset }));
                if (response.status !== 200) {
                    alert("Något gick fel. Försök igen.")
                } else if (response.status === 200) {
                    alert("Användare borttagen.")
                    const userState = this.state.users
                    let updatedUserList = userState.filter(u => u.userId_PK != user)
                    this.setState({ users: updatedUserList })
                }
            }
            removeIt();
            this.setState({ removeUser: '' })
        }
    }

    //Add new
    toggleAddNew = () => this.state.addNew === "Bolag" ? this.setState({ addNew: "User", addUserComp: "", addUserRole: "" }) : this.setState({ addNew: "Bolag", addCompType: "Dotterbolag", addCompMother: "" })

    //Add new company
    addCompType = event => this.setState({ addCompType: event.target.value })
    addCompMother = event => this.setState({ addCompMother: event.target.value })
    addCompName = event => this.setState({ addCompName: event.target.value })
    addCompLegalName = event => this.setState({ addCompLegalName: event.target.value })
    //more settings for new company
    toggleMoreSettings = () => { this.state.moreSettings ? this.setState({ moreSettings: false }) : this.setState({ moreSettings: true }) }
    addAgeSetting = event => this.setState({ ageSetting: event.target.value })
    addFilterSetting = event => this.setState({ filterSetting: event.target.value })
    addLevelSetting = event => event.target.value === "off" ? this.setState({ levelSetting: null }) : this.setState({ levelSetting: event.target.value })
    addAgeDocumentationDefault = event => this.setState({ ageDocumentationDefault: event.target.value })
    addExtraSetting = event => this.setState({ extraSetting: event.target.value })
    addSalaryBench = event => this.setState({ salaryBenchSetting: event.target.value })
    addShowExtValuation = event => this.setState({ showExtValuation: event.target.value })
    addEqualentGroupsSetting = event => this.setState({ equalentGroupsSetting: event.target.value })
    addNrEqualentGroupDefault = event => this.setState({ nrEqualentGroupDefault: event.target.value })

    //Add new User
    addUserRole = event => this.setState({ addUserRole: event.target.value })
    addUserMail = event => this.setState({ addUserMail: event.target.value })
    addUserPass = event => this.setState({ addUserPass: event.target.value })
    addUserComp = event => {
        const parsedData = JSON.parse(event.target.value)
        const { comp, compId } = parsedData
        this.setState({ addUserComp: comp, addUserCompParentId: compId })
    }

    newCompany = () => {
        const name = this.state.addCompName
        const legalName = this.state.addCompLegalName
        const type = this.state.addCompType
        const compMother = this.state.addCompMother

        //Extra settings
        const ageSetting = this.state.ageSetting
        const filterSetting = this.state.filterSetting
        const ageDocumentationDefault = this.state.ageDocumentationDefault
        const extraSetting = this.state.extraSetting
        const salaryBenchSetting = this.state.salaryBenchSetting
        const equalentGroupsSetting = this.state.equalentGroupsSetting
        const nrEqualentGroupDefault = this.state.nrEqualentGroupDefault
        const showExtValuation = this.state.showExtValuation
        const levelSetting = this.state.levelSetting

        if (name.length <= 0 || type.length <= 0 || legalName.length <= 0) {
            alert("Alla fält är inte ifyllda.")
            return;
        }
        else if (type === "Dotterbolag" && compMother.length <= 0) {
            alert("Du måste välja vilket moderbolag dotterbolaget tillhör.")
            return;
        }
        else if (name === this.state.activeCompany) {
            alert("Företagsnamnet finns redan.")
            return;
        }
        else {
            const data = {
                name: name, type: type, compMother: compMother, legalName: legalName,
                ageSetting: ageSetting, ageDocumentationDefault: ageDocumentationDefault, extraSetting: extraSetting, equalentGroupsSetting: equalentGroupsSetting, nrEqualentGroupDefault: nrEqualentGroupDefault, filterSetting: filterSetting, showExtValuation: showExtValuation, levelSetting: levelSetting, salaryBenchSetting: salaryBenchSetting
            }

            const addComp = async () => {
                let response = await fetchAuth(`/api/addCompany`, 'POST', JSON.stringify({ data: data }));
                if (response.status !== 200) {
                    alert("Något gick fel. Försök igen.")
                } else if (response.status === 200) {
                    alert("Företag skapat!")
                    this.setState({ addCompName: "", addCompLegalName: "" })
                    return;
                }
            }
            addComp();
        }

    }

    newUser = () => {
        const email = this.state.addUserMail
        const pass = this.state.addUserPass
        const role = this.state.addUserRole
        const comp = this.state.addUserComp
        const compId = this.state.addUserCompParentId

        if (pass.length < 12) {
            alert("För kort lösenord. Använd minst 12 tecken.")
            return;
        }
        else if (email.length < 4 || role === "" || comp === "") {
            alert("Alla fält är inte ifyllda.")
            return;
        }

        const data = { email: email, pass: pass, role: role, comp: comp, compId: compId }

        const addUser = async () => {
            let response = await fetchAuth(`/api/addUser`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                alert("Något gick fel. Försök igen.")
            } else if (response.status === 200) {
                alert("Användare skapad!")
                this.setState({ addUserMail: "", addUserPass: "", addUserRole: "", addUserComp: "", })
            }
        }
        addUser();

    }

    togglePopUpFile = (id) => this.setState({ showPopUpFile: id })
    popUpNull = () => this.setState({ showPopUpFile: null })

    changeUserPass = () => {
        if (this.state.newPass.length < 12) {
            alert("För kort lösenord, måste vara minst 12 tecken.")
            return;
        }
        const data = {
            email: this.state.changeUserPass,
            pass: this.state.newPass,
            secret: this.state.adminSecret
        }
        const changePass = async () => {
            let response = await fetchAuth(`/api/changeUserPassword`, 'POST', JSON.stringify({ data: data }));
            if (response.status === 403) {
                alert("Fel admin secret eller så finns inte användaren.")
                this.setState({ changeUserPass: "", newPass: "", adminSecret: "" })
                return;
            }
            else if (response.status !== 200) {
                alert("Något gick fel. Försök igen.")
                this.setState({ changeUserPass: "", newPass: "", adminSecret: "" })
                return;
            } else if (response.status === 200) {
                alert("Lösenorder har ändrats!")
                this.setState({ changeUserPass: "", newPass: "", adminSecret: "", popUpPass: false })
                return;
            }
        }
        changePass();
    }
    editCompanyType = () => {
        //Check so type is not koncern or bolag. They cant be changed
        if (this.state.companyEditType !== "Koncern" && this.state.companyEditType !== "Bolag") {
            alert("Det går inte att ändra denna bolagstyp. Ta bort företaget och lägg till ett nytt.")
            return;
        }
        const editInfo = this.state.editCompanyType
        const editComp = async () => {
            let response = await fetchAuth(`/api/editCompanyRole`, 'POST', JSON.stringify({ data: editInfo }));
            if (response.status === 409) {
                alert("Kunde ej ändra koncernen till ett bolag. Dotterbolag måste raderas först.")
                this.setState({ editCompanyType: "", editCompany: "", companyEditType: "", popUpEdit: false })
            }
            else if (response.status !== 200) {
                alert("Något gick fel. Försök igen.")
                this.setState({ editCompanyType: "", editCompany: "", companyEditType: "", popUpEdit: false })
            } else if (response.status === 200) {
                alert("Bolagstypen är ändrad!")
                this.setState({ editCompanyType: "", editCompany: "", companyEditType: "", popUpEdit: false })
                return;
            }
        }
        editComp();
    }

    togglePopUpAddConsultant = (event) => {
        if (this.state.popUpConsult) {
            this.setState({ popUpConsult: false, editCompany: "" })
        }
        else if (!this.state.popUpConsult) {
            const dataset = event.target.dataset.txt
            const parsedData = JSON.parse(dataset) //Get the clicked company and id
            const clickedCompany = parsedData.company

            this.setState({ popUpConsult: true, consultForComp: dataset, editCompany: clickedCompany, })
        }
    }

    consultCompany = event => {
        let consultUsers = this.state.users.filter(u => u.role === "Konsult" && u.companyId_FK == event.target.value)
        this.setState({ consultCompany: event.target.value, consultUsers: consultUsers })
    }
    consultUser = event => this.setState({ consultUser: event.target.value })
    consultSetting = event => this.setState({ consultSetting: event.target.value })

    addConsultant = () => {
        const addConsult = async () => {
            try {
                const parsedData = JSON.parse(this.state.consultForComp) //Get the clicked company and id
                const { company, type, companyId } = parsedData
                const data = {
                    consultCompany: this.state.consultCompany,
                    consultUser: this.state.consultUser,
                    consultSetting: this.state.consultSetting,
                    company: company,
                    type: type,
                    companyId: companyId
                }
                let response = await fetchAuth(`/api/addConsultToComp`, 'POST', JSON.stringify({ data: data }));
                if (response.status !== 200) {
                    alert("Något gick fel. Försök igen.")
                    return;
                }
                if (response.status === 200) {
                    alert("Konsult tillagd!")
                    this.setState({ consultCompany: "", consultUser: "", popUpConsult: false })
                }
            } catch {
                alert("Något gick fel. Försök igen.")
                return;
            }
        }
        addConsult();
    }

    consultRemoveSetting = event => this.setState({ consultRemoveSetting: event.target.value })

    removeConsultant = () => {
        const deleteConsultant = async () => {
            try {
                if (this.state.consultRemoveSetting !== "Koncern" && this.state.consultRemoveSetting !== "Bolag") {
                    alert("Välj om ska ta bort det enskilda bolaget eller hela koncernen.")
                    return;
                }
                const parsedData = JSON.parse(this.state.consultForComp)
                const { company, type, companyId } = parsedData

                const data = {
                    company: company,
                    type: type,
                    companyId: companyId,
                    setting: this.state.consultRemoveSetting
                }

                let response = await fetchAuth(`/api/removeConsultantFromComp`, 'POST', JSON.stringify({ data: data }));
                if (response.status !== 200) {
                    alert("Något gick fel. Försök igen.")
                    return;
                }
                if (response.status === 200) {
                    alert("Konsult borttagen!")
                    this.setState({ consultCompany: "", consultUser: "", popUpConsult: false })
                }
            } catch {
                alert("Något gick fel. Försök igen.")
                return;
            }
        }
        deleteConsultant();
    }

    componentDidMount() {
        const loadCompanies = async () => {
            let response = await fetchAuth(`/api/loadAdmin`, 'POST');
            let data = await response.json();
            let companies = data[0][0];
            let mainCompanies = data[1][0];
            let motherCompanies = mainCompanies.filter(c => c.type === "Koncern")
            let chosenCompanies = data[1][0];
            let users = data[2][0];

            //Filter the user from the users data and then get activeCompany
            let localT = localStorage.getItem('localT');
            let { name, aComp, comp } = jwt_decode(localT);
            let theUser = users.filter(u => u.userId_PK === name)
            let daUser = theUser[0].email

            //Filter out the consultCompanies and users
            const consultantComps = mainCompanies.filter(c => c.type === "Konsult")

            //Add users company to users list. A litte bit of calculation:)
            let aCompany = mainCompanies.filter(c => c.companyId_PK === comp)
            let adminComp = aCompany[0].company

            for (let i = 0; i < users.length; i++) {
                if (users[i].role === "Admin") {
                    users[i].belongTo = adminComp
                    users[i].lockedTo = "none"
                    users[i].compType = "Admin"

                } else if (users[i].role === "Bolag") {
                    let mainComp = mainCompanies.filter(c => c.companyId_PK === users[i].companyId_FK)
                    if (mainComp[0].type === "Koncern") {
                        users[i].belongTo = mainComp[0].company
                        users[i].lockedTo = users[i].activeCompany
                        users[i].compType = mainComp[0].type
                    } else {
                        users[i].belongTo = mainComp[0].company
                        users[i].lockedTo = "none"
                        users[i].compType = mainComp[0].type
                    }
                } else if (users[i].role === "Koncern" || users[i].role === "Konsult") {
                    let mainComp = mainCompanies.filter(c => c.companyId_PK === users[i].companyId_FK)
                    users[i].belongTo = mainComp[0].company
                    users[i].lockedTo = "none"
                    users[i].compType = mainComp[0].type
                }
            }



            this.setState({ companies: companies, activeCompany: aComp, mainCompanies: mainCompanies, chosenCompanies: chosenCompanies, users: users, motherCompanies: motherCompanies, theUser: daUser, consultantComps: consultantComps })


        }
        loadCompanies();
    }

    changeName = async () => {
        let data = ""
        let response = await fetchAuth(`/api/changeNameCompany`, 'POST', JSON.stringify({ data: data }));
        if (response.status !== 200) {
            alert("Något gick fel. Försök igen.")
        } else if (response.status === 200) {
            alert("Allt ok")
            this.setState({ addCompName: "", addCompLegalName: "" })
            return;
        }
    }

    render() {
        let companies = "Laddar företag.."

        if (this.state.companies.length > 0) {
            companies = this.state.chosenCompanies.map(company => {
                return <>
                    {this.state.usingDaughter === false ?
                        <div className="company">
                            <div style={{ width: 92, border: "1px solid #f9fafb", borderTop: "none", height: 30, position: "absolute", borderLeft: "none" }}>
                                <div className="removeCompany" onClick={this.togglePopUp} data-txt={`{"company": "${company.company}", "companyId": ${company.companyId_PK}, "type": "${company.type}"}`} style={{ borderBottomRightRadius: 0, lineHeight: 1.6 }}>x</div>
                                <div className="removeCompany" onClick={this.togglePopUpEdit} data-txt={`{"company": "${company.company}", "companyId": ${company.companyId_PK}, "type": "${company.type}"}`} style={{ marginLeft: 31, borderTopLeftRadius: 0, borderBottomRightRadius: 0, lineHeight: 1.7 }}>&#9998;</div>

                                <div className="removeCompany" onClick={this.togglePopUpChangeName} data-txt={`{"company": "${company.company}", "companyId": ${company.companyId_PK}, "type": "${company.type}"}`} style={{ marginLeft: 61.5, borderTopLeftRadius: 0, borderBottomRightRadius: 0, lineHeight: 2, fontSize: 14 }}>&#8448;</div>

                                <div className="removeCompany" onClick={this.togglePopUpAddConsultant} data-txt={`{"company": "${company.company}", "companyId": ${company.companyId_PK}, "type": "${company.type}"}`} style={{ marginLeft: 92, borderTopLeftRadius: 0, borderLeft: "1px solid #f9fafb" }}>&#9863;</div>


                            </div>
                            <span className="koncernText">{company.type === "Bolag" ? "" : company.type}</span>
                            <span style={{ marginLeft: 107, marginTop: 90, fontSize: 10 }} className="koncernText">{company.companyId_PK}</span>
                            <p
                                id={`{"company": "${company.company}", "companyId": ${company.companyId_PK}, "type": "${company.type}"}`}
                                onClick={this.selectCompany}
                                className="companyText">
                                {company.company}
                            </p>
                        </div>
                        :
                        <div className="company companyAnimation">
                            <div className="removeCompany" onClick={this.togglePopUp} data-txt={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`} style={{ borderBottomRightRadius: 0 }}>x</div>
                            <div className="removeCompany" onClick={this.togglePopUpChangeName} data-txt={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`} style={{ marginLeft: 31, borderTopLeftRadius: 0, borderBottomRightRadius: 0, lineHeight: 2, fontSize: 14 }}>&#8448;</div>
                            <div className="removeCompany" onClick={this.togglePopUpAddConsultant} data-txt={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`} style={{ marginLeft: 62, borderTopLeftRadius: 0 }}>&#9863;</div>
                            <span style={{ marginLeft: 38 }} className="koncernText">{company.type === "moder" ? "Huvudkonto" : ""}</span>
                            <span style={{ marginLeft: 107, marginTop: 90, fontSize: 10 }} className="koncernText">{company.companyId_FK}</span>
                            <p
                                id={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`}
                                onClick={this.selectCompany}
                                className="companyText">
                                {company.dotterBolag}
                            </p>
                        </div>}
                </>
            });
        }

        let users = ""

        if (this.state.users.length > 0) {
            users = this.state.users.map(user => {
                let locked = user.lockedTo === "none" ? <></> : <span className="liUserItem" style={{ fontStyle: "italic", fontSize: 13 }}>Låst till: {user.lockedTo}</span>
                return <li className="liUsers">
                    <span className="liUserItem">{user.belongTo} <span style={{ fontSize: 13, fontStyle: "italic", padding: 2, background: "#55cbaf", color: "white", borderRadius: 4, marginLeft: 4, display: "inline-block" }}> {user.compType}</span></span>
                    <span className="liUserItem">{user.email}</span>
                    <span className="liUserItem" style={{ width: "28%" }}>{user.role}</span>
                    <img onClick={this.togglePopUpPass} className="editUserBtn" src={edit} data-txt={`{"email": "${user.email}"}`} />
                    <span onClick={this.togglePopUpUser} className="liUserItem liRemove" data-txt={`{"user": "${user.userId_PK}", "companyId": ${user.companyId_FK}, "role": "${user.role}", "email": "${user.email}"}`}>X</span>
                    {locked}
                </li>
            });
        }

        let motherCompanies = ""
        if (this.state.motherCompanies.length > 0) {
            motherCompanies = this.state.motherCompanies.map(main => {
                return <option value={main.company}> {main.company} </option>
            });
        }

        let companiesList = ""
        if (this.state.companies.length > 0) {
            companiesList = this.state.companies.map(comp => {
                return <option value={`{"compId": "${comp.companyId_FK}", "comp": "${comp.dotterBolag}"}`}> {comp.dotterBolag} </option>
            });
        }

        let consultantComps = ""
        if (this.state.consultantComps.length > 0) {
            consultantComps = this.state.consultantComps.map(comp => {
                return <option value={comp.companyId_PK} > {comp.company} </option>
            });
        }

        let consultUsers = ""
        if (this.state.consultUsers.length > 0) {
            consultUsers = this.state.consultUsers.map(user => {
                return <option value={user.userId_PK} > {user.email} </option>
            });
        }

        return (
            <>
                <TopNav />
                {/*PopUp remove company */}
                <div className={this.state.popUp ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUp} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> Är du säker på att du vill ta bort detta bolag?
                        <p style={{ fontWeight: "normal", fontSize: 15 }}>All data tillhörande detta bolag kommer tas bort. Du kan inte ångra dig sedan.</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUp}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.companyDelete}>Fortsätt</span>
                    </div>
                </div>
                {/*PopUp remove user */}
                <div className={this.state.popUpUser ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpUser} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> Är du säker på att du vill ta bort denna användare?
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>Användaren tas bort men data som är skapat av användaren behålls. Ta även bort bolaget för att ta bort all data.</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpUser}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.userDelete}>Fortsätt</span>
                    </div>
                </div>
                {/*PopUp edit user pass */}
                <div className={this.state.popUpPass ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650, minHeight: 350 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpPass} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> Byt lösenord
                        <p style={{ fontWeight: "normal", fontSize: 15, paddingBottom: 5 }}>Byt lösenord för <b style={{ color: "rgb(208 163 51)" }}>{this.state.changeUserPass}</b></p>
                        <input onChange={this.newPass} type="password" placeholder="Ange nytt lösenord" style={{ display: "block", margin: "auto", width: 240, border: "1px solid", marginTop: 10 }} />
                        <input onChange={this.adminSecret} type="password" placeholder="Ange admin secret" style={{ display: "block", margin: "auto", width: 240, border: "1px solid", marginTop: 10 }} />
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpPass}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.changeUserPass}>Ändra</span>
                    </div>
                </div>
                {/*PopUp edit company role */}
                <div className={this.state.popUpEdit ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650, minHeight: 250 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpEdit} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> Vill du ändra bolagstyp för {this.state.editCompany}?
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{this.state.editCompany} har typen {this.state.companyEditType}.
                            {this.state.companyEditType === "Koncern" ? " Koncernen kommer omvandlas till ett Bolag. Notera att alla dotterbolag måste raderas manuellt innan koncernen kan tas bort. Vill du utföra denna ändring?" : this.state.companyEditType === "Bolag" ? " Bolaget kommer ändras till en koncern. När klart kan dotterbolag läggas till. Vill du utföra denna ändring?" : " Det går inte att justera denna bolagstyp. Radera företaget och skapa ett nytt."}
                        </p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpEdit}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.editCompanyType}>Utför ändring</span>
                    </div>
                </div>
                {/*PopUp change Name */}
                <div className={this.state.popUpChangeName ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650, minHeight: 250, paddingBottom: 80 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpChangeName} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> Vill du byta namn för {this.state.changeNameComp}?</span>
                    <div style={{ marginTop: 40 }}>
                        <input type="text" placeholder="Ange namn" value={this.state.changeNameTo} className="nameInputText" onChange={this.editCompName} style={{marginBottom: 10}}/>
                        <input type="text" placeholder="Ange legalt namn" value={this.state.changeLegalNameTo} className="nameInputText" onChange={this.editLegalCompName} />
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 32px" }} onClick={this.togglePopUpChangeName}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 35px" }} onClick={this.changeCompName}>Spara</span>
                    </div>
                </div>
                {/*PopUp add consult */}
                <div className={this.state.popUpConsult ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650, minHeight: 550, marginTop: -60 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpAddConsultant} />
                    <select onChange={this.consultRemoveSetting} className="selectorStyle" name="Bolagsform" style={{ display: "inline-block", width: 0 }}>
                        <option value=""> Välj vad du vill ta bort</option>
                        <option value="Koncern"> Ta bort konsult från hela koncernen</option>
                        <option value="Bolag"> Ta bort konsult från valda bolagt endast</option>
                    </select>
                    <span onClick={this.removeConsultant} style={{ display: "block", background: "#d05c4a", width: 130, padding: 8, borderRadius: 4, margin: "auto", marginTop: 20, fontWeight: 700, color: "white", cursor: "pointer", boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)", display: "inline-block", marginLeft: 10 }}>Ta bort konsult</span>
                    <span style={{ borderBottom: "1px solid lightgrey", display: "block", marginTop: 20 }}></span>

                    <span style={{ marginTop: 40, display: "block", fontWeight: 900, fontSize: 18 }}> Lägg till konsult för {this.state.editCompany}
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>
                            <p>Välj konsultfirma</p>
                            <select onChange={this.consultCompany} className="selectorStyle" name="Bolagsform" >
                                <option value=""> Välj </option>
                                {consultantComps}
                            </select>
                            <p>Välj användare</p>
                            <select onChange={this.consultUser} className="selectorStyle" name="Bolagsform" >
                                <option value=""> Välj </option>
                                {consultUsers}
                            </select>
                            <p>Om koncern, välj om användaren ska komma åt alla dotterbolag eller enbart ett bolag.</p>
                            <p style={{ fontSize: 12, fontStyle: "italic", maxWidth: 550, marginLeft: "auto", marginRight: "auto" }}>Om ingen koncern görs inget val, låt då stå kvar på "Välj". Observera att konsultbolaget också måste ha access till moderbolaget för att få access till enskilda bolag i samma koncern, annars syns det ej i kontot.
                            </p>
                            <select onChange={this.consultSetting} className="selectorStyle" name="Bolagsform" >
                                <option value=""> Välj </option>
                                <option value="Alla"> Alla (kräver att moderbolaget är valt)</option>
                                <option value="BaraModer"> Enskilt bolag</option>
                            </select>
                        </p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpAddConsultant}>Avbryt</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.addConsultant}>Lägg till</span>
                    </div>
                </div>

                {/*Other PopUps */}
                <AdminPopUp showPopUp={this.state.showPopUpFile} exitAction={this.popUpNull} />

                <NavLink exact to="/" className="goBack">Tillbaka</NavLink>
                <div className="login-page">
                    <h1 style={{ marginTop: 120 }}>Administratör</h1>
                    <div className="selectSectionContainer">
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopRightRadius: 0, borderBottomRightRadius: 0, }} className={this.state.section === "bolag" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "bolag" })}>Bolag</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea", borderRadius: 0 }} className={this.state.section === "users" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "users" })}>Användare</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className={this.state.section === "addNew" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "addNew" })}>Lägg till</span>
                    </div>

                    {/* Create company */}
                    <div className={this.state.section === "newUser" ? "users-container" : "hideComp"} style={{ height: 480, display: "inline-block" }}>
                        <h2 style={{ fontWeight: 800, fontSize: 22, marginBottom: 40 }}>Lägg till företag</h2>
                        <div className="form-container" style={{ marginTop: 0, paddingTop: 40, height: "auto", paddingBottom: 40 }}>
                            <form className="login-form">
                                <input className="form-input" value={this.state.company} type="text" placeholder="Företag" onChange={this.company} />
                                <button style={{ marginTop: 20 }} className="login-button" onClick={this.createUser}>Lägg till</button>
                            </form>
                        </div>
                    </div>

                    {/* Show companies */}
                    <div className={this.state.section === "bolag" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}> Du företräder:</p>
                        <h2 className={this.state.acBounce}>{this.state.activeCompany}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>Välj det bolag du vill företräda</h2>
                        {this.state.usingDaughter === true ? <p style={{ marginTop: -30 }}><span className="backCompanyButton" onClick={this.back}>Tillbaka</span> Visar alla {this.state.showingConsult} tillhörande: <b>{this.state.clickedCompany}</b></p> : ""}
                        {companies}
                    </div>

                    {/* Show users */}
                    <div className={this.state.section === "users" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}> Din användare:</p>
                        <h2 className={this.state.acBounce}>{this.state.theUser}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>Visar alla användare</h2>
                        <ul style={{ boxShadow: "0 3px 14px 0 rgb(0 0 0 / 6%)" }}>
                            <li className="liUsers liFilter">
                                <span className="liUserItem" style={{ fontSize: 16 }}>Företag</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>Email</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>Roll</span>
                                <span style={{ background: "transparent" }} className="liUserItem liRemove">
                                    <img style={{ width: 20, position: "absolute", marginTop: -13, marginLeft: -10 }} src={edit} /></span>
                            </li>
                            {users}
                        </ul>
                    </div>

                    {/* Add users or company */}
                    <div className={this.state.section === "addNew" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}> Välj vad du vill skapa:</p>
                        <div className="selectSectionContainer" style={{ width: 250, marginTop: 14 }}>
                            <div className={this.state.addNew === "Bolag" ? "kontoActive" : ""}
                                style={{
                                    display: "inline-block",
                                    width: 125,
                                    padding: "12px 0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                    fontSize: 15.6,
                                }}
                                onClick={this.toggleAddNew}
                            >Nytt bolag</div>
                            <div className={this.state.addNew === "User" ? "kontoActive" : ""} style={{
                                display: "inline-block",
                                width: 125,
                                padding: "12px 0px",
                                cursor: "pointer",
                                borderBottomLeftRadius: 0,
                                borderTopLeftRadius: 0,
                                fontSize: 15.6,
                            }}
                                onClick={this.toggleAddNew}
                            >Ny användare</div>
                        </div>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        {/* Add company */}
                        {this.state.addNew === "Bolag" ?
                            <>
                                <h2 style={{ textAlign: "center", fontWeight: 800, fontSize: 19.6, marginBottom: 40, }}>Skapa nytt bolag</h2>

                                <div style={{ textAlign: "left", maxWidth: 350, margin: "auto", width: "100%", padding: 40, background: "white", borderRadius: 6, boxShadow: "0 3px 14px 0 rgb(0 0 0 / 6%)" }}>
                                    <input style={{ width: "100%", maxWidth: 335, paddingLeft: 15, textAlign: "left" }} className="form-input" value={this.state.addCompName} type="text" placeholder="Namn på bolaget" onChange={this.addCompName} />
                                    <input style={{ width: "100%", maxWidth: 335, paddingLeft: 15, textAlign: "left" }} className="form-input" value={this.state.addCompLegalName} type="text" placeholder="Legalt namn (enligt Allabolag)" onChange={this.addCompLegalName} />
                                    <div style={{ paddingTop: 30, marginBottom: 7, fontSize: 15, fontWeight: 700 }}>Välj typ: <span className="questionPopUp" onClick={() => this.togglePopUpFile("selectType")}>?</span></div>
                                    <select onChange={this.addCompType} className="selectorStyle" name="Bolagsform" >
                                        <option value="Dotterbolag"> Dotterbolag </option>
                                        <option value="Bolag"> Bolag </option>
                                        <option value="Koncern"> Koncern </option>
                                        <option value="Konsult"> Konsult </option>
                                    </select>
                                    <div className={this.state.addCompType === "Dotterbolag" ? "" : "hide"} style={{ paddingTop: 30, marginBottom: 7, fontSize: 15, fontWeight: 700 }}>Vilket företag tillhör dotterbolaget?</div>
                                    <select onChange={this.addCompMother} className={this.state.addCompType === "Dotterbolag" ? "selectorStyle" : "hide"} name="Bolagsform" >
                                        <option value={""}> Välj </option>
                                        {motherCompanies}
                                    </select>
                                    {/* Later maybe create user automatically for bolag, koncern and konsult*/}


                                    {/* Extra settings for the company*/}
                                    <div style={{ marginTop: 25, textDecoration: "underline", cursor: "pointer", fontSize: 14 }} onClick={this.toggleMoreSettings}>Fler inställningar</div>
                                    <div className={this.state.moreSettings ? "adminMoreSettings" : "hide"}>
                                        <div>
                                            <div style={this.state.addCompType === "Dotterbolag" ? { display: "inline-block" } : { display: "inline-block", fontWeight: 600 }}>Visa nivåbeskrivningar som default</div> {/* Skrivs i company (showExtraSetting) VAL "on", "off"*/}
                                            <select onChange={this.addLevelSetting} className="adminMoreSelector" name="showExtValuation" style={this.state.addCompType === "Dotterbolag" ? { marginLeft: 23 } : { marginLeft: 12 }}>
                                                <option value="off"> Av </option>
                                                <option value="first"> På </option>
                                            </select>
                                        </div>
                                        <div style={{ borderTop: "1px dotted lightgrey" }} className={this.state.addCompType === "Dotterbolag" ? "hide" : ""}>
                                            <div style={{ display: "inline-block", fontWeight: 600 }}>Dölj/visa filter i diagram</div> {/* Skrivs i company (showAgeChart) VAL: "on", "off" */}
                                            <select onChange={this.addFilterSetting} className="adminMoreSelector" name="filterSetting" >
                                                <option value="off"> Av </option>
                                                <option value="on"> På </option>
                                            </select>
                                        </div>
                                        <div className={this.state.addCompType === "Dotterbolag" ? "hide" : ""}>
                                            <div style={{ display: "inline-block", fontWeight: 600 }}>Dölj/visa ålder i diagram</div> {/* Skrivs i company (showAgeChart) VAL: "on", "off" */}
                                            <select onChange={this.addAgeSetting} className="adminMoreSelector" name="ageSetting" >
                                                <option value="off"> Av </option>
                                                <option value="on"> På </option>
                                            </select>
                                        </div>
                                        <div style={{ display: "inline-block" }}>Ålder i dokumentation som default?</div> {/* Skrivs i company (showAgeChart) VAL: "on", "off" */}
                                        <select onChange={this.addAgeDocumentationDefault} className="adminMoreSelector" name="ageDocumentationDefault" style={{ marginLeft: 20 }}>
                                            <option value="expChart"> Nej </option>
                                            <option value="allCharts"> Ja </option>
                                        </select>
                                        <div className={this.state.addCompType === "Dotterbolag" ? "hide" : ""} style={{ borderTop: "1px dotted lightgrey" }}>
                                            <div style={{ display: "inline-block", fontWeight: 600 }}>Val av fler likavärdesgrupper</div> {/* Skrivs i company (eGSetting) VAL: 6, 9, 12 */}
                                            <select onChange={this.addEqualentGroupsSetting} className="adminMoreSelector" name="equalentGroupsSetting" style={{ marginLeft: 45, width: 78 }}>
                                                <option value="6"> Av (6) </option>
                                                <option value="9"> På (9)</option>
                                                <option value="12"> På (12)</option>
                                                <option value="15"> På (15)</option>
                                            </select>
                                        </div>
                                        <div style={{ display: "inline-block" }}>Antal likavärdesgrupper som default?</div> {/* Skrivs i company (showExtraSetting) VAL "on", "off"*/}
                                        <select onChange={this.addNrEqualentGroupDefault} className="adminMoreSelector" name="nrEqualentGroupDefault" style={{ marginLeft: 10 }}>
                                            <option value="6"> 6 </option>
                                            <option value="9"> 9 </option>
                                            <option value="12"> 12 </option>
                                            <option value="15"> 15 </option>
                                        </select>
                                        <div className={this.state.addCompType === "Dotterbolag" ? "hide" : ""} style={{ borderTop: "1px dotted lightgrey" }}>
                                            <div style={{ display: "inline-block", fontWeight: 600 }}>Dölj/visa tillägg i diagram</div> {/* Skrivs i company (showExtraSetting) VAL "on", "off"*/}
                                            <select onChange={this.addExtraSetting} className="adminMoreSelector" name="extraSetting" style={{ marginLeft: 85 }}>
                                                <option value="off"> Av </option>
                                                <option value="on"> På </option>
                                            </select>
                                        </div>

                                        <div className={this.state.addCompType === "Dotterbolag" ? "hide" : ""} style={{ borderTop: "1px dotted lightgrey" }}>
                                            <div style={{ display: "inline-block", fontWeight: 600 }}>Aktivera marknadslönedata</div>
                                            <select onChange={this.addSalaryBench} className="adminMoreSelector" name="salaryBenchSetting" style={{ marginLeft: 69 }}>
                                                <option value="off"> Av </option>
                                                <option value="on"> På </option>
                                            </select>
                                        </div>

                                        <div className={this.state.addCompType === "Dotterbolag" ? "hide" : ""} style={{ borderTop: "1px dotted lightgrey" }}>
                                            <div style={{ display: "inline-block", fontWeight: 600, width: 201, marginTop: 8 }}>Möjliggör anpassad värdering och/eller extern</div> {/* Skrivs i company (showExtraSetting) VAL "on", "off"*/}
                                            <select onChange={this.addShowExtValuation} className="adminMoreSelector" name="showExtValuation" style={{ marginLeft: 23, verticalAlign: "top", width: 92 }}>
                                                <option value="on"> Båda </option>
                                                <option value="edited"> Anpassad </option>
                                                <option value="extern"> Extern </option>
                                                <option value="off"> Av </option>
                                            </select>
                                            <div style={{ fontSize: 11, marginTop: 4 }}>Obs: "Val av fler likavärdesgrupper" ska vara 9 eller 12 ovan om det ska gå att ändra antal grupper i anpassad värdering.</div>
                                        </div>
                                        {/*<div style={{ fontStyle: "italic", marginTop: 15, fontSize: 11, borderTop: "1px solid lightgrey", paddingTop: 5 }}>Om inga inställningar ovan görs sätts ovan val som "off". Övriga inställningar såsom möjlighet att dölja medellöner och visa diagram i dokumentation sätts automatiskt som "on".</div>*/}
                                    </div>
                                    {/* End of extra settings for the company */}


                                    <button style={{ width: "100%", background: "linear-gradient(124deg,rgba(85, 203, 173, 1) 50%,rgba(85, 193, 203, 1) 100%)", boxShadow: 'none' }} className="login-button" onClick={this.newCompany}>Skapa bolag</button>
                                </div></>
                            : <>
                                {/* Add user */}
                                <h2 style={{ textAlign: "center", fontWeight: 800, fontSize: 19.6, marginBottom: 40, }}>Skapa ny användare</h2>
                                <div style={{ textAlign: "left", maxWidth: 350, margin: "auto", width: "100%", padding: 40, background: "white", borderRadius: 6, boxShadow: "0 3px 14px 0 rgb(0 0 0 / 6%)" }}>
                                    <input style={{ width: "100%", maxWidth: 335, paddingLeft: 15, textAlign: "left" }} className="form-input" value={this.state.addUserMail} type="email" placeholder="E-post" onChange={this.addUserMail} />
                                    <input style={{ width: "100%", maxWidth: 335, paddingLeft: 15, textAlign: "left" }} className="form-input" value={this.state.addUserPass} type="password" placeholder="Nytt lösenord" onChange={this.addUserPass} />
                                    <div style={{ paddingTop: 30, marginBottom: 7, fontSize: 15, fontWeight: 700 }}>Vilket företag tillhör användaren? <span className="questionPopUp" onClick={() => this.togglePopUpFile("selectMotherComp")}>?</span></div>
                                    <select onChange={this.addUserComp} className="selectorStyle" name="Bolagsform" >
                                        <option value={""}> Välj </option>
                                        {companiesList}
                                    </select>
                                    <div style={{ paddingTop: 30, marginBottom: 7, fontSize: 15, fontWeight: 700 }}>Roll: <span className="questionPopUp" onClick={() => this.togglePopUpFile("selectRole")}>?</span></div>
                                    <select onChange={this.addUserRole} className="selectorStyle" name="Bolagsform" >
                                        <option value={""}> Välj </option>
                                        <option value="Koncern"> Koncern </option>
                                        <option value="Bolag"> Bolag </option>
                                        <option value="Konsult"> Konsult </option>
                                    </select>
                                    {/* Later maybe create user automatically for bolag, koncern and konsult*/}
                                    <button style={{ width: "100%", background: "linear-gradient(124deg,rgba(85, 203, 173, 1) 50%,rgba(85, 193, 203, 1) 100%)", boxShadow: 'none' }} className="login-button" onClick={this.newUser}>Skapa användare</button>
                                </div></>}
                    </div>

                </div>
                <div style={{ zIndex: 3 }} className={this.state.popUp ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpEdit ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpUser ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpConsult ? "popUpFader" : "hide"}></div>
                <div className={this.state.showPopUpFile === null ? "hide" : "popUpFader"}></div>
            </>
        )
    }
}