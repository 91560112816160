import React from 'react';
import './CostumerAccounts.css';
import { TopNav } from '../TopNav.js';
import { fetchAuth } from '../../fetch-auth';
import Switch from "react-switch";
import { NavLink } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import exitImg from '../../assets/images/exit.png';
import edit from '../../assets/images/edit.png';
import lang from '../Language/language.js';

export class KoncernVy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section: "bolag",
            theUser: "",
            companies: [],
            usingDaughter: false,
            clickedCompany: '',
            activeCompany: '',
            acBounce: 'acStill',
            users: [],
            removeUser: "",
            removeCompany: "",
            mainCompanies: [],
            //For PopUps
            popUp: false,
            popUpUser: false,
            consultantUsers: [],
            popUpConsult: false,
        }
    }

    selectCompany = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const company = event.target.id
        const selectIt = async () => {
            //First check if the clicked one is the same as now.
            const parsedData = JSON.parse(company) //Get the clicked company and id
            const clickedCompany = parsedData.company
            const clickedId = parsedData.companyId

            if (clickedCompany === this.state.activeCompany) {
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            //If not the same, change it
            let response = await fetchAuth(`/api/r/selectCompanyKoncern`, 'POST', JSON.stringify({ data: company }));
            if (response.status === 200) {
                let data = await response.json();
                localStorage.setItem('localT', data.localToken)
                this.setState({ activeCompany: data.activeCompany })
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            if (response.status !== 200) {
                alert(language === "sv" ? 'Ett fel inträffade, prova igen.' : lang[language].somethingWrong)  
                return;
            }
        }
        selectIt();
    }

    back = () => {
        this.setState({ usingDaughter: false })
    }

    companyDelete = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        this.setState({ popUp: false });
        const dataset = this.state.removeCompany
        const parsedData = JSON.parse(dataset) //Get the clicked company and id
        const { company, companyId, type } = parsedData
        if (company === this.state.activeCompany) {
            alert(language === "sv" ? 'Du kan inte ta bort det företag du för närvarande representerar. Välj först ett annat företag och försök därefter igen.' : lang[language].cantDeleteOwnComp)
            return;
        } else {
            const removeCompany = async () => {
                let response = await fetchAuth(`/api/removeCompanyKoncern`, 'POST', JSON.stringify({ data: dataset }));
                if (response.status !== 200) {
                    alert(language === "sv" ?  "Något gick fel. Försök igen." : lang[language].somethingWrong)
                    return;
                } else if (response.status === 200) {
                    alert(language === "sv" ? `Bolaget "${company}" är borttaget.` : `${company} ${lang[language].isDeleted}`)
                    const companies = this.state.companies

                    let companiesUpd = companies.filter(c => c.dotterBolag != company)
                    this.setState({
                        companies: companiesUpd
                    })
                }
            }
            removeCompany();
            this.setState({ removeCompany: '' })
        }
    }

    togglePopUp = (event) => {
        if (this.state.popUp) {
            this.setState({ popUp: false, removeCompany: '' })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUp: true, removeCompany: dataset })
        }
    }

    togglePopUpUser = (event) => {
        if (this.state.popUpUser) {
            this.setState({ popUpUser: false, removeUser: '' })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUpUser: true, removeUser: dataset })
        }
    }

    togglePopUpConsult = (event) => {
        if (this.state.popUpConsult) {
            this.setState({ popUpConsult: false })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUpConsult: true, removeUser: dataset })
        }
    }

    removeConsultAccess = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const dataset = this.state.removeUser
        const parsedData = JSON.parse(dataset)
        const { user } = parsedData //Get user for removing from state
        const removeIt = async () => {
            let response = await fetchAuth(`/api/removeAccessKonsultKoncern`, 'POST', JSON.stringify({ data: dataset }));
            // let data = await response.json();
            if (response.status !== 200) {
                alert(language === "sv" ?  "Något gick fel. Försök igen." : lang[language].somethingWrong)
                return;
            } else if (response.status === 200) {
                alert(language === "sv" ? "Konsulten har inte längre access till ert bolag." : lang[language].consultantNoAccess)
                const consultantUsers = this.state.consultantUsers
                let updatedUserList = consultantUsers.filter(u => u.userId_PK != user)
                this.setState({ consultantUsers: updatedUserList, popUpConsult: false })
            }
        }
        removeIt();
        this.setState({ removeUser: '' })
    }

    userDelete = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        this.setState({ popUpUser: false });
        const dataset = this.state.removeUser
        const parsedData = JSON.parse(dataset) //Get the clicked company and id
        const { role, companyId, email, user } = parsedData
        if (role === "Admin") {
            alert(language === "sv" ? "Admin användare kan inte raderas." : lang[language].adminNotDelete)
            return;
        }
        let localT = localStorage.getItem('localT');
        let { name } = jwt_decode(localT);
        if (name == user) {
            alert(language === "sv" ? "Du kan inte radera din egen användare." : lang[language].cantDeleteOwn)
            return;
        } else {
            const removeIt = async () => {
                let response = await fetchAuth(`/api/userDeleteKoncern`, 'POST', JSON.stringify({ data: dataset }));
                // let data = await response.json();
                if (response.status !== 200) {
                    alert(language === "sv" ?  "Något gick fel. Försök igen." : lang[language].somethingWrong)
                } else if (response.status === 200) {
                    alert(language === "sv" ?  "Användare borttagen." : lang[language].userRemoved)
                    const userState = this.state.users
                    let updatedUserList = userState.filter(u => u.userId_PK != user)
                    this.setState({ users: updatedUserList })
                }
            }
            removeIt();
            this.setState({ removeUser: '' })
        }
    }


    componentDidMount() {
        const loadCompanies = async () => {
            let response = await fetchAuth(`/api/loadKoncern`, 'POST');
            let data = await response.json();
            let companies = data[0][0];
            let users = data[1][0]
            let consultantUsers = data[2][0]
            if (consultantUsers === undefined) {
                consultantUsers = []
            }
            let consultantCompany = data[3][0]

            //Filter the user from the users data and then get activeCompany
            let localT = localStorage.getItem('localT');
            let { name, aComp } = jwt_decode(localT);
            let theUser = users.filter(u => u.userId_PK === name)
            let daUser = theUser[0].email

            //Add users company to users list. A litte bit of calculation:)
            let mComp = companies.filter(c => c.type === "moder")
            let motherComp = mComp[0].dotterBolag
            for (let i = 0; i < users.length; i++) {
                if (users[i].role === "Bolag") {
                    users[i].belongTo = users[i].activeCompany
                } else if (users[i].role === "Koncern") {
                    users[i].belongTo = motherComp + " (Moderbolag)"
                }
            }
            //Add consultant company to consultant list
            if (consultantUsers.length > 0) {
                for (let i = 0; i < consultantUsers.length; i++) {
                    consultantUsers[i].belongTo = consultantCompany[0].company
                }
            }

            this.setState({ companies: companies, activeCompany: aComp, users: users, theUser: daUser, consultantUsers: consultantUsers })
        }
        loadCompanies();
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let companies = "Laddar företag.."

        if (this.state.companies.length > 0) {
            companies = this.state.companies.map((company) => {
                return <>
                    <div className="company">
                        <div className="removeCompany" onClick={this.togglePopUp} data-txt={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`} >x</div>
                        <span style={{ marginLeft: 38 }} className="koncernText">{company.type === "moder" ? "Moderbolag" : ""}</span>
                        <p
                            id={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}}`}
                            onClick={this.selectCompany}
                            className="companyText">{company.dotterBolag}</p>
                    </div>
                </>
            });
        }

        let users = ""

        if (this.state.users.length > 0) {
            users = this.state.users.map(user => {
                return <li className="liUsers">
                    <span className="liUserItem">{user.belongTo}</span>
                    <span className="liUserItem">{user.email}</span>
                    <span className="liUserItem">{user.role}</span>
                    <span onClick={this.togglePopUpUser} className="liUserItem liRemove" data-txt={`{"user": "${user.userId_PK}", "companyId": ${user.companyId_FK}, "role": "${user.role}", "email": "${user.email}"}`}>X</span>
                </li>
            });
        }

        let consultants = ""

        if (this.state.consultantUsers.length > 0) {
            consultants = this.state.consultantUsers.map(user => {
                return <li className="liUsers">
                    <span className="liUserItem">{user.belongTo}</span>
                    <span className="liUserItem">{user.email}</span>
                    <span className="liUserItem">{user.role}</span>
                    <span onClick={this.togglePopUpConsult} className="liUserItem liRemove" data-txt={`{"user": "${user.userId_PK}", "companyId": "${user.companyId_FK}"}`}>X</span>
                </li>
            });
        }

        return (
            <>
                <TopNav />
                {/*PopUp remove company */}
                <div className={this.state.popUp ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUp} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? 'Är du säker på att du vill ta bort detta bolag?' : lang[language].deleteComp}
                    <p style={{ fontWeight: "normal", fontSize: 15 }}>{language === "sv" ? "All data tillhörande detta bolag kommer tas bort. Du kan inte ångra dig sedan." : lang[language].deleteCompData}</p> 
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUp}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.companyDelete}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span>
                    </div>
                </div>
                {/*PopUp remove user */}
                <div className={this.state.popUpUser ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpUser} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? "Är du säker på att du vill ta bort denna användare?" : lang[language].sureDeleteUser}
                    <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Användaren tas bort men data som är skapat av användaren behålls. Ta även bort bolaget för att ta bort all data." : lang[language].deletedUserNotComp}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpUser}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.userDelete}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span>
                    </div>
                </div>
                {/*PopUp remove consult access */}
                <div className={this.state.popUpConsult ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpConsult} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}> {language === "sv" ? "Vill du ta bort konsultens access?" : lang[language].removeConsultantAccess}
                    <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Konsulten kommer inte längre komma åt ert konto. Du kan inte ångra ditt val." : lang[language].consultantNoAccessSelect}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpConsult}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.removeConsultAccess}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span>
                    </div>
                </div>
                {/*Other PopUps */}

                <NavLink exact to="/" className="goBack">{language === "sv" ? "Tillbaka" : lang[language].back}</NavLink> 
                <div className="login-page">
                    <h1 style={{ marginTop: 120 }}>{language === "sv" ? "Konto" : lang[language].account}</h1>
                    <div className="selectSectionContainer">
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopRightRadius: 0, borderBottomRightRadius: 0, }} className={this.state.section === "bolag" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "bolag" })}>{language === "sv" ? "Bolag" : lang[language].company}</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea", borderRadius: 0 }} className={this.state.section === "users" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "users" })}>{language === "sv" ? "Användare" : lang[language].users}</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className={this.state.section === "addNew" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "addNew" })}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</span>
                    </div>

                    {/* Create company */}
                    <div className={this.state.section === "newUser" ? "users-container" : "hideComp"} style={{ height: 480, display: "inline-block" }}>
                        <h2 style={{ fontWeight: 800, fontSize: 22, marginBottom: 40 }}>Lägg till företag</h2>
                        <div className="form-container" style={{ marginTop: 0, paddingTop: 40, height: "auto", paddingBottom: 40 }}>
                            <form className="login-form">
                                <input className="form-input" value={this.state.company} type="text" placeholder="Företag" onChange={this.company} />
                                <button style={{ marginTop: 20 }} className="login-button" onClick={this.createUser}>Lägg till</button>
                            </form>
                        </div>
                    </div>

                    {/* Show companies */}
                    <div className={this.state.section === "bolag" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}>{language === "sv" ? "Du företräder" : lang[language].youRepresent}:</p>
                        <h2 className={this.state.acBounce}>{this.state.activeCompany}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>{language === "sv" ? "Välj det bolag du vill företräda" :lang[language].chooseCompRepresent}</h2>
                        {this.state.usingDaughter === true ? <p style={{ marginTop: -30 }}><span className="backCompanyButton" onClick={this.back}>{language === "sv" ? "Tillbaka" : lang[language].back}</span>{language === "sv" ? "Visar alla bolag tillhörande: " : lang[language].showAllCompBelongingTo}<b>{this.state.clickedCompany}</b></p> : ""}
                        {companies}
                    </div>

                    {/* Show users and attached consultants */}
                    <div className={this.state.section === "users" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}>{language === "sv" ? "Din användare" : lang[language].yourUser}:</p>
                        <h2 className={this.state.acBounce}>{this.state.theUser}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        {/* Show users and consultants*/}
                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>{language === "sv" ? "Visar alla användare" : lang[language].allUsers}</h2>
                        <ul style={{ boxShadow: "0 3px 14px 0 rgb(0 0 0 / 6%)" }}>
                            <li className="liUsers liFilter">
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Företag" : lang[language].company}</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Email" : lang[language].email}</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Roll" : lang[language].role}</span>
                                <span style={{ background: "transparent" }} className="liUserItem liRemove">
                                    <img style={{ width: 20, position: "absolute", marginTop: -13, marginLeft: -10 }} src={edit} /></span>
                            </li>
                            {users}
                            {consultants}
                        </ul>
                    </div>

                    {/* Add users or company */}
                    <div className={this.state.section === "addNew" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b" }}>{language === "sv" ? "Behöver du skapa nytt bolag eller användare?" : lang[language].newUser}</p>
                        <a
                            className="goBack"
                            style={{ display: "block", position: "inherit", width: 110, margin: "auto", marginTop: 20 }}
                            href="mailto:kontakt@lonelys.se?subject=Lägga till användare/företag&body=Hej! Vi skulle vilja lägga till en ny användare / ett nytt företag. Kan ni återkomma till oss?">
                         {language === "sv" ? "Kontakta oss" : lang[language].contactUs}
                                </a>
                    </div>

                </div>
                <div style={{ zIndex: 3 }} className={this.state.popUp ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpUser ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpConsult ? "popUpFader" : "hide"}></div>
            </>
        )
    }
}