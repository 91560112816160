import React, { Component } from 'react';
import female from '../../assets/images/femalegender.png';
import male from '../../assets/images/malegender.png';
export class PercentageBar extends Component {
  render() {
    let firstWidth = this.props.percentage
    let secondWidth = 100 - this.props.percentage
    return (
      <div className="progress-bar-overview">
        <div className="progress-overview" style={{borderRight: firstWidth === 100 || secondWidth === 100 ? "none" : "2.5px white solid", width: `${this.props.percentage}%`, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderTopRightRadius: firstWidth === 100 ? 5 : 0, borderBottomRightRadius: firstWidth === 100 ? 5 : 0}}>
      <img src={female} width={16} className="progress-left-img" />
      <span className="firstpercentage-progress" style={{display: secondWidth === 100 ? "none" : "inline-block"}}>{this.props.percentage}%</span>
        </div>
        <div className="progress-overview" style={{borderRight: firstWidth === 100 || secondWidth === 100 ? "none" : "2.5px white solid", width: `${secondWidth}%`, borderTopRightRadius: 5, borderBottomRightRadius: 5, background: "#65c5b6", borderTopLeftRadius: firstWidth === 0 ? 5 : 0, borderBottomLeftRadius: firstWidth === 0 ? 5 : 0 }}>
        <img src={male} width={16} className="progress-right-img" />
        <span className="secondpercentage-progress" style={{display: firstWidth === 100 ? "none" : "inline-block"}}>{secondWidth}%</span>
        </div>
      </div>
    );
  }
}