import React from 'react';
import './editValuation.css';
import exitImg from '../../../assets/images/exit.png';
import editImg from '../../../assets/images/edit.png';
import trashIcon from '../../../assets/images/trashIcon.png';
import TextareaAutosize from 'react-textarea-autosize';
import { fetchAuth } from '../../../fetch-auth';
import lang from '../../Language/language.js';

export class EditValuation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whatSelection: "original",
            deleteItems: [],
            descPop: false,
            descId: "",
            instructions: "",
            focus: "",
            saved: false,
            nrOfEqGroups: 6,
            editedNrOfEqGroups: 6,
            weightTotal: 100,
            standardFactors: "off", // if it says "loaded" this means no factors has not been set before
            copyOpen: false,
            koncernCompanies: [],
            importFromWhatCompany: "",
            //show edited details
            showEditedDetails: false,
            //For edit descrptions
            isHoveringQuestion: false,
            activateSaveBtn: false,
            activateSaveBtnOriginal: false,
            activeSaveBtnExtern: false,
            isConcern: false,
            questionImport: false,
            groupInfoBoxPop: false,
            activeQuestion: "",
            nrOfLevels: 4,
            whatLevelDesc: null,
            showAddBtn: false,
            backupLevels: [],
            items: [
                {
                    new: "Yes",
                    id: 1,
                    category: 'Knowledge',
                    question: 'Erfarenhet & Utbildning',
                    levels: 4,
                    weight: 20,
                    instructions: "- Utbildning\n- Arbetslivserfarenhet",
                    focus: 'Sammanfattande bedömning över hur stora krav tjänsten ställer på utbildning och arbetslivserfarenhet.',
                    helper: "education",
                    level1: "Inga eller låga krav på utbildning och relevant arbetslivserfarenhet.",
                    level2: "Kräver eftergymnasial utbildning eller relevant yrkeserfarenhet.",
                    level3: "Kräver hög kunskap. Förvärvad genom hög utbildning eller medelhög utbildning med längre relevant arbetslivserfarenhet.",
                    level4: "Kräver mycket specialiserad eller bred kunskap. Förvärvard genom hög utbildning och lång relevant arbetslivserfarenhet.",
                },
                {
                    new: "Yes",
                    id: 2,
                    category: 'Knowledge',
                    question: 'Problemlösning',
                    levels: 4,
                    weight: 15,
                    instructions: "- Hantera uppkommande problem\n- Analysering\n- Kreativitet",
                    focus: "- Hur ofta problem förekommer och måste lösas\n- Självständigheten i arbetet. Finns det hjälp att tillgå?\n- Komplexiteten i uppgifterna\n- Om måste sätta sig in i nya områden till följd av snabb utveckling",
                    helper: "problemsolving",
                    level1: "Enkla och ofta rutinmässiga uppgifter där problemlösning förekommer i mindre utsträckning. För lösningar finns oftast kända tillvägagångssätt.",
                    level2: "Kräver viss analys och kreativitet. Komplicerade problem förekommer men ska diskuteras med överordnad innan beslut.",
                    level3: "Problemlösning vanligt förekommande. Självständig analys, nytänkande, utvärdering av alternativ inför beslut. Lösningar är sällan givna eller definierade.",
                    level4: "Problemlösning är stor del i arbetet. Varierade, komplexa problem. Kräver kreativa lösningar, ofta nya angreppssätt, kvalificerad kunskapsinhämtning och stor självständighet i beslut.",
                },
                {
                    new: "Yes",
                    id: 3,
                    category: 'Knowledge',
                    question: 'Sociala färdigheter',
                    levels: 4,
                    weight: 10,
                    instructions: "- Kommunikation\n- Samarbete\n- Service",
                    focus: "- Hur stor andel i arbetet som kräver sociala färdigheter\n- Om situationerna ofta är krävande\n- Om det ofta förekommer intressemotsättningar",
                    level1: "Inga krav på särskilda sociala färdigheter.",
                    level2: "Färdigheter i kommunikation och samarbete både internt och externt. Kontinuerliga dialoger. Kan innehålla intressemotsättningar.",
                    level3: "Kommunikation är en betydande del i arbetet. Ofta krävande situationer och intressemotsättningar. Kunna vägleda och argumentera.",
                    level4: "Sociala färdigheter avgörande för arbetet. Svåra och komplicerade kontakter med personer/grupper. Ofta stora intressemotsättningar eller svåra situationer.",
                    helper: "socialSkills"
                },
                {
                    new: "Yes",
                    id: 4,
                    category: 'Responsibility',
                    question: 'Personal',
                    levels: 4,
                    weight: 15,
                    instructions: "- Personalansvar\n- Arbetsledning",
                    focus: "- Antal anställda\n- Om en större del av arbetet upptas av arbetsledning\n- Självständigheten i ansvaret",
                    level1: "Har inget personalansvar eller ytterst få medarbetare där arbetsledning är en mycket liten del av arbetet.",
                    level2: "Ansvarar för en mindre grupp anställda. Arbetsledning är en viss del av arbetet.",
                    level3: "Ansvarar för ett betydande antal anställda. Arbetsledning är en stor del av arbetet.",
                    level4: "Övergripande chefsansvar för stora personalgrupper eller avdelningar. Ofta arbetsledning av andra chefer.",
                    helper: "personnel"
                },
                {
                    new: "Yes",
                    id: 5,
                    category: 'Responsibility',
                    question: 'Verksamhet',
                    levels: 4,
                    weight: 15,
                    instructions: "- Planering\n- Utveckling\n- Resultat",
                    focus: "- Om arbetet är av långsiktig betydelse för organisationen\n- Hur starkt resultatet påverkar organisationen\n- Självständigheten i ansvaret",
                    level1: "Riktlinjer skapat av någon annan finns att följa. Inget eller litet ansvar för planering, utveckling och resultat. Kan delta i planering för egna gruppen.",
                    level2: "Visst ansvar för planering, utveckling eller resultat. Ej nödvändigtvis arbetsledande funktion. Självständigt ansvar för arbetssätt, tidsplanering eller budget.",
                    level3: "Övergripande och strategiskt ansvar för planering, utveckling och resultat. Kan styra och förändra processer inom egna verksamhetsområdet.",
                    level4: "Övergripande affärsmässigt helhetsansvar för en/flera grupper eller verksamhetsområden. Utformar och påverkar varor/tjänster samt normer och riktlinjer som styr.",
                    helper: "operation"
                },
                {
                    new: "Yes",
                    id: 6,
                    category: 'Responsibility',
                    question: 'Människor',
                    levels: 4,
                    weight: 10,
                    instructions: "- Kunder\n- Patienter\n- Ansvar för andras hälsa eller säkerhet\n- Påverkan på människor genom beslut",
                    focus: "- Självständigheten i ansvaret\n- Hur mycket ansvaret för människor dominerar i arbetet\n- Hur starkt arbetet påverkar andra människor\n- Om känslig/konfidentiell information om människor tillhandahålls",
                    level1: "Ansvaret är begränsat till normal hänsyn och omtanke för andras välbefinnande.",
                    level2: "Ett visst ansvar för andras hälsa, utveckling eller säkerhet. Innebär ofta att påverka andra genom att inom ramen följa regler och anvisningar. Hantering av känslig information kan förekomma.",
                    level3: "Betydande ansvar/påverkan på andras hälsa, utveckling eller säkerhet. Självständiga beslut som påverkar andra människor är vanligt.",
                    level4: "Mycket stora krav att självständigt bedöma och fatta avgörande beslut som påverkar andras hälsa, utveckling eller säkerhet. Medför ofta stor påverkan på individer/grupper.",
                    helper: "people"
                },
                {
                    new: "Yes",
                    id: 7,
                    category: 'Effort',
                    question: 'Fysiska',
                    levels: 4,
                    weight: 5,
                    instructions: "- Tungt arbete\n- Repetitiva arbetsmoment\n- Obekväma arbetsställningar\n- Risk för skada",
                    focus: "- Hur påfrestande det fysiska arbetet är\n- Kombinationseffekter, till exempel om tungt arbete sker i kombination med obekväm arbetsställning\n- Långt repetetivt arbete kan vara lika fysiskt ansträngande som tunga moment under kortare perioder\n- Tillgång till hjälpmedel\n- Arbetstakt, hur snabbt det fysiska arbetet måste utföras",
                    level1: "Inga särskilda krav på fysisk ansträngning. Risk för skada är mycket liten.",
                    level2: "I perioder kan det krävas fysisk ansträning. Risk för skada är liten.",
                    level3: "Fysisk ansträngning sker dagligen. Långt repetetivt arbete eller tyngre moment under kortare perioder. Viss risk för skada.",
                    level4: "Fysisk ansträngning är en stor del av arbetet. Obekväma arbetställningar förekommer. Stor risk för skada.",
                    helper: "physical"
                },
                {
                    new: "Yes",
                    id: 8,
                    category: 'Effort',
                    question: 'Psykiska',
                    levels: 4,
                    weight: 10,
                    instructions: "- Känslomässig ansträngning \n- Enformighet \n- Tillgänglighet \n- Stress",
                    focus: "- Hur påfrestande den psykiska ansträngningen är \n- Sker ofta möte med arga, sjuka eller svårhanterliga människor? \n- Krav på tillgänglighet, tex utanför ordinarie arbetstid eller att andra uppgifter ofta blir lidande \n- Hårt styrt och enformigt arbete som kräver noggran koncentration kan vara mycket påfrestande \n- Hur stor andel arbetsbelastning och stress",
                    level1: "Låga krav på psykisk ansträngning. Psykiskt påfrestande situationer inträffar sällan.",
                    level2: "Psykiskt påfrestande situationer inträffar ibland. Hög arbetsbelastning och tidspress förekommer i perioder. ",
                    level3: "Psykisk och känslomässig ansträngning förekommer ofta.",
                    level4: "Mycket höga krav på psykisk och känslomässig ansträngning över tid. Höga krav på tillgänglighet, koncentration, tidspress och påfrestande relationer.",
                    helper: "psychological"
                },
            ]
        }
    }

    // radioSelected = event => this.setState({ whatSelection: event.target.id })
    changeValuation = event => this.setState({ whatSelection: event.target.value, showEditedDetails: true, activateSaveBtn: true, activateSaveBtnOriginal: true, activeSaveBtnExtern: true })

    componentDidMount() {

        const setLanguageForFactors = async () => {
            return new Promise(resolve => {
              let language = localStorage.getItem('language') ?? 'sv';
              if (language !== "sv") {
                this.setState({ items: lang[language].originalFactorsForEdited }, resolve);
              } else {
                resolve(); // Resolve immediately if language is "sv"
              }
            });
          }

        //load Questions/factors
        const loadIt = async () => {
            let response = await fetchAuth(`/api/loadFactors`, 'POST');
            let data = await response.json();
            let koncernCompanies = data[2]
            let item = []
            let nrOfGroups = ""
            let nrOfEqualGroups = 6
            let editedNrOfEqualGroups = 6
            let whatLevelDesc = null
            if (data[0][0] !== undefined || data[0][0] !== null) { item = data[0][0] }
            if (data[1][0] !== undefined || data[1][0] !== null) { nrOfGroups = data[1][0][0] }
            if (nrOfGroups.nrOfEqualGroups !== undefined || nrOfGroups.nrOfEqualGroups !== null) { nrOfEqualGroups = nrOfGroups.nrOfEqualGroups }
            if (nrOfGroups.editedNrOfEqualGroups !== undefined || nrOfGroups.editedNrOfEqualGroups !== null) { editedNrOfEqualGroups = nrOfGroups.editedNrOfEqualGroups }
            if (nrOfGroups.whatLevelDesc !== undefined) { whatLevelDesc = nrOfGroups.whatLevelDesc }

            //First, copy the levels of all standard factors (not the ones we retrieved) and save to state, this is used for "lagg till" button
            let levels = []
                  // Below will be executed once the state has been updated              
            for (let a of this.state.items) {
                let q = {
                    question: a.question,
                    helper: a.helper,
                    level1: a.level1,
                    level2: a.level2,
                    level3: a.level3,
                    level4: a.level4,
                }
                levels.push(q)
            }
            this.setState({ backupLevels: levels, whatSelection: this.props.whatEvaluation })

            // Check if there are any factors, if not, use the standard ones
            if (item.length === 0) {
                // Get info about weight for Ordinary
                let response = await fetchAuth(`/api/loadStandardFactors`, 'POST');
                let data = await response.json();
                let standardWeight = data[0][0][0]
                //  let standardResults = data[1][0]
                //Create standard questions and add Weight loaded from standard factors
                item = this.state.items.slice()

                for (let i = 0; i < item.length; i++) {
                    const obj = item[i];
                    const key = obj.helper;
                    if (standardWeight.hasOwnProperty(key)) {
                        obj.weight = standardWeight[key];
                    }
                }
                if (whatLevelDesc === null || whatLevelDesc === "oldStandard") {
                    for (let a of item) {
                        a.level1 = null
                        a.level2 = null
                        a.level3 = null
                        a.level4 = null
                    }
                }
                this.setState({ whatLevelDesc: whatLevelDesc, items: item, nrOfEqGroups: nrOfEqualGroups, editedNrOfEqGroups: nrOfEqualGroups, standardFactors: "Loaded", koncernCompanies: koncernCompanies })
            } else {
                this.setState({ whatLevelDesc: whatLevelDesc, items: item, nrOfEqGroups: nrOfEqualGroups, editedNrOfEqGroups: editedNrOfEqualGroups, koncernCompanies: koncernCompanies })
            }
        };
        setLanguageForFactors().then(() => { loadIt() });
    }

    changeQuestion = (event) => {
        let id = event.target.id
        let cleanId = id.replace(/question/g, "")
        let newQuestion = event.target.value
        let arrayCopy = this.state.items.slice()
        var updatedArray = arrayCopy.map(el => {
            if (el.id == cleanId)
                return Object.assign({}, el, { question: newQuestion })
            return el
        });
        this.setState({ items: updatedArray, activateSaveBtn: true })
    }
    changeWeight = (event) => {
        let id = event.target.id
        let cleanId = id.replace(/weight/g, "")
        let newWeight = event.target.value
        if (Number(newWeight) > 99 || Number(newWeight) < 1) {
            newWeight = ""
        }
        //First change in object
        let arrayCopy = this.state.items.slice()
        var updatedArray = arrayCopy.map(el => {
            if (el.id == cleanId)
                return Object.assign({}, el, { weight: newWeight })
            return el
        });
        //sum weight and set to state
        let allWeights = []
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].weight !== "") { allWeights.push(updatedArray[i].weight) }
        }
        let sumWeights = allWeights.reduce((pv, cv) => pv + Number(cv), 0);
        this.setState({ items: updatedArray, weightTotal: sumWeights, activateSaveBtn: true })
    }
    changeLevel = (event) => {
        let id = event.target.id
        let cleanId = id.replace(/level/g, "")
        let newLevel = event.target.value
        let arrayCopy = this.state.items.slice()
        var updatedArray = arrayCopy.map(el => {
            if (el.id == cleanId)
                return Object.assign({}, el, { levels: newLevel })
            return el
        });
        this.setState({ items: updatedArray, activateSaveBtn: true })
    }

    deleteItem = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        let id = event.target.id
        let cleanId = id.replace(/remove/g, "")
        let arrayCopy = this.state.items.slice()
        let deleteArrayCopy = this.state.deleteItems.slice()

        //First, check if there is less than 2 items for knowledge, responsibility or 1 for effort, if so, do not remove
        let nrOfKnowledge = arrayCopy.filter(el => el.category === "Knowledge").length
        let nrOfResponsibility = arrayCopy.filter(el => el.category === "Responsibility").length
        let nrOfEffort = arrayCopy.filter(el => el.category === "Effort").length

        var loopIt = arrayCopy.map(el => {
            if (el.id == cleanId) {
                if (el.category === "Knowledge" && nrOfKnowledge < 3) {
                    alert(language === "sv" ? "Ej tillåtet. Minst 2 faktorer måste finnas inom Kunskaper & Färdigheter." : lang[language].skillsMinimum)
                    return el
                }
                if (el.category === "Responsibility" && nrOfResponsibility < 3) {
                    alert(language === "sv" ? "Ej tillåtet. Minst 2 faktorer måste finnas inom Ansvar." : lang[language].skillsResponsibilites)
                    return el
                }
                if (el.category === "Effort" && nrOfEffort < 2) {
                    alert(language === "sv" ? "Ej tillåtet. Minst 1 faktor måste finnas inom Ansträngning & arbetsförhållanden." : lang[language].skillsEffort)
                    return el
                }
                else if (el.new === undefined) { //if new, we do not need to add it to deleteArray, just remove it.
                    deleteArrayCopy.push(el.id)
                    return null;
                }
                else { return null; }
            }
            return el
        });
        let updatedArray = loopIt.filter(item => item !== null);

        //sum weight and set to state
        let allWeights = []
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].weight !== "") { allWeights.push(updatedArray[i].weight) }
        }
        let sumWeights = allWeights.reduce((pv, cv) => pv + Number(cv), 0);
        this.setState({ items: updatedArray, deleteItems: deleteArrayCopy, weightTotal: sumWeights, activateSaveBtn: true })
    }
    addItem = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        let category = event.target.id
        let arrayCopy = this.state.items.slice()
        let deleteArray = this.state.deleteItems.slice()
        let ids = []
        for (let i = 0; i < arrayCopy.length; i++) {
            ids.push(arrayCopy[i].id)
        }
        let checkMax = deleteArray.length > 0 ? ids.concat(deleteArray) : ids
        var highestNumber = Math.max(...checkMax);
        let newItem = {
            new: "Yes",
            id: highestNumber + 1,
            question: "",
            category: category,
            levels: 4,
            weight: 10,
            instructions: language === "sv" ? "Ange beskrivning." : lang[language].addDescription, 
            focus: language === "sv" ? "Ange att ha i beaktande." : lang[language].addConsid, 
        }
        arrayCopy.push(newItem)
        //sum weight and set to state
        let allWeights = []
        for (let i = 0; i < arrayCopy.length; i++) {
            if (arrayCopy[i].weight !== "") { allWeights.push(arrayCopy[i].weight) }
        }
        let sumWeights = allWeights.reduce((pv, cv) => pv + Number(cv), 0);
        this.setState({ items: arrayCopy, weightTotal: sumWeights, activateSaveBtn: true })
    }

    descPop = (event) => {
        if (!this.state.descPop) { //load data
            let id = event.currentTarget.id
            let cleanId = id.replace(/desc/g, "")
            let arrayCopy = this.state.items.slice()
            let activeItemArray = arrayCopy.filter(item => item.id == cleanId)
            let activeItem = activeItemArray[0]
            let showAddBtn = false
            if (
                (this.state.whatLevelDesc === null || this.state.whatLevelDesc === "oldStandard" || this.state.whatLevelDesc === "standard") &&
                (activeItem.level1 === null || activeItem.level1 === "") &&
                (activeItem.level2 === null || activeItem.level2 === "") &&
                (activeItem.level3 === null || activeItem.level3 === "") &&
                (activeItem.level4 === null || activeItem.level4 === "") &&
                (activeItem.level5 === null || activeItem.level5 === "" || activeItem.level5 === undefined) &&
                (activeItem.level6 === null || activeItem.level6 === "" || activeItem.level6 === undefined) &&
                (activeItem.level7 === null || activeItem.level7 === "" || activeItem.level7 === undefined)
            ) {
                showAddBtn = true
            }
            //Here if "first" we dont have anything saved and should therefore always check for original levels
            if ((this.state.whatLevelDesc === "first") && (activeItem.level1 === "" || activeItem.level1 === null) && (activeItem.level2 === "" || activeItem.level2 === null) && (activeItem.level3 === "" || activeItem.level3 === null) && (activeItem.level4 === "" || activeItem.level4 === null) && (activeItem.level5 === "" || activeItem.level5 === null) && (activeItem.level6 === "" || activeItem.level6 === null) && (activeItem.level7 === "" || activeItem.level7 === null)) {
                let question = activeItem.question
                let backupLevels = this.state.backupLevels
                for (let a of backupLevels) {
                    if (a.question === question) {
                        this.setState({
                            level1: a.level1,
                            level2: a.level2,
                            level3: a.level3,
                            level4: a.level4,
                            level5: a.level5,
                            level6: a.level6,
                            level7: a.level7,
                            activeQuestion: activeItem.question,
                            showAddBtn: showAddBtn,
                            descPop: true,
                            descId: cleanId,
                            instructions: activeItem.instructions,
                            focus: activeItem.focus,
                            nrOfLevels: activeItem.levels
                        })
                        return;
                    }
                }
            }
            this.setState({ showAddBtn: showAddBtn, descPop: true, activeQuestion: activeItem.question, descId: cleanId, instructions: activeItem.instructions, focus: activeItem.focus, level1: activeItem.level1, level2: activeItem.level2, level3: activeItem.level3, level4: activeItem.level4, level5: activeItem.level5, level6: activeItem.level6, level7: activeItem.level7, nrOfLevels: activeItem.levels })
        } else { //save data
            let id = this.state.descId
            let arrayCopy = this.state.items.slice()
            var updatedArray = arrayCopy.map(el => {
                if (el.id == id)
                    return Object.assign({}, el, { instructions: this.state.instructions, focus: this.state.focus, level1: this.state.level1, level2: this.state.level2, level3: this.state.level3, level4: this.state.level4, level5: this.state.level5, level6: this.state.level6, level7: this.state.level7 })
                return el
            });
            this.setState({ items: updatedArray, descPop: false, descId: "" })
            this.setState({ instructions: "", focus: "" })
        }
    }

    instructions = (event) => {
        let whatText = event.target.id
        let textInfo = event.target.value
        this.setState({ [whatText]: textInfo, activateSaveBtn: true })
    }
    clickedQuestion = () => { this.state.isHoveringQuestion ? this.setState({ isHoveringQuestion: false }) : this.setState({ isHoveringQuestion: true }) }
    addBtnClicked = () => {
        //check if the question are the same, if so, add the default levels
        let question = this.state.activeQuestion
        let backupLevels = this.state.backupLevels
        let items = this.state.items.slice()
        for (let a of backupLevels) {
            if (a.question === question) {
                for (let b of items) {
                    if (a.question === b.question) {
                        b.level1 = a.level1
                        b.level2 = a.level2
                        b.level3 = a.level3
                        b.level4 = a.level4
                        b.level5 = a.level5
                        b.level6 = a.level6
                        b.level7 = a.level7
                        this.setState({
                            level1: a.level1,
                            level2: a.level2,
                            level3: a.level3,
                            level4: a.level4,
                            level5: a.level5,
                            level6: a.level6,
                            level7: a.level7
                        })
                    }
                }
                break;
            }
        }
        this.setState({ items: items, showAddBtn: false, activateSaveBtn: true })
    }

    saveIt = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            const items = {
                items: this.state.items,
                deleteItems: this.state.deleteItems,
                whatSelection: this.state.whatSelection,
                nrOfEqGroups: this.state.nrOfEqGroups,
                editedNrOfEqGroups: this.state.editedNrOfEqGroups,
                standardFactors: this.state.standardFactors,
                whatLevelDesc: this.state.whatLevelDesc
            }
            //Make sure that there is no empty fields
            for (let i = 0; i < this.state.items.length; i++) {
                if (this.state.items[i].weight === "" || this.state.weightTotal !== 100) {
                    alert(language === "sv" ? "Kan ej spara. Viktningen måste vara 100%. Ändra viktningen och spara sedan på nytt." : lang[language].cantSaveWeight) 
                    return;
                }
                if (this.state.items[i].question === "" || this.state.items[i].question === " " || this.state.items[i].question === undefined) {
                    alert(language === "sv" ? "Kan ej spara. Fältet för faktornamn är tomt. Ange text eller ta bort faktorn och spara sedan på nytt." : lang[language].cantSaveFactor) 
                    return;
                }
            }
            let response = await fetchAuth(`/api/saveFactors`, 'POST', JSON.stringify({ data: items }));
            let data = await response.json();
            data === "Saved" ? this.setState({ activateSaveBtn: false }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
        };
        saveData();
    }

    saveOriginal = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const data = {
            nrOfEqualentGroups: this.state.nrOfEqGroups
        }
        const saveData = async () => {
            let response = await fetchAuth(`/api/nrOfEqualentGroups`, 'POST', JSON.stringify({ data: data }));
            response.status === 200 ? this.setState({ activateSaveBtnOriginal: false }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
        }
        saveData();
    }

    handleChangeNrOfGroups = (event) => { this.setState({ nrOfEqGroups: event.target.value, activateSaveBtnOriginal: true }) }
    handleChangeNrOfGroupsEdited = (event) => { this.setState({ editedNrOfEqGroups: event.target.value, activateSaveBtn: true }) }
    toggleCopy = () => {
        if (this.state.copyOpen) {
            this.setState({ copyOpen: false })
        } else this.setState({ copyOpen: true })
    }
    questionImport = () => { this.state.questionImport ? this.setState({ questionImport: false }) : this.setState({ questionImport: true }) }

    importFactors = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let fromWhatCompany = this.state.importFromWhatCompany
        if (fromWhatCompany === "" || fromWhatCompany === undefined) {
            return alert(language === "sv" ? "Ingen organisation vald. Välj först den organisation vars faktorplan ska importeras, klicka därefter på importera igen." : lang[language].noOrgSelected) 
        }
        else {
            const data = { fromWhatCompany: fromWhatCompany }

            const result = window.confirm(language === "sv" ? "OBS: Befintliga värderingspoäng kommer raderas för de faktorer som inte har samma namn eller nivå. Vill du fortsätta?" : lang[language].obsImportVal); 

            if (!result) {
                // User clicked "Abort"
                return;
            } else {
                // User clicked "Continue"
                const importData = async () => {
                    let response = await fetchAuth(`/api/importFactors`, 'POST', JSON.stringify({ data: data }));
                    let gotData = await response.json();
                    let factors = gotData[0][0]
                    let editedNrOfEqGroups = gotData[1]
                    if (editedNrOfEqGroups === undefined || editedNrOfEqGroups === null) { editedNrOfEqGroups = this.state.editedNrOfEqGroups }
                    if (factors.length < 1) {
                        return alert(language === "sv" ? "Vald organisation hade ingen anpassad faktorplan." : lang[language].orgNoValPlan) 
                    } else if (gotData === "Error occurred") {
                        return alert(language === "sv" ? "Något gick fel. Prova att spara igen" : lang[language].somethingWrong)
                    } else {
                        this.setState({ items: factors, editedNrOfEqGroups: editedNrOfEqGroups })
                        return alert(language === "sv" ? "Faktorplan importerad." : lang[language].importedFplan)
                    }
                }
                importData();
            }
        }
    }

    importSelection = (event) => { this.setState({ importFromWhatCompany: event.target.value }) }
    showEditedDetails = () => { this.setState({ showEditedDetails: true }) }
    groupInfoBoxPop = () => { this.state.groupInfoBoxPop ? this.setState({ groupInfoBoxPop: false }) : this.setState({ groupInfoBoxPop: true }) }
    saveExtern = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            const data = {
                whatEvaluation: this.state.whatSelection
            }
            const response = await fetchAuth(`/api/saveExtArbetsvarderingSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong) 
            } else if (response.status === 200) {
                this.setState({ activeSaveBtnExtern: false })
            }
        }
        saveData();
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        {/* PopUp */ }
        let popUp = <></>
        if (this.state.descPop) {
            popUp = <div className="popUp active-popUp" style={{
                fontSize: 13.6, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginTop: "unset",
                right: "unset",
            }}>
                <div className="top-btn-sticky">
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.descPop} />
                </div>
                <div style={{ position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden", minHeight: 239 }}>
                    <div className="textblock-popup-left" style={{ height: "auto", width: 355, paddingLeft: 30, background: "#f8f8f8", borderRight: "1px solid #dddddd", paddingBottom: 30 }}>
                        <div>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginLeft: -13, marginTop: 0 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                            <TextareaAutosize value={this.state.instructions} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="instructions" />
                        </div>
                        <div>
                            <h4 style={{ marginTop: 24, fontSize: 15.6, marginBottom: 16, marginLeft: -13 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                            <TextareaAutosize value={this.state.focus} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="focus" />

                        </div>
                    </div>
                    <div className="textblock-popup-right" style={{ width: 345, height: "auto", paddingBottom: 80 }}>

                        <div style={{ width: 385 }}>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginTop: 0 }}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</h4>
                            {this.state.showAddBtn ?
                                <div>
                                    <span>{language === "sv" ? "Här kan ni lägga till mer detaljerade nivåbeskrivningar." : lang[language].infoDetailedLevelDesc}</span>
                                    <div className="edit-levels-small-btn" onClick={this.addBtnClicked}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</div><span onClick={this.clickedQuestion} className="infoBoxEditQuestionBox">?</span>
                                    <div className={this.state.isHoveringQuestion ? "infoBoxEditLevels" : "hide"}>{language === "sv" ? "Detaljerade nivåbeskrivningar kan hjälpa till att konkretisera arbetsvärderingen och göra det enklare att förstå vad varje nivå innebär." : lang[language].infoDeailLevels}</div>
                                </div>
                                :
                                <>
                                    <div className="popUp-list testar"><span className="aNumber">1</span><TextareaAutosize style={{ width: 300 }} value={this.state.level1} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level1" /></div>
                                    <div className="popUp-list testar"><span className="aNumber">2</span><TextareaAutosize style={{ width: 300 }} value={this.state.level2} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level2" /></div>
                                    {this.state.nrOfLevels > 2 && <div className="popUp-list testar"><span className="aNumber">3</span><TextareaAutosize style={{ width: 300 }} value={this.state.level3} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level3" /></div>}
                                    {this.state.nrOfLevels > 3 && <div className="popUp-list testar"><span className="aNumber">4</span><TextareaAutosize style={{ width: 300 }} value={this.state.level4} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level4" /></div>}
                                    {this.state.nrOfLevels > 4 && <div className="popUp-list testar"><span className="aNumber">5</span><TextareaAutosize style={{ width: 300 }} value={this.state.level5} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level5" /></div>}
                                    {this.state.nrOfLevels > 5 && <div className="popUp-list testar"><span className="aNumber">6</span><TextareaAutosize style={{ width: 300 }} value={this.state.level6} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level6" /></div>}
                                    {this.state.nrOfLevels > 6 && <div className="popUp-list testar"><span className="aNumber">7</span><TextareaAutosize style={{ width: 300 }} value={this.state.level7} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level7" /></div>}
                                </>
                            }
                        </div>
                    </div>

                </div>
            </div>
        }
        {/* End of PopUp */ }

        let editValuation = <div style={{ textAlign: "left", fontSize: 14.6 }}>
            {popUp}
            <div className={this.state.descPop ? "editValShader" : "hide"}></div>

            <div>

                {/* <div>
                    <div style={{ marginBottom: 5 }}><span className="editVarStep">1</span><span className="editVarStepInfo">Välj vilken arbetsvärdering ni vill använda</span></div>
                    <div style={{ display: "inline-block", marginRight: 20, marginLeft: 29 }}><input onClick={this.radioSelected} type="radio" id="original" name="original" className={this.state.whatSelection === "original" ? "chartSelected" : "radioChart"} />
                        <label className="checkBoxTypeLabel" for="original" style={{ marginLeft: 5 }} checked={this.state.whatSelection === "original"} >Original </label></div>
                    <div style={{ display: "inline-block" }}><input className={this.state.whatSelection === "edited" ? "chartSelected" : "radioChart"} onClick={this.radioSelected} type="radio" id="edited" name="edited" />
                        <label className="checkBoxTypeLabel" for="edited" style={{ marginLeft: 5 }} checked={this.state.whatSelection === "edited"}>Anpassad</label></div>
    </div> */}

                <div style={{ textAlign: "center" }}>
                    <select value={this.state.whatSelection} onChange={this.changeValuation} name="whatSelection" id="whatSelection">
                        <option value="original">Standard</option>
                        {(this.props.showHideExtValuation === "on" || this.props.showHideExtValuation === "edited") &&
                            <option value="edited">{language === "sv" ? "Anpassad" : lang[language].customized}</option>
                        }
                        {(this.props.showHideExtValuation === "on" || this.props.showHideExtValuation === "extern") &&
                            <option value="extLevel">{language === "sv" ? "Extern" : lang[language].extern}</option>
                        }
                    </select>
                </div>

                {/*<div style={{ marginTop: 30 }}><span className="editVarStep">2</span><span className="editVarStepInfo">Gör justeringar</span></div>*/}

                {/* <div className="editVarOverallSetting">Känslighet/Poängavgränsning: 20p</div> */}


                {this.state.whatSelection === "edited" ?
                    <>
                        <div onClick={this.showEditedDetails} className={!this.state.showEditedDetails ? "showSettingBtn" : "hide"}>{language === "sv" ? "Inställningar" : lang[language].settings}</div>

                        <div className={this.state.showEditedDetails ? "editVarContainer" : "hide"}>

                            <div className={this.state.isConcern ? "" : ""} style={{ marginBottom: 20 }}>
                                {/* <div className="importBtnEditValuation" >Importera</div> */}

                                <div style={{ marginBottom: 0, textAlign: language === "sv" ? "left" : "center"  }} className={!this.state.copyOpen ? "copyButton importBtnEditValuation" : "copyButton openCopyCont"}><span onClick={this.toggleCopy} style={{ paddingTop: 20, paddingBottom: 10, marginLeft: -4, paddingLeft: 10, fontSize: 14.6}}>{language === "sv" ? "Importera" : lang[language].groupImport}</span>
                                    <select onChange={this.importSelection} value={this.state.importFromWhatCompany} className={!this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany" style={{ fontSize: 14.6 }}>
                                        <option value=""> {language === "sv" ? "Importera ifrån.." : lang[language].importFrom}</option>
                                        {this.state.koncernCompanies.map(item =>
                                            <option disabled={item.whatEvaluation !== "edited"} value={item.dotterBolag}>{item.whatEvaluation !== "edited" ? item.dotterBolag + " - Har ingen anpassad faktorplan" : item.dotterBolag}</option>
                                        )}
                                    </select>
                                    <span onClick={this.importFactors} className={!this.state.copyOpen ? "hiddenButton" : "showButton"} style={{ fontSize: 14.6 }}>{language === "sv" ? "Importera" : lang[language].groupImport}</span>
                                    <span onClick={this.toggleCopy} className={!this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 10 }}>x</span>
                                </div>
                                {/* <div class="editVarRightInfo" style={{width: "70%", maxWidth: 450}}>Importera en anpassad arbetsvärdering som ni skapat i Lönelys åt ett annat bolag.</div>*/}
                                <span onClick={this.questionImport} className="questionM" style={{ background: "white", marginTop: 6, border: "1.5px solid", fontWeight: 800, fontSize: 14, lineHeight: 1.3 }}>?</span>
                                <div style={{ background: "#f1f1f1" }} className={this.state.questionImport ? "chartPercentInfoBox" : "hide"}>
                                {language === "sv" ? "Om ni har skapat en anpassad faktorplan i Lönelys för ett annat bolag inom samma koncern och vill använda samma faktorplan kan ni importera den här. Välj vilket bolag ni vill kopiera ifrån och klicka sedan importera." : lang[language].importPlanInfo}
                                </div>
                            </div>
                            {this.props.eGSetting > 6 ? <div className="editVarOverallSetting">
                                <div className="editVarSettingTitle">{language === "sv" ? "Antal grupper " : lang[language].nrOfGroups}</div>
                                <select value={this.state.editedNrOfEqGroups} onChange={this.handleChangeNrOfGroupsEdited} style={{ marginLeft: 0, display: "inline-block", verticalAlign: "top", marginRight: 15, padding: 7, borderRadius: 4, fontSize: 14 }} name="editedNrOfEqGroups" id="editedNrOfEqGroups">
                                    <option value={6}>6</option>
                                    <option value={9}>9</option>
                                    {this.props.eGSetting > 9 && <option value={12}>12</option>}
                                    {this.props.eGSetting > 12 && <option value={15}>15</option>}
                                </select>
                                <div className="editVarRightInfo">{language === "sv" ? "Justera antal grupper för likvärdiga arbeten. Ju fler grupper desto känsligare värdering." : lang[language].nrOfGroupsInfo}</div>
                            </div> : <></>}
                            <div className='editVarsmallHeaders'>
                                <div className="editVarInline" style={{ width: 200 }}>{language === "sv" ? "Faktornamn" : lang[language].factorName}</div>
                                <div className="editVarInline" style={{ width: 50 }}>{language === "sv" ? "Nivåer" : lang[language].levels}</div>
                                <div className="editVarInline" style={{ width: 47 }}>{language === "sv" ? "Viktning" : lang[language].weightning}</div>
                                <div className="editVarInline" style={{ marginRight: 0 }}>{language === "sv" ? "Beskrivning" : lang[language].description}</div>
                            </div>
                            <div style={{ display: "inline-block", width: 250 }} >
                                <div className="editVarTitle">{language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp}</div>
                                <div className="editVarAddBtn" id="Knowledge" onClick={this.addItem}>+</div>
                            </div>
                            <div style={{ width: 42, display: "inline-block" }} />
                            <div style={{ display: "inline-block", width: 47, textAlign: "center", padding: 5 }} className={this.state.weightTotal === 100 ? "" : "red-weight"}>{this.state.weightTotal}%</div>
                            <ul>
                                {this.state.items.map(item => {
                                    if (item.category === "Knowledge") {
                                        return <li className="editVarList" >
                                            <input type="text" id={"question" + item.id} value={item.question} className="editVarInline" style={{ width: 200 }} onChange={this.changeQuestion} />
                                            <select onChange={this.changeLevel} className="editVarSelection" name="editVarSelection" id={"level" + item.id} style={{ width: 60, height: 35, border: "1px solid #cbcbcb" }}>
                                                {['2', '3', '4', '5', '6', '7'].map(optionValue => (
                                                    <option value={optionValue} selected={item.levels == optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                            <input value={item.weight} id={"weight" + item.id} type="number" className="editVarInline input-vardering" style={{ width: 47, textAlign: "center" }} onChange={this.changeWeight} />
                                            <div className="editVarInline editVarLink" onClick={this.descPop} id={"desc" + item.id}><img className="editVarIcon" src={editImg} /> {language === "sv" ? "Ange" : lang[language].personBenefitAdd}</div>
                                            <div style={{ display: "inline-block" }}><img id={"remove" + item.id} onClick={this.deleteItem} src={trashIcon} className="editVarTrashcan" /></div>
                                        </li>
                                    }
                                })
                                }
                            </ul>
                            {/* <div className="editVarAddBtn"><span style={{ marginRight: 5}}>+</span>Skapa</div>*/}
                            <div className="editVarTitle">{language === "sv" ? "Ansvar" : lang[language].setResp}</div>
                            <div className="editVarAddBtn" id="Responsibility" onClick={this.addItem}>+</div>
                            <ul>
                                {this.state.items.map(item => {
                                    if (item.category === "Responsibility") {
                                        return <li className="editVarList" >
                                            <input type="text" id={"question" + item.id} value={item.question} className="editVarInline" style={{ width: 200 }} onChange={this.changeQuestion} />
                                            <select onChange={this.changeLevel} className="editVarSelection" name="editVarSelection" id={"level" + item.id} style={{ width: 60, height: 35, border: "1px solid #cbcbcb" }}>
                                                {['2', '3', '4', '5', '6', '7'].map(optionValue => (
                                                    <option value={optionValue} selected={item.levels == optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                            <input value={item.weight} id={"weight" + item.id} type="number" className="editVarInline input-vardering" style={{ width: 47, textAlign: "center" }} onChange={this.changeWeight} />
                                            <div className="editVarInline editVarLink" onClick={this.descPop} id={"desc" + item.id}><img className="editVarIcon" src={editImg} /> {language === "sv" ? "Ange" : lang[language].personBenefitAdd}</div>
                                            <div style={{ display: "inline-block" }}><img id={"remove" + item.id} onClick={this.deleteItem} src={trashIcon} className="editVarTrashcan" /></div>
                                        </li>
                                    }
                                })
                                }
                            </ul>
                            <div className="editVarTitle">{language === "sv" ? "Ansträngning & arbetsförhållanden" : lang[language].setEffort}</div>
                            <div className="editVarAddBtn" id="Effort" onClick={this.addItem}>+</div>
                            <ul>
                                {this.state.items.map(item => {
                                    if (item.category === "Effort") {
                                        return <li className="editVarList" >
                                            <input type="text" id={"question" + item.id} value={item.question} className="editVarInline" style={{ width: 200 }} onChange={this.changeQuestion} />
                                            <select onChange={this.changeLevel} className="editVarSelection" name="editVarSelection" id={"level" + item.id} style={{ width: 60, height: 35, border: "1px solid #cbcbcb" }}>
                                                {['2', '3', '4', '5', '6', '7'].map(optionValue => (
                                                    <option value={optionValue} selected={item.levels == optionValue}>{optionValue}</option>
                                                ))}
                                            </select>
                                            <input value={item.weight} id={"weight" + item.id} type="number" className="editVarInline input-vardering" style={{ width: 47, textAlign: "center" }} onChange={this.changeWeight} />
                                            <div className="editVarInline editVarLink" onClick={this.descPop} id={"desc" + item.id}><img className="editVarIcon" src={editImg} /> {language === "sv" ? "Ange" : lang[language].personBenefitAdd}</div>
                                            <div style={{ display: "inline-block" }}><img id={"remove" + item.id} onClick={this.deleteItem} src={trashIcon} className="editVarTrashcan" /></div>
                                        </li>
                                    }
                                })
                                }
                            </ul>
                        </div></> : this.state.whatSelection === "extLevel" ? <></> :
                        //For original
                        <div className="editVarContainer" style={{ width: 370, background: "white", border: "none", borderTop: "1px solid #dddddd", borderRadius: 0, paddingBottom: 0 }}>
                            {this.props.eGSetting > 6 ? <div className="editVarOverallSetting" style={{ borderBottom: "none", paddingBottom: 0 }}>
                                <div className="editVarSettingTitle" style={{ fontSize: 14 }}>{language === "sv" ? "Antal grupper " : lang[language].nrOfGroups}<span onClick={this.groupInfoBoxPop} style={{ textAlign: "center", fontStyle: "normal", color: "#333333" }} className="checkBoxQuestion">?</span></div>
                                <div className={this.state.groupInfoBoxPop ? "chartPercentInfoBox" : "hide"} style={{ width: 472, marginLeft: -65, marginBottom: 20 }}>
                                {language === "sv" ? "Att öka antalet grupper innebär att färre arbeten kommer att klassas som likvärdiga. För stora organisationer med många lika arbeten kan ett större antal grupper ge en mer rättvis analys. Det är viktigt att vara konsekvent och inte justera från år till år." : lang[language].nrOfGroupsDetails}
                                </div>
                                <select value={this.state.nrOfEqGroups} onChange={this.handleChangeNrOfGroups} style={{ marginLeft: 0, display: "inline-block", verticalAlign: "top", marginRight: 15, padding: 7, borderRadius: 4, fontSize: 14 }} name="nrOfEqGroups" id="nrOfEqGroups">
                                    <option value={6}>6</option>
                                    <option value={9}>9</option>
                                    {this.props.eGSetting > 9 && <option value={12}>12</option>}
                                    {this.props.eGSetting > 12 && <option value={15}>15</option>}
                                </select>
                                <div className="editVarRightInfo" style={{ width: 285 }}>{language === "sv" ? "Justera antal grupper för likvärdiga arbeten. Ju fler grupper desto känsligare värdering." : lang[language].nrOfGroupsInfo}</div>
                            </div> : <></>}
                            <div className="informationBox">
                                <span className="informationMarkImg">i</span><span style={{ display: "inline-block", width: "92%", verticalAlign: "top" }}>{language === "sv" ? "Viktning och beskrivningar kan vid behov justeras direkt i arbetsvärderingen under steg 2.1." : lang[language].nrOfGroupsDetailsBox}</span>
                            </div>
                        </div>
                }
            </div>
            <div style={{ textAlign: "center" }} className={!this.state.showEditedDetails && this.state.whatSelection === "edited" ? "hide" : ""}>
                {this.state.whatSelection === "edited" ?
                    <div className={this.state.activateSaveBtn ? "settingsButton" : "settingsButton inactive-konto-button"} style={{ display: "inline-block", textAlign: "center", marginTop: 20 }} onClick={this.saveIt}>{language === "sv" ? "Spara" : lang[language].save}</div> :
                    this.state.whatSelection === "extLevel" ?
                        <div className={this.state.activeSaveBtnExtern ? "settingsButton" : "settingsButton inactive-konto-button"} style={{ display: "inline-block", textAlign: "center", marginTop: 20 }} onClick={this.saveExtern}>{language === "sv" ? "Spara" : lang[language].save}</div>
                        :
                        <div className={this.state.activateSaveBtnOriginal ? "settingsButton" : "settingsButton inactive-konto-button"} style={{ display: "inline-block", textAlign: "center", marginTop: 20 }} onClick={this.saveOriginal}>{language === "sv" ? "Spara" : lang[language].save}</div>
                }
            </div>
        </div>
        return (
            editValuation
        )
    }
}