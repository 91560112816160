import React from 'react';
import { TopNav } from '../TopNav.js';
import { fetchAuth } from '../../fetch-auth';
import { NavLink } from 'react-router-dom';
import Switch from "react-switch";
import './Settings.css';
import extraExample from '../../assets/images/extraExample.jpg';
import exitImg from '../../assets/images/exit.png';
import { EditValuation } from '../Arbetsvardera/EditValuation/editValuation.js';
import { withRouter } from 'react-router-dom';
import lang from '../Language/language.js';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //For twoFactor
            twoFactorOn: "",
            changeMade: false,
            QRCode: "",
            factorCode: "",
            secret: "",
            twoFactorFirstTime: true,
            twoFactorPop: false,
            //For equalent groups
            equalentPop: false,
            equalGroup: 6,
            changedEqualentGroup: false,
            eGSetting: "loading",
            //For chart
            diagramOn: "",
            changeMadeDiagram: false,
            showChartSetting: "off",
            chartPCheckbox: "off",
            chartCheckboxInfo: false,
            //For hiding salaries in documentation
            hideSalaryOn: "",
            changeMadeSalary: false,
            showSalarySetting: "off",
            //For warning message
            warningOn: "on",
            changeMadeWarning: false,
            //For show ageChart
            showAgeChart: "off",
            changeMadeAgeChart: false,
            //For documentation radio buttons
            selectedRadio: "expChart",
            //For Extra/tillagg function
            showHideExtraIcon: "adminOn",
            changeMadeExtra: false,
            //For filter function
            showHideFilterIcon: "adminOff",
            changeMadeFilter: false,
            //For import settings
            showHideImportSetting: "adminOff",
            changeMadeImportSetting: false,
            //For salaray bench
            showHideSalBenchSetting: "adminOff",
            changeMadeSalBenchSetting: false,
             //For automatic analysis / AI
             showHideAI: "adminOff",
             changeMadeAI: false,
            //for addOn setting:
            addOnPop: false,
            addOnDocoOn: "",
            changeMadeAddOn: false,
            selectedRadioAddOn: "noExtraChart",
            hideExamplePop: true,
            //For ext valuation
            showHideExtValuation: "loading",
            whatEvaluation: "loading",
            changeMadeValuation: false,
            whatFiltered: "All",
            fromDoco: false, // Telling us if settings was from documentation page or not
            fromJobEvaluation: false, // Telling us if settings was from jobevaluation page or not
            //For language
            language: "sv",
            changeMadeLang: false,
            // For EU setting
            showEU: "off",
            settingEU: "off",
            changeMadeEU: false,
        }
        this.factorCode = this.factorCode.bind(this);
        this.sendCode = this.sendCode.bind(this);
    }
    componentDidMount() { this.getData(); }

    getData = () => {
        //First set language
        this.setState({ language: localStorage.getItem('language') || 'sv' }) // Defaults to swedish if not set
        //Check if we are calling this setting component from documentation, if so, add whatFiltered to "Doco"
        const { location } = this.props;
        const previousComponent = location.state && location.state.from.component;
        if (previousComponent === "Dokumentera") { this.setState({ whatFiltered: "Doco", fromDoco: true }) }
        if (previousComponent === "Arbetsvardera") { this.setState({ whatFiltered: "Val", fromJobEvaluation: true }) }
        //First get warning setting from localStorage
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ warningOn: "off" }) }
        //Then get hide salary setting from localstorage
        let hideSalary = localStorage.getItem('docoHideSalary');
        if (hideSalary === "on") { this.setState({ hideSalaryOn: "on" }) }
        //Then get EU setting
        let settingEU = localStorage.getItem('settingEU');
        if (settingEU === "on") { this.setState({ settingEU: "on" }) }
        //Then load the rest from db
        const loadData = async () => {
            const response = await fetchAuth(`/api/twoFactorStatus`, 'POST');
            let data = await response.json();
            //first set nrOfEqualentGroup and equalent groups setting (if should be shown or not)
            this.setState({ equalGroup: data.nrOfEqualentGroups, eGSetting: data.eGSetting })
            //then set showChart and chartSetting and chart percent
            this.setState({ showChartSetting: data.showChartSetting, diagramOn: data.chartSetting, chartPCheckbox: data.chartPercent })
            let chartRadio = ""
            data.selectedChart == undefined || data.selectedChart == null ? chartRadio = "expChart" : chartRadio = data.selectedChart
            this.setState({ selectedRadio: chartRadio })
            //then set hideSalary setting 
            this.setState({ showSalarySetting: data.showHideSalarySetting })
            //then set showAgeChart setting
            this.setState({ showAgeChart: data.showAgeChart })
            //then set showExtraSetting
            this.setState({ showHideExtraIcon: data.showExtraSetting })
            //then set showFilterSetting
            this.setState({ showHideFilterIcon: data.showFilterSetting })
            //then set showImportSetting
            this.setState({ showHideImportSetting: data.showImportSetting })
            //then set addOnSettings
            this.setState({ addOnDocoOn: data.addOnDetails })
            if (data.chartSetting === "on") { this.setState({ selectedRadioAddOn: data.addOnChart }) }
            //then set extValuationSetting 
            this.setState({ showHideExtValuation: data.showExtValuation })
            //then set whatEvaluation
            this.setState({ whatEvaluation: data.whatEvaluation })
            //then set showSalBench
            this.setState({ showHideSalBenchSetting: data.showSalBench })
            // then set showEU
            this.setState({showEU: data.showEU})
               // then set showEU
               this.setState({showHideAI: data.showAI})
            //then set twoFactor settings
            if (data.twoFactorOn === null || data.twoFactorOn === undefined || data.twoFactorOn === "") {
                this.setState({ twoFactorOn: "data.twoFactorOn" })
            } else { this.setState({ twoFactorOn: data.twoFactorOn }) }
            if (data.twoFactor === null || data.twoFactor === undefined || data.twoFactor === "") {
                this.setState({ twoFactorFirstTime: true })
            } else {
                this.setState({ twoFactorFirstTime: false })
            }
        }
        loadData();
    }

    twoFactorSwitch = () => { if (this.state.twoFactorOn !== "on") { this.setState({ twoFactorOn: "on", changeMade: true }) } else { this.setState({ twoFactorOn: "", changeMade: true }) } }
    diagramSwitch = () => { if (this.state.diagramOn !== "on") { this.setState({ diagramOn: "on", changeMadeDiagram: true }) } else { this.setState({ diagramOn: "", changeMadeDiagram: true, selectedRadioAddOn: "noExtraChart" }) } }
    warningSwitch = () => { if (this.state.warningOn !== "on") { this.setState({ warningOn: "on", changeMadeWarning: true }) } else { this.setState({ warningOn: "off", changeMadeWarning: true }) } }
    salarySwitch = () => { if (this.state.hideSalaryOn !== "on") { this.setState({ hideSalaryOn: "on", changeMadeSalary: true }) } else { this.setState({ hideSalaryOn: "off", changeMadeSalary: true }) } }
    addOnDocoSwitch = () => { if (this.state.addOnDocoOn !== "on") { this.setState({ addOnDocoOn: "on", changeMadeAddOn: true }) } else { this.setState({ addOnDocoOn: "off", changeMadeAddOn: true }) } }
    ageChartSwitch = () => { if (this.state.showAgeChart !== "adminOn") { this.setState({ showAgeChart: "adminOn", changeMadeAgeChart: true }) } else { this.setState({ showAgeChart: "adminOff", changeMadeAgeChart: true }) } }
    showEUSwitch = () => { this.state.settingEU === "on" ? this.setState({settingEU: "off", changeMadeEU: true}) : this.setState({settingEU: "on", changeMadeEU: true}) }
    checkBoxChange = () => { if (this.state.chartPCheckbox !== "on" && this.state.showSalarySetting === "on") { this.setState({ chartPCheckbox: "on", changeMadeDiagram: true }) } else { this.setState({ chartPCheckbox: "off", changeMadeDiagram: true }) } }
    chartCheckboxInfo = () => { if (this.state.chartCheckboxInfo) { this.setState({ chartCheckboxInfo: false }) } else { this.setState({ chartCheckboxInfo: true }) } }
    radioSelected = event => this.setState({ selectedRadio: event.target.id, changeMadeDiagram: true })
    radioSelectedAddOn = event => this.setState({ selectedRadioAddOn: event.target.id, changeMadeAddOn: true })
    extraFunctionSwitch = () => { if (this.state.showHideExtraIcon !== "adminOn") { this.setState({ showHideExtraIcon: "adminOn", changeMadeExtra: true }) } else { this.setState({ showHideExtraIcon: "adminOff", changeMadeExtra: true }) } }
    importSettingSwitch = () => { if (this.state.showHideImportSetting !== "adminOn") { this.setState({ showHideImportSetting: "adminOn", changeMadeImportSetting: true }) } else { this.setState({ showHideImportSetting: "adminOff", changeMadeImportSetting: true }) } }
    salBenchSwitch = () => { if (this.state.showHideSalBenchSetting !== "adminOn") { this.setState({ showHideSalBenchSetting: "adminOn", changeMadeSalBenchSetting: true }) } else { this.setState({ showHideSalBenchSetting: "adminOff", changeMadeSalBenchSetting: true }) } }
    filterFunctionSwitch = () => { if (this.state.showHideFilterIcon !== "adminOn") { this.setState({ showHideFilterIcon: "adminOn", changeMadeFilter: true }) } else { this.setState({ showHideFilterIcon: "adminOff", changeMadeFilter: true }) } }
    autoAnalysisSwitch = () => { if (this.state.showHideAI !== "adminOn") { this.setState({ showHideAI: "adminOn", changeMadeAI: true }) } else { this.setState({ showHideAI: "adminOff", changeMadeAI: true }) } }
   
    saveDiagramStatus = () => {
        const data = {
            chartSetting: this.state.diagramOn,
            chartPercent: this.state.chartPCheckbox,
            selectedChart: this.state.selectedRadio
        }
        const saveChartSetting = async () => {
            const response = await fetchAuth(`/api/saveChartSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status === 200) {
                this.setState({ changeMadeDiagram: false })
                return;
            } else {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
                return;
            }
        }
        saveChartSetting();
    }

    saveAddOnStatus = () => {
        let selectedExtraChart = this.state.diagramOn === "on" ? this.state.selectedRadioAddOn : "noExtraChart" //Makes sure we cant save an extra chart if charts as general is off
        const data = {
            addOnSetting: this.state.addOnDocoOn,
            selectedExtraChart: selectedExtraChart
        }
        const saveAddOnSetting = async () => {
            const response = await fetchAuth(`/api/saveAddOnSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status === 200) {
                this.setState({ changeMadeAddOn: false })
                return;
            } else {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
                return;
            }
        }
        saveAddOnSetting();
    }

    saveWarningStatus = () => {
        localStorage.setItem('warningPrompt', this.state.warningOn);
        this.setState({ changeMadeWarning: false })
    }

    saveSalaryStatus = () => {
        localStorage.setItem('docoHideSalary', this.state.hideSalaryOn);
        this.setState({ changeMadeSalary: false })
    }
    saveEUStatus = () => {
        localStorage.setItem('settingEU', this.state.settingEU);
        this.setState({ changeMadeEU: false })
    }

    saveChartYearStatus = () => {
        const saveData = async () => {
            const data = {
                ageChartStatus: this.state.showAgeChart
            }
            const response = await fetchAuth(`/api/saveAgeChartSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeAgeChart: false })
            }
        }
        saveData();
    }

    saveExtraSetting = () => {
        const saveData = async () => {
            const data = {
                extraSetting: this.state.showHideExtraIcon
            }
            const response = await fetchAuth(`/api/saveExtraSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeExtra: false })
            }
        }
        saveData();
    }

    saveFilterSetting = () => {
        const saveData = async () => {
            const data = {
                filterSetting: this.state.showHideFilterIcon
            }
            const response = await fetchAuth(`/api/saveFilterSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeFilter: false })
            }
        }
        saveData();
    }

    saveImportSetting = () => {
        const saveData = async () => {
            const data = {
                importSetting: this.state.showHideImportSetting
            }
            const response = await fetchAuth(`/api/saveImportSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeImportSetting: false })
            }
        }
        saveData();
    }

    saveSalBenchSetting = () => {
        const saveData = async () => {
            const data = {
                salBenchSetting: this.state.showHideSalBenchSetting
            }
            const response = await fetchAuth(`/api/saveSalaryBenchSetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeSalBenchSetting: false })
            }
        }
        saveData();
    }

    saveAISetting = () => {
        const saveData = async () => {
            const data = {
                AISetting: this.state.showHideAI
            }
            const response = await fetchAuth(`/api/saveAISetting`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMadeAI: false })
            }
        }
        saveData();
    }

    saveTwoFactor = () => {
        const saveData = async () => {
            const data = {
                factorStatus: this.state.twoFactorOn
            }
            const response = await fetchAuth(`/api/saveTwoFactorStatus`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
            } else if (response.status === 200) {
                this.setState({ changeMade: false })
            }
        }
        saveData();
    }

    factorCode(event) { this.setState({ factorCode: event.target.value }) }

    sendCode() {
        let language = localStorage.getItem('language') ?? 'sv';
        const data = {
            factorCode: this.state.factorCode,
            secret: this.state.secret,
        }
        const fetchIt = async () => {
            const response = await fetchAuth(`/api/twoFactorCreate`, 'POST', JSON.stringify({ data: data }));

            if (response.status === 200) {
                this.setState({ twoFactorFirstTime: false, changeMade: false, twoFactorPop: false })
                alert(language === "sv" ? "Tvåstegsverifiering aktiverat!" : lang[language].twoFactorActivated)
                return;
            } else if (response.status === 403) { alert(language === "sv" ? "Fel kod." : lang[language].wrongCode) }
            else {
                alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
            }
        }
        fetchIt();

    }

    twoFactorPop = () => {
        this.setState({ twoFactorPop: true })
        //Load QR code and more here
        const loadFieldData = async () => {
            const res = await fetchAuth(`/api/twoFactor`, 'POST');
            //If no previous secret
            if (res.status === 201) {
                let data = await res.json();
                this.setState({ QRCode: data.QRCode, secret: data.secret })
                return;
            } else {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Ett fel uppstod med aktivering av tvåstegsverifiering." : lang[language].errorTwoFactor)
                return;
            }
        }
        loadFieldData();
    }

    twoFactorPopClose = () => { this.setState({ twoFactorPop: false, twoFactorFirstTime: true }) }
    equalentPopOpen = () => { if (this.state.equalentPop) { this.setState({ equalentPop: false }) } else { this.setState({ equalentPop: true }) } }
    addOnPopToggle = () => { this.state.addOnPop ? this.setState({ addOnPop: false }) : this.setState({ addOnPop: true }) }
    changeEqualGroup = (event) => {
        let equalGroup = event.target.value
        this.setState({ equalGroup: equalGroup, changedEqualentGroup: true })
        //localStorage.setItem("year", year);
        //  window.location.reload();}
    }
    hideExamplePop = () => { this.setState({ hideExamplePop: true }) }
    showExamplePop = () => { this.setState({ hideExamplePop: false }) }

    saveEqualentGroup = () => {
        //Save setting to DB here
        const data = {
            nrOfEqualentGroups: this.state.equalGroup
        }
        const fetchIt = async () => {
            const response = await fetchAuth(`/api/nrOfEqualentGroups`, 'POST', JSON.stringify({ data: data }));

            if (response.status === 200) {
                this.setState({ changedEqualentGroup: false })
                return;
            } else {
                let language = localStorage.getItem('language') ?? 'sv';
                alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
                return;
            }
        }
        fetchIt();
    }
    changeWhatFiltered = (event) => { this.setState({ whatFiltered: event.target.id }) }
    changeLanguage = (event) => { this.setState({ language: event.target.value, changeMadeLang: true }) }
    saveLanguage = () => {
        // Save to database
        const data = { language: this.state.language }
        const fetchIt = async () => {
            const response = await fetchAuth(`/api/saveLanguage`, 'POST', JSON.stringify({ data: data }));
            if (response.status === 200) {
                // Save to localStorage
                localStorage.setItem('language', this.state.language); this.setState({ changeMadeLang: false });
                //Reload page
                alert(this.state.language === "sv" ? "Observera att text skriven av användare ej översätts." : lang[this.state.language].textChangePop)
                window.location.reload();
                return;
            }
            else { alert(this.state.language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[this.state.language].errorOccuredTryAgain) }
        }
        fetchIt();
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <div style={{ paddingTop: 79, paddingBottom: 80 }}>
                <TopNav />
                <NavLink exact to={this.state.fromDoco ? "dokumentera" : this.state.fromJobEvaluation ? "gruppera/arbetsvardera" : "/"} className="goBack">{language === "sv" ? "Tillbaka" : lang[language].back}</NavLink>
                <h1 style={{ marginTop: 40 }}>{language === "sv" ? "Inställningar" : lang[language].settingTitle}</h1>
                <div style={{ marginBottom: 0, marginTop: 40 }}>
                    <div id="All" onClick={this.changeWhatFiltered} className={this.state.whatFiltered === "All" ? "filterButtonsSettings filterButtonSettingsActive" : "filterButtonsSettings"}>{language === "sv" ? "Alla" : lang[language].all}</div>
                    <div id="Doco" onClick={this.changeWhatFiltered} className={this.state.whatFiltered === "Doco" ? "filterButtonsSettings filterButtonSettingsActive" : "filterButtonsSettings"}>{language === "sv" ? "Dokumentation" : lang[language].report}</div>
                    {this.state.showHideExtValuation === "on" || this.state.showHideExtValuation === "edited" || this.state.showHideExtValuation === "extern" ? <div id="Val" onClick={this.changeWhatFiltered} className={this.state.whatFiltered === "Val" ? "filterButtonsSettings filterButtonSettingsActive" : "filterButtonsSettings"}>{language === "sv" ? "Arbetsvärdering" : lang[language].jobEvaluation}</div> : <></>}
                    <div id="Rest" onClick={this.changeWhatFiltered} className={this.state.whatFiltered === "Rest" ? "filterButtonsSettings filterButtonSettingsActive" : "filterButtonsSettings"}>{language === "sv" ? "Övriga" : lang[language].other}</div>
                </div>
                {/* Pop up Verification*/}
                <div className={this.state.twoFactorPop ? "form-container popUp2Factor" : "hide"}>
                    <div onClick={this.twoFactorPopClose} style={{ position: "absolute", marginTop: -55, fontSize: 20, right: 0, marginRight: 15, cursor: "pointer" }}>x</div>
                    <h1 style={{ marginTop: -20, fontSize: 18 }}>{language === "sv" ? "Koppla tvåstegsverifiering" : lang[language].enableTwoStep}</h1>
                    <img style={{ width: 150 }} src={this.state.QRCode} />
                    <div style={{ marginTop: 10 }}>
                        <input type="Number" placeholder={language === "sv" ? "Ange kod" : lang[language].addCode} id="hideArrows" onChange={this.factorCode} value={this.state.factorCode} />
                        <div className="verifyButton" onClick={this.sendCode}>{language === "sv" ? "Verifiera" : lang[language].verify}</div>
                    </div>
                    <div>
                        <p style={{ textDecoration: "underline", marginBottom: 0, marginTop: 39, paddingTop: 10 }}>{language === "sv" ? "Gör såhär:" : lang[language].followSteps}</p>
                        <p className="verifyText"><span className="verifyStep">1</span>{language === "sv" ? `Ladda ner appen "Google Authenticator" på din mobiltelefon och scanna QR koden ovan.` : lang[language].downloadApp}</p>
                        <p className="verifyText" style={{ paddingBottom: 25 }}><span className="verifyStep">2</span>{language === "sv" ? "Ange den kod som du ser på appen." : lang[language].enterCode}</p>
                    </div>
                </div>
                {/* End of PopUp*/}
                {/* Two Step Verification */}
                <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Tvåstegsverifiering" : lang[language].twoStep}</p>
                        <p className="settingsDescription">{language === "sv" ? `Med tvåstegsverifiering lägger du till ett extra skydd ifall lösenordet skulle hamna i orätta händer.
                            Du får vid inlogg ange en extra kod som är knuten till din mobil genom appen "Google Authenticator".` : lang[language].twoStepInfo}
                        </p>
                        <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.twoFactorSwitch} checked={this.state.twoFactorOn === "on"} />
                        {this.state.changeMade ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.state.twoFactorFirstTime ? this.twoFactorPop : this.saveTwoFactor}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >{language === "sv" ? "Spara" : lang[language].saved}</div>}
                    </div>
                </div>

                {/* Show/Hide Warning message */}
                <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Varningsmeddelande" : lang[language].warningPop}</p>
                        <p className="settingsDescription">{language === "sv" ? "Visar varningsmeddelande om ni försöker byta sida utan att ha sparat ert arbete." : lang[language].warningInfo}
                        </p>
                        <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.warningSwitch} checked={this.state.warningOn === "on"} />
                        {this.state.changeMadeWarning ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveWarningStatus}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                    </div>
                </div>

                {/* Language */}
                 <div className={this.state.whatFiltered === "Doco" ? "hide" : this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Språk" : lang[language].language}</p>
                        <p className="settingsDescription">{language === "sv" ? "Välj det språk som ska användas." : lang[language].languageInfo}
                        </p>
                        <select style={{ padding: 7, borderRadius: 4, fontSize: 14 }} value={this.state.language} onChange={this.changeLanguage}>
                            <option value="sv">{language === "sv" ? "Svenska" : lang[language].swedish}</option>
                            <option value="en">{language === "sv" ? "Engelska" : lang[language].english}</option>
                        </select>
                        {this.state.changeMadeLang ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveLanguage}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                    </div>
        </div>

                {/* Show chart in doco */}
                {this.state.showChartSetting === "on" ? <div className={this.state.whatFiltered === "Rest" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Punktdiagram i dokumentation" : lang[language].scatterPlot}</p>
                        <p className="settingsDescription">{language === "sv" ? "Visa punktdiagram i dokumentationen för lika arbeten." : lang[language].scatterPlotInfo}
                        </p>
                        <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.diagramSwitch} checked={this.state.diagramOn === "on"} />
                        <div className={this.state.diagramOn === "on" && this.state.showSalarySetting === "on" ? "" : "hide"}>
                            <input className={this.state.chartPCheckbox !== "on" ? "checkBoxType checkBoxEmpty" : "checkBoxType"} type="checkbox" id="ChartPCheckbox" name="ChartPCheckbox" onChange={this.checkBoxChange} checked={this.state.chartPCheckbox === "on"} />
                            <label className="checkBoxTypeLabel" for="ChartPCheckbox">{language === "sv" ? "Dölj löner i y-axel" : lang[language].hideYSalary}</label><span className="checkBoxQuestion" onClick={this.chartCheckboxInfo}>?</span>
                            <div className={this.state.chartCheckboxInfo ? "chartPercentInfoBox" : "hide"}>{language === "sv" ? "Y-axeln visas normalt i kronor. Det är det tydligaste sättet att visa löneskillnaderna. Om detta upplevs som känslig information kan ni istället välja att visa y-axeln i procent. Procenten visar hur stor andel av den totala lönen i gruppen som individerna besitter. Detta ger samma visuella bild över var löneskillnaderna finns, men visar inte hur mycket lön det handlar om." : lang[language].hideYSalaryInfo}</div>
                        </div>

                        <div className={this.state.diagramOn === "on" && (this.state.showAgeChart === "adminOn" || this.state.showAgeChart === "on") ? "selectChartTypes" : "hide"}>
                            <div><input type="radio" id="allCharts" name="allCharts" onChange={this.radioSelected} className={this.state.selectedRadio === "allCharts" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "allCharts"} />
                                <label className="checkBoxTypeLabel" for="allCharts">{language === "sv" ? "Visa alla" : lang[language].showAll}</label></div>
                            <div><input type="radio" id="ageChart" name="ageChart" onChange={this.radioSelected} className={this.state.selectedRadio === "ageChart" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "ageChart"} />
                                <label className="checkBoxTypeLabel" for="ageChart">{language === "sv" ? "Visa ålder" : lang[language].showAge}</label></div>
                            <div><input type="radio" id="expChart" name="expChart" onChange={this.radioSelected} className={this.state.selectedRadio === "expChart" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "expChart"} />
                                <label className="checkBoxTypeLabel" for="expChart">{language === "sv" ? "Visa år i org." : lang[language].showOrg}</label></div>

                        </div>

                        <div className={this.state.diagramOn !== "on" && this.state.showSalarySetting === "on" ? "chartPercentInfoBox" : "hide"}>
                            {language === "sv" ? "Vi rekommenderar punktdiagram i dokumentationen för en tydligare redovisning." : lang[language].diagramRec}
                        </div>
                        {this.state.changeMadeDiagram ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveDiagramStatus}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                    </div>
                </div> : <></>}

                {/* Hide Salary in doco */}
                {this.state.showSalarySetting === "on" ? <div className={this.state.whatFiltered === "Rest" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Dölj medellöner i dokumentation" : lang[language].hideAvgSalaries}</p>
                        <p className="settingsDescription">{language === "sv" ? "Dölj medellöner för alla grupper i dokumentationen. Skillnader visas istället i procent." : lang[language].hideAvgSalariesInfo}
                        </p>
                        <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.salarySwitch} checked={this.state.hideSalaryOn === "on"} />
                        {this.state.changeMadeSalary ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveSalaryStatus}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                    </div>
                </div> : <></>}

                {/* Make addOn more clear in doco */}
                {this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" || this.state.showHideExtraIcon === "adminOff" ? <div className={this.state.whatFiltered === "Rest" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                    <div className="settingsBox" style={{ paddingBottom: 25 }}>
                        <p className="settingsTitle">{language === "sv" ? "Förtydliga tillägg i dokumentation" : lang[language].clarifyBenefits}</p>
                        <p className="settingsDescription">{language === "sv" ? "Redovisa tillägg & förmåner tydligare i dokumentationen." : lang[language].benefitsInfo}<span className="checkBoxQuestion" onClick={this.addOnPopToggle}>?</span>
                        </p>
                        <div className={this.state.hideExamplePop ? "hide" : "popUpExample"}>
                            <img src={exitImg} style={{ float: "right", marginBottom: 10, opacity: 0.5, width: 25, cursor: "pointer" }} onClick={this.hideExamplePop} />
                            <img style={{ width: 915, borderRadius: 5 }} src={extraExample} />
                        </div>
                        <div style={{ marginTop: -10, marginBottom: 15 }} className={this.state.addOnPop ? "chartPercentInfoBox" : "hide"}>
                            {language === "sv" ? "Istället för enbart lön inklusive tillägg visas fler rader med lön inklusive tillägg, lön exklusive tillägg, enbart tillägg samt med eller utan extra diagram. Ger en tydligare redovisning av tillägg och förmåner, nackdelen är en något mer komplex rapport. " : lang[language].benefitsInfoTwo}<span className="linkDownload" style={{ display: "inline" }} onClick={this.showExamplePop}>{language === "sv" ? "Se exempel" : lang[language].benefitsExample}</span>
                        </div>
                        <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.addOnDocoSwitch} checked={this.state.addOnDocoOn === "on"} />
                        <div className={this.state.addOnDocoOn === "on" ? "" : "hide"}>
                            <div className="selectChartTypes" style={{ width: language === "sv" ? 168 : 172 }}>
                                <div style={{ fontSize: 14, fontWeight: "bold", textAlign: "center", marginTop: 12 }}>{language === "sv" ? "Extra punktdiagram:" : lang[language].additionalCharts}</div>
                                <div><input type="radio" id="noExtraChart" name="noExtraChart" onChange={this.radioSelectedAddOn} className={this.state.selectedRadioAddOn === "noExtraChart" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadioAddOn === "noExtraChart"} />
                                    <label className="checkBoxTypeLabel" for="noExtraChart">{language === "sv" ? "Inga extra diagram" : lang[language].noExtraChart} </label></div>
                                <div><input type="radio" id="salaryNoExtra" name="salaryNoExtra" onChange={this.state.diagramOn === "on" ? this.radioSelectedAddOn : ""} className={this.state.diagramOn !== "on" ? "radioChart notAllowed" : this.state.selectedRadioAddOn === "salaryNoExtra" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadioAddOn === "salaryNoExtra"} />
                                    <label className={this.state.diagramOn === "on" ? "checkBoxTypeLabel" : "checkBoxTypeLabel notAllowed"} for="salaryNoExtra">{language === "sv" ? "Lön exklusive tillägg" : lang[language].salExclChart}</label></div>
                                <div><input type="radio" id="onlyExtra" name="onlyExtra" onChange={this.state.diagramOn === "on" ? this.radioSelectedAddOn : ""} className={this.state.diagramOn !== "on" ? "radioChart notAllowed" : this.state.selectedRadioAddOn === "onlyExtra" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadioAddOn === "onlyExtra"} />
                                    <label className={this.state.diagramOn === "on" ? "checkBoxTypeLabel" : "checkBoxTypeLabel notAllowed"} for="onlyExtra">{language === "sv" ? "Enbart tillägg" : lang[language].onlyBenefitsChart}</label></div>
                            </div>
                            <div style={{ marginTop: -12 }} className={this.state.diagramOn !== "on" ? "chartPercentInfoBox" : "hide"}>För att kunna välja extra diagram måste först inställningen <i>”Punktdiagram i dokumentation”</i> aktiveras ovan. Därefter går det att välja extra diagram. Detta på grund av att dessa extra diagram visas som komplement till ordinarie diagram.</div>
                        </div>
                        {this.state.changeMadeAddOn ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter settingsBtnForceCenter" onClick={this.saveAddOnStatus}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter settingsBtnForceCenter" >{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                    </div>
                </div> : <></>}
                {/* Toggle age in charts */}
                {this.state.showAgeChart === "adminOn" || this.state.showAgeChart === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Visa ålder i diagram</p>
                            <p className="settingsDescription">Aktivera val av ålder i x-axeln för punktdiagram.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.ageChartSwitch} checked={this.state.showAgeChart === "adminOn"} />
                            {this.state.changeMadeAgeChart ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveChartYearStatus}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                      {/* Toggle EU functions */}
                {this.state.showEU === "on" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">EU funktioner <span className="betaFunc">beta</span></p>
                            <p className="settingsDescription">I juni 2026 införs nya lagkrav för lönekartläggning & lönetransparens. Aktivera för att redan nu ta del av nya funktioner kopplade till detta.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.showEUSwitch} checked={this.state.settingEU === "on"} />
                            {this.state.changeMadeEU ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveEUStatus}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                {/* Toggle extra/tillagg function */}
                {this.state.showHideExtraIcon === "adminOn" || this.state.showHideExtraIcon === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Funktion för tillägg</p>
                            <p className="settingsDescription">Aktivera funktion för att dölja/visa tillägg.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.extraFunctionSwitch} checked={this.state.showHideExtraIcon === "adminOn"} />
                            {this.state.changeMadeExtra ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveExtraSetting}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                {/* Toggle filter function */}
                {this.state.showHideFilterIcon === "adminOn" || this.state.showHideFilterIcon === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Funktion för filter</p>
                            <p className="settingsDescription">Aktivera filter funktion vid analys.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.filterFunctionSwitch} checked={this.state.showHideFilterIcon === "adminOn"} />
                            {this.state.changeMadeFilter ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveFilterSetting}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                          {/* Toggle automatic analysis equal works */}
                {this.state.showHideAI === "adminOn" || this.state.showHideAI === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Automatisk analys av lika arbeten</p>
                            <p className="settingsDescription">Markerar medarbetare som är långt ifrån sin förväntade lön.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.autoAnalysisSwitch} checked={this.state.showHideAI === "adminOn"} />
                            {this.state.changeMadeAI ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveAISetting}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                {/* Toggle show import settings function */}
                {this.state.showHideImportSetting === "adminOn" || this.state.showHideImportSetting === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Inställningar för import</p>
                            <p className="settingsDescription">Visa extra inställningar vid import av excelfil.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.importSettingSwitch} checked={this.state.showHideImportSetting === "adminOn"} />
                            {this.state.changeMadeImportSetting ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveImportSetting}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                {/* Toggle show market bench salaries */}
                {this.state.showHideSalBenchSetting === "adminOn" || this.state.showHideSalBenchSetting === "adminOff" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" :  this.state.whatFiltered === "Val" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25 }}>
                            <p className="settingsTitle">Marknadslönedata</p>
                            <p className="settingsDescription">Aktivera visning av marknadslönedata.
                            </p>
                            <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition swiftPositionCenter" activeBoxShadow="none" onChange={this.salBenchSwitch} checked={this.state.showHideSalBenchSetting === "adminOn"} />
                            {this.state.changeMadeSalBenchSetting ? <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton settingsBtnForceCenter" onClick={this.saveSalBenchSetting}>Spara</div>
                                : <div style={{ marginLeft: 207, marginTop: 20 }} className="settingsButton inactive-konto-button settingsBtnForceCenter" >Sparat</div>}
                        </div>
                    </div>
                    : <></>}
                {/* NEW VALUATION SETTING */}
                {this.state.showHideExtValuation === "on" || this.state.showHideExtValuation === "edited" || this.state.showHideExtValuation === "extern" ?
                    <div className={this.state.whatFiltered === "Doco" ? "hide" : this.state.whatFiltered === "Rest" ? "hide" : "underSection"}>
                        <div className="settingsBox" style={{ paddingBottom: 25, marginBottom: 100 }}>
                            <p className="settingsTitle">{language === "sv" ? "Arbetsvärdering" : lang[language].jobEvaluation}</p>
                            <p className="settingsDescription">{language === "sv" ? "Välj vilken arbetsvärdering som ska användas." : lang[language].jobEvaluationInfo}<span style={{ marginTop: 2 }} className="checkBoxQuestion" onClick={this.equalentPopOpen}>?</span>
                            </p>
                            <div style={{ marginTop: -10, marginBottom: 15 }} className={this.state.equalentPop ? "chartPercentInfoBox" : "hide"}>
                                <b>Standard</b> <span className={this.state.showHideExtValuation === "on" || this.state.showHideExtValuation === "edited" ? "highlightGreen" : "hide"}>{language === "sv" ? "Enklast" : lang[language].easiest}</span>
                                <p style={{ fontSize: 13.6, marginTop: 7.5 }}> {this.state.eGSetting > 6 ? language === "sv" ? "Färdig faktorplan redo att användas. Vid behov kan beskrivningar, viktning och känslighet anpassas." : lang[language].standardInfo6Plus : language === "sv" ? "Färdig faktorplan redo att användas. Vid behov kan beskrivningar och viktning anpassas." : lang[language].standardInfo6}</p>
                                {(this.state.showHideExtValuation === "on" || this.state.showHideExtValuation === "edited") &&
                                    <>
                                        <p></p>
                                        <b>{language === "sv" ? "Anpassad" : lang[language].customized}</b>
                                        <p style={{ fontSize: 13.6, marginTop: 7.5 }}>{language === "sv" ? "För organisationer som önskar maximal flexibilitet. Möjlighet att skapa och radera faktorer, ändra nivåer m.m." : lang[language].customizedInfo}</p>
                                    </>}
                                {(this.state.showHideExtValuation === "on" || this.state.showHideExtValuation === "extern") &&
                                    <>
                                        <p></p>
                                        <b>{language === "sv" ? "Extern" : lang[language].extern}</b>
                                        <p style={{ fontSize: 13.6, marginTop: 7.5 }}>{language === "sv" ? "Använd resultatet från en arbetsvärdering som ej är skapad i Lönelys." : lang[language].externInfo}</p>
                                    </>}
                            </div>

                            {this.state.whatEvaluation === "loading" || this.state.eGSetting === "loading" || this.state.showHideExtValuation === "loading" ? <></> : <EditValuation whatEvaluation={this.state.whatEvaluation} eGSetting={this.state.eGSetting} showHideExtValuation={this.state.showHideExtValuation} />}

                        </div>
                    </div>
                    : <></>}
            </div>
        )
    }
}
export default withRouter(Settings);