import React from 'react';
import '../AnalysLikvardiga/Likvardiga.css';
import './Arkitektur.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import blueFlag from '../../assets/images/blue-flag.png';
import yellowFlag from '../../assets/images/yellow-flag.png';
import remove from '../../assets/images/x-mark.png';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import Chart from "react-apexcharts";
import { autoAnalysis } from '../AnalysLikvardiga/autoAnalysis';
import exitImg from '../../assets/images/exit.png';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import fullscreen from '../../assets/images/fullscreen.png';
import zoomOut from '../../assets/images/zoom-out.png';
import zoomIn from '../../assets/images/icon-zoom.png';
import { jitter } from '../AnalysLika/Jitter';
import ReactTooltip from 'react-tooltip';
import { Prompt } from 'react-router'
import percentile from "percentile";
import extraOn from '../../assets/images/extraOff.png';
import extraOff from '../../assets/images/extraOffKryss.png';
import bigText from '../../assets/images/bigText.png';
import zoomInIcon from '../../assets/images/zoomIn.png';
import zoomOutIcon from '../../assets/images/zoomOut.png';
import uncheckImg from '../../assets/images/uncheckImg.png';
import checkedImg from '../../assets/images/checkedImg.png';
import { ChartSalaryStructure } from './ChartSalaryStructure';
import chartIcon from '../../assets/images/chartIcon.png';
import Switch from "react-switch";
import settings from '../../assets/images/Settings.png';
import families from './families';
import { SalaryList } from './SalaryList';
import fullscreenWide from '../../assets/images/fullscreenWider.png';
import smallerScreen from '../../assets/images/smallerScreen.png';
import addIcon from '../../assets/images/addIcon.png';
import jobInfo from '../../assets/images/jobInfo.png';
import commentIcon from '../../assets/images/commentIcon.png';
import DraftEditor from './DraftEditor';
import ReadOnlyDraftEditor from './ReadOnlyDraftEditor';
import { convertToRaw } from 'draft-js';
import lang from '../Language/language.js';
import editImg from '../../assets/images/edit.png';
import exportImg from '../../assets/images/export.png';
import ReactToPrint from "react-to-print";

export class Arkitektur extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataByGroup: [],
            group1: [],
            group2: [],
            comments: [],
            commentsLastYear: [],
            analysis: "all",
            deletedComments: [],
            showOldComments: true,
            saveButtonStatus: "Sparat",
            moreFields: false,
            clickedGroup: "",
            avgSalary: null,
            womenPercent: null,
            demands: null,
            showInfoIcon: false,
            nrOfEqualentGroups: 6,
            //Below is for AIComment
            showAIComment: false,
            AInotable: "",
            AIreason: "",
            AIAnalys: "",
            //Below is for adding completely new comment
            addComment: "",
            addReason: "",
            addTodo: "",
            addDeadline: "",
            addCost: "",
            addResponsible: "",
            addAnalysis: "likvärdiga",
            //Below is for autoAnalysis
            divOpened: "first",
            analyseGroup1: "Välj grupp",
            group1EqualGroup: 0,
            selectGroup1: false, //when true, we can click to select group 1
            analyseGroup2: "Välj grupp",
            group2EqualGroup: 0,
            selectGroup2: false,
            allEmp: [],
            tableInfo: [{ oneNrOfEmp: "" }],
            analysisText: "Inga grupper valda",
            //For popups:
            popUp: false,
            popUpComments: false,
            popUpInfo: false,
            popUpEditSalarySetting: false,
            //below is for showing salaries as percent
            showPercent: false,
            hideName: false,
            //If chart is edited
            groupOneEdited: [],
            groupTwoEdited: [],
            clickedPerson: "",
            clickedPersonIndex: "",
            serieIndex: "",
            popItUp: false,
            newSalary: 0,
            showChangesPrompt: "Yes", //<-- For changes PopUp
            showDiffPercent: false,
            //Below is for making text big or small
            bigTextIcon: true,
            textBig: [],
            //Below is for extra vs salary
            hideExtra: "includeExtra", //"includeExtra", "hideExtra", "onlyExtra"
            showHideExtraIcon: "off",
            //Below is for view
            chosenView: "career",
            chartOpen: false,
            showSalRange: false,
            showComments: false,
            copyOpen: true,
            fullScreen: false,
            toggleTitles: false,
            //Below is for commenting a salary range
            showCommentSalary: false,
            allSalaryComments: [],
            thisSalaryComment: "",
            salaryCommentSaved: true,
            // Below is for following titles when scrolling
            showFixedTitle: false,
            // Below is for printing:
            printHeight: 0,
            // Below is sent to chartSalaryStructure module
            p10: [],
            p25: [],
            median: [],
            p75: [],
            p90: [],
            employeesInChartLevels: [],
            rangesLevels: [],
            individual: [], // For saving individual salary ranges
            family: [], // for saving family
            mainFunction: [], // for saving mainFunction
            subFunction: [], // for saving subFunction
            subFunction2: [], // for saving subFunction2
            subFunction3: [], // for saving subFunction3
            subFunction4: [], // for saving subFunction4
            activeFamilyOrFunc: [], // this array holds the active family or function. Added to correct family or array before saving
            autoCalculation: "no",
            salaryRangesOpen: false,
            salaryRangesManualOpen: false,
            triggerChartUpdate: "trigger",
            marketPosition: "median",
            activateSalRangeSave: "off", // on | off - If off ranges won't save to DB
            activateSalRangeSaveFamily: "off",
            activateSalRangeSaveMainFunction: "off",
            activateSalRangeSaveSubfunction: "off",
            activateSalRangeSaveSubfunction2: "off",
            activateSalRangeSaveSubfunction3: "off",
            activateSalRangeSaveSubfunction4: "off",
            activateSalRangeSaveIndividual: "off",
            chartWhat: "overall",   // overall | family | mainFunction | subFunction | subFunction2 | subFunction3 | subFunction4 - Setting for what type of range we will show/set. 
            selectedFamOrFunc: "overall", // overall is for levels, if not levels it is the name of the family or function
            listOfFamOrFunc: [], // Used for when selecting family or function in scrollable list
            whatEvaluation: "original",
            // Below is for job description
            showJobDesc: true,
            popUpJobDesc: false,
            jobDescWhatGroup: "",
            jobDescriptions: [],
            jobDescSelected: "",
            editJobDesc: false,
            positionsAndEqualWorks: [],
            // Below is for settings for chartSalaryStructure module
            standardDeviation: "two", // "one" or "two" standardDeviations, or "no"
            createProgression: "yes",
            marketWeight: 50,
            snappedValue: 50, // This is only used to make slider smooth for the marketWeight slider
            manualLevel: "-", // This is for creating manual levels, the level (integer)
            nrOfSelectableEqualentGroups: [],  // This is for selecting manual levels, will only show levels that does not exist.
            removeThis: [],
            showInputNewFam: "hide",
            popUpForFamilies: false,
            whatAIToCreate: "",
            // Below is for settingsbutton
            settingPopUp: false,
            //Below is for salary band chart
            options2: {
                /* tooltip: {
                   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                     return `<ul>
                             <li><b>Namn</b>: ${data.fullName}</li>
                             <li><b>Lön</b>: ${data.y} kr</li>
                             <li><b>År i org</b>: ${data.expNoJitter}</li>
                             <li><b>Ålder</b>: ${data.age}</li>
                             <li><b>Ort</b>: ${data.city}</li>
                             </ul>`
                   },
                 },*/
                chart: {
                    height: 350,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true
                    },
                },
                /*markers: { //REMOVE THIS
                 strokeWidth: 3,
                 strokeColors: ["#000", "#cf1af2"],
                 //size: 5,  
               },*/
                //colors:["#000", "#cbb958"],
                markers: {
                    size: [6, 6, 0]
                },
                tooltip: {
                    shared: false,
                    intersect: true,
                },
                xaxis: {
                    tickAmount: 5,
                    max: 5,
                    min: 1,
                    type: 'numeric',
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },
            },
            series2: [
                {
                    name: "Medarbetare",
                    data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                    type: 'scatter'
                },
                /*{
                name: "Män",
                data: [{y: 30000, x: 1},{y: 35000, x: 2}, {y: 45000, x: 3}],
                type: 'scatter'
              },*/
                /* {
                   name: "Kvinnor",
                   data: [{y: 30000, x: 1},{y: 35000, x: 2}, {y: 45000, x: 3}],
                   type: 'scatter'
                 },*/
                {
                    name: "Medel",
                    type: 'line',
                    color: "#cdcccc",
                    data: [{ y: 30000, x: 1 }, { y: 35000, x: 2 }, { y: 45000, x: 3 }, { y: 52500, x: 5 },]
                },
                {
                    name: "P90",
                    type: 'line',
                    color: "#cdcccc",
                    data: [{ y: 35000, x: 1 }, { y: 40000, x: 2 }, { y: 55000, x: 3 }, { y: 62500, x: 5 },]
                },
                {
                    name: "P10",
                    type: 'line',
                    color: "#cdcccc",
                    data: [{ y: 15000, x: 1 }, { y: 20000, x: 2 }, { y: 35000, x: 3 }, { y: 32500, x: 5 },]
                },
            ],
            legendSalRange: true,
            options3: {
                /* tooltip: {
                     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                       var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                       return `<ul>
                               <li><b>Namn</b>: ${data.fullName}</li>
                               <li><b>Lön</b>: ${data.y} kr</li>
                               <li><b>År i org</b>: ${data.expNoJitter}</li>
                               <li><b>Ålder</b>: ${data.age}</li>
                               <li><b>Ort</b>: ${data.city}</li>
                               </ul>`
                     },
                   },*/
                annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },

                    ]
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    // show: false,
                    //width: 0.5,
                    // width: 1.5,
                    width: [0, 2, 2, 2, 2, 2, 2],
                    colors: ["#f7f7f7"]
                },
                //colors: ["#FF0000", "#FF0000","#FF0000", "#FF0000","#FF0000", "#FF0000"],
                grid: {
                    borderColor: "#e1e1e1",
                    position: 'front',
                    show: false,
                    /*  xaxis: {
                          lines: {
                              show: false
                          }
                      }*/
                },
                /* tooltip: {
                   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                     return `<ul>
                             <li><b>Namn</b>: ${data.fullName}</li>
                             <li><b>Lön</b>: ${data.y} kr</li>
                             <li><b>År i org</b>: ${data.expNoJitter}</li>
                             <li><b>Ålder</b>: ${data.age}</li>
                             <li><b>Ort</b>: ${data.city}</li>
                             </ul>`
                   },
                 },*/
                chart: {
                    height: 350,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true
                    },
                    events: { // Change this to change the legend (series buttons) function
                        legendClick: function (chartContext, seriesIndex, config) {
                            // This toggles this.state.legendSalRange, it determes if annotations for lönespann should be shown or hidden.
                            const clickedLegendName = chartContext.w.config.series[seriesIndex].name;
                            if (clickedLegendName === "Lönespann") {
                                this.state.legendSalRange ? this.setState({ legendSalRange: false }) : this.setState({ legendSalRange: true })
                                this.updateChart()
                            }
                        }.bind(this)
                    }

                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        //floating: true, // Enable floating bars
                    },
                },
                /*markers: { //REMOVE THIS
                 strokeWidth: 3,
                 strokeColors: ["#000", "#cf1af2"],
                 //size: 5,  
               },*/
                //colors:["#000", "#cbb958"],
                markers: {
                    size: [2, 6]
                },

                tooltip: {
                    shared: false,
                    intersect: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                        if (data.range === undefined) { //If undefined we are not looking at the range box, instead we look at an individual or p-value
                            if (data.p === undefined) { //If p is undefined, we are looking at an individual
                                return `<div class="tooltip">
                                <div>Persondata här, tex:</div>
                                <div>Lön: ${data.y}</div>
                                <div>Lön inkl tillägg: ${data.totalSalary}</div>
                                <div>Befattning: ${data.positionTitle}</div>
                                <div>Lika arbete: ${data.groupNameArbVardering}</div>
                                <div>Nivå: ${data.x}</div>
                                <div>Ålder: ${data.age}</div>
                                <div>År i org: ${data.experience}</div>
                                <div>Stad: ${data.city}</div>
                                <div>Kön: ${data.gender}</div>
                                
                                </div>`;
                            } else { //If p is not undefined, we are looking at a "P" value
                                return `<div class="tooltip">${data.y}</div>`;
                            }
                        } else {
                            return `<div class="tooltip">
                            <div style="text-align: center; border-bottom: 1px solid lightgrey"><b>Nivå ${data.x}</b></div>
                            <div style="padding-top: 4px; padding-bottom: 2px"><b>Max:</b> ${data.range[2]}</div>
                            <div style="padding-top: 2px; padding-bottom: 2px"><b>Mid:</b>  ${data.range[1]}</div>
                            <div style="padding-top: 2px"><b>Min:</b>  ${data.range[0]}</div>
                            </div>`;
                        }
                    }
                },
                xaxis: {
                    tickAmount: 5,
                    max: 5,
                    min: 1,
                    type: 'numeric',
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },


            },
            series3: [
                {
                    name: "Lönespann",
                    type: 'bar',
                    //color: "#cdcccc",
                    data: [ // X tells is the level
                        { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                        { y: 40000, x: 2, fillColor: "#f7f7f7", range: [40000, 45000, 60000], }, // Bottom
                        { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                        { y: 60000, x: 5, range: [60000, 45000, 80000] },
                    ],
                },
                /* Marknadslöner kan visas såhär:  {
                      name: "Marknadslöner",
                      type: 'bar',
                      //color: "#cdcccc",
                      data: [ // X tells is the level
                          { y: 50000, x: 2, fillColor: "green" }, // Floating box between 20000-40000 for x-axis 1
                          { y: 40000, x: 2, fillColor: "#f7f7f7",  }, // Customize as per your requirement
                          { y: 60000, x: 2, fillColor: "transparent" }, // Customize as per your requirement
                      ],
                  },*/
                {
                    name: "Medarbetare",
                    data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                    type: 'scatter',
                },

                {
                    name: "P10",
                    type: 'line',
                    color: "#dda6a6",
                    data: [{ y: 25000, x: 1, p: "p" }, { y: 20000, x: 2, p: "p" }, { y: 35000, x: 3, p: "p" }, { y: 22500, x: 5, p: "p" },] //"P" is used to determe what type of item it is for tooltip
                },
                {
                    name: "P25",
                    type: 'line',
                    color: "#f6bdc7",
                    data: [{ y: 30000, x: 1, p: "p" }, { y: 40000, x: 2, p: "p" }, { y: 55000, x: 3, p: "p" }, { y: 92500, x: 5, p: "p" },]
                },

                {
                    name: "Median",
                    type: 'line',
                    color: "#8fa7e1",
                    data: [{ y: 35000, x: 1, p: "p" }, { y: 50000, x: 2, p: "p" }, { y: 65000, x: 3, p: "p" }, { y: 40500, x: 5, p: "p" },]
                },
                {
                    name: "P75",
                    type: 'line',
                    color: "#dbc191",
                    data: [{ y: 40000, x: 1, p: "p" }, { y: 58000, x: 2, p: "p" }, { y: 75000, x: 3, p: "p" }, { y: 97500, x: 5, p: "p" },]
                },
                {
                    name: "P90",
                    type: 'line',
                    color: "#9dc59d",
                    data: [{ y: 45000, x: 1, p: "p" }, { y: 60000, x: 2, p: "p" }, { y: 80000, x: 3, p: "p" }, { y: 102500, x: 5, p: "p" },]
                },
                /* {
                     name: "Medellön",
                     data: [{ y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }],
                     color: "#000000",
                     type: 'line',
                 },*/
            ],




            // OPTIONS 4
            options4: {
                chart: {
                    height: 430
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [1, 2, 3, 4, 5, 6],
                },
            },
            series4: [{
                data: [44, 55, 41, 64, 22, 43]
            }, {
                data: [53, 32, 33, 52, 13, 44]
            }],





            //Below is for architecture
            addFamilytoList: "",
            addMainFunction: "",
            addSubFunction: "",
            addSubFunction2: "",
            addSubFunction3: "",
            familySelectList: ["- Ingen -"],
            mainFunctionsSelectList: ["- Ingen -"],
            subFunctionSelectList: ["- Ingen -"],
            subFunctionSelectList2: ["- Ingen -"],
            subFunctionSelectList3: ["- Ingen -"],
            selectedMainFunction: "",
            selectedSubFunction: "",
            selectedSubFunction2: "",
            selectedSubFunction3: "",
            editGroupPop: false,
            selectedWorkForAutoCalc: false,
            showMore: false,
            nrOfSubFamilies: 2,
            empData: [],
            marketSalaries: [],
            hierarchy: [],
            jobFamilies: [
                {
                    family: "Finans",
                    subGroup: "Redovisning",
                    subGroup1: "Red.ekonomer",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#5bcaae",
                    equalGroup:
                    {
                        name: "Red. ekonom",
                        level: 2,
                        works: ["Redovisningsekonom"]
                    },
                },
                {
                    family: "Finans",
                    subGroup: "Redovisning",
                    subGroup1: "Ansvariga",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#5bcaae",
                    equalGroup:
                    {
                        name: "Ekonomiansvarig",
                        level: 5,
                        works: ["Ekonomiansvarig"]
                    },
                },
                {
                    family: "Finans",
                    subGroup: "Redovisning",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#5bcaae",
                    equalGroup:
                    {
                        name: "Ekonomiassistent",
                        level: 2,
                        works: ["Assistent"]
                    },
                },
                {
                    family: "Finans",
                    subGroup: "Controlling",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#5bcaae",
                    equalGroup:
                    {
                        name: "Controller",
                        level: 3,
                        works: ["Controller"]
                    },
                },
                {
                    family: "Finans",
                    subGroup: "Controlling",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#5bcaae",
                    equalGroup:
                    {
                        name: "Controller assistent",
                        level: 3,
                        works: ["controllerassistent"]
                    },
                },
                {
                    family: "HR",
                    subGroup: "HRBP",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#cabe5b",
                    equalGroup:
                    {
                        name: "HRBP",
                        level: 3,
                        works: ["HRBP"]
                    },
                },
                {
                    family: "IT",
                    subGroup: "Frontend",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#608dc9",
                    equalGroup:
                    {
                        name: "Frontendutvecklare",
                        level: 3,
                        works: ["Frontendutvecklare"]
                    },
                },
                {
                    family: "IT",
                    subGroup: "Backend",
                    subGroup1: "Subgrupp1",
                    subGroup2: "Subgrupp2",
                    subGroup3: "Subgrupp3",
                    color: "#608dc9",
                    equalGroup:
                    {
                        name: "Junior Backendutvecklare",
                        level: 3,
                        works: ["Junior Backendutvecklare"]
                    },
                },
            ],
            //Below is for chart
            xAxisType: "experience",
            xSetting: false,
            chartWidth: "300",
            chartHeight: "255",
            apexPopUp: "",
            longestExp: 0,
            series: [{
                name: "",
                data: []
            },
            {
                name: "",
                data: []
            }
            ],
            options: {
                colors: ['#ffffff', 'transparent'],
                fill: {
                    colors: ['#ffffff', 'transparent']
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                grid: {
                    show: false
                },
                /* toolbar: {
                     show: false,
                 },*/


                //fill:['white', 'blue'],
                tooltip: {
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                        return `<ul>
                            <li style="color: #333333"><b>Namn</b>: ${data.fullName}</li>
                            <li style="color: #333333"><b>Lön</b>: ${data.y} kr</li>
                            <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                            <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                            <li style="color: #333333">${data.equalGroup}</li>
                            </ul>`
                    },
                },
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    tickAmount: 5,
                    type: 'numeric',
                    //max: longestExp,
                    //min: 0,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#a3eade'
                        }
                    },
                },
                yaxis: {
                    tickAmount: 0,
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        },
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        /*hover: {*/
                        style: {
                            colors: '#a3eade',
                            /*    }*/
                        },
                    },
                },
            },
        }
        this.targetDivRef = React.createRef();
    }


    //Toggle div
    toggleDiv = () => {
        if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
        else if (this.state.divOpened === "yes") this.setState({ divOpened: "no" })
        else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })
    }
    hideExtra = () => {
        return new Promise((resolve, reject) => {
            this.state.hideExtra === "includeExtra" ? this.setState({ hideExtra: "hideExtra" }) : this.state.hideExtra === "hideExtra" ? this.setState({ hideExtra: "onlyExtra" }) : this.setState({ hideExtra: "includeExtra" })
            this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--widthExtraSalIcon', '20px') : document.documentElement.style.setProperty('--widthExtraSalIcon', '86px')
            this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px') : document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px')
            resolve();
        });
    }

    //Make text big/small for selected groups
    makeTextbig = (event) => {
        const equalentGroupId = event.target.id
        const equalentGroup = Number(equalentGroupId)
        let textBigCopy = this.state.textBig.slice();
        if (textBigCopy.includes(equalentGroup)) {
            const removeGroup = textBigCopy.filter(item => item !== equalentGroup);
            this.setState({ textBig: removeGroup })
        } else {
            textBigCopy.push(equalentGroup)
            this.setState({ textBig: textBigCopy })
        }
    }

    autoCompare = (filterOn) => {
        //Do nothing if no groups are compared
        if (
            (this.state.analyseGroup1 === "Klicka på grupp" || this.state.analyseGroup1 === "Välj grupp") ||
            (this.state.analyseGroup2 === "Klicka på grupp" || this.state.analyseGroup2 === "Välj grupp")
        ) { return; }

        else {
            let hideExtra = this.state.hideExtra //For showing/adding extra/tilagg button
            //If div is not opened, open it
            if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
            else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })

            //Make calculations
            const group1 = this.state.analyseGroup1
            const group2 = this.state.analyseGroup2

            let groupOne = []
            let groupTwo = []
            if (filterOn !== "edited") {
                groupOne = this.state.allEmp.filter(person => person.groupNameArbVardering === group1);
                groupTwo = this.state.allEmp.filter(person => person.groupNameArbVardering === group2);
                this.setState({ groupOneEdited: [], groupTwoEdited: [] })
            } else {
                if (this.state.groupOneEdited.length <= 0 || this.state.groupTwoEdited.length <= 0) { return; }
                groupOne = this.state.groupOneEdited
                groupTwo = this.state.groupTwoEdited
            }

            //1. First, get statistics for table
            let originalSalary = filterOn !== "edited" ? "totalSalary" : "salBeforeAddOn" // This saves the uses the original salary to each employee
            //group 1: 
            const oneNrOfEmp = groupOne.length
            const oneWomen = Math.floor((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
            let oneAvgSalary = 0
            if (hideExtra === "hideExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / oneNrOfEmp) }
            if (hideExtra === "onlyExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.addOn)), 0) / oneNrOfEmp) }
            if (hideExtra === "includeExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / oneNrOfEmp) }
            const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
            const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
            const oneEqualentGroup = groupOne[0].extEqualentGroup //CHANGE THIS IF CHANGING EQUALENT GROUP
            const oneCityArray = groupOne.map(person => person.city)
            const oneAverageCity = oneCityArray.sort((a, b) =>
                oneCityArray.filter(v => v === a).length
                - oneCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG1 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary] - groupOne[i].addOn) : getOnlySalariesG1.push(groupOne[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupOne.length; i++) { getOnlySalariesG1.push(groupOne[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary]) : getOnlySalariesG1.push(groupOne[i].salNoExtra + groupOne[i].addOn) } }
            let lowestSalaryG1 = Math.min(...getOnlySalariesG1)
            let highestSalaryG1 = Math.max(...getOnlySalariesG1)
            let spridningskvotG1 = Math.round((highestSalaryG1 / lowestSalaryG1) * 10) / 10

            //group 2:
            const twoNrOfEmp = groupTwo.length
            const twoWomen = Math.floor((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
            let twoAvgSalary = 0
            if (hideExtra === "hideExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / twoNrOfEmp) }
            if (hideExtra === "onlyExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.addOn)), 0) / twoNrOfEmp) }
            if (hideExtra === "includeExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / twoNrOfEmp) }
            const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
            const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
            const twoEqualentGroup = groupTwo[0].extEqualentGroup // CHANGE THIS IF CHANGING EQUALENT GROUP
            const twoCityArray = groupTwo.map(person => person.city)
            const twoAverageCity = twoCityArray.sort((a, b) =>
                twoCityArray.filter(v => v === a).length
                - twoCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG2 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary] - groupTwo[i].addOn) : getOnlySalariesG2.push(groupTwo[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupTwo.length; i++) { getOnlySalariesG2.push(groupTwo[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary]) : getOnlySalariesG2.push(groupTwo[i].salNoExtra + groupTwo[i].addOn) } }
            let lowestSalaryG2 = Math.min(...getOnlySalariesG2)
            let highestSalaryG2 = Math.max(...getOnlySalariesG2)
            let spridningskvotG2 = Math.round((highestSalaryG2 / lowestSalaryG2) * 10) / 10

            //Diffs
            const diffWomen = (oneWomen - twoWomen)

            //Calculate 10 and 90 percentiles if needed (if 20 or more employees)
            let moreOne10 = "-"
            let moreOne90 = "-"
            let moreTwo10 = "-"
            let moreTwo90 = "-"
            if (oneNrOfEmp > 19 || twoNrOfEmp > 19) {
                //If men bigger but not women
                if (oneNrOfEmp > 19 && twoNrOfEmp < 20) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                }
                //If women bigger but not men
                if (twoNrOfEmp > 19 && oneNrOfEmp < 20) {
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
                //If both bigger
                if (twoNrOfEmp > 19 && oneNrOfEmp > 19) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
            }
            let spridningDiff = Math.round((spridningskvotG1 - spridningskvotG2) * 10) / 10

            this.setState({
                tableInfo: [{
                    oneNrOfEmp: oneNrOfEmp,
                    oneWomen: oneWomen,
                    oneAvgSalary: oneAvgSalary,
                    oneAvgAge: oneAvgAge,
                    oneAvgExp: oneAvgExp,
                    oneEqualentGroup: oneEqualentGroup,
                    oneAvgCity: oneAverageCity,
                    twoNrOfEmp: twoNrOfEmp,
                    twoWomen: twoWomen,
                    twoAvgSalary: twoAvgSalary,
                    twoAvgAge: twoAvgAge,
                    twoAvgExp: twoAvgExp,
                    twoEqualentGroup: twoEqualentGroup,
                    twoAvgCity: twoAverageCity,
                    diffWomen: diffWomen,
                    lowestSalaryG1: lowestSalaryG1,
                    highestSalaryG1: highestSalaryG1,
                    spridningskvotG1: spridningskvotG1,
                    lowestSalaryG2: lowestSalaryG2,
                    highestSalaryG2: highestSalaryG2,
                    spridningskvotG2: spridningskvotG2,
                    spridningDiff: spridningDiff,
                    moreOne10: moreOne10,
                    moreOne90: moreOne90,
                    moreTwo10: moreTwo10,
                    moreTwo90: moreTwo90,
                }]
            });

            let xAxisType = this.state.xAxisType
            //Get data to use with settings for chart x-axis
            const bothGroups = groupOne.concat(groupTwo);
            let allExperiences = []
            let longestExp = 0
            if (xAxisType === "experience") {
                allExperiences = bothGroups.map(person => person.experience)
                longestExp = Math.max(...allExperiences)
                if (longestExp < 10) longestExp = 10
                if (longestExp > 10 && longestExp < 16) longestExp = 16
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }
            if (xAxisType === "age") {
                allExperiences = bothGroups.map(person => person.age)
                longestExp = Math.max(...allExperiences)
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }

            //2. Then update chart
            //if should show in kr
            if (!this.state.showPercent) {
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    // let salary = this.state.clickedNewGroup ? "totalSalary" : !this.state.clickedNewGroup && !this.state.showPercent ? "y" : "salInKr"
                    // let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? (el.salNoExtra) : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333;"><b>Övrigt</b>: ${additionalToLong}</li>`
                                if (data.addOn === 0 || data.addOn === null || data.addOn === undefined) { data.addOn = "" }
                                let showAddOn = ""
                                data.addOn === "" ? showAddOn = "" : hideExtra === "includeExtra" ? showAddOn = ` | <i style="font-size: 12px">Varav tillägg: ${data.addOn}</i>` : hideExtra === "hideExtra" ? showAddOn = ` | <i style="font-size: 12px">Tillägg: ${data.addOn} (exkluderat)</i>` : showAddOn = ``
                                let salaryName = hideExtra === "onlyExtra" ? "Tillägg" : "Lön"
                                //let salaryExklExtra = data.salNoExtra === undefined ? data.y : data.salNoExtra
                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>Namn</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>${salaryName}</b>: ${data.y} kr${showAddOn}</li>
                                    <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>Ort</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>Ålder</div>`,
                                        index: 4,
                                        title: 'Sätt x-axel till ålder',
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                        click: function (chart, options, e) {
                                            let activeItem = this.state.activeItem
                                            this.setState({ xAxisType: "age" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">Exkl tillägg</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">Bara tillägg</div>
                                          `
                                        ,
                                        index: 2,
                                        title: hideExtra === "includeExtra" ? 'Visar lön inklusive tillägg' : hideExtra === "hideExtra" ? 'Visar lön exklusive tillägg' : "Visar tillägg exklusive lön",
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: function (chart, options, e) {
                                            this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 1,
                                        title: 'Zooma ut',
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "300.1",
                                                })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({
                                                    chartWidth: "92.01%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>År i org.</div>`,
                                        index: 5,
                                        title: 'Sätt x-axel till år i organisationen',
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                        click: function (chart, options, e) {
                                            this.setState({ xAxisType: "experience" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: 'Fullskärm',
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, extEqualentGroup: groupOne[0].extEqualentGroup }
                                    })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, extEqualentGroup: groupTwo[0].extEqualentGroup }
                                    })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                /*stepSize: 1,**/
                                /*maxTicksLimit: 10*/
                                /* count: 10*/
                                /*autoSkip: false*/
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //if should show in PERCENT
            if (this.state.showPercent) {
                //1. Put togheter groupOne And Two
                const bothGroups = groupOne.concat(groupTwo);
                //2. calculate total salary for use when calculating percent
                // const salaryTotal = bothGroups.reduce((sum, cur) => sum + (filterOn !== "edited" ? cur.totalSalary : cur.salBeforeAddOn), 0) //<-- MÅSTE JUSTERA!
                let allSalaries = []
                for (let i = 0; i < bothGroups.length; i++) {
                    let el = bothGroups[i]
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    allSalaries.push(hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn)
                }
                const salaryTotal = allSalaries.reduce((sum, cur) => sum + cur, 0)
                //3. Update properties
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    //let theSalary = 0
                    //!hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    // let theSalary = 0
                    // !hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333"><b>Övrigt</b>: ${additionalToLong}</li>`

                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>Namn</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>Lön</b>: ${data.y} %</li>
                                    <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>Ort</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>Ålder</div>`,
                                        index: 4,
                                        title: 'Sätt x-axel till ålder',
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                        click: function (chart, options, e) {
                                            let activeItem = this.state.activeItem
                                            this.setState({ xAxisType: "age" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">Exkl tillägg</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">Bara tillägg</div>
                                          `,
                                        index: 2,
                                        title: !hideExtra ? 'Visar lön inkl tillägg, klicka för att exkludera' : 'Visar lön exkl tillägg, klicka för att inkludera',
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: function (chart, options, e) {
                                            this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                    opacity: 0.40;">`,
                                        index: 1,
                                        title: 'Zooma ut',
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({ chartWidth: "300.1" })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({ chartWidth: "92.01%" })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({ chartWidth: "92%" })
                                            }
                                            else {
                                                this.setState({ chartWidth: "300" })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>År i org.</div>`,
                                        index: 5,
                                        title: 'Sätt x-axel till år i organisationen',
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                        click: function (chart, options, e) {
                                            this.setState({ xAxisType: "experience" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: 'Fullskärm',
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, extEqualentGroup: groupOne[0].extEqualentGroup } })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, extEqualentGroup: groupTwo[0].extEqualentGroup } })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //b. We change this based on some if statements below. So we dont have to copy our If statements. 
            //Initial state is the same as "if (oneWomen > 59 && twoWomen < 60)"
            let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
            let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
            let firsAvgSalary = oneAvgSalary //Total average salary for group 1
            let secondAvgSalary = twoAvgSalary //Total average salary for group 2
            let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
            let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
            let firstGroup = group1 //Employees in group 1
            let secondGroup = group2 //Employees in group 2
            let firstExp = oneAvgExp //Average Experience in group 1
            let secondExp = twoAvgExp //Average Experience in group 2
            let firstAge = oneAvgAge //Average Experience in group 1
            let secondAge = twoAvgAge //Average Experience in group 2
            let firstEqualent = this.state.group1EqualGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
            let secondEqualent = this.state.group2EqualGroup
            let firstCity = oneAverageCity
            let secondCity = twoAverageCity

            //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
            if (twoWomen > 59 && oneWomen < 60) {
                lessWomen = twoWomen
                moreWomen = oneWomen
                firsAvgSalary = twoAvgSalary
                secondAvgSalary = oneAvgSalary
                nrOfEmp = twoNrOfEmp
                nrOfEmpTwo = oneNrOfEmp
                firstGroup = group2
                secondGroup = group1
                firstExp = twoAvgExp
                secondExp = oneAvgExp
                firstAge = twoAvgAge
                secondAge = oneAvgAge
                firstEqualent = this.state.group2EqualGroup
                secondEqualent = this.state.group1EqualGroup
                firstCity = twoAverageCity
                secondCity = oneAverageCity
            }

            //Run auto analysis (from separate file)
            //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, this.state.group1, this.state.group2, this.state.group3, this.state.group4, this.state.group5, this.state.group6);
            const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");

            //Check if analysisText contains "<age>"
            if (autoAnalys[0].includes("<age>")) {
                this.setState({ showInfoIcon: true })
            } else {
                this.setState({ showInfoIcon: false })
            }
            //Remove
            let autoString = autoAnalys[0].replace("<age>", "")
            //Set state to result of auto analysis
            if (autoAnalys[1] === "ignore" || autoAnalys[1] == undefined) { autoAnalys[1] = "" }
            if (autoAnalys[2] === "ignore" || autoAnalys[1] == undefined || typeof autoAnalys[2] === 'object') { autoAnalys[2] = "" }
            if (hideExtra === "onlyExtra") {
                this.setState({ analysisText: "Autoanalys ej tillämpbar för enbart tillägg.", AInotable: "", AIreason: "", AIAnalys: "" });
            }
            else {
                this.setState({ analysisText: autoString, AInotable: autoAnalys[1], AIreason: autoAnalys[2], AIAnalys: autoAnalys[3] });
            }
        }
    }

    selectGroup1 = () => {
        this.setState({
            analyseGroup1: "Klicka på grupp",
            selectGroup1: true,
        })
    }
    selectGroup2 = () => {
        this.setState({
            analyseGroup2: "Klicka på grupp",
            selectGroup2: true,
        })
    }

    updateApexChartWithThis = (men, women) => {
        return new Promise(resolve => {
            this.setState({
                series: [{
                    name: "Män",
                    data: men //filteredMale
                },
                {
                    name: "Kvinnor",
                    data: women //filteredWoman
                }],
            })
            resolve()
        });
    }

    showDiffPercent = () => { this.state.showDiffPercent ? this.setState({ showDiffPercent: false }) : this.setState({ showDiffPercent: true }) }


    updateChart = () => {
        //Hides annotations
        this.setState({
            options3: {
                annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },

                    ]
                },
            }
        })
        //this.setState({options3: options3})
    }

    handleScroll = () => {
        if (this.targetDivRef.current) {
          const targetPosition = this.targetDivRef.current.getBoundingClientRect().top;
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    
          // Check if the target div has scrolled into view
          if (targetPosition <= windowHeight && targetPosition >= 0) {
            this.setState({ showFixedTitle: false });
          } else {
            this.setState({ showFixedTitle: true });
          }
        }
      };

    componentDidUpdate() {
        if (this.lineHeightCalculateRef.current) {
            const height = this.lineHeightCalculateRef.current.offsetHeight;
            if (height !== this.state.printHeight) {
                this.setState({ printHeight: height });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll); // handles scrolldown for titles
      }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll); // handles scrolldown for titles
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }

        const getDataAndComments = async () => {
            const responseH = await fetchAuth(`/api/loadArchitecture`, 'POST');
            let dataH = await responseH.json();

            let allSalaryComments = dataH[4][0]
            this.setState({ allSalaryComments: allSalaryComments }) // save comments

            let savedSalaryRanges = dataH[2][0]

            // Save all unique families and subfamilies into state, used for list selections
            // Get all unique from empData
            let uniqueFamilies = new Set();
            let uniqueMainFunctions = new Set();
            let uniqueSubFunctions = new Set();
            let uniqueSubFunctions2 = new Set();
            let uniqueSubFunctions3 = new Set();
            for (let obj of dataH[0]) {
                if (obj.family !== undefined) uniqueFamilies.add(obj.family);
                if (obj.mainFunction !== undefined) uniqueMainFunctions.add(obj.mainFunction);
                if (obj.subFunction !== undefined) uniqueSubFunctions.add(obj.subFunction);
                if (obj.subFunction2 !== undefined) uniqueSubFunctions2.add(obj.subFunction2);
                if (obj.subFunction3 !== undefined) uniqueSubFunctions3.add(obj.subFunction3);
            }
            let familiesArray = Array.from(uniqueFamilies).filter(value => value !== null && value !== undefined);;
            let mainFunctionsArray = Array.from(uniqueMainFunctions).filter(value => value !== null && value !== undefined);;
            let subFunctionsArray = Array.from(uniqueSubFunctions).filter(value => value !== null && value !== undefined);;
            let subFunctions2Array = Array.from(uniqueSubFunctions2).filter(value => value !== null && value !== undefined);;
            let subFunctions3Array = Array.from(uniqueSubFunctions3).filter(value => value !== null && value !== undefined);;
            // Get all unique from families.js
            let uniqueFamiliesSSYK = new Set();
            let uniqueMainFunctionsSSYK = new Set();
            let uniqueSubFunctionsSSYK = new Set();
            for (let obj of families) {
                if (obj.family !== undefined) uniqueFamiliesSSYK.add(obj.family);
                if (obj.cat2 !== undefined) uniqueMainFunctionsSSYK.add(obj.cat2);
                if (obj.cat3 !== undefined) uniqueSubFunctionsSSYK.add(obj.cat3);
            }
            let familiesArraySSYK = Array.from(uniqueFamiliesSSYK);
            let mainFunctionsArraySSYK = Array.from(uniqueMainFunctionsSSYK);
            let subFunctionsArraySSYK = Array.from(uniqueSubFunctionsSSYK);
            // Merge the list from families.js and from empData and remove duplicates
            let combinedArrayFamily = Array.from(new Set([...familiesArray, ...familiesArraySSYK]));
            let combinedArrayMainFunction = Array.from(new Set([...mainFunctionsArray, ...mainFunctionsArraySSYK]));
            let combinedArraySubFunctions = Array.from(new Set([...subFunctionsArray, ...subFunctionsArraySSYK]));
            // Order by alphabetic order
            combinedArrayFamily.sort((a, b) => a.localeCompare(b));
            combinedArrayMainFunction.sort((a, b) => a.localeCompare(b));
            combinedArraySubFunctions.sort((a, b) => a.localeCompare(b));
            subFunctions2Array.sort((a, b) => a.localeCompare(b));
            subFunctions3Array.sort((a, b) => a.localeCompare(b));
            // Save to state
            this.setState({
                familySelectList: combinedArrayFamily,
                mainFunctionsSelectList: combinedArrayMainFunction,
                subFunctionSelectList: combinedArraySubFunctions,
                subFunctionSelectList2: subFunctions2Array, // families.js will max contain two subfunctions, therefore no need to combine
                subFunctionSelectList3: subFunctions3Array,
            })

            // Fix the savedSalaryRanges to be the correct format 
            const salaryRangesFixed = [];
            const uniqueCombinations = [];
            // Gather unique combinations of x and familyOrFunction
            savedSalaryRanges.forEach(obj => {
                //  let combination = `${obj.level}-${obj.familyOrFunction}`;
                let combination = `${obj.level}-${obj.familyOrFunction}-${obj.name}`;
                if (obj.familyOrFunction === "individual") {
                    combination = `${obj.name}-${obj.familyOrFunction}`;
                }
                if (!uniqueCombinations.includes(combination)) {
                    uniqueCombinations.push(combination);
                }
            });

            uniqueCombinations.forEach(combination => {
                const [x, familyOrFunction, name] = combination.split('-');
                let filteredObjects = savedSalaryRanges.filter(obj => obj.level == x && obj.familyOrFunction == familyOrFunction && obj.name == name);
                let filterObjectsIndividual = savedSalaryRanges.filter(obj => obj.name == x && obj.familyOrFunction == familyOrFunction);

                if (filterObjectsIndividual.length > 0) {
                    filteredObjects = filterObjectsIndividual
                }

                filteredObjects.forEach((obj, index) => {
                    const range = [obj.min, obj.mid, obj.max];
                    const spread = ((obj.max - obj.min) / obj.min) * 100;

                    salaryRangesFixed.push({
                        fillColor: "#e0e0e0",
                        range: range,
                        spread: spread,
                        x: obj.level,
                        y: obj.max,
                        familyOrFunction: obj.familyOrFunction,
                        name: obj.name
                    });
                    if (index === 0) {
                        salaryRangesFixed.push({
                            fillColor: "#ffffff",
                            range: "hide",
                            spread: spread,
                            x: obj.level,
                            y: obj.min,
                            familyOrFunction: obj.familyOrFunction,
                            name: obj.name
                        });
                    }
                });
            });
            let rangesLevels = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "overall");
            let family = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "family");
            let mainFunction = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "mainFunction");
            let subFunction = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction");
            let subFunction2 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction2");
            let subFunction3 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction3");
            let subFunction4 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction4");
            let individual = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "individual");

            this.setState({
                marketSalaries: dataH[1],
                rangesLevels: rangesLevels,
                family: family,
                mainFunction: mainFunction,
                subFunction: subFunction,
                subFunction2: subFunction2,
                subFunction3: subFunction3,
                subFunction4: subFunction4,
                individual: individual,
            })
            // End of salaryRangesFixed
            const response = await fetchAuth(`/api/loadArchitectureMoreData`, 'POST');
            let data = await response.json();

            let whatEvaluation = data[8]
            this.setState({ whatEvaluation: whatEvaluation })

            // Change equalentGroup in empData so that it always using the selected equalentGroup
            if (whatEvaluation === "edited") {
                dataH[0].forEach(item => { item.equalentGroup = item.editedEqualentGroup != null ? item.editedEqualentGroup : 0 })
            }
            if (whatEvaluation === "extLevel") {
                dataH[0].forEach(item => { item.equalentGroup = item.extEqualentGroup != null ? item.extEqualentGroup : 0 })
            }
            if (whatEvaluation === "original") {
                dataH[0].forEach(item => { item.equalentGroup = item.equalentGroup != null ? item.equalentGroup : 0 })
            }
            this.setState({ empData: dataH[0] })

            this.groupHierarchy()

            //set nr of selected equalentgroups
            if (whatEvaluation === "original") {
                this.setState({ nrOfEqualentGroups: data[3] })
            } if (whatEvaluation === "edited") {
                this.setState({ nrOfEqualentGroups: data[7] })
            } if (whatEvaluation === "extLevel") {
                const highestValue = Math.max(...dataH[0].map(obj => obj.extEqualentGroup));
                this.setState({ nrOfEqualentGroups: highestValue })
            }

            //set percent to state
            let percentStatus = localStorage.getItem("settingPercent");
            percentStatus === "true" ? percentStatus = true : percentStatus = false
            this.setState({ showPercent: percentStatus })

            //set name status to state
            let hideName = localStorage.getItem("settingName");
            hideName === "true" ? hideName = true : hideName = false
            this.setState({ hideName: hideName })

            //set age status to state
            const ageChart = data[4]
            if (ageChart != undefined) {
                if (ageChart.length > 0) {
                    let showAgeChart = ageChart[0].showAgeChart
                    if (showAgeChart === "on" || showAgeChart === "adminOn") { this.setState({ xSetting: true }) }
                }
            }
            //set showExtraSetting to state
            const extraSetting = data[5]
            if (extraSetting != undefined) {
                if (extraSetting.length > 0) {
                    let showExtraSetting = extraSetting[0].showExtraSetting
                    if (showExtraSetting === "on" || showExtraSetting === "adminOn") { this.setState({ showHideExtraIcon: "on" }) }
                }
            }

            let employees = data[0]
            const comments = data[1]
            const commentsLastYear = data[2]
            const allEmployees = employees

            //*** GET DATA FOR EACH GROUP ***
            //Group all data that has the same equalent group
            const groupedData = Object.values(allEmployees.reduce((result, item) => {
                let whatGroup = this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : this.state.whatEvaluation === "extLevel" ? "extEqualentGroup" : "equalentGroup"
                // let g = item.extEqualentGroup; // CHANGE THIS IF WE ARE USING ANOTHER EQUALENT GROUP
                let g = item[whatGroup]
                const positionTitle = item.positionTitle;
                const salary = item.totalSalary;
                const gender = item.gender;
                const groupNameArbVardering = item.groupNameArbVardering

                if (!result[g]) {
                    result[g] = [];
                }

                const group = result[g].find(group => group.groupNameArbVardering === groupNameArbVardering);
                if (group) {
                    group.groups.push(item);
                    group.averageSalary += salary;
                    group.numberOfPersons++;
                    group.percentageOfWomen += (gender === 'woman' ? 1 : 0);
                } else {
                    result[g].push({
                        g,
                        positionTitle,
                        groupNameArbVardering, //added this
                        groups: [item],
                        averageSalary: salary,
                        numberOfPersons: 1,
                        percentageOfWomen: (gender === 'woman' ? 1 : 0)
                    });
                }

                return result;
            }, {}));

            groupedData.forEach(gGroup => {
                gGroup.forEach(positionGroup => {
                    positionGroup.averageSalary /= positionGroup.numberOfPersons;
                    positionGroup.percentageOfWomen = (positionGroup.percentageOfWomen / positionGroup.numberOfPersons) * 100;
                });
            });
            this.setState({ dataByGroup: groupedData })

            //Sort comments by date 
            const commentsByDate = comments.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
            const commentsByDateLastYear = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

            //Set state for comments
            this.setState({
                comments: commentsByDate,
                commentsLastYear: commentsByDateLastYear
            })
            //Set alla employees to state
            this.setState({
                allEmp: allEmployees
            })

            // Lastly, run calcSalaryRanges to update chart data, if we do not have any data saved before, use the AI and create ranges without progression and any effects. This will show the actual ranges right now
            if (savedSalaryRanges.length < 1) {
                this.setState({
                    autoCalculation: "yes",
                    standardDeviation: "no", // "one" or "two" standardDeviations, or "no"
                    createProgression: "no",
                    marketWeight: 0,
                })
            }
            this.calcSalaryRanges(true);
            this.setState({
                autoCalculation: "yes",
                standardDeviation: "two",
                createProgression: "yes",
                marketWeight: 50,
            })

            // Get jobDescriptions and fix data for when selcting position for the job data
            const originalArray = dataH[0]
            const positionsAndEqualWorks = Object.entries(
                originalArray.reduce((acc, { groupNameArbVardering, positionTitle }) => {
                    acc[groupNameArbVardering] = acc[groupNameArbVardering] || new Set(); // Use a Set to store unique titles
                    acc[groupNameArbVardering].add(positionTitle); // Add positionTitle to the Set (automatically handles uniqueness)
                    return acc;
                }, {})
            ).map(([groupNameArbVardering, positionTitlesSet]) => ({
                groupNameArbVardering,
                positionTitles: [...positionTitlesSet] // Convert the Set back to an array
            }));

            const jobDesc = dataH[3][0]
            this.setState({ jobDescriptions: jobDesc, positionsAndEqualWorks: positionsAndEqualWorks })
        }

        getDataAndComments();
    }


    groupHierarchy = () => {
        function getRandomColor() {
            // Generate a random color in hexadecimal format
            return "#" + ((Math.random() * 0xFFFFFF) << 0).toString(16).padStart(6, '0');
        }
        //1. Create Hierarchy
        const originalArray = this.state.empData
        const groupedData = [];
        originalArray.forEach(item => {
            const { family, mainFunction, subFunction, subFunction2, subFunction3, groupNameArbVardering, positionTitle, color } = item;
            let equalentGroupVersion = this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : this.state.whatEvaluation === "extLevel" ? "extEqualentGroup" : "equalentGroup"
            let equalentGroup = item[equalentGroupVersion]
            let familyObj = groupedData.find(entry => entry.name === family);
            if (!familyObj) {
                familyObj = { name: family, mainFunctions: [], color: color !== null ? color : getRandomColor(), edited: color !== null ? "no" : "yes" };
                groupedData.push(familyObj);
            }

            let mainFunctionObj = familyObj.mainFunctions.find(entry => entry.name === mainFunction);
            if (!mainFunctionObj) {
                mainFunctionObj = { name: mainFunction, subFunctions: [] };
                familyObj.mainFunctions.push(mainFunctionObj);
            }

            let subFunctionObj = mainFunctionObj.subFunctions.find(entry => entry.name === subFunction);
            if (!subFunctionObj) {
                subFunctionObj = { name: subFunction, subFunction2s: [] };
                mainFunctionObj.subFunctions.push(subFunctionObj);
            }

            let subFunction2Obj = subFunctionObj.subFunction2s.find(entry => entry.name === subFunction2);
            if (!subFunction2Obj) {
                subFunction2Obj = { name: subFunction2, subFunction3s: [] };
                subFunctionObj.subFunction2s.push(subFunction2Obj);
            }

            let subFunction3Obj = subFunction2Obj.subFunction3s.find(entry => entry.name === subFunction3);
            if (!subFunction3Obj) {
                subFunction3Obj = { name: subFunction3, equalentGroups: [] };
                subFunction2Obj.subFunction3s.push(subFunction3Obj);
            }

            let equalentGroupObj = subFunction3Obj.equalentGroups.find(entry => entry.name === equalentGroup);
            if (!equalentGroupObj) {
                equalentGroupObj = { name: equalentGroup, groupNameArbVarderings: [] };
                subFunction3Obj.equalentGroups.push(equalentGroupObj);
            }

            let groupNameArbVarderingObj = equalentGroupObj.groupNameArbVarderings.find(entry => entry.name === groupNameArbVardering);
            if (!groupNameArbVarderingObj) {
                groupNameArbVarderingObj = { name: groupNameArbVardering, positionTitles: [] };
                equalentGroupObj.groupNameArbVarderings.push(groupNameArbVarderingObj);
            }

            groupNameArbVarderingObj.positionTitles.push(positionTitle);
        });

        //2. Check if there is any functions or subFunctions
        let nrOfFunctions = 0;
        for (const obj of originalArray) {
            if (obj.subFunction3 !== null && obj.subFunction3 !== "") {
                nrOfFunctions = 4;
                break;
            } else if (obj.subFunction2 !== null && obj.subFunction2 !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 3);
            } else if (obj.subFunction !== null && obj.subFunction !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 2);
            } else if (obj.mainFunction !== null && obj.mainFunction !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 1);
            }
        }

        //3. Save color to empData individuals, this is because we need it for the saving
        originalArray.forEach(originalItem => {
            const matchingGroupedItem = groupedData.find(groupedItem => groupedItem.name === originalItem.family);
            if (matchingGroupedItem) {
                originalItem.color = matchingGroupedItem.color;
            }
        });

        //4. Save to State
        this.setState({ nrOfSubFamilies: nrOfFunctions, hierarchy: groupedData })
    }














    //handle click function for hierarchical jobs. 
    //1. IF CLICKING ON WOMEN GROUP, MARK EVERY GROUP THAT IS NOT WOMEN AND HAS HIGHER PAY BUT LOWER DEMANDS
    //2. IF CLICKING ON MEN OR EQUAL GROUP, MARK EVERY GROUP THAT IS WOMEN AND HAS LOWER PAY AND HIGHER DEMANDS (LIKE IT IS NOW, BUT ONLY FOR MEN)
    handleClick = (event) => {

        const data = JSON.parse(event.currentTarget.id)

        //First, check if selectGroup1 and 2 is active, if so, the user is trying to select a group to compare
        if (this.state.selectGroup1) {
            this.setState({
                analyseGroup1: data.group,
                group1EqualGroup: data.demands,
                selectGroup1: false,
            })
            return;
        }
        if (this.state.selectGroup2) {
            this.setState({
                analyseGroup2: data.group,
                group2EqualGroup: data.demands,
                selectGroup2: false,
            })
            return;
        }

        //If user is not trying to select a group to compare, then show groups in green.
        if (this.state.clickedGroup === data.group) {

            this.setState({
                clickedGroup: "",
                avgSalary: null,
                womenPercent: null,
                demands: null
            })
        } else {
            this.setState({
                clickedGroup: data.group,
                avgSalary: data.avgSalary,
                womenPercent: data.womenPercent,
                demands: data.demands
            })
        }
    }

    editComment = (event) => {
        //let comment = ""
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.comments

        // if (text === "") { return; } //If no text changed, don't do anything

        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });

        this.setState({ comments: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    deleteComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        let del = this.state.deletedComments
        if (removedComment[0].reuseComment !== "Yes") { del = this.state.deletedComments.concat(removedComment) } //add removedComment to deletedComments array:

        this.setState({
            comments: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    //Save changes in comments
    saveButton = () => {
        //send only updatedComments to server for saving (and also the deleted comments)
        const updatedComments = this.state.comments.filter(el => el.updated)
        const deletedComments = this.state.deletedComments

        const saveData = async () => {
            const commentData = {
                changes: updatedComments,
                deleted: deletedComments
            }

            let response = await fetchAuth(`/api/saveEqualCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();
            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)

                this.setState({ saveButtonStatus: "Sparat" })

            } else { alert('Ett problem uppstod vid sparningen. Vänligen prova igen.') }
        };
        saveData();

        //Remove property updated if saved
        const removeUpdatedProperty = this.state.comments.map(el => {
            if (el.updated) {
                delete el.updated;
                return el;
            }
            return el;
        });
        this.setState({ comments: removeUpdatedProperty })
    }

    saveButtonFunc = () => {
        //We can get list items from empData on component mount, this way we do not need to save list items seperately
        const saveData = async () => {
            const filteredArray = this.state.empData.filter(obj => obj.hasOwnProperty("edited") && obj.edited === "Yes"); //Only get employees with edited: "Yes"
            // const salaryRangesCopy = JSON.parse(JSON.stringify(this.state.rangesLevels))
            let salaryRanges = this.state.rangesLevels.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryFamily = this.state.family.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryMainFunction = this.state.mainFunction.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction = this.state.subFunction.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction2 = this.state.subFunction2.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction3 = this.state.subFunction3.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction4 = this.state.subFunction4.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryIndividual = this.state.individual.filter(item => item.range !== "hide")
            let families = []
            const propertiesByGroup = {};
            filteredArray.forEach(employee => {
                if (!propertiesByGroup[employee.groupNameArbVardering]) {
                    propertiesByGroup[employee.groupNameArbVardering] = {
                        family: employee.family,
                        mainFunction: employee.mainFunction,
                        subFunction: employee.subFunction,
                        subFunction2: employee.subFunction2,
                        subFunction3: employee.subFunction3,
                        color: employee.color
                    };
                }
            });
            families = Object.entries(propertiesByGroup).map(([groupNameArbVardering, properties]) => ({
                groupNameArbVardering,
                ...properties
            }));

            if (this.state.activateSalRangeSave === "off") { salaryRanges = [] } // If nothing is changed nothing should be saved. Will be "on" if manually changed a salary range or used auto
            if (this.state.activateSalRangeSaveFamily === "off") { salaryFamily = [] }
            if (this.state.activateSalRangeSaveMainFunction === "off") { salaryMainFunction = [] }
            if (this.state.activateSalRangeSaveSubfunction === "off") { salarySubfunction = [] }
            if (this.state.activateSalRangeSaveSubfunction2 === "off") { salarySubfunction2 = [] }
            if (this.state.activateSalRangeSaveSubfunction3 === "off") { salarySubfunction3 = [] }
            if (this.state.activateSalRangeSaveSubfunction4 === "off") { salarySubfunction4 = [] }
            if (this.state.activateSalRangeSaveIndividual === "off") { salaryIndividual = [] }

            // Group all salaryRanges for families or functions togheter (first add key so we know what family or function they belong to)
            const salaryFamilyWithKey = salaryFamily.map(item => ({ ...item, key: 'family' }));
            const salaryMainFunctionWithKey = salaryMainFunction.map(item => ({ ...item, key: 'mainFunction' }));
            const salarySubfunctionWithKey = salarySubfunction.map(item => ({ ...item, key: 'subFunction' }));
            const salarySubfunction2WithKey = salarySubfunction2.map(item => ({ ...item, key: 'subFunction2' }));
            const salarySubfunction3WithKey = salarySubfunction3.map(item => ({ ...item, key: 'subFunction3' }));
            const salarySubfunction4WithKey = salarySubfunction4.map(item => ({ ...item, key: 'subFunction4' }));
            const salaryIndividualWithKey = salaryIndividual.map(item => ({ ...item, key: 'individual' }));
            const salaryIndividualWithKeyFiltered = salaryIndividualWithKey.filter((obj) => obj.edited === "yes")// Remove equal works if they do not have "edited: "yes"
            const subSalaryRanges = salaryFamilyWithKey.concat(salaryMainFunctionWithKey, salarySubfunctionWithKey, salarySubfunction2WithKey, salarySubfunction3WithKey, salarySubfunction4WithKey, salaryIndividualWithKeyFiltered);

            const removeThis = this.state.removeThis

            let response = await fetchAuth(`/api/saveArchitecture`, 'POST', JSON.stringify({ families: families, salaryRanges: salaryRanges, subSalaryRanges: subSalaryRanges, remove: removeThis }));
            let data = await response.json();
            if (data == 'Saved') {
                this.setState({ saveButtonStatus: "Sparat" })
            } else { alert('Ett problem uppstod vid sparningen. Vänligen prova igen.') }
        };
        saveData();
    }

    //Filter comments
    filterEqualent = () => {
        this.setState({ analysis: 'likvärdiga' })
    }
    filterHierarchy = () => {
        this.setState({ analysis: 'hierarkisk' })
    }
    filterAll = () => {
        this.setState({ analysis: 'all' })
    }

    //toggle more save fields
    toggleFields = () => {
        if (this.state.moreFields) {
            this.setState({
                moreFields: false
            })
        } else {
            this.setState({
                moreFields: true
            })
        }
    }

    //Save new comment
    //First add state for fields:
    addAnalysisEqualent = () => {
        this.setState({ addAnalysis: "likvärdiga" })
    }
    addAnalysisHierarchy = () => {
        this.setState({ addAnalysis: "hierarkisk" })
    }
    handleComment = (event) => {
        this.setState({ addComment: event.target.value })
    }
    handleReason = (event) => {
        this.setState({ addReason: event.target.value })
    }
    handleTodo = (event) => {
        this.setState({ addTodo: event.target.value })
    }
    handleDeadline = (event) => {
        this.setState({ addDeadline: event.target.value })
    }
    handleCost = (event) => {
        this.setState({ addCost: event.target.value })
    }
    handleResponsible = (event) => {
        this.setState({ addResponsible: event.target.value })
    }

    //Then, save comment if clicking save button
    saveNewComment = () => {
        if (this.state.addComment !== "") {
            const saveData = async () => {
                const commentData = {
                    notable: this.state.addComment,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    analysis: this.state.addAnalysis
                }

                let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
                let data = await response.json();

                //add todays date to temporary comment,or a previous date if we are doing it for a previous year
                let selectedYear = localStorage.getItem('year')
                if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
                let d = new Date()
                let month = d.getMonth();
                let day = d.getDate();
                const todaysDate = new Date(selectedYear, month, day);
                const dateNow = todaysDate.toISOString();

                //When saved, add the new comment to the comments array
                const toState = {
                    notable: this.state.addComment,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    equalGroup: this.state.groupTitle,
                    commentId_PK: data,
                    analysis: this.state.addAnalysis,
                    dateAdded: dateNow
                }
                const commentList = this.state.comments.concat(toState);

                //Arrange by descending date so the new comments goes to top:
                //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
                const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                //Reset fields
                if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                    this.setState({
                        addComment: "",
                        addReason: "",
                        addTodo: "",
                        addDeadline: "",
                        addCost: "",
                        addResponsible: "",
                        comments: sortedComments //add the new comment to the comments array
                    })

                } else { alert('Ett problem uppstod vid sparningen. Vänligen prova igen.') }
            };
            saveData();
        } else {
            alert('Du kan inte spara en tom notering. Vänligen fyll i fältet "Noterbart".')
        }
    }

    togglePop = () => {
        this.state.popUp ? this.setState({ popUp: false }) : this.setState({ popUp: true })
    }
    popUpInfo = () => {
        this.state.popUpInfo ? this.setState({ popUpInfo: false }) : this.setState({ popUpInfo: true })
    }

    //----------------------------- For old comments // ----------------------------- 
    reuseComment = (event) => {
        const commentId_PK = event.target.id

        //get data for comment from state
        const commentData = this.state.commentsLastYear.filter((comment) => comment.commentId_PK == commentId_PK)
        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        //Add the new comment to the comments array
        const toState = {
            notable: commentData[0].notable,
            reason: commentData[0].reason,
            todo: commentData[0].todo,
            deadline: commentData[0].deadline,
            cost: commentData[0].cost,
            responsible: commentData[0].responsible,
            equalGroup: commentData[0].equalGroup,
            commentId_PK: commentId_PK,
            analysis: commentData[0].analysis,
            dateAdded: dateNow,
            updated: 'Yes',
            reuseComment: 'Yes',
        }
        const commentList = this.state.comments.concat(toState);

        //Sort comments by descending date
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        //get all comments except this comment (to use for setting new state)
        const pastYearsComments = this.state.commentsLastYear.filter((comment) => comment.commentId_PK != commentId_PK)

        //Add comment to new state and remove from the past
        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: pastYearsComments,
        })

        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    reuseAll = () => {
        this.setState({ popUpComments: false }) //Hide popUp

        const comments = this.state.commentsLastYear.filter((comment) => comment.todo == null) //We filter out the ones with a todo, because these should be evaluated and can not be used for this year
        const commentsWithTodo = this.state.commentsLastYear.filter((comment) => comment.todo != null) //We use this to temporary update the commentsLastYearState
        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        let addedYes = comments.map(el => {
            return Object.assign({}, el, { updated: 'Yes', dateAdded: dateNow, reuseComment: 'Yes' })
        }); //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving

        const commentList = this.state.comments.concat(addedYes);
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: commentsWithTodo, //Empty last years comments. 
        })

        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    toggleOldComments = () => {
        this.state.showOldComments ? this.setState({ showOldComments: false }) : this.setState({ showOldComments: true })
    }

    toggleCommentPop = () => {
        this.state.popUpComments ? this.setState({ popUpComments: false }) : this.setState({ popUpComments: true })
    }

    AIComment = () => {
        //First, show popUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })

        //*** LOAD FROM COMMENTS HERE <------ DO LATER ***
        //1. KONTROLLERA DETTA ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF HÄMTA OCH SKRIV DET (SKRIV UNDER "DU har redan förklarat med ovanstående")
        //2. KONTROLLERA FÖREGÅENDE ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF SKRIV UNDER OCH BERÄTTA ATT FÖREGÅENDE FINNS OCH FRÅGA OM VILL HÄMTA
        //3. HÄMTA ANNARS FRÅN AUTOANALYS

        //ALT HÄMTA BARA FRÅN AUTOANALYS, IAF TILL EN BÖRJAN <--- BÖRJA MED DETTA! GÖRS GENOM ATT JAG SÄTTER AInotable och AIreason state när autonaalys har körts.

    }

    saveAIComment = () => {
        // SAVE TO DB HERE <-------- GLÖM EJ!!!!!! ***/
        let comparedArray = [this.state.analyseGroup1.replace(/\s/g, '').toLowerCase(), this.state.analyseGroup2.replace(/\s/g, '').toLowerCase()].sort()
        let compared = comparedArray[0] + comparedArray[1]

        let notable = this.state.AInotable
        let reason = this.state.AIreason
        let analyseType = this.state.AIAnalys

        //Check if empty, if so return "must add notable"
        if (notable === "") {
            alert("Du kan inte spara en tom kommentar. Fyll i noterbart rutan.")
            return;
        }

        //Save to DB:
        const saveData = async () => {
            const commentData = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                analysis: analyseType,
                comparedGroups: compared
            }

            let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();

            //add todays date to temporary comment,or a previous date if we are doing it for a previous year
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const todaysDate = new Date(selectedYear, month, day);
            const dateNow = todaysDate.toISOString();

            //When saved, add the new comment to the comments array
            const toState = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                commentId_PK: data,
                analysis: analyseType,
                dateAdded: dateNow,
                comparedGroups: compared
            }
            const commentList = this.state.comments.concat(toState);

            //Arrange by descending date so the new comments goes to top:
            //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
            const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

            if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                this.setState({
                    comments: sortedComments //add the new comment to the comments array
                })
            } else {
                alert('Ett problem uppstod vid sparningen. Vänligen prova igen.')
            }
        };
        saveData();

        //Then hide PopUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })
    }

    changeTextAINotable = (event) => {
        this.setState({ AInotable: event.target.value })
    }
    changeTextAIReason = (event) => {
        this.setState({ AIreason: event.target.value })
    }

    //For edit salary and hide person functions
    hideEditBox = () => { this.setState({ popItUp: false, popUpEditSalarySetting: false }) }
    addEditSalary = (event) => {
        let salary = Number(event.target.value)
        this.setState({ newSalary: salary })
    }
    forceUpdateApexChart = (serieZero, serieOne) => {
        return new Promise(resolve => {
            this.setState({
                groupOneEdited: serieZero,
                groupTwoEdited: serieOne,
                popItUp: false,
            })
            resolve()
        });
    }
    hideEmployee = async () => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0.
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all employees without mutating
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person from the array
        }
        if (this.state.serieIndex == 1) { //If serie 1
            serieOne = this.state.groupTwoEdited.slice(0)
            serieOne.splice(this.state.clickedPersonIndex, 1)
        }
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    popUpEditSalarySetting = () => { this.setState({ popUpEditSalarySetting: true }) }

    editSalary = async (excluded) => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieZero[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary
            } else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieZero.length === 0) { //If there is only one person in the list, push a copy
                // await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieZero.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieZero.push(thePerson)
            } else { //Else push at the start instead
                serieZero.unshift(thePerson)
            }
        }
        if (this.state.serieIndex == 1) { //If womens group
            serieOne = this.state.groupTwoEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieOne[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary //Create new person with updated salary that is not including extra
            }
            else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieOne.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieOne.length === 0) { //If there is only one person in the list, push a copy
                //  await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieOne.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieOne.push(thePerson)
            } else { //Else push at the start instead
                serieOne.unshift(thePerson)
            }
        }
        this.setState({ popUpEditSalarySetting: false })
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    editSalaryExclude = () => { this.editSalary("exclude") }

    //For arkitektur
    showMore = () => { this.state.showMore ? this.setState({ showMore: false }) : this.setState({ showMore: true }) }
    editGroup = (event) => {
        let equalWork = event.currentTarget.id
        if (this.state.editGroupPop !== equalWork) {
            //Also add mainFunction, subFunction, subFunction2 and subFunction3
            let mainFunction = ""
            let subFunction = ""
            let subFunction2 = ""
            let subFunction3 = ""
            for (let i = 0; i < this.state.empData.length; i++) {
                if (this.state.empData[i].groupNameArbVardering === equalWork) {
                    mainFunction = this.state.empData[i].mainFunction
                    subFunction = this.state.empData[i].subFunction
                    subFunction2 = this.state.empData[i].subFunction2
                    subFunction3 = this.state.empData[i].subFunction3
                    break;
                }
            }
            this.setState({
                editGroupPop: equalWork,
                selectedWorkForAutoCalc: equalWork, // use to remember the work even if we have closed the editGroupPop. This does not get deleted until next time we change it
                selectedMainFunction: mainFunction,
                selectedSubFunction: subFunction,
                selectedSubFunction2: subFunction2,
                selectedSubFunction3: subFunction3,
            })
        }

    }
    selectFamily = (event, add) => {
        let selectedFamily = add
        if (event !== "noEvent") {
            selectedFamily = event.target.value
        }
        let color = null
        //First get color if there is any
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].family === selectedFamily) {
                color = this.state.empData[i].color
                break;
            }
        }
        //Then update family
        const updatedEmpData = this.state.empData.map((job) => {
            if (job.groupNameArbVardering === this.state.editGroupPop) {
                return {
                    ...job,
                    family: selectedFamily,
                    /*   mainFunction: null,
                       subFunction: null,
                       subFunction2: null,
                       subFunction3: null,*/
                    color: color,
                    edited: "Yes"
                };
            }
            return job;
        });
        this.setState({ empData: updatedEmpData, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }
    selectMainFunction = (event, fam, add, whatS) => {
        let family = event === "noEvent" ? fam : event.target.getAttribute('data-family')
        let whatSub = event === "noEvent" ? whatS : event.target.getAttribute('data-whatSub')
        let selectedFunction = event === "noEvent" ? add : event.target.value
        if (selectedFunction === "- Ingen -" || selectedFunction === "Välj jobbfunktion" || selectedFunction === "Välj subfunktion" || selectedFunction === "Välj subfunktion2" || selectedFunction === "Välj jobbfunktion3") { selectedFunction = null }
        let color = null
        //First get color if there is any
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].family === family) {
                color = this.state.empData[i].color
                break;
            }
        }
        //Then update family
        const updatedEmpData = this.state.empData.map((job) => {
            if (job.groupNameArbVardering === this.state.editGroupPop) {
                if (whatSub === "mainFunction") {
                    return {
                        ...job,
                        mainFunction: selectedFunction,
                        /*subFunction: null,
                        subFunction2: null,
                        subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction") {
                    return {
                        ...job,
                        subFunction: selectedFunction,
                        /*  subFunction2: null,
                          subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction2") {
                    return {
                        ...job,
                        subFunction2: selectedFunction,
                        /*  subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction3") {
                    return {
                        ...job,
                        subFunction3: selectedFunction,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            return job;
        });
        this.setState({ empData: updatedEmpData, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }

    changeFamilyToList = (event) => { this.setState({ addFamilytoList: event.target.value }) }
    changeMainFunction = (event) => { this.setState({ addMainFunction: event.target.value }) }
    changeSubFunction = (event) => { this.setState({ addSubFunction: event.target.value }) }
    changeSubFunction2 = (event) => { this.setState({ addSubFunction2: event.target.value }) }
    changeSubFunction3 = (event) => { this.setState({ addSubFunction3: event.target.value }) }

    createMainFunction = (event) => { // This works with all subFunctions as well, so we do not have many functions that does the same thing
        let whatSub = event.target.getAttribute('data-whatSub')
        let addNew = ""
        let oldList = ""
        if (whatSub === "family") {
            addNew = this.state.addFamilytoList.slice()
            oldList = this.state.familySelectList.slice()
        } if (whatSub === "mainFunction") {
            addNew = this.state.addMainFunction.slice()
            oldList = this.state.mainFunctionsSelectList.slice()
        } if (whatSub === "subFunction") {
            addNew = this.state.addSubFunction.slice()
            oldList = this.state.subFunctionSelectList.slice()
        } if (whatSub === "subFunction2") {
            addNew = this.state.addSubFunction2.slice()
            oldList = this.state.subFunctionSelectList2.slice()
        } if (whatSub === "subFunction3") {
            addNew = this.state.addSubFunction3.slice()
            oldList = this.state.subFunctionSelectList3.slice()
        }
        if (addNew === "") { return } // Cant be an empty string
        if (oldList.some(item => item.trim().toLowerCase() === addNew.trim().toLowerCase())) { alert("Detta namn finns redan."); return } // Checks for duplicates
        // Find the correct position to insert the new string
        const insertIndex = oldList.findIndex((item) => item > addNew);
        // If insertIndex is -1, it means newString should be added at the end
        if (insertIndex === -1) {
            oldList.push(addNew);
        } else {
            // Insert newString at the correct position
            oldList.splice(insertIndex, 0, addNew);
        }
        oldList.sort((a, b) => a.localeCompare(b));    // sort by alphabetic order

        // Save to state
        let family = event.target.getAttribute('data-family')
        //if (whatSub === "family") { this.setState({ familySelectList: oldList, addFamilytoList: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        if (whatSub === "family") { this.setState({ familySelectList: oldList, addFamilytoList: "" }, () => { this.selectFamily("noEvent", addNew) }); } // <-- fel här. 
        if (whatSub === "mainFunction") { this.setState({ mainFunctionsSelectList: oldList, addMainFunction: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        if (whatSub === "subFunction") { this.setState({ subFunctionSelectList: oldList, addSubFunction: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        if (whatSub === "subFunction2") { this.setState({ subFunctionSelectList2: oldList, addSubFunction2: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        if (whatSub === "subFunction3") { this.setState({ subFunctionSelectList3: oldList, addSubFunction3: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        this.setState({ showInputNewFam: "hide" })
    }

    exitEditGroupPop = () => this.setState({ editGroupPop: false })

    showJobDesc = (event) => {
        this.setState({ popUpJobDesc: true, jobDescWhatGroup: event.target.dataset.egroup, jobDescSelected: event.target.dataset.egroup })
    }
    closePopUpJobDesc = () => { this.setState({ popUpJobDesc: false, jobDescWhatGroup: "", jobDescSelected: "", editJobDesc: false }) }

    zoomOut = (event) => {
        let zoom = document.documentElement.style.getPropertyValue('--zoom')
        if (zoom.length === 0) {
            document.documentElement.style.setProperty('--zoom', 'scale(0.9)')
        } else {
            let getZoomValue = zoom.substring(6, 9)
            let calculate = (Number(getZoomValue) - 0.1).toFixed(1)
            let string = calculate.toString()
            let addZoom = `scale(${string})`
            document.documentElement.style.setProperty('--zoom', addZoom)
        }
    }
    zoomIn = (event) => {
        let zoom = document.documentElement.style.getPropertyValue('--zoom')

        if (zoom.length === 0) {
            document.documentElement.style.setProperty('--zoom', 'scale(1.1)')
        } else {
            let getZoomValue = zoom.substring(6, 9)
            let calculate = (Number(getZoomValue) + 0.1).toFixed(1)
            let string = calculate.toString()
            let addZoom = `scale(${string})`
            document.documentElement.style.setProperty('--zoom', addZoom)
        }
    }
    selectView = (event) => { this.setState({ chosenView: event.target.id }) }

    openChart = () => { this.state.chartOpen ? this.setState({ chartOpen: false }) : this.setState({ chartOpen: true }) }
    openWhatChart = (event) => {
        const whatChart = event.target.getAttribute("whatChart");
        const name = event.target.getAttribute("whatName"); // is Null if not added from clicked family

        this.setState(
            { chartWhat: whatChart, chartOpen: true }, // set what type of chart (family, mainFunction, overall etc)
            () => {
                if (whatChart !== "overall" && this.state.empData.length > 0) { // If not overall, also set what function or family that is set. If none selected, use the first one in the empData list.
                    // Create a selectable list of each unique function or family
                    let values = []
                    if (whatChart !== "individual") { values = this.state.empData.map(obj => obj[whatChart]) }
                    if (whatChart === "individual") { values = this.state.empData.map(obj => obj.groupNameArbVardering) }
                    const uniqueValues = [...new Set(values)];

                    // If we just clicked the column title, select the first item in the uniqueValues
                    let firstFamilyOrFunc = ""
                    if (uniqueValues.length > 0) { firstFamilyOrFunc = uniqueValues[0]; }
                    if (typeof firstFamilyOrFunc !== 'string' && uniqueValues.length > 0) { firstFamilyOrFunc = uniqueValues[1] }
                    if (whatChart === "individual") { firstFamilyOrFunc = this.state.selectedWorkForAutoCalc }
                    if (name != null) { firstFamilyOrFunc = name }
                    let famOrFuncArray = this.state[whatChart].slice() // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
                    let arrayData = famOrFuncArray.filter((obj) => obj.name === firstFamilyOrFunc)
                    if (whatChart === "individual") {
                        let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.selectedWorkForAutoCalc)
                        // For individual/equal works x will always be saved as "0" (because otherwise it could change when user changes valuation), therefore, look up the real x-value from empData
                        let equalentGroup = this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : this.state.whatEvaluation === "extLevel" ? "extEqualentGroup" : "equalentGroup"
                        arrayDataFiltered.forEach(item => {
                            const matchingEmpItem = this.state.empData.find(empItem => empItem.groupNameArbVardering === item.name);
                            if (matchingEmpItem) {
                                item.x = matchingEmpItem[equalentGroup]
                            }
                        });
                        arrayData = arrayDataFiltered
                    }

                    this.setState({ selectedFamOrFunc: firstFamilyOrFunc, listOfFamOrFunc: uniqueValues, activeFamilyOrFunc: arrayData, }, () => {
                        this.noAutoSalaryRanges();
                        //Load comments about salary Range
                        if (this.state.showCommentSalary === true) {
                            this.setState({ showCommentSalary: false }, () => {
                                this.commentSalary()
                            })
                        }
                    });
                } else {
                    this.setState({ selectedFamOrFunc: "overall" }, () => {
                        //this.calcSalaryRanges();
                        this.noAutoSalaryRanges();
                        //Load comments about salary Range
                        if (this.state.showCommentSalary === true) {
                            this.setState({ showCommentSalary: false }, () => {
                                this.commentSalary()
                            })
                        }
                    });
                }
            })
    }
    showSalRange = () => { this.state.showSalRange ? this.setState({ showSalRange: false, showComments: false }) : this.setState({ showSalRange: true }) }
    showComments = () => {this.state.showComments ? this.setState({ showComments: false }) : this.setState({ showComments: true, showSalRange: true }) }
    copyOpen = () => { this.state.copyOpen ? this.setState({ copyOpen: false }) : this.setState({ copyOpen: true }) } // This is for green "create" button
    whatAIToCreate = (event) => { this.setState({ whatAIToCreate: event.target.value }) }
    runAIArchitecture = () => {
        let whatAIToCreate = this.state.whatAIToCreate
        if (whatAIToCreate === "families") {
            this.groupFamilies();
        } if (whatAIToCreate === "salaryRange") {
            this.salaryRangesSettingsPop();
        } if (whatAIToCreate === "fAndS") {
            this.groupFamilies();
            this.salaryRangesSettingsPop();
        }
    }
    salaryRangesSettingsPop = () => { this.state.salaryRangesOpen ? this.setState({ salaryRangesOpen: false }) : this.setState({ salaryRangesOpen: true }) }
    salaryRangesManualPop = () => {
        if (!this.state.salaryRangesManualOpen) { // If closed, open it
            // First, get all levels that are not already existing
            let chartWhat = this.state.chartWhat // // "individual", "mainFunction", "family" etc.
            let notUsedLevels = []
            let whatFam = chartWhat === "overall" ? "rangesLevels" : "activeFamilyOrFunc" // use this.state.rangesLevels if using "overall", otherwise use this.state.activeFamilyOrFunc
            let uniqueXValues = [...new Set(this.state[whatFam].map(obj => obj.x))];
            let maxXValues = this.state.nrOfEqualentGroups
            // notUsedLevels = Array.from({ length: maxXValues }, (_, i) => i + 1).filter(num => !uniqueXValues.includes(num)); // Use if just want to get all not used numbers instead of creating objects below
            notUsedLevels = Array.from({ length: maxXValues }, (_, i) => {
                const level = i + 1;
                return {
                    level,
                    display: uniqueXValues.includes(level) ? "no" : "yes"
                };
            });
            if (chartWhat === "individual") { // If individual, it should not be possible to add more levels than the one that already is there. Therefore, make the first one selectable or not (if it exist or not)
                if (uniqueXValues.length > 0) {
                    notUsedLevels = [
                        {
                            level: uniqueXValues[0],
                            display: "no"
                        }
                    ]
                } else {
                    // Get the only x value and make it selectable
                    const firstMatch = this.state.empData.find(item => item.groupNameArbVardering === this.state.selectedWorkForAutoCalc);
                    const equalentGroupValue = firstMatch ? firstMatch.equalentGroup : 0;
                    notUsedLevels = [
                        {
                            level: equalentGroupValue,
                            display: "yes"
                        }
                    ]
                }
            }
            this.setState({ nrOfSelectableEqualentGroups: notUsedLevels, salaryRangesManualOpen: true, manualLevel: "notSelected" })
        } else {
            this.setState({ salaryRangesManualOpen: false })
        }
    }
    closeManualOpen = () => { this.setState({ salaryRangesManualOpen: false }) }
    manualLevel = (event) => { this.setState({ manualLevel: event.target.value }) }
    addManualLevel = () => {
        let chartWhat = this.state.chartWhat // // "individual", "mainFunction", "family" etc.
        let level = this.state.manualLevel
        if (level === "notSelected") {
            alert("Ingen nivå vald")
            return;
        }
        let whatFam = this.state.chartWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"
        let dataNow = JSON.parse(JSON.stringify(this.state.chartWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels));
        let name = chartWhat === "individual" ? this.state.selectedWorkForAutoCalc : chartWhat === "individual" ? "overall" : this.state.selectedFamOrFunc

        // create object and add it to the state
        let newLevel = []
        let extraLevel = []
        newLevel = {
            familyOrFunction: chartWhat,
            fillColor: "#e0e0e0",
            name: name,
            range: [0, 0, 0],
            spread: 0,
            x: Number(level),
            y: 0,
        }
        extraLevel = {
            familyOrFunction: chartWhat,
            fillColor: "#ffffff",
            name: name,
            range: "hide",
            spread: 0,
            x: Number(level),
            y: 0
        }
        dataNow.push(newLevel)
        dataNow.push(extraLevel)
        this.setState({ [whatFam]: dataNow, salaryRangesManualOpen: false })
    }

    selectStandardDeviation = (event) => { this.setState({ standardDeviation: event.target.value }) }
    selectMarketPosition = (event) => { this.setState({ marketPosition: event.target.value }) }
    selectCreateProgression = (event) => { this.setState({ createProgression: event.target.value }) }
    sliderValue = (event) => {
        let value = parseInt(event.target.value);
        let snappedValue;
        if (value <= 12.5) {
            snappedValue = 0;
        } else if (value <= 37.5) {
            snappedValue = 25;
        } else if (value <= 62.5) {
            snappedValue = 50;
        } else if (value <= 87.5) {
            snappedValue = 75;
        } else {
            snappedValue = 100;
        }
        this.setState({ snappedValue: event.target.value, marketWeight: snappedValue });
    }
    handleSliderRelease = () => { let marketWeight = this.state.marketWeight; this.setState({ snappedValue: marketWeight }) } // Used to snap the range to marketWeight on mouse release
    toggleFullscreen = () => {
        this.state.fullScreen ? this.setState({ fullScreen: false }) : this.setState({ fullScreen: true })
        this.state.fullScreen ? window.scrollTo(0, 470) : window.scrollTo(0, 0)
    }
    toggleTitles = () => { this.state.toggleTitles ? this.setState({ toggleTitles: false }) : this.setState({ toggleTitles: true }) }

    groupFamilies = () => {
        let color = null
        //First get color if there is any
        /* for (let i = 0; i < this.state.empData.length; i++) {
             if (this.state.empData[i].family === selectedFamily) {
                 color = this.state.empData[i].color
                 break;
             }
         }*/

        // First, get all unique equal works
        // let uniqueWorkList = [...new Set(this.state.empData.map(emp => emp.groupNameArbVardering))];

        // Create uniqueWorkList with ssyk and associated family
        let uniqueWorkList = [...new Set(this.state.empData.map(emp => emp.groupNameArbVardering))]
            .map(groupNameArbVardering => {
                const emp = this.state.empData.find(emp => emp.groupNameArbVardering === groupNameArbVardering);
                const ssyk = emp.ssyk;
                let familyData = families.find(f => f.ssyk === ssyk); // Find associated data
                if (familyData === undefined) { familyData = { family: "Ej tilldelad", cat2: "", cat3: "" } }
                // Before return, make sure some works get another category than their SSYK code. 
                if (groupNameArbVardering.toLowerCase().includes("hr")) { familyData = { family: "HR", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("produktion")) { familyData = { family: "Tillverkning & Produktion", cat2: "", cat3: "" } } // because HR assistent is under "lon"

                return {
                    groupNameArbVardering,
                    ssyk,
                    family: familyData.family,
                    cat2: familyData.cat2,
                    cat3: familyData.cat3
                };
            });

        // Now loop all works
        let updatedEmployeeData = this.state.empData.slice(); // Copying the array

        // Now loop through all works
        for (let i = 0; i < uniqueWorkList.length; i++) {
            let theJob = uniqueWorkList[i].groupNameArbVardering;
            let theFamily = uniqueWorkList[i].family;
            let cat2 = uniqueWorkList[i].cat2;
            let cat3 = uniqueWorkList[i].cat3;
            // Update the data for each employee with the matching work
            updatedEmployeeData = updatedEmployeeData.map((job) => {
                if (job.groupNameArbVardering === theJob) {
                    return {
                        ...job,
                        family: theFamily,
                        mainFunction: cat2,
                        subFunction: cat3,
                        color: color,
                        edited: "Yes"
                    };
                }
                return job;
            });
        }

        // Update state
        this.setState({ empData: updatedEmployeeData, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }

    handleTrashClick = (level) => {
        // Get the object that should be removed using the level
        let whatState = this.state.chartWhat === "overall" ? "rangesLevels" : "activeFamilyOrFunc"
        let theObject = this.state[whatState].find(obj => obj.x === level);
        // Then add the object to remove array
        let copyState = JSON.parse(JSON.stringify(this.state.removeThis))
        copyState.push(theObject)
        // Remove the object from the current array
        let copyStateTwo = JSON.parse(JSON.stringify(this.state[whatState]))
        let updated = this.state[whatState].filter(obj => obj.x !== level);
        // Update state and save button
        this.setState({ removeThis: copyState, [whatState]: updated, saveButtonStatus: "Spara" })
    }
    showInputNewFam = (event) => { this.setState({ showInputNewFam: event.target.getAttribute('whatFam') }) }
    showInputNewFamHide = () => { this.setState({ showInputNewFam: "hide" }) }

    manualSalaryRangesUpdate = (data) => {
        // We retrieve the change from the child component, now update state, result looks like this: {value: '25000', level: '1', typeOfSal: 'max', familyOrSub: 'no'}
        // let dataNow = [...this.state.rangesLevels]; // Create copy
        //let dataNow = JSON.parse(JSON.stringify(this.state.rangesLevels));
        // let dataNow = JSON.parse(JSON.stringify(this.state.chartWhat !== "overall" ? this.state[this.state.chartWhat] : this.state.rangesLevels));
        let dataNow = JSON.parse(JSON.stringify(this.state.chartWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels));

        if (this.state.chartWhat === "overall") { // If for overall levels
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                    let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                    dataNow[i].range[whatSal] = Number(data.value)
                    if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                    // also recalculate and update mid value if min or max is set
                    if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                        let min = dataNow[i].range[0]
                        let max = dataNow[i].range[2]
                        let mid = (min + max) / 2
                        dataNow[i].range[1] = mid
                    }
                }
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {

                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }


            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ triggerChartUpdate: triggerChartUpdate, rangesLevels: dataNow, saveButtonStatus: "Spara" })
        }

        if (this.state.chartWhat !== "overall" && this.state.chartWhat !== "individual") { // If for family or function 
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide" && dataNow[i].name === this.state.selectedFamOrFunc) {
                    if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                        let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                        dataNow[i].range[whatSal] = Number(data.value)
                        if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                        // also recalculate and update mid value if min or max is set
                        if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                            let min = dataNow[i].range[0]
                            let max = dataNow[i].range[2]
                            let mid = (min + max) / 2
                            dataNow[i].range[1] = mid
                        }
                    }
                }
                //if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {
                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }
            // Then update original state
            // if x and name is same, update it. If x is not in the old array, add it
            let whatChart = this.state.chartWhat
            let savingArray = this.state[whatChart] // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
            let activeArray = dataNow // This is the activeArray that we will now save into its original array (savingArray) before we replace it with the new, activeArray

            // if x and name is same, update it. If x is not in the old array, add it
            const updatedArray = savingArray.map(savingItem => {         // Create a new array combining saving and updated objects
                const activeItem = activeArray.find(activeItem => activeItem.x === savingItem.x && activeItem.name === savingItem.name);
                return activeItem ? { ...savingItem, ...activeItem } : savingItem;
            });
            activeArray.forEach(activeItem => {         // Add new items from activeArray that do not exist in savingArray
                const exists = savingArray.some(savingItem => savingItem.x === activeItem.x && savingItem.name === activeItem.name);
                if (!exists) {
                    updatedArray.push(activeItem);
                }
            });

            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ activeFamilyOrFunc: dataNow, [whatChart]: updatedArray, saveButtonStatus: "Spara", triggerChartUpdate: triggerChartUpdate })
        }

        if (this.state.chartWhat === "individual") { // If for equal work
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide" && dataNow[i].name === this.state.selectedWorkForAutoCalc) { // If same level and equal work, and range is not "hide"
                    dataNow[i].edited = "yes"
                    if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                        let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                        dataNow[i].range[whatSal] = Number(data.value)
                        if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                        // also recalculate and update mid value if min or max is set
                        if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                            let min = dataNow[i].range[0]
                            let max = dataNow[i].range[2]
                            let mid = (min + max) / 2
                            dataNow[i].range[1] = mid
                        }
                    }
                }
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min" && dataNow[i].name === this.state.selectedWorkForAutoCalc) {
                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }
            // Then update original state
            // if x and name is same, update it. If x is not in the old array, add it
            let whatChart = this.state.chartWhat
            let savingArray = this.state[whatChart] // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
            let activeArray = dataNow // This is the activeArray that we will now save into its original array (savingArray) before we replace it with the new, activeArray
            // if x and name is same, update it. If x is not in the old array, add it
            const updatedArray = savingArray.map(savingItem => {         // Create a new array combining saving and updated objects
                const activeItem = activeArray.find(activeItem => activeItem.x === savingItem.x && activeItem.name === savingItem.name);
                return activeItem ? { ...savingItem, ...activeItem } : savingItem;
            });
            activeArray.forEach(activeItem => {         // Add new items from activeArray that do not exist in savingArray
                const exists = savingArray.some(savingItem => savingItem.x === activeItem.x && savingItem.name === activeItem.name);
                if (!exists) {
                    updatedArray.push(activeItem);
                }
            });
            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ activeFamilyOrFunc: dataNow, [whatChart]: updatedArray, saveButtonStatus: "Spara", triggerChartUpdate: triggerChartUpdate })

        }




        // Activate save for the selected type.
        if (this.state.chartWhat === "overall") { this.setState({ activateSalRangeSave: "on" }) }
        if (this.state.chartWhat === "family") { this.setState({ activateSalRangeSaveFamily: "on" }) }
        if (this.state.chartWhat === "mainFunction") { this.setState({ activateSalRangeSaveMainFunction: "on" }) }
        if (this.state.chartWhat === "subFunction") { this.setState({ activateSalRangeSaveSubfunction: "on" }) }
        if (this.state.chartWhat === "subFunction2") { this.setState({ activateSalRangeSaveSubfunction2: "on" }) }
        if (this.state.chartWhat === "subFunction3") { this.setState({ activateSalRangeSaveSubfunction3: "on" }) }
        if (this.state.chartWhat === "subFunction4") { this.setState({ activateSalRangeSaveSubfunction4: "on" }) }
        if (this.state.chartWhat === "individual") { this.setState({ activateSalRangeSaveIndividual: "on" }) }

        // this.calcSalaryRanges(); // We also need to redraw the salaryRanges chart here. Should not rerun calcSalaryRanges but just make the changes in a new function.
    }

    closeAutoCalc = () => { this.setState({ salaryRangesOpen: false }) }

    autoCalcSalaryRanges = () => {
        this.setState({ autoCalculation: 'yes' }, () => {
            this.calcSalaryRanges();
        });
    }

    noAutoSalaryRanges = () => {
        // First, make sure there is ranges saved since before, otherwise add it.
        /*    let whatLevels = this.state.chartWhat !== "overall" ? this.state.chartWhat : "rangesLevels" // If job family or function selected, save different saveState. The saveStates are "rangesLevels" (for overall), mainFunciton, subFunction, subFunction2, subFunction3
            let whatFamily = this.state.selectedFamOrFunc
            let filteredFamily = this.state[whatLevels].filter((obj) => obj.name === whatFamily);
    
    
            if(filteredFamily.length < 1) {
                this.state[whatLevels] = []
            }*/



        /* this.setState({
             p10: p10,
             p25: p25,
             median: median,
             p75: p75,
             p90: p90,
             employeesInChartLevels: employees,
             //rangesLevels: ranges,
             [whatLevels]: ranges,
             salaryRangesOpen: false,
             chartOpen: chartOpen,
             triggerChartUpdate: triggerChartUpdate,
         })*/


        this.setState({ autoCalculation: 'no' }, () => {
            this.calcSalaryRanges();
        });
    }

    selectFamilyForRanges = (event) => {
        let selectedName = event.target.value
        // Before changing to a new family, save the changes made for the past one
        let whatChart = this.state.chartWhat
        let savingArray = this.state[whatChart].slice() // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
        let activeArray = this.state.activeFamilyOrFunc // This is the activeArray that we will now save into its original array (savingArray) before we replace it with the new, activeArray
        let pastName = this.state.selectedFamOrFunc
        let activeArrayCopy = JSON.parse(JSON.stringify(activeArray)); // Create a deep copy of ranges


        // First, remove all items from savingArray (the original array) that contains the family name
        let filteredArray = savingArray.filter(item => item.name !== pastName);
        // Now add the new items into it
        let newItemsAdded = filteredArray.concat(activeArray)
        this.setState({ [whatChart]: newItemsAdded }, () => { // Saves the past array
            // Now update the activeArray to the new selected one
            let savingArrayFiltered = savingArray.filter((obj) => obj.name === selectedName);  // Get what type of family or function we are using and filter by the family or func name
            // If equal work, then get the x-value so it fits correctly in the chart <-- OBS!!! Move under row 3443 instead
            if (whatChart === "individual") {
                savingArrayFiltered.forEach(item => {
                    const matchingEmpItem = this.state.empData.find(empItem => empItem.groupNameArbVardering === item.name);
                    if (matchingEmpItem) {
                        item.x = matchingEmpItem.equalentGroup;
                    }
                });
            }
            // Make sure that there is always two objects with the same x-values (so that the chart always shows white at the bottom) <-- No longer necessary
            /*  let xCount = savingArrayFiltered.reduce((acc, obj) => { // Step 1: Count occurrences of each x value
                  acc[obj.x] = (acc[obj.x] || 0) + 1;
                  return acc;
              }, {});
              let xToDuplicate = Object.keys(xCount).filter(x => xCount[x] === 1);  // Step 2: Identify x values that appear only once
              xToDuplicate.forEach(x => { // Step 3: Create new objects for each unique x value and add to array
                  let originalObject = savingArrayFiltered.find(obj => obj.x == x);
                  let newObject = { ...originalObject, y: originalObject.range[0], range: "hide", fillColor: "#ffffff" }; // Modify properties as needed
                  savingArrayFiltered.push(newObject);
              });
  */

            this.setState({ selectedFamOrFunc: selectedName, activeFamilyOrFunc: savingArrayFiltered, selectedWorkForAutoCalc: selectedName }, () => {
                this.noAutoSalaryRanges(); // Run calculation
            });
        }
        );
    }

    calcSalaryRanges = (calledOnLoad) => { // calledOnLoad is true if it was called in componentDidMount. Used to inactivate save if no changes has been made
        // 1. Get all employees and add Y as salary excluding benefits and X as the equalentGroup or extEqualentGroup.
        const empData = this.state.empData.slice()
        let employees = empData.map(obj => ({
            ...obj, // Spread the original object (get all properties)
            y: obj.salary,
            x: obj.equalentGroup
        }));
        // Use family or function if that was selected
        if (this.state.chartWhat !== "overall" && this.state.chartWhat !== "individual") {
            const chartWhat = this.state.chartWhat //mainFunction, subFunction, subFunction2, subFunction3
            const filteredEmployees = employees.filter((obj) => obj[chartWhat] === this.state.selectedFamOrFunc);
            employees = filteredEmployees // We now only do calculations for the employees in this function or family
        }
        // Use individual if that was selected
        if (this.state.chartWhat === "individual") {
            const filteredEmployees = employees.filter((obj) => obj.groupNameArbVardering === this.state.selectedWorkForAutoCalc);
            let addNameAndEdited = filteredEmployees.map(item => ({ ...item, name: this.state.selectedWorkForAutoCalc, edited: "yes" }));
            employees = addNameAndEdited // We now only do calculations for the employees with the same equal group
        }

        // 2. Get market salaries (P10, P25, P50, P75, P90)

        // 2.1) First, get all ssyk codes for the jobs. We have this inside the employees variable
        // Group ssyk codes by equalentGroup and remove duplicates
        const ssykByEqualentGroup = employees.reduce((acc, employee) => {
            if (!acc[employee.equalentGroup]) {  // If equalentGroup doesn't exist in the accumulator, create it with an empty Set
                acc[employee.equalentGroup] = new Set();
            }
            acc[employee.equalentGroup].add(employee.ssyk);   // Add ssyk code to the corresponding equalentGroup set
            return acc;
        }, {});
        const ssykArray = Object.entries(ssykByEqualentGroup).map(([equalentGroup, ssykSet]) => ({ // Convert the grouped object into an array of objects with removed duplicates
            equalentGroup: parseInt(equalentGroup),
            ssykCodes: Array.from(ssykSet)
        }));
        // 2.2) Now get the market salaries for each ssyk code inside the array. This can be found in this.state.marketSalaries
        const marketData = this.state.marketSalaries
        ssykArray.forEach(item => {
            let totalMedian = 0;
            let totalP10 = 0;
            let totalP25 = 0;
            let totalP75 = 0;
            let totalP90 = 0;
            let totalP10Privat = 0;
            let totalP25Privat = 0;
            let totalP75Privat = 0;
            let totalP90Privat = 0;
            let totalP10Offentlig = 0;
            let totalP25Offentlig = 0;
            let totalP75Offentlig = 0;
            let totalP90Offentlig = 0;
            let totalMedianPrivat = 0;
            let totalMedianOffentlig = 0;

            let count = 0;
            item.ssykCodes.forEach(ssykCode => {
                const marketItem = marketData.find(data => data.ssyk === ssykCode); // Find the corresponding market data for the ssyk code
                if (marketItem) {  // If market data is found, add its marketSalary to totalMedian
                    totalMedian += marketItem.median;
                    totalP10 += marketItem.percentile10;
                    totalP25 += marketItem.percentile25;
                    totalP75 += marketItem.percentile75;
                    totalP90 += marketItem.percentile90;
                    totalP10Privat += marketItem.percentile10Privat;
                    totalP25Privat += marketItem.percentile25Privat;
                    totalP75Privat += marketItem.percentile75Privat;
                    totalP90Privat += marketItem.percentile90Privat;
                    totalP10Offentlig += marketItem.percentile10Offentlig;
                    totalP25Offentlig += marketItem.percentile25Offentlig;
                    totalP75Offentlig += marketItem.percentile75Offentlig;
                    totalP90Offentlig += marketItem.percentile90Offentlig;
                    totalMedianPrivat += marketItem.medianPrivat;
                    totalMedianOffentlig += marketItem.medianOffentlig;
                    count++;
                }
            });
            // Calculate the average market salary
            const averageMedian = count > 0 ? totalMedian / count : 0;
            const averageP10 = count > 0 ? totalP10 / count : 0;
            const averageP25 = count > 0 ? totalP25 / count : 0;
            const averageP75 = count > 0 ? totalP75 / count : 0;
            const averageP90 = count > 0 ? totalP90 / count : 0;
            const averageP10Privat = count > 0 ? totalP10Privat / count : 0;
            const averageP25Privat = count > 0 ? totalP25Privat / count : 0;
            const averageP75Privat = count > 0 ? totalP75Privat / count : 0;
            const averageP90Privat = count > 0 ? totalP90Privat / count : 0;
            const averageP10Offentlig = count > 0 ? totalP10Offentlig / count : 0;
            const averageP25Offentlig = count > 0 ? totalP25Offentlig / count : 0;
            const averageP75Offentlig = count > 0 ? totalP75Offentlig / count : 0;
            const averageP90Offentlig = count > 0 ? totalP90Offentlig / count : 0;
            const averageMedianPrivat = count > 0 ? totalMedianPrivat / count : 0;
            const averageMedianOffentlig = count > 0 ? totalMedianOffentlig / count : 0;
            // Add the average for the market salaries to the item in ssykArray
            item.median = averageMedian;
            item.averageP10 = averageP10;
            item.averageP25 = averageP25;
            item.averageP75 = averageP75;
            item.averageP90 = averageP90;
            item.averageP10Privat = averageP10Privat;
            item.averageP25Privat = averageP25Privat;
            item.averageP75Privat = averageP75Privat;
            item.averageP90Privat = averageP90Privat;
            item.averageP10Offentlig = averageP10Offentlig;
            item.averageP25Offentlig = averageP25Offentlig;
            item.averageP75Offentlig = averageP75Offentlig;
            item.averageP90Offentlig = averageP90Offentlig;
            item.averageMedianPrivat = averageMedianPrivat;
            item.averageMedianOffentlig = averageMedianOffentlig;
        });

        // 2.3 To make the data fit the chart, now create a seperate array for each percentile and add a "p" property with a "p" value. Like this:
        let p10 = []
        let p25 = []
        let median = []
        let p75 = []
        let p90 = []
        for (let i = 0; i < ssykArray.length; i++) {
            const per10 = {
                y: ssykArray[i].averageP10,
                x: ssykArray[i].equalentGroup,
                p: "p"
            };
            const per25 = {
                y: ssykArray[i].averageP25,
                x: ssykArray[i].equalentGroup,
                p: "p"
            };
            const per50 = {
                y: ssykArray[i].median,
                x: ssykArray[i].equalentGroup,
                p: "p"
            };
            const per75 = {
                y: ssykArray[i].averageP75,
                x: ssykArray[i].equalentGroup,
                p: "p"
            };
            const per90 = {
                y: ssykArray[i].averageP90,
                x: ssykArray[i].equalentGroup,
                p: "p"
            };
            p10.push(per10);
            p25.push(per25);
            median.push(per50);
            p75.push(per75);
            p90.push(per90);
        }
        // 3. Create the salary ranges (floating boxes) based on market (as we got above, and internal (as is calculated below) 
        if (this.state.autoCalculation === "yes") {
            // 3. A) - First get all Percentile values for the internal employee data (we already got the market p-values from above)

            // Extract unique x values
            const uniqueXValues = [...new Set(employees.map(obj => obj.x))];
            // Calculate percentiles for each unique x value
            const percentilesByX = {};

            uniqueXValues.forEach(xValue => {
                const filteredData = employees.filter(obj => obj.x === xValue);   // Filter data based on the current x value
                const salaries = filteredData.map(obj => obj.salary); // Extract salaries from filtered data
                salaries.sort((a, b) => a - b); // Sort the salaries in ascending order

                // Function to calculate percentile
                let useStandardDeviation = this.state.standardDeviation
                function percentile(p) {

                    // Before we calculate percentiles for percentile 0 and 100 (highest and lowest salary), lets remove the extrem values with standard deviation
                    if (p === 0 || p === 100) {
                        if (useStandardDeviation === "one" || useStandardDeviation === "two") {
                            let editedSalaries = salaries.slice() // Create copy so we dont affect the salaries array
                            const mean = editedSalaries.reduce((sum, salary) => sum + salary, 0) / editedSalaries.length; // Calculate mean
                            const deviation = Math.sqrt(editedSalaries.reduce((sum, salary) => sum + Math.pow(salary - mean, 2), 0) / editedSalaries.length); // Calculate standard deviation
                            const deviationThreshold = useStandardDeviation === "two" ? 2 : 1; // using 2 standard deviations, change if needed
                            // Calculate minimum and maximum salary based on mean and standard deviation
                            const minSalary = Math.max(mean - deviationThreshold * deviation, Math.min(...editedSalaries));
                            const maxSalary = Math.min(mean + deviationThreshold * deviation, Math.max(...editedSalaries));
                            if (p === 0) return minSalary;
                            if (p === 100) return maxSalary;
                        } else {
                            if (p === 0) return salaries[0];
                            if (p === 100) return salaries[salaries.length - 1];
                        }
                    }

                    const index = (p / 100) * (salaries.length - 1);
                    const lowerIndex = Math.floor(index);
                    const upperIndex = Math.ceil(index);
                    if (lowerIndex === upperIndex) {
                        return salaries[lowerIndex];
                    }
                    const lowerValue = salaries[lowerIndex];
                    const upperValue = salaries[upperIndex];
                    return lowerValue + (upperValue - lowerValue) * (index - lowerIndex);
                }
                // Calculate percentiles
                let P10 = Math.round(percentile(10));
                let P25 = Math.round(percentile(25));
                let P50 = Math.round(percentile(50));
                let P75 = Math.round(percentile(75));
                let P90 = Math.round(percentile(90));
                let P0 = Math.round(percentile(0)); // The lowest salary 
                let P100 = Math.round(percentile(100)); // The highest salary

                // Store percentiles for current x value
                percentilesByX[xValue] = { P10, P25, P50, P75, P90, P0, P100 };
            });
            // Now we have the P-values for all levels.
            // Internal percentiles are inside the variable percentilesByX. To access a specific level we do percentilesByX[2], for a specific percentile: percentilesByX[2].P50
            // Market percentiles is inside the variables p10, p25, median, p75, p90 (small letters)


            // 3. B) - Now create the ranges based on internal and market data, then update chart

            // Settings overall:
            let marketPosition = this.state.marketPosition

            let weightMarket = this.state.marketWeight / 100 // weightInternal will fill the rest up till 100%

            let ranges = []
            let weightInternal = 1 - weightMarket

            let midpointP = median
            for (let i = 0; i < midpointP.length; i++) {

                // Get the level
                let theLevel = midpointP[i].x;
                if (Number.isNaN(theLevel)) {
                    theLevel = "null"
                }

                let minInternal = percentilesByX[theLevel].P0; // This uses standardDeviation if the setting is on in state (which it is by default)
                let maxInternal = percentilesByX[theLevel].P100; // This uses standardDeviation if the setting is on in state (which it is by default)

                //Get the range for market
                let minMarket = p10[i].y;
                let maxMarket = p90[i].y;
                if (marketPosition === "lag") { minMarket = p10[i].y; maxMarket = p75[i].y; }
                if (marketPosition === "lead") { minMarket = p25[i].y; maxMarket = p90[i].y; }
                //Get the average range (the min and max salary, takes weight for market vs internal into account)
                //   let avgMin = ((minInternal * weightInternal) + (minMarket * weightMarket));
                //   let avgMax = ((maxInternal * weightInternal) + (maxMarket * weightMarket));

                let avgMinO = ((minInternal * weightInternal) + (minMarket * weightMarket));
                let avgMaxO = ((maxInternal * weightInternal) + (maxMarket * weightMarket));
                let avgMin = Math.floor(avgMinO / 1000) * 1000; // round down to lowest 1000, for example 32888 will be 32000
                let avgMax = Math.floor(avgMaxO / 1000) * 1000; // round down to lowest 1000, for example 32888 will be 32000
                //Get spread for average range
                let midpoint = (avgMax + avgMin) / 2

                // create range
                let bottom = { y: avgMin, x: midpointP[i].x, fillColor: "#fff", range: "hide" /*[min, midpoint, max]*/ }
                let top = { y: avgMax, x: midpointP[i].x, fillColor: "#e0e0e0", range: [avgMin, midpoint, avgMax] }
                if (this.state.chartWhat === "individual") { // Add name and edited "yes if it is for individual"
                    bottom = { y: avgMin, x: midpointP[i].x, fillColor: "#fff", range: "hide", name: this.state.selectedWorkForAutoCalc /*[min, midpoint, max]*/ }
                    top = { y: avgMax, x: midpointP[i].x, fillColor: "#e0e0e0", range: [avgMin, midpoint, avgMax], name: this.state.selectedWorkForAutoCalc, edited: "yes" }
                }
                ranges.push(top)
                ranges.push(bottom)
            }

            // Create progression 
            if (this.state.createProgression === "yes" && uniqueXValues.length > 1) { // If set to "no" or there is only one x-value, do not calculate progression

                let rangesCopy = JSON.parse(JSON.stringify(ranges)); // Create a deep copy of ranges
                const encounteredX = {};  // Object to keep track of encountered x values
                const uniqueXRanges = rangesCopy.filter(obj => { // Filter the array to keep only the first object with each x value
                    if (!encounteredX[obj.x]) {
                        encounteredX[obj.x] = true; // Mark the x value as encountered
                        return true; // Keep this object
                    }
                    return false; // Discard this object
                });

                // Also get the unique x objects for the old values (we wont change them)
                let rangesCopyTwo = JSON.parse(JSON.stringify(ranges)); // Create a deep copy of ranges
                const encounteredXPrev = {};  // Object to keep track of encountered x values
                const rangesPrev = rangesCopyTwo.filter(obj => { // Filter the array to keep only the first object with each x value
                    if (!encounteredXPrev[obj.x]) {
                        encounteredXPrev[obj.x] = true; // Mark the x value as encountered
                        return true; // Keep this object
                    }
                    return false; // Discard this object
                });

                // Calculate average midpoint from all levels
                let sum = 0;
                for (let i = 0; i < uniqueXRanges.length; i++) {
                    sum += uniqueXRanges[i].range[1];
                }
                let average = sum / uniqueXRanges.length;

                // Now, make it so the progress follows the average

                // Get the lowest and highest midpoints
                let highestMidpointLevel = uniqueXRanges[uniqueXRanges.length - 1].range[1];
                let lowestMidpointLevel = uniqueXRanges[0].range[1];
                // V2. This uses the highest max an lowest min overall, no matter what level
                // Find lowest and highest midpoint levels
                /*   let lowestMidpointLevel = Infinity;
                   let highestMidpointLevel = -Infinity;
                   uniqueXRanges.forEach(object => {
                       lowestMidpointLevel = Math.min(lowestMidpointLevel, object.range[1]);
                       highestMidpointLevel = Math.max(highestMidpointLevel, object.range[1]);
                   });*/

                // Calculate average midpoint level 
                let totalMidpointLevels = 0;
                uniqueXRanges.forEach(object => {
                    totalMidpointLevels += object.range[1]; // Adding up midpoints
                });
                const averageMidpointLevel = totalMidpointLevels / uniqueXRanges.length;

                // Calculate new midpoint levels
                const spread = highestMidpointLevel - lowestMidpointLevel;
                const increment = spread / (uniqueXRanges.length - 1); // Increment value

                let currentMidpointLevel = lowestMidpointLevel;
                uniqueXRanges.forEach((object, index) => {
                    if (index > 0) {
                        currentMidpointLevel += increment;
                    }

                    object.range[1] = currentMidpointLevel;
                    let objMax = Math.round(currentMidpointLevel * (1.25))
                    let objMin = Math.round(currentMidpointLevel * (0.75))

                    // Check how the level fits its real range
                    let prevObj = rangesPrev.find(obj => obj.x === object.x);
                    let prevMin = prevObj.range[0]
                    let prevMax = prevObj.range[2]

                    // Before changing the range (if needed), get the values from past and next level, will be used to make sure we stay inside the boundaries and follow the progression
                    let pastMax = objMax
                    let pastMin = objMin
                    if (index > 0) { // If not the first item
                        pastMax = uniqueXRanges[index - 1].range[2]
                        pastMin = uniqueXRanges[index - 1].range[0]
                    }
                    let nextMidpoint = currentMidpointLevel + increment
                    let nextMax = Math.round(nextMidpoint * (1.25))
                    let nextMin = Math.round(nextMidpoint * (0.75))

                    // Adjust the ranges so there are as close to the original min and max but still insied the boundaries. Otherwise all ranges would be exactly the same spread and difference
                    let adjustRanges = (originalNumber, newNumber, lowBoundary, highBoundary, whatRange, nowBoundary) => {
                        if (newNumber < lowBoundary) {  // Ensure the new number is within the boundaries
                            newNumber = lowBoundary;
                        } else if (newNumber > highBoundary) {
                            newNumber = highBoundary;
                        }
                        let difference = Math.abs(originalNumber - newNumber);   // Calculate the difference between the original and new numbers
                        if (originalNumber < newNumber) { // Move new number closer to original, within boundaries
                            newNumber = Math.max(lowBoundary, newNumber - difference);
                        } else {
                            newNumber = Math.min(highBoundary, newNumber + difference);
                        }
                        // Make sure it is not shrinked more than original
                        if (whatRange === 2) { // For max range number
                            if (newNumber < nowBoundary) {
                                newNumber = objMax
                            }
                        }
                        if (whatRange === 0) { // For min range number
                            if (newNumber > nowBoundary) {
                                newNumber = nowBoundary
                            }
                        }
                        object.range[whatRange] = Math.round(newNumber) // Update the number
                    }

                    adjustRanges(prevMax, objMax, pastMax, nextMax, 2, objMax) // For max
                    adjustRanges(prevMin, objMin, pastMin, nextMin, 0, objMin) // For min
                    // Calculate and set new midpoint
                    object.range[1] = Math.round((object.range[0] + object.range[2]) / 2)
                    object.y = object.range[2]
                });
                // Now update ranges to be correct for chart
                const newRange = uniqueXRanges.reduce((acc, obj) => {
                    const minY = obj.range[0]
                    acc.push(obj);
                    acc.push({ x: obj.x, y: minY, range: "hide", fillColor: "#fff" });
                    return acc;
                }, []);
                // If family or function, add the selected family/funciton name to the range
                if (this.state.chartWhat !== "overall") {
                    for (let i = 0; i < newRange.length; i++) {
                        newRange[i].name = this.state.selectedFamOrFunc
                    }
                }

                // Round everything down to nearest 100, and recalculate midpoint and spread
                for (let obj of newRange) {
                    obj.y = Math.floor(obj.y / 1000) * 1000; // Rounds down to nearest 1000
                    if (Array.isArray(obj.range)) { // If range is not "hide" but an array
                        obj.range = obj.range.map(num => Math.floor(num / 1000) * 1000); // Rounds down to nearest 1000
                        // Recalculate midpoint and spread
                        obj.range[1] = (obj.range[0] + obj.range[2]) / 2
                        obj.spread = ((obj.range[2] - obj.range[0]) / obj.range[0]) * 100;
                    }
                }
                // Save updated ranges
                ranges = newRange

                // Add ranges that is not in the created array and push it to delete array (will be deleted when saved)
                const removeArray = [];
                const newRangeXSet = new Set(newRange.map(item => item.x));
                const uniqueXSet = new Set();
                let whatC = this.state.chartWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"
                this.state[whatC].forEach(item => {
                    if (!newRangeXSet.has(item.x) && !uniqueXSet.has(item.x)) {
                        removeArray.push(item);
                        uniqueXSet.add(item.x);
                    }
                });
                this.setState({ removeThis: removeArray })
                /* The finished array "Ranges" should look like this: 
                    data: [ // X is the level
                         { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                        { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
                         { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                         { y: 60000, x: 5, range: [60000, 45000, 80000] },
                        ],  */
            }

            // 3 C Add data to chart
            // Now all market salaries are ready to be added to the chart series, for market just add the variables p10, p25, median, p75 and p90
            // For employees just add the variable "employees"
            // For ranges just add the variable "ranges"

            // ÄNDRA FÖR FAMILJ/FUNKTION HÄR. Jobbfamiljer och funktioner ska ligga i egen array. rangesLevels <---- OBS!!!!

            // Update state, this will update the ChartSalaryStructure module
            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            let activateSalRangeSave = calledOnLoad === true ? "off" : "on"
            let chartOpen = calledOnLoad === true ? false : true
            let whatLevels = this.state.chartWhat !== "overall" ? this.state.chartWhat : "rangesLevels" // If job family or function selected, save different saveState. The saveStates are "rangesLevels" (for overall), mainFunciton, subFunction, subFunction2, subFunction3
            let levelOrFamily = this.state.chartWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"

            // Except to save into activeFamilyOrFunc, wich is our temporary list, we also need to save it into the original array. 
            if (this.state.chartWhat !== "overall") {
                // Remove all items with the same name
                let savingArray = this.state[whatLevels]
                let name = this.state.selectedFamOrFunc
                let removedItems = savingArray.filter(obj => obj.name !== name);
                // Then add the auto generated to it
                let addedAuto = removedItems.concat(ranges)
                this.setState({ [whatLevels]: addedAuto })
            }
            // Then update the rest of the states
            this.setState({
                p10: p10,
                p25: p25,
                median: median,
                p75: p75,
                p90: p90,
                employeesInChartLevels: employees,
                [levelOrFamily]: ranges,
                salaryRangesOpen: false,
                chartOpen: chartOpen,
                triggerChartUpdate: triggerChartUpdate,
            })
            // activate save for selected
            if (this.state.chartWhat === "overall") { this.setState({ activateSalRangeSave: activateSalRangeSave }) }
            if (this.state.chartWhat === "family") { this.setState({ activateSalRangeSaveFamily: "on" }) }
            if (this.state.chartWhat === "mainFunction") { this.setState({ activateSalRangeSaveMainFunction: "on" }) }
            if (this.state.chartWhat === "subFunction") { this.setState({ activateSalRangeSaveSubfunction: "on" }) }
            if (this.state.chartWhat === "subFunction2") { this.setState({ activateSalRangeSaveSubfunction2: "on" }) }
            if (this.state.chartWhat === "subFunction3") { this.setState({ activateSalRangeSaveSubfunction3: "on" }) }
            if (this.state.chartWhat === "subFunction4") { this.setState({ activateSalRangeSaveSubfunction4: "on" }) }
            if (this.state.chartWhat === "individual") { this.setState({ activateSalRangeSaveIndividual: "on" }) }
        } // End of if statement for autoCalculation

        // 3 C Add data to chart
        // Now all market salaries are ready to be added to the chart series, for market just add the variables p10, p25, median, p75 and p90
        // For employees just add the variable "employees"
        // For ranges just add the variable "ranges"

        // Update state, this will update the ChartSalaryStructure module
        let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
        this.setState({
            p10: p10,
            p25: p25,
            median: median,
            p75: p75,
            p90: p90,
            employeesInChartLevels: employees,
            // rangesLevels: ranges, <-- Only set if we use autocalc
            salaryRangesOpen: false,
            chartOpen: calledOnLoad === true ? false : true,
            triggerChartUpdate: triggerChartUpdate
        })
        // The data in the chart need to have this format (click on rows to see it, it is commented out so not changing anything)
        /*
        series3: [
                 {
                     name: "Lönespann",
                     type: 'bar',
                     //color: "#cdcccc",
                     data: [ // X tells is the level
                         { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                         { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
                         { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                         { y: 60000, x: 5, range: [60000, 45000, 80000] },
                     ],
                 },
                   {
                     name: "Medarbetare",
                     data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                     type: 'scatter',
                 },
 
                 {
                     name: "P10",
                     type: 'line',
                     color: "#dda6a6",
                     data: [{ y: 25000, x: 1, p: "p" }, { y: 20000, x: 2, p: "p" }, { y: 35000, x: 3, p: "p" }, { y: 22500, x: 5, p: "p" },] //"P" is used to determe what type of item it is for tooltip
                 },
                 {
                     name: "P25",
                     type: 'line',
                     color: "#f6bdc7",
                     data: [{ y: 30000, x: 1, p: "p" }, { y: 40000, x: 2, p: "p" }, { y: 55000, x: 3, p: "p" }, { y: 92500, x: 5, p: "p" },]
                 },
 
                 {
                     name: "Median",
                     type: 'line',
                     color: "#8fa7e1",
                     data: [{ y: 35000, x: 1, p: "p" }, { y: 50000, x: 2, p: "p" }, { y: 65000, x: 3, p: "p" }, { y: 40500, x: 5, p: "p" },]
                 },
                 {
                     name: "P75",
                     type: 'line',
                     color: "#dbc191",
                     data: [{ y: 40000, x: 1, p: "p" }, { y: 58000, x: 2, p: "p" }, { y: 75000, x: 3, p: "p" }, { y: 97500, x: 5, p: "p" },]
                 },
                 {
                     name: "P90",
                     type: 'line',
                     color: "#9dc59d",
                     data: [{ y: 45000, x: 1, p: "p" }, { y: 60000, x: 2, p: "p" }, { y: 80000, x: 3, p: "p" }, { y: 102500, x: 5, p: "p" },]
                 },
             ],
 
             // -------------------------
 
        // ------------------- This is to create lines inside the boxes -----
                  [
                         { // This is the range TOP
                             y: 60000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "transparent",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: '60 000', //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 10
                             }
                         },
                         {  // This is the midpoint
                             y: 45000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "#333333",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: "45 000", //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 10
                             }
                         },
                         { // This is the range BOTTOM
                             y: 40000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "transparent",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: '40 000', //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 22
                             }
                         },
 
                     ]
        ---------------------------------- 
        */

        // Update save btn
        let activateSalRangeSave = this.state.saveButtonStatus === "Spara" ? "Spara" : calledOnLoad === true ? "Sparat" : "Spara"
        if (this.state.autoCalculation === "yes") { this.setState({ saveButtonStatus: activateSalRangeSave }) }

        //Load comments about salary Range
        if (this.state.showCommentSalary === true) {
            this.setState({ showCommentSalary: false }, () => {
                this.commentSalary()
            })
        }
    }

    exitPopForFamilies = () => { this.setState({ popUpForFamilies: false }) }
    popUpForFamilies = (name, whatFam) => {
        return <div className="popUpArchitectureAbsolute" style={{ marginLeft: 122, marginTop: 9, borderTopLeftRadius: 0, borderTopRightRadius: 6 }}>
            <img alt="" src={exitImg} class="hide-box" style={{ width: 22 }} onClick={this.exitPopForFamilies} />
            <div className="createSalBandBtn" whatChart={whatFam} whatName={name} onClick={this.openWhatChart}>Skapa lönespann</div>
            <div className="titleFamily" style={{ marginTop: 0, marginBottom: 20, paddingTop: 3 }}>Jobbfamilj</div>
            {/*  <div className={this.state.showInputNewFam !== "family" ? "" : "hide"}>
                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} onChange={this.selectFamily}>
                    <option value={family === false || family === "" || family === null || family === undefined ? "Välj kategori" : family}>{family === false || family === "" || family === null || family === undefined ? "Välj kategori" : family}</option>
                    <JobFamilies />
                </select>
                <div className="plusBtnDivContainer">
                    <div className="plusBtnDivBack" whatFam="family" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                </div>
                <div>OBS! Funkar ej än</div>
    </div>*/}
        </div>
    }
    popUpForFamiliesToggle = (event) => {
        let name = event.target.getAttribute('data-popfamily');
        this.setState({ popUpForFamilies: name })
    };

    commentSalary = () => { //Show comment textfield and load the comment if is any
        if (this.state.showCommentSalary) {
            if (this.state.thisSalaryComment.length > 0 && !this.state.salaryCommentSaved) {
                const userConfirmed = window.confirm("Kommentaren har inte sparats och kommer att försvinna. Vill du fortsätta?");
                if (userConfirmed) { this.setState({ showCommentSalary: false }) } else { return }
            } else {
                this.setState({ showCommentSalary: false })
            }
        } else {
            let whatFunction = this.state.chartWhat // This is what type is selected: family, mainFunction, subFunction, subFunction2, subFunction3 or individual (equal work)
            let theName = this.state.selectedFamOrFunc // This is the name of the selected family, function or equal work name
            let allSalaryComments = this.state.allSalaryComments
            // Check if there already is a comment
            const existingObject = allSalaryComments.find(obj => obj.whatFunction === whatFunction && obj.theName === theName);
            if (existingObject) {    // If a comment is found, use the 'comment' property
                this.setState({ thisSalaryComment: existingObject.comment })
            } else {
                this.setState({ thisSalaryComment: "" })
            }
            this.setState({ showCommentSalary: true, salaryCommentSaved: true })
        }
    }

    editCommentSalary = (event) => {
        const text = event.target.value //what text should be saved
        this.setState({ thisSalaryComment: text })
    }
    saveCommentSalary = async (e) => {
        let language = localStorage.getItem('language') ?? 'sv';
        // let whatFunction = this.state.selectedFamOrFunc // This is the overall, family name, function name or equal work name.
        // let allSalaryComments = this.state.allSalaryComments.slice() // all salary comments
        //   let thisSalaryComment = this.state.thisSalaryComment // the temporary comment we are writing to

        // Info we need to know to identify the comment
        let whatFunction = this.state.chartWhat // This is what type is selected: family, mainFunction, subFunction, subFunction2, subFunction3 or individual (equal work)
        let theName = this.state.selectedFamOrFunc // This is the name of the selected family, function or equal work name
        // Comment info
        let allSalaryComments = this.state.allSalaryComments.slice() // all salary comments
        let thisSalaryComment = this.state.thisSalaryComment // the edited comment

        // Check if new comment or already exists
        const existingObject = allSalaryComments.find(obj => obj.whatFunction === whatFunction && obj.theName === theName);

        let commentObject = {
            whatFunction: whatFunction,
            theName: theName,
            comment: thisSalaryComment,
            commentId: existingObject ? existingObject.commentId : undefined
        }

        // Save to database and send back id
        let response = await fetchAuth(`/api/saveSalaryRangeComment`, 'POST', JSON.stringify({ commentObject: commentObject }));
        if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        else {
            if (!existingObject) { // If the comment is new we first need to get the new id and push the object
                let newId = await response.json();
                commentObject.commentId = newId
                allSalaryComments.push(commentObject)
            } else if (existingObject) { // Otherwise just update it
                existingObject.comment = thisSalaryComment
            }
            // Add the comment to the comment list
            this.setState({ salaryCommentSaved: true, allSalaryComments: allSalaryComments })


            // Check if already in the allSalaryComments list, add or update
            /* const existingObject = allSalaryComments.find(obj => obj.whatFunction === whatFunction);
             if (existingObject) {    // If the object is found, update the 'text' property
                 existingObject.text = thisSalaryComment;
             } else { // If the object is not found, add a new object to the array
                 allSalaryComments.push({
                     whatFunction: whatFunction,
                     text: thisSalaryComment,
                 });
             }
         
         
         
         
                     // Add temporary to object
                     this.setState({ salaryCommentSaved: true, allSalaryComments: allSalaryComments })*/
        }
    }
    commentSalaryOnClick = () => {
        this.setState({ salaryCommentSaved: false })
    }

    showDraftEditor = () => {
        this.setState({ editJobDesc: true })
    }
    hideDraftEditor = () => {
        this.setState({ editJobDesc: false })
    }
    selectJobDesc = (event) => { this.setState({ jobDescSelected: event.target.value }) }
    draftEditorHandleSave = async (editorState) => {
        let language = localStorage.getItem('language') ?? 'sv';
        // Save jobDescription
        let title = this.state.jobDescSelected
        let equalGroup = this.state.jobDescWhatGroup // This may be the same as title, but that is on purpose. Will be saved like that.
        let contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState); // Serialize the content
        const jobDesc = JSON.stringify(rawContentState); // Convert it to JSON to be saved.
        // Save it 
        let response = await fetchAuth(`/api/saveJobDescription`, 'POST', JSON.stringify({ jobDesc: jobDesc, title: title, equalGroup: equalGroup }));
        if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        else {
            console.log(jobDesc)

            // Update object with new jobDescription, then close editor
            let jobDescObject = this.state.jobDescriptions.slice()
            let jobIndex = jobDescObject.findIndex(job => job.equalGroup === equalGroup && job.title === title);
            jobIndex !== -1
                ? jobDescObject[jobIndex].jobDesc = jobDesc // Update if exists
                : jobDescObject.push({ equalGroup, title, jobDesc }); // Add if not

            this.setState({ jobDescriptions: jobDescObject }, () => { this.setState({ editJobDesc: false }); console.log(this.state.jobDescriptions) });
        }
    }

    settingPopUp = () => { this.state.settingPopUp ? this.setState({ settingPopUp: false }) : this.setState({ settingPopUp: true }) }

    exportThis = () => {
        alert("EXPORTERA")
        // Export as pdf for the start

    }
    componentRef = React.createRef();
    lineHeightCalculateRef = React.createRef();
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let widthOfContainer = 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) // The actual width of the div
        /*   let printerWidth = widthOfContainer > 1048 ? "A4 landscape" : "A3 landscape" */
        let printerWidth = 1123 // pixel width for the pdf
        // let scale = Math.min(printerWidth / widthOfContainer, 1) // math.min ensures the scale does not go bigger
        // let scale = Math.floor((printerWidth / widthOfContainer) * 10) / 10
        let scale = printerWidth / widthOfContainer
        console.log(scale)
        {/* Create columns for architecture depending on number of equivalent groups*/ }
        const columns = []
        for (let i = 0; i < this.state.nrOfEqualentGroups; i++) {
            const currentLevel = i + 1;
            const rangeObject = this.state.rangesLevels.find(obj => obj.x === currentLevel);
            const rangeText = rangeObject ? `${rangeObject.range[0].toLocaleString()} - ${rangeObject.range[2].toLocaleString()}` : "-";
            columns.push(
                <div key={i + 1} className="column-manual centerColumnTitle">
                    <div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div>
                    <span className="arcColumnTitleWhiteBack" whatChart="overall" onClick={this.openWhatChart}>Nivå {currentLevel}</span>
                    <span className={this.state.showSalRange ? "salaryRangeLevelInArc" : "hide"}>{rangeText}</span>
                    <span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 48,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Nivå {currentLevel}</span> {/* This is just for fixed titles when scrolling */}
                </div>
            )
        }

        {/* Adding groups */ }
        let equivalentGroup = []

        if (this.state.dataByGroup.length > 0) {
            equivalentGroup = this.state.dataByGroup
        } //[1,2,3] //here we add all the groups, they are then rendered at row 2519

        {/* For comments */ }

        let comments = "Inga kommentarer för senaste året."

        if (this.state.comments.length > 0) {
            comments = this.state.comments.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange vad som är noterbart" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange orsak" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange åtgärd (vid behov)" name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange ansvarig" name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /><span className="analysisIcon">{comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange vad som är noterbart" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange orsak" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange åtgärd (vid behov)" name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder="Ange ansvarig" name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
                    </tr>
                }
            });
        }

        let pastComments = "Inga kommentarer från tidigare år."

        if (this.state.commentsLastYear.length > 0) { //<--- FILTER PAST YEARS COMMENTS!!
            pastComments = this.state.commentsLastYear.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">Utvärderas</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">Återanvänd</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>Återanvänd igen</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">Återanvänd</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">Utvärderas</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">Återanvänd</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>Återanvänd igen</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">Återanvänd</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                }
            });
        }
        //Also need to check how many subGroups and how many equalentGroups
        /*  let gridTable = [
              {
                  family: "Finans",
                  subGroup: "Redovisning",
                  equalGroups: [
                    {name: "Red. ekonom",
                    level: 3,
                    works: ["Redovisningsekonom"]
                  },
                  {name: "Ekonomiassistent",
                  level: 1,
                  works: ["Ekonomiassistent"]
              }
                  ]
              },    
          ]*/
        let nrOfSubFamilies = this.state.nrOfSubFamilies
        let gridTable = this.state.jobFamilies.slice()

        /////////////////////////////////////








        function getRandomColor() {
            // Generate a random color in hexadecimal format
            return "#" + ((Math.random() * 0xFFFFFF) << 0).toString(16).padStart(6, '0');
        }
        // Update colors if changed
        gridTable.forEach((item, index) => {
            if (item.color === "") {
                const itemWithColor = gridTable.find((otherItem, otherIndex) =>
                    otherIndex !== index && otherItem.family === item.family && otherItem.color !== ""
                );
                if (itemWithColor) {
                    // Use the color from another item with the same family
                    item.color = itemWithColor.color;
                } else {
                    // Generate a random color
                    item.color = getRandomColor();
                }
            }
        });

        //Then, group
        const groupedByFamily = [];
        // gridTable.forEach((item) => {
        gridTable.forEach((item) => {
            const existingFamily = groupedByFamily.find((group) => group.family === item.family);
            if (existingFamily) {
                existingFamily.items.push(item);
            } else {
                groupedByFamily.push({ family: item.family, items: [item], color: item.color });
            }
        });

        const groupedBySubGroup = [];
        groupedByFamily.forEach((family) => {
            const familyGroups = {};
            family.items.forEach((item) => {
                if (!familyGroups[item.subGroup]) {
                    familyGroups[item.subGroup] = [];
                }
                familyGroups[item.subGroup].push(item);
            });
            const subGroupEntries = Object.entries(familyGroups);
            const subGroupArray = subGroupEntries.map(([subGroup, items]) => ({ subGroup, items }));
            // const subGroup1Array = 
            groupedBySubGroup.push({ family: family.family, subGroups: subGroupArray, color: family.color });
        });

        let gridRows = []
        for (let i = 0; i < groupedBySubGroup.length; i++) {
            gridRows = <div style={{
                display: "grid",
                gridTemplateAreas:
                    `'menu main right right right right'
          'menu main footer footer footer footer'`,
                gap: 10,
                backgroundColor: "#2196F3",
                padding: 10
            }}>
                <div style={{ gridColumn: '1 / span 1' }}>{groupedBySubGroup[i].family}</div>
                {groupedBySubGroup[i].subGroups.map((item, innerIndex) => (
                    <div style={{ gridColumn: '2 / span 1' }}>{item.subGroup}</div>
                ))}
            </div>
        }

        let createdRows = []
        let hierarchy = this.state.hierarchy



        const createColumns = (workData, color, family) => {
            let nrOfEqualentGroups = this.state.nrOfEqualentGroups
            let theDivs = []
            for (let i = 0; i < nrOfEqualentGroups; i++) {
                theDivs.push(<div className="column-manual">
                    {workData.map((item, b) => (item.name == i + 1 && item.groupNameArbVarderings.map((work) => {
                        const { lowestFirstFam, highestLastFam } = checkIfBandsExist("individual", work.name);
                        let salaryRange = `${lowestFirstFam === Infinity ? '' : lowestFirstFam} - ${highestLastFam === -Infinity ? '' : highestLastFam}`;
                        return (
                            <div className="column-equalWork" id={work.name} onClick={this.editGroup}>
                                <span class={!this.state.showSalRange || (lowestFirstFam === Infinity && highestLastFam === -Infinity) ? "hide" : "familyBandItem bandColorSingle"}><span>{salaryRange}</span>
                                   {/* <span class="notificationFilter" style={{ marginLeft: 5, marginTop: -10 }}>1</span> */} 
                                </span> {/* This is the salary range*/}
                                {/* PopUp */}
                                <div className={this.state.editGroupPop === work.name ? "popUpArchitectureAbsolute" : "hide"}>
                                    <div>
                                        <img alt="" src={exitImg} class="hide-box" style={{ width: 22 }} onClick={this.exitEditGroupPop} />
                                        {this.state.showJobDesc && <img alt="" src={jobInfo} class="hide-box" style={{ width: 20, marginTop: 0.5, marginRight: 9 }} onClick={this.showJobDesc} data-egroup={work.name} />}
                                        <div className="createSalBandBtn" whatChart="individual" onClick={this.openWhatChart}>Skapa lönespann</div>
                                        <div className="titleFamily" style={{ marginTop: 0, marginBottom: 20, paddingTop: 3 }}>Jobbfamilj</div>
                                        <div className={this.state.showInputNewFam !== "family" ? "" : "hide"}>
                                            <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} onChange={this.selectFamily}>
                                                <option value={family === false || family === "" || family === null || family === undefined ? "Välj kategori" : family}>{family === false || family === "" || family === null || family === undefined ? "Välj kategori" : family}</option>
                                                {/* <JobFamilies /> */}
                                                {this.state.familySelectList.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}

                                            </select>
                                            <div className="plusBtnDivContainer">
                                                <div className="plusBtnDivBack" whatFam="family" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                            </div>
                                        </div>
                                        {/* <div style={{ display: "inline", marginLeft: 5 }}>eller */}
                                        <div className={this.state.showInputNewFam === "family" ? "" : "hide"}>
                                            <input type="text" className="inputFamily" placeholder="Skapa ny" onChange={this.changeFamilyToList} value={this.state.addFamilytoList} />
                                            <div data-family={family} data-whatSub="family" className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }}>Skapa</div>
                                            <div data-family={family} data-whatSub="family" className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }}>Avbryt</div>
                                        </div>
                                        {/*</div>*/}
                                    </div>
                                    <div style={{ textDecoration: "underline", marginTop: 15, marginBottom: 5, cursor: "pointer", color: "#31a390", fontWeight: 600 }} onClick={this.showMore}>{!this.state.showMore ? "Visa mer" : "Visa mindre"}</div>
                                    <div className={!this.state.showMore ? "hide" : "moreDetailsFamily"}>

                                        <div>
                                            <div className="titleFamily">Funktion</div>
                                            <div className={this.state.showInputNewFam !== "mainFunction" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="mainFunction" onChange={this.selectMainFunction}>
                                                    <option value={this.state.selectedMainFunction === null || this.state.selectedMainFunction === "" || this.state.selectedMainFunction === undefined ? "Välj jobbfunktion" : this.state.selectedMainFunction}>{this.state.selectedMainFunction === null || this.state.selectedMainFunction === "" || this.state.selectedMainFunction === undefined ? "Välj jobbfunktion" : this.state.selectedMainFunction}</option>
                                                    {this.state.mainFunctionsSelectList.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="mainFunction" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>

                                            <div className={this.state.showInputNewFam === "mainFunction" ? "" : "hide"}>
                                                {/* <input type="text" className="inputFamily" placeholder="Skapa ny" /> */}
                                                <input type="text" className="inputFamily" placeholder="Skapa ny" onChange={this.changeMainFunction} value={this.state.addMainFunction} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="mainFunction">Skapa</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="mainFunction">Avbryt</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="titleFamily">Subfunktion</div>
                                            <div className={this.state.showInputNewFam !== "subFunction" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction" onChange={this.selectMainFunction}>
                                                    <option value={this.state.selectedSubFunction === null || this.state.selectedSubFunction === "" || this.state.selectedSubFunction === undefined ? "Välj jobbfunktion" : this.state.selectedSubFunction}>{this.state.selectedSubFunction === null || this.state.selectedSubFunction === "" || this.state.selectedSubFunction === undefined ? "Välj subfunktion" : this.state.selectedSubFunction}</option>
                                                    {this.state.subFunctionSelectList.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder="Skapa ny" onChange={this.changeSubFunction} value={this.state.addSubFunction} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction">Skapa</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction">Avbryt</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="titleFamily">Subfunktion 2</div>
                                            <div className={this.state.showInputNewFam !== "subFunction2" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction2" onChange={this.selectMainFunction}>
                                                    <option value={this.state.selectedSubFunction2 === null || this.state.selectedSubFunction2 === "" || this.state.selectedSubFunction2 === undefined ? "Välj jobbfunktion" : this.state.selectedSubFunction2}>{this.state.selectedSubFunction2 === null || this.state.selectedSubFunction2 === "" || this.state.selectedSubFunction2 === undefined ? "Välj subfunktion 2" : this.state.selectedSubFunction2}</option>
                                                    {this.state.subFunctionSelectList2.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction2" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction2" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder="Skapa ny" onChange={this.changeSubFunction2} value={this.state.addSubFunction2} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction2">Skapa</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction2">Avbryt</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="titleFamily">Subfunktion 3</div>
                                            <div className={this.state.showInputNewFam !== "subFunction3" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction3" onChange={this.selectMainFunction}>
                                                    <option value={this.state.selectedSubFunction3 === null || this.state.selectedSubFunction3 === "" || this.state.selectedSubFunction3 === undefined ? "Välj jobbfunktion" : this.state.selectedSubFunction3}>{this.state.selectedSubFunction3 === null || this.state.selectedSubFunction3 === "" || this.state.selectedSubFunction3 === undefined ? "Välj subfunktion 3" : this.state.selectedSubFunction3}</option>
                                                    {this.state.subFunctionSelectList3.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction3" onClick={this.showInputNewFam}>Skapa ny</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction3" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder="Skapa ny" onChange={this.changeSubFunction3} value={this.state.addSubFunction3} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction3">Skapa</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction3">Avbryt</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of popUp */}
                                <span className={!this.state.showSalRange || (lowestFirstFam === Infinity && highestLastFam === -Infinity) ? "column-stripe" : "column-stripe column-stripe-salary"} style={{ background: color }}></span><span className="name-stripe">{work.name}</span>
                                <div className={this.state.toggleTitles ? "showTitleNames" : "hide"}>
                                    {[...new Set(work.positionTitles)].sort().map((title, index) => (
                                        //   <div className={work.positionTitles.length === 1 && title.trim() === work.name.trim() ? "hide" : ""} key={"work" + index}>{title}</div> // This hides it if only one work that is the same
                                        <div style={{ marginBottom: 5, color: "#6f6f6f" }} key={"work" + index}>{title}</div>
                                    ))}
                                </div>

                            </div>
                        )
                    })))}
                </div>)
            }
            return theDivs
        }

        let checkIfBandsExist = (familyOrFunc, name) => {
            let uniqueFamily = this.state[familyOrFunc].filter(obj => obj.range !== "hide" && obj.name === name);
            let lowestFirstFam = Infinity;
            let highestLastFam = -Infinity;
            for (const obj of uniqueFamily) { // Get the lowest and highest salaries
                if (obj.range[0] < lowestFirstFam) { lowestFirstFam = obj.range[0]; }
                if (obj.range[obj.range.length - 1] > highestLastFam) { highestLastFam = obj.range[obj.range.length - 1]; }
            }
            return { lowestFirstFam: lowestFirstFam, highestLastFam: highestLastFam }
        }

        let createSalaryBandItems = (familyOrFunc, name, color) => { // Create salary bands for viewing in architecture
            for (let i = 0; i < hierarchy.length; i++) {
                let bandItems = [];

                // Add the family salary band
                let checkBands = checkIfBandsExist(familyOrFunc, name)
                let lowestFirstFam = checkBands.lowestFirstFam
                let highestLastFam = checkBands.highestLastFam

                if (lowestFirstFam === Infinity && highestLastFam === -Infinity) { return [] }  // If there are no salary bands, just return empty. This is also used for hiding the salary bands rows

                bandItems.push(
                    <span className="familyBandItem">
                        <span style={{ marginLeft: -10 }}>{(lowestFirstFam === Infinity ? "" : lowestFirstFam.toLocaleString()) + " - " + (highestLastFam === -Infinity ? "" : highestLastFam.toLocaleString())}</span>
                    </span>
                );
                if (this.state.nrOfSubFamilies > 0 && familyOrFunc === "family") { // If there is one sub family (mainFunction), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 1 && familyOrFunc === "family") { // If there is two sub families (subFamily), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "family") { // If there is three sub families (subFamily2), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "family") { // If there is four sub families (subFamily3), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                // Add salary for each level in the family
                for (let j = 0; j < (this.state.nrOfEqualentGroups); j++) {
                    const currentLevel = j + 1;
                    const rangeObject = this.state[familyOrFunc].find(obj => obj.x === currentLevel && obj.name === name);
                    const rangeText = rangeObject ? `${rangeObject.range[0].toLocaleString()} - ${rangeObject.range[2].toLocaleString()}` : "";

                    bandItems.push(
                        <span key={j} className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{rangeText}</span>
                        </span>
                    );
                    if (this.state.nrOfSubFamilies > 1 && familyOrFunc === "mainFunction" && j === 1) {  // If we have two subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "mainFunction" && j === 1) {  // If we have three subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be and where the "subFunction2" should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "mainFunction" && j === 1) {  // If we have four subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be and where the "subFunction2" should be and where subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "subFunction" && j === 1) {  // If we have three subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "subFunction" && j === 1) {  // If we have four subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be and where the subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "subFunction2" && j === 1) {  // If we have four subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be and where the subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem">
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                }

                return bandItems
            }
        }




        //--- If only family  ---
        if (this.state.nrOfSubFamilies < 1) {
            for (let i = 0; i < hierarchy.length; i++) {
                // Create the columns and rows
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {this.state.popUpForFamilies === this.state.hierarchy[i].name && this.popUpForFamilies(this.state.hierarchy[i].name, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}> {/* This is the salary band row*/}
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={this.state.hierarchy[i].name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={this.state.hierarchy[i].name} className="column-stripe" style={{ background: hierarchy[i].color }}></span><span data-popfamily={this.state.hierarchy[i].name} className="name-stripe">{hierarchy[i].name}</span></span>

                        <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                            {createColumns(hierarchy[i].mainFunctions[0].subFunctions[0].subFunction2s[0].subFunction3s[0].equalentGroups, hierarchy[i].color, this.state.hierarchy[i].name)}
                        </div>
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }

        //--- If family and function ---
        if (this.state.nrOfSubFamilies === 1) {
            for (let i = 0; i < this.state.hierarchy.length; i++) {
                let familyName = this.state.hierarchy[i].name;
                let color = this.state.hierarchy[i].color;

                // Create the columns
                let createRow = (
                    <div key={i} className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                        {this.state.popUpForFamilies === familyName && this.popUpForFamilies(familyName, "family")}
                        <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                            {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color)} {/* Creates the salary band items */}
                        </span>
                        <div className="column-row">
                            <span className="column-sub" data-popfamily={familyName} onClick={this.popUpForFamiliesToggle}>
                                <span className="column-stripe" style={{ background: color }} data-popfamily={familyName} ></span>
                                <span className="name-stripe" data-popfamily={familyName} >{familyName}</span>
                            </span>
                            {this.state.hierarchy[i].mainFunctions.map((mainFunction, a) => (
                                <>
                                    {this.state.popUpForFamilies === mainFunction.name && this.popUpForFamilies(mainFunction.name, "mainFunction")}
                                    <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunction.name).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                        {createSalaryBandItems("mainFunction", mainFunction.name)} {/* Creates the salary band items */}
                                    </span>

                                    <div key={a} className={
                                        a === 0 ? "subGroupFirst marginLeftColumn funcHover" :
                                            (a !== this.state.hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") :
                                                (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))
                                    }>
                                        <span className={mainFunction.name == null ? "hide" : "column-sub"} data-popfamily={mainFunction.name} onClick={this.popUpForFamiliesToggle}>
                                            <span className="column-stripe" style={{ background: color }} data-popfamily={mainFunction.name} ></span>
                                            <span className="name-stripe" data-popfamily={mainFunction.name} >{mainFunction.name}</span>
                                        </span>
                                        <div style={{ display: "inline-block", verticalAlign: "top" }} className="marginLeftColumn">
                                            {createColumns(mainFunction.subFunctions[0].subFunction2s[0].subFunction3s[0].equalentGroups, color, familyName)}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                );
                createdRows.push(createRow)
            }
        }

        //If family, function and subFunction1 
        if (this.state.nrOfSubFamilies === 2) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {this.state.popUpForFamilies === family && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} onClick={this.popUpForFamiliesToggle}><span data-popfamily={family} className="column-stripe" style={{ background: color }}></span>
                        <span data-popfamily={family} className="name-stripe">{hierarchy[i].name}</span>
                    </span>
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {this.state.popUpForFamilies === mainFunctions.name && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name)} {/* Creates the salary band items */}
                                </span>

                                {/*  <div className={a !== hierarchy[i].mainFunctions.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}>*/}
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={mainFunctions.name} className="column-stripe" style={{ background: color }}></span>
                                        <span data-popfamily={mainFunctions.name} className="name-stripe">{mainFunctions.name}</span>
                                    </span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => ( // <-- There is a bug here creating "subGroupFirst" for wrong div, but it "works" visually so not changing it
                                        //  <div className={a !== subFunction.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}>
                                        <>
                                            {this.state.popUpForFamilies === subFunction.name && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name)} {/* Creates the salary band items */}
                                            </span>

                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }} ></span>
                                                    {/*} <span className={this.state.showSalRange ? "salaryRangeInArc" : "hide"}>20 000 - 40 000</span> {/* <-- OBS! TESTAR LÖNESPANN */}
                                                    <span data-popfamily={subFunction.name} className="name-stripe">{subFunction.name}</span>
                                                </span>
                                                <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                    {createColumns(subFunction.subFunction2s[0].subFunction3s[0].equalentGroups, color, family)}
                                                </div>
                                            </div>
                                        </>
                                    )

                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }


        //If family, function, subFunction1 and subFunction 2
        if (this.state.nrOfSubFamilies === 3) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {this.state.popUpForFamilies === family && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} onClick={this.popUpForFamiliesToggle}><span data-popfamily={family} className="column-stripe" style={{ background: color }}></span><span data-popfamily={family} className="name-stripe">{hierarchy[i].name}</span></span>
                        {/* Function */}
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {this.state.popUpForFamilies === mainFunctions.name && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name)} {/* Creates the salary band items */}
                                </span>
                                {/* <div className={a !== hierarchy[i].mainFunctions.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}> */}
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={mainFunctions.name} className="column-stripe" style={{ background: color }} ></span><span data-popfamily={mainFunctions.name} className="name-stripe">{mainFunctions.name}</span></span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => (
                                        <>
                                            {this.state.popUpForFamilies === subFunction.name && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name)} {/* Creates the salary band items */}
                                            </span>

                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }}></span><span data-popfamily={subFunction.name} className="name-stripe">{subFunction.name}</span></span>
                                                {/* Sub-function 2*/}
                                                {subFunction.subFunction2s.map((sub2, c) => (
                                                    <>
                                                        {this.state.popUpForFamilies === sub2.name && this.popUpForFamilies(sub2.name, "subFunction2")}
                                                        <span className={this.state.showSalRange && createSalaryBandItems("subFunction2", sub2.name).length > 0 ? c === 0 ? "familyBand mainFuncBandFirst bandColorFour" : "familyBand mainFuncBand bandColorFour" : "hide"} style={{ marginLeft: 132, width: (((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131 }}>
                                                            {createSalaryBandItems("subFunction2", sub2.name)} {/* Creates the salary band items */}
                                                        </span>
                                                        <div className={c === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (c !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>

                                                            <span className={sub2.name == null ? "hide" : "column-sub"} data-popfamily={sub2.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={sub2.name} className="column-stripe" /* style={{ background: groupedBySubGroup[i].color }} */></span><span data-popfamily={sub2.name} className="name-stripe">{sub2.name}</span></span>
                                                            <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                                {createColumns(sub2.subFunction3s[0].equalentGroups, color, family)}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        )
                        )}

                    </div>
                </div>
                createdRows.push(createRow)
            }
        }

        //If family, function, subFunction1. subFunction2 and subFunction3
        if (this.state.nrOfSubFamilies === 4) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {this.state.popUpForFamilies === family && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name).length > 0 ? "familyBand" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} onClick={this.popUpForFamiliesToggle}><span data-popfamily={family} className="column-stripe" style={{ background: color }}></span><span data-popfamily={family} className="name-stripe">{hierarchy[i].name}</span></span>
                        {/* Function */}
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {this.state.popUpForFamilies === mainFunctions.name && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name)} {/* Creates the salary band items */}
                                </span>
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} onClick={this.popUpForFamiliesToggle} ><span data-popfamily={mainFunctions.name} className="column-stripe" style={{ background: color }} ></span><span data-popfamily={mainFunctions.name} className="name-stripe">{mainFunctions.name}</span></span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => (
                                        <>
                                            {this.state.popUpForFamilies === subFunction.name && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name)} {/* Creates the salary band items */}
                                            </span>
                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }} ></span><span data-popfamily={subFunction.name} className="name-stripe">{subFunction.name}</span></span>
                                                {/* Sub-function 2*/}
                                                {subFunction.subFunction2s.map((sub2, c) => (
                                                    <>
                                                        {this.state.popUpForFamilies === sub2.name && this.popUpForFamilies(sub2.name, "subFunction2")}
                                                        <span className={this.state.showSalRange && createSalaryBandItems("subFunction2", sub2.name).length > 0 ? c === 0 ? "familyBand mainFuncBandFirst bandColorFour" : "familyBand mainFuncBand bandColorFour" : "hide"} style={{ marginLeft: 132, width: (((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131 }}>
                                                            {createSalaryBandItems("subFunction2", sub2.name)} {/* Creates the salary band items */}
                                                        </span>

                                                        <div className={c === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (c !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                            <span className={sub2.name == null ? "hide" : "column-sub"} data-popfamily={sub2.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={sub2.name} className="column-stripe" style={{ background: color }} ></span><span data-popfamily={sub2.name} className="name-stripe">{sub2.name}</span></span>
                                                            {/* Sub-function 3 */}
                                                            {sub2.subFunction3s.map((sub3, d) => (
                                                                <>
                                                                    {this.state.popUpForFamilies === sub3.name && this.popUpForFamilies(sub3.name, "subFunction3")}
                                                                    <span className={this.state.showSalRange && createSalaryBandItems("subFunction3", sub3.name).length > 0 ? d === 0 ? "familyBand mainFuncBandFirst bandColorFive" : "familyBand mainFuncBand bandColorFive" : "hide"} style={{ marginLeft: 132, width: ((((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131) - 131 }}>
                                                                        {createSalaryBandItems("subFunction3", sub3.name)} {/* Creates the salary band items */}
                                                                    </span>
                                                                    <div className={d === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (d !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                                        <span className={sub3.name == null ? "hide" : "column-sub"} data-popfamily={sub3.name} onClick={this.popUpForFamiliesToggle}><span data-popfamily={sub3.name} className="column-stripe" style={{ background: color }} ></span><span data-popfamily={sub3.name} className="name-stripe">{sub3.name}</span></span>
                                                                        <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                                            {createColumns(sub3.equalentGroups, color, family)}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }
        const selectedJob = this.state.jobDescriptions.find(job => job.title === this.state.jobDescSelected);
        // console.log("selectedJob")
        // console.log(selectedJob)
        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message="Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?"
                />
                <TopNav />
                <Menu />
                <div className="container" style={{ marginBottom: 200 }}>
                    <Information step="lonestruktur" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButtonFunc : ""}>{this.state.saveButtonStatus}</button>

                    {/* PopUp job description */}
                    <div className={this.state.popUpJobDesc ? "popUpJobDesc" : "hide"}>
                        <img style={{ position: "absolute", right: 20, top: 20, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closePopUpJobDesc} />
                        <div style={{
                            textAlign: "left", fontSize: 15, marginBottom: 20, borderBottom: "1px solid #efefef", paddingBottom: 20, marginLeft: -20, marginRight: -20, paddingLeft: 20, paddingRight: 20,


                        }}>
                            <div style={{ display: "inline-block", fontWeight: 600, borderRight: "1px solid #bdbdbd", paddingRight: 10, fontSize: 17 }}>Arbetsbeskrivning</div>
                            {/*  <div style={{ display: "inline-block", marginLeft: 10 }}>{this.state.jobDescWhatGroup} </div> */}
                            <div style={{ display: "inline-block", marginLeft: 10 }}>
                                <select onChange={this.selectJobDesc} value={this.state.jobDescSelected} style={{ padding: 5, textAlign: "left", padding: 5, width: 200, width: "fit-content", border: "none", fontSize: 15, fontFamily: `'Inter', sans-serif !important`, cursor: "pointer" }}>
                                    <option value={this.state.jobDescWhatGroup} >{this.state.jobDescWhatGroup}</option>
                                    {this.state.positionsAndEqualWorks
                                        .filter(group => group.groupNameArbVardering === this.state.jobDescWhatGroup) // Filter the group by equalGroup.name
                                        .flatMap(group => group.positionTitles) // Get the works array from the matching group
                                        .map((work, index) => (
                                            <option key={index} value={work}>
                                                {work}
                                            </option>
                                        ))
                                    }
                                    {/* if jobFamilies.equalGroup.name === jobDescWhatGroup}, get all jobFamilies.equalGroup.works *
                                    <option value="lag" >Montör 2</option>
                                    <option value="lead">Reparatör 2</option>*/}
                                </select>
                            </div>
                        </div>
                        <div style={{ textAlign: "left", fontSize: 13 }}>



                            {/*
                             jobDescWhatGroup: "",
            jobDescriptions: [],
            jobDescSelected: "",
            editJobDesc: false
            */}

                            {!this.state.editJobDesc && this.state.jobDescriptions.some(job => job.title === this.state.jobDescSelected) ? (
                                //  <ReadOnlyDraftEditor rawContent={`{"blocks":[{"key":"ajmo5","text":"Hej!","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":4,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"e860v","text":"Hur är läget?","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":13,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"1a503","text":"Punkt","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":5,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"3ujrf","text":"Punkt","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"bfidv","text":"Punkt","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`} />
                                <>
                                    <ReadOnlyDraftEditor rawContent={selectedJob.jobDesc} />
                                    <div className="adjustDraftBtn" onClick={this.showDraftEditor}><img src={editImg} style={{ width: 12, marginRight: 6, verticalAlign: "top", filter: "invert(1)", opacity: 0.7, display: "inline-block", marginTop: 1.5 }} /><div style={{ display: "inline-block", verticalAlign: "top" }}>Redigera</div></div>
                                </>
                            ) : !this.state.editJobDesc ? (
                                <div>
                                    <div style={{ textAlign: "center", marginTop: 120 }}>Ingen arbetsbeskrivning</div>
                                    <div onClick={this.showDraftEditor} className="commentButton" style={{ textAlign: "center", margin: "auto", display: "block", marginTop: 20, width: 100 }}>Lägg till</div>
                                </div>)
                                : <DraftEditor rawContent={selectedJob && selectedJob.jobDesc ? selectedJob.jobDesc : ""} onSave={this.draftEditorHandleSave} onCancel={this.hideDraftEditor} />
                            }
                        </div>
                    </div>
                    <div style={{ zIndex: 3 }} className={this.state.popUpJobDesc ? "popUpFader" : "hide"}></div>
                    {/* PopUp Questionmark */}
                    <div className={this.state.popUp ? "popUp active-popUp" : "popUp"} style={{ height: "auto", maxWidth: 700, marginTop: -300, fontSize: 14.6 }}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.togglePop} />
                        <h4 style={{ fontSize: 15.6 }}>Detaljerad och automatisk analys</h4>
                        Här kan du utföra en detaljerad analys mellan två grupper. Se mer detaljer, visualisera individuella skillnader samt få en automatisk analys.
                        <div style={{ padding: 20 }}>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>Välj grupper</b></p>Du väljer grupp att analysera genom att först klicka på en av rutorna där det står "Välj grupp". Texten kommer nu ändras till "Klicka på grupp". Klicka på den första gruppen du vill se. Gör nu likadant för den andra rutan där det står "Välj grupp".</p>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>Byt ut grupper</b></p>För att byta ut en grupp klickar du helt enkelt i den ruta där det tidigare stod "Välj grupp" (nu står gruppnamnet). Texten kommer nu ändras till "Klicka på grupp". Klicka på den grupp du vill se istället.</p>
                        </div>
                    </div>
                    <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                    {/* PopUp infoblock */}
                    <div style={{ height: "auto" }} className={this.state.popUpInfo ? "popUp active-popUp" : "popUp"}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.popUpInfo} />
                        <div style={{ padding: 20, textAlign: "left", fontSize: 15.6 }}>
                            <span><b>Analysen tar hänsyn till flera faktorer såsom: </b></span>
                            <ul style={{ listStyleType: "circle", paddingLeft: 20, padding: "inherit" }}>
                                <li>Lönenivåer</li>
                                <li>Könsfördelning</li>
                                <li>Ålder</li>
                                <li>Erfarenhet</li>
                                <li>Gruppstorlek</li>
                                <li>Placeringsort</li>
                                <li>Arbetets krav</li>
                                <li style={{ display: "block" }}>... och mer</li>
                            </ul>

                            <span>Verkligheten kan dock vara mer komplex och andra faktorer kan spela in, såsom marknaden, bibehållna löner, prestationer etc, varav du alltid bör göra en egen bedömning. </span>
                            <span>Eventuella felaktigheter reserveras. </span>
                        </div>
                    </div>
                    <div className={this.state.salaryRangesOpen ? "salaryRangesPopUpSettings" : "hide"}>
                        <div style={{ fontWeight: 700, marginBottom: 20 }}>Inställningar</div>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closeAutoCalc} />

                        <div className="slider-container">
                            <div style={{ borderBottom: "1px solid #e2e2e2", paddingBottom: 10 }}>
                                <span style={{ display: 'block', marginBottom: 20, color: '#333333', fontWeight: 600 }}>Vikt marknad</span>
                                <div style={{ marginBottom: 20, fontWeight: 700, color: "#46b09c", fontSize: 20, borderBottom: "1px solid #e8e8e8", width: 69, marginLeft: "auto", marginRight: "auto", paddingBottom: 5, marginBottom: 15 }}>
                                    {this.state.marketWeight} <span>%</span></div>
                                <input type="range" min="0" max="100" value={this.state.snappedValue} step="1" class="slider" id="myRange" onChange={this.sliderValue} onMouseUp={this.handleSliderRelease} />
                            </div>
                            <div style={{ padding: 15, textAlign: "left", paddingTop: 15, paddingBottom: 0 }}>
                                <div style={{ paddingBottom: 15, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>

                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>Marknadsposition</div>
                                    <select onChange={this.selectMarketPosition} name="midPoint" value={this.state.marketPosition} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        <option value="median" disabled={this.state.marketWeight === 0}>I nivå</option>
                                        <option value="lag" disabled={this.state.marketWeight === 0}>Eftersläpa</option>
                                        <option value="lead" disabled={this.state.marketWeight === 0}>Leda</option>
                                    </select>
                                </div>
                                <div style={{ marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, paddingTop: 15, borderTop: "1px solid #e2e2e2", }}>
                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>Skapa progression</div>
                                    <select onChange={this.selectCreateProgression} name="selectCreateProgression" value={this.state.createProgression} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        <option value={"no"}>Nej</option>
                                        <option value={"yes"}>Ja</option>
                                    </select>
                                </div>
                                {/*}  <div>
                                    <div>Använda standardavvikelser? Dvs ta bort extremvärden. Ska ej visas som val</div>
                                    <select onChange={this.selectStandardDeviation} name="standardDeviation" value={this.state.standardDeviation} style={{ padding: 5 }}>
                                        <option value={"two"}>Ja - 2</option>
                                        <option value={"one"}>Ja - 1</option>
                                        <option value={"no"}>Nej</option>
                                    </select>
        </div>*/}

                            </div>
                        </div>
                        <div className="commentButton" onClick={this.autoCalcSalaryRanges}>Skapa</div>
                    </div>
                    {/* PopUp for manual */}
                    <div className={this.state.salaryRangesManualOpen ? "salaryRangesPopUpSettings" : "hide"}>
                        <div style={{ fontWeight: 700, marginBottom: 20 }}>Skapa lönespann</div>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closeManualOpen} />
                        <div className="slider-container">
                            <div style={{ padding: 15, textAlign: "left", paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ paddingBottom: 0, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>
                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>Skapa för nivå</div>
                                    <select name="selectCreateProgression" value={this.state.manualLevel} onChange={this.manualLevel} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        {<option key={"notSelected"} value={"notSelected"} >
                                            -
                                        </option>}
                                        {this.state.nrOfSelectableEqualentGroups.map((value, index) => (
                                            <option key={value.level} value={value.level} disabled={value.display === "no"}>
                                                {value.level}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="commentButton" onClick={this.addManualLevel}>Skapa</div>
                    </div>
                    <div className={this.state.salaryRangesManualOpen ? "popUpFader" : (this.state.salaryRangesManualOpen && this.state.fullScreen) ? "popUpFader fadeHighZ" : "hide"}></div>
                    <div className={this.state.popUpInfo ? "popUpFader" : (this.state.salaryRangesOpen && this.state.fullScreen) ? "popUpFader fadeHighZ" : this.state.salaryRangesOpen ? "popUpFader" : "hide"}></div>


                    {/* Auto analysis */}
                    {/* <div className="equivalentContainer">
                        <div className="autoAnalysisContainer">
                            <div className={this.state.divOpened === "first" ? "topAnalysis hideBoxShadowInitial" : this.state.divOpened === "yes" ? "topAnalysis showBoxShadow" : "topAnalysis hideBoxShadow"}>
                                <span onClick={this.selectGroup1} className={this.state.analyseGroup1 === "Välj grupp" || this.state.analyseGroup1 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{this.state.analyseGroup1}</span>
                                <span style={{ fontSize: 16, display: "inline-block", fontWeight: 600 }}> &amp;</span>
                                <span onClick={this.selectGroup2} className={this.state.analyseGroup2 === "Välj grupp" || this.state.analyseGroup2 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{this.state.analyseGroup2}</span>
                                <div onClick={this.autoCompare} className="compareButton">Jämför</div>
                                <span onClick={this.toggleDiv} className="minusAnalysis">-</span>
                                <span className="questionMark" onClick={this.togglePop}>?</span>
                            </div>

                            <div className={this.state.divOpened === "first" ? "hideAnalysisInitial" : this.state.divOpened === "yes" ? "showAnalysis" : "hideAnalysis"}>
                                <div style={{ display: "inline-block", verticalAlign: "top", width: 380, fontSize: 13.6, marginTop: -5 }}>
                                    <div class="cssGrid">
                                        <div class="gridLine"> </div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingRight: 2 }}>{this.state.analyseGroup1}</div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingLeft: 2 }}>{this.state.analyseGroup2}</div>
                                        <div class="gridLine autoTitleGroup"> Differens</div>
                                        <div class="autoTitel">Krav</div>
                                        <div class="gridLine">{this.state.tableInfo[0].oneEqualentGroup}</div>
                                        <div class="gridLine">{this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup) ? "" : this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="autoTitel">Kvinnor</div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].oneWomen) ? "" : this.state.tableInfo[0].oneWomen + "%"} </div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].twoWomen) ? "" : this.state.tableInfo[0].twoWomen + "%"} </div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].diffWomen) ? "" : this.state.tableInfo[0].diffWomen + "%"} </div>
                                        <div class="autoTitel"> Anställda</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneNrOfEmp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp) ? "" : this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="autoTitel"> {this.state.hideExtra === "onlyExtra" ? "M. tillägg" : "M. lön"}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgSalary === undefined ? "" : this.state.tableInfo[0].oneAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgSalary === undefined ? "" : this.state.tableInfo[0].twoAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine" style={{ cursor: "pointer" }} onClick={this.showDiffPercent}> {isNaN(this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary) ? "" : !this.state.showDiffPercent ? (this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary).toLocaleString() : (Math.round((100 - ((this.state.tableInfo[0].twoAvgSalary / this.state.tableInfo[0].oneAvgSalary) * 100)) * 10) / 10) + "%"}</div>
                                        <div class="autoTitel"> M. ålder</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgAge}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge) ? "" : this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="autoTitel"> M. erfarenhet</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgExp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp) ? "" : this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="autoTitel">Lönespridning</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG1}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG2}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningDiff}</div>
                                        <div class="autoTitel"> M. ort</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].oneAvgCity}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].twoAvgCity}</div>
                                        <div class="gridLine"> </div>
                                    </div>
                                </div>

                                <div className="analysisInText">
                                    <div style={{ marginTop: -5 }}>
                                        <h4 style={{ margin: 0, fontWeight: 800, fontSize: 15.6 }}>Analys<span className="infoIcon" onClick={this.popUpInfo}>i</span></h4>
                                        <span className="analyseText">
                                            {this.state.analysisText}
                                            <span
                                                data-tip="Ålder i sig är inte en saklig orsak, det behöver vara relevant tidigare erfarenhet."
                                                className={this.state.showInfoIcon ? "infoIconInText" : "hide"}
                                                data-background-color="white"
                                                data-text-color="#333333"
                                            >i</span>
                                            <ReactTooltip />
                                        </span>
                                    </div>
                                   
                                    <div onClick={this.AIComment} className={this.state.divOpened === "yes" ? "addAsReasonButton" : "hideAddAsReasonButton"}>Använd som kommentar</div>
                                    <div className={this.state.showAIComment ? "AIComment" : "hide"}>
                                        <img style={{ position: "absolute", right: 8, top: 8 }} alt="" src={exitImg} class="hide-box" onClick={this.AIComment} />
                                        <div className="AICommentTextField AICommentTitles">Noterbart</div>
                                        <div className="AICommentTextField AICommentTitles">Orsak</div>
                                        <TextareaAutosize id="AISelComment" onChange={this.changeTextAINotable} style={{ marginRight: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AInotable} name="AINotable" />
                                        <TextareaAutosize id="AISelReason" onChange={this.changeTextAIReason} style={{ marginLeft: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AIreason} name="AIReason" />
                                        <div className="addAsReasonButton AIAcceptButton" onClick={this.saveAIComment}>Ange som kommentar</div>
                                    </div>
                                    <div className={this.state.showAIComment ? "popUpFader" : "hide"}></div>
                                </div>

                                <div class={this.state.apexPopUp !== "" ? "apexPopUpGreen" : "chartContainerLikvardiga"}>
                                    <div style={{ zIndex: 13, position: "absolute", maxWidth: 145, boxShadow: "0 2px 10px 0 rgb(128 128 128 / 40%)", height: "auto", paddingBottom: 0, top: 260, color: "#333333" }} className={this.state.popItUp ? "popUp active-popUp" : "hide"}>
                                        <span className="exitVideo" onClick={this.hideEditBox} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer", fontSize: 14.6, fontWeight: 600, height: 22, width: 22 }}>x</span>
                                        <span style={{ fontWeight: 800, display: "block", paddingBottom: 16, marginTop: -20, float: "left", fontSize: 13.6 }}>Justera</span>
                                        <div className={this.state.popUpEditSalarySetting ? "hide" : ""}>
                                            <span style={{ display: "block", marginTop: 35, marginBottom: 6, fontSize: 13.6, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: -0.2 }}>{!this.state.hideName ? this.state.clickedPerson.fullName : ""}</span>
                                            <div className={this.state.showPercent ? "hide" : ""}><input type="number" placeholder="Lön" style={{ width: 75, border: "1px solid #d5d4d4", display: "inlinge-block", padding: 0, fontWeight: "normal", height: 30, margin: 0, verticalAlign: "top", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none", fontSize: 13.6 }} value={this.state.addEditSalary} onChange={this.addEditSalary} /><div style={{ display: "inline-block", border: "1px solid #d5d4d4", height: 30, verticalAlign: "top", lineHeight: "30px", paddingLeft: 7, paddingRight: 7, borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", fontSize: 13.6, background: "#d7d7d7" }} onClick={this.state.clickedPerson.addOn > 0 ? this.popUpEditSalarySetting : this.editSalary}>Ny lön</div></div>
                                            <div onClick={this.hideEmployee} style={{ height: 30, lineHeight: "30px", width: 133, margin: "auto", marginTop: 7, borderRadius: 5, fontSize: 13.6, cursor: "pointer", background: "#d7d7d7" }}>Dölj anställd</div>
                                            <p style={{ fontSize: 11.6, borderTop: "1px solid #cacaca", paddingTop: 2, marginBottom: 6, marginTop: 6 }}>Klicka på jämför för att återställa justeringen.</p>
                                        </div>
                                        <div className={this.state.popUpEditSalarySetting ? "" : "hide"}>
                                            <span style={{ display: "block", fontSize: 12.6, marginTop: 35 }}>Personen har ett tillägg på {this.state.clickedPerson.addOn} kr. <p>Ska tillägget inkluderas eller adderas till summan?</p></span>
                                            <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginRight: 4, cursor: "pointer" }} onClick={this.editSalary}>Inkludera</div><div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginLeft: 4, cursor: "pointer", marginBottom: 15 }} onClick={this.editSalaryExclude}>Addera</div>
                                        </div>
                                    </div>
                                    <Chart
                                        options={this.state.options}
                                        series={this.state.series}
                                        type="scatter"
                                        width={this.state.chartWidth}
                                        height={this.state.chartHeight}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>*/}

                    {/* End of auto analysis container */}

                    {/* Views */}

                    {/* <div style={{
                        padding: 10,
                        boxShadow: "0 -2px 3px 0 rgba(0, 0, 0, 0.07)",
                        borderRadius: 4,
                        marginBottom: 0,
                        background: "white",
                        //backgroundImage: this.state.chosenView === "career" ? "linear-gradient(to bottom, #ffffff 20%, #f7f7f7 100%)" : "none",
                        backgroundImage: "linear-gradient(to bottom, #ffffff 20%, #f7f7f7 100%)"
                        //backgroundImage: "linear-gradient(to bottom, #ffffff, #f7f7f7)"
                    }}>

                        <h3 style={{ paddingTop: 0, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>Välj vy</h3>
                        <div style={{ marginBottom: 24, paddingBottom: 24, borderBottom: "1px solid #e6e6e6" }}>
                            <div id="career" className={this.state.chosenView === "career" ? "selectedFilterUtvardering" : "filterUtvardering"} onClick={this.selectView}>Karriärsvägar</div>
                            <div id="salaryBand" className={this.state.chosenView === "salaryBand" ? "selectedFilterUtvardering" : "filterUtvardering"} onClick={this.selectView}>Lönespann</div>
                        </div>
                </div>*/}


                    {/* Job architecture v 2 */}
                    <div className={this.state.chosenView === "salaryBand" ? "" : "hide"} style={{
                        textAlign: "left",
                        marginBottom: 80,
                        background: "white",
                        padding: "20px 20px 0px",
                        borderRadius: 5,
                        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 4px 0px",
                        fontSize: 14
                    }}>
                        <div style={{ marginBottom: 40 }}>
                            <div>Systemet har automatiskt tagit fram ett förslag på lönestruktur och lönespann nedan. Strukturen bygger på er nuvarande interna lönesättning samt hänsyn tagen till lönemarknaden.</div>
                            <div>Ni kan närsomhelst ändra både struktur och spann så att det passar er organisation och lönepolicy. Detta gör ni under inställningar här åt vänster.</div>
                        </div>
                        <div className="salStructureLeftBox">
                            <div className="salStructureLeftBoxTitle">Inställningar</div>
                            <div style={{ fontSize: 12 }}>Ange på vilken/vilka nivåer ni vill använda lönespann.</div>
                            <ul style={{ marginTop: 20 }}>
                                <li><div style={{ cursor: "pointer" }}>
                                    <img src={checkedImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} /><span className="filterTitleName">För kravnivå</span>
                                </div></li>
                                <li style={{ marginTop: 15 }}><div style={{ cursor: "pointer" }}>
                                    <img src={uncheckImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} /><span className="filterTitleName">För jobbfamilj</span>
                                </div></li>
                                <li style={{ marginTop: 15 }}><div style={{ cursor: "pointer" }}>
                                    <img src={uncheckImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} /><span className="filterTitleName">För arbete</span>
                                </div></li>
                            </ul>
                            <div style={{ fontSize: 12, paddingTop: 15, borderTop: "1px solid lightgrey", marginTop: 15 }}>Ange om lönespannen tar hänsyn till:</div>
                            <ul style={{ marginTop: 20 }}>
                                <li style={{ marginTop: 15 }}><div style={{ cursor: "pointer" }}>
                                    <img src={checkedImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} /><span className="filterTitleName">Marknadslöner</span>
                                </div></li>
                                <li style={{ marginTop: 15 }}><div style={{ cursor: "pointer" }}>
                                    <img src={checkedImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} /><span className="filterTitleName">Intern struktur</span>
                                </div></li>
                            </ul>

                        </div>

                        <div className="salStructureRight">

                            {/* <Chart
                            options={this.state.options2}
                            series={this.state.series2}
                            type="line"
                            width={500}
                />*/}
                            <div>Diagram för Nivå</div>
                            <Chart
                                options={this.state.options3}
                                series={this.state.series3}
                                type="line"
                                width={500}
                            />
                            <div>Diagram för arbeten och nivåer</div>
                            <Chart
                                options={this.state.options4}
                                series={this.state.series4}
                                type="bar"
                                width={500}
                            />

                            <div>Diagram för arbeten, nivåer och jobbfamiljer</div>

                        </div>
                    </div>
                    {/*<div>ANGE DIAGRAMMET OCH LÖNESPANN INSTÄLLNINGARNA HÄR ISTÄLLET FÖR "VÄLJ VY". SKA SKE SOM POPUP NÄR KLICKAR PÅ "ANGE LÖNESPANN". 
                        MAN KAN ÄNDRA PER NIVÅ GENOM DIAGRAMMET, ELLER SÅ ANGER MAN FÖR LIKA ARBETE ELLER JOBBFAMILJ ELLER UNDERKATEGORI
                        GENOM ATT KLICKA PÅ EN SÅDAN I JOBBARIKITEKTUREN. DÅ FÅR MAN UPP STATISTIK FÖR BARA DEN JOBBFAMILJEN, DET ARBETET, KATEGORIN ETC.
                        ÖVERSIKTEN BLIR ATT MAN SER DET I JOBBARKITEKTUREN ISTÄLLET. EV KAN MAN ÄVEN VISA EN LISTA FÖR JÄMFÖRELSE, MEN FÅR bLI SENARE ISF.
            </div>    */}
                    {/*   <div>Var ska jag få plats med: Visa/dölj lönespann, inställningar lönespann (visa per nivå eller inte), skapa lönespann för nivå</div>
            <div>AI ska ta fram färdig struktur, var ska denna knapp vara</div>
        <div>Ska kunna ångra struktur</div>*/}

                    {/*<div className={this.state.chosenView === "career" ? (this.state.fullScreen ? "arcFullScreen" : "") : "hide"} style={{ textAlign: "left", width: this.state.nrOfSubFamilies == 1 ? 1050 : this.state.nrOfSubFamilies == 2 ? 1180 : this.state.nrOfSubFamilies == 3 ? 1310 : this.state.nrOfSubFamilies == 4 ? 1445 : 920, marginBottom: 80, background: "white", padding: 20, borderRadius: 5, boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)", fontSize: 14, paddingBottom: 0 }}>*/}
                    <div className={this.state.fullScreen ? "arcFullScreenWhiteBack" : "hide"}></div>
                    <div className={this.state.chosenView === "career" ? (this.state.fullScreen ? "arcFullScreen" : "") : "hide"} style={{ textAlign: "left", width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131), marginBottom: 180, background: "white", padding: 20, borderRadius: 5, boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)", fontSize: 14, paddingBottom: 0, minWidth: "96.5%" }}>
                        {/* width: */}
                        <div>
                            <div className="filterValuationBtn" style={{ marginTop: 0, marginRight: 0 }}>
                                <img onClick={this.settingPopUp} src={settings} width={16} style={{ opacity: 0.4, filter: "invert(100%)", verticalAlign: "top" }} />


                                <div style={{ marginLeft: -119, width: 125, cursor: "default", overflow: "hidden" }} className={this.state.settingPopUp ? "filterValuationBtnBox" : "hide"}>
                                    <ReactToPrint
                                        trigger={() => <a className="moreSettingsItem" href="#" style={{ marginTop: -9, borderTop: "none", paddingRight: 22 }}><img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 27, marginLeft: 9 }} />{language === "sv" ? "Exportera" : lang[language].export}</a>}
                                        content={() => this.componentRef.current}
                                        pageStyle={`
                                            @page {
                                                size: A4 landscape; /* Force landscape mode */
                                                margin: 0; /* Set to 0 to avoid extra space */
                                            }
                                            @media print {
                                                body {
                                                    margin: 0;
                                                    padding: 0;
                                                    -webkit-print-color-adjust: exact;
                                                    color-adjust: exact;
                                                }
                                                .print-content {
                                                  /*  transform: scale(${scale}); *//* Scale to fit */
                                                  zoom: ${scale};
                                                    transform-origin: top left; /* Scale from the top-left corner */
                                                   /* width: ${widthOfContainer}px; *//* Set original width for scaling */
                                                    display: block; 
                                                  /*  height: auto; 
                                                    margin: 0; 
                                                    overflow: visible; 
                                                    position: relative; 
                                                    left: 0; 
                                                    top: 0;

                                                    display: block; 
                                                    page-break-inside: avoid; */
                                                }
                                                .centerColumnTitle {
                                                    position: inherit !important; /* Adjust to prevent jumping */
                                                }
                                                    .column-equalWork { box-shadow: none !important; border: 1px solid lightgrey; box-sizing: border-box; border-radius: 5px !important}
                                            }
                                        `}
                                    />

                                    {/* <div onClick={this.exportThis} className="moreSettingsItem" style={{ marginTop: -9, borderTop: "none" }}><img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 27, marginLeft: 9 }} />
                                        {language === "sv" ? "Exportera" : lang[language].export}
                                    </div>*/}
                                </div>


                            </div>
                            <div style={{
                                display: "inline-block",
                                textAlign: "left",
                                verticalAlign: "top",
                            }}>
                                {/*<div className="copyButton" style={{width: 136,width: "fit-content"}}>Skapa automatiskt</div>{/*} <span className="questionM">?</span>*/}
                                <div className={this.state.copyOpen ? "copyButton" : "copyButton openCopyCont"} >
                                    <span style={{ paddingTop: 20, paddingBottom: 20, marginLeft: -4, paddingLeft: 10, fontSize: 14.6 }} onClick={this.copyOpen}>Skapa automatiskt</span>
                                    <select onChange={this.whatAIToCreate} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany">
                                        <option value="">Vad ska skapas?</option>
                                        <option value="families">Jobbfamiljer</option>
                                        <option value="salaryRange">Lönespann</option>
                                        <option value="fAndS">Jobbfamiljer & Lönespann</option>
                                    </select>
                                    <span onClick={this.runAIArchitecture} className={this.state.copyOpen ? "hiddenButton" : "showButton"}>Skapa</span>
                                    <span onClick={this.copyOpen} className={this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 30 }}>x</span>
                                </div>
                                <div>
                                    <div style={{ display: "inline-block", marginRight: 10, verticalAlign: "middle", cursor: "pointer" }} onClick={this.toggleFullscreen}>
                                        <img style={{ width: 22, marginLeft: 5, marginTop: 4, opacity: 0.5, marginRight: 2.5 }} src={this.state.fullScreen ? smallerScreen : fullscreenWide} />
                                        <div style={{ display: "inline-block", marginLeft: 7.5, verticalAlign: "top", marginTop: 6 }}>{this.state.fullScreen ? "Mindre skärm" : "Helskärm"}</div>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        borderLeft: "1px solid #e5e5e5",
                                        paddingLeft: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.toggleTitles} checked={this.state.toggleTitles} />
                                        <span style={{ marginLeft: 5 }}>Visa befattningar</span>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        borderLeft: "1px solid #e5e5e5",
                                        paddingLeft: 12.5,
                                        borderRight: "1px solid #e5e5e5",
                                        paddingRight: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.showSalRange} checked={this.state.showSalRange} />
                                        <span style={{ marginLeft: 5 }}>Visa lönespann</span>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        paddingLeft: 12.5,
                                        borderRight: "1px solid #e5e5e5",
                                        paddingRight: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.showComments} checked={this.state.showComments} />
                                        <span style={{ marginLeft: 5 }}>Visa kommentarer</span>
                                    </div>
                                    <div style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        paddingLeft: 0,
                                        fontSize: 13,
                                        marginLeft: 2.5,
                                    }} onClick={this.openChart}><img src={chartIcon} width={17} style={{ marginRight: 10, verticalAlign: "top", opacity: 0.5 }} />{this.state.chartOpen ? "Stäng diagram" : "Öppna diagram"}</div>
                                </div>
                            </div>
                            {/*} <div style={{
                                display: "inline-block", marginLeft: 20, background: "#f3f3f3",
                                padding: 10,
                                textAlign: "center",
                                borderRadius: 7,
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <div style={{ fontWeight: 600, marginBottom: 10 }}>Meddelande (kan döljas, ev sätt först)</div>
                                <div>Information här vid behov</div>
                        </div>*/}
                        </div>

                        <div className={!this.state.chartOpen && "hide"}>

                            <div className="chartSalaryBandBox">
                                <ChartSalaryStructure
                                    triggerChartUpdate={this.state.triggerChartUpdate}
                                    p10={this.state.p10}
                                    p25={this.state.p25}
                                    median={this.state.median}
                                    p75={this.state.p75}
                                    p90={this.state.p90}
                                    employees={this.state.employeesInChartLevels}
                                    //  ranges={this.state.rangesLevels} />
                                    ranges={this.state.chartWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels}
                                    nrOfEqualentGroups={this.state.nrOfEqualentGroups} // Used to make sure that x axis displays correct number of levels
                                // ranges={this.state.activeFamilyOrFunc}
                                />

                                <div style={{ display: "inline-block", verticalAlign: "top", fontSize: 13, marginLeft: 20, minWidth: 436 }}>
                                    <div>
                                        <div style={{ display: "block", fontWeight: 700, marginBottom: 12.5, marginTop: 10, fontSize: 15 }}>{this.state.chartWhat === "overall" ? "Lönespann per nivå" : this.state.chartWhat === "individual" ? "Lönespann för specifikt arbete" : this.state.chartWhat === "family" ? "Lönespann per nivå och familj" : "Lönespann per nivå och underkategori"}
                                            <div onClick={this.commentSalary} className="commentSalaryBtn"><img src={commentIcon} style={{ width: 15, verticalAlign: "top", /*marginRight: 5*/ }} />{/*Kommentar*/}</div>
                                        </div>
                                        <div style={{ position: "relative" }} className={this.state.showCommentSalary ? "" : "hide"}>
                                            <TextareaAutosize onClick={this.commentSalaryOnClick} onChange={this.editCommentSalary} className="autoHeightText" style={{ width: "100%", border: "1px solid grey", fontSize: 13, textAlign: "left", padding: 10, boxSizing: "border-box", cursor: "auto", marginBottom: 10, borderRadius: 3, paddingRight: 70 }} placeholder="Kommentar till lönespannet" name="notable" /*id={comment.commentId_PK}*/ value={this.state.thisSalaryComment} />
                                            <div onClick={this.saveCommentSalary} style={{
                                                marginTop: -41.5,
                                                background: this.state.salaryCommentSaved ? "#cacaca" : "#56cbb1",
                                                color: "white",
                                                padding: 5,
                                                borderRadius: 3,
                                                width: 50,
                                                textAlign: "center",
                                                marginRight: 5,
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: 0
                                            }}>{this.state.salaryCommentSaved ? "Sparat" : "Spara"}</div>
                                        </div>
                                        <select className={this.state.chartWhat !== "overall" ? "selectFamily" : "hide"} style={{ borderRadius: 3, marginBottom: 5, marginTop: 0 }} onChange={this.selectFamilyForRanges} value={this.state.selectedFamOrFunc}>
                                            {this.state.listOfFamOrFunc.map((value, index) => (
                                                <option key={value} value={value}>
                                                    {value}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="calculateWithAIBtn" onClick={this.salaryRangesSettingsPop}>Skapa med AI</div>
                                        <div className="calculateWithAIBtn" style={{ background: "#e0e0e0", color: "#2a2a2a", marginLeft: 10 }} onClick={this.salaryRangesManualPop}>Skapa manuellt</div>
                                    </div>
                                    <SalaryList handleTrashClick={this.handleTrashClick} onInputChange={this.manualSalaryRangesUpdate} createProgression={this.state.createProgression} ranges={this.state.chartWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels} />
                                </div>
                            </div>
                        </div>

                        <span ref={this.componentRef} className="print-content">
                            <span ref={this.lineHeightCalculateRef}>
                                <p style={{
                                    borderTop: "1px solid #efefef",
                                    paddingBottom: 5,
                                    marginLeft: -20,
                                    paddingLeft: 20,
                                    marginRight: -20,
                                    paddingRight: 20,
                                    marginTop: 0,
                                }}></p>
                                {/*} <div className={this.state.editGroupPop ? "popUpArchitectureOne" : "hide"}>
                    ÄNDRA FAMILJ ETC HÄR, MEN GÖR POSITION FIXED
        </div> */}

                                {/* Header */}
                                <div ref={this.targetDivRef} className="column-manual centerColumnTitle" style={{ marginBottom: 20 }}><span className="arcColumnTitleWhiteBack" whatChart="family" onClick={this.openWhatChart}>Familj</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 47,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Familj</span></div>
                                {this.state.nrOfSubFamilies > 0 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="mainFunction" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">Funktion</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 38,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Funktion</span></div>}
                                {this.state.nrOfSubFamilies > 1 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">Subfunktion</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 30,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Subfunktion</span></div>}
                                {this.state.nrOfSubFamilies > 2 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction2" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">Subfunktion 2</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 25,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Subfunktion2</span></div>}
                                {this.state.nrOfSubFamilies > 3 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction3" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">Subfunktion 3</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 25,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Subfunktion3</span></div>}
                                {this.state.nrOfSubFamilies > 4 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction4" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">Subfunktion 4</span><span style={{position: "fixed",top: 73,zIndex: 1,marginLeft: 25,background: "white",display: this.state.showFixedTitle ? "block" : "none"}}>Subfunktion4</span></div>}
                                {columns}
                                {createdRows}
                            </span>

                            {/* Comment list, for pdf export*/}
                            <div className={this.state.allSalaryComments.length === 0 ? "hide" : !this.state.showComments ? "hide" : ""} style={{ borderTop: "10px solid #ecf1f0", padding: 20, paddingTop: 40, marginLeft: -20, paddingLeft: 40, marginRight: -20, paddingRight: 40 }}>
                                <div style={{ fontWeight: 600, paddingBottom: 20, fontSize: 13 }}>Kommentarer till lönespann</div>
                                <ul style={{ fontSize: 13 }}>
                                    <li style={{fontWeight: 500, marginBottom: 10, color: "grey", fontStyle: "italic"}}>
                                    <div style={{ display: "inline-block", width: 150, paddingRight: 20}}>Namn</div>
                                    <div style={{ display: "inline-block", width: 150, paddingRight: 20 }}>Kategori</div>
                                    <div style={{ display: "inline-block" }}>Kommentar</div>
                                    </li>

                                    {this.state.allSalaryComments.map((item, index) => (
                                          <li style={{ marginBottom: 10 }}>
                                          <div style={{ verticalAlign: "top", display: "inline-block", width: 150, paddingRight: 20, wordWrap: "break-word",whiteSpace: "normal" }}>{/*<b style={{ display: "inline-block", textAlign: "center", borderRadius: "50%", color: "white", fontWeight: 800, fontStyle: "normal", verticalAlign: "top", marginRight: 10, width: 17, height: 17, fontSize: 9, lineHeight: "17px", background: "#ca5554" }}>
                                          {item.commentId}</b>*/}
                                          <span style={{width: 140}}>{item.theName}</span></div>
                                          <div style={{ verticalAlign: "top", display: "inline-block", width: 150, paddingRight: 20 }}>
                                            <span style={{width: 140}}>{
                                            item.whatFunction === "family" ? "Jobbfamilj" 
                                            : item.whatFunction === "mainFunction" ? "Funktion" 
                                            : item.whatFunction === "subFunction" ? "Subfunktion" 
                                            : item.whatFunction === "subFunction2" ? "Subfunktion 2" 
                                            : item.whatFunction === "subFunction3" ? "Subfunktion 3" 
                                            : "Arbete" 
                                            }</span>
                                            </div>
                                          <div style={{ verticalAlign: "top", display: "inline-block", }}>{item.comment}</div>
                                      </li>
                                    ))}
                                </ul>
                            </div>
                        </span>
                    </div>
                    {/* <div style={{textAlign: "left"}}>
                        <div style={{display: "inline-block", width: "10%"}}>Familj</div>
                        <div style={{display: "inline-block", width: "10%"}}>SubGrupp</div>
                        <div style={{display: "inline-block", width: "10%"}}>1</div>
                        <div style={{display: "inline-block", width: "10%"}}>2</div>
                        <div style={{display: "inline-block", width: "10%"}}>3</div>
                        <div style={{display: "inline-block", width: "10%"}}>4</div>
                        <div style={{display: "inline-block", width: "10%"}}>5</div>
                        <div style={{display: "inline-block", width: "10%"}}>6</div>
                    </div>
                   
                    <div style={{textAlign: "left", background: "grey"}}>
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Finans</div>
                   
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Redovisning</div>
                   
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block",width: "10%" }}></div>
                    <div style={{display: "inline-block", width: "10%"}}>Red.ekonom</div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                   
                    <div>
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Finans</div>
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Redovisning</div>
                    <div style={{display: "inline-block", width: "10%"}}>Ekonomiassistent</div>
                    <div style={{display: "inline-block",width: "10%" }}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    </div>
                     
                     <div style={{display: "inline-block", width: "10%", background: "blue"}}>Finans</div>
                     <div style={{display: "inline-block", width: "10%", background: "blue"}}>Värdepapper</div>
                  
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block",width: "10%" }}></div>
                    <div style={{display: "inline-block", width: "10%"}}>Förhandlare</div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    
                    <div>
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Finans</div>
                    <div style={{display: "inline-block", width: "10%", background: "blue"}}>Värdepapper</div>
                    <div style={{display: "inline-block", width: "10%"}}>Mäklare</div>
                    <div style={{display: "inline-block",width: "10%" }}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    <div style={{display: "inline-block", width: "10%"}}></div>
                    </div>

                </div>*/}





                    {/* gridRows */}




                    {/* Job architecture */}



                    {/* 
                    <div className="equivalentContainerExt" >
                        {equivalentGroup.map((group, index) => (
                            <div className="equivalentGroup">
                            <div className="infoBoxEq">
                                <div className="fillertitleEq"><h4 className={this.state.bigTextIcon ? "groupNamesH4" : "groupNamesH4A"}>Grupp {group[0].g}</h4><img src={bigText} className={this.state.bigTextIcon ? "bigTextIcon" : "hide"} id={group[0].g} onClick={this.makeTextbig} /></div>
                                <div className="titleEq flag"></div> 
                                <div className="titleEq">Kvinnor</div>
                                <div className="titleEq">Ans.</div>
                                <div className="titleEq">M. lön</div>
                            </div>
                            <ul style={{ fontSize: 15.6, letterSpacing: -0.3 }}>
                           {group.map((item, index) => (
                                   // <li className="infoBoxEq infoBoxList" 
                                    <li className={item.positionTitle === this.state.clickedGroup ? "infoBoxEq infoBoxList listSelected" :
                                       //  If clicked group is women dominated, this marks male and equal groups with lower demands but higher salary
                                       (this.state.womenPercent !== null && this.state.womenPercent > 59 && item.percentageOfWomen < 60 && item.averageSalary > this.state.avgSalary && item.g < this.state.demands) ? "infoBoxEq infoBoxList markList"
                                            // If clicked group is not women dominated, this marks women groups with higher demands but lower salary
                                          : (this.state.womenPercent !== null && this.state.womenPercent < 60 && item.percentageOfWomen > 59 && item.averageSalary < this.state.avgSalary && item.g > this.state.demands) ? "infoBoxEq infoBoxList markList"
                                            //    If clicked group is women dominated, this marks male and equal groups with same demands but higher salary
                                             : (this.state.womenPercent !== null && this.state.womenPercent > 59 && item.percentageOfWomen < 60 && item.averageSalary > this.state.avgSalary && item.g === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                              //      If clicked group is male dominated, this marks women groups with same demands but lower salary
                                               : (this.state.womenPercent !== null && this.state.womenPercent < 60 && item.percentageOfWomen > 59 && item.averageSalary < this.state.avgSalary && item.g === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                        //Else, dont mark
                                                        : "infoBoxEq infoBoxList default"
                                    }
                                         key={item.positionTitle}
                                        id={
                                            `{"group": "${item.groupNameArbVardering}", 
                                         "demands": ${item.g},
                                         "womenPercent": ${item.percentageOfWomen},
                                         "avgSalary": ${item.averageSalary} }`
                                        } 
                                        onClick={this.handleClick}>
                                        <div className={!this.state.textBig.includes(item.g) ? "fillertitleEq" : "fillertitleEqBig"}>{item.groupNameArbVardering}</div>
                                        <div className="titleEq flag">{item.percentageOfWomen > 59 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={yellowFlag} /> : item.percentageOfWomen < 41 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={blueFlag} /> : ""}</div>
                                        <div className="titleEq noBold">{Math.round(item.percentageOfWomen)}%</div>
                                        <div className="titleEq noBold">{item.numberOfPersons}</div>
                                    <div className="titleEq noBold">{Math.round(item.averageSalary)}</div>
                                    </li>
                                      ))} 
                            </ul>
                        </div>
                        ))} 

                    </div>
                    */}

                    {/*  <div className="commentContainer likvCommentCont">
                        <h3 style={{ marginTop: 0, fontWeight: 800 }}>Anteckna</h3>
                        <div style={{ marginBottom: 15 }}>
                            <div className={this.state.addAnalysis === "likvärdiga" ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.addAnalysisEqualent}>Likvärdig</div>
                            <div className={this.state.addAnalysis === "hierarkisk" ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.addAnalysisHierarchy}>Hierarkisk</div>
                        </div>
                        <textarea id="curSelComment" className="comment" placeholder="Noterbart" style={{ minHeight: 48 }} onChange={this.handleComment} value={this.state.addComment} />
                        <div className="fieldsB" onClick={this.toggleFields}>Fler fält <img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                        <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                            <textarea id="curSelReason" className="comment reason" placeholder="Orsak" style={{ minHeight: 48 }} onChange={this.handleReason} value={this.state.addReason} />
                            <textarea id="curSelTodo" className="comment todo" placeholder="Åtgärd" style={{ minHeight: 48 }} onChange={this.handleTodo} value={this.state.addTodo} />
                            <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                            <input type="text" className="comment cost" placeholder="Kostnad" onChange={this.handleCost} value={this.state.addCost} />
                            <input type="text" className="comment responsible" placeholder="Ansvarig" onChange={this.handleResponsible} value={this.state.addResponsible} />
                            <span className="dateBoxTitle">Deadline</span>
                        </div>
                        <div
                            className="commentButton"
                            style={{ width: 120, display: "block", margin: "auto", marginTop: 20, fontSize: 15.6 }}
                            onClick={this.saveNewComment}>Spara</div>
                    </div>

                    <div className="allCommentsContainer">
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>Noteringar</h3>
                            <div className={this.state.analysis === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>Alla</div>
                            <div className={this.state.analysis === "likvärdiga" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqualent}>Likvärdiga</div>
                            <div className={this.state.analysis === "hierarkisk" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterHierarchy}>Hierarkiska</div>
                        </div>

                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">Noterbart</th>
                                <th className="thpadding">Orsak</th>
                                <th className="thpadding">Åtgärd</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>Kostnad</th>
                                <th className="thpadding">Ansvarig</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>

                            {comments}
                        </table>

                        <div className={this.state.commentsLastYear < 1 ? "hide" : ""}>
                            <div style={{ marginTop: 80, fontSize: 16, fontWeight: 900, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>Föregående års kommentarer</span>
                                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
                            </div>
                            <div className={this.state.showOldComments ? "" : "hide"}>
                                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.toggleCommentPop}>Återanvänd alla</span>
                                <div style={{ top: 500, width: 450 }} className={this.state.popUpComments ? "popUp active-popUp" : "hide"}>
                                    <img alt="" src={exitImg} class="hide-box" onClick={this.toggleCommentPop} />
                                    <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}> Vill du återanvända alla kommentarer från föregående år till alla grupper?</span>
                                    <div style={{ marginTop: 40 }}>
                                        <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10, padding: 10 }} onClick={this.toggleCommentPop}>Avbryt</span>
                                        <span class="lastYearButton" style={{ marginLeft: 10, padding: 10 }} onClick={this.reuseAll}>Återanvänd alla</span>
                                    </div>
                                </div>

                                <div className={this.state.popUpComments ? "popUpFader" : "hide"}></div>
                                <table className="commentsTable lastyearList">

                                    {pastComments}

                                </table>
                            </div>
                        </div>
                    </div>
                */}
                </div>
            </>
        )
    }
}