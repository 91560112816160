import React from 'react';
import './Utvardera.css';
import check from '../../assets/images/check-mark.png';
import remove from '../../assets/images/x-mark.png';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import { Prompt } from 'react-router'
import exitImg from '../../assets/images/exit.png';
import lang from '../Language/language.js';

export class Utvardera extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: "all",
            comments: [],
            commentsThisYear: [],
            deletedComments: [],
            saveButtonStatus: "Sparat",
            showChangesPrompt: "Yes",
            showList: "pastYear",
            //For general comment
            popUpGeneralComment: false,
            newcomNotable: "",
            newcomReason: "",
            newcomTodo: "",
            newcomDeadline: "",
            newcomCost: "",
            newcomResponsible: ""
        }
    }

    componentDidMount() {
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }

        const getComments = async () => {
            let response = await fetchAuth(`/api/loadPreviousComments`, 'POST'); //<-- Change this so it also loads this years comments!!
            let data = await response.json();
            let commentsLastYear = data[0]
            const commentsByDate = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
            let commentsThisYear = data[2]
            const commentsThisYearByDate = commentsThisYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
            this.setState({ comments: commentsByDate, commentsThisYear: commentsThisYearByDate })
        }
        getComments();
    }

    listOfPastYear = () => {
        this.setState({ showList: "pastYear" })
    }

    listOfThisYear = () => {
        this.setState({ showList: "thisYear" })

    }

    //Set state for edited comments, will be used for saving with saveButton function
    editComment = (event) => {
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.comments
        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });
        this.setState({ comments: updateComments })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }  //Update save button
    }
    editTodaysComment = (event) => {
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.commentsThisYear
        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });
        this.setState({ commentsThisYear: updateComments })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }  //Update save button
    }

    completed = (event) => {
        const button = event.target.className
        const commentId_PK = event.target.id //For what comment ID
        const editedComments = this.state.comments
        let finished = "Ja"
        //If clicked on button finish/unfinished.
        if (button === "checkmark") {
            finished = "Nej" //No, because if it is already marked as finished, it should now change to not finished
        } else if (button === "crossmark") {
            finished = "Ja" //No, because if it is already marked as finished, it should now change to not finished
        }
        //add the date for when it was marked as completed/finished
        let markedCompleted = ""
        if (finished === "Ja") {
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const today = new Date(selectedYear, month, day);
            markedCompleted = today
        }
        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { finished: finished, markedCompleted: markedCompleted, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });
        this.setState({ comments: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }
    completedTodaysComment = (event) => {
        const button = event.target.className
        const commentId_PK = event.target.id //For what comment ID
        const editedComments = this.state.commentsThisYear
        let finished = "Ja"
        //If clicked on button finish/unfinished.
        if (button === "checkmark") {
            finished = "Nej" //No, because if it is already marked as finished, it should now change to not finished
        } else if (button === "crossmark") {
            finished = "Ja" //No, because if it is already marked as finished, it should now change to not finished
        }
        //add the date for when it was marked as completed/finished
        let markedCompleted = ""
        if (finished === "Ja") {
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const today = new Date(selectedYear, month, day);
            markedCompleted = today
        }
        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { finished: finished, markedCompleted: markedCompleted, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });
        this.setState({ commentsThisYear: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    deleteComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        const del = this.state.deletedComments.concat(removedComment); //add removedComment to deletedComments array:
        this.setState({
            comments: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }
    deleteTodaysComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.commentsThisYear.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.commentsThisYear.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        const del = this.state.deletedComments.concat(removedComment); //add removedComment to deletedComments array:
        this.setState({
            commentsThisYear: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }


    //Save changes in comments
    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //send only updatedComments to server for saving (and also the deleted comments)
        const updatedComments = this.state.comments.filter(el => el.updated)
        const deletedComments = this.state.deletedComments
        const updatedTodaysComments = this.state.commentsThisYear.filter(el => el.updated)
        const allUpdatedComments = updatedComments.concat(updatedTodaysComments)

        //Query DB HERE (Use deletedComments and updatedComments) 
        const saveData = async () => {
            const commentData = {
                changes: allUpdatedComments,
                deleted: deletedComments,
            }

            let response = await fetchAuth(`/api/saveUtvardering`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();
            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)

                this.setState({ saveButtonStatus: "Sparat" })

            } else { alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) }
        };
        saveData();

        //Remove property updated if saved
        const removeUpdatedProperty = this.state.comments.map(el => {
            if (el.updated) {
                delete el.updated;
                return el;
            }
            return el;
        });
        this.setState({ comments: removeUpdatedProperty })
    }

    //Filter comments
    filterEqual = () => {
        this.setState({ analysis: 'lika' })
    }
    filterEqualent = () => {
        this.setState({ analysis: 'likvärdiga' })
    }
    filterHierarchy = () => {
        this.setState({ analysis: 'hierarkisk' })
    }
    filterAll = () => {
        this.setState({ analysis: 'all' })
    }

    //For general comment
    toggleGeneralComment = () => { this.state.popUpGeneralComment ? this.setState({ popUpGeneralComment: false }) : this.setState({ popUpGeneralComment: true }) }
    addInfoGeneralComment = (event) => { this.setState({ [event.target.name]: event.target.value }) }
    addInfoGCDeadline = (event) => { this.setState({ newcomDeadline: event.target.value }) }

    createGeneralComment = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.newcomTodo === "" || this.state.newcomDeadline === "" || this.state.newcomNotable === "") {
            return alert(language === "sv" ? "Ej skapat. Noterbart, åtgärd och deadline måste anges. Lägg till och försök igen." : lang[language].notCreated)
        } else {
            const saveData = async () => {
                const generalComment = {
                    notable: this.state.newcomNotable,
                    reason: this.state.newcomReason,
                    todo: this.state.newcomTodo,
                    cost: this.state.newcomCost,
                    deadline: this.state.newcomDeadline,
                    responsible: this.state.newcomResponsible
                }
                let response = await fetchAuth(`/api/saveNewGeneralComment`, 'POST', JSON.stringify({ data: generalComment }));
                let data = await response.json();
                if (response.status !== 200) { 
                    alert(language === "sv" ? 'Ett problem uppstod. Vänligen prova igen.'  : lang[language].somethingWrong)
                } else {
                    let selectedYear = localStorage.getItem('year')
                    if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                    if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
                    let d = new Date()
                    let month = d.getMonth();
                    let day = d.getDate();
                    const todaysDate = new Date(selectedYear, month, day);
                    const dateNow = todaysDate.toISOString();
                    const toState = {
                        notable: this.state.newcomNotable,
                        reason: this.state.newcomReason,
                        todo: this.state.newcomTodo,
                        cost: this.state.newcomCost,
                        deadline: this.state.newcomDeadline,
                        responsible: this.state.newcomResponsible,
                        commentId_PK: data,
                        analysis: "general",
                        dateAdded: dateNow,
                    }
                    const commentList = this.state.commentsThisYear.concat(toState);
                    const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                    
                    if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                        this.setState({
                        commentsThisYear: sortedComments, //add the new comment to the comments array
                        popUpGeneralComment: false,
                        newcomNotable: "",
                        newcomReason: "",
                        newcomTodo: "",
                        newcomCost: "",
                        newcomDeadline: "",
                        newcomResponsible: "",       
                        })
                    } else {
                        alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) 
                    }
                }
            }
            saveData();
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let comments = language === "sv" ? "Inga åtgärder från föregående år." : lang[language].noActionsPastYear
        //For past years comments
        if (this.state.comments.length > 0) {
            comments = this.state.comments.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr key={comment.commentId_PK} id={comment.commentId_PK} className={comment.finished === "Ja" ? "trStyleLine greenTr" : "trStyleLine greyTR"}>{/*style={{ background: "#f7f7f7" }} */}
                        <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                            {comment.analysis === "lika" ? <span className="equalGroupUtvardera">{comment.equalGroup}</span> : ""}
                            <TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>

                        <td className="thpadding" style={{ background: "white" }} >{comment.finished === "Ja" ? <span className="checkmark" id={comment.commentId_PK} onClick={this.completed}>&#10004;</span> : <span className="crossmark" id={comment.commentId_PK} onClick={this.completed}>&#10006;</span>} </td>
                        <td className="thpadding" style={{ background: "white" }}><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange kommentar" : lang[language].addComment} name="comment" id={comment.commentId_PK} value={comment.comment}/></td>
                        <td className="thpadding remove" style={{ borderRight: "1px solid #e2e2e2", background: "white" }}><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /><span className="analysisIcon">{comment.analysis === "likvärdiga" ? "Lv" : comment.analysis === "general" ? "A" : comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr key={comment.commentId_PK} id={comment.commentId_PK} className={comment.finished === "Ja" ? "trStyleLine greenTr" : "trStyleLine greyTR"}>{/*style={{ background: "#f7f7f7" }} */}
                        <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                            {comment.analysis === "lika" ? <span className="equalGroupUtvardera">{comment.equalGroup}</span> : ""}
                            <TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>

                        <td className="thpadding" style={{ background: "white" }} >{comment.finished === "Ja" ? <span className="checkmark" id={comment.commentId_PK} onClick={this.completed}>&#10004;</span> : <span className="crossmark" id={comment.commentId_PK} onClick={this.completed}>&#10006;</span>} </td>
                        <td className="thpadding" style={{ background: "white" }}><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange kommentar" : lang[language].addComment} name="comment" id={comment.commentId_PK} value={comment.comment} /></td>
                        <td className="thpadding remove" style={{ borderRight: "1px solid #e2e2e2", background: "white" }}><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /><span className="analysisIcon">{comment.analysis === "likvärdiga" ? "Lv" : comment.analysis === "general" ? "A" : comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                }
            });
        }

        //for this years comments
        let commentsThisYear = language === "sv" ? "Inga åtgärder för i år." : lang[language].noActionsThisYear
        if (this.state.commentsThisYear.length > 0) {
            commentsThisYear = this.state.commentsThisYear.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr key={comment.commentId_PK} id={comment.commentId_PK} className={comment.finished === "Ja" ? "trStyleLine greenTr" : "trStyleLine greyTR"}>{/*style={{ background: "#f7f7f7" }} */}
                        <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                            {comment.analysis === "lika" ? <span className="equalGroupUtvardera">{comment.equalGroup}</span> : ""}
                            <TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={comment.deadline} onChange={this.editTodaysComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editTodaysComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>

                        <td className="thpadding" style={{ background: "white" }} >{comment.finished === "Ja" ? <span className="checkmark" id={comment.commentId_PK} onClick={this.completedTodaysComment}>&#10004;</span> : <span className="crossmark" id={comment.commentId_PK} onClick={this.completedTodaysComment}>&#10006;</span>} </td>
                        <td className="thpadding" style={{ background: "white" }}><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange kommentar" : lang[language].addComment} name="comment" id={comment.commentId_PK} value={comment.comment} /></td>
                        <td className="thpadding remove" style={{ borderRight: "1px solid #e2e2e2", background: "white" }}><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteTodaysComment} /><span className="analysisIcon">{comment.analysis === "likvärdiga" ? "Lv" : comment.analysis === "general" ? "A" : comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr key={comment.commentId_PK} id={comment.commentId_PK} className={comment.finished === "Ja" ? "trStyleLine greenTr" : "trStyleLine greyTR"}>{/*style={{ background: "#f7f7f7" }} */}
                        <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                            {comment.analysis === "lika" ? <span className="equalGroupUtvardera">{comment.equalGroup}</span> : ""}
                            <TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={comment.deadline} onChange={this.editTodaysComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editTodaysComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>

                        <td className="thpadding" style={{ background: "white" }} >{comment.finished === "Ja" ? <span className="checkmark" id={comment.commentId_PK} onClick={this.completedTodaysComment}>&#10004;</span> : <span className="crossmark" id={comment.commentId_PK} onClick={this.completedTodaysComment}>&#10006;</span>} </td>
                        <td className="thpadding" style={{ background: "white" }}><TextareaAutosize onChange={this.editTodaysComment} className="autoHeightText" placeholder={language === "sv" ? "Ange kommentar" : lang[language].addComment} name="comment" id={comment.commentId_PK} value={comment.comment} /></td>
                        <td className="thpadding remove" style={{ borderRight: "1px solid #e2e2e2", background: "white" }}><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteTodaysComment} /><span className="analysisIcon">{comment.analysis === "likvärdiga" ? "Lv" : comment.analysis === "general" ? "A" : comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                }
            });
        }

        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="utvardera" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>

                    {/* Hide Name PopUp*/}
                    <div className={this.state.popUpRightShow ? "" : "hide"} style={{ boxShadow: "0 1px 4px 0 rgb(170 170 170)", borderRadius: 4, top: this.state.topPos, left: this.state.leftPos, position: "absolute", zIndex: 1 }}>
                        <div className="popUpBoxNameExit" onClick={this.hideRightClickPopUp}>X</div>
                        <div id="IDwoman" onClick={this.rightClick} style={{ borderRight: "1px solid #54b6a4", borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} className="popUpBoxNames">Kvinna</div>
                        <div id="IDman" onClick={this.rightClick} style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }} className="popUpBoxNames">Man</div>
                    </div>
                    {/* Hide Name PopUp*/}
                    <div className="allCommentsContainer">
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Åtgärder" : lang[language].actions}</h3>
                            <div style={{ marginBottom: 24, paddingBottom: 24, borderBottom: "1px solid #e6e6e6" }}>
                                <div className={this.state.showList === "pastYear" ? "selectedFilterUtvardering" : "filterUtvardering"} onClick={this.listOfPastYear}>{language === "sv" ? "Föregående år" : lang[language].pastYear}</div><div className={this.state.showList === "thisYear" ? "selectedFilterUtvardering" : "filterUtvardering"} onClick={this.listOfThisYear}>{language === "sv" ? "Nuvarande år" : lang[language].thisYear}</div>
                            </div>
                            <div className={this.state.analysis === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
                            <div className={this.state.analysis === "lika" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqual}>{language === "sv" ? "Lika" : lang[language].equal}</div>
                            <div className={this.state.analysis === "likvärdiga" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqualent}>{language === "sv" ? "Likvärdiga" : lang[language].equivalent}</div>
                            <div className={this.state.analysis === "hierarkisk" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterHierarchy}>{language === "sv" ? "Hierarkiska" : lang[language].hierarchical}</div>
                        </div>
                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                <th className="thpadding" style={{ width: 40 }}>{language === "sv" ? "Utfört" : lang[language].completed}</th>
                                <th className="thpadding">{language === "sv" ? "Kommentar" : lang[language].comment}</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>
                            {this.state.showList === "pastYear" ? comments : commentsThisYear}
                        </table>
                        {/* PopUp General Comment */}
                        {/* <div className={this.state.popUpGeneralComment ? "popUp active-popUp" : "hide"}> */}
                        <div className={this.state.popUpGeneralComment ? "createGeneralPopUp" : "hide"}>
                            <img alt="" src={exitImg} class="hide-box" onClick={this.toggleGeneralComment} />
                            <span style={{ display: "block", marginTop: 10, fontSize: 19.6, fontWeight: 900 }}>{language === "sv" ? "Skapa allmän åtgärd" : lang[language].generalAction}</span>
                            <span style={{ fontSize: 14.6, margin: "auto", marginTop: 16, display: "block", fontWeight: "normal", lineHeight: 1.4, maxWidth: 650 }}>{language === "sv" ? "En allmän åtgärd är en åtgärd som inte är kopplad till en specifik analys. Analysspecifika åtgärder ska inte skapas här utan under respektive avsnitt (steg 3.1 och 3.2)." : lang[language].infoGeneral}</span>
                            <table className="commentsTable" style={{ marginTop: 5 }}>
                                <tr className="trStyleLine" style={{ background: "white", borderBottom: "1px solid #dbdbdb" }}>
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                    <th className="thpadding">Deadline</th>
                                    <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                    <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                </tr>
                                <tr id="newGeneralComment" className={"trStyleLine greyTR"}>
                                    <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                                        <TextareaAutosize onChange={this.addInfoGeneralComment} className="autoHeightText" placeholder="Ange vad som är noterbart" name="newcomNotable" id="newGeneralComment" value={this.state.newcomNotable} /></td>
                                    <td className="thpadding"><TextareaAutosize onChange={this.addInfoGeneralComment} className="autoHeightText" placeholder="Ange orsak" name="newcomReason" id="newGeneralComment" value={this.state.newcomReason} /></td>
                                    <td className="thpadding"><TextareaAutosize onChange={this.addInfoGeneralComment} className="autoHeightText" placeholder="Ange åtgärd" name="newcomTodo" id="newGeneralComment" value={this.state.newcomTodo} /></td>
                                    <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={this.state.newcomDeadline} onChange={this.addInfoGCDeadline} name="deadline" id="newGeneralComment" /></td>
                                    <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={this.state.newcomCost} onChange={this.addInfoGeneralComment} name="newcomCost" id="newGeneralComment" /></td>
                                    <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.addInfoGeneralComment} className="autoHeightText" placeholder="Ange ansvarig" name="newcomResponsible" id="newGeneralComment" value={this.state.newcomResponsible} /></td>
                                </tr>
                            </table>
                            <div style={{ marginTop: 24, marginBottom: 16 }}>
                                <span class="lastYearButton" style={{ background: "#c95759", marginRight: 10, padding: 10, display: "inline-block", width: 100 }} onClick={this.toggleGeneralComment}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                <span class="lastYearButton" style={{ marginLeft: 10, padding: 10, display: "inline-block", width: 100 }} onClick={this.createGeneralComment}>{language === "sv" ? "Skapa" : lang[language].create}</span>
                            </div>
                        </div>
                        <div className={this.state.popUpGeneralComment ? "popUpFader" : "hide"}></div>
                        <div className={this.state.showList !== "thisYear" ? "hide" : "createGeneralBtn"} onClick={this.toggleGeneralComment}>{language === "sv" ? "Skapa allmän åtgärd" : lang[language].generalAction}</div>
                    </div>
                </div>
            </>
        )
    }


}