import React, { useEffect, useState } from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

const ReadOnlyDraftEditor = ({ rawContent }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (rawContent) {
      const contentState = convertFromRaw(JSON.parse(rawContent));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [rawContent]);

  return (
    <div style={{ border: 'none', minHeight: '280px' }}>
      <Editor editorState={editorState} readOnly={true} />
    </div>
  );
};

export default ReadOnlyDraftEditor;