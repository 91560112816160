import React from 'react';
import logoGreen from '../../assets/images/logo-lonelys.png';
import './Login.css';
import lang from '../Language/language.js';

export class TwoFactor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            QRCode: "",
            factorCode: "",
            secret: ""
        }
        this.factorCode = this.factorCode.bind(this);
        this.sendCode = this.sendCode.bind(this);
    }
    componentDidMount() { this.getData(); }

    getData() {
        const loadFieldData = async () => {
            let response = await fetch(`/api/twoFactor`, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            //If no previous secret
            if (response.status === 201) {
                let data = await response.json();
                this.setState({ QRCode: data.QRCode, secret: data.secret })
                return;
            }
            //If previous secret
            if (response.status === 200) {
                let data = await response.json();
                return;
            }
        }
        loadFieldData();
    }

    factorCode(event) { this.setState({ factorCode: event.target.value }) }

    sendCode() {
        const data = {
            factorCode: this.state.factorCode,
            secret: this.state.secret,
        }
        const fetchIt = async () => {
            let response = await fetch(`/api/verifyTwoFactor`, {
                method: 'POST',
                credentials: 'same-origin', //Try credentials: 'include' if problem with cookie set, otherwise use credentials:'same-site'
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data
                })
            });
        }
        fetchIt();

    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <div>
                <img src={logoGreen} className="logo2Factor" style={{ width: 220, width: 170, float: "left", marginLeft: 30, marginTop: 15 }} />
                <div style={{ paddingTop: 1 }}>
                    <div className="form-container">
                        <h1 style={{ marginTop: -20 }}>{language === "sv" ? "Tvåstegsverifiering" : lang[language].twoStep}</h1>
                        <img style={{ width: 150 }} src={this.state.QRCode} />
                        <div style={{ marginTop: 10 }}>
                            <input type="Number" placeholder="Ange kod" id="hideArrows" onChange={this.factorCode} value={this.state.factorCode}/>
                            <div className="verifyButton" onClick={this.sendCode}>{language === "sv" ? "Verifiera" : lang[language].verify}</div>
                        </div>
                        <div className={this.state.secret === "" ? "hide" : ""}>
                            <p style={{ textDecoration: "underline", marginBottom: 0, marginTop: 39, paddingTop: 10 }}>{language === "sv" ? "Gör såhär:" : lang[language].followSteps}</p>
                            <p className="verifyText"><span className="verifyStep">1</span>{language === "sv" ? `Öppna appen "Google Authenticator" på din mobiltelefon och scanna QR koden ovan.` : lang[language].openAuthAndScan}</p>
                            <p className="verifyText"><span className="verifyStep">2</span>{language === "sv" ? "Ange den kod som du ser på appen." : lang[language].enterCodeFromApp}</p>
                            <p className="verifyText downloadText">{language === "sv" ? "Om du inte har appen kan du ladda ner den " : lang[language].ifNoApp}<u><b><a style={{ color: "#46b9a3" }} href="">{language === "sv" ? "här." : lang[language].dHere}</a></b></u></p>
                        </div>
                        <div className={this.state.secret === "" ? "" : "hide"}>
                            <p style={{ textDecoration: "underline", marginBottom: 0, marginTop: 39, paddingTop: 10 }}>{language === "sv" ? "Gör såhär:" : lang[language].followSteps}</p>
                            <p className="verifyText" style={{textAlign: "center"}}>{language === "sv" ? `Öppna appen "Google Authenticator" på din mobiltelefon och ange den kod som du ser på appen.` : lang[language].openGoogleAuth}</p>
                            <p className="verifyText downloadText" style={{color: "grey",fontSize: 13, paddingLeft: 10, paddingRight: 10}}>{language === "sv" ? "Om du har tappat din mobil eller har andra problem med att logga in kan du kontakta oss så hjälper vi dig." : lang[language].contactIfNeeded}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}