import React from "react";
import lang from '../../../Language/language.js';

export class Inledning extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let menSalary = ""
        if (this.props.totStats.avgSalMen !== undefined) menSalary = parseInt(this.props.totStats.avgSalMen).toLocaleString()
        let womenSalary = ""
        if (this.props.totStats.avgSalWomen !== undefined) womenSalary = parseInt(this.props.totStats.avgSalWomen).toLocaleString()
        let diff = ""
        if (this.props.totStats.diffWomen !== undefined) diff = parseInt(this.props.totStats.diffWomen).toLocaleString()
        let total = ""
        if (this.props.totStats.avgSalTot !== undefined) total = parseInt(this.props.totStats.avgSalTot).toLocaleString()
        let diffSalWomenPercent = ""
        if (this.props.totStats.diffSalWomenPercent !== undefined && this.props.totStats.avgSalWomen !== undefined) {
            diffSalWomenPercent =  this.props.totStats.diffSalWomenPercent
            
        }

        return (
            <div className="aPage">

                <h2 className="h2PaddingTop">{language === "sv" ? "1. Inledning" : lang[language].tocIntro}</h2>

                <p className="P-document">{language === "sv" ? "Enligt diskrimineringslagen skall samtliga arbetsgivare genomföra en lönekartläggning varje år. Syftet med lönekartläggningen är att upptäcka, åtgärda och förhindra osakliga löneskillnader mellan kvinnor och män." : lang[language].intOne}</p>
                <p className="P-document">{this.props.totStats.nrWomen + this.props.totStats.nrMen}{language === "sv" ? " anställda omfattas totalt av lönekartläggningen. Den totala lönefördelningen visas i tabellen nedan." : lang[language].intTwo}</p>
                <h4 style={{ marginBlockEnd: 10 }}>{language === "sv" ? "Sammanställning för samtliga grupper" : lang[language].intSum}</h4>
                {this.props.hideSalaries ?
                    <table className="tableLeftText">
                        <tr>
                            <th style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Antal" : lang[language].intEmp}</th>
                            <th style={{ textAlign: "left", width: 60 }}></th>
                            <th style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Lönefördelning" : lang[language].intDist}</th>
                            <th style={{ textAlign: "left", width: 90 }}></th>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Män" : lang[language].men}:</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrMen}</td>
                            <td style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Differens" : lang[language].intDiff}<span style={{ fontSize: 14, letterSpacing: -0.2 }}>({language === "sv" ? "kvinnor" : lang[language].women}):</span></td>
                            <td style={{ textAlign: "left", width: 90 }}>{diffSalWomenPercent}%</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Kvinnor" : lang[language].women}:</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrWomen}</td>
                            <td style={{ textAlign: "left", width: 120 }}></td>
                            <td style={{ textAlign: "left", width: 90 }}></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}></td>
                            <td style={{ textAlign: "left", width: 60 }}></td>
                            <td style={{ textAlign: "left", width: 120 }}></td>
                            <td style={{ verticalAlign: "top", textAlign: "left", width: 90 }}></td>
                        </tr>
                        <tr style={{ background: "#f3f3f3", fontWeight: "bold" }}>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Totalt" : lang[language].total}</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrTot}</td>
                            <td style={{ textAlign: "left", width: 120 }}></td>
                            <td style={{ textAlign: "left", width: 90 }}></td>
                        </tr>
                    </table>
                    :
                    <table className="tableLeftText">
                        <tr>
                            <th style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Antal" : lang[language].intEmp}</th>
                            <th style={{ textAlign: "left", width: 60 }}></th>
                            <th style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Medellön" : lang[language].averageSal}</th>
                            <th style={{ textAlign: "left", width: 90 }}></th>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Män" : lang[language].men}:</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrMen}</td>
                            <td style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Män" : lang[language].men}:</td>
                            <td style={{ textAlign: "left", width: 90 }}>{menSalary}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Kvinnor" : lang[language].women}:</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrWomen}</td>
                            <td style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Kvinnor" : lang[language].women}:</td>
                            <td style={{ textAlign: "left", width: 90 }}>{womenSalary}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left", width: 60 }}></td>
                            <td style={{ textAlign: "left", width: 60 }}></td>
                            <td style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Differens" : lang[language].intDiff} <span style={{ fontSize: 14, letterSpacing: -0.2 }}>({language === "sv" ? "kvinnor" : lang[language].women}):</span></td>
                            <td style={{ verticalAlign: "top", textAlign: "left", width: 90 }}>{parseInt(this.props.totStats.diffWomen).toLocaleString()}</td>
                        </tr>
                        <tr style={{ background: "#f3f3f3", fontWeight: "bold" }}>
                            <td style={{ textAlign: "left", width: 60 }}>{language === "sv" ? "Totalt" : lang[language].total}</td>
                            <td style={{ textAlign: "left", width: 60 }}>{this.props.totStats.nrTot}</td>
                            <td style={{ textAlign: "left", width: 120 }}>{language === "sv" ? "Totalt" : lang[language].total}</td>
                            <td style={{ textAlign: "left", width: 90 }}>{total}</td>
                        </tr>
                    </table>
                }
                <p className="P-document" style={{ paddingTop: 20, marginBottom: 10 }}>{language === "sv" ? "Följande personer har ingått i gruppen som genomfört lönekartläggningen:" : lang[language].intPart}</p>
                <p className="P-document" style={{ marginBottom: 10 }}>{this.props.medverkandeText}</p>

            </div>
        )
    }
}