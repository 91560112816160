import React from 'react';
import './Login.css';
import logoGreen from '../../assets/images/logo-lonelys.png';
import emailIcon from '../../assets/images/emailSent.png';
import ReCAPTCHA from "react-google-recaptcha";
import lang from '../Language/language.js';

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            wrongInfo: false,
            reCap: "",
            reCaptchaOn: false,
            QRCode: "",
            secret: "",
            show2Factor: false,
            factorCode: "",
            forgotPassPopUp: false,
            emailResetPass: "",
            resetPassSent: false,
            reCapReset: "",
            reCapResetOn: false,
        }
        this.factorCode = this.factorCode.bind(this);
        this.sendCode = this.sendCode.bind(this);
    }

    login = (e) => {
        let language = localStorage.getItem('language') ?? 'sv';
        e.preventDefault(); //<-- button click always make the page to refresh this prevents that (it also rerenders the component). So if there is a button we have to use this. Another way is to make a div instead
        if (this.state.reCaptchaOn === true && this.state.reCap === "") {
            alert(language === "sv" ? `Du har glömt att kryssa i rutan "Jag är ingen robot". Kryssa i den och logga in på nytt.` : lang[language].forgotRobotCheckbox)
            return;
        } else {
            const userInfo = {
                email: this.state.email,
                password: this.state.password,
                reCaptcha: this.state.reCap
            }
            const fetchIt = async () => {
                let response = await fetch(`/api/login`, {
                    method: 'POST',
                    // mode: 'same-origin', //for cookie set
                    // redirect: 'follow', //for cookie set
                    credentials: 'same-origin', //Try credentials: 'include' if problem with cookie set, otherwise use credentials:'same-site'
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: userInfo
                    })
                });

                if (response.status === 200) {
                    let data = await response.json();
                    localStorage.setItem('localT', data.localToken)
                    window.location.href = '/'; // <-- Redirect to start of app 
                    return;
                } else if (response.status === 403) {
                    this.setState({ wrongInfo: true })
                    this.captcha.reset()
                } else if (response.status === 500) {
                    alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
                } else if (response.status === 400) {
                    this.setState({ wrongInfo: true, reCaptchaOn: true })
                    this.captcha.reset()
                }
                //If 2 factor is on
                else if (response.status === 202) {
                    this.setState({ show2Factor: true })
                    return;
                }
            }
            fetchIt();
        }
    }

    pass = (event) => {
        const pass = event.target.value
        this.setState({ password: pass })
    }

    email = (event) => {
        const email = event.target.value
        this.setState({ email: email })
    }
    emailResetPass = (event) => {
        const email = event.target.value
        this.setState({ emailResetPass: email })
    }

    addRecApp = (value) => {
        this.setState({ reCap: value })
        //window.value.reset();
    }

    addRecAppReset = (value) => {
        this.setState({ reCapReset: value })
    }

    factorCode(event) { this.setState({ factorCode: event.target.value }) }

    forgotPass = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
            const data = {
                email: this.state.emailResetPass,
                reCaptcha: this.state.reCapReset
            }
            let response = await fetch(`/api/resetPass`, {
                method: 'POST',
                credentials: 'same-origin', //Try credentials: 'include' if problem with cookie set, otherwise use credentials:'same-site'
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data
                })
            });
            if (response.status === 200) {
                this.setState({ resetPassSent: true, emailResetPass: "" })
            } else if (response.status === 400) {
                this.setState({ reCapResetOn: true})
                alert(language === "sv" ? 'Vänligen fyll i "Jag är ingen robot"' : lang[language].notRobot)
            } else if (response.status === 406) {
                this.setState({ reCapResetOn: true})
                alert(language === "sv" ? 'Felaktig reCaptcha. Fyll i "Jag är ingen robot" igen.' : lang[language].wrongRecaptcha)
            } else {
                alert(language === "sv" ? 'Något gick fel. Försök igen.' : lang[language].somethingWrong)
            }
    }

    forgotPassPopUp = () => { this.state.forgotPassPopUp ? this.setState({ forgotPassPopUp: false, resetPassSent: false, emailResetPass: "" }) : this.setState({ forgotPassPopUp: true }) }

    sendCode() {
        let language = localStorage.getItem('language') ?? 'sv';
        const data = {
            factorCode: this.state.factorCode,
            secret: this.state.secret,
            email: this.state.email,
            password: this.state.password
        }
        const fetchIt = async () => {
            let response = await fetch(`/api/verifyTwoFactor`, {
                method: 'POST',
                credentials: 'same-origin', //Try credentials: 'include' if problem with cookie set, otherwise use credentials:'same-site'
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data
                })
            });
            if (response.status === 200) {
                let data = await response.json();
                localStorage.setItem('localT', data.localToken)
                window.location.href = '/'; // <-- Redirect to start of app 
                return;
            } else if (response.status === 403) { alert("Fel kod.") }
            else {
                alert(language === "sv" ? "Ett fel inträffade. Ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
            }
        }
        fetchIt();

    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <div className={this.state.forgotPassPopUp ? "resetPass" : "hide"}>
                    <div className="passX" onClick={this.forgotPassPopUp}>x</div>
                    {this.state.resetPassSent === false ?
                        <>
                            <b style={{ fontSize: 16, paddingBottom: 20, display: "block" }}>{language === "sv" ? "Återställ lösenord" : lang[language].resetPass}</b>
                            <div>{language === "sv" ? "Fyll i din användare nedan för att återställa ditt lösenord." : lang[language].addUsernameToReset}</div>
                            <input style={{ marginTop: 40, marginBottom: 20 }} className="form-input" value={this.state.emailResetPass} type="text" placeholder={language === "sv" ? "Användarnamn" : lang[language].username} onChange={this.emailResetPass} />
                           <div className={this.state.reCapResetOn ? "marButtom50" : "hide"}>
                            <ReCAPTCHA
                                ref={(r) => this.captcha = r}
                                sitekey="6Lel_OIbAAAAAFkU-BFBNAo1VFVvOJVo1cA1qHUG"
                                onChange={this.addRecAppReset}
                                // size="compact"
                                hl="sv"
                            />
                            </div>
                            <button style={{marginTop: 0}} className="login-button" onClick={this.forgotPass}>{language === "sv" ? "Återställ lösenord" : lang[language].resetPass}</button>
                        </>
                        : <div><img style={{ marginBottom: 20, width: 60 }} src={emailIcon} /><p style={{ padding: 5 }}>{language === "sv" ? "Ett mail med en länk har skickats till din användare. Klicka på länken för att ställa in ditt nya lösenord." : lang[language].emailSent}</p> <p style={{ marginTop: 30, borderTop: "1px solid #e9e9e9", paddingTop: 30 }}><u>OBS:</u></p><p style={{ marginTop: -10 }}>{language === "sv" ? "Kika i skräpkorgen om du inte ser mailet." : lang[language].checkFolder}</p></div>}
                </div>
                <div className={this.state.forgotPassPopUp ? "shadowPass" : "hide"}></div>
                {this.state.show2Factor ?
                    <div>
                        <img src={logoGreen} className="logo2Factor" style={{ width: 220, width: 170, float: "left", marginLeft: 30, marginTop: 15 }} />
                        <div style={{ paddingTop: 1 }}>
                            <div className="form-container">
                                <h1 style={{ marginTop: -20 }}>{language === "sv" ? "Tvåstegsverifiering" : lang[language].twoStep}</h1>
                                <img style={{ width: 150 }} src={this.state.QRCode} />
                                <div style={{ marginTop: 10 }}>
                                    <input type="Number" placeholder="Ange kod" id="hideArrows" onChange={this.factorCode} value={this.state.factorCode} />
                                    <div className="verifyButton" onClick={this.sendCode}>{language === "sv" ? "Verifiera" : lang[language].verify}</div>
                                </div>
                                <div className={this.state.secret === "" ? "" : "hide"}>
                                    <p style={{ textDecoration: "underline", marginBottom: 0, marginTop: 39, paddingTop: 10 }}>{language === "sv" ? "Gör såhär:" : lang[language].followSteps}</p>
                                    <p className="verifyText" style={{ textAlign: "center" }}>{language === "sv" ? `Öppna appen "Google Authenticator" på din mobiltelefon och ange den kod som du ser på appen.` : lang[language].openGoogleAuth}</p>
                                    <p className="verifyText downloadText" style={{ color: "grey", fontSize: 13, paddingLeft: 10, paddingRight: 10 }}>{language === "sv" ? "Om du har tappat din mobil eller har andra problem med att logga in kan du kontakta oss så hjälper vi dig." : lang[language].contactIfNeeded}</p>
                                </div>
                            </div>
                        </div>
                        <div className="forgot_pass" onClick={this.forgotPassPopUp}>{language === "sv" ? "Glömt lösenord?" : lang[language].forgotPass}</div>
                    </div>
                    :
                    <div className="login-page">
                        <div className="form-container">
                            <img style={{ marginBottom: 30, width: 220 }} src={logoGreen} />
                            <form className="login-form">
                                <input className="form-input" value={this.state.email} type="text" placeholder={language === "sv" ? "Användarnamn" : lang[language].username} onChange={this.email} />
                                <input className="form-input" value={this.state.password} type="password" placeholder={language === "sv" ? "Lösenord" : lang[language].password} onChange={this.pass} style={{ marginBottom: 10 }} />
                                <div className={this.state.reCaptchaOn ? "" : "hideImportant"} style={{ display: "inline-block", overflow: "hidden", width: 300, height: 74, textAlign: "left", border: "1px solid", borderRadius: 8, transform: "scale(0.87)" }}>
                                    <ReCAPTCHA
                                        ref={(r) => this.captcha = r}
                                        sitekey="6Lel_OIbAAAAAFkU-BFBNAo1VFVvOJVo1cA1qHUG"
                                        onChange={this.addRecApp}
                                        // size="compact"
                                        hl="sv"
                                    />
                                </div>
                                <button className="login-button" onClick={this.login}>{language === "sv" ? "Logga in" : lang[language].login}</button>
                            </form>
                        </div>
                        <div className={this.state.wrongInfo ? "error_box" : "hide"}>
                            {language === "sv" ? "Fel användarnamn eller lösenord." : lang[language].wrongUserOrPass}
                        </div>
                        {/* <div className="forgot_pass">Glömt lösenord?</div>*/}
                        <div className="forgot_pass" onClick={this.forgotPassPopUp}>{language === "sv" ? "Glömt lösenord?" : lang[language].forgotPass}</div>
                    </div>
                }
            </>
        )
    }

}