import React from 'react';
import ReactToPrint from "react-to-print";
import './Dokumentera.css?v=1.2';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import { ComponentToPrint } from './ComponentToPrint/ComponentToPrint.js';
import logo from '../../assets/images/logo-default.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import { fetchAuth } from '../../fetch-auth';
import { fetchAuthUpload } from '../../fetch-auth';
import { wordDocument } from './word.js'
import { wordDocumentDefault } from './wordDefault.js'
import word from '../../assets/images/word.png';
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { jitter } from '../AnalysLika/Jitter';
import { RunProperties } from 'docx';
import loaderImg from '../../assets/images/loaderImg.gif';
import settings from '../../assets/images/Settings.png';
import { NavLink } from 'react-router-dom';
import lang from '../Language/language.js';


export class Dokumentera extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsButton: 'logga',
            toggleDiv: false,
            samverkanText: "",
            praxisText: "",
            medverkandeText: "",
            fritext: "",
            fritextTitel: "",
            logo: "",
            temporaryLogo: "",
            //for save button
            buttonSamverkanStatus: "Sparat",
            buttonPraxis: "Sparat",
            buttonParticipants: "Sparat",
            buttonfreetext: "Sparat",
            buttonLogoSize: "Sparat",
            logoWidth: 230,
            //for pdf (sent as props to "ComponentToPrint")
            vikt: [],
            comments: [],
            oldComments: [],
            persData: [],
            company: "",
            legalComp: "",
            whatEvaluation: "",
            valuationName: "",
            totStats: {},
            // uniqueGroups: [],
            theYear: 2021,
            nrOfEqualentGroups: 6,
            //For hiding salaries
            hideSalaries: false,
            //Make sure everything is loaded before creating documentation
            allDataLoaded: false,
            //For chart
            chartSetting: "off",
            chartPercent: "off",
            chartAge: "off",
            chartExp: "off",
            showAgeChart: "off",
            addOnChart: "none",
            //For editedFactors
            editedFactors: [],
            originalFactors: [],
            whatLevelDesc: "loading",
            //chart exp
            chartImages: "none",
            series: [],
            options: {
                chart: {
                    id: "chartId"
                },
            },
            //chart age
            chartImagesAge: "none",
            seriesAge: [],
            optionsAge: {
                chart: {
                    id: "chartIdAge"
                },
            },
            //chart extra exp
            chartImagesExtraExp: "none",
            seriesExtraExp: [],
            optionsExtraExp: {
                chart: {
                    id: "chartIdExtraExp"
                },
            },
            //chart extra age
            chartImagesExtraAge: "none",
            seriesExtraAge: [],
            optionsExtraAge: {
                chart: {
                    id: "chartIdExtraAge"
                },
            },
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let hideSalaries = localStorage.getItem('docoHideSalary')
        hideSalaries === "on" ? this.setState({ hideSalaries: true }) : this.setState({ hideSalaries: false })
        const loadFieldData = async () => {
            try {
                let response = await fetchAuth(`/api/loadDocFields`, 'POST');
                let data = await response.json();
                let originalFactors = data[17][0]
                let whatLevelDesc = data[18]
                let editedFactors = data[16][0]
                let nrOfEqualentGroups = data[9]
                let chartSetting = data[10]
                let chartPercent = data[11]
                let selectedChart = data[12]
                let showAgeChart = data[13]
                let addOnDetails = data[14]
                if (addOnDetails == undefined || addOnDetails == null) { addOnDetails = "off" }
                let addOnChart = data[15]
                if (addOnChart == undefined || addOnChart == null) { addOnChart = "none" }
                let doco = "";
                if (data[0][0] != undefined) { doco = data[0][0]; }
                let samverkanText = ""
                if (doco.cooperation != null) { samverkanText = doco.cooperation }
                let praxisText = ""
                if (doco.praxis != null) { praxisText = doco.praxis }
                let participants = ""
                if (doco.participants != null) { participants = doco.participants }
                let legalComp = ""
                let whatEvaluation = ""
                let valuationName = ""
                if (data[8][0].length != 0) {
                    if (data[8][0].legalName != undefined) {
                        legalComp = data[8][0].legalName
                    }
                    if (data[8][0].whatEvaluation != undefined) {
                        whatEvaluation = data[8][0].whatEvaluation
                    }
                    if (data[8][0].valuationName != undefined) {
                        valuationName = data[8][0].valuationName
                    }
                }
                const vikt = data[1][0]; //<-- change to data[1] only if error
                const comments = data[2];
                const persData = data[3];
                const company = data[4][0]['company'];
                if (data[5].length != 0) {
                    if (data[5][0].logo != undefined) {
                        const logo = data[5][0].logo
                        this.setState({
                            logo: data[5][0].logo,
                        })
                    }
                    if (data[5][0].logoWidth != undefined) {
                        const logoWidth = data[5][0].logoWidth
                        this.setState({
                            logoWidth: data[5][0].logoWidth
                        })
                    }
                }
                const commentsLastYear = data[6];
                const commentsBeforeLastYear = data[7]; //This is now an empty array
                const oldComments = commentsLastYear.concat(commentsBeforeLastYear)

                //VAD VI BEHÖVER FÅ UT SOM INTE REDAN FINNS:
                /*TOTAL STATISTIK
                - Totalt antal anställda
                - Antal män och kvinnor
                - Total meddellön
                - Meddellön för män resp kvinnor samt differensen för kvinnor*/
                const nrTot = persData.length
                const women = persData.filter(emp => emp.gender === "woman")
                const men = persData.filter(emp => emp.gender === "man")
                const nrWomen = women.length
                const nrMen = men.length
                const avgSalTot = ((persData.reduce((sum, cur) => sum + cur.totalSalary, 0)) / nrTot).toFixed(0)
                const avgSalMen = ((men.reduce((sum, cur) => sum + cur.totalSalary, 0)) / nrMen).toFixed(0)
                const avgSalWomen = ((women.reduce((sum, cur) => sum + cur.totalSalary, 0)) / nrWomen).toFixed(0)
                const diffWomen = avgSalWomen - avgSalMen
               // const diffSalWomenPercent = Math.round((100 - ((avgSalMen / avgSalWomen) * 100)) * 10) / 10
               const diffSalWomenPercent = ((diffWomen / avgSalMen) * 100).toFixed(1)

                const totStats = {
                    nrTot: nrTot,
                    women: women,
                    men: men,
                    nrWomen: nrWomen,
                    nrMen: nrMen,
                    avgSalTot: avgSalTot,
                    avgSalMen: avgSalMen,
                    avgSalWomen: avgSalWomen,
                    diffWomen: diffWomen,
                    diffSalWomenPercent: diffSalWomenPercent,
                }

                //Make comments anonomous by removing everything that is in parantheses
                for (let i = 0; i < comments.length; i++) { //This years comments
                    //Make notable anonymous
                    if (comments[i].notable !== undefined && comments[i].notable !== null) {
                        let hide = comments[i].notable.replace(/\([^()]*\)/g, '')
                        comments[i].notable = hide
                    } //Make reason anonymous
                    if (comments[i].reason !== undefined && comments[i].reason !== null) {
                        let hideR = comments[i].reason.replace(/\([^()]*\)/g, '')
                        comments[i].reason = hideR
                    } //Make todo anonymous
                    if (comments[i].todo !== undefined && comments[i].todo !== null) {
                        let hideT = comments[i].todo.replace(/\([^()]*\)/g, '')
                        comments[i].todo = hideT
                    }
                }
                for (let i = 0; i < oldComments.length; i++) { //Old comments
                    //Make notable anonymous
                    if (oldComments[i].notable !== undefined && oldComments[i].notable !== null) {
                        let hide = oldComments[i].notable.replace(/\([^()]*\)/g, '')
                        oldComments[i].notable = hide
                    } //Make reason anonymous
                    if (oldComments[i].reason !== undefined && oldComments[i].reason !== null) {
                        let hideR = oldComments[i].reason.replace(/\([^()]*\)/g, '')
                        oldComments[i].reason = hideR
                    } //Make todo anonymous
                    if (oldComments[i].todo !== undefined && oldComments[i].todo !== null) {
                        let hideT = oldComments[i].todo.replace(/\([^()]*\)/g, '')
                        oldComments[i].todo = hideT
                    }
                }

                let selectedYear = localStorage.getItem('year')
                if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }

                this.setState({
                    samverkanText: samverkanText,
                    praxisText: praxisText,
                    medverkandeText: participants,
                    fritext: doco.freeText,
                    fritextTitel: doco.freeTextTitle,
                    //---->     //logo: logoImg // <--- not working
                    //below is for use when creating pdf (being sent as props to "ComponentToPrint")
                    vikt: vikt,
                    comments: comments,
                    oldComments: oldComments,
                    persData: persData,
                    company: company,
                    legalComp: legalComp,
                    whatEvaluation: whatEvaluation,
                    valuationName: valuationName,
                    totStats: totStats, //for inledning
                    theYear: selectedYear,
                    nrOfEqualentGroups: nrOfEqualentGroups,
                    chartSetting: chartSetting,
                    chartPercent: chartPercent,
                    showAgeChart: showAgeChart,
                    addOnChart: addOnChart,
                    addOnDetails: addOnDetails,
                    editedFactors: editedFactors,
                    originalFactors: originalFactors,
                    whatLevelDesc: whatLevelDesc,
                    //uniqueGroups: uniqueGroups
                });
                selectedChart === "allCharts" ? this.setState({ chartAge: "on", chartExp: "on" }) : selectedChart === "ageChart" ? this.setState({ chartAge: "on", chartExp: "off" }) : this.setState({ chartAge: "off", chartExp: "on" })

                if (this.state.chartSetting === "on") {

                    //********** If charts should be show, load them.
                    //1. Prepare charts for word document before generating wordfile
                    let employees = this.state.persData.slice(0) //create copy of persData without mutating
                    let dividebyGroup = employees.reduce((result, value) => {
                        result[value.groupNameArbVardering] = result[value.groupNameArbVardering] || [];
                        result[value.groupNameArbVardering].push({ positionTitle: value.positionTitle, gender: value.gender, totalSalary: value.totalSalary, experience: value.experience, age: value.age, addOn: value.addOn, salaryNoExtra: value.salary });
                        return result;
                    }, {});
                    let groups = Object.entries(dividebyGroup)
                    groups.sort((a, b) => a[0][0].localeCompare(b[0][0])) //Sort groups in alphabetic order
                    let chartImages = []// The array that will hold our chart images
                    let chartImagesAge = [] // The array that will hold our age chart images
                    let chartImagesExtraExp = [] // The array that will hold our experience chart images for highlighting extra/addON
                    let chartImagesExtraAge = [] // The array that will hold our age chart images for highlighting extra/addON

                    //1.2 Calculate data for each group
                    for (let i = 0; i < groups.length; i++) {
                        const prepareDataForImage = () => {
                            return new Promise((resolve, reject) => {
                                const women = groups[i][1].filter(emp => emp.gender === "woman")
                                const men = groups[i][1].filter(emp => emp.gender === "man")
                                if (this.state.chartExp === "on" || (this.state.showAgeChart === "off" || this.state.showAgeChart === "adminOff")) {
                                    //1.3 Create jitter
                                    let chartWomen = ""
                                    let chartMen = ""
                                    if (this.state.chartPercent !== "on") {
                                        chartWomen = women.map(function (el) { return { y: el.totalSalary, x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                        chartMen = men.map(function (el) { return { y: el.totalSalary, x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                    } else {
                                        //If percent, take el.totalSalary / salaryTotal * 100
                                        let salaryTotal = groups[i][1].reduce((sum, cur) => sum + cur.totalSalary, 0)
                                        chartWomen = women.map(function (el) { return { y: ((el.totalSalary / salaryTotal) * 100).toFixed(1), x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                        chartMen = men.map(function (el) { return { y: ((el.totalSalary / salaryTotal) * 100).toFixed(1), x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                    }
                                    jitter(chartMen, chartWomen, 180)
                                    //1.4 fix max length of x axis
                                    let womenExperiences = women.map(person => person.experience)
                                    let menExperiences = men.map(person => person.experience)
                                    let allExperiences = womenExperiences.concat(menExperiences)
                                    let longestExp = Math.max(...allExperiences)
                                    if (longestExp < 10) longestExp = 10
                                    if (longestExp > 10 && longestExp < 16) longestExp = 16
                                    //1.5 Restructure data to fit apexchart (didnt work with x,y for some reason)
                                    let menForChart = []
                                    for (let i = 0; i < chartMen.length; i++) {
                                        menForChart.push([chartMen[i].x, chartMen[i].y])
                                    }
                                    let womenForChart = []
                                    for (let i = 0; i < chartWomen.length; i++) {
                                        womenForChart.push([chartWomen[i].x, chartWomen[i].y])
                                    }
                                    //1.6 Set state for chart
                                    this.setState({
                                        series: [{
                                            name: "Män",
                                            data: menForChart
                                        },
                                        {
                                            name: "Kvinnor",
                                            data: womenForChart
                                        }],
                                        options: {
                                            chart: {
                                                offsetX: 0,
                                                id: "chartId",
                                                width: 350,
                                                type: 'scatter',
                                                animations: {
                                                    enabled: false,
                                                }
                                            },
                                            legend: {
                                                show: false,
                                                position: 'top',
                                                offsetX: 0
                                            },
                                            xaxis: {
                                                tickAmount: 5,
                                                type: 'numeric',
                                                max: longestExp,
                                                min: 0,
                                                ticks: {
                                                    includeBounds: true,
                                                },
                                                labels: {
                                                    formatter: function (val) {
                                                        return parseFloat(val).toFixed(0)
                                                    },
                                                    offsetY: -6
                                                }
                                            },
                                            yaxis: {
                                                tickAmount: 0,
                                                labels: {
                                                    show: true,
                                                    offsetX: 13,
                                                }
                                            },
                                        },
                                    })

                                } //End of normal chart, now check ageChart
                                if (this.state.chartAge === "on" && (this.state.showAgeChart === "on" || this.state.showAgeChart === "adminOn")) {
                                    //1.3 Create jitter
                                    let chartWomen = ""
                                    let chartMen = ""
                                    if (this.state.chartPercent !== "on") {
                                        chartWomen = women.map(function (el) { return { y: el.totalSalary, x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                        chartMen = men.map(function (el) { return { y: el.totalSalary, x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                    } else {
                                        //If percent, take el.totalSalary / salaryTotal * 100
                                        let salaryTotal = groups[i][1].reduce((sum, cur) => sum + cur.totalSalary, 0)
                                        chartWomen = women.map(function (el) { return { y: ((el.totalSalary / salaryTotal) * 100).toFixed(1), x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                        chartMen = men.map(function (el) { return { y: ((el.totalSalary / salaryTotal) * 100).toFixed(1), x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                    }
                                    jitter(chartMen, chartWomen, 180)
                                    //1.4 fix max length of x axis
                                    let womenAge = women.map(person => person.age)
                                    let menAge = men.map(person => person.age)
                                    let allAge = womenAge.concat(menAge)
                                    let longestAge = Math.max(...allAge)
                                    if (longestAge < 10) longestAge = 10
                                    if (longestAge > 10 && longestAge < 16) longestAge = 16
                                    //1.5 Restructure data to fit apexchart (didnt work with x,y for some reason)
                                    let menForChart = []
                                    for (let i = 0; i < chartMen.length; i++) {
                                        menForChart.push([chartMen[i].x, chartMen[i].y])
                                    }
                                    let womenForChart = []
                                    for (let i = 0; i < chartWomen.length; i++) {
                                        womenForChart.push([chartWomen[i].x, chartWomen[i].y])
                                    }
                                    //1.6 Set state for chart
                                    this.setState({
                                        seriesAge: [{
                                            name: "Män",
                                            data: menForChart
                                        },
                                        {
                                            name: "Kvinnor",
                                            data: womenForChart
                                        }],
                                        optionsAge: {
                                            chart: {
                                                offsetX: 0,
                                                id: "chartIdAge",
                                                width: 350,
                                                type: 'scatter',
                                                animations: {
                                                    enabled: false,
                                                }
                                            },
                                            legend: {
                                                show: false,
                                                position: 'top',
                                                offsetX: 0
                                            },
                                            xaxis: {
                                                tickAmount: 5,
                                                type: 'numeric',
                                                max: longestAge,
                                                min: 0,
                                                ticks: {
                                                    includeBounds: true,
                                                },
                                                labels: {
                                                    formatter: function (val) {
                                                        return parseFloat(val).toFixed(0)
                                                    },
                                                    offsetY: -6
                                                }
                                            },
                                            yaxis: {
                                                tickAmount: 0,
                                                labels: {
                                                    show: true,
                                                    offsetX: 13,
                                                }
                                            },
                                        },
                                    })
                                }
                                //End of ageChart, now check extraChart 
                                if (addOnDetails === "on" && (addOnChart === "onlyExtra" || addOnChart === "salaryNoExtra")) {
                                    //If age chart is selected
                                    if (selectedChart === "allCharts" || selectedChart === "ageChart") {
                                        //1.3 Create jitter
                                        let chartWomen = ""
                                        let chartMen = ""
                                        let extraSalary = addOnChart === "onlyExtra" ? "addOn" : "salaryNoExtra"
                                        if (this.state.chartPercent !== "on") {
                                            chartWomen = women.map(function (el) { return { y: el[extraSalary], x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                            chartMen = men.map(function (el) { return { y: el[extraSalary], x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                        } else {
                                            //If percent, take el.totalSalary / salaryTotal * 100
                                            let salaryTotal = groups[i][1].reduce((sum, cur) => sum + cur[extraSalary], 0)
                                            chartWomen = women.map(function (el) { return { y: ((el[extraSalary] / salaryTotal) * 100).toFixed(1), x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                            chartMen = men.map(function (el) { return { y: ((el[extraSalary] / salaryTotal) * 100).toFixed(1), x: el.age, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                        }
                                        jitter(chartMen, chartWomen, 180)
                                        //1.4 fix max length of x axis
                                        let womenAge = women.map(person => person.age)
                                        let menAge = men.map(person => person.age)
                                        let allAge = womenAge.concat(menAge)
                                        let longestAge = Math.max(...allAge)
                                        if (longestAge < 10) longestAge = 10
                                        if (longestAge > 10 && longestAge < 16) longestAge = 16
                                        //1.5 Restructure data to fit apexchart (didnt work with x,y for some reason)
                                        let menForChart = []
                                        for (let i = 0; i < chartMen.length; i++) {
                                            menForChart.push([chartMen[i].x, chartMen[i].y])
                                        }
                                        let womenForChart = []
                                        for (let i = 0; i < chartWomen.length; i++) {
                                            womenForChart.push([chartWomen[i].x, chartWomen[i].y])
                                        }
                                        //1.6 Set state for chart
                                        this.setState({
                                            seriesExtraAge: [{
                                                name: "Män",
                                                data: menForChart
                                            },
                                            {
                                                name: "Kvinnor",
                                                data: womenForChart
                                            }],
                                            optionsExtraAge: {
                                                chart: {
                                                    offsetX: 0,
                                                    id: "chartIdExtraAge",
                                                    width: 350,
                                                    type: 'scatter',
                                                    animations: {
                                                        enabled: false,
                                                    }
                                                },
                                                legend: {
                                                    show: false,
                                                    position: 'top',
                                                    offsetX: 0
                                                },
                                                xaxis: {
                                                    tickAmount: 5,
                                                    type: 'numeric',
                                                    max: longestAge,
                                                    min: 0,
                                                    ticks: {
                                                        includeBounds: true,
                                                    },
                                                    labels: {
                                                        formatter: function (val) {
                                                            return parseFloat(val).toFixed(0)
                                                        },
                                                        offsetY: -6
                                                    }
                                                },
                                                yaxis: {
                                                    tickAmount: 0,
                                                    labels: {
                                                        show: true,
                                                        offsetX: 13,
                                                    }
                                                },
                                            },
                                        })
                                    }
                                    //If experience chart is selected
                                    if (selectedChart === "allCharts" || selectedChart === "expChart") {
                                        //1.3 Create jitter
                                        let chartWomen = ""
                                        let chartMen = ""
                                        let extraSalary = addOnChart === "onlyExtra" ? "addOn" : "salaryNoExtra"
                                        if (this.state.chartPercent !== "on") {
                                            chartWomen = women.map(function (el) { return { y: el[extraSalary], x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                            chartMen = men.map(function (el) { return { y: el[extraSalary], x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                        } else {
                                            //If percent, take el.totalSalary / salaryTotal * 100
                                            let salaryTotal = groups[i][1].reduce((sum, cur) => sum + cur[extraSalary], 0)
                                            chartWomen = women.map(function (el) { return { y: ((el[extraSalary] / salaryTotal) * 100).toFixed(1), x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering, } })
                                            chartMen = men.map(function (el) { return { y: ((el[extraSalary] / salaryTotal) * 100).toFixed(1), x: el.experience, expNoJitter: el.experience, positionTitle: el.positionTitle, gender: el.gender, groupNameArbVardering: el.groupNameArbVardering } })
                                        }
                                        jitter(chartMen, chartWomen, 180)
                                        //1.4 fix max length of x axis
                                        let womenExperiences = women.map(person => person.experience)
                                        let menExperiences = men.map(person => person.experience)
                                        let allExperiences = womenExperiences.concat(menExperiences)
                                        let longestExp = Math.max(...allExperiences)
                                        if (longestExp < 10) longestExp = 10
                                        if (longestExp > 10 && longestExp < 16) longestExp = 16
                                        //1.5 Restructure data to fit apexchart (didnt work with x,y for some reason)
                                        let menForChart = []
                                        for (let i = 0; i < chartMen.length; i++) {
                                            menForChart.push([chartMen[i].x, chartMen[i].y])
                                        }
                                        let womenForChart = []
                                        for (let i = 0; i < chartWomen.length; i++) {
                                            womenForChart.push([chartWomen[i].x, chartWomen[i].y])
                                        }
                                        //1.6 Set state for chart
                                        this.setState({
                                            seriesExtraExp: [{
                                                name: "Män",
                                                data: menForChart
                                            },
                                            {
                                                name: "Kvinnor",
                                                data: womenForChart
                                            }],
                                            optionsExtraExp: {
                                                chart: {
                                                    offsetX: 0,
                                                    id: "chartIdExtraExp",
                                                    width: 350,
                                                    type: 'scatter',
                                                    animations: {
                                                        enabled: false,
                                                    }
                                                },
                                                legend: {
                                                    show: false,
                                                    position: 'top',
                                                    offsetX: 0
                                                },
                                                xaxis: {
                                                    tickAmount: 5,
                                                    type: 'numeric',
                                                    max: longestExp,
                                                    min: 0,
                                                    ticks: {
                                                        includeBounds: true,
                                                    },
                                                    labels: {
                                                        formatter: function (val) {
                                                            return parseFloat(val).toFixed(0)
                                                        },
                                                        offsetY: -6
                                                    }
                                                },
                                                yaxis: {
                                                    tickAmount: 0,
                                                    labels: {
                                                        show: true,
                                                        offsetX: 13,
                                                    }
                                                },
                                            },
                                        })
                                    }
                                }
                                resolve();
                            });
                        }
                        await prepareDataForImage()
                        //1.7 Create image from chart to use in word

                        // -- ADD AWAIT FUNCTION AOURND IFS AS WELL MAYBE ALSO TRY AWAIT FOR GETDATA ITSELF---

                        if (this.state.chartExp === "on" || (this.state.showAgeChart === "off" || this.state.showAgeChart === "adminOff")) {
                            let chartImgCreate = await ApexCharts.exec("chartId", "dataURI").then(({ imgURI }) => {
                                let cleanURI = imgURI.split(',')[1]
                                chartImages.push(cleanURI)
                            })
                            //Set images to state to be used when creating pdf or word file
                            this.setState({ chartImages: chartImages })
                        }
                        if (this.state.chartAge === "on" && (this.state.showAgeChart === "on" || this.state.showAgeChart === "adminOn")) {
                            let chartImgCreateAge = await ApexCharts.exec("chartIdAge", "dataURI").then(({ imgURI }) => {
                                let cleanURI = imgURI.split(',')[1]
                                chartImagesAge.push(cleanURI)
                            })
                            //Set images to state to be used when creating pdf or word file
                            this.setState({ chartImagesAge: chartImagesAge })
                        }
                        //For extra charts
                        if (addOnDetails === "on" && (addOnChart === "onlyExtra" || addOnChart === "salaryNoExtra") && (this.state.chartExp === "on" || (this.state.showAgeChart === "off" || this.state.showAgeChart === "adminOff"))) {
                            let chartImgCreateExtraExp = await ApexCharts.exec("chartIdExtraExp", "dataURI").then(({ imgURI }) => {
                                let cleanURI = imgURI.split(',')[1]
                                chartImagesExtraExp.push(cleanURI)
                            })
                            //Set images to state to be used when creating pdf or word file
                            this.setState({ chartImagesExtraExp: chartImagesExtraExp })
                        }
                        if (addOnDetails === "on" && (addOnChart === "onlyExtra" || addOnChart === "salaryNoExtra") && this.state.chartAge === "on" && (this.state.showAgeChart === "on" || this.state.showAgeChart === "adminOn")) {
                            let chartImgCreateExtraAge = await ApexCharts.exec("chartIdExtraAge", "dataURI").then(({ imgURI }) => {
                                let cleanURI = imgURI.split(',')[1]
                                chartImagesExtraAge.push(cleanURI)
                            })
                            //Set images to state to be used when creating pdf or word file
                            this.setState({ chartImagesExtraAge: chartImagesExtraAge })
                        }
                    }
                }; // End of if statement for showDocChart

                //Set state to finished so we know that all images are loaded before creatind documentation
                this.setState({ allDataLoaded: true })
            } catch (error) {
                //Do nothing
            }
        }
        loadFieldData();
        /*  const loadFinishedFieldData = async () => {
          await loadFieldData();
          this.setState({ allDataLoaded: true })
            //Set state to finished so we know that all images are loaded before creatind documentation
                  //this.setState({ allDataLoaded: true })
          }
          loadFinishedFieldData()*/
    };

    handleClick = (event) => {
        this.setState({
            settingsButton: event.target.id
        })
    }

    //show and hide selected divs
    toggleDiv = (event) => {
        if (this.state.toggleDiv) {
            this.setState({
                toggleDiv: false
            })
        }
        if (!this.state.toggleDiv) {
            this.setState({
                toggleDiv: true
            })
        }
    }

    //Update state when textfield changes
    changeSamverkanText = (event) => {
        this.setState({ samverkanText: event.target.value });
        if (this.state.buttonSamverkanStatus === "Sparat") { this.setState({ buttonSamverkanStatus: "Spara" }) }
    }
    changePraxisText = (event) => {
        this.setState({ praxisText: event.target.value });
        if (this.state.buttonPraxis === "Sparat") { this.setState({ buttonPraxis: "Spara" }) }
    }
    changeMedverkandeText = (event) => {
        this.setState({ medverkandeText: event.target.value });
        if (this.state.buttonParticipants === "Sparat") { this.setState({ buttonParticipants: "Spara" }) }
    }
    changeFritext = (event) => {
        this.setState({ fritext: event.target.value });
        if (this.state.buttonfreetext === "Sparat") { this.setState({ buttonfreetext: "Spara" }) }
    }
    changeFritextTitel = (event) => {
        this.setState({ fritextTitel: event.target.value });
        if (this.state.buttonfreetext === "Sparat") { this.setState({ buttonfreetext: "Spara" }) }
    }

    //Warning if text is not added and trying to create pdf
    clickPDFWarning = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        alert(language === "sv" ? "Det saknas information. Du måste fylla i fälten för Samverkan, Lönebestämmelser & Praxis samt Medverkande." : lang[language].uploadLogo)
    }

    //Upload logo function
    upload = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const file = event.target.files[0]
        const data = new FormData()
        data.append('file', file)
        //data.append('user', 'hubot')

        fetchAuthUpload('api/saveLogo', 'POST', data)
            .then(
                response => response.json()
            ).then(
                message => {
                    // console.log(message)
                    if (message === 'Invalid fileType') {
                        alert(language === "sv" ? 'Felaktigt format. Ladda upp som .jpg, .jpeg eller .png' : lang[language].wrongFormat)
                        this.setState({ logo: "", temporaryLogo: "" })
                    }
                    if (message === 'File is too Large') {
                        alert(language === "sv" ? 'För stor fil. Filen kan max vara 1mb' : lang[language].maxFileSize)
                        this.setState({ logo: "", temporaryLogo: "" })
                    }
                    if (message === 'No files were uploaded') {
                        console.log('Fileupload aborted')
                        this.setState({ logo: "", temporaryLogo: "" })
                    }
                    if (message === 'Error occurred') {
                        alert(language === "sv" ? 'Ett problem uppstod. Prova att ladda upp logotypen igen.' : lang[language].logoError)
                        this.setState({ logo: "", temporaryLogo: "" })
                    }
                    if (message === 'Logo uploaded') {
                        this.setState({ logo: file, temporaryLogo: file, })
                    }
                }
            );
    };

    //send logo to server
    fileToState = event => {
        //console.log(event.target.files[0]) //File to send
        if (event.target.files[0]) {
            this.setState({ logo: event.target.files[0], temporaryLogo: event.target.files[0], })
            this.upload(event.target.files[0]) //Call upload function above. Uploads file to server
        }
    }

    //save Samverkan to server
    saveSamverkan = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            let response = await fetchAuth(`/api/saveSamverkan`, 'POST', JSON.stringify({ data: this.state.samverkanText }));
            let data = await response.json();
            // console.log(data) //Logs response message
            data === "Saved" ? this.setState({ buttonSamverkanStatus: "Sparat" }) : alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) //For save button status
        };
        saveData();
    }

    //save Praxis to server
    savePraxis = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            let response = await fetchAuth(`/api/savePraxis`, 'POST', JSON.stringify({ data: this.state.praxisText }));
            let data = await response.json();
            // console.log(data)
            data === "Saved" ? this.setState({ buttonPraxis: "Sparat" }) : alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave)//For save button status
        };
        saveData();
    }

    //save Participants to server
    saveParticipants = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            let response = await fetchAuth(`/api/saveParticipants`, 'POST', JSON.stringify({ data: this.state.medverkandeText }));
            let data = await response.json();
            //  console.log(data)
            data === "Saved" ? this.setState({ buttonParticipants: "Sparat" }) : alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave)//For save button status
        };
        saveData();
    }

    //save Freetext to server
    saveFreetext = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            let response = await fetchAuth(`/api/saveFreetext`, 'POST', JSON.stringify({ data: [this.state.fritextTitel, this.state.fritext] }));
            let data = await response.json();
            //console.log(data)
            data === "Saved" ? this.setState({ buttonfreetext: "Sparat" }) : alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) //For save button status
        };
        saveData();
    }

    sliderValue = (event) => {
        let width = Number(event.target.value)
        this.setState({ logoWidth: width });
        if (this.state.buttonLogoSize === "Sparat") {
            this.setState({ buttonLogoSize: "Spara" })
        }
    }

    saveLogoWidth = () => {
        let width = this.state.logoWidth
        const saveData = async () => {
            let response = await fetchAuth(`/api/saveLogoWidth`, 'POST', JSON.stringify({ width: width }));
            let data = await response.json();
            this.setState({ buttonLogoSize: "Sparat" })
        };
        saveData();
    }

    createWord = () => {
        //Create word document
        if (!this.state.hideSalaries) {
            wordDocument(
                this.state.logoWidth,
                this.state.samverkanText,
                this.state.praxisText,
                this.state.medverkandeText,
                this.state.fritext,
                this.state.fritextTitel,
                this.state.logo,
                this.state.temporaryLogo,
                this.state.totStats,
                this.state.vikt,
                this.state.persData,
                this.state.comments,
                this.state.oldComments,
                this.state.legalComp,
                this.state.theYear,
                this.state.nrOfEqualentGroups,
                this.state.chartImages,
                this.state.chartPercent,
                this.state.chartImagesAge,
                this.state.chartAge,
                this.state.chartExp,
                this.state.showAgeChart,
                this.state.chartImagesExtraExp,
                this.state.chartImagesExtraAge,
                this.state.addOnChart,
                this.state.addOnDetails,
                this.state.whatEvaluation,
                this.state.valuationName,
                this.state.editedFactors,
                this.state.originalFactors,
                this.state.whatLevelDesc
            )
        } else {
            wordDocumentDefault(
                this.state.logoWidth,
                this.state.samverkanText,
                this.state.praxisText,
                this.state.medverkandeText,
                this.state.fritext,
                this.state.fritextTitel,
                this.state.logo,
                this.state.temporaryLogo,
                this.state.totStats,
                this.state.vikt,
                this.state.persData,
                this.state.comments,
                this.state.oldComments,
                this.state.legalComp,
                this.state.theYear,
                this.state.nrOfEqualentGroups,
                this.state.chartImages,
                this.state.chartPercent,
                this.state.chartImagesAge,
                this.state.chartAge,
                this.state.chartExp,
                this.state.showAgeChart,
                this.state.chartImagesExtraExp,
                this.state.chartImagesExtraAge,
                this.state.addOnChart,
                this.state.addOnDetails,
                this.state.whatEvaluation,
                this.state.valuationName,
                this.state.editedFactors,
                this.state.originalFactors,
                this.state.whatLevelDesc
            )
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let showWhat = ''
        if (this.state.settingsButton === "logga") {
            showWhat =
                <>
                    <img
                        alt=""
                        src={this.state.logo === "" && this.state.temporaryLogo === "" ? uploadIcon : this.state.temporaryLogo !== "" ? URL.createObjectURL(this.state.temporaryLogo) : this.state.logo}
                        className={this.state.logo === "" && this.state.temporaryLogo === "" ? "logoUpload-default" : "logoUpload-userLogo"} />
                    <label htmlFor="files" className="upload-button logoUploadButton" style={{ fontWeight: 600, width: 150, paddingLeft: 10, paddingRight: 10, letterSpacing: -0.1 }}>{language === "sv" ? "Ladda upp logga" : lang[language].uploadLogo}</label>
                    <input name="logo" id="files" style={{ display: "none" }} type="file" onChange={this.upload} />

                    <div style={this.state.logo === "" && this.state.temporaryLogo === "" ? { display: 'none' } : {}}>
                        <div className="slider-container">
                            <span style={{ display: 'block', marginBottom: 40, color: '#333333', fontWeight: 600 }}>{language === "sv" ? "Ändra storlek" : lang[language].changeSize}</span>
                            <input type="range" min="100" max="400" value={this.state.logoWidth} step="5" class="slider" id="myRange" onChange={this.sliderValue} />
                            <div style={{ display: 'inline-block', marginLeft: 20, width: 50, fontWeight: 600 }} className={this.state.buttonLogoSize === "Spara" ? "upload-button saveButton" : "upload-button saveButton alreadySaved"} onClick={this.state.buttonLogoSize === "Spara" ? this.saveLogoWidth : ""}>{language === "sv" ? this.state.buttonLogoSize : (this.state.buttonLogoSize === "Spara" ? lang[language].save : lang[language].saved)}</div>
                        </div>
                        <div style={{ width: 760, background: 'white', height: 200, border: '1px solid lightgrey', margin: 'auto', boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 4px 0px' }}>
                            <img src={this.state.logo === "" && this.state.temporaryLogo === "" ? logo : this.state.temporaryLogo !== "" ? URL.createObjectURL(this.state.temporaryLogo) : this.state.logo}
                                style={{ maxWidth: this.state.logoWidth, marginTop: 30, float: 'left', marginLeft: 20 }} />
                        </div>
                    </div>
                </>
        }

        if (this.state.settingsButton === "samverkan") {
            showWhat =
                <>
                    <div className="whatExplain">
                        <p className="whatExplainTitle">{language === "sv" ? "Beskriv hur samverkan skett med arbetstagarorganisationen" : lang[language].cooperationInfo}</p>
                        <p style={{ margin: "Auto", width: "95%" }}>{language === "sv" ? "Enligt lag ska lönekartläggning ske i samverkan med arbetstagarna, oavsett om ni är fackligt anslutna eller ej." : lang[language].cooperationInfoDetails}</p>
                        <span onClick={this.toggleDiv} style={{ margin: "Auto", cursor: "pointer", paddingTop: 15, display: "inline-block", paddingRight: 6 }}>{this.state.toggleDiv ? (language === "sv" ? "Visa mindre" : lang[language].showLess) : (language === "sv" ? "Visa mer" : lang[language].showMore)} </span>{this.state.toggleDiv ? <img alt="" src={up} style={{ width: 10 }} /> : <img alt="" src={down} style={{ width: 10 }} />}
                        <div className={this.state.toggleDiv ? "toggleDiv" : "toggleDiv toggleHide"}>
                            <p>{language === "sv" ? "Om ni är fackligt anslutna så är det facket som samverkan sker med, om inte kan en grupp arbetstagare företräda arbetstagarna." : lang[language].cooperationInfoTwo}</p>
                            <b>{language === "sv" ? "Exempel på innehåll:" : lang[language].cooperationExampleTitle}</b>
                            <ul style={{ paddingBottom: 15, paddingBottom: 15, listStyleType: "disc", paddingLeft: 20, paddingTop: 10 }}>
                                <li>{language === "sv" ? `Inled med "Kartläggningen och analysen har genomförts i samverkan mellan arbetsgivare och arbetstagare"` : lang[language].cooperationInfoThree}</li>
                                <li>{language === "sv" ? "Ange hur samverkan gått till. Hur inleddes arbetet, vilka har medverkat, hur har facket medverkat etc." : lang[language].cooperationInfoFour}</li>
                            </ul>
                            {language === "sv" ? " Notera att kollektivavtal kan innehålla information om hur samverkan ska gå till." : lang[language].cooperationNote} 
                        </div>
                    </div>
                    <textarea id="samverkanText" className="whatTextField" placeholder={language === "sv" ? "Ange hur er samverkan gått till" : lang[language].cooperationDescribe} value={this.state.samverkanText} onChange={this.changeSamverkanText} />
                    <div className="saveContainer">
                        <div className={this.state.buttonSamverkanStatus === "Spara" ? "upload-button saveButton" : "upload-button saveButton alreadySaved"} onClick={this.state.buttonSamverkanStatus === "Spara" ? this.saveSamverkan : ""}>{language === "sv" ? this.state.buttonSamverkanStatus : (this.state.buttonSamverkanStatus === "Spara" ? lang[language].save : lang[language].saved)}</div>
                    </div>
                </>
        }

        if (this.state.settingsButton === "praxis") {
            showWhat =
                <>
                    <div className="whatExplain">
                        <p className="whatExplainTitle">{language === "sv" ? "Analysera Lönebestämmelser & Praxis" : lang[language].payPolicy}</p>
                        <p style={{ margin: "Auto", width: "95%" }}>
                        {language === "sv" ? "Kartlägg och analysera era bestämmelser och praxis för löner och förmåner." : lang[language].payPolicyInfo}</p>
                        <span onClick={this.toggleDiv} style={{ margin: "Auto", cursor: "pointer", paddingTop: 15, display: "inline-block", paddingRight: 6 }}>{this.state.toggleDiv ? (language === "sv" ? "Visa mindre" : lang[language].showLess) : (language === "sv" ? "Visa mer" : lang[language].showMore)} </span>{this.state.toggleDiv ? <img alt="" src={up} style={{ width: 10 }} /> : <img alt="" src={down} style={{ width: 10 }} />}
                        <div className={this.state.toggleDiv ? "toggleDiv" : "toggleDiv toggleHide"}>
                            {/* <p>Kartlägg och analysera lönesättning </p> */}
                            <b>{language === "sv" ? "Exempel på innehåll:" : lang[language].cooperationExampleTitle}</b>
                            <ul style={{ paddingBottom: 15, paddingBottom: 15, listStyleType: "disc", paddingLeft: 20, paddingTop: 10 }}>
                                <li>{language === "sv" ? "Beskriv er lönepolitik. Har ni någon lönepolicy? I korta drag vad består den av?" : lang[language].payPInfo}</li>
                                <li>{language === "sv" ? "Använder ni individuell lönesättning?" : lang[language].payPInfoTwo}</li>
                                <li>{language === "sv" ? "Vad baseras lönesättningen på? Hur säkerställer ni att den inte könsdiskriminerar?" : lang[language].payPInfoThree}</li>
                                <li>{language === "sv" ? "Om ni vid analysen av bestämmelser och praxis hittar sådant som kan vara diskriminerande  skall detta tas med här tillsammans med åtgärd." : lang[language].payPInfoFour}</li>
                            </ul>
                        </div>
                    </div>
                    <textarea id="praxisText" className="whatTextField" placeholder={language === "sv" ? "Beskriv er analys av lönebestämmelser & praxis" : lang[language].payPDesc} value={this.state.praxisText} onChange={this.changePraxisText} />
                    <div className="saveContainer">
                        <div className={this.state.buttonPraxis === "Spara" ? "upload-button saveButton" : "upload-button saveButton alreadySaved"} onClick={this.state.buttonPraxis === "Spara" ? this.savePraxis : ""}>{language === "sv" ? this.state.buttonPraxis : (this.state.buttonPraxis === "Spara" ? lang[language].save : lang[language].saved)}</div>
                    </div>
                </>
        }

        if (this.state.settingsButton === "medverkande") {
            showWhat =
                <>
                    <div className="whatExplain">
                        <p className="whatExplainTitle">{language === "sv" ? "Ange vilka som medverkat vid lönekartläggningen" : lang[language].whoAttended}</p>
                        <p style={{ margin: "Auto", width: "95%" }}>{language === "sv" ? "Ange för- och efternamn samt befattning." : lang[language].whoAttendedInfo}</p>
                    </div>
                    <textarea id="medverkandeText" className="whatTextField" placeholder={language === "sv" ? "Ange vilka som har varit med och genomfört lönekartläggningen" : lang[language].whoAttended} value={this.state.medverkandeText} onChange={this.changeMedverkandeText} />
                    <div className="saveContainer">
                        <div className={this.state.buttonParticipants === "Spara" ? "upload-button saveButton" : "upload-button saveButton alreadySaved"} onClick={this.state.buttonParticipants === "Spara" ? this.saveParticipants : ""}>{language === "sv" ? this.state.buttonParticipants : (this.state.buttonParticipants === "Spara" ? lang[language].save : lang[language].saved)}</div>
                    </div>
                </>
        }

        if (this.state.settingsButton === "fritext") {
            showWhat =
                <>
                    <div className="whatExplain">
                        <p className="whatExplainTitle">{language === "sv" ? "Lägg till övrig information" : lang[language].additionalInfo}</p>
                        <p style={{ margin: "Auto", width: "95%" }}>{language === "sv" ? "Om ni vill poängtera eller lägga till något övrigt i rapporten anger ni det här. Detta fält är frivilligt och tas ej med i rapporten om ni inte anger något." : lang[language].additionalInfoDetails}</p>
                    </div>
                    <input id="fritextTitel" type="text" placeholder={language === "sv" ? "Titel" : lang[language].title} className="inputWhat" value={this.state.fritextTitel} onChange={this.changeFritextTitel} />
                    <textarea id="fritext" className="whatTextField" placeholder={language === "sv" ? "Ange övrig info" : lang[language].additionalInfo} value={this.state.fritext} onChange={this.changeFritext} />
                    <div className="saveContainer">
                        <div className={this.state.buttonfreetext === "Spara" ? "upload-button saveButton" : "upload-button saveButton alreadySaved"} onClick={this.state.buttonfreetext === "Spara" ? this.saveFreetext : ""}>{language === "sv" ? this.state.buttonfreetext : (this.state.buttonfreetext === "Spara" ? lang[language].save : lang[language].saved)}</div>
                    </div>
                </>
        }
        return (
            <>
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="dokumentera" />
                    { /* mini page */}
                    <div className="miniPage">
                        <img
                            alt=""
                            src={this.state.logo === "" && this.state.temporaryLogo === "" ? logo : this.state.temporaryLogo !== "" ? URL.createObjectURL(this.state.temporaryLogo) : this.state.logo}   //<--- remove the last URL if error
                            className="miniLogo"
                            style={{ maxWidth: (190 / (800 / this.state.logoWidth)) }}
                        />
                        <span className="miniTitle">{language === "sv" ? "Lönekartläggning" : lang[language].payE}</span>
                        <span className="miniYear">{this.state.theYear}</span>
                        <div className="miniPage miniPage2"></div>
                        <div className="miniPage miniPage3"></div>
                    </div>

                    { /* show skapa PDF button */}
                    {this.state.samverkanText === "" || this.state.praxisText === "" || this.state.medverkandeText === "" ?
                        <a className="upload-button button-inactive" onClick={this.clickPDFWarning} style={{ height: 15, lineHeight: '15px', marginBottom: 30, width: 105 }}>{language === "sv" ? "Skapa PDF" : lang[language].createPDF}</a> :
                        !this.state.allDataLoaded ? <a className="upload-button button-inactive" style={{ height: 15, lineHeight: '15px', marginBottom: 30, width: 200, paddingLeft: 0, paddingRight: 0 }}><img style={{ width: 28, marginTop: -7, marginRight: 8 }} src={loaderImg} /><span style={{ verticalAlign: "top" }}>{language === "sv" ? "Förbereder..." : lang[language].preparing}</span></a> :
                            <ReactToPrint
                                trigger={() => <a className="upload-button" href="#" style={{ height: 15, lineHeight: '15px', marginBottom: 30, width: 105 }}>{language === "sv" ? "Skapa PDF" : lang[language].createPDF}</a>}
                                content={() => this.componentRef}
                            />}
                    { /* Word document */}
                    <div style={{ margin: "auto", padding: 15, paddingTop: 20, height: 10, width: 255, width: "fit-content", borderTop: "1px solid #e8e8e8", marginTop: -10, paddingBottom: 60 }}>
                        {this.state.samverkanText === "" || this.state.praxisText === "" || this.state.medverkandeText === "" ?
                            <div style={{ opacity: 0.45, display: "inline-block" }}> <img src={word} style={{ width: 22, marginTop: -10, opacity: 0.8, filter: "grayscale(40%)", display: "inline-block" }} />
                                <div style={{
                                    border: "none",
                                    textDecoration: "underline",
                                    background: "transparent",
                                    verticalAlign: "top",
                                    cursor: "pointer",
                                    fontSize: 14,
                                    marginBottom: 40,
                                    fontFamily: 'Inter',
                                    marginTop: -4.5,
                                    color: "#333333",
                                    display: "inline-block",
                                    marginLeft: 5
                                }}
                                    onClick={this.clickPDFWarning}>{language === "sv" ? "Skapa Wordfil" : lang[language].createWord}
                                </div>
                            </div>
                            : !this.state.allDataLoaded ?
                                <div style={{ display: "inline-block" }}>
                                    <div style={{
                                        border: "none",
                                        textDecoration: "underline",
                                        background: "transparent",
                                        verticalAlign: "top",
                                        fontSize: 14,
                                        marginBottom: 40,
                                        fontFamily: 'Inter',
                                        cursor: "default",
                                        marginTop: -4.5,
                                        color: "transparent",
                                        display: "inline-block",
                                        marginLeft: 5
                                    }}
                                    >{language === "sv" ? "Förbereder..." : lang[language].preparing}</div>
                                </div>
                                :
                                <div style={{ display: "inline-block" }}>
                                    <img src={word} style={{ width: 22, marginTop: -10, opacity: 0.8, filter: "grayscale(40%)", display: "inline-block" }} />
                                    <div style={{
                                        border: "none",
                                        textDecoration: "underline",
                                        background: "transparent",
                                        verticalAlign: "top",
                                        cursor: "pointer",
                                        fontSize: 14,
                                        marginBottom: 40,
                                        fontFamily: 'Inter',
                                        marginTop: -4.5,
                                        color: "#333333",
                                        display: "inline-block",
                                        marginLeft: 5
                                    }}
                                        onClick={this.createWord}
                                    >{language === "sv" ? "Skapa Wordfil" : lang[language].createWord}</div>
                                </div>}
                        {/*<NavLink exact to="/settings">*/}
                        {this.state.allDataLoaded && <NavLink
                            exact
                            to={{
                                pathname: '/settings',
                                state: { from: { component: 'Dokumentera' } },
                            }}
                        >
                            <div style={{ opacity: this.state.samverkanText === "" || this.state.praxisText === "" || this.state.medverkandeText === "" ? 0.45 : 1, display: "inline-block", fontSize: 14, verticalAlign: "top", marginTop: -4.5, marginLeft: 30, color: "#333333", textDecoration: "underline", cursor: "pointer" }}><img src={settings} style={{ filter: "invert(1)", opacity: 0.4, width: 17.5, verticalAlign: "top", marginRight: 5 }} />{language === "sv" ? "Inställningar" : lang[language].settings}</div>
                        </NavLink>}
                    </div>
                    { /* add info section */}
                    <div>
                        <div id="logga" onClick={this.handleClick} className={this.state.settingsButton === "logga" ? "selectWhat whatActive" : "selectWhat"}>{language === "sv" ? "Logga" : lang[language].logo}</div>
                        <div id="samverkan" onClick={this.handleClick} className={this.state.settingsButton === "samverkan" ? "selectWhat whatActive" : "selectWhat"}>{language === "sv" ? "Samverkan" : lang[language].coop}</div>
                        <div id="praxis" onClick={this.handleClick} className={this.state.settingsButton === "praxis" ? "selectWhat whatActive" : "selectWhat"}>{language === "sv" ? "Lönebestämmelser & praxis" : lang[language].payPol}</div>
                        <div id="medverkande" onClick={this.handleClick} className={this.state.settingsButton === "medverkande" ? "selectWhat whatActive" : "selectWhat"}>{language === "sv" ? "Medverkande" : lang[language].participants}</div>
                        <div id="fritext" onClick={this.handleClick} className={this.state.settingsButton === "fritext" ? "selectWhat whatActive" : "selectWhat"}>{language === "sv" ? "Fritext" : lang[language].freeText}</div>
                    </div>

                    <div className="whatContainer">
                        {showWhat}
                    </div>

                    { /* large page */}
                    <div className="printContainer"> {/* Add "display: none" to this div to hide componenttoprnt but still enable it to print */}
                        {this.state.allDataLoaded && <ComponentToPrint
                            logoWidth={this.state.logoWidth}
                            ref={el => (this.componentRef = el)}
                            samverkanText={this.state.samverkanText}
                            praxisText={this.state.praxisText}
                            medverkandeText={this.state.medverkandeText}
                            fritext={this.state.fritext}
                            fritextTitel={this.state.fritextTitel}
                            logo={this.state.logo}
                            temporaryLogo={this.state.temporaryLogo}
                            totStats={this.state.totStats}
                            vikt={this.state.vikt}
                            //uniqueGroups={this.state.uniqueGroups}
                            persData={this.state.persData}
                            comments={this.state.comments}
                            oldComments={this.state.oldComments}
                            legalComp={this.state.legalComp}
                            whatEvaluation={this.state.whatEvaluation}
                            valuationName={this.state.valuationName}
                            theYear={this.state.theYear}
                            nrOfEqualentGroups={this.state.nrOfEqualentGroups}
                            chartImages={this.state.chartImages}
                            hideSalaries={this.state.hideSalaries}
                            chartPercent={this.state.chartPercent}
                            chartImagesAge={this.state.chartImagesAge}
                            chartAge={this.state.chartAge}
                            chartExp={this.state.chartExp}
                            showAgeChart={this.state.showAgeChart}
                            //For extra
                            chartImagesExtraExp={this.state.chartImagesExtraExp}
                            chartImagesExtraAge={this.state.chartImagesExtraAge}
                            addOnChart={this.state.addOnChart}
                            addOnDetails={this.state.addOnDetails}
                            editedFactors={this.state.editedFactors}
                            originalFactors={this.state.originalFactors}
                            whatLevelDesc={this.state.whatLevelDesc}
                        />}
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="scatter"
                            width="600"
                        />
                        <Chart
                            options={this.state.optionsAge}
                            series={this.state.seriesAge}
                            type="scatter"
                            width="600"
                        />
                        <Chart
                            options={this.state.optionsExtraExp}
                            series={this.state.seriesExtraExp}
                            type="scatter"
                            width="600"
                        />
                        <Chart
                            options={this.state.optionsExtraAge}
                            series={this.state.seriesExtraAge}
                            type="scatter"
                            width="600"
                        />
                    </div>
                </div>
            </>
        )
    }
}