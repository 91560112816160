import React from "react";
import percentile from "percentile";
import lang from '../../../Language/language.js';

export class ResultatLika extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        /*
             RESULTAT AV LIKA ARBETE
             - Loopar persdata för varje lika grupp och sammanställer:
             - - Vilka befattningar som ingår i gruppen
             - - Totalt antal anställda
             - - Antal män och kvinnor
             - - Total medellön
             - - Medellön för män resp kvinnor samt differensen för kvinnor
            */
        let persData = this.props.persData
        let comments = this.props.comments
        //Divide persData by equal Groups
        let dividebyGroup = persData.reduce((result, value) => {
            result[value.groupNameArbVardering] = result[value.groupNameArbVardering] || [];
            result[value.groupNameArbVardering].push({ name: value.fullName, position: value.positionTitle, gender: value.gender, salary: value.totalSalary, addOn: value.addOn });
            return result;
        }, {});
        let groups = Object.entries(dividebyGroup)
        //Sort groups in alphabetic order
        groups.sort((a, b) => a[0][0].localeCompare(b[0][0]))

        //Do calculations
        let arr = [];
        for (let i = 0; i < groups.length; i++) {
            let egroup = groups[i][0]
            let totEmp = groups[i][1].length
            //let positions = groups[i][1].map(pos => pos.position).filter((value, index, self) => self.indexOf(value) === index) //another way of doing it, but probably slower
            let positions = [...new Set(groups[i][1].map(item => item.position))] //a faster way
            let women = [...new Set(groups[i][1].filter(item => item.gender === 'woman'))]
            let nrWomen = women.length
            let men = [...new Set(groups[i][1].filter(item => item.gender === 'man'))]
            let nrMen = men.length
            let avgSalary = (groups[i][1].reduce((a, { salary }) => a + salary, 0)) / totEmp
            let avgSalMen = (men.reduce((a, { salary }) => a + salary, 0)) / nrMen
            let avgSalWom = (women.reduce((a, { salary }) => a + salary, 0)) / nrWomen

            //For no extra
            let avgSalTotNoExtra = ((groups[i][1].reduce((sum, cur) => sum + (cur.salary - cur.addOn), 0)) / totEmp).toFixed(0)
            let avgSalMenNoExtra = ((men.reduce((sum, cur) => sum + (cur.salary - cur.addOn), 0)) / nrMen).toFixed(0)
            let avgSalWomNoExtra = ((women.reduce((sum, cur) => sum + (cur.salary - cur.addOn), 0)) / nrWomen).toFixed(0)
            let diffWomenNoExtra = avgSalWomNoExtra - avgSalMenNoExtra
            //For only extra
            let avgSalTotOnlyExtra = ((groups[i][1].reduce((sum, cur) => sum + cur.addOn, 0)) / totEmp).toFixed(0)
            let avgSalMenOnlyExtra = ((men.reduce((sum, cur) => sum + cur.addOn, 0)) / nrMen).toFixed(0)
            let avgSalWomenOnlyExtra = ((women.reduce((sum, cur) => sum + cur.addOn, 0)) / nrWomen).toFixed(0)

            let diffWom = avgSalWom - avgSalMen
            let diffWomenOnlyExtra = avgSalWomenOnlyExtra - avgSalMenOnlyExtra
            //make sure 0 is displayed instead of "NaN"
            avgSalWom = avgSalWom ? avgSalWom : 0;
            avgSalMen = avgSalMen ? avgSalMen : 0;
            diffWom = diffWom ? diffWom : 0;
            //For no extra
            avgSalWomNoExtra = avgSalWomNoExtra ? avgSalWomNoExtra : 0;
            if (avgSalWomNoExtra === "NaN") { avgSalWomNoExtra = 0 }
            avgSalMenNoExtra = avgSalMenNoExtra ? avgSalMenNoExtra : 0;
            if (avgSalMenNoExtra === "NaN") { avgSalMenNoExtra = 0 }
            diffWomenNoExtra = diffWomenNoExtra ? diffWomenNoExtra : 0;
            //For only Extra
            avgSalWomenOnlyExtra = avgSalWomenOnlyExtra ? avgSalWomenOnlyExtra : 0;
            if (avgSalWomenOnlyExtra === "NaN") { avgSalWomenOnlyExtra = 0 }
            avgSalMenOnlyExtra = avgSalMenOnlyExtra ? avgSalMenOnlyExtra : 0;
            if (avgSalMenOnlyExtra === "NaN") { avgSalMenOnlyExtra = 0 }
            diffWomenOnlyExtra = diffWomenOnlyExtra ? diffWomenOnlyExtra : 0;

            let diffWomenPercent = ""
            let diffSalWomenPercentNoExtra = ""
            let diffSalWomenPercentOnlyExtra = ""

            if (avgSalWom && avgSalMen) {
              //  diffWomenPercent = (Math.round((100 - ((avgSalMen / avgSalWom) * 100)) * 10) / 10) + "%"
                 diffWomenPercent = ((diffWom / avgSalMen) * 100).toFixed(1) + "%"
              //  diffSalWomenPercentNoExtra = (Math.round((100 - ((avgSalMenNoExtra / avgSalWomNoExtra) * 100)) * 10) / 10) + "%"
               // diffSalWomenPercentOnlyExtra = (Math.round((100 - ((avgSalMenOnlyExtra / avgSalWomenOnlyExtra) * 100)) * 10) / 10) + "%"
                diffSalWomenPercentNoExtra = ((diffWomenNoExtra / avgSalMenNoExtra) * 100).toFixed(1) + "%"
                diffSalWomenPercentOnlyExtra = ((diffWomenOnlyExtra / avgSalMenOnlyExtra) * 100).toFixed(1) + "%"
            }
            if (nrWomen < 1 || nrMen < 1) {
                diffWomenPercent = ""
                diffSalWomenPercentNoExtra = ""
                diffSalWomenPercentOnlyExtra = ""
            }
            //get group comments
            let thisGroupComments = []
            for (let i = 0; i < comments.length; i++) {
                if (comments[i].equalGroup === egroup && comments[i].analysis === "lika") {
                    thisGroupComments.push({
                        cost: comments[i].cost,
                        deadline: comments[i].deadline,
                        notable: comments[i].notable,
                        reason: comments[i].reason,
                        todo: comments[i].todo
                    });
                }
            }
            //Spridning
            //Get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesMen = []
            for (let i = 0; i < men.length; i++) { getOnlySalariesMen.push(men[i].salary) }
            let lowestSalaryMen = Math.min(...getOnlySalariesMen)
            let highestSalaryMen = Math.max(...getOnlySalariesMen)
            let spridningskvotMen = Math.round((highestSalaryMen / lowestSalaryMen) * 10) / 10

            let getOnlySalariesWomen = []
            for (let i = 0; i < women.length; i++) { getOnlySalariesWomen.push(women[i].salary) }
            let lowestSalaryWomen = Math.min(...getOnlySalariesWomen)
            let highestSalaryWomen = Math.max(...getOnlySalariesWomen)
            let spridningskvotWomen = Math.round((highestSalaryWomen / lowestSalaryWomen) * 10) / 10
            //let spridningDiff = Math.round((spridningskvotMen - spridningskvotWomen) * 10) / 10

            //Calculate 10 and 90 percentiles if needed (if more than 20 employees)
            //let moreThan20 = ""
            let moreMen10 = "-"
            let moreMen90 = "-"
            //let moreMenKvot = "-"
            let moreWomen10 = "-"
            let moreWomen90 = "-"
            //let moreWomenKvot = "-"

            if (nrMen > 19 || nrWomen > 19) {
                //If men bigger but not women
                if (nrMen > 19 && nrWomen < 20) {
                    let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                    moreMen10 = percentile(10, menSalariesInOrder)
                    moreMen90 = percentile(90, menSalariesInOrder)
                    spridningskvotMen = Math.round((moreMen90 / moreMen10) * 10) / 10
                    // moreThan20 = "men"
                }
                //If women bigger but not men
                if (nrWomen > 19 && nrMen < 20) {
                    let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                    moreWomen10 = percentile(10, womenSalariesInOrder)
                    moreWomen90 = percentile(90, womenSalariesInOrder)
                    spridningskvotWomen = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                    //moreThan20 = "women"
                }
                //If both bigger
                if (nrWomen > 19 && nrMen > 19) {
                    let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                    moreMen10 = percentile(10, menSalariesInOrder)
                    moreMen90 = percentile(90, menSalariesInOrder)
                    spridningskvotMen = Math.round((moreMen90 / moreMen10) * 10) / 10
                    let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                    moreWomen10 = percentile(10, womenSalariesInOrder)
                    moreWomen90 = percentile(90, womenSalariesInOrder)
                    spridningskvotWomen = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                    //moreThan20 = "both"
                }
            }
            //Make sure spridningskvot is not "NaN"
            spridningskvotMen = spridningskvotMen ? spridningskvotMen : "-";
            spridningskvotWomen = spridningskvotWomen ? spridningskvotWomen : "-";

            let group = {
                equalGroup: egroup,
                positions: positions,
                totEmp: totEmp,
                women: nrWomen,
                men: nrMen,
                avgSalary, avgSalary,
                avgSalWom: avgSalWom,
                avgSalMen: avgSalMen,
                avgSalMenNoExtra: avgSalMenNoExtra,
                avgSalWomNoExtra: avgSalWomNoExtra,
                avgSalMenOnlyExtra: avgSalMenOnlyExtra,
                avgSalWomenOnlyExtra: avgSalWomenOnlyExtra,
                diffWom: diffWom,
                diffWomenPercent, diffWomenPercent,
                diffSalWomenPercentOnlyExtra: diffSalWomenPercentOnlyExtra,
                diffSalWomenPercentNoExtra: diffSalWomenPercentNoExtra,
                comments: thisGroupComments,
                spridningskvotMen: spridningskvotMen,
                spridningskvotWomen: spridningskvotWomen,
                avgSalTotNoExtra: avgSalTotNoExtra,
                diffWomenNoExtra: diffWomenNoExtra,
                avgSalTotOnlyExtra: avgSalTotOnlyExtra,
                diffWomenOnlyExtra: diffWomenOnlyExtra,
            }
            arr.push(group)
        }
        this.setState({ data: arr })
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let likaData = ""
        this.state.data === undefined || this.state.data.length == 0 ? likaData = "" :

            //If should hide salaries and show extra data
            this.props.hideSalaries && this.props.addOnDetails === "on" ?

                likaData = this.state.data.map((el, i) =>
                    <div style={{ paddingTop: 20 }} className="dontCut">
                        <h4>{el.equalGroup}</h4>
                        <span style={{ fontSize: 14 }}>{el.positions.map(pos => <span>{pos}, </span>)} </span>
                        <table style={{ marginLeft: 0, maxWidth: 'none' }} className="fixedTablePercent">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }} className="showB color bNoR bigPad"></td>
                                    <td className="showB color bNoR bNoL bigPad">{language === "sv" ? "Män" : lang[language].men}</td>
                                    <td className="showB color bNoR bNoL bigPad">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                    <td className="showB color bNoL bigPad">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left", }} className="showB bNoR bigPad">{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</td>
                                    <td className="showB bNoR bNoL bigPad">{parseInt(el.men)}</td>
                                    <td className="showB bNoR bNoL bigPad">{parseInt(el.women)}</td>
                                    <td className="showB bNoL bigPad">{parseInt(el.totEmp)}</td>
                                    {/*  <td className="showB bNoL bigPad">{el.women > 0 && el.men > 0 ? Math.round((100 - ((el.avgSalMen / el.avgSalWom) * 100)) * 10) / 10 : 0}%</td> */}
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</td>
                                    <td className="showB bNoR bNoL bigPadTwo">{el.spridningskvotMen}</td>
                                    <td className="showB bNoL bNoR bigPadTwo">{el.spridningskvotWomen}</td>
                                    <td className="showB bNoL bigPadTwo"></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }} className="showB color bNoR bigPad">{language === "sv" ? "Lönedifferens" : lang[language].salDiff}</td>
                                    <td className="showB color bNoR bNoL bigPad" style={{ width: 120 }}>{language === "sv" ? "Enbart tillägg" : lang[language].onlyBenefitsChart}</td>
                                    <td className="showB color bNoR bNoL bigPad" style={{ width: 120 }}>{language === "sv" ? "Exkl tillägg" : lang[language].exclBen}</td>
                                    <td className="showB color bNoL bigPad" style={{ width: 120 }}>{language === "sv" ? "Inkl tillägg" : lang[language].inclBen}</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Differens kvinnor" : lang[language].womenDiff}</td>
                                    <td className="showB bNoR bNoL bigPadTwo">{el.diffSalWomenPercentOnlyExtra === "" || el.diffSalWomenPercentOnlyExtra === "0%" || el.diffSalWomenPercentOnlyExtra === "NaN%" ? "0%" : el.diffSalWomenPercentOnlyExtra}</td>
                                    <td className="showB bNoR bNoL bigPadTwo">{el.diffSalWomenPercentNoExtra === "" || el.diffSalWomenPercentNoExtra === "0%" || el.diffSalWomenPercentNoExtra === "NaN%" ? "0%" : el.diffSalWomenPercentNoExtra}</td>
                                    <td className="showB bNoL bigPadTwo">{el.diffWomenPercent === "" || el.diffWomenPercent === "0%" || el.diffWomenPercent === "NaN%" ? "0%" : el.diffWomenPercent}</td>
                                </tr>

                                {this.props.chartImages === "none" && this.props.chartImagesAge === "none" ? <></> : // If no chart
                                    // If should show experience only
                                    this.props.chartImages !== "none" && this.props.chartImagesAge === "none" && this.props.chartImagesExtraExp === "none" ?
                                        <tr>
                                            <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /></td>
                                        </tr>
                                        // If should show experience only and extra chart
                                        : this.props.chartImages !== "none" && this.props.chartImagesAge === "none" && this.props.chartImagesExtraExp !== "none" ?
                                            <>
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesExtraExp[i]}`} /></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 45 }}>{language === "sv" ? "Lön inkl tillägg - År i organisationen" : lang[language].chartInfoOne}</span>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: 115 }}>{language === "sv" ? "Lön exkl tillägg - År i organisationen" : lang[language].chartInfoThree}</span> : <span style={{ marginLeft: 136 }}>{language === "sv" ? "Tillägg - År i organisationen" : lang[language].chartInfoFive}</span>}</td>
                                                </tr>
                                            </>
                                            //If should show age only
                                            : this.props.chartImages === "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraAge === "none" ?
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                </tr>
                                                //If should show age only and extra chart
                                                : this.props.chartImages === "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraAge !== "none" ?
                                                    <>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesExtraAge[i]}`} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: (language === "sv" ? 85 : 73 ) }}>{language === "sv" ? "Lön inkl tillägg - Ålder" : lang[language].chartInfoTwo}</span>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 187 : 172 ) }}>{language === "sv" ? "Lön exkl tillägg - Ålder" : lang[language].chartInfoFour}</span> : <span style={{ marginLeft: 205 }}>{language === "sv" ? "Tillägg - Ålder" : lang[language].chartInfoSix}</span>}</td>
                                                        </tr>
                                                    </>
                                                    //If should show both charts but no extra
                                                    :
                                                    this.props.chartImages !== "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraExp === "none" && this.props.chartImagesExtraAge === "none" ?
                                                        <>
                                                            <tr>
                                                                <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 78 }}>{language === "sv" ? "År i organisationen" : lang[language].yearInOrg}</span><span style={{ marginLeft: (language === "sv" ? 206 : 241) }}>{language === "sv" ? "Ålder" : lang[language].age}</span></td>
                                                            </tr>
                                                        </>

                                                        //If should show both charts and both extra
                                                        : this.props.chartImages !== "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraExp !== "none" && this.props.chartImagesExtraAge !== "none" ?
                                                            <>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: (language === "sv" ? 45 : 27) }}>{language === "sv" ? "Lön inkl tillägg - År i organisationen" : lang[language].chartInfoOne}</span><span style={{ marginLeft: (language === "sv" ? 155 : 124 ) }}>{language === "sv" ? "Lön inkl tillägg - Ålder" : lang[language].chartInfoTwo}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImagesExtraExp[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesExtraAge[i]}`} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 46 : 27) }}>{language === "sv" ? "Lön exkl tillägg - År i organisationen" : lang[language].chartInfoThree}</span> : <span style={{ marginLeft: (language === "sv" ? 63 : 55) }}>{language === "sv" ? "Tillägg - År i organisationen" : lang[language].chartInfoFive}</span>}{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 153 : 119 ) }}>{language === "sv" ? "Lön exkl tillägg - Ålder" : lang[language].chartInfoFour}</span> : <span style={{ marginLeft: (language === "sv" ? 196 : 183) }}>{language === "sv" ? "Tillägg - Ålder" : lang[language].chartInfoSix}</span>}</td>
                                                                </tr>
                                                            </> : <></>
                                }
                                {/* For comments */}
                                {el.comments.length > 0 ?
                                    <>
                                        <tr>
                                            <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="5" className="showB color bNoB">{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", paddingRight: 10 }} colspan="2" className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                                            <td style={{ textAlign: "left" }} colspan="2" className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                                            <td style={{ textAlign: "left" }} colspan="1" className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                                        </tr> </> : ""}
                                {el.comments.length > 0 ?
                                    el.comments.map((com) =>
                                        <tr>
                                            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} colspan="2" className="showB bNoR">{com.notable}</td>
                                            <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top" }} colspan="2" className="showB bNoR bNoL">{com.reason}</td>
                                            <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top" }} colspan="1" className="showB bNoL">{com.todo}</td>
                                        </tr>
                                    )
                                    : ""
                                }
                            </tbody>
                        </table>
                    </div >
                )
                //If should hide salaries & NOT show extra data
                : this.props.hideSalaries && this.props.addOnDetails !== "on" ?
                    likaData = this.state.data.map((el, i) =>
                        <div style={{ paddingTop: 20 }} className="dontCut">
                            <h4>{el.equalGroup}</h4>
                            <span style={{ fontSize: 14 }}>{el.positions.map(pos => <span>{pos}, </span>)} </span>
                            <table style={{ marginLeft: 0, maxWidth: 'none' }} className="fixedTablePercent">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }} className="showB color bNoR bigPad"></td>
                                        <td className="showB color bNoR bNoL bigPad">{language === "sv" ? "Män" : lang[language].men}</td>
                                        <td className="showB color bNoR bNoL bigPad">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                        <td className="showB color bNoL bigPad">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                        <td className="showB color bNoL bigPad">{language === "sv" ? "Lönediff. kvinnor" : lang[language].gapWomen}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", }} className="showB bNoR bigPad">{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</td>
                                        <td className="showB bNoR bNoL bigPad">{parseInt(el.men)}</td>
                                        <td className="showB bNoR bNoL bigPad">{parseInt(el.women)}</td>
                                        <td className="showB bNoL bigPad">{parseInt(el.totEmp)}</td>
                                        <td className="showB bNoL bigPad"> {el.diffWomenPercent === "" || el.diffWomenPercent === "0%" || el.diffWomenPercent === "NaN%" ? "0%" : el.diffWomenPercent}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</td>
                                        <td className="showB bNoR bNoL bigPadTwo">{el.spridningskvotMen}</td>
                                        <td className="showB bNoL bNoR bigPadTwo">{el.spridningskvotWomen}</td>
                                        <td className="showB bNoL bNoR bigPadTwo"></td>
                                        <td className="showB bNoL bigPadTwo"></td>
                                    </tr>
                                    {this.props.chartImages === "none" && this.props.chartImagesAge === "none" ? <></> :
                                        this.props.chartImages !== "none" && this.props.chartImagesAge === "none" ?
                                            <tr>
                                                <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /></td>
                                            </tr> :
                                            this.props.chartImages === "none" && this.props.chartImagesAge !== "none" ?
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                </tr> :
                                                <><tr>
                                                    <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={285} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={285} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                </tr>
                                                    <tr>
                                                        <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 85 }}>{language === "sv" ? "År i organisationen" : lang[language].yearInOrg}</span><span style={{ marginLeft: 239 }}>{language === "sv" ? "Ålder" : lang[language].age}</span></td>
                                                    </tr>
                                                </>
                                    }
                                    {el.comments.length > 0 ?
                                        <>
                                            <tr>
                                                <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="5" className="showB color bNoB">{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left", paddingRight: 10 }} colspan="2" className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                                                <td style={{ textAlign: "left" }} colspan="2" className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                                                <td style={{ textAlign: "left" }} colspan="1" className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                                            </tr> </> : ""}
                                    {el.comments.length > 0 ?
                                        el.comments.map((com) =>
                                            <tr>
                                                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} colspan="2" className="showB bNoR">{com.notable}</td>
                                                <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top" }} colspan="2" className="showB bNoR bNoL">{com.reason}</td>
                                                <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top" }} colspan="1" className="showB bNoL">{com.todo}</td>
                                            </tr>
                                        )
                                        : ""
                                    }
                                </tbody>
                            </table>
                        </div >
                    )

                    //If should show salaries & show extra data
                    : !this.props.hideSalaries && this.props.addOnDetails === "on" ?
                        likaData = this.state.data.map((el, i) =>
                            <div style={{ paddingTop: 20 }} className="dontCut">
                                <h4>{el.equalGroup}</h4>
                                <span style={{ fontSize: 14 }}>{el.positions.map(pos => <span>{pos}, </span>)} </span>
                                <table style={{ marginLeft: 0, maxWidth: 'none' }} className="fixedTable">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPadTwo">{language === "sv" ? "Anställda i grupp" : lang[language].equalNrOfWorkers}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Män" : lang[language].men}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                            <td className="showB color bNoL bigPadTwo">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.men)}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.women)}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.totEmp)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPadTwo">{language === "sv" ? "Lön (inkl tillägg)" : lang[language].salInclBenefit}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Män" : lang[language].men}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                            <td className="showB color bNoL bigPadTwo">{language === "sv" ? "Differens kvinnor" : lang[language].womenDiff}</td>
                                        </tr>
                                        <tr style={{ background: "#fdedd1" }}>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Medellön inkl tillägg" : lang[language].avgSalInclBenefit}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalMen).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalWom).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalary).toLocaleString()}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.diffWom).toLocaleString()}<span style={{ fontStyle: "italic", color: "#565656", fontSize: 12 }}>{el.diffWomenPercent === "" || el.diffWomenPercent === "0%" || el.diffWomenPercent === "NaN%" ? "" : " (" + el.diffWomenPercent + ")"}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Medellön exkl tillägg" : lang[language].avgSalExclBenefit}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalMenNoExtra).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalWomNoExtra).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalTotNoExtra).toLocaleString()}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.diffWomenNoExtra).toLocaleString()}<span style={{ fontStyle: "italic", color: "#565656", fontSize: 12 }}>{el.diffSalWomenPercentNoExtra === "" || el.diffSalWomenPercentNoExtra === "0%" || el.diffSalWomenPercentNoExtra === "NaN%" ? "" : " (" + el.diffSalWomenPercentNoExtra + ")"}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Tillägg" : lang[language].benefits}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalMenOnlyExtra).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalWomenOnlyExtra).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalTotOnlyExtra).toLocaleString()}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.diffWomenOnlyExtra).toLocaleString()}<span style={{ fontStyle: "italic", color: "#565656", fontSize: 12 }}>{el.diffSalWomenPercentOnlyExtra === "" || el.diffSalWomenPercentOnlyExtra === "0%" || el.diffSalWomenPercentOnlyExtra === "NaN%" ? "" : " (" + el.diffSalWomenPercentOnlyExtra + ")"}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Lönespridning (inkl tillägg)" : lang[language].salarySpreadInclBenefit}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{el.spridningskvotMen}</td>
                                            <td className="showB bNoL bNoR bigPadTwo">{el.spridningskvotWomen}</td>
                                            <td className="showB bNoL bNoR bigPadTwo"></td>
                                            <td className="showB bNoL bigPadTwo"></td>
                                        </tr>
                                        {this.props.chartImages === "none" && this.props.chartImagesAge === "none" ? <></> : // If no chart
                                            // If should show experience only
                                            this.props.chartImages !== "none" && this.props.chartImagesAge === "none" && this.props.chartImagesExtraExp === "none" ?
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /></td>
                                                </tr>
                                                // If should show experience only and extra chart
                                                : this.props.chartImages !== "none" && this.props.chartImagesAge === "none" && this.props.chartImagesExtraExp !== "none" ?
                                                    <>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesExtraExp[i]}`} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: (language === "sv" ? 65 : 53) }}>{language === "sv" ? "Lön inkl tillägg - År i organisationen" : lang[language].chartInfoOne}</span>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 164 : 125 ) }}>{language === "sv" ? "Lön exkl tillägg - År i organisationen" : lang[language].chartInfoThree}</span> : <span style={{ marginLeft: (language === "sv" ? 184 : 155 ) }}>{language === "sv" ? "Tillägg - År i organisationen" : lang[language].chartInfoFive}</span>}</td>
                                                        </tr>
                                                    </>
                                                    //If should show age only
                                                    : this.props.chartImages === "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraAge === "none" ?
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                        </tr>
                                                        //If should show age only and extra chart
                                                        : this.props.chartImages === "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraAge !== "none" ?
                                                            <>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /><img style={{ marginLeft: 17 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesExtraAge[i]}`} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 102 }}>{language === "sv" ? "Lön inkl tillägg - Ålder" : lang[language].chartInfoTwo}</span>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 231 : 215) }}>{language === "sv" ? "Lön exkl tillägg - Ålder" : lang[language].chartInfoFour}</span> : <span style={{ marginLeft: (language === "sv" ? 256 : 243) }}>{language === "sv" ? "Tillägg - Ålder" : lang[language].chartInfoSix}</span>}</td>
                                                                </tr>
                                                            </>
                                                            //If should show both charts but no extra
                                                            :
                                                            this.props.chartImages !== "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraExp === "none" && this.props.chartImagesExtraAge === "none" ?
                                                                <>
                                                                    <tr>
                                                                        <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 107 }}>{language === "sv" ? "År i organisationen" : lang[language].yearInOrg}</span><span style={{ marginLeft: 289 }}>{language === "sv" ? "Ålder" : lang[language].age}</span></td>
                                                                    </tr>
                                                                </>

                                                                //If should show both charts and both extra
                                                                : this.props.chartImages !== "none" && this.props.chartImagesAge !== "none" && this.props.chartImagesExtraExp !== "none" && this.props.chartImagesExtraAge !== "none" ?
                                                                    <>
                                                                        <tr>
                                                                            <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 17 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: (language === "sv" ? 67 : 55) }}>{language === "sv" ? "Lön inkl tillägg - År i organisationen" : lang[language].chartInfoOne}</span><span style={{ marginLeft: (language === "sv" ? 194 : 175) }}>{language === "sv" ? "Lön inkl tillägg - Ålder" : lang[language].chartInfoTwo}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImagesExtraExp[i]}`} /><img style={{ marginLeft: 17 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesExtraAge[i]}`} /></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}>{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: 66 }}>{language === "sv" ? "Lön exkl tillägg - År i organisationen" : lang[language].chartInfoThree}</span> : <span style={{ marginLeft: 87 }}>{language === "sv" ? "Tillägg - År i organisationen" : lang[language].chartInfoFive}</span>}{this.props.addOnChart !== "onlyExtra" ? <span style={{ marginLeft: (language === "sv" ? 194 : 161) }}>{language === "sv" ? "Lön exkl tillägg - Ålder" : lang[language].chartInfoFour}</span> : <span style={{ marginLeft: (language === "sv" ? 239 : 233 ) }}>{language === "sv" ? "Tillägg - Ålder" : lang[language].chartInfoSix}</span>}</td>
                                                                        </tr>
                                                                    </> : <></>
                                        }
                                        {el.comments.length > 0 ?
                                            <>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingLeft: 12 }} colspan="5" className="showB color bNoB">{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingRight: 10 }} colspan="2" className="showB color bNoR bigPad bNoT smallTitlePDF12">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                                                    <td style={{ textAlign: "left" }} colspan="2" className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                                                    <td style={{ textAlign: "left" }} colspan="1" className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                                                </tr> </> : ""}
                                        {el.comments.length > 0 ?
                                            el.comments.map((com) =>
                                                <tr>
                                                    <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 12, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="2" className="showB bNoR">{com.notable}</td>
                                                    <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="2" className="showB bNoR bNoL">{com.reason}</td>
                                                    <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="1" className="showB bNoL">{com.todo}</td>
                                                </tr>
                                            )
                                            : ""
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )

                        //If should show salaries & NOT show extra data
                        :
                        likaData = this.state.data.map((el, i) =>
                            <div style={{ paddingTop: 20 }} className="dontCut">
                                <h4>{el.equalGroup}</h4>
                                <span style={{ fontSize: 14 }}>{el.positions.map(pos => <span>{pos}, </span>)} </span>
                                <table style={{ marginLeft: 0, maxWidth: 'none' }} className="fixedTable">
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPadTwo">{language === "sv" ? "Anställda i grupp" : lang[language].equalNrOfWorkers}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Män" : lang[language].men}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                            <td className="showB color bNoL bigPadTwo">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.men)}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.women)}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.totEmp)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPadTwo">{language === "sv" ? "Lön (inkl tillägg)" : lang[language].salInclBenefit}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Män" : lang[language].men}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                            <td className="showB color bNoR bNoL bigPadTwo">{language === "sv" ? "Totalt" : lang[language].total}</td>
                                            <td className="showB color bNoL bigPadTwo">{language === "sv" ? "Differens kvinnor" : lang[language].womenDiff}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Medellön" : lang[language].averageSal}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalMen).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalWom).toLocaleString()}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{parseInt(el.avgSalary).toLocaleString()}</td>
                                            <td className="showB bNoL bigPadTwo">{parseInt(el.diffWom).toLocaleString()}<span style={{ fontStyle: "italic", color: "#565656", fontSize: 12 }}>{el.diffWomenPercent === "" || el.diffWomenPercent === "0%" || el.diffWomenPercent === "NaN%" ? "" : " (" + el.diffWomenPercent + ")"}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left" }} className="showB bNoR bigPadTwo">{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</td>
                                            <td className="showB bNoR bNoL bigPadTwo">{el.spridningskvotMen}</td>
                                            <td className="showB bNoL bNoR bigPadTwo">{el.spridningskvotWomen}</td>
                                            <td className="showB bNoL bNoR bigPadTwo"></td>
                                            <td className="showB bNoL bigPadTwo"></td>
                                        </tr>
                                        {this.props.chartImages === "none" && this.props.chartImagesAge === "none" ? <></> :
                                            this.props.chartImages !== "none" && this.props.chartImagesAge === "none" ?
                                                <tr>
                                                    <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /></td>
                                                </tr> :
                                                this.props.chartImages === "none" && this.props.chartImagesAge !== "none" ?
                                                    <tr>
                                                        <td colspan="5" style={{ textAlign: "left" }}><img width={380} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                    </tr> :
                                                    <>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", paddingLeft: 0 }}><img width={330} src={`data:image/jpeg;base64,${this.props.chartImages[i]}`} /><img style={{ marginLeft: 25 }} width={330} src={`data:image/jpeg;base64,${this.props.chartImagesAge[i]}`} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" style={{ textAlign: "left", fontSize: 11, color: "#b8b8b8", fontWeight: 600 }}><span style={{ marginLeft: 108 }}>{language === "sv" ? "År i organisationen" : lang[language].yearInOrg}</span><span style={{ marginLeft: 293 }}>{language === "sv" ? "Ålder" : lang[language].age}</span></td>
                                                        </tr>
                                                    </>
                                        }
                                        {el.comments.length > 0 ?
                                            <>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingLeft: 12 }} colspan="5" className="showB color bNoB">{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingRight: 10 }} colspan="2" className="showB color bNoR bigPad bNoT smallTitlePDF12">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                                                    <td style={{ textAlign: "left" }} colspan="2" className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                                                    <td style={{ textAlign: "left" }} colspan="1" className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                                                </tr> </> : ""}
                                        {el.comments.length > 0 ?
                                            el.comments.map((com) =>
                                                <tr>
                                                    <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 12, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="2" className="showB bNoR">{com.notable}</td>
                                                    <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="2" className="showB bNoR bNoL">{com.reason}</td>
                                                    <td style={{ fontSize: 13, textAlign: "left", wordWrap: "break-word", paddingLeft: 0, paddingRight: 10, verticalAlign: "top", paddingTop: 7, paddingBottom: 7 }} colspan="1" className="showB bNoL">{com.todo}</td>
                                                </tr>
                                            )
                                            : ""
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )

        return (
            <div className="aPage">
                <h2 className="h2PaddingTop">{language === "sv" ? "5. Resultat & analys" : lang[language].tocResults}</h2>

                <h3 className="h2PaddingTop">{language === "sv" ? "5.1 Lika arbete" : lang[language].tocEqualWork}</h3>
                {/* Do not show images and show kr */}
                <p className={this.props.chartImages === "none" && this.props.chartImagesAge === "none" && !this.props.hideSalaries ? "P-document" : "hide"}>
                    {language === "sv" ? <>Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter.
                    Varje grupp presenteras med totalt antal anställda, könsfördelning, lönespridning, medellön för kvinnor
                    och män samt lönedifferens. </> : lang[language].equalOne} <span className={this.props.addOnDetails === "on" ? "" : "hide"}>{language === "sv" ? "Medellön redovisas inklusive tillägg och exklusive tillägg samt så redovisas differensen för enbart tillägg." : lang[language].equalTwo}</span>

                    <p className="P-document">{language === "sv" ? <>Spridningskvoten visar hur stor lönespridningen är,
                        där en kvot på 2 innebär att den högsta lönen är dubbelt så hög som den lägsta. I de fall ett kön består av 20 personer eller mer
                        baseras kvoten på percentil 10 och 90, dvs den lönenivå där 10% tjänar mindre och där 10% tjänar mer. Detta för att ge en rättvis bild av spridningen
                        och undvika extremvärden.</> : lang[language].equalThree}</p>

                    {language === "sv" ? <>Vid analysering har förutom nedan redovisade uppgifter även
                    punktdiagram använts för att belysa individuella skillnader. Punktdiagrammen har synliggjort alla anställda i 
                    respektive grupp baserat på lön och år i organisationen</> : lang[language].equalFour}<span className={this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn" ? "" : "hide"}>{language === "sv" ? " samt ålder" : lang[language].equalFive}</span>.
                </p>

                {/* Show images and show kr */}
                <p className={(this.props.chartImages !== "none" || this.props.chartImagesAge !== "none") && !this.props.hideSalaries ? "P-document" : "hide"}>
                    {language === "sv" ? <>Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter.
                    Varje grupp presenteras med totalt antal anställda, könsfördelning, lönespridning, medellön för kvinnor
                    och män samt lönedifferens. </> : lang[language].equalOne} <span className={this.props.addOnDetails === "on" ? "" : "hide"}>{language === "sv" ? "Medellön redovisas inklusive tillägg och exklusive tillägg samt så redovisas differensen för enbart tillägg." : lang[language].equalTwo}</span>

                    <p className="P-document">{language === "sv" ? <>Spridningskvoten visar hur stor lönespridningen är,
                        där en kvot på 2 innebär att den högsta lönen är dubbelt så hög som den lägsta. I de fall ett kön består av 20 personer eller mer
                        baseras kvoten på percentil 10 och 90, dvs den lönenivå där 10% tjänar mindre och där 10% tjänar mer. Detta för att ge en rättvis bild av spridningen
                        och undvika extremvärden.</> : lang[language].equalThree}</p>

                    <span className={this.props.showAgeChart === "off" || this.props.showAgeChart === "adminOff" ? "" : "hide"}>
                        {language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSix}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "on" && this.props.chartAge === "off" ? "" : "hide"}>
                        {language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSix}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "off" && this.props.chartAge === "on" ? "" : "hide"}>
                        {language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och ålder, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSeven}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "on" && this.props.chartAge === "on" ? "" : "hide"}>
                        {language === "sv" ? <>Vid analysering har även punktdiagram använts för att belysa
                        individuella skillnader. Punktdiagrammen har synliggjort alla anställda i respektive grupp
                        baserat på lön och antal år i organisationen samt ålder. Dessa redovisas nedan, där punkter med blå färg är
                        män och grön färg är kvinnor.</> : lang[language].equalEight}
                    </span>

                    {this.props.chartPercent === "on" ? language === "sv" ? " Y-axeln visar hur stor andel av den totala lönen i gruppen som individerna besitter i procent." : lang[language].equalNine : ""}
                </p>

                {/* Do not show images and show percent */}
                <p className={(this.props.chartImages === "none" && this.props.chartImagesAge === "none") && this.props.hideSalaries ? "P-document" : "hide"}>
                    {language === "sv" ? <>Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter. Varje grupp
                    presenteras med totalt antal anställda, könsfördelning, lönespridning samt skillnaden i lön för kvinnor i procent.
                    Ett negativt procenttal visar hur mycket mindre kvinnorna tjänar jämfört med männens lön, ett positivt procenttal visar hur mycket mer. </> : lang[language].equalTen}<span className={this.props.addOnDetails === "on" ? "" : "hide"}>{language === "sv" ? "Löneskillnaden redovisas inklusive tillägg, exklusive tillägg samt differensen för enbart tillägg." : lang[language].equalEleven}</span>

                    <p className="P-document">{language === "sv" ? <>Spridningskvoten visar hur stor lönespridningen är,
                        där en kvot på 2 innebär att den högsta lönen är dubbelt så hög som den lägsta. I de fall ett kön består av 20 personer eller mer
                        baseras kvoten på percentil 10 och 90, dvs den lönenivå där 10% tjänar mindre och där 10% tjänar mer. Detta för att ge en rättvis bild av spridningen
                        och undvika extremvärden.</> : lang[language].equalThree}</p>

                    {language === "sv" ? <>Vid analysering har förutom nedan redovisade uppgifter även
                    punktdiagram använts för att belysa individuella skillnader. Punktdiagrammen har synliggjort alla anställda i 
                    respektive grupp baserat på lön och år i organisationen</> : lang[language].equalFour}<span className={this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn" ? "" : "hide"}>{language === "sv" ? " samt ålder" : lang[language].equalFive}</span>.
                </p>

                {/* Show images and show percent */}
                <p className={(this.props.chartImages !== "none" || this.props.chartImagesAge !== "none") && this.props.hideSalaries ? "P-document" : "hide"}>
                    {language === "sv" ? <>Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter. Varje grupp
                    presenteras med totalt antal anställda, könsfördelning, lönespridning samt skillnaden i lön för kvinnor i procent.
                    Ett negativt procenttal visar hur mycket mindre kvinnorna tjänar jämfört med männens lön, ett positivt procenttal visar hur mycket mer. </> : lang[language].equalTen} <span className={this.props.addOnDetails === "on" ? "" : "hide"}>{language === "sv" ? "Löneskillnaden redovisas inklusive tillägg, exklusive tillägg samt differensen för enbart tillägg." : lang[language].equalEleven}</span>

                    <p className="P-document">{language === "sv" ? <>Spridningskvoten visar hur stor lönespridningen är,
                        där en kvot på 2 innebär att den högsta lönen är dubbelt så hög som den lägsta. I de fall ett kön består av 20 personer eller mer
                        baseras kvoten på percentil 10 och 90, dvs den lönenivå där 10% tjänar mindre och där 10% tjänar mer. Detta för att ge en rättvis bild av spridningen
                        och undvika extremvärden.</> : lang[language].equalThree}</p>

                    {language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader" : lang[language].equalTwelve}

                    <span className={this.props.showAgeChart === "off" || this.props.showAgeChart === "adminOff" ? "" : "hide"}>
                    {language === "sv" ? ". Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalThirteen}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "on" && this.props.chartAge === "off" ? "" : "hide"}>
                    {language === "sv" ? ". Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalThirteen}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "off" && this.props.chartAge === "on" ? "" : "hide"}>
                    {language === "sv" ? ". Nedan redovisas punktdiagram baserat på lön och ålder, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalFourteen}
                    </span>
                    <span className={(this.props.showAgeChart === "on" || this.props.showAgeChart === "adminOn") && this.props.chartExp === "on" && this.props.chartAge === "on" ? "" : "hide"}>
                    {language === "sv" ? ". Punktdiagrammen har synliggjort alla anställda i respektive grupp baserat på lön och år i organisationen samt ålder. Dessa redovisas nedan, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalFifteen}
                    </span>

                    {this.props.chartPercent === "on" ? language === "sv" ? " Y-axeln visar hur stor andel av den totala lönen i gruppen som individerna besitter i procent." : lang[language].equalNine : ""}
                </p>

                <p className="P-document">
                {language === "sv" ? <>Lika arbeten som har bedömts utföra lika eller nästintill lika arbetsuppgifter
                    har satts ihop i samma grupp för att ge en rättvis och lagenlig kartläggning.
                    De befattningar som ingår i respektive grupp anges under gruppens rubrik.</> : lang[language].equalSixteen}
                </p>

                <p className="P-document">
                {language === "sv" ? "I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].equalSeventeen}
                </p>

                {/*Loop av resultat for lika arbeten */}
                {likaData}
            </div>
        )
    }
}