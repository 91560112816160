import React, { Component } from 'react';
import Chart from "react-apexcharts";
import './Arkitektur.css';
import trashCan from '../../assets/images/trashIcon.png';

export class SalaryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salaryRanges: [],
            inputValues: {},
            reCalculateMid: "off",
        }
        this.debouncedFunctions = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ranges !== this.props.ranges) { // Check if ranges prop has changed, otherwise there would be an infinite loop error
            let ranges = this.props.ranges

            // Get the ranges for each salary range and level
            // First remove all x value duplicates, we already have the range for each x-value in the range property
            const encounteredX = {};  // Object to keep track of encountered x values
           // let rangesCopy = JSON.parse(JSON.stringify(ranges)); 
            const uniqueXRanges = ranges.filter(obj => { // Filter the array to keep only the first object with each x value
                if (!encounteredX[obj.x]) {
                    encounteredX[obj.x] = true; // Mark the x value as encountered
                    return true; // Keep this object
                }
                return false; // Discard this object
            });

            // Calculate Spread and mid diff
            for (let i = 0; i < uniqueXRanges.length; i++) {
                // Calculate Spread (max-min)/min. Om har ställt in olika procent för över och under midpoint så beräknas det såhär: Över: (Max pay – Mid pay) / Mid pay, Under: (Min pay – Mid pay) / Mid. 
                uniqueXRanges[i].spread = Math.round(((uniqueXRanges[i].range[2] - uniqueXRanges[i].range[0]) / uniqueXRanges[i].range[0]) * 100) // This is the spread percent, for example 40 (40 percent)
                // Calculate mid diff (higher level midpoint - lower level midpoint) ÷ lower level midpoint] x 100)
                if (i + 1 < uniqueXRanges.length) {
                    uniqueXRanges[i + 1].midDiff = Math.round(((uniqueXRanges[i + 1].range[1] - uniqueXRanges[i].range[1]) / uniqueXRanges[i].range[1]) * 100) // This is the mid diff in percent, for example 40 (40 percent)
                }
            }
            this.setState({ inputValues: []}) // this is added because it stopped salaryList to update when clicking auto again. This fix may cause a bit worse performence. Look into if problems.
            this.setState({ salaryRanges: uniqueXRanges })
        }
    }

    /*handleChange = (event) => {
        const value = event.target.value;
        const level = event.target.getAttribute("level") // The equivalent group/level
        const typeOfSal = event.target.getAttribute("typeOfSal") // This is if it is the min, mid or max salary
        const familyOrSub = event.target.getAttribute("familyOrSub") // This is if it is for a family or the overall levels. "no" is no family or subfamily
        const grouped = {value: value, level: level, typeOfSal: typeOfSal, familyOrSub: familyOrSub }
        this.props.onInputChange(grouped); // This calls the onInputChange function passed from parent component
        //value={row.range[2]} 
    }*/
    handleChange = (e, level, typeOfSal) => {
        // First, check if midValue is editied, if so, let it be edited. If it is max or min that is edited, make mid recalculate automatically 
        if(typeOfSal === "mid") {
            this.setState({reCalculateMid: "off"})
        } else if (typeOfSal === "min" || typeOfSal === "max") {
            this.setState({reCalculateMid: "on"})
        } 

        // Then update values and state in parent company
        const { value } = e.target;
        const identifier = `${level}_${typeOfSal}`;

        // Update the input value immediately
        this.setState(prevState => ({
            inputValues: {
                ...prevState.inputValues,
                [identifier]: value
            }
        }));

        // Clear the previous timer for this input if it exists
        clearTimeout(this.debouncedFunctions[identifier]);

        // Set a new timer to call the function after 100ms <-- Höj till 200 om går segt
        this.debouncedFunctions[identifier] = setTimeout(() => {
            // Call the function after debounce time
            this.props.onInputChange({
                value: this.state.inputValues[identifier], // Correctly use the value from state
                level,
                typeOfSal
            });
        }, 100);
    };

    componentWillUnmount() {
        Object.values(this.debouncedFunctions).forEach(timer => clearTimeout(timer));  // Clear all timers when component unmounts
    }

    render() {
        return (
            <>
            <div className={this.props.ranges.length < 1 ? "noSalaryBandBox" : "hide"}>Inga lönespann skapade</div>
            <div className={this.props.ranges.length < 1 ? "hide" : "arcSalTable"}>
                <div className="arcSalRow" style={{ borderBottom: "none" }}>
                    <div className="arcSalCell arcSalCellTitle arcSalCellLevel arcSalCellTop">Nivå</div>
                    <div className="arcSalCell arcSalCellTitle arcSalCellTop"><span style={{marginLeft: -2}}>Min</span></div>
                    <div className="arcSalCell arcSalCellTitle arcSalCellTop"><span style={{marginLeft: -14}}>Mid</span></div>
                    <div className="arcSalCell arcSalCellTitle arcSalCellTop"><span style={{marginLeft: -14}}>Max</span></div>
                    <div className="arcSalCell arcSalCellTitle arcSalCellTop">Mid diff</div>
                    <div className="arcSalCell arcSalCellTitle arcSalCellTop">Spridning</div>
                </div>
                {this.state.salaryRanges.map((row, index) => (
                    <div className="arcSalRow">
                        <div className="arcSalCell arcSalCellLevel arcSalCellTitle">{row.x}</div>
                        <div className="arcSalCell" style={{paddingRight: 1}}><input type="number" familyOrSub="no" level={row.x} typeOfSal="min" value={this.state.inputValues[`${row.x}_min`] !== undefined ? this.state.inputValues[`${row.x}_min`] : row.range[0]} className="inputSalaryList" step="1000" onChange={e => this.handleChange(e, row.x, "min", "no")} /></div>
                        <div className="arcSalCell" style={{paddingLeft: 0, paddingRight: 1}}><input type="number" familyOrSub="no" level={row.x} typeOfSal="mid" value={this.state.reCalculateMid === "on" ? row.range[1] : this.state.inputValues[`${row.x}_mid`] !== undefined ? this.state.inputValues[`${row.x}_mid`] : row.range[1]} className="inputSalaryList" step="1000" onChange={e => this.handleChange(e, row.x, "mid", "no")} /></div>
                        <div className="arcSalCell" style={{paddingLeft: 0, paddingRight: 1}}><input type="number" familyOrSub="no" level={row.x} typeOfSal="max" value={this.state.inputValues[`${row.x}_max`] !== undefined ? this.state.inputValues[`${row.x}_max`] : row.range[2]} className="inputSalaryList" step="1000" onChange={e => this.handleChange(e, row.x, "max", "no")}
                        /></div>
                        <div className="arcSalCell">{row.midDiff === undefined ? "-" : row.midDiff + " %"}</div>
                        <div className="arcSalCell">{row.spread + " %"}</div>
                        <img src={trashCan} className="salaryTrashCan" onClick={() => this.props.handleTrashClick(row.x)}/>
                    </div>
                ))}
            </div>
            </>
        )
    }
}