import React, { useEffect, useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import './draftEditor.css';
import DOMPurify from 'dompurify';

const DraftEditor = ({ rawContent, onSave, onCancel }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlOutput, setHtmlOutput] = useState('');

  // Initialize editorState with rawContent if available
  useEffect(() => {
    if (rawContent && rawContent !== "") {
      try {
        // Convert rawContent from JSON to EditorState
        const contentState = convertFromRaw(JSON.parse(rawContent));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Failed to parse rawContent", error);
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [rawContent]);

  // Handle key commands and format buttons
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (type) => {
    setEditorState(RichUtils.toggleBlockType(editorState, type));
  };

  // Handle paste events
  const handlePastedText = (text, html) => {
    const cleanHTML = DOMPurify.sanitize(html);
    setEditorState(EditorState.createWithContent(ContentState.createFromText(cleanHTML)));
    return true;
  };

  // Update HTML output when editor state changes
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const content = JSON.stringify(rawContentState);
    const sanitizedOutput = DOMPurify.sanitize(content); // Sanitize output
    setHtmlOutput(sanitizedOutput);
  }, [editorState]);

  return (
    <div>
      <div id="editor-toolbar" className="draftToolbar">
        <button className="draftBtn" style={{ fontWeight: 'bold' }} onClick={() => toggleInlineStyle('BOLD')}>B</button>
        <button className="draftBtn" style={{ fontStyle: 'italic' }} onClick={() => toggleInlineStyle('ITALIC')}>I</button>
        <button className="draftBtn" style={{ textDecoration: 'underline' }} onClick={() => toggleInlineStyle('UNDERLINE')}>U</button>
        <button className="draftBtn" onClick={() => toggleBlockType('unordered-list-item')}>•</button>
      </div>
      <div style={{ border: '1px solid #ddd', padding: '10px', minHeight: '280px', borderRadius: 5 }}>
        <Editor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
          onPastedText={handlePastedText}
          placeholder="Ange rollbeskrivning, arbetsuppgifter samt kvalifikationer."
        />
      </div>
      <div className="draftSaveBtn" onClick={() => onSave(editorState)}>Spara</div>
      <div className="draftCancelBtn" onClick={() => onCancel(editorState)}>Avbryt</div>
    </div>
  );
};

export default DraftEditor;