import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    ImageRun,
    ShadingType,
    Run,
} from "docx";
import { autoAnalysis } from '../../AnalysLikvardiga/autoAnalysis';
import lang from '../../Language/language.js';
const percentile = require("percentile");

export const LikvardigaComparedExt = (persData, tableSpace, nrOfEqualentGroups, whatEvaluation) => {
    let language = localStorage.getItem('language') ?? 'sv';
    let borderTop = {style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" }//borderTop <-- change later

    //1. Group persData by extEqualentGroups
    const calculate = (group) => {
        let useG = ""
            if(whatEvaluation === "extLevel") {useG = "extEqualentGroup"}
            if(whatEvaluation === "edited") {useG = "editedEqualentGroup"}
        const groupedData = Object.values(persData.reduce((result, item) => {
            let g = ""
            if(whatEvaluation === "extLevel") {g = item.extEqualentGroup}
            if(whatEvaluation === "edited") {g = item.editedEqualentGroup}
            const positionTitle = item.positionTitle;
            const salary = item.totalSalary;
            const gender = item.gender;
            const groupNameArbVardering = item.groupNameArbVardering

            if (!result[g]) {
                result[g] = [];
            }

            const group = result[g].find(group => group.groupNameArbVardering === groupNameArbVardering);
            if (group) {
                group.groups.push(item);
                group.averageSalary += salary;
                group.numberOfPersons++;
                group.percentageOfWomen += (gender === 'woman' ? 1 : 0);
                group.lowestSalary = Math.min(group.lowestSalary, salary);
                group.highestSalary = Math.max(group.highestSalary, salary);
            } else {
                result[g].push({
                    g,
                    positionTitle,
                    groupNameArbVardering, //added this
                    groups: [item],
                    averageSalary: salary,
                    numberOfPersons: 1,
                    percentageOfWomen: (gender === 'woman' ? 1 : 0),
                    lowestSalary: salary,
                    highestSalary: salary
                });
            }
            return result;
        }, {}));

        groupedData.forEach(gGroup => {
            gGroup.forEach(positionGroup => {
                positionGroup.averageSalary /= positionGroup.numberOfPersons;
                positionGroup.percentageOfWomen = Math.round(((positionGroup.percentageOfWomen / positionGroup.numberOfPersons) * 100))
                positionGroup.spreadRatio = Math.round((positionGroup.highestSalary / positionGroup.lowestSalary) * 10) / 10
            });
        });

        // 2. Compare each work
        let comparisons = 0
        let allComparisons = []
        //make array of all groups and their data, not structured by equalent group
        let allGroups = groupedData.flat();

        for (let i = 0; i < allGroups.length; i++) {
            for (let j = i + 1; j < allGroups.length; j++) {

                //console.log(allGroups[i], allGroups[j], )
                //Here compare them to eachother
                const group1 = allGroups[i].groupNameArbVardering
                const group2 = allGroups[j].groupNameArbVardering
                //Get all employees in those groups
                const groupOne = persData.filter(person => person.groupNameArbVardering === group1);
                const groupTwo = persData.filter(person => person.groupNameArbVardering === group2);

                //calculate average values
                //group 1: 
                const oneNrOfEmp = groupOne.length
                const oneWomen = Math.round((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
                const oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + person.totalSalary, 0) / oneNrOfEmp)
                const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
                const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
                const oneEqualentGroup = groupOne[0][useG] //CHANGE THIS IF CHANGING EQUALENT GROUP
                const oneCityArray = groupOne.map(person => person.city)
                const oneAverageCity = oneCityArray.sort((a, b) =>
                    oneCityArray.filter(v => v === a).length
                    - oneCityArray.filter(v => v === b).length
                ).pop();
                //Spridning
                let oneGetOnlySalaries = []
                for (let i = 0; i < groupOne.length; i++) { oneGetOnlySalaries.push(groupOne[i].totalSalary) }
                let oneLowestSalary = Math.min(...oneGetOnlySalaries)
                let oneHighestSalary = Math.max(...oneGetOnlySalaries)
                let oneSpridningskvot = Math.round((oneHighestSalary / oneLowestSalary) * 10) / 10
                //If 20 or more persons in the group
                if (groupOne.length > 19) {
                    let salariesInOrder = oneGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                    let more10 = percentile(10, salariesInOrder)
                    let more90 = percentile(90, salariesInOrder)
                    oneSpridningskvot = Math.round((more90 / more10) * 10) / 10
                }

                //group 2:
                const twoNrOfEmp = groupTwo.length
                const twoWomen = Math.floor((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
                const twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + person.totalSalary, 0) / twoNrOfEmp)
                const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
                const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
                const twoEqualentGroup = groupTwo[0][useG] //CHANGE THIS IF CHANGING EQUALENT GROUP
                const twoCityArray = groupTwo.map(person => person.city)
                const twoAverageCity = twoCityArray.sort((a, b) =>
                    twoCityArray.filter(v => v === a).length
                    - twoCityArray.filter(v => v === b).length
                ).pop();
                //Spridning
                let twoGetOnlySalaries = []
                for (let i = 0; i < groupTwo.length; i++) { twoGetOnlySalaries.push(groupTwo[i].totalSalary) }
                let twoLowestSalary = Math.min(...twoGetOnlySalaries)
                let twoHighestSalary = Math.max(...twoGetOnlySalaries)
                let twoSpridningskvot = Math.round((twoHighestSalary / twoLowestSalary) * 10) / 10
                //If 20 or more persons in the group
                if (groupTwo.length > 19) {
                    let salariesInOrder = twoGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                    let more10 = percentile(10, salariesInOrder)
                    let more90 = percentile(90, salariesInOrder)
                    twoSpridningskvot = Math.round((more90 / more10) * 10) / 10
                }

                //Diffs
                const diffWomen = (oneWomen - twoWomen)

                //prepare data for robot
                let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
                let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
                let firsAvgSalary = oneAvgSalary //Total average salary for group 1
                let secondAvgSalary = twoAvgSalary //Total average salary for group 2
                let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
                let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
                let firstGroup = group1 //Employees in group 1
                let secondGroup = group2 //Employees in group 2
                let firstExp = oneAvgExp //Average Experience in group 1
                let secondExp = twoAvgExp //Average Experience in group 2
                let firstAge = oneAvgAge //Average Experience in group 1
                let secondAge = twoAvgAge //Average Experience in group 2
                let firstEqualent = oneEqualentGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
                let secondEqualent = twoEqualentGroup
                let firstCity = oneAverageCity
                let secondCity = twoAverageCity
                let firstSpridning = oneSpridningskvot
                let secondSpridning = twoSpridningskvot

                //Only compare if one group is women dominated and the second is not
                if ((oneWomen > 59 && twoWomen < 60) || (twoWomen > 59 && oneWomen < 60)) {
                    //Count nr of comparisions
                    comparisons = comparisons + 1
                    //this.setState({ nrOfComparisions: comparisons })

                    //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
                    if (twoWomen > 59 && oneWomen < 60) {
                        lessWomen = twoWomen
                        moreWomen = oneWomen
                        firsAvgSalary = twoAvgSalary
                        secondAvgSalary = oneAvgSalary
                        nrOfEmp = twoNrOfEmp
                        nrOfEmpTwo = oneNrOfEmp
                        firstGroup = group2
                        secondGroup = group1
                        firstExp = twoAvgExp
                        secondExp = oneAvgExp
                        firstAge = twoAvgAge
                        secondAge = oneAvgAge
                        firstEqualent = twoEqualentGroup
                        secondEqualent = oneEqualentGroup
                        firstCity = twoAverageCity
                        secondCity = oneAverageCity
                        firstSpridning = twoSpridningskvot
                        secondSpridning = oneSpridningskvot
                    }

                    //Only compare if there is a need for it. If no need to compare the groups, because male group is in higher group or has lower salary, then dont compare group
                    if (firstEqualent < secondEqualent || firsAvgSalary > secondAvgSalary) {
                        //Do nothing
                    } else {

                        //Run auto analysis (from separate file)
                        //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "Yes");
                        const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");
                        //create object for analyticList
                        let type = (firstEqualent - secondEqualent) === 0 ? "equalent" : "hierarchical"
                        let group = {
                            "Group1": firstGroup,
                            "avgWomen1": lessWomen,
                            "emp1": nrOfEmp,
                            "avgSal1": firsAvgSalary,
                            "demands1": firstEqualent,
                            "Group2": secondGroup,
                            "avgWomen2": moreWomen,
                            "emp2": nrOfEmpTwo,
                            "avgSal2": secondAvgSalary,
                            "demands2": secondEqualent,
                            "Analys": autoAnalys[0], //<-- Måste skapa så skickar för robot här
                            //"Analys": autoAnalys, // <-- For testing
                            "AllResult": autoAnalys[4], //Used to get all result, example citydiff, agediff etc
                            "TotResult": autoAnalys[4] !== undefined ? autoAnalys[4].total : undefined, //The overall result for all
                            "reason": "",
                            "type": type,
                            "oneSpridningskvot": firstSpridning,
                            "twoSpridningskvot": secondSpridning
                        }
                        if (group.TotResult !== undefined && group.Analys !== "ignore") {
                            allComparisons.push(group)
                        }
                    } //End of if statement if need to run function or not
                } //End of if first group is women dominated if statement
            } //End of first for loop
        } //End of second for loop
        //Divide all women dominated groups togheter with the male dominated groups that needs to be looked at
        let nestedGroups = allComparisons.reduce(function (r, a) {
            r[a.Group1] = r[a.Group1] || [];
            r[a.Group1].push(a);
            return r;
        }, Object.create(null));
        let nestedGroupsArray = Object.keys(nestedGroups).map((key) => nestedGroups[key]); //Makes nestedgroups an array


// 2. Rendera datan

let loopTables = []
for (let i = 0; i < nestedGroupsArray.length; i++) {
    let allColumns = []

    //1. first, push the column titles
    allColumns.push(new TableRow({
        children: [
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: language === "sv" ? `Grupp` : lang[language].group,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: language === "sv" ? "Kvinnor" : lang[language].women,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: (language === "sv" ? "Ans" : lang[language].empMinimal) + ".",
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: language === "sv" ? "Spridning" : lang[language].spread,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: language === "sv" ? "Krav" : lang[language].requirementsShort,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: 0,
                        after: 0,
                    },
                    children: [
                        new TextRun({
                            text: language === "sv" ? "Löneskillnad" : lang[language].salDiffShort,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
        ],
    })
    )


    //2. then, push the women dominated group for the title
    allColumns.push(new TableRow({
        children: [
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: tableSpace,
                        after: tableSpace,
                    },
                    children: [
                        new TextRun({
                            text: `${nestedGroupsArray[i][0].Group1}`,
                            font: "Arial",
                            bold: true,
                            size: 21
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: tableSpace,
                        after: tableSpace,
                    },
                    children: [
                        new TextRun({
                            text: `${nestedGroupsArray[i][0].avgWomen1}%`,
                            font: "Arial",
                            bold: true,
                            size: 21
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: tableSpace,
                        after: tableSpace,
                    },
                    children: [
                        new TextRun({
                            text: `${nestedGroupsArray[i][0].emp1}`,
                            font: "Arial",
                            bold: true,
                            size: 21
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: tableSpace,
                        after: tableSpace,
                    },
                    children: [
                        new TextRun({
                            text: `${nestedGroupsArray[i][0].oneSpridningskvot}`,
                            font: "Arial",
                            bold: true,
                            size: 21
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
            new TableCell({
                children: [new Paragraph({
                    spacing: {
                        before: tableSpace,
                        after: tableSpace,
                    },
                    children: [
                        new TextRun({
                            text: `${nestedGroupsArray[i][0].demands1}`,
                            font: "Arial",
                            bold: true,
                            size: 21
                        }),
                    ]
                }),],
                columnSpan: 1,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
        ],
    })
    )
    //3. Then push all groups that should be compared
    for (let a = 0; a < nestedGroupsArray[i].length; a++) {
        let borderTop = { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" }
        if (a === 0) { borderTop = { style: BorderStyle.SINGLE, size: 2, color: "000000" } }
        allColumns.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][a].Group2}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][a].avgWomen2}%`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][a].emp2}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][a].twoSpridningskvot}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][a].demands2}`,
                                font: "Arial"
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                               // text: `+${Math.round((((nestedGroupsArray[i][a].avgSal2 / nestedGroupsArray[i][a].avgSal1) * 100) - 100) * 10) / 10}%`,
                                text: `${(((nestedGroupsArray[i][a].avgSal2 - nestedGroupsArray[i][a].avgSal1) / nestedGroupsArray[i][a].avgSal2) * 100).toFixed(1) }%`,
                                font: "Arial",
                                color: "d29934",
                                bold: true
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: borderTop
                    },
                }),
            ],
        })
        )
    }
    //4. Push empty row at end to create space
    allColumns.push(new TableRow({
        children: [
            new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: ``,
                            font: "Arial",
                            size: 15,
                            bold: true,
                            italics: true,
                            color: "#333333",
                        }),
                    ]
                }),],
                columnSpan: 6,
                borders: {
                    right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                },
            }),
        ],
    })
    )

    //5. Then create the table and push it to an array which we return
    let table = new Table({
        columns: 6,
        width: 0,
        columnWidths: [4000, 1100, 900, 1100, 1100, 1200],  // total page width is 9638 DXA for A4 portrait
        layout: TableLayoutType.FIXED,
        rows: allColumns
    })
    loopTables.push(table)
}
if (nestedGroupsArray.length === 0) {
    return [new Table({
        columns: 6,
        width: 0,
        columnWidths: [4000, 1100, 1100, 1100, 1200, 1100],  // total page width is 9638 DXA for A4 portrait
        layout: TableLayoutType.FIXED,
        rows: [new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Inga kvinnodominerande arbeten har haft lägre lön än icke kvinnodominerade arbeten med lika eller högre krav." : lang[language].eqvFive,
                                font: "Arial",
                                color: "#000000",
                            }),
                        ]
                    }),],
                    columnSpan: 6,
                }),
            ],
        })
        ]
    })]
} else {
    return loopTables
}

    }
    return calculate();
}
