import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    UnderlineType,
} from "docx";
import lang from '../../Language/language.js';
let language = localStorage.getItem('language') ?? 'sv';

let factorDescriptions = language === "sv" ? [
    {
        question: "utbildning",
        category: "Knowledge",
        instructions: "- Utbildning\n- Arbetslivserfarenhet",
        focus: "Sammanfattande bedömning över hur stora krav tjänsten ställer på utbildning och arbetslivserfarenhet.",
        level1: "Inga eller låga krav på utbildning och relevant arbetslivserfarenhet.",
        level2: "Kräver eftergymnasial utbildning eller relevant yrkeserfarenhet.",
        level3: "Kräver hög kunskap. Förvärvad genom hög utbildning eller medelhög utbildning med längre relevant arbetslivserfarenhet.",
        level4: "Kräver mycket specialiserad eller bred kunskap. Förvärvard genom hög utbildning och lång relevant arbetslivserfarenhet.",
    },
    {
        question: "problem",
        category: "Knowledge",
        instructions: "- Hantera uppkommande problem\n- Analysering\n- Kreativitet",
        focus: "- Hur ofta problem förekommer och måste lösas\n- Självständigheten i arbetet. Finns det hjälp att tillgå?\n- Komplexiteten i uppgifterna\n- Om måste sätta sig in i nya områden till följd av snabb utveckling",
        level1: "Enkla och ofta rutinmässiga uppgifter där problemlösning förekommer i mindre utsträckning. För lösningar finns oftast kända tillvägagångssätt.",
        level2: "Kräver viss analys och kreativitet. Komplicerade problem förekommer men ska diskuteras med överordnad innan beslut.",
        level3: "Problemlösning vanligt förekommande. Självständig analys, nytänkande, utvärdering av alternativ inför beslut. Lösningar är sällan givna eller definierade.",
        level4: "Problemlösning är stor del i arbetet. Varierade, komplexa problem. Kräver kreativa lösningar, ofta nya angreppssätt, kvalificerad kunskapsinhämtning och stor självständighet i beslut.",
    },
    {
        question: "sociala",
        category: "Knowledge",
        instructions: "- Kommunikation\n- Samarbete\n- Service",
        focus: "- Hur stor andel i arbetet som kräver sociala färdigheter\n- Om situationerna ofta är krävande\n- Om det ofta förekommer intressemotsättningar",
        level1: "Inga krav på särskilda sociala färdigheter.",
        level2: "Färdigheter i kommunikation och samarbete både internt och externt. Kontinuerliga dialoger. Kan innehålla intressemotsättningar.",
        level3: "Kommunikation är en betydande del i arbetet. Ofta krävande situationer och intressemotsättningar. Kunna vägleda och argumentera.",
        level4: "Sociala färdigheter avgörande för arbetet. Svåra och komplicerade kontakter med personer/grupper. Ofta stora intressemotsättningar eller svåra situationer.",
    },
    {
        question: "personal",
        category: "Responsibility",
        instructions: "- Personalansvar\n- Arbetsledning",
        focus: "- Antal anställda\n- Om en större del av arbetet upptas av arbetsledning\n- Självständigheten i ansvaret",
        level1: "Har inget personalansvar eller ytterst få medarbetare där arbetsledning är en mycket liten del av arbetet.",
        level2: "Ansvarar för en mindre grupp anställda. Arbetsledning är en viss del av arbetet.",
        level3: "Ansvarar för ett betydande antal anställda. Arbetsledning är en stor del av arbetet.",
        level4: "Övergripande chefsansvar för stora personalgrupper eller avdelningar. Ofta arbetsledning av andra chefer.",
    },
    {
        question: "verksamhet",
        category: "Responsibility",
        instructions: "- Planering\n- Utveckling\n- Resultat",
        focus: "- Om arbetet är av långsiktig betydelse för organisationen\n- Hur starkt resultatet påverkar organisationen\n- Självständigheten i ansvaret",
        level1: "Riktlinjer skapat av någon annan finns att följa. Inget eller litet ansvar för planering, utveckling och resultat. Kan delta i planering för egna gruppen.",
        level2: "Visst ansvar för planering, utveckling eller resultat. Ej nödvändigtvis arbetsledande funktion. Självständigt ansvar för arbetssätt, tidsplanering eller budget.",
        level3: "Övergripande och strategiskt ansvar för planering, utveckling och resultat. Kan styra och förändra processer inom egna verksamhetsområdet.",
        level4: "Övergripande affärsmässigt helhetsansvar för en/flera grupper eller verksamhetsområden. Utformar och påverkar varor/tjänster samt normer och riktlinjer som styr."
    },
    {
        question: "manniskor",
        category: "Responsibility",
        instructions: "- Kunder\n- Patienter\n- Ansvar för andras hälsa eller säkerhet\n- Påverkan på människor genom beslut",
        focus: "- Självständigheten i ansvaret\n- Hur mycket ansvaret för människor dominerar i arbetet\n- Hur starkt arbetet påverkar andra människor\n- Om känslig/konfidentiell information om människor tillhandahålls",
        level1: "Ansvaret är begränsat till normal hänsyn och omtanke för andras välbefinnande.",
        level2: "Ett visst ansvar för andras hälsa, utveckling eller säkerhet. Innebär ofta att påverka andra genom att inom ramen följa regler och anvisningar. Hantering av känslig information kan förekomma.",
        level3: "Betydande ansvar/påverkan på andras hälsa, utveckling eller säkerhet. Självständiga beslut som påverkar andra människor är vanligt.",
        level4: "Mycket stora krav att självständigt bedöma och fatta avgörande beslut som påverkar andras hälsa, utveckling eller säkerhet. Medför ofta stor påverkan på individer/grupper.",
    },
    {
        question: "fysiska",
        category: "Effort",
        instructions: "- Tungt arbete\n- Repetitiva arbetsmoment\n- Obekväma arbetsställningar\n- Risk för skada",
        focus: "- Hur påfrestande det fysiska arbetet är\n- Kombinationseffekter, till exempel om tungt arbete sker i kombination med obekväm arbetsställning\n- Långt repetetivt arbete kan vara lika fysiskt ansträngande som tunga moment under kortare perioder\n- Tillgång till hjälpmedel\n- Arbetstakt, hur snabbt det fysiska arbetet måste utföras",
        level1: "Inga särskilda krav på fysisk ansträngning. Risk för skada är mycket liten.",
        level2: "I perioder kan det krävas fysisk ansträning. Risk för skada är liten.",
        level3: "Fysisk ansträngning sker dagligen. Långt repetetivt arbete eller tyngre moment under kortare perioder. Viss risk för skada.",
        level4: "Fysisk ansträngning är en stor del av arbetet. Obekväma arbetställningar förekommer. Stor risk för skada.",
    },
    {
        question: "psykiska",
        category: "Effort",
        instructions: "- Känslomässig ansträngning \n- Enformighet \n- Tillgänglighet \n- Stress",
        focus: "- Hur påfrestande den psykiska ansträngningen är \n- Sker ofta möte med arga, sjuka eller svårhanterliga människor? \n- Krav på tillgänglighet, tex utanför ordinarie arbetstid eller att andra uppgifter ofta blir lidande \n- Hårt styrt och enformigt arbete som kräver noggran koncentration kan vara mycket påfrestande \n- Hur stor andel arbetsbelastning och stress",
        level1: "Låga krav på psykisk ansträngning. Psykiskt påfrestande situationer inträffar sällan.",
        level2: "Psykiskt påfrestande situationer inträffar ibland. Hög arbetsbelastning och tidspress förekommer i perioder. ",
        level3: "Psykisk och känslomässig ansträngning förekommer ofta.",
        level4: "Mycket höga krav på psykisk och känslomässig ansträngning över tid. Höga krav på tillgänglighet, koncentration, tidspress och påfrestande relationer.",
    }
] : lang[language].originalFactors

/*
What to use and why: ---
- whatLevelDesc, 
- factor descriptions and levels from table factorsOriginal (if original)
- factor descriptions and levels from table factors (if edited)
- standard descriptions and levels (for if whatLevelDesc are "first" for example)
- whatEvaluation, so we know if we are using edited, standard or extern (extern is handled outside of this function)
- whatCategory, so we know what category table that should be generated

How levels works overview: ---
If the user has add levels for every factor except one, no factors will be shown in the documentation. 
The system also looks for level1. If that is filled in but the rest of the levels is not, then it will still count as the factor has levels.
*/

export const viktningTab1 = (vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, whatCategory) => {
    let showLevels = ""
    let factors = ""
    if (whatLevelDesc === null) { showLevels = "noLevels"; factors = factorDescriptions }

    if (whatLevelDesc === "oldStandard") {
        if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
            //Check if there is 8 factors saved in originalFactors. If not, show no levels.
            // If there is 8 factors, check if each one has a level description for level1. If not, show no levels
            if (originalFactors.length === 8) {
                for (let item of originalFactors) {
                    if (item.level1 === null || item.level1 === "") {
                        showLevels = "noLevels"
                        break;
                    } else {
                        showLevels = "originalLevels" //<-- maybe change what this should be
                    }
                }
            } else { showLevels = "noLevels" }
            //Now merge descriptions. focus and levels for the factor to be rendered
            factors = factorDescriptions.map(factorDesc => {
                const matchingFactor = originalFactors.find(factor => factor.question === factorDesc.question);
                if (matchingFactor) {
                    return {
                        ...factorDesc,
                        instructions: matchingFactor.instructions,
                        focus: matchingFactor.focus,
                        level1: matchingFactor.level1,
                        level2: matchingFactor.level2,
                        level3: matchingFactor.level3,
                        level4: matchingFactor.level4,
                    };
                }
                return factorDesc;
            });
        }
        if (whatEvaluation === "edited") {
            //Here we always have all factors saved. Check if each one has a level description for level1. If not, show no levels
            for (let item of editedFactors) {
                if (item.level1 === null || item.level1 === "") {
                    showLevels = "noLevels"
                    break;
                } else {
                    showLevels = "editedLevels" //<-- maybe change what this should be
                }
            }
            factors = editedFactors
        }
    }
    //If whatLevelDesc = "first", we know that this means that it has the original levels by default
    if (whatLevelDesc === "first") { showLevels = "originalLevels"; factors = factorDescriptions }
    //If whatLevelDesc = "standard", the levels might have changed or been removed. 
    if (whatLevelDesc === "standard") {
        if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
            //Check if there is 0 saved factors for original, if so we just use defaul values
            if (originalFactors.length === 0) { showLevels = "originalLevels"; factors = factorDescriptions }
            //Check if there is 8 factors saved in originalFactors. If there is, check each one if they have null or "" for level1. If so then show no levels.
            if (originalFactors.length === 8) {
                for (let item of originalFactors) {
                    if (item.level1 === null || item.level1 === "") {
                        showLevels = "noLevels"
                        break;
                    } else {
                        showLevels = "originalLevels" //<-- maybe change what this should be
                    }
                }
                //Now merge descriptions. focus and levels for the factor to be rendered
                factors = originalFactors
            }
            //If there is not 8 factors, loop and check if the once who are there have null or "". If so then show no levels. If not, then use those level descriptions and then use default for the rest.
            if (originalFactors.length !== 8 && originalFactors.length !== 0) {
                for (let item of originalFactors) {
                    if (item.level1 === null || item.level1 === "") {
                        showLevels = "noLevels"
                        break;
                    } else {
                        showLevels = "originalSome"
                    }
                }
                //Now merge descriptions. focus and levels for the factor to be rendered
                factors = factorDescriptions.map(factorDesc => {
                    const matchingFactor = originalFactors.find(factor => factor.question === factorDesc.question);
                    if (matchingFactor) {
                        return {
                            ...factorDesc,
                            instructions: matchingFactor.instructions,
                            focus: matchingFactor.focus,
                            level1: matchingFactor.level1,
                            level2: matchingFactor.level2,
                            level3: matchingFactor.level3,
                            level4: matchingFactor.level4,
                        };
                    }
                    return factorDesc;
                });
            }
        }
        if (whatEvaluation === "edited") {
            //Here we always have all factors saved. Check if each one has a level description for level1. If not, show no levels
            for (let item of editedFactors) {
                if (item.level1 === null || item.level1 === "") {
                    showLevels = "noLevels"
                    break;
                } else {
                    showLevels = "editedLevels" //<-- maybe change what this should be
                }
            }
            factors = editedFactors
        }
    }

    // Above is setting descriptions, factors and levels. Now categorise by category 
    let knowledge = []
    let responsibility = []
    let effort = []
    for (let i = 0; i < factors.length; i++) {
        if (factors[i].category === "Knowledge") { knowledge.push(factors[i]) }
        if (factors[i].category === "Responsibility") { responsibility.push(factors[i]) }
        if (factors[i].category === "Effort") { effort.push(factors[i]) }
    }

    // -------- Render if levels should not be shown  ----------
    if (showLevels === "noLevels") {
        let rows = []
        let createTables = (category) => {
            let whatCategory = category[0].category === "Knowledge" ? language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp : category[0].category === "Responsibility" ? language === "sv" ? "Ansvar" : lang[language].setResp : language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort

            // First push header rows
            rows.push(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: whatCategory,
                                        bold: true,
                                        color: "000000",
                                        size: 23,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                            columnSpan: 3
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            shading: {
                                fill: "f3f3f3",
                            },
                            children: [new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Område" : lang[language].factor,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                        }),
                        new TableCell({
                            shading: {
                                fill: "f3f3f3",
                            },
                            children: [new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Viktning" : lang[language].weightning,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                        }),
                        new TableCell({
                            shading: {
                                fill: "f3f3f3",
                            },
                            children: [new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: language === "sv" ? "Bedömning" : lang[language].assessment,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                        }),
                    ],
                }),
            )

            //Then create rows for each factor
            for (let item of category) {
                let itemQuestion = item.question
                let weight = ""
                if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                        if (item.question === "utbildning") { itemQuestion = (language === "sv" ? "Utbildning & Erfarenhet" : lang[language].eduAndExp); weight = vikt.education } 
                        if (item.question === "problem") { itemQuestion = (language === "sv" ? "Problemlösning" : lang[language].dprob) ; weight = vikt.problemsolving }
                        if (item.question === "sociala") { itemQuestion = (language === "sv" ? "Sociala färdigheter" : lang[language].dsoc); weight = vikt.socialSkills }
                        if (item.question === "personal") { itemQuestion = (language === "sv" ? "Personal" : lang[language].dPer); weight = vikt.personnel } 
                        if (item.question === "verksamhet") { itemQuestion = (language === "sv" ? "Verksamhet" : lang[language].dOpe) ; weight = vikt.operation }
                        if (item.question === "manniskor") { itemQuestion = (language === "sv" ? "Människor" : lang[language].dPeo) ; weight = vikt.people }
                        if (item.question === "fysiska") { itemQuestion = (language === "sv" ? "Fysiska" : lang[language].dPhy) ; weight = vikt.physical }
                        if (item.question === "psykiska") { itemQuestion = (language === "sv" ? "Psykiska" : lang[language].dPsy) ; weight = vikt.psychological }
                }
                if (whatEvaluation === "edited") { weight = item.weight }
                rows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: itemQuestion,
                                            //  text: itemQuestion + "hejhejhej" + item.question,
                                            font: "Arial"
                                        }),
                                    ]
                                }),],
                            }),
                            new TableCell({
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: `${weight} %`,
                                            font: "Arial"
                                        }),
                                    ]
                                }),],
                            }),
                            new TableCell({
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: (language === "sv" ? "Bedömning av" : lang[language].assessmentOf) + ":",
                                            font: "Arial",
                                            underline: {
                                                type: UnderlineType.SINGLE,
                                            },
                                        }),
                                    ]
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.instructions,
                                            font: "Arial"
                                        }),
                                    ]
                                }),
                                new Paragraph(""),
                                new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: (language === "sv" ? "I beaktande vid bedömning" : lang[language].assessmentOfCons) + ":",
                                            font: "Arial",
                                            underline: {
                                                type: UnderlineType.SINGLE,
                                            },
                                        }),
                                    ]
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.focus,
                                            font: "Arial"
                                        }),
                                    ]
                                }),
                                ],
                            })
                        ],
                    }),
                )
            }
        }
        whatCategory === "knowledge" && createTables(knowledge)
        whatCategory === "responsibility" && createTables(responsibility)
        whatCategory === "effort" && createTables(effort)

        return new Table({
            // rows: 5,
            columns: 3,
            width: 0, // AUTO
            columnWidths: [2500, 1000, 6138], // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: rows,
        })
    }

    // -------- Render if levels should be shown ----------
    if (showLevels !== "noLevels") {
        let rows = []
        let createTables = (category) => {
            let whatCategory = category[0].category === "Knowledge" ? language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp : category[0].category === "Responsibility" ? language === "sv" ? "Ansvar" : lang[language].setResp : language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort

            //First create and push the category row
            rows.push(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                spacing: {
                                    before: tableSpace,
                                    after: tableSpace,
                                },
                                children: [
                                    new TextRun({
                                        text: whatCategory,
                                        bold: true,
                                        color: "000000",
                                        size: 23,
                                        font: "Arial"
                                    }),
                                ]
                            }),],
                            columnSpan: 2
                        })
                    ],
                }),
            )

            //then create and push the rows for each factor
            for (let item of category) {
                let itemQuestion = item.question
                let weight = ""
                if (whatEvaluation === "original" || whatEvaluation === null || whatEvaluation === "") {
                    if (item.question === "utbildning") { itemQuestion = (language === "sv" ? "Utbildning & Erfarenhet" : lang[language].eduAndExp); weight = vikt.education } 
                    if (item.question === "problem") { itemQuestion = (language === "sv" ? "Problemlösning" : lang[language].dprob) ; weight = vikt.problemsolving }
                    if (item.question === "sociala") { itemQuestion = (language === "sv" ? "Sociala färdigheter" : lang[language].dsoc); weight = vikt.socialSkills }
                    if (item.question === "personal") { itemQuestion = (language === "sv" ? "Personal" : lang[language].dPer); weight = vikt.personnel } 
                    if (item.question === "verksamhet") { itemQuestion = (language === "sv" ? "Verksamhet" : lang[language].dOpe) ; weight = vikt.operation }
                    if (item.question === "manniskor") { itemQuestion = (language === "sv" ? "Människor" : lang[language].dPeo) ; weight = vikt.people }
                    if (item.question === "fysiska") { itemQuestion = (language === "sv" ? "Fysiska" : lang[language].dPhy) ; weight = vikt.physical }
                    if (item.question === "psykiska") { itemQuestion = (language === "sv" ? "Psykiska" : lang[language].dPsy) ; weight = vikt.psychological }
                }
                if (whatEvaluation === "edited") { weight = item.weight }

                //Create the correct nr of levels
                let levels = []
                if (item.levels === undefined) { //If undefined it means this is original which is 4 levels
                    levels.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `1. ${item.level1}`,
                                    font: "Arial",
                                }),
                            ]
                        }),
                        new Paragraph({}),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `2. ${item.level2}`,
                                    font: "Arial",
                                }),
                            ]
                        }),
                        new Paragraph({}),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `3. ${item.level3}`,
                                    font: "Arial",
                                }),
                            ]
                        }),
                        new Paragraph({}),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `4. ${item.level4}`,
                                    font: "Arial",
                                }),
                            ]
                        }),
                    )
                }
                else {
                    //Loopa antalet gånger som vi har item.levels
                    for (let i = 1; i <= item.levels; i++) {
                        let level = `level${i}`;
                        let levelText = item[level]
                        if (levelText === null) { levelText = "" }
                        if (i === 1) { //Skip first paragraph
                            levels.push(
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `${i}. ${levelText}`,
                                            font: "Arial",
                                        }),
                                    ]
                                }),
                            )
                        } else {
                            levels.push(
                                new Paragraph({}),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `${i}. ${levelText}`,
                                            font: "Arial",
                                        }),
                                    ]
                                }),
                            )
                        }
                    }
                }

                rows.push(
                    new TableRow({
                        children: [
                            new TableCell({
                                shading: {
                                    fill: "f3f3f3",
                                },
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: itemQuestion,
                                            font: "Arial",
                                            bold: true,
                                        }),
                                        new TextRun({
                                            text: " | " + (language === "sv" ? "Viktning" : lang[language].weightning) + " " + weight + "%",
                                            font: "Arial",

                                        }),
                                    ]
                                }),],
                            }),
                            new TableCell({
                                shading: {
                                    fill: "f3f3f3",
                                },
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc,
                                            font: "Arial"
                                        }),
                                    ]
                                }),],
                            })
                        ],
                    }),
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: (language === "sv" ? "Bedömning av" : lang[language].assessmentOf) + ":",
                                            font: "Arial",
                                            underline: {
                                                type: UnderlineType.SINGLE,
                                            },
                                        }),
                                    ]
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.instructions,
                                            font: "Arial"
                                        }),
                                    ]
                                }),
                                new Paragraph({
                                    text: "",
                                    font: "Arial"
                                }),
                                new Paragraph({
                                    spacing: {
                                        before: tableSpace,
                                        after: tableSpace,
                                    },
                                    children: [
                                        new TextRun({
                                            text: (language === "sv" ? "I beaktande vid bedömning" : lang[language].assessmentOfCons) + ":",
                                            font: "Arial",
                                            underline: {
                                                type: UnderlineType.SINGLE,
                                            },
                                        }),
                                    ]
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.focus,
                                            font: "Arial"
                                        }),
                                    ]
                                }),
                                ],
                            }),
                            new TableCell({
                                children: levels,
                            }),

                        ],
                    }),
                )
            }
            return;
        }
        whatCategory === "knowledge" && createTables(knowledge)
        whatCategory === "responsibility" && createTables(responsibility)
        whatCategory === "effort" && createTables(effort)

        return new Table({
            // rows: 5,
            columns: 2,
            width: 0, // AUTO
            columnWidths: [4250, 5388], // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: rows,
        })
    } // End of showLevels === "noLevels"


}