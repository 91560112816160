import { saveAs } from 'file-saver';
import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    Footer
} from "docx";
import { viktningTab1 } from '../Dokumentera/WordFunctions/ViktningTab1.js';
import lang from '../Language/language.js';

export const exportEvaluationWord = async (vikt, whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc) => {
    let language = localStorage.getItem('language') ?? 'sv'
    //Group edited factors
    let knowledge = []
    let responsibility = []
    let effort = []
    for (let i = 0; i < editedFactors.length; i++) {
        if(editedFactors[i].category === "Knowledge") {knowledge.push(editedFactors[i])}
        if(editedFactors[i].category === "Responsibility") {responsibility.push(editedFactors[i])}
        if(editedFactors[i].category === "Effort") {effort.push(editedFactors[i])}
    }
    
    //Settings
    const fontSize = 23
    const fontTitleSize = 34
    const subTitleSize = 28
    const tableSpace = 80
    const tableSpaceBig = 120

    const doc = new Document({
        sections: [
            {
               /* properties: {
                    type: SectionType.NEXT_PAGE,
                },*/
               
                children: [

                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "knowledge") : "",
                  
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "responsibility") : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "effort") : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",

                   
                ]
            },
        ]
    });
    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "Lönekartlaggning.docx");
    });
}