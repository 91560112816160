//This is used to create jitter (small differences) for items in chart that has identical values for years and salary
export const jitter = async (womensList, mensList, jitterValue) => {
  //Create a shallow copy of the two groups to use when finding duplicates
  const bothMenAndWomen = mensList.concat(womensList);
  //get list of all duplicated values (gets the duplicates only, not the original values, )
  const duplicates = bothMenAndWomen.filter((val, i, self) => self.findIndex(k => k.x == val.x && k.y == val.y) != i);
  //Sort it to make if statements work
  duplicates.sort(function(a, b) {
    return a.y - b.y;
});
  //Add the jitter
  let yValue = ""
  let xValue = ""
  for (let i = 0; i < duplicates.length; i++) {
    if ( duplicates[i].y === yValue) {
      if (xValue === 0.1) {
        duplicates[i].x = duplicates[i].x + 0.2
        xValue = 0.2
      } else if (xValue === 0.2) {
        duplicates[i].x = duplicates[i].x + 0.3
        xValue = 0.3
      } else if (xValue === 0.3) {
        duplicates[i].x = duplicates[i].x + 0.4
        xValue = 0.4
      } else if (xValue === 0.4) {
        duplicates[i].x = duplicates[i].x + 0.49
        xValue = 0.5
      } 
      else if (xValue > 0.49) {
        duplicates[i].x = duplicates[i].x + (Math.floor(Math.random() * (49 - 12 + 1)) + 15) /100
        xValue = 0.5
      } 
  } else {
    duplicates[i].x = duplicates[i].x + 0.1
    yValue =  duplicates[i].y 
    xValue = 0.1
  }
  }
  return bothMenAndWomen
}