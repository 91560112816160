
/*const families = [
     //{ssyk: 8211, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
     {ssyk: 8211, family: "Supply Chain", cat2: "abc", cat3: ""},
       {ssyk: 1321, family: "Supply Chain", cat2: "abc", cat3: "a"},
       {ssyk: 3323, family: "Supply Chain", cat2: "ccc", cat3: "aa"},
    // {ssyk: 3323, family: "Supply Chain", cat2: "Inköpare", cat3: ""},
    // {ssyk: 1321, family: "Supply Chain", cat2: "", cat3: ""},
     {ssyk: 3313, family: "Ekonomi", cat2: "", cat3: ""},
     {ssyk: 3112, family: "Ingenjörskap", cat2: "Byggteknik", cat3: "test"},
    ];
   
    export default families;*/

    const families = [
        {ssyk: 1111, family: "Politiker", cat2: "", cat3: ""},
        {ssyk: 1112, family: "Politiker", cat2: "", cat3: ""},
        {ssyk: 1113, family: "Politiker", cat2: "", cat3: ""},

        {ssyk: 1120, family: "Företagsledning", cat2: "", cat3: ""},

        {ssyk: 1211, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 1212, family: "Ekonomi", cat2: "", cat3: ""},

        {ssyk: 1221, family: "HR", cat2: "", cat3: ""},
        {ssyk: 1222, family: "HR", cat2: "", cat3: ""},

        {ssyk: 1230, family: "Okategoriserad", cat2: "", cat3: ""},

        {ssyk: 1241, family: "Marknad", cat2: "", cat3: ""},
        {ssyk: 1242, family: "Marknad", cat2: "", cat3: ""},

        {ssyk: 1251, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 1252, family: "Sälj", cat2: "", cat3: ""},

        {ssyk: 1291, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 1292, family: "Administration & Sekreterare", cat2: "", cat3: ""},

        {ssyk: 1311, family: "IT", cat2: "", cat3: ""},
        {ssyk: 1312, family: "IT", cat2: "", cat3: ""},

        {ssyk: 1321, family: "Supply Chain", cat2: "", cat3: ""},
        {ssyk: 1322, family: "Supply Chain", cat2: "", cat3: ""},

        {ssyk: 1331, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 1332, family: "Forskning och utveckling", cat2: "", cat3: ""},

        {ssyk: 1341, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 1342, family: "Byggnation", cat2: "", cat3: ""},

        {ssyk: 1351, family: "Fastighet", cat2: "", cat3: ""},
        {ssyk: 1352, family: "Fastighet", cat2: "", cat3: ""},
        {ssyk: 1361, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 1362, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 1371, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 1372, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 1380, family: "Lantbruk", cat2: "", cat3: ""},
        {ssyk: 1411, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 1412, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 1421, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 1422, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 1491, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 1492, family: "Utbildning", cat2: "", cat3: ""},

        {ssyk: 1511, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 1512, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 1521, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 1522, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 1531, family: "Socialt och kurativt arbete", cat2: "äldreomsorg", cat3: ""},
        {ssyk: 1532, family: "Socialt och kurativt arbete", cat2: "äldreomsorg", cat3: ""},
        {ssyk: 1540, family: "Religion", cat2: "", cat3: ""},
        {ssyk: 1591, family: "Okategoriserad", cat2: "", cat3: ""},
        {ssyk: 1592, family: "Okategoriserad", cat2: "", cat3: ""},
        {ssyk: 1611, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 1612, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 1711, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 1712, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 1721, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 1722, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 1731, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 1732, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 1741, family: "Idrott", cat2: "", cat3: ""},
        {ssyk: 1742, family: "Idrott", cat2: "", cat3: ""},
        {ssyk: 1791, family: "Okategoriserad", cat2: "", cat3: ""},
        {ssyk: 1792, family: "Okategoriserad", cat2: "", cat3: ""},
        {ssyk: 2111, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2112, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2113, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2114, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2121, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2122, family: "Forskning och utveckling", cat2: "", cat3: ""},
        {ssyk: 2131, family: "", cat2: "", cat3: ""},
        {ssyk: 2132, family: "", cat2: "", cat3: ""},
        {ssyk: 2133, family: "", cat2: "", cat3: ""},
        {ssyk: 2134, family: "", cat2: "", cat3: ""},
        {ssyk: 2135, family: "", cat2: "", cat3: ""},
        {ssyk: 2141, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2142, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2143, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2144, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2145, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2146, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2149, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 2161, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 2162, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 2163, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 2164, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 2171, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2172, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2173, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2179, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2181, family: "Miljö", cat2: "", cat3: ""},
        {ssyk: 2182, family: "Miljö", cat2: "", cat3: ""},
        {ssyk: 2183, family: "Miljö", cat2: "", cat3: ""},
        {ssyk: 2211, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2212, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2213, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2219, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2221, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2222, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2223, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2224, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2225, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2226, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2227, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2228, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2231, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2232, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2233, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2234, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2235, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2239, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2241, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2242, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2250, family: "Djurvård", cat2: "", cat3: ""},
        {ssyk: 2260, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},

        {ssyk: 2271, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2272, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2273, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2281, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2282, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2283, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2284, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2289, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 2311, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2312, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2313, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2314, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2319, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2320, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2330, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2341, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2342, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2343, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2351, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2352, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2359, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 2411, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2412, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2413, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2414, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2415, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2419, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 2421, family: "", cat2: "", cat3: ""},

        {ssyk: 2422, family: "", cat2: "", cat3: ""},
        {ssyk: 2423, family: "", cat2: "", cat3: ""},
        {ssyk: 2431, family: "Marknad", cat2: "", cat3: ""},
        {ssyk: 2432, family: "Marknad", cat2: "", cat3: ""},
        {ssyk: 2511, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2512, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2513, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2514, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2515, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2516, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2519, family: "IT", cat2: "", cat3: ""},
        {ssyk: 2611, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2612, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2613, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2614, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2615, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2619, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 2621, family: "", cat2: "", cat3: ""},
        {ssyk: 2622, family: "", cat2: "", cat3: ""},
        {ssyk: 2623, family: "", cat2: "", cat3: ""},
        {ssyk: 2641, family: "Media- och kommunikation", cat2: "", cat3: ""},
        {ssyk: 2642, family: "Media- och kommunikation", cat2: "", cat3: ""},
        {ssyk: 2643, family: "Media- och kommunikation", cat2: "", cat3: ""},
        {ssyk: 2651, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2652, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2653, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2654, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2655, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 2661, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 2662, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 2663, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 2669, family: "Socialt och kurativt arbete", cat2: "", cat3: ""},

        {ssyk: 2671, family: "Religion", cat2: "", cat3: ""},
        {ssyk: 2672, family: "Religion", cat2: "", cat3: ""},
        {ssyk: 3111, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3112, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3113, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3114, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3115, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3116, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3117, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3119, family: "Ingenjörskap", cat2: "", cat3: ""},
        {ssyk: 3121, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 3122, family: "Byggnation", cat2: "", cat3: ""},
        /* FEL NEDAN */
        {ssyk: 3151, family: "Sjöfart", cat2: "", cat3: ""},
        {ssyk: 3152, family: "Sjöfart", cat2: "", cat3: ""},
        {ssyk: 3153, family: "Pilot", cat2: "", cat3: ""},
        {ssyk: 3154, family: "Säkerhet & Bevakning", cat2: "", cat3: ""},
        {ssyk: 3155, family: "Ingenjörskap", cat2: "Flygteknik", cat3: ""},

   
        {ssyk: 3211, family: "Ingenjörskap", cat2: "Medicinteknik", cat3: ""},
        {ssyk: 3212, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 3213, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 3214, family: "Ingenjörskap", cat2: "Medicinteknik", cat3: ""},
        {ssyk: 3215, family: "Ingenjörskap", cat2: "Medicinteknik", cat3: ""},

        {ssyk: 3230, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 3240, family: "Djurvård", cat2: "", cat3: ""},
        {ssyk: 3250, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 3311, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 3312, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 3313, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 3314, family: "Ekonomi", cat2: "", cat3: ""},
       
        {ssyk: 3321, family: "Försäkring", cat2: "", cat3: ""},
        {ssyk: 3322, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 3323, family: "Supply chain", cat2: "Inköpare", cat3: ""},
        {ssyk: 3324, family: "Supply chain", cat2: "", cat3: ""},

        {ssyk: 3331, family: "Supply chain", cat2: "", cat3: ""},
        {ssyk: 3332, family: "Service", cat2: "Event & Turism", cat3: ""},
        {ssyk: 3333, family: "HR", cat2: "", cat3: ""},
        {ssyk: 3334, family: "Fastighet", cat2: "Sälj", cat3: ""},
        {ssyk: 3335, family: "Fastighet", cat2: "", cat3: ""},
        {ssyk: 3339, family: "Sälj", cat2: "", cat3: ""},

        {ssyk: 3341, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 3342, family: "Juridik", cat2: "", cat3: ""},
        {ssyk: 3351, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 3352, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 3353, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 3354, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 3355, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 3359, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 3360, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},


        {ssyk: 3411, family: "Socialt & kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 3412, family: "Religion", cat2: "", cat3: ""},
        {ssyk: 3421, family: "Idrott", cat2: "Idrottare", cat3: ""},
        {ssyk: 3422, family: "Idrott", cat2: "Tränare", cat3: ""},
        {ssyk: 3423, family: "Socialt & kurativt arbete", cat2: "", cat3: ""},
        {ssyk: 3424, family: "Idrott", cat2: "Friskvård", cat3: ""},

        {ssyk: 3431, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 3432, family: "Kreativitet & Design", cat2: "", cat3: ""},
        {ssyk: 3433, family: "Media & kommunikation", cat2: "", cat3: ""},
        {ssyk: 3439, family: "Media & kommunikation", cat2: "", cat3: ""},

        {ssyk: 3441, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 3449, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 3451, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 3452, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 3511, family: "IT", cat2: "", cat3: ""},

        {ssyk: 3512, family: "IT", cat2: "", cat3: ""},
        {ssyk: 3513, family: "IT", cat2: "", cat3: ""},
        {ssyk: 3514, family: "IT", cat2: "", cat3: ""},
        {ssyk: 3515, family: "IT", cat2: "", cat3: ""},
        {ssyk: 3521, family: "Media- och kommunikation", cat2: "", cat3: ""},
        {ssyk: 3522, family: "Media- och kommunikation", cat2: "", cat3: ""},

        {ssyk: 4111, family: "Ekonomi", cat2: "", cat3: ""},
        {ssyk: 4112, family: "HR", cat2: "Lön", cat3: ""},
        {ssyk: 4113, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 4114, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 4115, family: "Supply Chain", cat2: "", cat3: ""},
        {ssyk: 4116, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 4117, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 4119, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 4211, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 4212, family: "Administration & Sekreterare", cat2: "", cat3: ""},

        {ssyk: 4221, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 4222, family: "Kundtjänst", cat2: "", cat3: ""},
        {ssyk: 4223, family: "Kundtjänst", cat2: "", cat3: ""},
        {ssyk: 4224, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 4225, family: "Kundtjänst", cat2: "", cat3: ""},
        {ssyk: 4226, family: "Sälj", cat2: "", cat3: ""},



        {ssyk: 4321, family: "Supply Chain", cat2: "", cat3: ""},
        {ssyk: 4322, family: "Supply Chain", cat2: "", cat3: ""},
        {ssyk: 4323, family: "Supply Chain", cat2: "", cat3: ""},

        {ssyk: 4411, family: "Administration & Sekreterare", cat2: "", cat3: ""},
        {ssyk: 4420, family: "Supply Chain", cat2: "", cat3: ""},
        {ssyk: 4430, family: "Okategoriserad", cat2: "", cat3: ""},

        {ssyk: 5111, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5112, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5113, family: "Service", cat2: "", cat3: ""},
    
        {ssyk: 5120, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 5131, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 5132, family: "Restaurang & Hotell", cat2: "", cat3: ""},

        {ssyk: 5141, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5142, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5143, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5144, family: "Service", cat2: "", cat3: ""},
        
        {ssyk: 5149, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5151, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        {ssyk: 5152, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        
        {ssyk: 5161, family: "Service", cat2: "", cat3: ""},
        {ssyk: 5169, family: "Service", cat2: "", cat3: ""},

        {ssyk: 5221, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5222, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5223, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5224, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5225, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5226, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5227, family: "Butik & Detaljhandel", cat2: "", cat3: ""},
        {ssyk: 5230, family: "Butik & Detaljhandel", cat2: "", cat3: ""},

        {ssyk: 5241, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 5242, family: "Sälj", cat2: "", cat3: ""},

        {ssyk: 5311, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 5312, family: "Utbildning", cat2: "", cat3: ""},
        {ssyk: 5321, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5322, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5323, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5324, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5325, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5326, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5330, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5341, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5342, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5343, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5349, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},
        {ssyk: 5350, family: "Hälso- & Sjukvård", cat2: "", cat3: ""},

        {ssyk: 5411, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 5412, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 5413, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 5414, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},
        {ssyk: 5419, family: "Säkerhet och Bevakning", cat2: "", cat3: ""},

        {ssyk: 6111, family: "Lantbruk", cat2: "", cat3: ""},
        {ssyk: 6112, family: "Lantbruk", cat2: "", cat3: ""},
        {ssyk: 6113, family: "Lantbruk", cat2: "", cat3: ""},
   
        {ssyk: 6121, family: "Djurvård", cat2: "", cat3: ""},
        {ssyk: 6122, family: "Djurvård", cat2: "", cat3: ""},
        {ssyk: 6129, family: "Djurvård", cat2: "", cat3: ""},

        {ssyk: 6130, family: "Lantbruk", cat2: "", cat3: ""},
        {ssyk: 6210, family: "Lantbruk", cat2: "Skogsbruk", cat3: ""},

        {ssyk: 6221, family: "Sjöfart", cat2: "", cat3: ""},
        {ssyk: 6222, family: "Sjöfart", cat2: "", cat3: ""},

        {ssyk: 7111, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7112, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7113, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7114, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7115, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7116, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7119, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7121, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7122, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7123, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7124, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7125, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7126, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7131, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7132, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7133, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7134, family: "Byggnation", cat2: "", cat3: ""},

        {ssyk: 7211, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7212, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7213, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7214, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7215, family: "Byggnation", cat2: "", cat3: ""},
        
        {ssyk: 7221, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7222, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7223, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 7224, family: "Byggnation", cat2: "", cat3: ""},
        
        {ssyk: 7231, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7232, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7233, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 7311, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7312, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7319, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 7321, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7322, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7323, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 7411, family: "El", cat2: "", cat3: ""}, 
        {ssyk: 7412, family: "El", cat2: "", cat3: ""},
        {ssyk: 7413, family: "El", cat2: "", cat3: ""},
        {ssyk: 7420, family: "El", cat2: "", cat3: ""},

    
        {ssyk: 7521, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7522, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7523, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
    
        {ssyk: 7531, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7532, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7533, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7534, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
       
        {ssyk: 7611, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7612, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7613, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 7619, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 8111, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8112, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8113, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8114, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8115, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8116, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
     
       
        {ssyk: 8121, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8122, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8129, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8131, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8139, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 8141, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8142, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8143, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8151, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8159, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8161, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8162, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8163, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8169, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8171, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8172, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8173, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8174, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8181, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8189, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8191, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8192, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8193, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8199, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 8211, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8212, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8213, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8214, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 8219, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 8311, family: "Transport", cat2: "", cat3: ""},
        {ssyk: 8312, family: "Transport", cat2: "", cat3: ""},
        {ssyk: 8321, family: "Transport", cat2: "", cat3: ""},
        {ssyk: 8329, family: "Transport", cat2: "", cat3: ""},
        {ssyk: 8331, family: "Transport", cat2: "", cat3: ""},
        {ssyk: 8332, family: "Transport", cat2: "", cat3: ""},

        {ssyk: 8341, family: "Lantbruk", cat2: "Skogsbruk", cat3: ""},
        {ssyk: 8342, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 8343, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 8344, family: "Tillverkning & Produktion", cat2: "", cat3: ""},

        {ssyk: 8350, family: "Sjöfart", cat2: "", cat3: ""},

        {ssyk: 9111, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        {ssyk: 9119, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        {ssyk: 9120, family: "Städ & underhållsarbete", cat2: "", cat3: ""},

        {ssyk: 9210, family: "Lantbruk", cat2: "", cat3: ""},
      
        {ssyk: 9310, family: "Byggnation", cat2: "", cat3: ""},
        {ssyk: 9320, family: "Tillverkning & Produktion", cat2: "", cat3: ""},
        {ssyk: 9331, family: "Supply chain", cat2: "", cat3: ""},
        {ssyk: 9332, family: "Supply chain", cat2: "", cat3: ""},

        {ssyk: 9411, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 9412, family: "Restaurang & Hotell", cat2: "", cat3: ""},
        {ssyk: 9413, family: "Restaurang & Hotell", cat2: "", cat3: ""},

        {ssyk: 9520, family: "Sälj", cat2: "", cat3: ""},
        {ssyk: 9610, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        {ssyk: 9621, family: "Marknad", cat2: "", cat3: ""},
        {ssyk: 9622, family: "Städ & underhållsarbete", cat2: "", cat3: ""},
        {ssyk: 9629, family: "Okategoriserad", cat2: "", cat3: ""},
        {ssyk: "0110", family: "Säkerhet och Bevakning", cat2: "Militär", cat3: ""},
        {ssyk: "0210", family: "Säkerhet och Bevakning", cat2: "Militär", cat3: ""},
        {ssyk: "0310", family: "Säkerhet och Bevakning", cat2: "Militär", cat3: ""},
    
    







/* --------------- BELOW IS FOR TESTING ONLY, IS NOT CORRECT*/

     /* {ssyk: 8211, family: "Supply Chain", cat2: "abc", cat3: ""},
        {ssyk: 1321, family: "Supply Chain", cat2: "abc", cat3: "a"},
        {ssyk: 3323, family: "Supply Chain", cat2: "ccc", cat3: "aa"},
      {ssyk: 3313, family: "Ekonomi", cat2: "", cat3: ""},
      {ssyk: 3112, family: "Ingenjörskap", cat2: "Byggteknik", cat3: "test"},*/
     ];
    
     export default families;