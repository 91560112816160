import React from "react";

export class Avslutning extends React.Component {
    render() {
        let friText = <></>
        if(this.props.fritext !== "" && this.props.fritextTitel !== "") {
            friText = <div className="aPage">
                <h2 className="h2PaddingTop">8. {this.props.fritextTitel}</h2>
                <p className="P-document">
                    {this.props.fritext}
                </p>
            </div>
        }
        return (
            friText
        )
    }
}