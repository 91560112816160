import React from 'react';
import Chart from "react-apexcharts";
import lang from '../Language/language.js';

export class BarChart extends React.Component {
  
  constructor(props) {
    let language = localStorage.getItem('language') ?? 'sv';
    super(props);

    this.state = {
      series: [{
        data: [0, 0, 0]
      }],
      options: {
        tooltip: {
          enabled: false,
        },
        colors: ['#54c7b6', '#55cbaf', '#55c2ca'],
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: language === "sv" ? ['Manliga', 'Kvinnliga', 'Jämställda'] : [lang[language].men, lang[language].women, lang[language].genderEqual],
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },

          labels: {
            show: false,
            style: {
              colors: '#a3eade'
            }
          },
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          reversed: true,
          axisTicks: {
            show: false
          }
        }
      },


    };
  }

  render() {
    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.props.chartData} type="bar" height={250} />
      </div>
    );
  }
}
