import React from 'react';
import './Introduktion.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import Vimeo from '@u-wave/react-vimeo';
import happyYearIcon from '../../assets/images/happyYear.png';
import { fetchAuth } from '../../fetch-auth';
import lang from '../Language/language.js';

export class Introduktion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPop: false,
            manuallyHidePop: false // If true pop is never shown no matter what. Can only be hardcoded
        }
    }

    componentDidMount() {
        //Check if new year, if so and if it is january and if the user have imported data in september or later and made comments for past year, show new year popUp
        const runIt = async () => {
            // Before hitting database, check if an answer has already been selected by user
            const thisYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth();
            let clickedYear = localStorage.getItem("clickedYear")
            if ((clickedYear !== null && clickedYear == thisYear) || currentMonth !== 0 || this.state.manuallyHidePop === true) { // If localstorage is set and it is set this year, OR if the month is not january, do not show popup again.
                return;
            }
            else { // If user has not select an answer and if it is january, do a check if they should see the popup
                let response = await fetchAuth(`/api/checkImportDate`, 'POST');
                let data = await response.json();
                if (data !== undefined) {
                    if (data === "Show") { // If importdate was in september or later last year, then show popUp message for this year
                        this.setState({ showPop: true })
                    }
                }
            }
        }
        runIt();
    }
    changeYear = (event) => {
        let year = event.target.id
        localStorage.setItem("year", year);
        //Fix so that we dont calculate wrong year on import if the year switches but last year (which was current) is still saved in local storage
        const thisYear = new Date().getFullYear()
        const today = new Date()
        if (thisYear == year) {
            localStorage.setItem("currentYearSelected", "Yes")
            localStorage.removeItem('dateForYearSelected'); // remove the date for when we set the selected year
        } else {
            localStorage.setItem("currentYearSelected", "No")
            localStorage.setItem("dateForYearSelected", today.toISOString()) // Set when we selected the year. After 3 months it will go back to current year.
        }
        // Add to localstorage that a selection has been made 
        localStorage.setItem("clickedYear", thisYear) // This will always show the current year, no matter what the user selects. Its just for the system to know if they have clicked it and if so what yer did they click
        window.location.reload();
    }
    render() {
        let currentYear = new Date().getFullYear();
        let pastYear = new Date().getFullYear() - 1
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <TopNav />
                <Menu />
                <div className={this.state.showPop ? "newYearPop" : "hide"}>
                    <div className="newYearPopTitle"><img src={happyYearIcon} style={{ width: 60, marginRight: 20, transform: "scaleX(-1)" }} />{language === "sv" ? "Gott nytt år" : lang[language].welcomePopTitle}<img src={happyYearIcon} style={{ width: 60, marginLeft: 20 }} /></div>
                    <div>{language === "sv" ? "Systemet har automatiskt gått över till att utföra lönekartläggningen för " : lang[language].welcomePopTextOne}{currentYear}.
                        <p>{language === "sv" ? `Har ni påbörjat er lönekartläggning under ${pastYear} och inte blivit klara innan årskiftet? Välj i sådana fall att fortsätta lönekartläggningen för ${pastYear} här nedan. Om ni vill starta en ny lönekartläggning för ${currentYear}, välj istället att påbörja ${currentYear} års arbete.` : lang[language].welcomePopTextTwo}

                            <p>{language === "sv" ? `Om ni väljer år ${currentYear} så kommer alla de kommentarer och åtgärder som ni skapade ${pastYear} att visas som föregående års kommentarer/åtgärder.` : lang[language].welcomePopTextThree}</p>
                        </p>
                    </div>
                    <div className="newYearPopBtn" id={pastYear} onClick={this.changeYear}>{language === "sv" ? `Fortsätt ${pastYear} års arbete` : lang[language].welcomeContinue}</div>
                    <div className="newYearPopBtn" id={currentYear} onClick={this.changeYear}>{language === "sv" ? `Påbörja ${currentYear} års arbete` : lang[language].welcomeStartNew}</div>
                    <div style={{ borderTop: "1px solid lightgrey", paddingTop: 15 }}><b><u>OBS:</u></b> {language === "sv" ? "Ni kan alltid ändra år när ni vill under inställningar via den gröna knappen längst upp åt höger." : lang[language].welcomeNote}</div>
                </div>
                <div className={this.state.showPop ? "popUpFader" : "hide"} />
                <div className="container">
                    <div className="explainer-container">
                        <h1 className="welcome-title">{language === "sv" ? "Välkommen!" : lang[language].welcome}</h1>
                        <span style={{ fontSize: 15.6 }}>{language === "sv" ? "Se nedan introduktionsfilm, sedan är det bara att sätta igång." : lang[language].welcomeInfo}</span>
                        <div style={{ paddingTop: 40, paddingBottom: 140 }}>
                            <Vimeo
                                video="590375259"
                                paused={true}
                                autoplay={false}
                                height="350px"
                                showTitle={false}
                                color="55cab1"
                                showByline={false}
                                showPortrait={false}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}