import React from "react";
import lang from '../../../Language/language.js';
export class Arbetsgang extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <div className="aPage">
                <h3 className="h2PaddingTop">{language === "sv" ? "2.3 Arbetsgång" : lang[language].tocProcedure}</h3>

                <p className="P-document" style={{ fontWeight: "bold", marginBottom: -14 }}>{language === "sv" ? "Steg " : lang[language].step}1:</p>
                <p className="P-document">{language === "sv" ? "Information om organisationens löner har importerats i verktyget." : lang[language].proOne}</p>

                <p className="P-document" style={{ fontWeight: "bold", marginBottom: -14 }}>{language === "sv" ? "Steg " : lang[language].step}2:</p>
                <p className="P-document">{language === "sv" ? <>Befattningarna har grupperats in i grupper för lika arbeten.
                De arbeten som anses utföra lika eller nästintill lika arbetsuppgifter har blivit tilldelade
                samma grupp för jämförelse av lika arbete.</> : lang[language].proTwo}</p>

                <p className="P-document" style={{ fontWeight: "bold", marginBottom: -14 }}>{language === "sv" ? "Steg " : lang[language].step}3:</p>
                <p className="P-document">{language === "sv" ? <>En gruppering av likvärdiga arbeten har gjorts. Med likvärdiga arbeten syftas på arbeten som inte utför samma arbetsuppgifter men som ändå kan anses ha likvärdiga krav. Arbetena har bedömts med hjälp av en arbetsvärdering där de delats in i grupper
                baserat på kraven i arbetet. Arbetsvärderingens utformning och grund för bedömning beskrivs i
                detalj ovan under punkt 2.2, "Viktning & bedömning".</> : lang[language].proThree}</p>

                <p className="P-document" style={{ fontWeight: "bold", marginBottom: -14 }}>{language === "sv" ? "Steg " : lang[language].step}4:</p>
                <p className="P-document">{language === "sv" ? "Lönerna har kartlagts och eventuella löneskillnader mellan könen har analyserats för:" : lang[language].proFour}</p>
                <ul style={{ fontSize: 15, marginBlockStart: -14 }}>
                    <li>1. {language === "sv" ? "Lika arbeten" : lang[language].equalWorks}.</li>
                    <li>2. {language === "sv" ? "Likvärdiga arbeten" : lang[language].equivalentWorks}.</li>
                    <li>3. {language === "sv" ? "Hierarkiska skillnader. Det vill säga kvinnodominerade arbeten som har högre krav i sitt arbete men lägre lön än icke kvinnodominerade arbeten." : lang[language].proFive}</li>
                </ul>
                <p className="P-document">{language === "sv" ? "Bestämmelser och praxis har också setts över." : lang[language].proSix}</p>

                <p className="P-document" style={{ fontWeight: "bold", marginBottom: -14 }}>{language === "sv" ? "Steg " : lang[language].step}5:</p>
                <p className="P-document">{language === "sv" ? "Handlingsplan tillsammans med kostnadsberäkning har tagits fram i de fall åtgärd varit nödvändigt. Även föregående års eventuella åtgärder har utvärderats." : lang[language].proSeven}</p>
            </div>
        )
    }
}