import React from 'react';
import '../Arbetsvardera/Popup/PopUp.css';
import exitImg from '../../assets/images/exit.png';

export class AdminPopUp extends React.Component {

    render() {
        let popUp = <></>

        if (this.props.showPopUp === "selectType") {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ position: "fixed", margin: "auto", maxWidth: 600 }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.props.exitAction} />
                <h4 style={{ textDecoration: 'underline', marginTop: 40 }}>Bolagstyper</h4>
                <ul style={{ textAlign: "left", margin: "auto", maxWidth: 420 }}>
                    <li><b>Dotterbolag</b> - Bolag som tillhör en redan tillagd Koncern.</li>
                    <li><b>Bolag</b> - En vanlig organisation utan dotterbolag.</li>
                    <li><b>Koncern</b> - En koncern kan ha flera dotterbolag under sig.</li>
                    <li><b>Konsult</b> - Konsulter kan lägga till egna kunder</li>
                </ul>
            </div>
        }

        if (this.props.showPopUp === "selectMotherComp") {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ position: "fixed", margin: "auto", maxWidth: 600, minHeight: 260 }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.props.exitAction} />
                <h4 style={{ textDecoration: 'underline', marginTop: 40 }}>Välj företag</h4>
                <ul style={{ textAlign: "left", margin: "auto", maxWidth: 420 }}>
                    <li>
                        En användare tillhör ett företag där denne kan utföra lönekartläggning.
                        Om användaren tillhör en koncern kan antingen koncernbolaget väljas (då kan användaren utföra lönekartläggning i alla
                        koncernens bolag, kräver att även rollen "Koncern" väljs) eller ett enskilt dotterbolag (då kommer användaren endast åt dotterbolaget och ej hela koncernen).
                        Om ett dotterbolag väljs ska rollen "Bolag" väljas.
                    </li>
                </ul>
            </div>
        }

        if (this.props.showPopUp === "selectRole") {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ position: "fixed", margin: "auto", maxWidth: 600 }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.props.exitAction} />
                <h4 style={{ textDecoration: 'underline', marginTop: 40 }}>Välj roll</h4>
                <ul style={{ textAlign: "left", margin: "auto", maxWidth: 420 }}>
                    <li><b>Koncern</b> - Användaren kan utföra lönekartläggning åt alla dotterbolag i en viss koncern.</li>
                    <li><b>Bolag</b> - Användaren kan utföra lönekartläggning åt ett specifikt bolag. Välj även detta om bara ska komma åt ett dotterbolag och inte hela koncernen. </li>
                    <li><b>Konsult</b> - Konsulter kan lägga till egna kunder.</li>
                </ul>
            </div>
        }

        return (popUp)
    }

}