import React from 'react';
import './PopUp.css';
import exitImg from '../../../assets/images/exit.png';
import editImg from '../../../assets/images/edit.png';
import resetImg from '../../../assets/images/resetIcon.png';
import TextareaAutosize from 'react-textarea-autosize';
import { fetchAuth } from '../../../fetch-auth';
import lang from '../../Language/language.js';

export class PopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editQuestions: false,
            question: "",
            instructions: "",
            focus: "",
            level1: "",
            level2: "",
            level3: "",
            level4: "",
            showAddBtn: false,
            whatLevelDesc: null,
            isHoveringQuestion: false,
            resetted: false,
            saveBtn: false,
            originalFactors: [
                {
                    question: "utbildning",
                    instructions: "- Utbildning\n- Arbetslivserfarenhet",
                    focus: "Sammanfattande bedömning över hur stora krav tjänsten ställer på utbildning och arbetslivserfarenhet.",
                    level1: "Inga eller låga krav på utbildning och relevant arbetslivserfarenhet.",
                    level2: "Kräver eftergymnasial utbildning eller relevant yrkeserfarenhet.",
                    level3: "Kräver hög kunskap. Förvärvad genom hög utbildning eller medelhög utbildning med längre relevant arbetslivserfarenhet.",
                    level4: "Kräver mycket specialiserad eller bred kunskap. Förvärvard genom hög utbildning och lång relevant arbetslivserfarenhet.",
                },
                {
                    question: "problem",
                    instructions: "- Hantera uppkommande problem\n- Analysering\n- Kreativitet",
                    focus: "- Hur ofta problem förekommer och måste lösas\n- Självständigheten i arbetet. Finns det hjälp att tillgå?\n- Komplexiteten i uppgifterna\n- Om måste sätta sig in i nya områden till följd av snabb utveckling",
                    level1: "Enkla och ofta rutinmässiga uppgifter där problemlösning förekommer i mindre utsträckning. För lösningar finns oftast kända tillvägagångssätt.",
                    level2: "Kräver viss analys och kreativitet. Komplicerade problem förekommer men ska diskuteras med överordnad innan beslut.",
                    level3: "Problemlösning vanligt förekommande. Självständig analys, nytänkande, utvärdering av alternativ inför beslut. Lösningar är sällan givna eller definierade.",
                    level4: "Problemlösning är stor del i arbetet. Varierade, komplexa problem. Kräver kreativa lösningar, ofta nya angreppssätt, kvalificerad kunskapsinhämtning och stor självständighet i beslut.",
                },
                {
                    question: "sociala",
                    instructions: "- Kommunikation\n- Samarbete\n- Service",
                    focus: "- Hur stor andel i arbetet som kräver sociala färdigheter\n- Om situationerna ofta är krävande\n- Om det ofta förekommer intressemotsättningar",
                    level1: "Inga krav på särskilda sociala färdigheter.",
                    level2: "Färdigheter i kommunikation och samarbete både internt och externt. Kontinuerliga dialoger. Kan innehålla intressemotsättningar.",
                    level3: "Kommunikation är en betydande del i arbetet. Ofta krävande situationer och intressemotsättningar. Kunna vägleda och argumentera.",
                    level4: "Sociala färdigheter avgörande för arbetet. Svåra och komplicerade kontakter med personer/grupper. Ofta stora intressemotsättningar eller svåra situationer.",
                },
                {
                    question: "personal",
                    instructions: "- Personalansvar\n- Arbetsledning",
                    focus: "- Antal anställda\n- Om en större del av arbetet upptas av arbetsledning\n- Självständigheten i ansvaret",
                    level1: "Har inget personalansvar eller ytterst få medarbetare där arbetsledning är en mycket liten del av arbetet.",
                    level2: "Ansvarar för en mindre grupp anställda. Arbetsledning är en viss del av arbetet.",
                    level3: "Ansvarar för ett betydande antal anställda. Arbetsledning är en stor del av arbetet.",
                    level4: "Övergripande chefsansvar för stora personalgrupper eller avdelningar. Ofta arbetsledning av andra chefer.",
                },
                {
                    question: "verksamhet",
                    instructions: "- Planering\n- Utveckling\n- Resultat",
                    focus: "- Om arbetet är av långsiktig betydelse för organisationen\n- Hur starkt resultatet påverkar organisationen\n- Självständigheten i ansvaret",
                    level1: "Riktlinjer skapat av någon annan finns att följa. Inget eller litet ansvar för planering, utveckling och resultat. Kan delta i planering för egna gruppen.",
                    level2: "Visst ansvar för planering, utveckling eller resultat. Ej nödvändigtvis arbetsledande funktion. Självständigt ansvar för arbetssätt, tidsplanering eller budget.",
                    level3: "Övergripande och strategiskt ansvar för planering, utveckling och resultat. Kan styra och förändra processer inom egna verksamhetsområdet.",
                    level4: "Övergripande affärsmässigt helhetsansvar för en/flera grupper eller verksamhetsområden. Utformar och påverkar varor/tjänster samt normer och riktlinjer som styr."
                },
                {
                    question: "manniskor",
                    instructions: "- Kunder\n- Patienter\n- Ansvar för andras hälsa eller säkerhet\n- Påverkan på människor genom beslut",
                    focus: "- Självständigheten i ansvaret\n- Hur mycket ansvaret för människor dominerar i arbetet\n- Hur starkt arbetet påverkar andra människor\n- Om känslig/konfidentiell information om människor tillhandahålls",
                    level1: "Ansvaret är begränsat till normal hänsyn och omtanke för andras välbefinnande.",
                    level2: "Ett visst ansvar för andras hälsa, utveckling eller säkerhet. Innebär ofta att påverka andra genom att inom ramen följa regler och anvisningar. Hantering av känslig information kan förekomma.",
                    level3: "Betydande ansvar/påverkan på andras hälsa, utveckling eller säkerhet. Självständiga beslut som påverkar andra människor är vanligt.",
                    level4: "Mycket stora krav att självständigt bedöma och fatta avgörande beslut som påverkar andras hälsa, utveckling eller säkerhet. Medför ofta stor påverkan på individer/grupper.",
                },
                {
                    question: "fysiska",
                    instructions: "- Tungt arbete\n- Repetitiva arbetsmoment\n- Obekväma arbetsställningar\n- Risk för skada",
                    focus: "- Hur påfrestande det fysiska arbetet är\n- Kombinationseffekter, till exempel om tungt arbete sker i kombination med obekväm arbetsställning\n- Långt repetetivt arbete kan vara lika fysiskt ansträngande som tunga moment under kortare perioder\n- Tillgång till hjälpmedel\n- Arbetstakt, hur snabbt det fysiska arbetet måste utföras",
                    level1: "Inga särskilda krav på fysisk ansträngning. Risk för skada är mycket liten.",
                    level2: "I perioder kan det krävas fysisk ansträning. Risk för skada är liten.",
                    level3: "Fysisk ansträngning sker dagligen. Långt repetetivt arbete eller tyngre moment under kortare perioder. Viss risk för skada.",
                    level4: "Fysisk ansträngning är en stor del av arbetet. Obekväma arbetställningar förekommer. Stor risk för skada.",
                },
                {
                    question: "psykiska",
                    instructions: "- Känslomässig ansträngning \n- Enformighet \n- Tillgänglighet \n- Stress",
                    focus: "- Hur påfrestande den psykiska ansträngningen är \n- Sker ofta möte med arga, sjuka eller svårhanterliga människor? \n- Krav på tillgänglighet, tex utanför ordinarie arbetstid eller att andra uppgifter ofta blir lidande \n- Hårt styrt och enformigt arbete som kräver noggran koncentration kan vara mycket påfrestande \n- Hur stor andel arbetsbelastning och stress",
                    level1: "Låga krav på psykisk ansträngning. Psykiskt påfrestande situationer inträffar sällan.",
                    level2: "Psykiskt påfrestande situationer inträffar ibland. Hög arbetsbelastning och tidspress förekommer i perioder. ",
                    level3: "Psykisk och känslomässig ansträngning förekommer ofta.",
                    level4: "Mycket höga krav på psykisk och känslomässig ansträngning över tid. Höga krav på tillgänglighet, koncentration, tidspress och påfrestande relationer.",
                }
            ]
        }
    }

    componentDidMount = () => {
        this.setState({ whatLevelDesc: this.props.whatLevelDesc, manualDescriptions: this.props.manualDescriptions }, () => {
           this.factorDescriptions()
        });
    }
    handleMouseEnter = () => { this.setState({ isHoveringQuestion: true }); };
    handleMouseLeave = () => { this.setState({ isHoveringQuestion: false }); };
    AddBtnClicked = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let factors =  language === "sv" ? this.state.originalFactors : lang[language].originalFactors
        for (let i = 0; i < factors.length; i++) {
            if (factors[i].question === this.props.showPopUp) {
                this.setState({
                    level1: factors[i].level1,
                    level2: factors[i].level2,
                    level3: factors[i].level3,
                    level4: factors[i].level4,
                    saveBtn: true,
                    showAddBtn: false,
                })
                break;
            }
        }
    }
    closeAlert = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let userConfirmation = window.confirm(language === "sv" ? "Är du säker på att du vill avbryta? Dina ändringar kommer inte att sparas." : lang[language].sureCancel)
        if (userConfirmation) { this.props.exitAction() }
    }
    saveIt = () => {
        const save = async () => {
            let data = {
                question: this.state.question,
                instructions: this.state.instructions,
                focus: this.state.focus,
                level1: this.state.level1,
                level2: this.state.level2,
                level3: this.state.level3,
                level4: this.state.level4,
                whatLevelDesc: this.state.whatLevelDesc
            }
            let response = await fetchAuth(`/api/saveArbVarderingOriginalDesc`, 'POST', JSON.stringify({ data: data }))
            let gotData = await response.json();
            gotData === "Saved" ? this.setState({ saveBtn: false, editQuestions: false }) : alert('Ett problem uppstod vid sparningen. Vänligen prova igen.')

            //Also update state in arbetsVarderingNormal which is parent component
            let factors = {
                question: this.state.question,
                instructions: this.state.instructions,
                focus: this.state.focus,
                level1: this.state.level1,
                level2: this.state.level2,
                level3: this.state.level3,
                level4: this.state.level4,
            }
            this.props.onDataSent(factors);
        }
        save();
    }

    reset = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let userConfirmation = window.confirm(language === "sv" ? "Återställer faktorn till ordinarie text. Alla justeringar kommer att raderas. Vill du fortsätta?" : lang[language].resetFactor)
        if (userConfirmation) {
            let factors =  language === "sv" ? this.state.originalFactors : lang[language].originalFactors
            for (let i = 0; i < factors.length; i++) {
                let level1 = factors[i].level1
                let level2 = factors[i].level2
                let level3 = factors[i].level3
                let level4 = factors[i].level4
                let showAddBtn = false
                if (this.state.whatLevelDesc === null || this.state.whatLevelDesc === "oldStandard") {
                    level1 = null
                    level2 = null
                    level3 = null
                    level4 = null
                    showAddBtn = true
                }
                if (factors[i].question === this.props.showPopUp) {
                    this.setState({
                        instructions: factors[i].instructions,
                        focus: factors[i].focus,
                        level1: level1,
                        level2: level2,
                        level3: level3,
                        level4: level4,
                        resetted: true,
                        saveBtn: true,
                        showAddBtn: showAddBtn,
                    })
                    break;
                }
            }
            setTimeout(() => {
                this.setState({
                    resetted: false
                })
            }, 10);
        }
    }

    factorDescriptions = async () => {
        return new Promise((resolve, reject) => {
            let theQuestion = this.props.showPopUp  //Get the selected question
            let language = localStorage.getItem('language') ?? 'sv';
            let factors =  language === "sv" ? this.state.originalFactors : lang[language].originalFactors
            let savedFactors = this.props.manualDescriptions // Get saved factors
            let whatLevelDesc = this.props.whatLevelDesc //Get whatLevelDesc
            let showAddBtn = false

            let instructions = ""
            let focus = ""
            let level1 = ""
            let level2 = ""
            let level3 = ""
            let level4 = ""
            // Get saved factor result for the selected factor if there is any (if we have saved the changes before)
            let savedFactorFound = false
            for (let i = 0; i < savedFactors.length; i++) {
                if (savedFactors[i].question === theQuestion) {
                    instructions = savedFactors[i].instructions
                    focus = savedFactors[i].focus
                    level1 = savedFactors[i].level1
                    level2 = savedFactors[i].level2
                    level3 = savedFactors[i].level3
                    level4 = savedFactors[i].level4
                    savedFactorFound = true
                    break;
                }
            }
            // Get original factor result for the selected factor if there was nothing saved
            if (!savedFactorFound) {
                for (let i = 0; i < factors.length; i++) {
                    if (factors[i].question === theQuestion) {
                        instructions = factors[i].instructions
                        focus = factors[i].focus
                        level1 = factors[i].level1
                        level2 = factors[i].level2
                        level3 = factors[i].level3
                        level4 = factors[i].level4
                        break;
                    }
                }
                //if whatLevelDesc is "null" or "oldStandard", then make levels null instead.
                if (whatLevelDesc === "oldStandard" || whatLevelDesc === null) {
                    level1 = null
                    level2 = null
                    level3 = null
                    level4 = null
                }
            }
            //Now, check if every level is null or "", if so, add showAddBtn if whatLevelDesc === null or "oldStandard"
            if (
               /* (whatLevelDesc === null || whatLevelDesc === "oldStandard") &&*/
                (level1 === null || level1 === "") &&
                (level2 === null || level2 === "") &&
                (level3 === null || level3 === "") &&
                (level4 === null || level4 === "")
            ) { showAddBtn = true }

            this.setState({
                question: theQuestion,
                instructions: instructions,
                focus: focus,
                level1: level1,
                level2: level2,
                level3: level3,
                level4: level4,
                showAddBtn: showAddBtn
            })
            resolve();
        })
    }

    editQuestionStatus = () => { this.state.editQuestions === false ? this.setState({ editQuestions: true }) : this.setState({ editQuestions: false }) }
    instructions = (event) => {
        let whatText = event.target.id
        let textInfo = event.target.value
        this.setState({ [whatText]: textInfo, saveBtn: true })
    }
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let popUp = <></>

        let bulletCreatorInstructions = this.state.instructions.split('-').filter(item => item.trim() !== '').map((item, index) => (
            <li className="popUp-list" key={index}>
                {item.trim()}
            </li>
        ));

        let bulletCreatorFocus = this.state.focus.split('-').filter(item => item.trim() !== '').map((item, index) => (
            <li className="popUp-list" key={index}>
                {item.trim()}
            </li>
        ));

        if (!this.state.editQuestions && this.state.showAddBtn) {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ fontSize: 15, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.state.saveBtn ? this.closeAlert : this.props.exitAction} />
                </div>
                <div style={{ position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden", minHeight: 239 }}>
                    <div className="textblock-popup-left" style={{ height: "auto", background: "#f8f8f8", borderRight: "1px solid #dddddd", width: 285, marginLeft: -20, paddingLeft: 60 }}>
                        <h4 style={{ marginLeft: -17, fontSize: 15.6, marginBottom: 16 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                        <ul>
                            {bulletCreatorInstructions}
                        </ul>
                    </div>
                    <div className="textblock-popup-right" style={{ height: "auto", paddingLeft: 25, width: 405 }}>
                        <h4 style={{ fontSize: 15.6, marginBottom: 16 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                        <ul className="ulRight" style={{ marginLeft: "15px !important", paddingLeft: 15 }}>
                            {bulletCreatorFocus}
                        </ul>
                    </div>
                </div>
            </div>
        }

        if (!this.state.editQuestions && !this.state.showAddBtn) {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ fontSize: 13.6, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.state.saveBtn ? this.closeAlert : this.props.exitAction} />
                </div>
                <div style={{ position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden" }}>
                    <div className="textblock-popup-left" style={{ float: "left", height: "auto", width: 355, paddingLeft: 30, background: "#f8f8f8", borderRight: "1px solid #dddddd", paddingBottom: 30, minHeight: 239 }}>
                        <div>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginLeft: -13, marginTop: 0 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                            <ul>
                                {bulletCreatorInstructions}
                            </ul>
                        </div>
                        <div>
                            <h4 style={{ marginTop: 24, fontSize: 15.6, marginBottom: 16, marginLeft: -13 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                            <ul className="ulRight">
                                {bulletCreatorFocus}
                            </ul>
                        </div>
                    </div>
                    <div className="textblock-popup-right" style={{ float: "left", width: 345, height: "auto", paddingBottom: 30 }}>

                        <div style={{ width: 365 }}>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginTop: 0 }}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</h4>
                            <div className="popUp-list testar"><span className="aNumber">1</span><span className="besideNumber">{this.state.level1}</span></div>
                            <div className="popUp-list testar"><span className="aNumber">2</span><span className="besideNumber">{this.state.level2}</span></div>
                            <div className="popUp-list testar"><span className="aNumber">3</span><span className="besideNumber">{this.state.level3}</span></div>
                            <div className="popUp-list testar"><span className="aNumber">4</span><span className="besideNumber">{this.state.level4}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {/* PopUp for editQuestion --------------------------------------- */ }
        if (this.state.editQuestions) {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ fontSize: 13.6, marginTop: -300, paddingLeft: 0, maxWidth: 830, overflow: "auto", overflowX: "hidden", height: "auto", maxHeight: 500, minHeight: 200, paddingBottom: 0 }}>
                <div className="top-btn-sticky">
                    <img alt="" src={editImg} className="edit-box" onClick={this.editQuestionStatus} />
                    <img alt="" style={{ position: "absolute", right: 0, marginRight: 0, marginTop: -0.5 }} src={exitImg} className="hide-box" onClick={this.state.saveBtn ? this.closeAlert : this.props.exitAction} />
                </div>
                <div style={{ marginBottom: -60, position: "relative", marginTop: -10, paddingTop: 10, marginRight: -10, paddingRight: 10, display: "flex", overflowX: "hidden" }}>
                    <div className="textblock-popup-left" style={{ float: "left", height: "auto", width: 355, paddingLeft: 30, background: "#f8f8f8", borderRight: "1px solid #dddddd", paddingBottom: 30 }}>
                        <div>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginLeft: -13, marginTop: 0 }}>{language === "sv" ? "Kraven arbetet ställer vad gäller" : lang[language].demandsFor}</h4>
                            <TextareaAutosize value={this.state.instructions} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="instructions" />
                        </div>
                        <div>
                            <h4 style={{ marginTop: 24, fontSize: 15.6, marginBottom: 16, marginLeft: -13 }}>{language === "sv" ? "I beaktande vid bedömning" : lang[language].consider}</h4>
                            <TextareaAutosize value={this.state.focus} onChange={this.instructions} className="edit-factors-textField" placeholder={language === "sv" ? "Beskriv faktorn." : lang[language].describeFactor} name="" id="focus" />

                        </div>
                    </div>
                    <div className="textblock-popup-right" style={{ float: "left", width: 345, height: "auto", paddingBottom: 80 }}>

                        <div style={{ width: 385 }}>
                            <h4 style={{ fontSize: 15.6, marginBottom: 16, marginTop: 0 }}>{language === "sv" ? "Nivåbeskrivningar" : lang[language].levelDesc}</h4>
                            {this.state.showAddBtn ?
                                <div>
                                    <span>{language === "sv" ? "Här kan ni lägga till mer detaljerade nivåbeskrivningar." : lang[language].infoDetailedLevelDesc}</span>
                                    <div className="edit-levels-small-btn" onClick={this.AddBtnClicked}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</div><span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className="infoBoxEditQuestionBox">?</span>
                                    <div className={this.state.isHoveringQuestion ? "infoBoxEditLevels" : "hide"}>{language === "sv" ? "Detaljerade nivåbeskrivningar kan hjälpa till att konkretisera arbetsvärderingen och göra det enklare att förstå vad varje nivå innebär." : lang[language].infoDeailLevels}<p style={{ marginBottom: 0 }}>{language === "sv" ? `Om ni klickar på lägg till kommer färdiga nivåbeskrivningar att hämtas, dessa kan även justeras vid behov.`: lang[language].infoDetailLevelsTwo}</p></div>
                                </div>
                                :
                                <>
                                    <div className="popUp-list testar"><span className="aNumber">1</span><TextareaAutosize value={this.state.level1} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level1" /></div>
                                    <div className="popUp-list testar"><span className="aNumber">2</span><TextareaAutosize value={this.state.level2} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level2" /></div>
                                    <div className="popUp-list testar"><span className="aNumber">3</span><TextareaAutosize value={this.state.level3} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level3" /></div>
                                    <div className="popUp-list testar"><span className="aNumber">4</span><TextareaAutosize value={this.state.level4} onChange={this.instructions} className="edit-factors-textField edit-levels-textfields" placeholder={language === "sv" ? "Ange nivåbeskrivning." : lang[language].addLevelDesc} name="" id="level4" /></div>
                                </>
                            }
                        </div>
                    </div>

                </div>
                <div style={{ bottom: 0, position: "sticky", right: 4, float: "right" }}>
                    <div className={this.state.saveBtn ? "edit-levels-save" : "edit-levels-save-grey"} onClick={this.state.saveBtn && this.saveIt}>{language === "sv" ? "Spara" : lang[language].save}</div>
                    <div className="edit-levels-reset" onClick={this.reset}> <img alt="" src={resetImg} className={this.state.resetted ? "resettedIcon" : "resetIcon"} />{language === "sv" ? "Återställ" : lang[language].reset}</div>
                </div>
            </div>
        }

        {/* PopUp for info about import arbetsvardering */ }
        if (this.props.showPopUp === "importVardering") {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ maxWidth: 650, height: "auto" }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.props.exitAction} />
                <div style={{ padding: 20, textAlign: "left", fontSize: 14.6 }}>
                {language === "sv" ? "Du kan importera och återanvända arbetsvärderingar från andra bolag i er koncern. Ändringarna du gör i ett bolag påverkar inte de andra." : lang[language].importOther}

                    <p></p><b>{language === "sv" ? "Såhär gör du:" : lang[language].howTo}</b>
                    {language === "sv" ? <ul>
                        <li><b>1)</b> Klicka på knappen "Hämta värderingar"</li>
                        <li><b>2)</b> Välj vilket bolag du vill importera ifrån.</li>
                        <li><b>3)</b> Välj vad du vill importera. Notera att "allt" kopierar både arbetsvärdering och viktning.</li>
                        <li><b>4)</b> Klicka på importera.</li>
                    </ul> : lang[language].howToSteps}
                </div>
            </div>
        }

        {/* PopUp for info about import arbetsvardering */ }
        if (this.props.showPopUp === "importGroups") {
            popUp = <div id="utbildning" className="popUp active-popUp" style={{ maxWidth: 650, height: "auto" }}>
                <img alt="" src={exitImg} className="hide-box" onClick={this.props.exitAction} />
                <div style={{ padding: 20, textAlign: "left", fontSize: 14.6 }}>
                    Har ni gjort grupperingar av befattningar i ett annat bolag inom er koncern kan du importera och återanvända dessa grupperingar även för detta bolag. Ändringarna du gör i ett bolag påverkar inte de andra.

                    <p></p><b>Såhär gör du:</b>
                    <ul>
                        <li><b>1)</b> Klicka på knappen "Hämta grupperingar"</li>
                        <li><b>2)</b> Välj vilket bolag du vill kopiera/importera ifrån.</li>
                        <li><b>3)</b> Välj vad du vill importera. Allt, endast tomma, eller enskilda befattningar.</li>
                        <li><b>4)</b> Klicka på importera.</li>
                    </ul>
                </div>
            </div>
        }

        return (
            popUp
        )
    }

}